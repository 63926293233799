import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import '../css/CADConverter/ConverterInputBox.scss';
import { ClickAwayListener } from '@material-ui/core';
import { buttonNum } from './Modal';
import { BuildingPart } from './BuildingPart';
import { ExpandMore, Info, Refresh, Warning } from '@material-ui/icons';
import Tooltip from '../Tooltip';
interface InputBoxProp {
  name: string,
  value: number,
  disable?: boolean,
  step?: number
  unit?: string,
  buildingPart?: BuildingPart,
  defaultValue?: number,
  showMore?: boolean,
  hideEdited?: boolean, // 수정됨 표시
  canHaveZero?: boolean, // 값이 0이어도 font-error가 아님
  maxValue?: number,
  hasRefresh?: boolean,
  //showBalconyArea?: boolean,
  //expandBalconyInput: (value: boolean) => void,
  valueOnChange: (value: number) => void,
  valueUpdate?: () => void,
  showModal?: (title: string, content: string[], buttonNum: buttonNum, func: () => void) => void,
}

interface InputBoxState {
  focus: boolean,
  hover: boolean,
  value: number,
  step: number,
  disable: boolean,
}

export class ConverterInputBox extends Component<InputBoxProp, InputBoxState> {
  state: InputBoxState = {
    focus: false,
    hover: false,
    value: this.props.value,
    disable: false,
    step: 1,

  }


  componentDidMount = async () => {
    if (this.props.disable !== null) {
      this.setState({ disable: this.props.disable! })
    }

    if (this.props.step) {
      this.setState({ step: this.props.step })
    }
  }

  componentDidUpdate = (previousProps: Readonly<InputBoxProp>, previousState: Readonly<InputBoxState>) => {
    if (previousProps.disable !== this.props.disable) {
      this.setState({
        disable: this.props.disable!,
      })
    }

    if (previousProps.value !== this.props.value || this.state.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      })
    }
    if (this.props.step && previousProps.step !== this.props.step || this.state.step !== this.props.step) {
      this.setState({
        step: this.props.step!
      })
    }
  }

  addEvent = () => {
    if (this.props.name === "대지레벨(m)") this.changeValue((this.state.value + this.state.step).toFixed(5), 9999.99);
    else this.changeValue(((this.state.value + this.state.step).toFixed(5)).toString());
  }

  decreaseEvent = () => {
    this.changeValue(((this.state.value - this.state.step).toFixed(5)).toString());
  }

  onClickAway = () => {
    if (this.state.focus) {
      this.setState({
        focus: false,
      })  
    }
    if (this.state.value !== Number(this.props.value.toFixed(2)))
      this.setValue(Number(this.props.value.toFixed(2)));

  }



  changeValue = (value: string, maxLimit?: number) => { 
    if (this.props.defaultValue && Number(value) === this.props.defaultValue) {
      let v = this.props.defaultValue;
      this.setValue(v);
      return;
    }
    
    let s = 1 / this.state.step;
    let v = Math.round(Number(value) * 100000) / 100000;
    // * 소수점 제한
    if (v < 0) { v = 0 }
    if (maxLimit && v > maxLimit) return;
    v = Math.floor(v * s) / s;
    this.setValue(v);
  }

  setValue = (v: number) => {

    this.props.valueOnChange(v);
    if(this.props.valueUpdate){
      this.props.valueUpdate();
    }
    this.setState({ value: v });
  }


  render = () => {
    return (
      <div className='inputBody'
  
      >
        <div className="label-wrap">
          <div className='label'>
            <span className={`font font-12px name ${ this.props.canHaveZero !== true && this.props.value === 0 && 'font-error'}`}>{this.props.name}</span>
            <Tooltip place={"right"} arrowOn={false} msg="초깃값 되돌리기" >
              <span onClick={() => {
                
                  this.changeValue(String(this.props.defaultValue));
                  if (this.props.valueUpdate) {
                    this.props.valueUpdate();
                  }
              }}>
                {
                  (this.props.defaultValue !== undefined && this.props.hasRefresh) && 
                  <Refresh
                    className={`refresh-icon ${this.props.defaultValue !== undefined && this.props.value !== this.props.defaultValue ? "reset-icon-on" : ""}`}
                  />
                }
              </span>
            </Tooltip>
          </div>
        </div>
   

        <ClickAwayListener onClickAway={this.onClickAway}>
          <div className={`${(this.state.disable && 'box-disable') || 'box'}`} 
          onFocus={() => this.setState({ focus: true })} 
          onMouseEnter={() => this.setState({ hover: true })} 
          onMouseLeave={() => this.setState({ hover: false })}>
            <input
              type='number'
              className={`value ${(this.state.disable) && 'disable'}`}
              value={this.state.value}
              onKeyDownCapture={(e) => {
                if (e.key === "Enter") {
                  this.setValue(Number(this.state.value.toFixed(2)));
                }                
              }}
              onChange={e => {
                if (this.props.name === "대지레벨(m)") this.changeValue(e.target.value, 9999.99)
                else this.changeValue(e.target.value)
              }}
              disabled={this.state.disable}
              step='0.01'
            />
            
            {this.props.unit && <span className='unit'>{this.props.unit}</span>}
            <RemoveIcon
              className={`icon ${(!this.state.focus && !this.state.hover) && 'hide'} ${(this.state.disable) && 'disable'}`}
              onClick={this.decreaseEvent}
            />
            <AddIcon
              className={`icon ${(!this.state.focus && !this.state.hover) && 'hide'} ${(this.state.disable) && 'disable'}`}
              onClick={this.addEvent}
            />
          </div> 
        </ClickAwayListener>

        {
          (this.props.name === "대지레벨(m)" && this.props.value >= 500 &&
            <Tooltip className="warning-color" arrowOn={false} msg={<>
              <span style={{ color: "#FFB068" }}>대지레벨 값이 500m 이상으로 입력되었습니다.<br/>
              (권장 범위: 500m 미만)</span>
              <br />실제 프로젝트 생성 시 사업영역 대지레벨 기준으로 <br />
              범위가 제한될 수 있습니다.</>}>
              <div className="label-bottom">
                <Warning className="icon active icon-warning warning-color" /><span className="warning-color">권장 높이 이상 입력됨</span>
              </div>
            </Tooltip>) ||
          (this.props.hasRefresh && this.props.defaultValue !== undefined && this.props.value !== this.props.defaultValue  && this.props.hideEdited !== true &&
            
          <div className="label-bottom height-20px">
            <Info className="icon active icon-info"></Info><span className="input-changed">수정됨</span>
          </div>)
          ||
          <div className="label-bottom"></div>
        }
        {/* {
          this.props.showMore &&
          <Tooltip msg="발코니 면적 설정" arrowOn={false}>
            <div className="arrow-bottom"
            //</Tooltip> onClick={this.props.expandBalconyInput(!this.props.showBalconyArea)}
            >
            <ExpandMore className="icon"/>
          </div> 
          </Tooltip>
        } */}
        {/* <BuilditInput
          select={false}
          type="number"
          className=""
          value={this.state.value}
          step={0.0001}

          onChange={(e) => {
            this.changeValue(e.toString())
          }}
          startAdornment={
            <>
              <IconButton className={`icon-btn dec-btn ${(!this.state.focus && !this.state.hover) && "visibility-hidden" || ""}`}
                onClick={e => {
                  this.addEvent();
                  //      this.handleValue("DEC") 
                }}
              >
                <DecIcon className="icon" />
              </IconButton>
            <IconButton className={`icon-btn inc-btn ${(!this.state.focus && !this.state.hover) && "visibility-hidden" || ""}`}
              //onClick={e => this.handleValue("INC")}
              onClick={e => {
                this.decreaseEvent();
              }}
              >
                <IncIcon className="icon" />
              </IconButton>
            </>
          }
          endAdornment={
            <>
              <div className="unit" style={{ padding: "0px 10px 0px 0px" }}>㎡</div>
            </>
          }
        //    list={this.props.unitList}
        /> */}

      </div>
    )
  }
}