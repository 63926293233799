import React, { Component } from 'react';
import { FileDrop } from 'react-file-drop';
import { Button } from '@material-ui/core'
import '../css/CADConverter/DropFileToLoadBox.scss';
import { ConverterType } from './DataTypes';
import { ReactComponent as DownloadIcon } from '../img/icon/download.svg';
import Tooltip from '../Tooltip';
import { Help } from '@material-ui/icons';
import { ReactComponent as FileDownload } from '../img/icon/file_download.svg';
import { ReactComponent as FileUpload } from '../img/icon/file_upload.svg';

interface DropFilePops {
  functionForDrop: (file: any) => void,
  functionForButton: (e: React.ChangeEvent<HTMLInputElement>) => void,
  type: ConverterType,
  showSample: boolean,
  loadFile: boolean,
  isBlockVer: boolean,
}

interface DropFileState {
  dragCover: boolean,
  imgSrc: string,

}

export class DropFileBox extends Component<DropFilePops, DropFileState>{
  state: DropFileState = {
    dragCover: true,
    imgSrc: "./img/cad_converter/myTypeThumbnail.png",
  }
  type = 'my_type';
  mouseLeaveImg = "";
  mouseEnterImg = "";

  componentWillMount = () => {
    switch (this.props.type) {
      case ConverterType.myType:
        this.type = 'my_type';
        this.setState({ imgSrc: "./img/cad_converter/myTypeThumbnail.png" });
        this.mouseEnterImg = "./img/cad_converter/myTypeThumbnailHover.png";
        this.mouseLeaveImg = "./img/cad_converter/myTypeThumbnail.png";
        
        
        break;
      case ConverterType.mySite:
        this.type = 'my_site';
        this.setState({ imgSrc: "./img/cad_converter/mySiteThumbnail.png" });
        this.mouseEnterImg = "./img/cad_converter/mySiteThumbnailHover.png";
        this.mouseLeaveImg = "./img/cad_converter/mySiteThumbnail.png";
        break;
      case ConverterType.myPlane:
        this.type = 'my_plane';
        this.setState({ imgSrc: "./img/cad_converter/myPlanThumbnail.png" });
        this.mouseEnterImg = "./img/cad_converter/myPlanThumbnailHover.png";
        this.mouseLeaveImg = "./img/cad_converter/myPlanThumbnail.png";
        break;
      default:
        break;
    }
  }

  render = () => {
    return (
      <FileDrop
        onDrop={(files, event) => this.props.functionForDrop(files)}
        onFrameDragEnter={() => this.setState({ dragCover: false })}
        onFrameDragLeave={() => this.setState({ dragCover: true })}
        onFrameDrop={() => this.setState({ dragCover: true })}
      // //@ts-ignore
      // this.props.history.push({
      //   pathname: '/cad/mySiteblock',
      //   state: { file: files }
      // });
      // onFrameDragEnter={() => {
      //   this.setState({ dragCover: false })
      // }}
      // onFrameDragLeave={() => this.setState({ dragCover: true })}
      // onFrameDrop={() => {
      //   this.setState({ dragCover: true })
      // }}
      >
   
        <div className={`buildit-template dropFileBox ${this.props.loadFile && 'hidden'} `}>
         
          <article className="converter-type ">
            <div className="font font-special type-title">나의 {this.props.type}
              <Tooltip msg={
              <>직접 설정한 {this.props.type} CAD 파일을 '나의 {this.props.type}'에 저장하면<br />다양한 BUILDIT 서비스를 경험해 보실 수 있습니다.</>
              } arrowOn={false}>
                <div className="icon-wrap"><Help className="icon help-icon" /></div>
              </Tooltip>
            </div>

            <label className="open-btn-wrap"
              onMouseOver={() => {
                this.setState({ imgSrc: this.mouseEnterImg });
              }}
              onMouseLeave={() => {
                this.setState({ imgSrc: this.mouseLeaveImg });
              }}
            >
              <input type="file" accept='.dxf' id="ex_file" style={{ display: 'none' }} onClick={e => e.currentTarget.value = ''}
                onChange={this.props.functionForButton}
              />

              <img src={this.state.imgSrc} style={{cursor: 'pointer'}}/>
              <Button className="btn bg-navy btn-run font font-18px " component="label">
                <input type="file" accept='.dxf' id="ex_file" style={{ display: 'none' }} onClick={e => e.currentTarget.value = ''}
                  onChange={this.props.functionForButton}
                />
                {`${this.props.type} 파일 열기`}
                <FileUpload className="m-l-sm icon upload-icon" />
              </Button>
            </label>

            <Button className={'btn bg-navy btn-secondary font font-18px m-t-md'}
              onClick={() => {                
                switch (this.props.type) {
                  case ConverterType.myType:
                    if (this.props.isBlockVer) window.location.href = "../img/sample_file/my_type/나의 동평면 샘플.dxf";
                    else window.location.href = "../img/sample_file/my_type/나의 동평면 샘플 레이어.dxf";
                    break;
                  case ConverterType.mySite:
                    if (this.props.isBlockVer) window.location.href = "../img/sample_file/my_site/나의 사업영역 샘플.dxf";
                    else window.location.href = "../img/sample_file/my_site/나의 사업영역 샘플 레이어.dxf";
                    break;
                  case ConverterType.myPlane:
                    if (this.props.isBlockVer) window.location.href = "../img/sample_file/my_plan/나의 배치안 샘플.dxf";
                    else window.location.href = "../img/sample_file/my_plan/나의 배치안 샘플 레이어.dxf";
                  default:
                    break;
                }
              }}
            >{`${this.props.type} 샘플 파일 받기`}
              <FileDownload className="icon download-icon m-l-sm" /></Button>
            <span className="font font-secondary bottom-text font-14px m-t-md">또는 파일을 여기로 끌어 놓으세요.</span>
          </article>


          {/* <FileDrop onDrop={(files, event) => this.props.functionForDrop(files)}
          onFrameDragEnter={() => this.setState({ dragCover: false })}
          onFrameDragLeave={() => this.setState({ dragCover: true })}
          onFrameDrop={() => this.setState({ dragCover: true })}
        >
          <div className='cover hidden'></div>
        </FileDrop>
        <div className={`sampleExample ${this.props.showSample && 'hidden'}`}>
          <img src={`/img/sample_file/${this.type}/sample.png`} className="sampleImg" />
          <div className='sampleText' >
            <div className='title'>Welcome to BUILDIT!</div>
            <div className='content'><span style={{ color: '#48DDF2' }}>샘플 파일 다운로드 후 업로드</span> 하시면</div>
            <div className='content'> CAD Converter를 체험해 보실 수 있습니다.</div>
            <a href={`../img/sample_file/${this.type}/sample.dxf`} download='sample.dxf'>
              <div className='downloadButton'><span>샘플 DXF 파일 내려받기</span><DownloadIcon className='donwloadIcon' />
              </div>
            </a>
          </div>
        </div>
        <div className={`content ${!this.state.dragCover && 'noEvent'} ${!this.props.showSample && 'topMargin40'}`}>
          <div className='topText'>CAD 파일을 불러와서 <br />{this.props.type}을 업로드해주세요!</div>
          <Button className='btn bg-navy btn-run button' component="label">
            파일 선택
            <input type="file" id="ex_file" style={{ display: 'none' }} onChange={this.props.functionForButton} accept='.dxf' />
          </Button>
          <div className='bottomText'>또는 파일을 여기로 끌어 놓으세요.</div>
        </div> */}
          <div className={`${!this.state.dragCover && 'drag-cover'}`}></div>
        </div>
      </FileDrop>
    )
  }
}

