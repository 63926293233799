import React, { Component } from 'react';
import { brightenAllLayer, darkenAllLayer, deleteCoreFromCoreList } from './CoreAndHouseController';
import { BuildingTypeData, ConverterLayer } from './DataTypes';
import CloseIcon from '@material-ui/icons/Close'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Button } from '@material-ui/core';
import '../css/CADConverter/BuildingType.scss'
import { HouseUI } from './HouseUI';
import { CoreUI } from './CoreUI';
import { House } from './House';
import { Core } from './Core';

interface buildingTypeState {
  name: string,
  id: string,
  hideList: boolean,
  selectLayer: ConverterLayer | null,
  selectHouse: House | null,
  totalExclusiveAreas: number,
  totalServiceAreas: number,
  totalCoreAreas: number,
  buildingArea: number,
  groundArea: number,
  settingID: string,
  layers: ConverterLayer[];
}

interface buildingTypeProp {
  building: BuildingTypeData,
  selectLayer: ConverterLayer | null,
  selectHouse: House | null,
  settingID: string,
  recalculate: () => void,
  showLayerList: (id: string) => void,
  deleteBuilding: (buildingData: BuildingTypeData) => void,
  showHouseList: (id: string, houses: House[]) => void,
}

export class BuildingType extends Component<buildingTypeProp, buildingTypeState> {
  state: buildingTypeState = {
    hideList: this.props.building.hideList,
    name: this.props.building.name,
    id: this.props.building.id,
    selectLayer: null,
    selectHouse: null,
    totalExclusiveAreas: 0,
    totalServiceAreas: 0,
    totalCoreAreas: 0,
    buildingArea: 0,
    groundArea: 0,
    settingID: this.props.settingID,
    layers: [],
  }

  addHouse = () => {
    this.props.building.houses.push(new House(`세대 ${this.props.building.houses.length + 1}`));
    this.props.recalculate();
  }

  addCore = () => {
    this.props.building.cores.push(new Core(`코어 ${this.props.building.cores.length + 1}`));
    this.props.recalculate();
  }

  showHouseList = (id: string) => {
    // this.props.showHouseList(id, this.props.building.houses);
  }

  removeCoreFromCoreList = (core: Core) => {
    deleteCoreFromCoreList(this.props.building.cores, core);
    this.props.recalculate();
  }

  removeHouseFromHouseList = (house: House) => {
    house.deleteHouse();
    let index = this.props.building.houses.findIndex(h => h.id === house.id);
    this.props.building.houses.splice(index, 1);

    this.props.building.cores.forEach(c => {
      let index = c.houses.indexOf(house);
      if (index > -1) {
        c.houses.splice(index, 1);
      }
    })

    this.props.recalculate();
  }

  toogleList = () => {
    this.props.building.hideList = !this.state.hideList;
    this.setState({ hideList: !this.state.hideList })
  }

  render = () => {
    return (
      <div className='building' key={this.state.id}>
        <div className='nameDiv'>
          <div className='name' onClick={this.toogleList}>{this.state.name}{(this.state.hideList && <UpIcon />) || <DownIcon />}</div>
          <CloseIcon className='closeIcon' onClick={() => this.props.deleteBuilding(this.props.building)} />
        </div>
        <div className='Line' />
        <div className='buildingBody' hidden={this.state.hideList}>
          <div className='roomAndCore'>
            <div className='list'>{this.props.building.houses.map(h =>
              <HouseUI
                key={h.id}
                showLayerList={this.props.showLayerList}
                house={h}
                selectLayer={this.props.selectLayer}
                settingID={this.props.settingID}
                removeHouse={this.removeHouseFromHouseList}
                valueChanged={this.props.recalculate}
                isSettingLevel={true}
                editable={true}
                showModel={() => { }}
                brightenAllLayer={() => brightenAllLayer(this.state.layers)}
                darkenAllLayer={() => darkenAllLayer(this.state.layers)}
              />
            )}
            </div>
            <Button onClick={this.addHouse}>+ 세대 추가</Button>

            <div className='list'>{this.props.building.cores.map(c =>
              <CoreUI
                key={c.id}
                Core={c}
                showLayerList={this.props.showLayerList}
                selectLayer={this.props.selectLayer}
                removeCore={this.removeCoreFromCoreList}
                valueChanged={this.props.recalculate}
                settingID={this.props.settingID}
                showHouseList={this.showHouseList}
                selectHouse={this.props.selectHouse}
                editable={true}
                showModel={() => { }}
              />)}
            </div>
            <Button onClick={this.addCore}>+ 코어 추가</Button>
          </div>
        </div>
      </div >
    )
  }
}
