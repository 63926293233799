// detialSetting
import React, { Component } from 'react';
import Info from '@material-ui/icons/InfoOutlined';
import { updateDynamoDBData, getSettingData } from './DBManager';
import { myTypeSettingData } from './detialSetting';



interface myTypeSettingProps {
  myTypeSettingData: myTypeSettingData,
}

interface myTypeSettingState {
  windowOffsetEnable: boolean,
  windowOffsetValue: number,
  areaOffsetEnable: boolean,
  areaOffsetValue: number,
  layerOffsetEnable: boolean,
  layerOffsetValue: number,
  layerOverlapEnable: boolean,
  layerOverlapValue: number,
}

export class MyTypeSettingDataUILayer extends Component<myTypeSettingProps, myTypeSettingState>{

  state: myTypeSettingState = {
    areaOffsetEnable: this.props.myTypeSettingData.areaOffset.enable,
    areaOffsetValue: this.props.myTypeSettingData.areaOffset.value,
    layerOffsetEnable: this.props.myTypeSettingData.layerOffset.enable,
    layerOffsetValue: this.props.myTypeSettingData.layerOffset.value,
    layerOverlapEnable: this.props.myTypeSettingData.layerOverlap.enable,
    layerOverlapValue: this.props.myTypeSettingData.layerOverlap.value,
    windowOffsetEnable: this.props.myTypeSettingData.windowOffset.enable,
    windowOffsetValue: this.props.myTypeSettingData.windowOffset.value,
  }

  render = () => {
    return (
      <React.Fragment>
        <div className='item'>
          <div className='mianContent'>
            <span style={{ width: '217px', display: 'flex' }}><span className='name'>창, 세대 레이어 간 이격 거리</span><Info className='icon' /></span>
            <input className='inputBox' type='number' value={this.state.windowOffsetValue} onChange={e => { this.setState({ windowOffsetValue: Number(e.target.value) }); this.props.myTypeSettingData.updateWindowOffsetValue(Number(e.target.value)) }} />
            <span className={`text ${!this.state.windowOffsetEnable && 'active'}`} style={{ margin: 'auto auto auto 9px' }}>mm 초과 시 알림</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.windowOffsetEnable} onClick={(e) => { this.setState({ windowOffsetEnable: e.currentTarget.checked }); this.props.myTypeSettingData.updateWindowOffsetEnable(e.currentTarget.checked) }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.windowOffsetEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>항상 알림</span>
          </div>
          <div className={`description ${!this.state.windowOffsetEnable && 'hidden'}`}><span>이격 거리가 보정되지 않습니다.</span></div>
        </div>
        <div className='item'>
          <div className='mianContent'>
            <span style={{ width: '217px', display: 'flex' }}><span className='name'>입력 면적, 계산 면적의 오차</span><Info className='icon' /></span>
            <input className='inputBox' type='number' value={this.state.areaOffsetValue} onChange={e => { this.setState({ areaOffsetValue: Number(e.target.value) }); this.props.myTypeSettingData.updateAreaOffsetValue(Number(e.target.value)) }} />
            <span className={`text ${!this.state.areaOffsetEnable && 'active'}`} style={{ margin: 'auto auto auto 9px' }}>% 초과 시 알림</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.areaOffsetEnable} onClick={(e) => { this.setState({ areaOffsetEnable: e.currentTarget.checked }); this.props.myTypeSettingData.updateAreaOffsetEnable(e.currentTarget.checked) }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.areaOffsetEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>항상 알림</span>
          </div>
          <div className={`description ${!this.state.areaOffsetEnable && 'hidden'}`}><span>면적의 오차가 보정되지 않습니다.</span></div>
        </div>
        {/* <div className='item'>
          <div className='mianContent'>
            <span style={{ width: '217px', display: 'flex' }}><span className='name'>레이어 간의 이격 거리</span><Info className='icon' /></span>
            <input className='inputBox' type='number' value={this.state.layerOffsetValue} onChange={e => { this.setState({ layerOffsetValue: Number(e.target.value) }); this.props.myTypeSettingData.updateLayerOffsetsetValue(Number(e.target.value)) }} />
            <span className={`text ${!this.state.layerOffsetEnable && 'active'}`} style={{ margin: 'auto auto auto 9px' }}>mm 초과 시 알림</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.layerOffsetEnable} onClick={(e) => { this.setState({ layerOffsetEnable: e.currentTarget.checked }); this.props.myTypeSettingData.updateLayerOffsetEnable(e.currentTarget.checked) }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.layerOffsetEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>항상 알림</span>
          </div>
          <div className={`description ${!this.state.layerOffsetEnable && 'hidden'}`}><span>레이어 간의 이격 거리가 보정되지 않습니다.</span></div>
        </div> */}
        <div className='item'>
          <div className='mianContent'>
            <span style={{ width: '217px', display: 'flex' }}><span className='name'>레이어 간 교차</span><Info className='icon' /></span>
            <input className='inputBox' type='number' value={this.state.layerOverlapValue} onChange={e => { this.setState({ layerOverlapValue: Number(e.target.value) }); this.props.myTypeSettingData.updateLayerOverlapsetValue(Number(e.target.value)) }} />
            <span className={`text ${!this.state.layerOverlapEnable && 'active'}`} style={{ margin: 'auto auto auto 9px' }}>% 초과 시 알림</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.layerOverlapEnable} onClick={(e) => { this.setState({ layerOverlapEnable: e.currentTarget.checked }); this.props.myTypeSettingData.updateLayerOverlapEnable(e.currentTarget.checked) }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.layerOverlapEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>항상 알림</span>
          </div>
          <div className={`description ${!this.state.layerOverlapEnable && 'hidden'}`}><span>이격 거리가 보정되지 않습니다.</span></div>
        </div>
      </React.Fragment>
    )
  }
}