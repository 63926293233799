import DxfParser, { dxf } from '@teneleven/dxf-parser/src';
import { entity } from '@teneleven/dxf-parser/src';
import { ConverterEntity, ConverterLayer, entityType, Polygon, Unit, BlockType, ConverterInsert, ConverterUnit, ConverterType } from './DataTypes';
import * as THREE from '@teneleven/three';
import _, { partition } from 'lodash';
import iconv from 'iconv-lite';
import * as turf from '@turf/turf';
import { LineGeometry } from '@teneleven/three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from '@teneleven/three/examples/jsm/lines/LineMaterial';
import { Line2 } from '@teneleven/three/examples/jsm/lines/Line2';
import { BuildingGroup } from './BuildingGroup';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { ConverterBlock } from './ConverterBlock';
import { BlockParsingData, BuildingPart, ConverterBuilding, ConverterField, ConverterMap } from './BuildingPart';
import { getS3Json } from '../Viewer/DBManager';
import { FieldType } from './Field';
import { userSettingData, Setting } from './SettingModal';
import * as jsts from 'jsts';
import { ErrorLogCell2 } from './ErrorLog';
import { ContactSupportOutlined, FeaturedVideoTwoTone } from '@material-ui/icons';

import App from '../App';
import { ErrorList } from './Error';

const earcut = require('earcut');

export function makePolygon(vertices: THREE.Vector3[], color: THREE.Color, type: entityType, shape: boolean, vertsForArea?: THREE.Vector3[], innerMeshColor?: THREE.Color): Polygon {
  
  let vertsArea = new Array<number>();
  let turfVerts = new Array<number[]>();
  let area = 0;
  let hasCurve = false;
  let innerMesh = new THREE.Mesh();

  let box = new THREE.Box2();
  let boxSize = new THREE.Vector2();
  vertices.forEach(v => {
    vertsArea.push(v.x, v.y);
    turfVerts.push([v.x, v.y]);
    box.expandByPoint(new THREE.Vector2(v.x, v.y));
    if (v.z !== 0)
      hasCurve = true;
  });
  box.getSize(boxSize)

  if (vertices.length > 2) {
    let triangles = earcut(vertsArea);
    for (let i = 0; i < triangles.length; i += 3) {
      area += new THREE.Triangle(vertices[triangles[i]], vertices[triangles[i + 1]], vertices[triangles[i + 2]]).getArea();
    }

    const geo = new THREE.Geometry();

    geo.vertices = vertices;
    for (let i = 0; i < triangles.length; i += 3) {
      geo.faces.push(new THREE.Face3(triangles[i], triangles[i + 1], triangles[i + 2]));

      geo.faceVertexUvs[0].push([
        new THREE.Vector2(0, 0),
        new THREE.Vector2(0, 1),
        new THREE.Vector2(1, 0),
      ]);
    }

    geo.computeFaceNormals();

    innerMesh.geometry = geo;    
    new THREE.Color(1, 1, 1);

  
    innerMesh.material = new THREE.MeshBasicMaterial({ color: innerMeshColor ? innerMeshColor: color, opacity: 0.5, transparent: true });
    innerMesh.visible = false;
   // innerMesh.visible = true;
    
  }

  let meshVerts: THREE.Vector3[] = [];
  if (turf.booleanClockwise(turf.lineString(turfVerts))) {
    for (let i = vertices.length - 1; i >= 0; i--) {
      meshVerts.push(vertices[i].clone());
    }
  }
  else {
    vertices.forEach(v => {
      meshVerts.push(v.clone());
    });
  }

  let vertsGeo = new Array<number>();
  let colorGeo = new Array<number>();
  meshVerts.forEach(v => {
    vertsGeo.push(v.x, v.y, v.z);
    colorGeo.push(1, 1, 1);
  })

  let geometry = new LineGeometry();
  geometry.setPositions(vertsGeo);
  geometry.setColors(colorGeo);
  
  
  let matLine = new LineMaterial({
    linewidth: 4, // in pixels
    vertexColors: true,
    dashed: false,
    dashSize: 1,
    gapSize: 1,
    dashScale: 2,
  });  
  matLine.resolution.set(window.innerWidth, window.innerHeight);
  matLine.transparent = true;

  let line = new Line2(geometry, matLine).computeLineDistances();
  
  //@ts-ignore
  line.material.color = color;
  //@ts-ignore
  line.material.opacity = 0.2;

  line.renderOrder = -1;
  let polygonShape = shape;
  if (vertices[0].distanceTo(vertices[vertices.length - 1]) < 0.00001 && vertices.length >= 4)
    polygonShape = true;

  if (shape) {
    vertices[0] = vertices[vertices.length - 1];
  }    

  return ({
    lineMesh: line,
    vertices: meshVerts,
    type: type,
    selected: false,
    area: vertsForArea ? getCalcArea(vertsForArea): area,
    shape: polygonShape,
    hasCurve: hasCurve,
    innerMesh: innerMesh,
    layer: "",
  })
}

export function getCalcArea(vertices: THREE.Vector3[]) { // 계산 면적
  let vertsArea = new Array<number>();
  let turfVerts = new Array<number[]>();
  let area = 0;

  vertices.forEach(v => {
    vertsArea.push(v.x, v.y);
    turfVerts.push([v.x, v.y]);
  });
  
  if (vertices.length > 2) {
    let triangles = earcut(vertsArea);
    for (let i = 0; i < triangles.length; i += 3) {
      area += new THREE.Triangle(vertices[triangles[i]], vertices[triangles[i + 1]], vertices[triangles[i + 2]]).getArea();
    }
  }
  
  return  area;
}

export function switchLineDashedState(material: LineMaterial, dashed: boolean) {
  material.dashed = dashed;

  if (material.dashed) {
    material.defines.USE_DASH = "";
  }
  else {
    delete material.defines.USE_DASH;
  }
  material.needsUpdate = true;
}

export function checkHolePolygon(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      l.polygons.forEach(hp => {
        if (p !== hp) {
          if (p.area > hp.area) {
            if (p.shape && hp.vertices.length > 3 && polygonInOtherPolygon(p.vertices, hp.vertices)) {
              hp.motherPolygon = p;
              hp.area *= -1;
            }
          }
        }
      });
    });
  });
}

function polygonInOtherPolygon(vertices1: THREE.Vector3[], vertices2: THREE.Vector3[]) {
  let coord: number[][] = [];
  vertices1.forEach(v => {
    coord.push([v.x, v.y]);
  })

  var polygon = turf.polygon([coord]);
  let v2inv1 = turf.inside([vertices2[0].x, vertices2[0].y], polygon);

  vertices2.forEach(v => {
    v2inv1 = turf.inside([v.x, v.y], polygon) && v2inv1;
  });

  return v2inv1;
}

export function getScale(unit: Unit) {
  let scale = 0.001;

  switch (unit) {
    case Unit.Millimeters:
      scale = 0.001;
      break;
    case Unit.Meters:
      scale = 1;
      break;
    case Unit.Inches:
      scale = 39.3701;
    default:
      break;
  }
  return scale;
}

export function dataParsing(data: string, unit: Unit = Unit.Millimeters) {
  let parser = new DxfParser();

  let dxf = parser.parseSync(data);
  let entities = dxf.entities;

  let scale = getScale(unit); // mm -> m
  let layers = dxf.tables['layer'].layers;
  let layerArray = new Array<ConverterLayer>();
  // App.stage !== "prod" && console.log(dxf.tables['layer'].layers);
  //get layers
  _.forEach(dxf.tables['layer'].layers, (v, k) => {
    layerArray.push({
      name: v.name,
      color: v.color,
      colorIndex: v.colorIndex,
      frozen: v.frozen,
      visible: v.visible,
      polygons: [],
      selected: false,
      isSinglePolygon: false,
      z_index: 0,
      errorLayer: false,
    });
  });

  let bbox = new THREE.Box2();
  entities.forEach(e => {
    if (e.vertices) {
      e.vertices.forEach(v => {
        bbox.expandByPoint(new THREE.Vector2(v.x, v.y));
      });
    }
  })

  let center = new THREE.Vector2();
  bbox.getCenter(center);
  center.multiplyScalar(scale);

  //get polygons
  entities.forEach(e => {
    let verts = [];
    let l = layerArray.find(layer => layer.name === e.layer);
    let entitiesBbox = new THREE.Box2();
    let boxSize = new THREE.Vector2();
    switch (e.type) {
      case entityType.LWPOLYLINE:
      case entityType.POLYLINE:
        
        for (let j = 0; j < e.vertices.length; j++) {
          let x = Number((e.vertices[j].x * scale - center.x).toFixed(4));
          let y = Number((e.vertices[j].y * scale - center.y).toFixed(4));
          verts.push(new THREE.Vector3(x, y, e.vertices[j].bulge));
          entitiesBbox.expandByPoint(new THREE.Vector2(x, y));
        }
        if (e.shape) {
          let x = Number((e.vertices[0].x * scale - center.x).toFixed(4));
          let y = Number((e.vertices[0].y * scale - center.y).toFixed(4));
          verts.push(new THREE.Vector3(x, y, e.vertices[0].bulge));
          entitiesBbox.expandByPoint(new THREE.Vector2(x, y));
        }
        entitiesBbox.getSize(boxSize);
        if (l && (boxSize.x > 0.0001 || boxSize.y > 0.0001)) {
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape ? true : false));
        }
        break;

      case entityType.LINE:
        for (let j = 0; j < e.vertices.length; j++) {
          verts.push(new THREE.Vector3(Number((e.vertices[j].x * scale - center.x).toFixed(4)), Number((e.vertices[j].y * scale - center.y).toFixed(4)), e.vertices[j].bulge));
        }
        if (l && verts[0].distanceTo(verts[1]) > 0.001)
        { l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape));
        }
        break;

      default:
       // console.log(e);
        break;
    }

    
  });

  checkHolePolygon(layerArray);
  for (let i = 0; i < layerArray.length;) {
    if (layerArray[i].polygons.length === 0) {
      layerArray.splice(i, 1);
    }
    else {
      i++;
    }
  }
  return layerArray.sort((a, b) => a.name.localeCompare(b.name));
}

export function parsingUpdateFile(data: string) {
  let parser = new DxfParser();

  let dxf = parser.parseSync(data);
  let entities = dxf.entities;
  let layerArray = new Array<ConverterLayer>();

  //get layers
  _.forEach(dxf.tables['layer'].layers, (v, k) => {
    if (v.name === 'BOUNDARY' ||
      v.name === 'BUILDING' ||
      v.name === 'FIELD' ||
      v.name === 'text' ||
      v.name === '0') {
      console.log('무시 하는 레이어' + v.name);
    }
    else
      layerArray.push({
        name: v.name,
        color: v.color,
        colorIndex: v.colorIndex,
        frozen: v.frozen,
        visible: v.visible,
        polygons: [],
        selected: false,
        isSinglePolygon: false,
        z_index: 0,
        errorLayer: false,
      });
  });

  let bbox = new THREE.Box2();
  entities.forEach(e => {
    if (e.vertices) {
      e.vertices.forEach(v => {
        bbox.expandByPoint(new THREE.Vector2(v.x, v.y));
      });
    }
  })

  remakeEntities(entities, dxf, layerArray);

  return layerArray;
}

function remakeEntities(entities: entity[], dxf: dxf, layerArray: ConverterLayer[], position: THREE.Vector3 = new THREE.Vector3(0)) {
  let layers = dxf.tables['layer'].layers;
  let blocks = dxf.blocks;

  entities.forEach(e => {
    let verts = [];
    let l = layerArray.find(layer => layer.name === e.layer);
    let entitiesBbox = new THREE.Box2();
    let boxSize = new THREE.Vector2();
    switch (e.type) {
      case entityType.LWPOLYLINE:
      case entityType.POLYLINE:
        for (let j = 0; j < e.vertices.length; j++) {
          let x = Number((e.vertices[j].x).toFixed(4));
          let y = Number((e.vertices[j].y).toFixed(4));
          verts.push(new THREE.Vector3(x + position.x, y + position.y, e.vertices[j].bulge));
          entitiesBbox.expandByPoint(new THREE.Vector2(x + position.x, y + position.y));
        }
        if (e.shape) {
          let x = Number((e.vertices[0].x).toFixed(4));
          let y = Number((e.vertices[0].y).toFixed(4));
          verts.push(new THREE.Vector3(x + position.x, y + position.y, e.vertices[0].bulge));
          entitiesBbox.expandByPoint(new THREE.Vector2(x + position.x, y + position.y));
        }
        entitiesBbox.getSize(boxSize);
        if (l && (boxSize.x > 0.0001 || boxSize.y > 0.0001)) {
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape ? true : false));
        }
        break;

      case entityType.LINE:
        for (let j = 0; j < e.vertices.length; j++) {
          verts.push(new THREE.Vector3(Number((e.vertices[j].x + position.x).toFixed(4)), Number((e.vertices[j].y + position.y).toFixed(4)), e.vertices[j].bulge));
        }
        if (l && verts[0].distanceTo(verts[1]) > 0.001)
          l.polygons.push(makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape));
        break;
      case entityType.INSERT:
        //@ts-ignore
        remakeEntities(blocks[e.name].entities, dxf, layerArray, e.position);
        break;
      default:
        console.log(e);
        break;
    }
  });
}

export async function asyncFileRead(fl: FileList) {
  let reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    if (fl[0]) {
      reader.readAsArrayBuffer(fl[0]);
      reader.onload = function () {
        // @ts-ignore 
        let data = iconv.decode(Buffer.from(this.result), 'utf-8');
        resolve(data);
      };
    }
  });
}

export async function asyncOneFileRead(file: any) {
  let reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.readAsArrayBuffer(file);
    reader.onload = function () {
      // @ts-ignore 
      let data = iconv.decode(Buffer.from(this.result), 'utf-8');
      resolve(data);
    };
  });
}

function makeInsertEntity(entity: any, color?: any, unitScale?: any) {
  let position = new THREE.Vector3(0);
  // let vertScale = getScale(dataUnit); // mm -> m


  if (entity.position) {
    position = new THREE.Vector3(entity.position.x, entity.position.y, entity.position.z);
  }

  let scale = new THREE.Vector3(0);
  scale.x = entity.xScale ? entity.xScale : 1;
  scale.y = entity.yScale ? entity.yScale : 1;
  scale.z = entity.zScale ? entity.zScale : 1;

  if (entity.scale)
    scale = entity.scale;

  // 잘못해서 폴리곤이 들어있는경우
  let line = undefined;
  if (entity.type === "LWPOLYLINE" || entity.type === "POLYLINE") {
    let verts: THREE.Vector3[] = [];
      
    entity.vertices.forEach((v: any) => {
    if (v.bulge) v.z = v.bulge; // 라운드 에러
      verts.push(new THREE.Vector3(v.x, v.y, v.z ? v.z : 0));
    })
  
    if (entity.shape)
      verts.push(verts[0]);
    
      
    line = makePolygon(verts, new THREE.Color().set(color), entity.type, entity.shape ? true : false);

    // innerMesh.material = new THREE.MeshBasicMaterial({ color: color, opacity: 0.5, transparent: true });

  }
  
  let insert: ConverterInsert = {
    layer: entity.layer,
    position: position,
    scale: scale,
    rotate: entity.rotation ? entity.rotation : 0,
    type: entity.type,
    name: entity.name,
    polygon: line !== undefined ? line : undefined,  
  }

  return insert
}

function makeUnitEntit(entity: any, color: number | string, unitScale: number, blockName?: string, converterType?: ConverterType) {
  
  let verts: THREE.Vector3[] = [];
  // let scale = getScale(dataUnit); // mm -> m
  let vertsForArea: THREE.Vector3[] = [];
  let editedShape = false;
  let hasZCoord = false;
  
  entity.vertices.forEach((v: any) => {
    // let x = Number((e.vertices[0].x * scale - center.x).toFixed(4));
    // let y = Number((e.vertices[0].y * scale - center.y).toFixed(4))

    if (v.z) {
      hasZCoord = true;      
    }
    if (v.bulge) v.z = v.bulge; // 라운드 에러
    verts.push(new THREE.Vector3(v.x, v.y, v.bulge ? v.bulge : 0)); // v.z ? v.z : 0
    vertsForArea.push(new THREE.Vector3(v.x * unitScale, v.y * unitScale, v.z ? v.z * unitScale : 0)); // 면적 계산용 verts
  })
  
  if (verts.length >= 2 && verts[0].x === verts[verts.length-1].x &&verts[0].y === verts[verts.length-1].y && verts[0].z === verts[verts.length-1].z ) {
    entity.shape = true;
  }
  
  if (entity.layer.toUpperCase() === "CON" || converterType === ConverterType.mySite || (converterType === ConverterType.myPlane && !entity.layer.toUpperCase().startsWith("WIN"))) {
    //@ts-ignore
    if (entity.shape === false) {
      // 떨어진 점 연결
      let v1 = verts[0];
      let v2 = verts[verts.length - 1];
    
      if (userSettingData.myTypeSettingData.unClosedPolygon.enable && v1.distanceTo(v2) <= 1) { // 거리가 1mm이하인 점은 연결 시킴
        
        let line = new jsts.geom.LineSegment(new jsts.geom.Coordinate(verts[0].x, verts[0].y), new jsts.geom.Coordinate(verts[1].x, verts[1].y));
        let line2 = new jsts.geom.LineSegment(new jsts.geom.Coordinate(verts[verts.length-1].x, verts[verts.length-1].y), new jsts.geom.Coordinate(verts[verts.length-2].x, verts[verts.length-2].y));
        let vertice = line.lineIntersection(line2);
        if (vertice) {
          verts[0] = new THREE.Vector3(vertice.x, vertice.y, 0);    
          vertsForArea[0] = new THREE.Vector3(vertice.x, vertice.y, 0);    
          verts[verts.length-1] = new THREE.Vector3(vertice.x, vertice.y, 0);        
          vertsForArea[vertsForArea.length-1] = new THREE.Vector3(vertice.x, vertice.y, 0);     
        }
        verts.push(verts[0]);
        vertsForArea.push(vertsForArea[0]);
        entity.shape = true;
        editedShape = true;
      }
    }
  }
  if (entity.shape)
    verts.push(verts[0]);

  // 레이어 색 변경
  if (entity.layer) {
    let name = entity.layer.toUpperCase();
    if (name === "CON")
      color ="#95E4B3";// light green
    else if (name === "WIN1")
      color = "#0038FF"; // blue
    else if (name === "WIN2")
      color = "#8F00FF"; // purple
  }

  let meshColor = undefined;
  if (blockName) {
    if (/^C/i.test(blockName)) {   
      // 코어 mesh 색상
      meshColor = new THREE.Color().set("#246D77");
    }
    else if (/^U/i.test(blockName)) {
      // 세대 mesh 색상      
      meshColor = new THREE.Color().set("#95E4B3");
    }  
  }

  let polygon = makePolygon(verts, new THREE.Color().set(color), entity.type, entity.shape ? true : false, vertsForArea);
  
  let unit: ConverterUnit = {
    layer: entity.layer.toUpperCase(),
    polygon: makePolygon(verts, new THREE.Color().set(color), entity.type, entity.shape ? true : false, vertsForArea, meshColor),
    type: entity.type,
    verts: verts,
    color: color,
    shape: entity.shape ? true : false,
    editedShape,
    hasZCoord,
  }
  
  return unit;
}


function makeBuilding(e: ConverterEntity, blocks: ConverterBlock[]) {
  //@ts-ignore
  let block = blocks.find(cb => cb.name === e.name);
  
  let pos = new THREE.Vector3(0);
  let scale = new THREE.Vector3(0);
  let rotate = 0;
  let name = '';
  
  if (block) {
    //@ts-ignore
    if (e.type === entityType.INSERT) {
      pos = (e as ConverterInsert).position;
      scale = (e as ConverterInsert).scale;
      rotate = (e as ConverterInsert).rotate;
      name = (e as ConverterInsert).name;
    }

    if (block.type === BlockType.group) {
      let newPart = new BuildingGroup(block!);
      newPart.SetPosition(pos);
      newPart.SetScale(scale);
      newPart.RotateWithDegrees(rotate);
      newPart.SetName(name);
      let polygon: Polygon[] = [];
      newPart.polygon = polygon;
      
      //B블록에 있는 폴리곤 그리기
      block!.entities.forEach(en => { 
        if (en.type === "LWPOLYLINE" || en.type === "POLYLINE") {
          //@ts-ignore
          en.polygon.layer = en.layer;
          if (!newPart.name.toUpperCase().startsWith("B") || !newPart.name.toUpperCase().startsWith("F")) { // 블록이름이 B/F 블록이 아닌 경우
            let p = (en as ConverterUnit).polygon;
            let newLineMesh = p.lineMesh.clone(); // material 참조방지
            newLineMesh.material = p.lineMesh.material.clone();
            let newPolygon: Polygon = {
              area: p.area,
              hasCurve: p.hasCurve,
              innerMesh: p.innerMesh.clone(),
              lineMesh: newLineMesh,
              selected: p.selected,
              shape: p.shape,
              type: p.type,
              vertices: p.vertices,
              layer: en.layer,
            }
            polygon.push(newPolygon)
            let line = newPolygon.lineMesh.clone();
            newPart.renderGroup.add(line); // group에 있는 폴리라인 추가
          }
          else 
          if (/^WIN[1-2]$/i.test(en.layer)) { // 창문 레이어라면
            newPart.renderGroup.add((en as ConverterInsert).polygon.lineMesh); // group에 있는 폴리라인 추가
          }
        }
        let compenent = makeBuilding(en, blocks);
        
        if (compenent) {
          newPart.AddNewPart(compenent);
          newPart.renderGroup.add(compenent.renderGroup);
        }
      })
      
      return newPart;
    }
    else if (block.type === BlockType.house) {         
      let house = new BuildingHouseUnit(block!);
      
      house.SetPosition(pos);
      house.SetScale(scale);
      house.RotateWithDegrees(rotate);
      house.SetName(name);
      let splits = name.split('_');
      house.SetExclusiveArea(Number(splits[1]) ? Number(Number(splits[1]).toFixed(2)) : 0);
      house.SetServiceArea(Number(splits[2]) ? Number(Number(splits[2]).toFixed(2)) : 0);
      house.SetCommonWallArea(Number(splits[3]) ? Number(Number(splits[3]).toFixed(2)) : 0);
      house.SetInitialArea({
        exclusiveArea: Number(splits[1]) ? Number(splits[1]) : 0,
        serviceArea: Number(splits[2]) ? Number(splits[2]) : 0,
        commonWallArea: Number(splits[3]) ? Number(splits[3]) : 0, 
      });
      return house;
    }
    else {
      let core = new BuildingCoreUnit(block!);
      core.SetPosition(pos);
      core.SetScale(scale);
      core.RotateWithDegrees(rotate);
      core.SetName(name);

      let splits = name.split('_');
      core.SetArea(Number(splits[1]) ? Number(Number(splits[1]).toFixed(2)) : 0);
      core.SetInitialArea(Number(splits[1]) ? Number(splits[1]) : 0);

      return core;
    }
  }
}

export function cutMultipleFields(type: FieldType, outputData: BlockParsingData, newField: ConverterField) {
  // 도로가왔는데 인접대지잇으면 등록x
  // let centerLine = outputData.fields.filter(f => f.typeName === FieldType.centerLineOfRoad);
  // if (centerLine.length >= 1) {
  //   return;
  // }
  // 하나만 남게
  if (outputData.fields.filter(f => f.typeName === type).length === 0) {
    outputData.fields.push(newField);
  }
  else {
    outputData.fields.forEach((oldField, i) => {
      if (oldField.typeName === type) {        
        if (oldField.getArea() < newField.getArea()) {
          outputData.fields.splice(i, 1);
          outputData.fields.push(newField);
        }
        else if (oldField.getArea() === newField.getArea()) {
          outputData.fields.push(newField);
        }
        // else if (oldField.getArea() === newField.getArea()) {
        //   outputData.fields.splice(i, 1);
        //   outputData.fields.push(newField);
        //   oldField.isMultiple = true;
        // }
        else {
          oldField.isMultiple = true;
        }
      }
    })
  }
  // if (type === FieldType.centerLineOfRoad) { // 인접대지경계선
  //   // road 삭제
  //   outputData.fields.forEach((oldField, i) => {
  //     if (oldField.typeName === FieldType.road) {
  //       outputData.fields.splice(i, 1);
  //     }
  //   })
  // }

}


export function checkFieldBlockName(name: string, fieldType: FieldType) {
  name = name.toUpperCase();
  
  if (name.indexOf(" _") > -1 || name.indexOf("_ ") > -1) return false;

  switch (fieldType) {
    case FieldType.site:
      //SA숫자_면적
      if (/^[S][A](\d+)$/.test(name) || (/^[S][A](\d+)[_]/i.test(name) && name.split('_').length === 2 && name.split('_')[1] !== '' && !isNaN(Number(name.split('_')[1])))) {
        return true;
      }
      return false;
    case FieldType.road:
      if (/^[R][A](\d+)$/i.test(name)) return true;
      return false;
    case FieldType.vacancyOutside:
      if (/^[G][A](\d+)$/i.test(name)) return true;
      return false;
    case FieldType.vacancyInside:
      if (/^[L][A](\d+)$/i.test(name)) return true;
      return false;
    case FieldType.centerLineOfRoad:
      if (/^[A][A](\d+)$/i.test(name)) return true;  
      return false;
    case FieldType.topography:
      //EL숫자_높이
      if (/^[E][L](\d+)$/.test(name) || (/^[E][L](\d+)[_]/i.test(name) && name.split('_').length === 2 && !isNaN(Number(name.split('_')[1])))) { 
        return true;
      }
      return false;
    default:
      return false;
  }
  
}

function autoFixBlockName(name: string, type: FieldType) {  
  let newName = '';
  if (name.split('_').length === 2 && Number(name.split('_')[1])) {
    let number;
    if (type === FieldType.road) {
      number = name.replace(/[R][A]/ig, '').split('_')[0];
      newName = `RA${number}`;
    }
    else if (type === FieldType.vacancyInside) {
      number = name.replace(/[L][A]/ig, '').split('_')[0];
      newName = `LA${number}`;
    }
    else if (type === FieldType.vacancyOutside) {
      number = name.replace(/[G][A]/ig, '').split('_')[0];
      newName = `GA${number}`;
    }
    else if (type === FieldType.centerLineOfRoad) {
      number = name.replace(/[A][A]/ig, '').split('_')[0];      
      newName = `AA${number}`;
    }
  }
  return newName;
}

export function blockParsing<T>(data: string, outputData: BlockParsingData, converterType: ConverterType, layerOfPoint: Set<T>) {
  let parser = new DxfParser();
  const dataUnit = Unit.Millimeters;
  let dxf = parser.parseSync(data);
  let blocks = dxf.blocks; // B1, D1, C1, H1
  let layers = dxf.tables['layer'].layers; // CON, WIN1, WIN2


  let entities = dxf.entities;
  let unitScale = getScale(dataUnit); // mm -> m
  App.stage !== "prod" && console.log(dxf, 'dxf');

  let converterBlocks: ConverterBlock[] = [];


  if (blocks !== undefined) {
    for (const block of Object.values(blocks)) {

      let type = BlockType.group;
      if (block.name.indexOf('*') === -1) {
        let entities: ConverterEntity[] = [];

        if (block.entities) {

          block.entities.forEach(e => {
            switch (e.type) { // entities[0]의 타입으로해서에러
              case 'INSERT':
                entities.push(makeInsertEntity(e, layers[e.layer].color, unitScale)); // D, B
                break;
              case 'LWPOLYLINE':
              case 'LINE':
              case 'POLYLINE':
                let name = e.layer.toUpperCase();
                if (converterType === ConverterType.mySite) {
                  entities.push(makeUnitEntit(e, layers[e.layer].color, unitScale,'', converterType));
                }
                else if (converterType === ConverterType.myType) {
                  // if (errorList) {
                  //   if (name !== "CON" && name !== "WIN1" && name !== "WIN2") {
                  //     errorNameLayers.add(e.layer);
                  //   }
                  // }
                  entities.push(makeUnitEntit(e, layers[e.layer].color, unitScale, block.name)); // C, U
                }
                else if (converterType === ConverterType.myPlane) {
                  entities.push(makeUnitEntit(e, layers[e.layer].color, unitScale, block.name, converterType));
                }
                break;
              case 'POINT':
                layerOfPoint.add(e.layer);
                

                // 보정알람
                  // if (errorList) {
                  //   if (errorList.pointError.indexOf(e.layer) === -1) {
                  //     errorList.pointError.push(e.layer);
                  //   }
                  // } // block.name에 vetex가 있습니다.
                break;
              default:
                break;
            }
          })
          switch (block.entities[0].type) {
            case 'INSERT':
              type = BlockType.group;
              break;
            case 'LWPOLYLINE':
            case 'POLYLINE':
            case 'LINE':           
              if (block.name.indexOf('C') > -1 || block.name.indexOf('c') > -1 )
                type = BlockType.core;
              else if ( block.name.indexOf('U') > -1  || block.name.indexOf('u') > -1)
                type = BlockType.house;
                  //..
              break;
            default:
              break;
          }
        }
  
        let newBlock = new ConverterBlock();
        newBlock.entities = entities;
        newBlock.name = block.name;
        newBlock.name2 = block.name2;
        newBlock.layer = block.layer;
        //@ts-ignore
        newBlock.position = new THREE.Vector3(block.position.x, block.position.y, block.position.z);
        newBlock.type = type;
        converterBlocks.push(newBlock);
  
  
      }
    }

  
    // 이름이 같은 entity 삭제
    if (converterType !== ConverterType.myType) {
      const nameSet = new Map();
      for (let i = 0; i < entities.length; i++) {
        const e = entities[i];
        
        //@ts-ignore
        if (e.name) {
          //@ts-ignore
          const name = e.name;
          //@ts-ignore
          if (name && !/^[U]/i.test(name) && !/^[C]/i.test(name) && !/^[F]/i.test(name) && !/^[B]/i.test(name)) {
            const nameCnt = nameSet.get(name);
            if (nameCnt) {
              nameSet.set(name, nameCnt + 1);
              entities.splice(i, 1);
              i--;
            }
            else nameSet.set(name, 1);
          }
        }
      }
    }
  
    const wrongBlockEntity: Array<any> = [];
    const fieldBlockCnt = {
      site: 0,
      road: 0,
      vacancyOutside: 0,
      vacancyInside: 0,
      centerRoadLine: 0,
      topography: 0,
    };
    entities.forEach(e => {
      let hasSpecialChar = false;
      //@ts-ignore
      if (e.name) {
        const regExp = /[\{\}\[\],;:\)*~`!^\-+@\#$%&\=\(\'\"]/gi;
        //@ts-ignore
        if(regExp.test(e.name)) { hasSpecialChar = true; }
        else{ hasSpecialChar = false; }
      }
      //TODO
      //사업영역 e.type- polyline, lwpolyline, line만 허용
      //@ts-ignore
      if (e.name && checkFieldBlockName(e.name, FieldType.site) && !hasSpecialChar) { // site
        //@ts-ignore
        let splitedName = e.name.toUpperCase().split('_');
        if (splitedName.length <= 2) {
          
          //@ts-ignore
          let block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            let field = new ConverterField(block, FieldType.site);
            //@ts-ignore
            if (e.position) {
              //@ts-ignore
              field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
            }
            //@ts-ignore
            if (e.xScale) {
              //@ts-ignore
              field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
          }
            //@ts-ignore
            if (e.rotation) {
              //@ts-ignore
              field.renderGroup.rotateZ(turf.degrees2radians(e.rotation));
            }
  
            //@ts-ignore
            let names = e.name.split('_');
            if (names.length === 2 && !isNaN(splitedName[1])) { // S_, S_면적
              field.setArea(Number((Math.floor(names[1] * 10000) / 10000).toFixed(2)));
              field.setInitialInput(splitedName[1]);
            }
            // else if (names.length === 3 && !isNaN(splitedName[2])) { // s_번호_면적
            //   field.setArea(names[2]);
  
            // }
            else if (names.length === 1) { // S
              field.setArea(0);
            }
      
            // console.log(outputData, 'output');
            // 0903 임시주석
          //  if (App.session.email !== "eenql00@1011.co.kr" || App.session.email !== "test@1011.co.kr")// 0903 나중에 삭제하기
         //     cutMultipleFields(FieldType.site, outputData, field);
              fieldBlockCnt.site++;
            //else 
            outputData.fields.push(field);
          }
        }
        else {
          //@ts-ignore
          const block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            outputData.wrongBlocks.push(new ConverterField(block, FieldType.site));
          }
        }
      }
      //@ts-ignore
      else if (e.name && !hasSpecialChar && /^[R][A](\d+)/i.test(e.name)) {
        //@ts-ignore
        const newName = autoFixBlockName(e.name, FieldType.road);
        //@ts-ignore
        if (newName !== '' || checkFieldBlockName(e.name, FieldType.road)) {
          //@ts-ignore
          let block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            let field = new ConverterField(block, FieldType.road);
            //@ts-ignore
            if (e.position) {
              //@ts-ignore
              field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
            }
            //@ts-ignore
            if (e.xScale) {
              //@ts-ignore
              field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
            }
            //@ts-ignore
            if (e.rotation) {
              //@ts-ignore
              field.renderGroup.rotateZ(turf.degrees2radians(e.rotation));
            }
            //    cutMultipleFields(FieldType.road, outputData, field);
            outputData.fields.push(field);
            if (newName !== '') field.name = newName;
            fieldBlockCnt.road++;
          }
        }
        else {
          //@ts-ignore
          const block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            outputData.wrongBlocks.push(new ConverterField(block, FieldType.road));
          }
        }
      }
      //@ts-ignore
      else if (e.name && !hasSpecialChar && /^[L][A](\d+)/i.test(e.name)) {
        //@ts-ignore
        const newName = autoFixBlockName(e.name, FieldType.vacancyInside);
        //@ts-ignore
        if (newName || checkFieldBlockName(e.name, FieldType.vacancyInside)) {
          //@ts-ignore
          let block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            let field = new ConverterField(block, FieldType.vacancyInside);
            //@ts-ignore
            if (e.position) {
              //@ts-ignore
              field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
            }
            //@ts-ignore
            if (e.xScale) {
              //@ts-ignore
              field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
            }
            //@ts-ignore
            if (e.rotation) {
              //@ts-ignore
              field.renderGroup.rotateZ(turf.degrees2radians(e.rotation));
            }
            outputData.fields.push(field);
            if (newName !== '') field.name = newName;
            fieldBlockCnt.vacancyInside++;
          }
        }
        else {
          //@ts-ignore
          const block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            outputData.wrongBlocks.push(new ConverterField(block, FieldType.vacancyInside));
          }
        }
      }
      //@ts-ignore
      else if (e.name && /^[G][A](\d+)/i.test(e.name) && !hasSpecialChar) {
        //@ts-ignore
        const newName = autoFixBlockName(e.name, FieldType.vacancyOutside);
        //@ts-ignore
        if (newName !== "" || checkFieldBlockName(e.name, FieldType.vacancyOutside)) {
          //@ts-ignore
          let block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            let field = new ConverterField(block, FieldType.vacancyOutside);
            //@ts-ignore
            if (e.position) {
              //@ts-ignore
              field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
            }
            //@ts-ignore
            if (e.xScale) {
              //@ts-ignore
              field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
            }
            //@ts-ignore
            if (e.rotation) {
              //@ts-ignore
              field.renderGroup.rotateZ(turf.degrees2radians(e.rotation));
            }
            outputData.fields.push(field);
            if (newName !== '') field.name = newName;
            fieldBlockCnt.vacancyOutside++;
          }
        }
        else {
          //@ts-ignore
          const block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            outputData.wrongBlocks.push(new ConverterField(block, FieldType.vacancyOutside));
          }
        }
      }
      //@ts-ignore
      else if (e.name && /^[A][A](\d+)/i.test(e.name)  && !hasSpecialChar) {
      
        //@ts-ignore
        const newName = autoFixBlockName(e.name, FieldType.centerLineOfRoad);      
        //@ts-ignore
        if (newName !== "" || checkFieldBlockName(e.name, FieldType.centerLineOfRoad)) {
          //@ts-ignore
          let block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            let field = new ConverterField(block, FieldType.centerLineOfRoad);
            //@ts-ignore
            if (e.position) {
              //@ts-ignore
              field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
            }
            //@ts-ignore
            if (e.xScale) {
              //@ts-ignore
              field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
            }
            //@ts-ignore
            if (e.rotation) {
              //@ts-ignore
              field.renderGroup.rotateZ(turf.degrees2radians(e.rotation));
            }
            cutMultipleFields(FieldType.centerLineOfRoad, outputData, field);
            //[        outputData.fields.push(field);
            if (newName !== '') field.name = newName;
            fieldBlockCnt.centerRoadLine++;
          }
        }
        else {
          //@ts-ignore
          const block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            outputData.wrongBlocks.push(new ConverterField(block, FieldType.centerLineOfRoad));
          }
        }
  
      }
  
      //@ts-ignore
      else if (e.name && checkFieldBlockName(e.name, FieldType.topography) && !hasSpecialChar) {
       // level_1(개수번호)_높이
        //@ts-ignore
        let splitedName = e.name.split('_');
        if (splitedName.length === 2 && !isNaN(splitedName[1])) {
          //@ts-ignore
          let block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            let field = new ConverterField(block, FieldType.topography);
            //@ts-ignore
            if (e.position) {
              //@ts-ignore
              field.setPosition(new THREE.Vector3(e.position.x, e.position.y, e.position.z));
            }
            //@ts-ignore
            if (e.xScale) {
              //@ts-ignore
              field.setScale(new THREE.Vector3(e.xScale, e.yScale, e.zScale));
            }
            //@ts-ignore
            if (e.rotation) {
              //@ts-ignore
              field.renderGroup.rotateZ(turf.degrees2radians(e.rotation));
            }
            //@ts-ignore
            let splitedName = e.name.split('_');
            if (splitedName.length === 2) {
              field.setInitialInput(splitedName[1]);
              field.setHeight(Number((Math.floor(splitedName[1] * 10000) / 10000).toFixed(2)));
            }
            outputData.fields.push(field);
            fieldBlockCnt.topography++;
          }
        }
        else if (splitedName.length === 1) {
          //@ts-ignore
          let block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            const field = new ConverterField(block, FieldType.topography);
            outputData.fields.push(field);
          }
        }
        else {
          //@ts-ignore
          const block = converterBlocks.find(cb => cb.name === e.name);
          if (block) {
            outputData.wrongBlocks.push(new ConverterField(block, FieldType.topography));
          }
        }
      }
      
      else if (e.layer === "BOUNDARY") {
        let converterMap = new ConverterMap();
        if (e.vertices) {
          e.vertices.forEach(v => {
            v.x = v.x * unitScale;
            v.y = v.y * unitScale;
          })
          converterMap.boundary = e;
          if (outputData.cadastralMap) {
            outputData.cadastralMap!.push(converterMap);
          }
        }
      }
  
      else if ( e.layer !== 'FIELD' && e.layer !== 'BOUNDARY' && e.layer !== 'BUILDING' && e.layer !== 'TEXT'
      //@ts-ignore
        && e.layer !== 'VOID' && e.layer !== 'ROAD' && e.name !== "DIMDOT" && e.name !== 'A$Cd09eb8b0' && e.layer !== 'SAMPLE_DESCRIPTION') { // 지적도 레이어가 아닐때
        //!
        //@ts-ignore
        let block = converterBlocks.find(cb => cb.name === e.name);
        if (block) {
          if (converterType === ConverterType.myType || block.entities.filter(e => e.type === "INSERT").length) { // F블록
            let build = new ConverterBuilding();
            if (e.type === 'INSERT') {
              //@ts-ignore
              build.name = e.name;
  
              //@ts-ignore
              if (e.position) {
                //@ts-ignore
                build.setPosition(e.position);
              }
  
              //@ts-ignore
              if (e.xScale) {
                //@ts-ignore
                build.setScale(new THREE.Vector3(e.xScale, e.yScale === undefined ? 1 : e.yScale, e.zScale === undefined ? 1 : e.zScale));
              }
  
              //@ts-ignore
              if (e.rotation) {
                //@ts-ignore
                build.renderGroup.rotateZ(turf.degrees2radians(e.rotation));
              }
  
              //@ts-ignore
              let block = converterBlocks.find(cb => cb.name === e.name);
              if (block) {
                //checkTypeBlockName(block.name, 'F', outputData.wrongBlocks);
                block.entities.forEach(e => { // F블럭에 라인찾기            
                  if (e.type === "LWPOLYLINE" || e.type === "LINE" || e.type === "POLYLINE") {
                    //@ts-ignore
                    build.polygon.push(e.polygon);
                    //@ts-ignore
                    e.polygon.layer = e.layer;
  
                    //@ts-ignore
                    e.polygon.lineMesh.material.uniforms.opacity = { value: 0.5 };
  
                    //@ts-ignore
                    build.renderGroup.add(e.polygon.lineMesh);
                  }
                })
              }
  
              if (block) {
                block.entities.forEach(e => {
                  let part = makeBuilding(e, converterBlocks);
                  if (part) {
  
                    //   checkTypeBlockName(part.name, 'B', outputData.wrongBlocks, part);
                    build.parts.push(part);
                    //@ts-ignore
                    build.renderGroup.add(part.renderGroup);
                  }
                })
              }
              build.resetPartsElements();
              outputData.buildings.push(build);
            }
          }
          else { // 사업영역
            //TODO
            wrongBlockEntity.push([e, block]);
          
          }
        }
        else if (e.type === "LINE" || e.type === "LWPOLYLINE") {
          if (e.vertices) {
            let verts = e.vertices.map(vert => new THREE.Vector3(vert.x, vert.y, vert.z));
            //@ts-ignore
            const polygon = makePolygon(verts, new THREE.Color().set(layers[e.layer].color), e.type, e.shape);
            switchLineDashedState(polygon.lineMesh.material, true);
            outputData.wrongPolygons.push(polygon);
          }
        }
  
  
      }
    })
  
    wrongBlockEntity.forEach((item) => {
      const [entity, block] = item;    
      let fieldType = FieldType.site;
      
      //@ts-ignore
      const name = entity.name;
      let isWrongBlock = true;  
      if (/^[S][A]$/i.test(name) && fieldBlockCnt.site === 0) {
        isWrongBlock = false;
      }
      if (/^[R][A]$/i.test(name) && fieldBlockCnt.road === 0) {
        fieldType = FieldType.road;
        isWrongBlock = false;
      }
      else if (/^[A][A]$/i.test(name) && fieldBlockCnt.centerRoadLine === 0) {
        fieldType = FieldType.centerLineOfRoad;
        isWrongBlock = false;
      }
      else if (/^[G][A]$/i.test(name) && fieldBlockCnt.vacancyOutside === 0) {
        fieldType = FieldType.vacancyOutside;
        isWrongBlock = false;
      }
      else if (/^[L][A]$/i.test(name) && fieldBlockCnt.vacancyInside === 0) {
        fieldType = FieldType.vacancyInside;
        isWrongBlock = false;
      }
      else if (/^[E][L]$/i.test(name) && fieldBlockCnt.topography === 0) {
        fieldType = FieldType.topography;
        isWrongBlock = false;
      }
  
      let field = new ConverterField(block, fieldType);
      //@ts-ignore
      if (entity.position) {
        //@ts-ignore
        field.setPosition(new THREE.Vector3(entity.position.x, entity.position.y, entity.position.z));
      }
      //@ts-ignore
      if (entity.xScale) {
        //@ts-ignore
        field.setScale(new THREE.Vector3(entity.xScale, entity.yScale, entity.zScale));
      }
      //@ts-ignore
      if (entity.rotation) {
        //@ts-ignore
        field.renderGroup.rotateZ(turf.degrees2radians(entity.rotation));
      }
      //TODO: if field type이 한개면 outputData.fields 에 push, 아ㅣㄴ면 wrongblocks에 푸시
      if (isWrongBlock) {
        (outputData.wrongBlocks as ConverterField[]).push(field);
      }
      else {
        outputData.fields.push(field);
      }
    })
  
    // if (outputData.buildings.length > 0)
    // outputData.buildings[0].wrongFBlock = outputData.buildings[0].wrongFBlock.concat(wrongFBlock)
  
    outputData.buildings.forEach(building => {
      building.setUnitScale(unitScale);
    });
    outputData.fields.forEach(field => {
      field.setUnitScale(unitScale);
  
    });
    (outputData.wrongBlocks as ConverterField[]).forEach((block: ConverterField) => {
      block.setUnitScale(unitScale);
    })
  
    // hierachy정렬
    outputData.fields = outputData.fields.sort((a, b) => {
      if (a.typeName === FieldType.site) return -1;
      else if (b.typeName === FieldType.site) return 1;
      
      else if (a.typeName === FieldType.centerLineOfRoad) return -1;
      else if (b.typeName === FieldType.centerLineOfRoad) return 1;
      
      else if (a.typeName === FieldType.road && b.typeName === FieldType.road) {
        return Number(a.name.split('_')[1]) - Number(b.name.split('_')[1]);
      }
      else if (a.typeName === FieldType.road) return -1;
      else if (b.typeName === FieldType.road) return 1;
      
      else if (a.typeName === FieldType.vacancyOutside) return -1;
      else if (b.typeName === FieldType.vacancyOutside) return 1;
      
      else if (a.typeName === FieldType.vacancyInside) return -1;
      else if (b.typeName === FieldType.vacancyInside) return 1;
  
      else if (a.typeName === FieldType.topography && b.typeName === FieldType.topography) {
        return Number(a.name.split('_')[1]) - Number(b.name.split('_')[1]);
      }
      else if (a.typeName === FieldType.topography) return -1;
      else return 1;
    })  
  }

  return converterBlocks;
}

function MakeBuildingWithMetaData(e: any, blocks: ConverterBlock[]) {
  let block = blocks.find(cb => cb.name === e.name);
  let pos = e.position;
  let scale = e.scale;
  let rotate = e.rotate;
  let name = e.name;

  if (block!.type === BlockType.group) {
    let newPart = new BuildingGroup(block!);
    newPart.SetPosition(pos);
    newPart.SetScale(scale);
    newPart.RotateWithDegrees(rotate);
    newPart.SetName(name);

    e.parts.forEach((p: any) => {
      let compenent = MakeBuildingWithMetaData(p, blocks);
      newPart.AddNewPart(compenent!);
    })
    return newPart;
  }
  else if (block!.type === BlockType.house) {
    let house = new BuildingHouseUnit(block!);
    house.SetPosition(pos);
    house.SetScale(scale);
    house.RotateWithDegrees(rotate);
    house.SetName(name);

    house.SetExclusiveArea(e.exclusiveArea);
    house.SetCommonWallArea(e.commonWallArea);
    house.SetServiceArea(e.serviceArea);
    house.SetBalconyOver150cm(e.balconyOver150cm);

    return house;
  }
  else {
    let core = new BuildingCoreUnit(block!);
    core.SetPosition(pos);
    core.SetScale(scale);
    core.RotateWithDegrees(rotate);
    core.SetName(name);
    core.SetArea(e.area);

    return core;
  }
}

export async function loadMetaFile(metaPath: string, buildings: ConverterBuilding[], dataUnit: Unit = Unit.Millimeters) {
  let meta = await getS3Json(`${metaPath}/meta.json`);
  let converterBlocks: ConverterBlock[] = [];
  let blockMeta = meta.blocks;
    let unitScale = getScale(dataUnit); // mm -> m
  let buildingMeta = meta.entities;
  blockMeta.forEach((bm: any) => {
    let newBlock = new ConverterBlock();
    newBlock.layer = bm.layer;
    newBlock.name = bm.name;
    newBlock.name2 = bm.name2;
    newBlock.position = bm.position;
    newBlock.type = bm.type;

    bm.entities.forEach((e: any) => {
      switch (e.type) {
        case 'INSERT':
          newBlock.entities.push(makeInsertEntity(e));
          break;
        case 'LWPOLYLINE':
        case 'POLYLINE':
        case 'LINE':
          newBlock.entities.push(makeUnitEntit(e, e.color, unitScale));
          break;
        default:
          break;
      }
    })
    converterBlocks.push(newBlock);
  });

  buildingMeta.forEach((bm: any) => {
    let build = new ConverterBuilding();
    build.name = bm.name;
    build.setPosition(bm.position);

    bm.parts.forEach((p: any) => {
      let part = MakeBuildingWithMetaData(p, converterBlocks);
      build.parts.push(part);
      build.renderGroup.add(part.renderGroup);
    })
    build.setUnitScale(getScale(dataUnit));

    buildings.push(build);
  })

  return converterBlocks;
}
