import React, { Component } from 'react';
import { ComponentUI } from './BuildingComponentUI';
import { BuildingComponent, BuildingPart } from './BuildingPart';
import { ConverterType } from './DataTypes';
import { CadConvereterBuildintShapeType } from './MyTypeBlockVersionConverter';

interface partState {

}


interface partProp {
  compoent: BuildingPart;
  type: ConverterType;
  buildingPart?: BuildingPart[];
  showFontError?: boolean;
  selectedBuildingInfo?: {bay: number, type: string};
  onSelectBuildingInfo?: (bay: number, type: string) => void;
  updateArea: () => void;
  showSnackBar?: () => void;
}

export class BuildingPartUI extends Component<partProp, partState> {
  render = () => {
    return (
      this.props.compoent && <div key={this.props.compoent.uuid} className='core m-t-md'>
        {/* {this.props.compoent.name} */}
        {
          <ComponentUI type={this.props.type} buildingPart={this.props.buildingPart && this.props.buildingPart.filter(h=>h.renderGroup.uuid === this.props.compoent.renderGroup.parent!.uuid)[0]} 
            updateArea={this.props.updateArea} component={this.props.compoent as BuildingComponent} 
            onSelectBuildingInfo={this.props.onSelectBuildingInfo}
            selectedBuildingInfo={this.props.selectedBuildingInfo}
            showFontError={this.props.showFontError}
            showSnackBar={this.props.showSnackBar}
            /> 
        //||
          // this.props.compoent.parts.map(p => {
          //   return p !== undefined && <BuildingPartUI key={p.uuid} updateArea={this.props.updateArea} compoent={p} /> 
          //   || <div></div>
          //})
          }
      </div>
    )
  }
}

