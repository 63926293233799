import React, { Component } from 'react';
import { Button, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Cached, Info } from '@material-ui/icons';
import '../css/CADConverter/SettingModal.scss'
import { ConverterType, saveState, Unit } from './DataTypes';
import { saveDataToDynamoDB, updateDynamoDBData, getSettingData } from './DBManager';
import { myTypeSettingData, MyTypeSettingDataUI } from './detialSetting';
import App from '../App';
import '../css/BuilditTemplate.scss';
import Tooltip from "../Tooltip";
import Switch from "../Switch";
import Tabs from '../Tabs';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import BuilditInput from '../BuilditInput';
import MySiteSettingUI from './MySiteSettingUI';
import { BooleanModel } from 'aws-sdk/clients/gamelift';

interface defaultSettingData {
  autoSetting: boolean,
  dataUnit: Unit,
  
}

export class userSettingData extends Component implements defaultSettingData {
  static myTypeSettingData: myTypeSettingData;
  autoSetting = true;
  dataUnit = Unit.Millimeters;
  settingDBTable = 'platform-buildit-cad-converter-user-setting';
    
  componentDidMount = async () => {
    
  }
  getDataFromDB = async () => {
    
    let data = await getSettingData();
    if (!data) {
      let newData = {
        myType: {
          windowOffset: { enable: false, value: 200 },
          areaOffset: { enable: false, value: 10 },
          layerOffset: { enable: false, value: 200 },
          layerOverlap: { enable: false, value: 10 },
          unClosedPolygon: {enable: false, value: 0}, // 닫혀있지 않은 폴리곤 오차 보정
          polygonOffset: {enable: false, value: 0}, //   폴리곤간 이격보정
          blockOverlap: { enable: false, value: 0 },
          delWindowLine: { enable: false, value: 0 },
          delLineNotInBlock: { enable: false, value: 0 },
          lineInSameBlock: { enable: false, value: 0 },
        },
        mySite: {},
        myPlane: {},
        autoSetting: true, /////* this.autoSetting, 21.06.24 일단 자동설정만 가능하게 처리 */ 
        dataUnit: this.dataUnit,
        stage: App.tempStage,
        email: App.session.email,
      }
      data = newData;
      
      saveDataToDynamoDB(newData, this.settingDBTable);
    }
    if (!userSettingData.myTypeSettingData) {
      userSettingData.myTypeSettingData = new myTypeSettingData();
    }
    else if (userSettingData.myTypeSettingData.windowOffset.value > 100) {
      userSettingData.myTypeSettingData.windowOffset.value = 100;  
    }
    
    userSettingData.myTypeSettingData.updateDataFromDB(data.myType);
    this.autoSetting = data.autoSetting !== undefined ? data.autoSetting : this.autoSetting;
    this.dataUnit = data.dataUnit !== undefined ? data.dataUnit : this.dataUnit;
    return data.myType;
  }

  updateAutoSetting = async (value: boolean, type: ConverterType, changeAll: boolean = false) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.autoSetting = value;
    this.autoSetting = value;
    if (changeAll) {
      if (type === ConverterType.mySite) {
        data.myType.unClosedPolygon.enable = value;
        data.myType.blockOverlap.enable = value;
        data.myType.polygonOffset.enable = value;
        data.myType.lineInSameBlock.enable = value;
      }
      else if (type === ConverterType.myType) {
        data.myType.windowOffset.enable = value;
        data.myType.blockOverlap.enable = value;
        data.myType.polygonOffset.enable = value;
        data.myType.unClosedPolygon.enable = value;
        data.myType.delWindowLine.enable = value;
        data.myType.delLineNotInBlock.enable = value;
      }
      else if (type === ConverterType.myPlane) {
        data.myType.unClosedPolygon.enable = value;
        data.myType.blockOverlap.enable = value;
        data.myType.polygonOffset.enable = value;
        data.myType.lineInSameBlock.enable = value;
        data.myType.windowOffset.enable = value;
        data.myType.blockOverlap.enable = value;
        data.myType.polygonOffset.enable = value;
        data.myType.unClosedPolygon.enable = value;
        data.myType.delWindowLine.enable = value;
        data.myType.delLineNotInBlock.enable = value;
      }  
    }
    await updateDynamoDBData(this.settingDBTable, data);
  }

  updateAutoSettingLayer = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.autoSetting = value;
    this.autoSetting = value;
    await updateDynamoDBData(this.settingDBTable, data);
  }

  updateUnit = async (value: Unit) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.dataUnit = value;
    this.dataUnit = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

}

export default userSettingData;

interface SettingProps {
  closeModal: () => void,
  settingData: userSettingData,
  open: boolean,
  converterType: ConverterType,
  handleConverterType: (type: ConverterType) => void,
}


interface SettingState {
  unit: Unit,
  saveState: SaveState,
  showUnit: boolean,
  autoSetting: boolean,
  converterType: ConverterType,
}

export enum SaveState {
  saving = '저장 중..',
  saveDone = `자동 저장 되었습니다. `,
  default = '',
}
export class Setting extends Component<SettingProps, SettingState>{
  settingData = new userSettingData(App.tempStage, App.session.email);

  state: SettingState = {
    unit: this.props.settingData.dataUnit,
    showUnit: false,
    autoSetting: this.props.settingData.autoSetting,
    converterType: ConverterType.myType,
    saveState: SaveState.default,
  }
  

  settingDBTable = 'platform-buildit-cad-converter-user-setting';
  componentDidUpdate = async (previousProps: Readonly<SettingProps>) => {
    if (previousProps.open !== this.props.open) {
      await this.handleAutoEditAll();
      this.setState({
        unit: this.props.settingData.dataUnit,
        showUnit: false,
        autoSetting: this.props.settingData.autoSetting,
        converterType: ConverterType.myType,
        saveState: SaveState.default,
      })
    }
    if (previousProps.converterType !== this.props.converterType) {
      this.setState({saveState: SaveState.default});
    }
  }

  autoSettingUpdate = async (value: boolean, updateAll: boolean = false) => {
    await this.props.settingData.updateAutoSetting(value, this.props.converterType, updateAll);
    this.setState({ autoSetting: value });
    this.setState({ saveState: SaveState.saveDone })
  }

  unitUpdate = (value: Unit) => {
    this.setState({ showUnit: false, unit: value });
  }

  changeSettingData = (optionName: string, value: any) => {
    switch (optionName) {
      case 'windowOffsetValue':
        if (value >= 1 && value <= 100) userSettingData.myTypeSettingData.windowOffset.value = Number(value);
        break;
      case 'layerOverlapEnable':
        userSettingData.myTypeSettingData.layerOverlap.enable = value;
        break;
      case 'PolygonOffsetEnable':
        userSettingData.myTypeSettingData.polygonOffset.enable = value;
        break;
      case 'unClosedPolygonEnable':
        userSettingData.myTypeSettingData.unClosedPolygon.enable = value;
        break;
      case 'windowOffsetEnable':
        userSettingData.myTypeSettingData.windowOffset.enable = value;
        break;
    }
  }

  handleSaveState = (saveState: SaveState) => {    
    this.setState({saveState});
  }

  handleAutoEditAll = async () => { // 전체 자동 보정
    const settingData = userSettingData.myTypeSettingData; //await this.props.settingData.getDataFromDB(); 
    if (this.props.converterType === ConverterType.mySite) {
      if (settingData.unClosedPolygon.enable && settingData.blockOverlap.enable && settingData.polygonOffset.enable &&
        settingData.lineInSameBlock.enable) {
        await this.autoSettingUpdate(true);
      }
      else {
        await this.autoSettingUpdate(false);
      }
    }
    else if (this.props.converterType === ConverterType.myType) {
      if (settingData.unClosedPolygon.enable && settingData.blockOverlap.enable && settingData.polygonOffset.enable &&
        settingData.windowOffset.enable && settingData.delLineNotInBlock.enable && settingData.delWindowLine.enable) {
        await this.autoSettingUpdate(true);
      }
      else {
        await this.autoSettingUpdate(false);
      }
    }
    else if (this.props.converterType === ConverterType.myPlane) {
      if (settingData.unClosedPolygon.enable && settingData.blockOverlap.enable && settingData.polygonOffset.enable &&
        settingData.lineInSameBlock.enable && settingData.windowOffset.enable && settingData.delLineNotInBlock.enable && settingData.delWindowLine.enable) {
        await this.autoSettingUpdate(true);
      }
      else {
        await this.autoSettingUpdate(false);
      }
    }
  }

  switchAll = async (checked: boolean) => {
    // 전체자동보정 클릭
    let data = userSettingData.myTypeSettingData;//await getSettingData();
    if (!data)
        return;
    this.setState({ saveState: SaveState.saving });
    await this.autoSettingUpdate(checked, true);
  
    if (this.props.converterType === ConverterType.mySite) {
      data.unClosedPolygon.enable = checked;
      data.blockOverlap.enable = checked
      data.polygonOffset.enable = checked;
      data.lineInSameBlock.enable = checked;
    }
    else if (this.props.converterType === ConverterType.myType) {  
      data.windowOffset.enable = checked;      
      data.blockOverlap.enable = checked
      data.polygonOffset.enable = checked;
      data.unClosedPolygon.enable = checked;
      data.delWindowLine.enable = checked;
      data.delLineNotInBlock.enable = checked;      
    }
    else {
      data.windowOffset.enable = checked;      
      data.blockOverlap.enable = checked
      data.polygonOffset.enable = checked;
      data.unClosedPolygon.enable = checked;
      data.delWindowLine.enable = checked;
      data.delLineNotInBlock.enable = checked;
      data.unClosedPolygon.enable = checked;
      data.blockOverlap.enable = checked
      data.polygonOffset.enable = checked;
      data.lineInSameBlock.enable = checked;
    }
  }

  render = () => {

    return (
      <Dialog
        className='SettingModalBody  buildit-template'
        aria-labelledby="simple-dialog-title"
        open={this.props.open}
        onClose={this.props.closeModal}
      >
        <div className={`modalContent `}>
          <div className='title'>
            <div className="setting font font-secondary">설정</div>
            <div className="right">
              {
                this.state.saveState === SaveState.default && <span></span> ||
                this.state.saveState === SaveState.saving &&
                <span className="save-msg font font-primary font-12px"><Cached className="icon" /><span className="text">{this.state.saveState}</span></span> ||
                this.state.saveState === SaveState.saveDone &&
                <span className="save-msg font font-primary font-12px">{`${this.state.saveState}${moment(new Date()).format("YYYY-MM-DD HH:MM:SS")}`}</span>
              }
              <div className="wrap-icon">
                <CloseIcon className='closeIcon' onClick={this.props.closeModal} />
              </div>
            </div>
          </div>

          <div className="input-unit font font-primary font-14px">캐드 파일 입력 단위 : mm</div>

          <div className="wrap-key-value">
            
            <div className="key font font-emphasis font-18px">자동 보정
              <Tooltip msg="캐드컨버터에서는 캐드 설정상 나올 수 있는 오차에 대한 자동 보정이 가능합니다." arrowOn={false}>
                <div className="wrap-icon">
                  <Info className="icon info-icon font font-emphasis" />
                </div>
              </Tooltip>
            </div>
            <div className="value">
              <Switch
                className={`font ${this.state.autoSetting ? "font-special" : "font-secondary"}`}
                checked={this.state.autoSetting}
                onClick={async (checked) => {
                  await this.switchAll(checked);
                  // this.setState({
                  //   autoCorrection:
                  //   {
                  //     ...this.state.autoCorrection,
                  //     correctionAll: checked
                  //   }
                }}
                name="전체 자동 보정"
              />

            </div>
          </div>

          <div className="wrap-tab">
            <Tabs className="tabs bg-navy">
              <Button
                className={`tab bg-navy tab-primary font font-pretendard first-child ${this.props.converterType === ConverterType.myType ? "active" : ""}`}
                onClick={() => this.props.handleConverterType(ConverterType.myType)}
              // className={`img-btn tab bg-navy tab-primary first-child ${this.state.hierarchyMode === ConverterType.mySite && "active"}`}
              >
                동평면
              </Button>
              <Button
                className={`tab bg-navy tab-primary font font-pretendard first-child ${this.props.converterType === ConverterType.mySite ? "active" : ""}`}
                onClick={() => this.props.handleConverterType(ConverterType.mySite)}
              // className={`img-btn tab bg-navy tab-primary ${this.state.hierarchyMode === ConverterType.myType && "active"}`}
              >
                사업영역
              </Button>
              <Button
                className={`tab bg-navy tab-primary font font-pretendard first-child ${this.props.converterType === ConverterType.myPlane ? "active" : ""}`}
                onClick={() => this.props.handleConverterType(ConverterType.myPlane)}
              >
                배치안
              </Button>
            </Tabs>
          </div>
          {
            this.props.converterType === ConverterType.myType &&
            <>
              <div className="description font font-primary font-14px m-b-xl m-t-xl">
                <li>전용 면적만 입력 한 경우, 서비스 면적과 벽체 공용 면적은 0.00으로 자동 보정됩니다.</li>
                <li>동평면 작성 시 불필요한 점(POINT) , Z(캐드 상)값은 자동 삭제됩니다.</li>
              </div>
            <div className="border m-b-md light-grey"/>
              <MyTypeSettingDataUI
                handleAutoEditAll={this.handleAutoEditAll}
                // handleSwitch={this.handleSwitch}
                handleSaveState={this.handleSaveState}
                converterType={ConverterType.mySite}
                autoEditAll={this.state.autoSetting}
                myTypeSettingData={userSettingData.myTypeSettingData}
                changeSettingData={this.changeSettingData}
              
              />
            </> 
          }
          {
            this.props.converterType === ConverterType.mySite &&
            <>
              <div className="description font font-primary font-14px m-b-xl m-t-xl">
                <li>대지 영역을 미입력 한 경우, 계산 면적으로 자동 보정됩니다. </li>
                <li>다운로드한 지적도 영역 밖에 작성된 폴리곤은 자동 삭제됩니다.</li>
                <li>사업 영역 작성 시 불필요한 점(POINT) , Z(캐드 상)값은 자동 삭제됩니다.</li>
              </div>
              <div className="border m-b-md light-grey"/>
              <MySiteSettingUI
                myTypeSettingData={userSettingData.myTypeSettingData}
                handleAutoEditAll={this.handleAutoEditAll}
                handleSaveState={this.handleSaveState}
                autoEditAll={this.state.autoSetting}
                converterType={ConverterType.mySite}
              />
            </>
          }
          {
            this.props.converterType === ConverterType.myPlane &&
            <>
              <div className="description font font-primary font-14px m-b-xl m-t-xl">
                <li>전용 면적만 입력 한 경우, 서비스 면적과 벽체 공용 면적은 0.00으로 자동 보정됩니다.</li>
                <li>대지 영역을 미입력 한 경우, 계산 면적으로 자동 보정됩니다.</li>
                <li>다운로드한 지적도 영역 밖에 작성된 폴리곤은 자동 삭제됩니다.</li>
                <li>배치안 작성 시 불필요한 점(POINT) , Z(캐드 상)값 의 경우에는 자동 삭제됩니다.</li>
              </div>
              <div className="border m-b-27px light-grey"/>

              <div className="SettingModalContent">
                <MyTypeSettingDataUI
                  handleAutoEditAll={this.handleAutoEditAll}
                  // handleSwitch={this.handleSwitch}
                  handleSaveState={this.handleSaveState}
                  converterType={ConverterType.myPlane}
                  autoEditAll={this.state.autoSetting}
                  myTypeSettingData={userSettingData.myTypeSettingData}
                  changeSettingData={this.changeSettingData}
                />
                <MySiteSettingUI
                  myTypeSettingData={userSettingData.myTypeSettingData}
                  handleAutoEditAll={this.handleAutoEditAll}
                  handleSaveState={this.handleSaveState}
                  autoEditAll={this.state.autoSetting}
                  converterType={ConverterType.myPlane}
                />
              </div>
            </>
          }
        </div>
      </Dialog >
    )
  }
}
