import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplateBlock.scss';
import '../css/BuilditUI.scss';
import '../css/CADConverter/BlockPanel.scss';
import * as jsts from 'jsts';
import '@teneleven/protocols-ts-web';
import { Button, ListItemSecondaryAction, Snackbar } from '@material-ui/core'

import { ConverterLayer, buildingTypeStruct, ListType, saveState, CompletenessType, ConverterType, PartOfSelect, entityType, Polygon, ConverterInsert, ConverterUnit, BuildingTypeAlertType } from './DataTypes';
import { asyncFileRead, blockParsing, loadMetaFile, switchLineDashedState } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, makeBlockBuildingData, getMetaData } from './DBManager';
import { SceneManager } from './SceneManager';
import { brightenAllLayer, darkenAllLayer, darkenAllBlock, isOverlapBlock, checkPolygonError, JSTSGeoToTHREEGeo, getPolygonType, setBlockOpacity, handleOpacityCertainType, setBlockNameNumber } from './CoreAndHouseController';
import { CadConverterModal, ModalProps, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import { MakeBuildingMesh } from '../Viewer/MeshManager'
import App from '../App';
import queryString from 'query-string';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { LoadingPage } from './LoadingPage';
import { userSettingData, Setting } from './SettingModal';
import { House } from './House';
import { ErrorLog, ErrorLogCell, ErrorLogCell2, ErrorType } from './ErrorLog';
import _ from 'lodash';
import { ConverterBlock } from './ConverterBlock';
import { BlockParsingData, BuildingComponent, BuildingPart, ConverterBuilding } from './BuildingPart';
import { BuildingPartUI } from './BuildingPartUI';
import { BlockPanelUI } from './BlockPanel'
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { ErrorLogBlock } from './ErrorLogBlock';
import { getCircle, getCurveErrorCircle, getCurveErrorCircleBlock, getErrorLine } from './MeshMaker';
import { group } from 'console';
import BlockTreeList from './BlockTreeList';
import { ReactComponent as BlockIcon } from '../img/icon/blockIcon.svg';
import MySiteBlockSaveModal from './MySiteBlockSaveModal';
import { myTypeSettingData } from './detialSetting';
import { FileDrop } from 'react-file-drop';
import { changeLineColorRed, checkBlockName, checkBlockStruct, checkConnectionComponent, checkLayerNameError, checkTypeError } from './CheckTypeBlockError';
import { ChevronLeft, Info, NavigateNext } from '@material-ui/icons';
//import { Resizable } from 're-resizable';
import {ReactComponent as HierarchyIcon} from '../img/icon/hierarchyIcon.svg';
import { ErrorList, TypeError } from './Error';
import Tooltip from '../Tooltip';
import { BuildingShapeType, LineInfoType } from '../model/Project';
import Modal, { ModalOptions } from '../Modal';
import { BuildingGroup } from './BuildingGroup';
import ConstructionLineInfo from '../ConstructionLineInfo';
import polylabel from 'polylabel';


export type BuildingType = "판상 I형" | "판상 L형" | "타워 T형" | "타워 Y형" | "복도식 I형" | "복도식 L형" | string

const uuid4 = require('uuid/v4');



interface SceneProps {

}

interface SceneState {
  blocks: ConverterBlock[];
  is2D: boolean;
  screenWidth: number;
  screenHeight: number;
  fileName: string;
  saveName: string;
  showBlock: boolean;
  listType: ListType;
  selectLayer: ConverterLayer | null;
  selectHouse: House | null;
  settingID: string,
  partOfHouse: PartOfSelect,
  totalExclusiveAreas: string;
  totalServiceAreas: string;
  totalCoreAreas: string;
  totalCommonWallAreas: string;
  buildingArea: string,
  groundArea: string,
  userID: string,
  showModal: boolean,
  showSaveModal: boolean,
  loadFile: boolean,
  showAutoSetting: boolean,
  loadingPage: boolean,
  showSample: boolean,
  showSettingModal: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
  infoSize: number,
  houseNumber: number,
  loadDB: any,
  clickedBuildingPart: any,
  isSaved: boolean,
  errorLogs: any[],
  currentLog: string,
  showBlockInfo: boolean,
  selectBuilding: {
    bay: number,
    type: string,//CadConvereterBuildintShapeType,
  },
  openSnack: boolean,
  openModal: boolean,
  modalOptions: ModalOptions,
  showFontError: boolean,
  colorInfoType: {
    concrete: Polygon[],
    unit: Polygon[],
    core: Polygon[],
    lightWindow: Polygon[],
    normalWindow: Polygon[],    
  },
  settingModalType: ConverterType,
  snackMsg: React.ReactNode,
}

export type CadConvereterBuildintShapeType = BuildingShapeType | "USER_DEFINED_TYPE";
export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    blocks: [],
    is2D: true,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    fileName: '선택된 파일 없음',
    showBlock: false,
    selectLayer: null,
    settingID: '',
    totalCoreAreas: '0',
    totalExclusiveAreas: '0',
    totalServiceAreas: '0',
    totalCommonWallAreas: '0',
    buildingArea: '0',
    groundArea: '0',
    listType: ListType.layer,
    selectHouse: null,
    saveName: '',
    userID: 'test@1011.co.kr',
    showModal: false,
    showSaveModal: false,
    loadFile: false,
    showAutoSetting: false,
    partOfHouse: PartOfSelect.wall,
    loadingPage: false,
    showSample: true,
    showSettingModal: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    infoSize: 0,
    houseNumber: 0,
    loadDB: null,
    clickedBuildingPart: null,
    isSaved: false,
    errorLogs: [],
    currentLog: "ALL",
    showBlockInfo: false,
    selectBuilding: {
      bay: 0,
      type: "선택",
    },
    showFontError: false,
    openSnack: false,
    openModal: false,
    modalOptions: {},
    colorInfoType: {
      concrete: [],
      unit: [],
      core: [],
      lightWindow: [],
      normalWindow: [],    
    },
    settingModalType: ConverterType.myType,
    snackMsg: "",
  };

  mount: HTMLDivElement | null = null;

  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  polygon3DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  S3BucketName = 'teneleven-platform-my-building-type-v2';
  DBTableName = 'platform-buildit-my-building-type-v2';
  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log('test'),
    title: '제목'
  }
  fileData: string = '';

  houseCount = 0;
  coreCount = 0;
  blockBs: Array<BuildingPart> = [];
  houses: Array<BuildingHouseUnit> = [];
  cores: Array<BuildingCoreUnit> = [];
  errorLogs: ErrorLogCell2[][] = [];
  loadFileErrorLogs: ErrorLogCell2[] = [];
  globalErrorLogs: ErrorLogCell2[] = [];
  parsingOutput: BlockParsingData = { buildings: [], fields: [], wrongBlocks: [], wrongPolygons: [] };
  block: ConverterBlock[] = [];
  settingData = new userSettingData(App.tempStage, App.session.email);
  notComponentBlocks: Array<any> = [];
  errorList = new ErrorList();
  polyLabelList = new Map();//{x: number, y: number, dist: number} = [];

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
    // this.houses.forEach(h => {
    //   this.updateInfoPosition(h.uuid, h.complete, h);
    // });
    // this.cores.forEach(c => {
    //   this.updateInfoPosition(c.uuid, c.complete, c);
    // });
    this.updateInfoPosition();
    
    
  }

  constructor(props: SceneProps) {
    super(props);
  }

  setPolyLabel = () => {
    const polyLabelArr = [];
    [...this.cores, ...this.houses].forEach((block: BuildingHouseUnit | BuildingCoreUnit) => {
      block.renderGroup.updateWorldMatrix(true, true);
      let matrixWorld = block.renderGroup.matrixWorld;
      let coords: jsts.geom.Coordinate[] = [];
      if (this.errorList.isCompleteById(block.uuid)) {

        for (let i = 0; i < block.polygon.length; i++) {
          const polygon = block.polygon[i];
          try {
            if (polygon.shape && polygon.area > 0 && polygon.vertices.length >= 4 &&
              polygon.vertices[0].x === polygon.vertices[polygon.vertices.length - 1].x
              && polygon.vertices[0].y === polygon.vertices[polygon.vertices.length - 1].y) {
              polygon.vertices.forEach(v => {
                let newV = v.clone().applyMatrix4(matrixWorld);
                coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
              });

              let geoFac = new jsts.geom.GeometryFactory();
              let linearRing = geoFac.createLinearRing(coords);

              //@ts-ignore
              let newPolygon = geoFac.createPolygon(linearRing, []).buffer(0);
              const polygonCoords = newPolygon.getCoordinates();
              const p = polylabel([polygonCoords.map(c => [c.x, c.y])], 1.0);
              //@ts-ignore
              const dist = p.distance;
              let center = new THREE.Vector3(p[0], p[1]);

              this.polyLabelList.set(block.uuid, {x:p[0], y: p[1], dist});
            }
          }
          catch { }
        }
      }
    });

    //@ts-ignore
    this.polyLabelList = new Map([...this.polyLabelList.entries()].sort((a, b) => b[1].dist - a[1].dist));

    
  }


  updateInfoPosition = () => {
    const components = [...this.houses, ...this.cores];
    // 표현 가능한지 먼저 체크
    if (!this.state.is2D || !this.state.showBlockInfo) {
      components.forEach(block => {
        const blockInfo = document.querySelector(`.TT${block.uuid}`) as HTMLDivElement;
        if (blockInfo) blockInfo.style.visibility = 'hidden';
        const infoIcon = document.querySelector(`.info${block.uuid}`) as HTMLDivElement;
        if (infoIcon) infoIcon.style.visibility = 'hidden';
      });
      return;
    }
    if (this.polyLabelList.size > 0) {
      const polyLabelArr = Array.from(this.polyLabelList);
      const { x, y, dist } = polyLabelArr[0][1];
      let screenPosition = this.sceneManager.getScreenPosition(new THREE.Vector3(x + dist, y + dist, 0));
      let c = this.sceneManager.getScreenPosition(new THREE.Vector3(x, y, 0));
      const elementWidth = Math.abs(c.x - screenPosition.x) * 1.5;

      if (elementWidth >= 148) {
        // 전체 표시        
        components.forEach(block => {
          const polyLabel = this.polyLabelList.get(block.uuid);
          if (polyLabel) {
            const { x, y } = polyLabel;
            let c = this.sceneManager.getScreenPosition(new THREE.Vector3(x, y, 0));
            const blockInfo = document.querySelector(`.TT${block.uuid}`) as HTMLDivElement;
            if (blockInfo) {
              blockInfo.style.top = `${c.y}px`;//c.y.toString() + 'px';
              blockInfo.style.left = `${c.x}px`;
              blockInfo.style.visibility = 'visible';
            }
            const infoIcon = document.querySelector(`.info${block.uuid}`) as HTMLDivElement;
            if (infoIcon) {
              infoIcon.style.visibility = 'hidden';
            }
          }
        });
      }
      else if (elementWidth >= 20) {
        // info 표시
        components.forEach(block => {
          const polyLabel = this.polyLabelList.get(block.uuid);
          if (polyLabel) {
            const { x, y } = polyLabel;
            let c = this.sceneManager.getScreenPosition(new THREE.Vector3(x, y, 0));
            const blockInfo = document.querySelector(`.TT${block.uuid}`) as HTMLDivElement;
            if (blockInfo) {
              //@ts-ignore
              if (!blockInfo.mouseEnter) blockInfo.style.visibility = 'hidden';
              blockInfo.style.top = `${c.y}px`;
              blockInfo.style.left = `${c.x}px`;
              //   blockInfo.style.visibility = 'visible';  
            }
            const infoIcon = document.querySelector(`.info${block.uuid}`) as HTMLDivElement;
            if (infoIcon) {
              infoIcon.style.top = `${c.y}px`;
              infoIcon.style.left = `${c.x}px`;
              infoIcon.style.visibility = 'visible';
            }
          }
        });
      }
      else {
        // 표시 no
        components.forEach(block => {
          const polyLabel = this.polyLabelList.get(block.uuid);
          if (polyLabel) {
            const { x, y } = polyLabel;
            //            let c = this.sceneManager.getScreenPosition(new THREE.Vector3(x, y, 0));
            const blockInfo = document.querySelector(`.TT${block.uuid}`) as HTMLDivElement;
            if (blockInfo) {
              blockInfo.style.visibility = 'hidden';
            }
            const infoIcon = document.querySelector(`.info${block.uuid}`) as HTMLDivElement;
            if (infoIcon) {
              infoIcon.style.visibility = 'hidden';
            }
          }
        });
      }
    }
  }

  componentWillMount = () => {
    document.addEventListener("keydown", (e) => {
      if (this.state.loadFile && e.key === "Escape") {
        this.setState({ clickedBuildingPart: null });
        setBlockOpacity(this.parsingOutput.buildings, 1);
      }
    });
  }

  componentDidMount = async () => {    
    await this.settingData.getDataFromDB(); // 사용자 설정값 
    this.mount!.appendChild(this.sceneManager.canvasElement);
    
    this.sceneManager.SceneInit();
    this.sceneManager.renderer.sortObjects = false;
//    this.sceneManager.renderer.sortObjects = false;
    this.sceneManager.addObjectToScene(this.polygon2DGroup);
    this.sceneManager.addObjectToScene(this.polygon3DGroup);

    this.sceneManager.addObjectToScene(new THREE.AmbientLight(0xffffff, 0.5));
    let light = new THREE.DirectionalLight();
    light.position.set(10, 10, 10);
    this.sceneManager.addObjectToScene(light);
    this.sceneManager.addObjectToScene(light.target);

    this.animate();

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
    App.stage !== "prod" && console.log(this.sceneManager.canvasElement.getBoundingClientRect());


    const qs = queryString.parse(window.location.search);
    if (qs.id) {
      let item = await getMetaData(this.DBTableName, qs.id as string);
      this.block = await loadMetaFile(item.meta_path, this.parsingOutput.buildings, this.settingData.dataUnit);

      this.parsingOutput.buildings.forEach((b, i) => {
        b.renderGroup.updateWorldMatrix(true, true);
        this.polygon2DGroup.add(b.renderGroup);
      })
      
    
      this.recalculateResult();

      this.bbox.makeEmpty();
      this.bbox.setFromObject(this.polygon2DGroup);
      let center = new THREE.Vector3();
      this.bbox.getCenter(center);

      this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
      this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
      this.sceneManager.orthoCamera.zoom = 1;

      let aspect = this.state.screenWidth / this.state.screenHeight;
      this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);

      this.setState({
        fileName: item.name,
        saveName: item.name,
        loadingPage: false,
        loadFile: true,
        loadDB: item,
      }, () => this.onWindowResize())
      
    }

    // var axesHelper = new THREE.AxesHelper(5);
    // this.sceneManager.addObjectToScene(axesHelper);

    const r = await App.search({
      "table": this.DBTableName,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${App.session.email} AND deleted:false`
    });

    this.setState({
      userID: App.session.email,
      showSample: r.data.hits.total > 0 ? true : false,
    })

    //@ts-ignore
    if (this.props.location.state && this.props.location.state.file && this.props.location.state.file[0]) {
      //@ts-ignore
      await this.loadDXFFileOutside(this.props.location.state.file[0]);
      //@ts-ignore
      window.addEventListener('keydown', (e) => {
        if (e.key === "F5") { // 새로고침시 파일 새로 열게
          //@ts-ignore
          this.props.location.state = undefined;
          //@ts-ignore
          this.props.history.push('/cad/myTypeblock');
        }
      })
    }
    // this.cores.forEach(c => {
    //   let center = new THREE.Vector3();
    //   this.bbox.getCenter(center);

    //   this.updateInfoPosition(c.uuid, c.centerOfAllLine, CompletenessType.complete, c);
    // }); // c.complete
    this.recalculateResult = _.debounce(this.recalculateResult, 500);
  }


  componentDidUpdate = (previousProps: Readonly<SceneProps>, previousState: Readonly<SceneState>) => {
    
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      let aspect = width / height;

      this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);
      this.sceneManager.renderer.setSize(width, height);

    }
    
    if (this.state.showBlock !== previousState.showBlock) {
      this.onWindowResize();
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }

    if ((previousState.selectBuilding.bay !== this.state.selectBuilding.bay || previousState.selectBuilding.type !== this.state.selectBuilding.type) &&
      (this.state.selectBuilding.bay !== 0 && this.state.selectBuilding.type !== "선택")) {
      this.setState({ openModal: false });
    }

    if (this.state.showBlockInfo && previousState.showBlockInfo !== this.state.showBlockInfo) {
      this.setState({ 
        openSnack: true,
        snackMsg: <div className="font font-emphasis msg font-pretendard"><span className="font font-special">마우스 휠로 줌 인</span> 하시면 블록 정보를 확인 가능합니다.</div>,
      });
    }
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':

        // this.autoSetHouse();
        break;
      default:
        break;
    }
  }

  // checkBlockStruct = () => {
  //   const buildings = this.parsingOutput.buildings; // D



  //   // F블록레벨에서 F블록이 아닌 블록
  //   const notBlockF = buildings.filter(b => !/^[F][\d]+$/i.test(b.name));
  //   if (notBlockF.length > 0) {
  //     const errorPolygons: Polygon[] = [];
  //     notBlockF.map(block => {
  //       block.polygon.forEach(poly => {
  //         errorPolygons.push(poly);
  //         switchLineDashedState(poly.lineMesh.material, true);
  //       })
  //     })

  //     this.loadFileErrorLogs.push(makeWarningInformation2('[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
  //       `F블록으로 설정되지 않은 ${notBlockF.length}개의 블록이 있습니다. `,
  //       new THREE.Group(), undefined, {
  //       windows: errorPolygons,
  //     }));
      
      
  //     return false;
  //   }

  //   let blockFError = false; // 블록 F 존재여부
  //   let blockBError = false;

  //   if (buildings.length === 0) {
  //     blockFError = true;
  //     blockBError = true;
  //   }    
  //   let blockFName = "";

  //   let coreHouseError = false;

  //   buildings.forEach(building => { // blockF
  //     if (!building.name.toUpperCase().startsWith("F")) {
  //       blockFError = true;
  //     }
  //     else blockFName = building.name;

  //     const notBlockB = building.parts.filter(b => !/^[B][\d]+$/i.test(b.name));
  //     if (notBlockB.length > 0) {
  //       const errorPolygons: Polygon[] = [];
  //       notBlockB.map(block => {
  //         block.polygon.forEach((poly: Polygon) => {
  //           errorPolygons.push(poly);
  //           switchLineDashedState(poly.lineMesh.material, true);
  //         });
  //       })        

  //       this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
  //         `B블록으로 설정되지 않은 ${notBlockB.length}개의 블록이 있습니다. `,
  //         new THREE.Group(), undefined, {
  //         windows: errorPolygons,
  //       }));
  
  //       return false;
  //     }
      
  //     let notCoreAndHouse = 0;
  //     let notCoreAndHousePart: any = [];
  //     building.parts.forEach((part: BuildingPart) => { // blockB  
  //       let core = 0, house = 0;
  //       if (!part || !/^[B][\d]+$/i.test(part.name)) {
  //         return;
  //       }
  //       part.parts.forEach(component => {
  //         console.log(component);

  //         //@ts-ignore
  //         if (component.componentType === "core" && component.name.toUpperCase().startsWith("C")) core++;
  //         //@ts-ignore
  //         else if (component.componentType === "house" && component.name.toUpperCase().startsWith("U")) house++;
  //         else {
            
  //           notCoreAndHouse++;
  //           notCoreAndHousePart.push(component);
  //         }
  //       })

  //       if (core === 0) {
  //         this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
  //           `${part.name} 안에 코어 블록 C가 없습니다.`, new THREE.Group(), undefined, { components: [part] }));
            
  //       }
  //       if (house === 0) {
  //         this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
  //           `${part.name} 안에 유닛 블록 U가 없습니다.`, new THREE.Group(), undefined, { components: [part] }));
  //       }

  //       if (part.buildingType !== "group") {
  //         blockBError = true;
  //       }

  //       // B블록 안에 WIN1/WIN2레이어가 있는경우
  //      //!지우지말기 
  //      this.checkWindowInBuilding(part.polygon, part.name);

  //       // B블록 안에 폴리곤/폴리라인 이 있는경우 (WIN레이어 제외)
  //       if (part.polygon.length > 0) {
  //         //let polygons: Polygon[] = [];
  //         let polygonType = new Set();
  //         part.polygon.forEach((poly: Polygon) => {      
  //           if (!/^WIN[1-2]$/i.test(poly.layer)) {
  //             polygonType.add(getPolygonType(poly.type, poly.shape));
  //            // polygons.push(poly);  
  //           }
  //         })
  //         polygonType && this.loadFileErrorLogs.push(makeInfoInformation('[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
  //           `${part.name}으로 지정되지 않은 ${Array.from(polygonType).join('/')} 이 존재하여 자동 삭제 되었습니다.`, new THREE.Group(), undefined, {
  //          // windows: polygons,
  //         }));
  //       }
  //     })
  //     console.log(notCoreAndHouse, '3333');
      
  //     //U/C블록) 에 U/C블록)으로 설정되지 않은 블록이 있을 경우 (모든 블록)
  //     if (notCoreAndHouse) {
  //       this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
  //       `U/C블록 으로 설정되지 않은 ${notCoreAndHouse}개의 블록이 있습니다.`, new THREE.Group(), undefined, { components: notCoreAndHousePart }));
  //     }

  //     // F블록 안에 WIN1/WIN2레이어가 있는경우
  //  //!지우지말기  
  //   this.checkWindowInBuilding(building.polygon, building.name);

  //     // (F블록) 으로 지정되지 않은 N개의 (폴리라인/폴리곤) 이 있을 경우 (WIN레이어 제외)
  //     if (building.polygon.length > 0) {
  //       let polygonType = new Set();
  //       let polygons: Polygon[] = [];
  //       building.polygon.forEach((poly: Polygon) => {
  //         if (!/^WIN[1-2]$/i.test(poly.layer)) {
  //           polygonType.add(getPolygonType(poly.type, poly.shape));
  //           polygons.push(poly);
  //         }
  //       })

  //       this.loadFileErrorLogs.push(makeInfoInformation('[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다. ',
  //         `${building.name}으로 지정되지 않은 ${Array.from(polygonType).join('/')} 이 존재하여 자동 삭제 되었습니다.`,
  //         new THREE.Group(), undefined, {
  //         windows: polygons,
  //       }));
  //     }
  //   })

  //   if (blockFError) {
  //     this.loadFileErrorLogs.push(
  //       makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `동 평면 블록 F1이 없습니다. `,
  //         new THREE.Group(), [], {components: [...this.cores, ...this.houses]}));
  //     return false;
  //   }
  //   if (blockBError) {
  //     this.loadFileErrorLogs.push(makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, 
  //     `${blockFName} 안에 코어 공유세대 블록 B1이 없습니다.`,
  //       new THREE.Group(), [], {components: [...this.cores, ...this.houses]}));
  //     return false;
  //   }
  //   if (coreHouseError) return false;
  //   return true;
  // }


  /* F블록/B블록 안에 WIN1/WIN2 레이어의 라인/폴리라인/폴리곤이 있을 경우 */
  // checkWindowInBuilding = (polygons: Polygon[], blockName: string) => {
  //   let win1: Polygon[] = [];
  //   let win2: Polygon[] = [];
  //   let win: Polygon[] = [];

  //   polygons.forEach(poly => {
  //     let layerName = poly.layer.toUpperCase();
  //     layerName === "WIN1" && win1.push(poly);
  //     layerName === "WIN2" && win2.push(poly);
  //     switchLineDashedState(poly.lineMesh.material, true);
  //   })
  //  }



  
  dataInitialize = () => {    
    Object.values(this.parsingOutput).forEach(value => {
      while (value.length  > 0) {
        value.splice(0, 1);
      }
    })
    
    this.polygon2DGroup.children = [];
    this.polygon3DGroup.children = [];
    this.bbox = new THREE.Box3();
        
    while (this.blockBs.length > 0) {
      this.blockBs.splice(0, 1);
    }
    while (this.cores.length > 0) {
      this.cores.splice(0, 1);
    }
    while (this.houses.length > 0) {
      this.houses.splice(0, 1);
    }


    this.errorList.resetError();
    this.Switch2D3D(false);

    this.setState({
      showBlock: false,
      is2D: true,
      clickedBuildingPart: null,
      isSaved: false,
      loadFile: false,
      currentLog: "ALL",
      errorLog: false,
      errorSize: 0,
      warningSize: 0,
      infoSize: 0,
      selectBuilding: {
        bay: 0,
        type: "선택",
      },
      colorInfoType: {
        concrete: [],
        unit: [],
        core: [],
        lightWindow: [],
        normalWindow: [],    
      },
      showBlockInfo: false,
      showFontError: false,
    })
    
    this.notComponentBlocks = [];
  }

  setGroupByColor = () => {
    let concrete: Polygon[] = [];
    let unit: Polygon[] = [];
    let core: Polygon[] = [];
    let lightWindow: Polygon[] = [];
    let normalWindow: Polygon[] = [];

    this.parsingOutput.buildings.forEach(blockF => {
      grouping(blockF);
    })
    

    function grouping(block: ConverterBuilding | BuildingPart | BuildingHouseUnit | BuildingCoreUnit | BuildingGroup) {
      block.polygon.forEach((polygon: Polygon) => checkLayerName(polygon));
      block.parts.forEach(part => grouping(part));
      //@ts-ignore
      if (block.buildingType && block.buildingType === "component") {
        //@ts-ignore
        if (block.componentType === "house") unit = unit.concat(block.polygon);
        //@ts-ignore
        else if (block.componentType === "core") core = core.concat(block.polygon);
      }
    }

    function checkLayerName(polygon: Polygon) {
      switch (polygon.layer.toUpperCase()) {
        case "CON":
          concrete.push(polygon);
          break;
        case "WIN1":
          lightWindow.push(polygon);
          break;
        case "WIN2":
          normalWindow.push(polygon);
          break;
        default:
          break;
      }
    }
    this.setState({
      colorInfoType: {
        concrete: this.state.colorInfoType.concrete.concat(concrete),
        unit: this.state.colorInfoType.unit.concat(unit),
        core: this.state.colorInfoType.core.concat(core),
        lightWindow: this.state.colorInfoType.lightWindow.concat(lightWindow),
        normalWindow: this.state.colorInfoType.normalWindow.concat(normalWindow),
      }
    });

  }

  loadFilebyFile = async (file: any) => {
    this.dataInitialize();

    if (!file[0].name.endsWith('.dxf')) {
      this.setState({ loadingPage: false });
      this.setState({
        openModal: true,
        modalOptions: {
          title: "파일 업로드 실패",
          content: <div>dxf 파일을 올려주세요.</div>,
          negative: 'hidden',
          positive: () => { this.setState({ openModal: false}); },
        }
      })
      return;
    }
    this.setState({ loadingPage: true });


    let data = await asyncFileRead(file);
    if (!data)
      return;

    this.fileData = data;

    const layerOfPoint = new Set();
    this.block = blockParsing(data, this.parsingOutput,ConverterType.myType, layerOfPoint);
    App.stage !== "prod" && console.log(this.parsingOutput, 'parsing 후');

    if (layerOfPoint.size) {
      this.errorList.addError(new TypeError(
        {
          title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
          msg: `${Array.from(layerOfPoint).join('/')}의 불필요한 Point 가 자동 삭제 되었습니다.`,
          id: [],
          type: ErrorType.Info,
        }));
    }



    this.setGroupByColor();
    setBlockNameNumber(this.parsingOutput.buildings);


    //! START
    { //* 블럭 구조 체크      
      this.parsingOutput.buildings.forEach(blockD => {
        blockD.parts.forEach(blockB => {
          this.blockBs.push(blockB);
          blockB.parts.forEach((component) => {
            //@ts-ignore
            if (component !== undefined && component.componentType === "core") {
              this.cores.push(component as BuildingCoreUnit);
              this.errorLogs.push((component as BuildingCoreUnit).ErrorLog);
            }
            //@ts-ignore
            else if (component !== undefined && component.componentType === "house") {
              this.houses.push(component as BuildingHouseUnit);
              this.errorLogs.push((component as BuildingHouseUnit).ErrorLog);
            } 
            else {
              this.notComponentBlocks.push(component);
            }
          })
        })
      })

      const isBlockStructOK = checkBlockStruct(this.parsingOutput.buildings, this.errorList, this.parsingOutput.wrongPolygons, ConverterType.myType);
      const isConnected = checkConnectionComponent({
        wrongPolygons: this.parsingOutput.wrongPolygons,
        buildings: this.parsingOutput.buildings,
        blockBs: this.blockBs,
        cores: this.cores,
        houses: this.houses,
      }, this.errorList);


      if (isBlockStructOK && isConnected) {
        
        // 블럭 이름 체크  
        checkBlockName(this.parsingOutput.buildings, this.notComponentBlocks, this.errorList);
  
  

        // 레이어 이름 체크
        checkLayerNameError([...this.houses, ...this.cores,...this.parsingOutput.buildings, ...this.blockBs], this.errorList)
      
        // 전용면적만 들어온경우
        this.block.forEach(block => {
          let splitedName = block.name.toUpperCase().split('_');
          if (block.type === "house" && (/^[U](\d+)[_](\d+)$/i.test(block.name) || /^[U]\d[_](\d*)[.](\d*)$/i.test(block.name))) { // 'u_정수', 'u_소수'의 경우
            this.errorList.addError(new TypeError({
              title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
              msg: `${block.name} 의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
              type: ErrorType.Info,
              id: this.houses.filter(h => h.name === block.name).map(h => h.uuid),
              components: this.houses.filter(h => h.name === block.name),
            }));
            
            // this.loadFileErrorLogs.push(makeInfoInformation(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
            //   `${block.name}의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
            //   new THREE.Group(), [], {
            //   components: this.houses.filter(h => h.name === block.name)
            // }
            // ));
          }
        })
        
      
        this.setState({
          blocks: this.block
        })
        
        checkTypeError(
          this.loadFileErrorLogs,
          {
            wrongPolygons: this.parsingOutput.wrongPolygons,
            buildings: this.parsingOutput.buildings,
            blockBs: this.blockBs,
            cores: this.cores,
            houses: this.houses,
          }, this.settingData.dataUnit, this.errorList);

          this.setState({
            errorLogs: this.errorList.getError(),
          });
        // 완료된 컴포넌트에 면 표현
        {
          this.cores.forEach(core => {
            core.CheckCompleteness(this.errorList);
            if (this.errorList.isCompleteById(core.uuid)) {
              core.polygon.forEach(poly => { if (poly.layer.toUpperCase() === "CON") poly.innerMesh.visible = true; })
            }
          });
          this.houses.forEach(house => {
            house.getHouseError(this.errorList);
            house.CheckCompleteness(this.errorList);
            if (this.errorList.isCompleteById(house.uuid)) {
              house.polygon.forEach(poly => { if (poly.layer.toUpperCase() === "CON") poly.innerMesh.visible = true; })
            }
          });

        }
      }
    }


    [...this.cores, ...this.houses, ...this.blockBs, ...this.parsingOutput.buildings].forEach(block => {
      this.polygon2DGroup.add(block.ErrorPolygonGroup);
    })
    this.parsingOutput.wrongPolygons.forEach(polygon => {
      this.polygon2DGroup.add(polygon.lineMesh.material);
    })
    this.polygon2DGroup.updateWorldMatrix(true, true);

    this.setPolyLabel();


    this.parsingOutput.buildings.forEach(b => {
      this.polygon2DGroup.add(b.renderGroup);
    })
    if (this.state.errorSize === 0) {
      this.houses.forEach(h => {

        h.polygon.forEach(poly => {
          poly.lineMesh.opacity = 1;
        })
      })
      this.cores.forEach(h => {
        h.polygon.forEach(poly => {
          poly.lineMesh.opacity = 1;
        })
      })
    }

    
    this.setState({
      errorLogs: this.errorList.getError(),
    })
//    this.errorLogs.forEach();

    this.recalculateResult();

    this.bbox.makeEmpty();
    this.bbox.setFromObject(this.polygon2DGroup);
    let center = new THREE.Vector3();
    this.bbox.getCenter(center);

    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.setState({
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadingPage: false,
      showBlock: true,
      loadFile: true,
      openSnack: true,
      snackMsg: <div className="font font-emphasis msg font-pretendard">F블록에서 <span className="font font-special">동평면 설정(필수)</span>을 진행 후 저장해주세요!</div>,
      clickedBuildingPart: this.parsingOutput.buildings.length > 0 ? this.parsingOutput.buildings[0] : null,
      
    }, () => {
      this.onWindowResize();

    })
    
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);

    this.houses.forEach(h => {
      h.polygon.forEach(p => {
        p.lineMesh.material.uniforms.resolution.value = new THREE.Vector2(this.sceneManager.canvasElement.clientWidth, this.sceneManager.canvasElement.clientHeight);
        p.lineMesh.material.needUpdate = true;
        //        p.lineMesh.resolution.set(this.state.screenWidth, this.state.screenWidth,);
      })
    })
  }

  getFrustumSize = (aspect: number) => {
    let bboxSize = new THREE.Vector3(0);
    this.bbox.getSize(bboxSize);
    let frustumSize = bboxSize.x / 2 * 1.1;
    if (aspect > bboxSize.x / bboxSize.y) {
      let height = bboxSize.y / 2 * 1.1;
      frustumSize = height * aspect;
    }
    return frustumSize;
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }
  loadDXFFileOutside = async (file: any) => {
    if (file) {
      this.loadFilebyFile([file]);
    }
  }
  setErrorByType = (type: ErrorType) => {
    this.setState({
      errorLogs: this.errorList.getErrorByType(type)
    });
  }


  onWindowResize = () => {
    if (window.innerWidth >= 1200) {
      this.setState({
        screenWidth: window.innerWidth - 640 * (this.state.showBlock ? 1 : 0),
        screenHeight: window.innerHeight + (this.state.loadFile ? - 122 : 0) - (this.state.errorLog ? 286 : 30) + (!this.state.loadFile ? 30 : 0),
      });
    }
  }

  recalculateResult = () => {
    let totalExclusiveAreas = 0;
    let totalServiceAreas = 0;
    let totalCommonWallAreas = 0;
    let totalCoreAreas = 0;
    let houseNumber = 0;

    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        p.UpdateArea();
        totalExclusiveAreas += p.totalExclusiveAreas;
        totalServiceAreas += p.totalServiceAreas;
        totalCommonWallAreas += p.totalCommonWallAreas;
        totalCoreAreas += p.totalCoreAreas;
        houseNumber += p.houseNumber;
      })
    })

    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.splice(0, 1);
    }
    // let error = 0, waring = 0, info = 0;
    // this.errorLogs.forEach(els => {
    //   els.forEach(el => {
    //     if (el.Type === ErrorType.Error) error++;
    //     if (el.Type === ErrorType.Warning) waring++;
    //     if (el.Type === ErrorType.Info) info++;
    //   })
    // })
    const error = this.errorList.getErrorSize();
    const warning = this.errorList.getWarningSize();
    const info = this.errorList.getInfoSize();
    
    

    [...this.cores, ...this.houses].forEach(block => {
      block.checkAreaError(this.errorList);
    })
    this.setState({
      totalExclusiveAreas: totalExclusiveAreas.toFixed(4),
      totalServiceAreas: totalServiceAreas.toFixed(4),
      totalCoreAreas: totalCoreAreas.toFixed(4),
      totalCommonWallAreas: totalCommonWallAreas.toFixed(4),
      buildingArea: (totalExclusiveAreas + totalServiceAreas + totalCoreAreas + totalCommonWallAreas).toFixed(4),
      groundArea: (totalExclusiveAreas + totalCoreAreas + totalCommonWallAreas).toFixed(4),
      houseNumber: houseNumber,
      errorSize: error,
      warningSize: warning,
      infoSize: info,
      errorLogs: this.errorList.getError(),
      errorLog: error + warning + info > 0 ? true : false,
    });
  }

  Switch2D3D = (is3D: boolean) => {
    

    if (is3D) {
      if (this.state.errorSize > 0) {
        this.showModal('알림', ['코어와 세대의 세팅을 완성해 주세요.'], buttonNum.oneButton, () => { });
        return;
      }
      this.sceneManager.switchRenderCamera(!is3D);

      this.polygon3DGroup.children = [];
      this.polygon3DGroup.add(this.makeReviewBuilding());
      let bbox = new THREE.Box3();
      bbox.expandByObject(this.polygon3DGroup);
      let bboxCenter = new THREE.Vector3();
      bbox.getCenter(bboxCenter);
      this.sceneManager.set3DViewerCameraPosition(bboxCenter);
    }
    else {
      this.sceneManager.switchRenderCamera(!is3D);

    }
    this.polygon2DGroup.children.forEach(c => {
      c.visible = !is3D;
    })

    this.polygon3DGroup.children.forEach(c => {
      c.visible = is3D;
    })

    this.setState({
      is2D: !is3D,
    })
  }

  makeReviewBuilding = () => {
    let tmpGroup = new THREE.Group();
    this.parsingOutput.buildings.forEach((block, i) => {
      block.parts.forEach(p => {
        p.RebuildOutputPolygon();
      })
  
      let building = makeBlockBuildingData(block);
  
      building.floorHeight = [2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8];
      building.floorStatus.forEach(fs => {
        fs.coreFloorInfo.forEach(fi => {
          fi.floorCategory = ["FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE"]
        })
        fs.houseFloorInfo.forEach(hi => {
          hi.floorCategory = ["FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE"]
        })
      })
//      return MakeBuildingMesh(building);
      let result =  MakeBuildingMesh(building, [], true);
     // result.position.set(building.position.x * 0.001, 0, building.position.y * 0.001 * 1);
      //@ts-ignore
   //   result= new THREE.Vector3(building.x, building.y, building.z);
      tmpGroup.add(result);
    })
    return tmpGroup
    // this.parsingOutput.buildings[0].parts.forEach(p => {
    //   p.RebuildOutputPolygon();
    // })

    // let building = makeBlockBuildingData(this.parsingOutput.buildings[0]);

    // building.floorHeight = [2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8];
    // building.floorStatus.forEach(fs => {
    //   fs.coreFloorInfo.forEach(fi => {
    //     fi.floorCategory = ["FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE"]
    //   })
    //   fs.houseFloorInfo.forEach(hi => {
    //     hi.floorCategory = ["FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE"]
    //   })
    // })
    // return MakeBuildingMesh(building);
  }


  makeSaveMetaFile = async (savePath: string) => {
    let meta = {
      blocks: this.block.map(b => b.toJson()),
      entities: this.parsingOutput.buildings.map(b => b.toJson()),
    }

    saveDataToS3(JSON.stringify(meta), savePath, 'meta.json', 'application/json');
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  handleBuildingInfo = (bay: number, type: string) => {
    // 베이수, 동타입
    this.setState({
      selectBuilding: {
        bay,
        type
      }
    })
  }

  saveToAWS = async (fn: string) => {
    try {

      let uuid = uuid4();
      App.stage !== "prod" && console.log(uuid);
      let globalID, privateID;
      if (this.state.loadDB) {
        globalID = this.state.loadDB.global_id;
        privateID = this.state.loadDB.user_id;
      }
      else {
        let id = await incrementCounter("my_building_type_id", this.state.userID);
        globalID = id.globalId;
        privateID = id.userId;
      }
      App.stage !== "prod" && console.log(globalID, privateID);

      let imageName = `img_large.png`;
      let buildingTemplateName = `${uuid}.json`;
      let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;
      this.makeSaveMetaFile(S3SavePath);
      this.parsingOutput.buildings.length > 0 && this.parsingOutput.buildings[0].renderGroup.children.forEach(c => {
        if (c.type !== 'Group') this.parsingOutput.buildings[0].renderGroup.remove(c);
      });

      let captureBbox = new THREE.Box3();
      captureBbox.expandByObject(this.parsingOutput.buildings[0].renderGroup);

      // capture image
      if (!this.state.is2D) { this.Switch2D3D(false); }
      this.parsingOutput.buildings.forEach(building => {
        building.parts.forEach(blockB => {
          blockB.parts.forEach(part => {
            part.polygon.forEach((poly: Polygon) =>
              {
                poly.lineMesh.material.opacity = 1;
                //@ts-ignore
                poly.innerMesh.material.opacity = 0.5;
              })
          })
        });
      })
      this.parsingOutput.wrongPolygons.forEach(polygon => {
        polygon.lineMesh.visible = false;      
      })
      // wrongPolygon을 renderGroup에서 제외
      
      let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
      let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
      let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

      saveDataToS3(lImgBuf, S3SavePath, imageName, 'image/png');
      saveDataToS3(mImgBuf, S3SavePath, 'img_middle.png', 'image/png');
      saveDataToS3(sImgBuf, S3SavePath, 'img_small.png', 'image/png');

      // template data
      this.parsingOutput.buildings[0].parts.forEach(p => {
        p.RebuildOutputPolygon();
        p.UpdateArea();
      })

      let outputData = makeBlockBuildingData(this.parsingOutput.buildings[0]);
      outputData.building.name = uuid;
    //TODO
      outputData.building.mainBay = this.state.selectBuilding.bay;
      
      outputData.building.exclusiveAreaList = this.houses.map(h => { return { name: h.name, area: h.exclusiveArea } });
      let building_shape_type = "";
            
      switch(this.state.selectBuilding.type) {
        case "복도식 I형":
          building_shape_type = "LINEAR_CORRIDOR_TYPE";
          break;
        case "복도식 L형":
          building_shape_type = "FOLDED_CORRIDOR_TYPE";
          break;
        case "판상 I형":
          building_shape_type = "LINEAR_NON_CORRIDOR_TYPE";
          break;
        case "판상 L형":
          building_shape_type = "FOLDED_NON_CORRIDOR_TYPE";
          break;
        case "타워 T형":
          building_shape_type = "T_TOWER_TYPE";
          break;
        case "타워 Y형":
          building_shape_type = "Y_TOWER_TYPE";
          break;
        default:
          building_shape_type = "USER_DEFINED_TYPE";
          break;
      }
    
      outputData.building.buildingShapeType = building_shape_type;



      App.stage !== "prod" && console.log(outputData.building);

      // dynamoDB data    
      let data = new Date().toISOString();
      let dbItem: buildingTypeStruct = {
        stage: App.tempStage,
        global_id: globalID,
        user_id: privateID,
        uuid: uuid,
        name: fn,
        email: this.state.userID,
        total_exclusive_area: Number(this.state.totalExclusiveAreas),
        total_service_area: Number(this.state.totalServiceAreas),
        core_area: Number(this.state.totalCoreAreas),
        building_area: Number(this.state.buildingArea),
        floor_area: Number(this.state.groundArea),
        common_wall_area: Number(this.state.totalCommonWallAreas),
        houses_number: this.state.houseNumber,
        img_path: `s3://${S3SavePath}/${imageName}`,
        file_path: `s3://${S3SavePath}/${buildingTemplateName}`,
        meta_path: `s3://${S3SavePath}`,
        created_at: data,
        modified_at: data,
        deleted: false,
        //TODO
        main_bay: outputData.building.mainBay,
        exclusive_area_list: outputData.building.exclusiveAreaList,
        building_shape_type: outputData.building.buildingShapeType,
      }
      App.stage !== "prod" && console.log(dbItem);
      saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');
      saveDataToS3(JSON.stringify(outputData.building), S3SavePath, buildingTemplateName, 'application/json');
      saveDataToDynamoDB(dbItem, this.DBTableName);
      this.setState({ isSaved: true });
      
      this.setState({
        openModal: true,
        modalOptions: {
          title: "나의 동평면 저장 완료",
          content: <div>'{fn}'의 저장이 완료되었습니다.</div>,
          positive: () => {
            window.location.replace("/cad/myTypeBlock");
          },
          negative: () => {
            window.location.href = "/myPage/file/mybuildingType";
          },
          negativeTitle: "나의 동평면 바로가기",
        }
      })
      // this.showModal('알림', ['나의 동평면을 저장했습니다.'], buttonNum.oneButton, () => {App.stage !== "prod" && console.log('save done')});
      return true;
    }
    catch {
      // this.showModal('알림', ['나의 동평면을 저장에 실패 했습니다.'], buttonNum.oneButton, () => {
      // })
      return false;
     
    }
  }

  clickBlockUI = (buildingPart: BuildingPart | ConverterBuilding) => {
    this.setState({ clickedBuildingPart: buildingPart })
  }

  showSaveModal() {
    this.setState({ 
      showSaveModal: false,
      isSaved: false,
    });
  }

  changeSaveFileName = (name: string) => {
    this.setState({ saveName: name });
  }

  reset = () => {
    this.setState({
      loadFile: false,
    });
    this.setState({
      screenWidth: window.innerWidth, //- (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 60,
    });
    this.dataInitialize();
  }

  changeCurrentLog = (id: string) => {
    if (this.state.currentLog === id || id === "ALL") {
      this.setState({
        errorLogs: this.errorList.getError(),
        currentLog: "ALL",
      });
    }
    else {
      this.setState({
        errorLogs: this.errorList.getErrorById(id),
        currentLog: id,
      })
    }    
  }

  findUUIDBlock(uuid: string) {
    let result: any = undefined;
    for (let i = 0; i < this.parsingOutput.buildings.length; i++) {
      checkUUID(this.parsingOutput.buildings[i]);
      if (result) {
        return result;
      }
    }

    function checkUUID(block: any) {
      if (block.uuid === uuid) {
        result = block;
        return;
      }
      else {
        if (block.parts) {
          for (let i = 0; i < block.parts.length; i++) {
            checkUUID(block.parts[i]);
            if (result) break;
          }
        } 
      }
    }    
    return null;
  }


  openModal = (modalOptions: ModalOptions, showFontError?: boolean) => {
    if (showFontError !== true) showFontError = false;
    this.setState({ 
      openModal: true,
      modalOptions,
      showFontError: showFontError,
    })
  }

  handleSettingModalType = (type: ConverterType) => {
    this.setState({ settingModalType: type })
  }

  closeModal = () => {
    this.setState({ openModal: false}, () => {
      console.log(this.state.openModal)
    })
  }

  setIsFocus = (type: LineInfoType | null) => {
    switch (type) {
      case "normal_window":
        setBlockOpacity(this.parsingOutput.buildings, 0.2);
        handleOpacityCertainType(this.state.colorInfoType.normalWindow, 1, "WIN2");
        break;
      case "light_window":
        setBlockOpacity(this.parsingOutput.buildings, 0.2);
        handleOpacityCertainType(this.state.colorInfoType.lightWindow, 1, "WIN1");
        break;
      case "concrete":
        setBlockOpacity(this.parsingOutput.buildings, 0.2);
        handleOpacityCertainType(this.state.colorInfoType.concrete, 1, "CON");
        break;
      case "core":
        setBlockOpacity(this.parsingOutput.buildings, 0.2);
        handleOpacityCertainType(this.state.colorInfoType.core, 1, "CORE");
        break;
      case "house":
        setBlockOpacity(this.parsingOutput.buildings, 0.2);
        handleOpacityCertainType(this.state.colorInfoType.unit, 1, "UNIT");
        break;
      case null:
        setBlockOpacity(this.parsingOutput.buildings, 1);
        break;
    }

  };

  render = () => {
    return (
      <div className="buildit-template">
        {
          this.state.openModal && 
          <Modal
            open={this.state.openModal}
            color="DARK"
            type="MOBILE"
            negative={this.state.modalOptions.negative}
            positive={this.state.modalOptions.positive}
            title={this.state.modalOptions.title}
            positiveTitle={this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions.content}
            negativeTitle={this.state.modalOptions.negativeTitle}
          />
        }
        <MySiteBlockSaveModal
          DBTableName={this.DBTableName}
          converterType={ConverterType.myType}
          userId={this.state.userID}
          showModal={this.state.showSaveModal}
          onShowSaveModal={() => this.showSaveModal()}
          errorSize={this.state.errorSize}
          fileName={this.state.saveName}
          onSave={async () => {
            const result = await this.saveToAWS(this.state.saveName);
            return result;
          }}
          typeArea={{
            totalHouseholds: this.state.houseNumber,
            totalExclusiveArea: this.state.totalExclusiveAreas,
            totalCommonWallArea: this.state.totalCommonWallAreas,
            totalCoreArea: this.state.totalCoreAreas,
            totalBuildingArea: this.state.buildingArea,
            mainBay: this.state.selectBuilding.bay,
            buildingShapeType: this.state.selectBuilding.type,
          }}
          isSaved={this.state.isSaved}
          parsingOutput={this.parsingOutput}
          onChangeFileName={this.changeSaveFileName}
          buildingObject={this.makeReviewBuilding}
        />
        <CadConverterModal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></CadConverterModal>
        {/* <SaveViewerModal open={this.state.showSaveModal} buildingObject={this.makeReviewBuilding} positive={this.checkSaveState} /> */}
        <LoadingPage show={this.state.loadingPage} loadingMsg="동평면 파일을 불러오는 중입니다." />
        <Setting
          converterType={this.state.settingModalType}
          closeModal={() => this.setState({ showSettingModal: false })}
          open={this.state.showSettingModal}
          settingData={this.settingData}
          handleConverterType={this.handleSettingModalType}
        />
        <ConverterHeader
          type={ConverterType.myType}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={() => {
            this.reset();
          }}
          closeModal={this.closeModal}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          infoSize={this.state.infoSize}
          isFileOpen={this.state.loadFile}
          isLatest={true}
          fillInRequiredField={this.state.selectBuilding.bay !== 0 && this.state.selectBuilding.type !== "선택"}
          saveFile={() => {
            if (this.state.selectBuilding.bay === 0 || this.state.selectBuilding.type === "선택") {
              this.openModal(
                {
                  title: "동평면 저장 알림",
                  positiveTitle: "확인",
                  negative: "hidden",
                  content: <div>필수 설정을 완료 후 저장이 가능합니다.<br />필수 설정(베이, 동타입)은 F블록에서 설정해 주세요!</div>,
                  positive: () => { this.setState({ openModal: false }); },//this.setState({ openModal: false }) },
                }, true
              );
            }
            else {
              this.setState({ showSaveModal: true })
            }
          }}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.openModal}//{this.showModal}
        />
        <div className="MainBody">
        {this.cores.map((c) => (
            <>
              <div className={`infoIcon info${c.uuid}`} key={`info${c.uuid}`}
              hidden={!this.state.is2D}
                onMouseEnter={() => {
                  const element = document.querySelector(`.TT${c.uuid}`);
                  if (element) {
                    (element as HTMLDivElement).style.visibility = "visible";
                    //@ts-ignore
                    element.mouseEnter = true;
                  }
                }}
                onMouseLeave={() => {
                  const element = document.querySelector(`.TT${c.uuid}`);
                  if (element) {
                    (element as HTMLDivElement).style.visibility = "hidden";
                    //@ts-ignore
                    element.mouseEnter = undefined;
                  }
                }}
              >
                <Info />
              </div>
            </>
          ))}

          {this.houses.map((h) => (
            <>
              <div className={`infoIcon info${h.uuid}`}
              hidden={!this.state.is2D}
                key={`info${h.uuid}`}
                onMouseEnter={() => {
                  const element = document.querySelector(`.TT${h.uuid}`);
                  if (element) {
                    (element as HTMLDivElement).style.visibility = "visible";
                    //@ts-ignore
                    element.mouseEnter = true;
                  }
                }}
                onMouseLeave={() => {
                  const element = document.querySelector(`.TT${h.uuid}`);
                  if (element) {
                    (element as HTMLDivElement).style.visibility = "hidden";
                    //@ts-ignore
                    element.mouseEnter = undefined;
                  }
                }}
              >
                <Info/>
              </div>
            </>
          ))}
          
          <div className="areaInfo" hidden={!this.state.is2D}>
          {this.houses.map((h) => (
              <>
                <div className={`textTest TT${h.uuid} houseInfo`} key={h.uuid}
                style={{height: "89px"}}
                >
                  <div className="key-value">
                    <li className="font font-12px key house">{`단위 세대 ${h.name.split('_')[0]}-${h.sameNameNumber}`}</li>
                  </div>
                  <div className="key-value">
                    <li className={`font font-12px key house ${h.initialArea.exclusiveArea !== h.exclusiveArea && "active"}`}>
                    <div className={`circle-icon ${h.initialArea.exclusiveArea !== h.exclusiveArea ? "active" : "non-active"}`} />
                      전용면적
                    </li>
                    <span className={`font font-12px value ${h.initialArea.exclusiveArea !== h.exclusiveArea && "active"}`}>{`${h.exclusiveArea}`}㎡</span>
                  </div>
                  <div className="key-value">
                    <li className={`font font-12px key house ${h.initialArea.serviceArea !== h.serviceArea && "active"}`}>
                      <div className={`circle-icon ${h.initialArea.serviceArea !== h.serviceArea ? "active" : "non-active"}`} />
                      서비스면적
                    </li>
                    <span className={`font font-12px value ${h.initialArea.serviceArea !== h.serviceArea && "active"}`}>{`${h.serviceArea}`}㎡</span>
                  </div>
                  <div className="key-value">
                    <li className={`font font-12px key house ${h.initialArea.commonWallArea !== h.commonWallArea && "active"}`}>
                      <div className={`circle-icon ${h.initialArea.commonWallArea !== h.commonWallArea ? "active" : "non-active"}`} />
                      벽체공용면적
                    </li>
                    <span className={`font font-12px value ${h.initialArea.commonWallArea !== h.commonWallArea && "active"}`}>{`${h.commonWallArea}`}㎡</span>
                  </div>
                </div>
              </>
            ))}
            {this.cores.map((c) => (
              <>
                <div className={`textTest font font-emphasis font-12px TT${c.uuid} coreInfo`} key={c.uuid}
                  style={{height: "54px"}}
                >
                  <div className="key-value">
                    <li className="font font-12px core key">{`코어 ${c.name.split('_')[0]}-${c.sameNameNumber}`}</li>
                  </div>
                  <div className="key-value">
                    <li className={`font font-12px core key ${c.GetArea() !== c.initialArea ? "active": ""}`}>
                      <div className={`circle-icon ${c.GetArea() !== c.initialArea ? "active" : 'non-active'}`} />코어면적
                    </li>
                    <span className={`font font-12px value ${c.GetArea() !== c.initialArea ? "active" : ""}`}>{`${c.GetArea()}㎡`}</span>
                  </div>
                </div>
              </>
            ))}
          </div>
          {
            this.state.loadFile &&
            <div className="information">
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">총세대수</div>
                <div className="inforValue font font-emphasis font-14px">{this.state.houseNumber}세대</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">총전용면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(Number(this.state.totalExclusiveAreas).toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">총서비스면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(Number(this.state.totalServiceAreas).toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">총벽체공용면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(Number(this.state.totalCommonWallAreas).toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">총코어면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(Number(this.state.totalCoreAreas).toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">건축면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(Number(this.state.buildingArea).toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">바닥면적(연면적산출용)</div>
                <div className="inforValue font font-emphasis font-14px">{Number(Number(this.state.groundArea).toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="fileInfo">
                <span className={`dxfIcon ${this.state.loadFile && "loaded"}`}>
                  <span className={`text ${this.state.loadFile && "loaded"}`}>dxf</span>
                </span>
                <span className={`fileName ${this.state.loadFile && "loaded"}`}>{(this.state.loadFile && this.state.fileName) || "선택된 파일이 없습니다."}</span>
                {/* <span className={`layerToggle ${this.state.loadFile && 'loaded'}`} onClick={() => this.state.loadFile && this.setState({ showBlock: !this.state.showBlock })}>블록 보기</span> */}
              </div>
            </div>
          }

          <div className="RenderView">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.openSnack}
              onClose={() => { this.setState({ openSnack: false }) }}//{this.handleSnackClose}
              className="snack-bar"
              autoHideDuration={5000}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={this.state.snackMsg}
            />
            <div className="Scene">
              <div
                className="Canvas"
                ref={(mount) => {
                  this.mount = mount;
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  // //@ts-ignore
                  // if (e.target.className.baseVal.indexOf("icon") === -1) {

                    this.setState({ clickedBuildingPart: null });

                  setBlockOpacity(this.parsingOutput.buildings, 1);
                }}
              >
                <DropFileBox functionForButton={this.loadDXFFile} functionForDrop={this.loadFilebyFile} loadFile={this.state.loadFile} type={ConverterType.myType} showSample={this.state.showSample} 
                isBlockVer={true}
                />
                {this.state.loadFile && (
                  <Button
                    className="btn bg-navy btn-primary fold-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ showBlock: !this.state.showBlock });
                    }}
                  >
                    {this.state.showBlock ? <NavigateNext className="icon icon-next" /> : <ChevronLeft className="icon icon-next" />}
                  </Button>
                )}

                {/* <div className={`toolBar ${!this.state.loadFile && 'hidden'} 
                     ${this.state.showBlock ? '' : 'showBlock'}  
                     `
                }> */}

                <div className={`toolBar ${!this.state.loadFile && "hidden"}`}>
                  <div className="rightButtons">
                    <div className="switchTabs">
                      <Button className={`switchButton ${this.state.is2D && "enable"}`} onClick={() => this.Switch2D3D(false)}>
                        CAD
                      </Button>
                      <Button className={`switchButton ${!this.state.is2D && "enable"}`} onClick={() => this.Switch2D3D(true)}>
                        3D VIEW
                      </Button>
                    </div>
                    <Tooltip place="left" msg="블록 정보 보기" arrowOn={false}>
                      <Button
                        className={`btn bg-navy btn-secondary infoButton ${this.state.showBlockInfo ? "active" : ""}`}
                        onClick={() => {
                          this.setState({ showBlockInfo: !this.state.showBlockInfo });
                        }}
                      >
                        <Info className={`icon info-icon font font-emphasis ${this.state.showBlockInfo ? "font-special" : ""}`} />
                      </Button>
                    </Tooltip>
                  </div>
                  {/* <div className={`autosetDiv ${!this.state.showAutoSetting && 'hidden'}`}>
                  <Button className='autosetButton' onClick={() => { }} >자동 추가</Button>
                  <div className='autosetMessage'>정해진 규칙에 따라 생성된 레이어는 변환 설정값을 자동으로 처리합니다 (상세한 규칙은 메뉴얼을 참고해주세요) </div>
                </div> */}

                  {/* </div> */}
                </div>
                {
                  this.state.loadFile && this.state.is2D &&
                  <ConstructionLineInfo
                    title={["나의 동평면"]}
                    hasVisibleIcon={false}
                    isCadConverter={true}
                    darkMode={true}
                    isCollapsible={true}
                    //                  isCadConverter={this.state.canvasMode === "MAP" ? false : true}
                    className="construction-line-info"
                    typeList={[["concrete", "house", "core", "light_window", "normal_window"]]}
                    setIsFocus={this.setIsFocus}
                  // fieldBlocks={this.state.field}
                  />
                }

              </div>
              <ErrorLogBlock
                converterType={ConverterType.myType}
                show={this.state.errorLog}
                closeLog={() => this.setState({ errorLog: !this.state.errorLog })}
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                confirmSize={this.state.infoSize}
                errorList={this.state.errorLogs}
                showErrorByType={this.setErrorByType}
                currentLog={this.state.currentLog === "ALL" ? "ALL" : this.findUUIDBlock(this.state.currentLog)!.name}
                allBuildings={this.parsingOutput.buildings}
                closeCurrentLog={() => {
                  this.setState({ errorLogs: this.errorList.getError() });
                  this.setState({ currentLog: "ALL" });
                }}
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && "hidden"}`}>
              <div className={`wrapBlockList ${this.state.showBlock ? "" : "display-none z-index-back"}`}>
                <div className="header">
                  <div className="left-side">
                    <HierarchyIcon className="icon hierarchy-icon" />
                    <span className="text">목록</span>                                          
                  </div>
                </div>
                <div className={`description`}>
                  {this.state.showBlock && (
                    <BlockTreeList
                      clickedField={null}
                      parsingOutput={this.parsingOutput}
                      setClickedBlock={(block: any, type: ConverterType.myType | ConverterType.mySite) => {
                        this.setState({
                          clickedBuildingPart: block,
                        })
                      }}

                      clickComponent={this.state.clickedBuildingPart}
                      currentLog={this.state.currentLog}
                      errorList={this.errorList}
                      handleCurLogById={(id: string) => { this.changeCurrentLog(id) }}
                      handleCurLog={() => { }}
                      hasHeader={false}
                      filter={ConverterType.myType}
                      isVisible={{ site: false, type: true }}
                      clickedBuilding={this.state.clickedBuildingPart}
                      handleVisible={() => { }}
                      findBlockUUID={(id: string) => this.findUUIDBlock(id)}
                    />

                  )}
                </div>
              </div>

              <div className="functionalLayout">
                <div className="header">
                  <div className="wrapper">
                    <div className="wrap-icon m-l-md">
                      <Info className="icon info-icon font font-emphasis" />
                    </div>
                    <span className="font font-primary">속성</span>
                  </div>
                </div>
                <div className={`description ${this.state.showBlock ? "" : "display-none"}`}>
                  <div className="font font-emphasis font-14px selectedBlockName">
                    {this.state.clickedBuildingPart && (
                      <div className="title">
                        <span>
                          <BlockIcon className="block-icon font font-emphasis block-icon" />
                          {this.state.clickedBuildingPart.name.split('_')[0]}
                        </span>
                        <span className="font font-secondary font-12px block-type">
                          {this.state.clickedBuildingPart.blockType}
                        </span>
                      </div>
                    )}
                    {
                      this.state.clickedBuildingPart === null &&
                      <div className="title">
                        <span>
                          <BlockIcon className="block-icon font font-emphasis" />
                          -
                        </span>
                      </div>
                    }
                  </div>
                  {/* {(this.state.clickedBuildingPart === null || this.state.clickedBuildingPart.buildingType !== "component") && <div className="selected-not-block">세대 Block 또는 코어 Block을 클릭하시면 면적 입력이 가능합니다.</div>} */}

                  <BuildingPartUI
                    type={ConverterType.myType}
                    showFontError={(this.state.showFontError && (this.state.selectBuilding.bay === 0 || this.state.selectBuilding.type === "선택")) ? true : false}
                    updateArea={() => {
                      this.recalculateResult();
                      this.setState({
                        openSnack: true,
                        snackMsg: <div className="font font-emphasis msg font-pretendard" style={{ textAlign: "center" }}>
                          <span className="font font-special">면적이 수정되었습니다.</span><br />
                          수정된 면적은 ‘파일 관리’에서 제공되는 원본 캐드파일에는 반영되지 않습니다.</div>
                      });
                    }}
                    compoent={this.state.clickedBuildingPart}
                    onSelectBuildingInfo={this.handleBuildingInfo}
                    selectedBuildingInfo={this.state.selectBuilding}
                  />
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

     
        </div>
      </div>
    );
  }
}
