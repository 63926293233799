import { SyncAlt } from "@material-ui/icons";
import Tooltip from "../Tooltip";
import React, { Component } from "react";
import "../css/CADConverter/CadConverterTypeTag.scss";
import { ConverterType } from "./DataTypes";

export interface CadConverterTypeTagProps {
  isLatest: boolean;
  isSwitchable?: boolean;
  switchPage?: (isLatest: boolean) => void;
}



export default class CadConverterTypeTag extends Component<CadConverterTypeTagProps> {

  render() {
    if (this.props.isSwitchable) {
      return (
        <Tooltip arrowOn={false} msg={`${!this.props.isLatest ? "클릭 시 최신 버전(블록 버전)으로 전환됩니다." : "클릭 시 구버전(레이어 버전)으로 전환됩니다."}`}>
          <div
            className={`CadConverterTypeTag m-r-sm isSwitchable ${this.props.isLatest === true ? "latest" : "old"} ${this.props.isSwitchable && "width-71px"}`}
            onClick={() => {
              if (this.props.switchPage) {
                this.props.switchPage(this.props.isLatest);
              }
            }}
          >
            <div className="text">
              {this.props.isLatest === true && "최신 버전"}
              {this.props.isLatest !== true && "구버전"}
              <SyncAlt className="m-l-sm sync-icon" />
            </div>
          </div>
        </Tooltip>
      )
    }
    else {
      return (
        <div className={`CadConverterTypeTag m-r-sm ${this.props.isLatest === true ? "latest" : "old"} ${this.props.isSwitchable && "width-71px"}`}>
          <div className="text">
            {this.props.isLatest === true && "최신 버전"}
            {this.props.isLatest !== true && "구버전"}
          </div>
        </div>
      );
    }
  }
}
