import { mapProjectionData } from "./DataTypes";
import * as THREE from '@teneleven/three';
import { any } from 'prop-types';
import { PolygonOptions, Polygon, Shape, Polyline, PolylineOptions, InfoWindow, Circle, CircleOptions, MapOptions, Map, ProjectSitePolygon, Marker, MarkerOptions, InfoWindowOptions } from '../Shape';
// @ts-ignore
import { reproject } from 'reproject';
import { GeometryTypes } from "@turf/turf";

import wkx, { Geometry } from 'wkx';
import App from "../App";
import { pathToFileURL } from "url";
import * as jsts from 'jsts';
import { ConverterField, FieldPart } from "./BuildingPart";
import DrawingManager2 from "../DrawingManager2";
import { LineInfoType } from "../model/Project";
export class NaverMapManager {
  //@ts-ignore
  //private 
  naverMap = window.naver.maps;
  private cadastralLayer: any;// = new window.naver.maps.CadastralLayer();
  private map: any;
  private benchmarkPosition: any;
  private mapProj: mapProjectionData = { mapOffset: new THREE.Vector2(0), projection: any };
  private naverSitePolygon: any;
  private naverVacancyOutsidePolygon: any;
  private naverVacancyInsidePolygon: any;
  private naverTopographyPolygon: any;
  private naverRoadPolygon: any;
  private centerOfRoadPolygon: any;
  private corePolygons: any[] = [];
  private housePolygons: any[] = [];

  private buildingPolygon: any;

  private topologyMarker: Array<any> = [];

  drawingMananger: any;
  static nProj: string = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
  static bProj: string = '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs';

  private drawingEnd = true;

  createMap = (center: any, htmlElement: HTMLElement) => {
    if (this.map) {
      this.map.destroy();
    }
    this.map = new this.naverMap.Map(htmlElement, {
      // mapTypes: this.naverMap.StyleMapTypeOption.getMapTypes({
      //   initLayers: [
      //     this.naverMap.StyleMapLayerId.BACKGROUND_DETAIL,
      //     this.naverMap.StyleMapLayerId.POI_KOREAN,
      //     this.naverMap.StyleMapLayerId.CADASTRAL,
      //   ]
      // }),
      mapTypeControlOptions: {
        style: this.naverMap.MapTypeControlStyle.DROPDOWN
      },

      // mapTypeControl: true,
      center: center,
      // mapTypeId: this.naverMap.StyleMapTypeId.NORMAL,
      zoom: 17,
    });

    this.cadastralLayer = new this.naverMap.CadastralLayer({ useStyleMap: true });
    this.naverMap.Event.once(this.map, 'init_stylemap', () => {
      this.cadastralLayer.setMap(this.map);
    });

    this.mapProj.projection = this.map.getProjection();
    this.mapProj.mapOffset = new THREE.Vector2(0);

    this.drawingMananger = new this.naverMap.drawing.DrawingManager(
      {
        map: this.map,
        drawingControl: [
          this.naverMap.drawing.DrawingMode.RECTANGLE,
          this.naverMap.drawing.DrawingMode.MARKER
        ],
        drawingControlOptions: {
          position: this.naverMap.Position.TOP_CENTER,
          // style: this.naverMap.drawing.DrawingStyle.VERTICAL
        },
        rectangleOptions: {
          strokeWeight: 3,
          strokeColor: '#ff0000'
        },
      }
    );

  }

  getMap = () => {
    return this.map;
  }

  setZoom = (zoom: number, effect: boolean = false) => {
    this.map.setZoom(zoom, effect);
  }

  getZoom = () => {
    return this.map.getZoom();
  }

  searchAddressToCoordinate = (address: string) => {
    this.naverMap.Service.geocode({
      query: address
    }, (status: any, response: any) => {
      if (status === this.naverMap.Service.Status.ERROR) {
        return alert('Something Wrong!');
      }

      if (response.v2.meta.totalCount === 0) {
        return alert('totalCount' + response.v2.meta.totalCount);
      }

      var htmlAddresses = [],
        item = response.v2.addresses[0],
        point = new this.naverMap.Point(item.x, item.y);

      if (item.roadAddress) {
        htmlAddresses.push('[도로명 주소] ' + item.roadAddress);
      }

      if (item.jibunAddress) {
        htmlAddresses.push('[지번 주소] ' + item.jibunAddress);
      }

      if (item.englishAddress) {
        htmlAddresses.push('[영문명 주소] ' + item.englishAddress);
      }

      this.map.setCenter(point);
    });

  }

  setMapCenter = (x: number, y: number) => {
    this.map.setCenter(new this.naverMap.Point(x, y));
  }

  addListener = (event: string, eventFunction: Function) => {
    this.naverMap.Event.addListener(this.map, event, () => {
      eventFunction();
    })
  }

  getProjection = () => {
    return this.map.getProjection();
  }

  isCreate = () => {
    if (this.map) {
      return true;
    }
    else {
      return false;
    }
  }

  getTMPosition = (screenPos: THREE.Vector2, proj: any) => {
    return proj.fromPageXYToCoord(NaverPoint(screenPos.x, screenPos.y));
  }

  changeMapType = (isCadastral: boolean) => {
    this.cadastralLayer.setMap(isCadastral ? this.map : null);
  }

  clearAllPolygon = () => {
    if (this.naverSitePolygon) {
      this.naverSitePolygon.setMap(null);
      this.naverSitePolygon = undefined;
    }

    if (this.naverVacancyOutsidePolygon) {
      this.naverVacancyOutsidePolygon.setMap(null);
      this.naverVacancyOutsidePolygon = undefined;
    }

    if (this.naverVacancyInsidePolygon) {
      this.naverVacancyInsidePolygon.setMap(null);
      this.naverVacancyInsidePolygon = undefined;
    }

    if (this.naverRoadPolygon) {
      this.naverRoadPolygon.setMap(null);
      this.naverRoadPolygon = undefined;
    }

    if (this.centerOfRoadPolygon) {
      this.centerOfRoadPolygon.setMap(null);
      this.centerOfRoadPolygon = undefined;
    }

    if (this.naverTopographyPolygon) {
      this.naverTopographyPolygon.setMap(null);
      this.naverTopographyPolygon = undefined;
    }
    if (this.buildingPolygon) {
      this.buildingPolygon.setMap(null);
      this.buildingPolygon = undefined;
 
    }
  }

  createDrawingManager = (drawingMode?: number) => {
    if (this.drawingMananger)
      this.drawingMananger.destroy();
    this.drawingMananger = new this.naverMap.drawing.DrawingManager(
      {
        map: this.map,
        drawingControl: [
          this.naverMap.drawing.DrawingMode.RECTANGLE,
        ],

        drawingMode: drawingMode ? drawingMode : 0,

        drawingControlOptions: {
          position: this.naverMap.Position.TOP_RIGHT,
          style: this.naverMap.drawing.DrawingStyle.HORIZONTAL_2,
          // style: this.naverMap.drawing.DrawingStyle.VERTICAL
        },
        rectangleOptions: {
          strokeWeight: 2,
          strokeColor: "#0038FF",
          fillColor: '#0038FF',
          fillOpacity: 0.1,
        },
      }
    );
  }

  coordToAddress = (x: number = 37.3595316, y: number = 127.1052133, fileName: Array<string>) => {
    if (fileName.length > 0) {
      fileName.pop();
    }
    this.naverMap.Service.reverseGeocode({
      location: new this.naverMap.LatLng(y, x),
    }, function (status: any, response: any) {
      if (status !== App.naver.maps.Service.Status.OK) {
        return "파일 이름";//alert('Something wrong!');
      }
      var result = response.result, // 검색 결과의 컨테이너
        items = result.items; // 검색 결과의 배열
//      console.log(items, 'items');
      fileName.push(items[0].address);
    });
  }

  getPolygonInDrawingManager = () => {
    return this.drawingMananger.getDrawings();
  }

  static toGeom = (wkt: string, type: GeometryTypes | "Field") => {
    let coordinates = undefined;
    switch (type) {
      case "Field":
        // @ts-ignore
        coordinates = wkx.Geometry.parse(wkt).toGeoJSON().coordinates[0];
        break;
      default:
        // @ts-ignore
        coordinates = wkx.Geometry.parse(wkt).toGeoJSON().coordinates;
        break;
    }

    const gj = {
      type: type,
      coordinates: coordinates,
    }
    //@ts-ignore
    const geom = reproject(gj, this.bProj, this.nProj);
    if (type === "Polygon") {
      for (let i = 0; i < geom.coordinates.length; i++) {
        geom.coordinates[i].splice(geom.coordinates[i].length - 1, 1);
      }
    }
    return geom;
  }


  addPolygon = (paths?: number[][][], options?: PolygonOptions) => {
    const nPolygon = new App.naver.maps.Polygon({
      ...options,
      paths: paths
    });

    const polygon = options && options.isProjectSite ? new ProjectSitePolygon() : new Polygon();

    nPolygon.setMap(this.map);
    polygon.setOverlay(nPolygon);
    polygon.setId(nPolygon.id);
    polygon.setType('Polygon');
    
    return polygon;
  }

  addMarker = (position: number[], options?: MarkerOptions, controllable: boolean = false) => {

    const nMarker = new App.naver.maps.Marker({
      ...options,
      position: position
    })

    const marker = new Marker();
    nMarker.setMap(this.map);
    nMarker.setOptions({ draggable: false });
    nMarker.addListener('click', (e: any) => { marker.emit('click', e) });
    nMarker.addListener('mouseover', () => { marker.emit('hover') });
    marker.setId(nMarker._nmarker_id);
    marker.setOverlay(nMarker);
    marker.setType('Point');

    return marker;
  }
  removeMarker = (marker: Marker) => {
    marker.remove();
  }
  setNaverSitePolygon = (latlngs: any, hasError?: boolean) => {
    let fillOpacity = DrawingManager2.DrawingOption.PROJECT_SITE.fillOpacity;
    if (hasError) fillOpacity = 0;
    if (this.naverSitePolygon) {
      this.naverSitePolygon.setPaths(latlngs)
    }
    else {
      //@ts-ignore
      this.naverSitePolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,
        ...DrawingManager2.DrawingOption.PROJECT_SITE,
        fillOpacity,
      });
    }
  }

  setNaverVacancyOutsidePolygon = (latlngs: any, hasError?: boolean) => {
    let fillOpacity = DrawingManager2.DrawingOption.VACANCY_OUTSIDE.fillOpacity;
    if (hasError) fillOpacity = 0;

    if (this.naverVacancyOutsidePolygon) {
      this.naverVacancyOutsidePolygon.setPaths(latlngs)
    }
    else {
      //@ts-ignore
      this.naverVacancyOutsidePolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,
        ...DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
        fillOpacity
      });
    }
  }

  setBuildingPolygon = (latlngs: any, type: "PCT_CORE" | "PCT_HOUSE") => {
    const path = [[]];
    latlngs.forEach((latlng: THREE.Vector2) => {
      //@ts-ignore
      path[0].push([latlng.x, latlng.y])
    });
    
    if (type === "PCT_CORE")
      this.corePolygons.push(this.addPolygon(path, { ...DrawingManager2.DrawingOption.CAD_CORE, simplify: false }));
    else if (type === "PCT_HOUSE")
      this.housePolygons.push(this.addPolygon(path, { ...DrawingManager2.DrawingOption.CAD_HOUSE, simplify: false }));    
  }

  setNaverTopographyPolygon = (latlngs: any, topoFields?: Array<ConverterField>) => {
    if (this.naverTopographyPolygon) {
      this.naverTopographyPolygon.setPaths(latlngs);
    }
    else {
      //@ts-ignore
      this.naverTopographyPolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,      
        ...DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
      });
    }
    
    // 마커 추가
    if (topoFields) {
      const markers = this.getTopologyMarker();
      markers.forEach(marker=>{
        marker.remove();
      })
    for (let i = 0; i < markers.length; i++) {
      markers.pop();
    }
      markers.pop();
      latlngs.forEach((latlng: any, i: number) => {
        let inputHeight = topoFields[i].defaultInput;
        let content = inputHeight !== topoFields[i].getHeight() ?
        `<div style="color: #95E4B3; font-size: 14px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
          <img class="level-icon" src="/img/icon/ic-level-green.png" style="color: #95E4B3;"/>
          <span>${topoFields[i].getHeight()}</span>
        </div>`
          :
          `<div style="color: white; font-size: 14px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
          <img class="level-icon" src="/img/icon/level.png"/><span>${topoFields[i].getHeight()}</span>
        </div>`;

        const markerOptions = {
          draggable: false,
          icon: {
            content
          } 
        };
  
        let geoFac = new jsts.geom.GeometryFactory();
        let coords: jsts.geom.Coordinate[] = [];
        latlng.forEach((coord: any) => {
          coords.push(new jsts.geom.Coordinate(coord[0], coord[1]));
        })       
        let linearRing = geoFac.createLinearRing(coords);
        let polygon = geoFac.createPolygon(linearRing, [])
        let center = polygon.getCentroid();
        let centerCoord = {x: center.getX(), y: center.getY()};

        this.topologyMarker.push(this.addMarker([centerCoord.x, centerCoord.y], {
          ...markerOptions,
          icon: {
            content: markerOptions.icon.content,
            anchor: { x: 15, y: 7 }
          }
        }, false));
      })

    }
  }

  getTopologyMarker = () => {
    return this.topologyMarker;
  }

  setNaverVacancyInsidePolygon = (latlngs: any, hasError?: boolean) => {
    let fillOpacity = DrawingManager2.DrawingOption.VACANCY_INSIDE.fillOpacity;
    if (hasError) fillOpacity = 0;

    if (this.naverVacancyInsidePolygon) {
      this.naverVacancyInsidePolygon.setPaths(latlngs)
    }
    else {
      //@ts-ignore
      this.naverVacancyInsidePolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,
        ...DrawingManager2.DrawingOption.VACANCY_INSIDE,
        fillOpacity
      });
    }
  }

  setNaverRoadPolygon = (latlngs: any, hasError?: boolean) => {
    
    let fillOpacity = DrawingManager2.DrawingOption.ROAD_SITE.fillOpacity;
    if (hasError) fillOpacity = 0;

    if (this.naverRoadPolygon) {
      this.naverRoadPolygon.setPaths(latlngs)
    }
    else {
      //@ts-ignore
      this.naverRoadPolygon = new window.naver.maps.Polygon({
        map: this.map,
        paths: latlngs,        
        ...DrawingManager2.DrawingOption.ROAD_SITE,
        fillOpacity
      });
    }
  }

  setCenterofRoadPolygon = (latlngs: any) => {

    if (this.centerOfRoadPolygon) {
      this.centerOfRoadPolygon.setMap(null);
    }
    //@ts-ignore
    this.centerOfRoadPolygon = new window.naver.maps.Polygon({
      map: this.map,
      paths: latlngs,
      ...DrawingManager2.DrawingOption.BOUNDARY_SITE,
    });

    // latlngs.forEach((latlng: any, i: number) => {
    //   if (i !== 0) {
    //     this.addPolygon([latlngs[i]], {
    //       strokeColor: "#0000FF",
    //       strokeWeight: 2,
    //       //fillColor: "#1919E6",
    //       zIndex: -6,
    //       //fillOpacity: 0,
    //       //clickable: false,
    //       //simplify: false,
    //     });
    //   } 
    // })

  }


  //sitePoint:: site field's firset point in screen space
  setBenchmarkPosition = (sitePoint: THREE.Vector2) => {
    this.benchmarkPosition = this.mapProj.projection.fromPageXYToCoord(NaverPoint(sitePoint.x, sitePoint.y));
  }

  getBenchmarkPosition = () => { return this.benchmarkPosition; }

  getLatLngPosition(screenPoint: THREE.Vector2) {
    this.mapProj.projection = this.map.getProjection();
    return this.mapProj.projection.fromPageXYToCoord(NaverPoint(screenPoint.x, screenPoint.y));
  }

  getMapProjectionData = () => { return this.mapProj }
  getMapSettingState = () => { return !this.mapProj.projection || !this.benchmarkPosition }


  setPolygonOpacity = (type: LineInfoType | null) => {
    const projectSite = {
      data: [this.naverSitePolygon],
      normalOption: DrawingManager2.DrawingOption.PROJECT_SITE,
      fadeOption: {
        strokeColor: "rgb(255, 51, 51, 0.4)",
        strokeStyle: DrawingManager2.DrawingOption.PROJECT_SITE.strokeStyle,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.PROJECT_SITE,
      type: "project_site",
    };
    const boundarySite = {
      data: [this.centerOfRoadPolygon],
      normalOption: DrawingManager2.DrawingOption.BOUNDARY_SITE,
      fadeOption: {
        strokeColor: "rgba(255, 255, 255, .4)",
      },
      focusOption: DrawingManager2.DrawingOption.BOUNDARY_SITE,
      type: "center_of_road",
    };

    const topographyLine = {
      data: [this.naverTopographyPolygon],
      normalOption: DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
        strokeColor: "rgba(255, 255, 255, .4)",
      },
      focusOption: {
        ...DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
        strokeColor: "#0085FF",
        strokeWeight: 2.5,
      },
      type: "topography",
    };
    const vacancyInside = {
      data: [this.naverVacancyInsidePolygon],
      normalOption: DrawingManager2.DrawingOption.VACANCY_INSIDE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.VACANCY_INSIDE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.VACANCY_INSIDE,
      type: "vacancy_inside",
    };
    const vacancyOutside = {
      data: [this.naverVacancyOutsidePolygon],
      normalOption: DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
      type: "vacancy_outside",
    };
    const roadSite = {
      data: [this.naverRoadPolygon],
      normalOption: DrawingManager2.DrawingOption.ROAD_SITE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.ROAD_SITE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0
      },
      focusOption: DrawingManager2.DrawingOption.ROAD_SITE,
      type: "road",
    };
    const coreData = {
      data: this.corePolygons,
      normalOption: DrawingManager2.DrawingOption.CAD_CORE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.CAD_CORE,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.CAD_CORE,
      type: "core",
    };
    const houseData = {
      data: this.housePolygons,
      normalOption: DrawingManager2.DrawingOption.CAD_HOUSE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.CAD_HOUSE,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.CAD_HOUSE,
      type: "house",
    };


    let fadeArray: Array<any> = [projectSite, boundarySite, topographyLine, vacancyInside, vacancyOutside, roadSite, coreData, houseData];
    let focusArray: Array<any> = [];
    let index: number | undefined;

    if (type !== null) {
      index = fadeArray.findIndex(item => item.type === type);
      if (index > -1) {
        focusArray = fadeArray.splice(index, 1);
        fadeArray.map((fades) => {
          fades["data"]!.length !== 0 &&
            fades["data"].map((fade: any) => {
              fade !== undefined && fade.setOptions(fades.fadeOption);
            });
        });
        focusArray.length !== 0 &&
          focusArray[0]["data"].map((focusData: any) => {
            focusData !== undefined && focusData.setOptions(focusArray[0].focusOption);
          });  
      }
    }  
    else {
      // type === null: 범례에서 focus되지 않고 있는 경우
      index = undefined;
      focusArray = [];

      fadeArray.map((fades) => {
        fades["data"]![0] !== null &&
          fades["data"].map((allData: any) => {
            allData !== undefined && allData.setOptions(fades.normalOption)
          });
      });
    }
  };
}

export function NaverPoint(x: number, y: number) {
  //@ts-ignore
  return new window.naver.maps.Point(x, y);
}

export function NaverLatLng(lat: number, lng: number) {
  //@ts-ignore
  return new window.naver.maps.LatLng(lat, lng);
}

export function NaverPolygon(parameters: any) {
  //@ts-ignore
  return new window.naver.maps.Polygon(parameters);
}

