import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { Checkbox, IconButton, CircularProgress } from "@material-ui/core";
import SortDecIcon from "@material-ui/icons/ArrowDownward";
import SortAscIcon from "@material-ui/icons/ArrowUpward";
import FavoriteProjectIconActive from "@material-ui/icons/Star";
import FavoriteProjectIcon from "@material-ui/icons/StarBorder";

import { default as _ } from "lodash";

import App from "./App";
import { SortOptions, ProjectListType, TagType, TableType, ProjectStatusLabel, TableBuildingType } from "./ProjectList";
import { ProjectListBodyProps } from "./ProjectListBody";
import BuilditSelect from "./BuilditSelect";
import ProjectTypeTag from "./ProjectTypeTag";
import ProjectIcon, { TooltipProjectIcon } from "./ProjectIcon";
import Tooltip from "./Tooltip";
import { Project } from "./model/Project";
import AWSModule from "./AWSModule";
import { timeScale, buildingTypeLabel } from "./Utils";
import moment from "moment";
import S3Image from "./S3Image";
import MapView from "./MapView";

import "./css/ProjectListTable.scss";
import DiscountTag from "./DiscountTag";
// import { project } from '@teneleven/protocols-ts-web';
import TooltipMemoIcon from "./TooltipMemoIcon";
import ProjectCardComponent from "./ProjectCardComponent";

export interface ProjectListTableProps extends ProjectListBodyProps {
  sort: SortOptions;
  setSortOptions: (field: keyof SortOptions, value: "asc" | "desc") => void;
}
export interface ProjectListTableState {}

export default class ProjectListTable extends Component<ProjectListTableProps, ProjectListTableState> {
  componentWillMount = async () => {};

  componentDidUpdate = async (pp: Readonly<ProjectListTableProps>, ps: Readonly<ProjectListTableState>) => {};

  render() {
    let table: any;
    let buildingTypeList = [
      { label: "전체", value: "전체" },
      { label: "아파트", value: "아파트" },
      { label: "오피스텔", value: "오피스텔" },
      { label: "다세대주택", value: "다세대주택" },
      { label: "BUILDIT 추천", value: "MULTIPLE" },
    ]
    
    switch (this.props.tableType) {
      case "TABLE":
        table = (
          <div className="ProjectListTable">
            <div className="table-header">
              <div className="tr">
                <div className="th checkbox">
                  <Checkbox
                    className={`checkbox ${(this.props.checkAll && "checked") || ""}`}
                    checked={this.props.checkAll}
                    onClick={(e) => {
                      this.props.projects &&
                        this.props.setSelectedProject(
                          this.props.projects.map((p) => p.user_project_id!),
                          !this.props.checkAll
                        );
                    }}
                  />
                </div>
                <div className="th user-project-id" onClick={() => this.props.setSortOptions("user_project_id", this.props.sort.user_project_id === "asc" ? "desc" : "asc")}>
                  <div className="name">번호</div>
                  <div className="arrow">
                    {this.props.sort.user_project_id === "desc" && <SortDecIcon className="icon" />}
                    {this.props.sort.user_project_id === "asc" && <SortAscIcon className="icon" />}
                  </div>
                </div>
                <div className="th project-type">
                  <BuilditSelect
                    type="Table"
                    adornment="Bottom"
                    list={[
                      { label: <ProjectTypeTag background="DARK" type="NOCONFIG" isForTable={true} />, value: "NOCONFIG" },
                      // { label: <ProjectTypeTag background="DARK" type="UNDEFINED" isForTable={true}/>, value: "UNDEFINED" },
                      { label: <ProjectTypeTag background="DARK" type="AI" isForTable={true} />, value: "AI" },
                      { label: <ProjectTypeTag background="DARK" type="CAL" isForTable={true} />, value: "CAL" },
                      { label: <ProjectTypeTag background="DARK" type="DESIGNER" isForTable={true} />, value: "DESIGNER" },
                    ]}
                    placeholder="타입"
                    value={this.props.tagType}
                    onChange={(r: React.ReactText) => this.props.setProjectTypeSelect(r as TagType)}
                    useJSXPlaceholder={true}
                  />
                </div>
                <div className="th status">
                  <BuilditSelect
                    type="Table"
                    adornment="Bottom"
                    list={[
                      { label: "전체", value: "ALL" },
                      { label: <ProjectIcon status="CONFIG" />, value: "CONFIG" },
                      { label: <ProjectIcon status="WAITING" />, value: "WAITING" },
                      { label: <ProjectIcon status="FINISHED" />, value: "FINISHED" },
                      { label: <ProjectIcon status="FAILED" />, value: "FAILED" },
                    ]}
                    value={this.props.projectListType}
                    onChange={(r: React.ReactText) => this.props.setProjectListType(r as ProjectListType)}
                    useJSXPlaceholder={true}
                    placeholder="상태"
                  />
                </div>
                <div className="th building-type">
                  <BuilditSelect
                    type="Table"
                    adornment="Bottom"
                    // 도시형 생활주택 추가할 때 kibana에 building_type을 building_type.keyword로 변경해줘야함
                    // 다세대추택, 연립주택이 선택할때 도시형 생활주택도 같이 검색됨
                    // 현재는 list에 추가할 순 없는데 확인이 필요할 수 잇을 것 같아서 같이 검색되도록 유지해놓았습니다.
                    list={buildingTypeList}
                    value={this.props.building_type}
                    onChange={(r: React.ReactText) => {
                      this.props.setBuildingType(r as TableBuildingType);
                    }}
                    placeholder="건물 종류"
                  />
                </div>
                <div className="th project-name">프로젝트명</div>
                <div className="th favorite" />
                <div className="th area" onClick={() => this.props.setSortOptions("project_site_area", this.props.sort.project_site_area === "asc" ? "desc" : "asc")}>
                  <div className="name">면적</div>
                  <div className="arrow">
                    {this.props.sort.project_site_area === "desc" && <SortDecIcon className="icon" />}
                    {this.props.sort.project_site_area === "asc" && <SortAscIcon className="icon" />}
                  </div>
                </div>
                <div className="th floor-area-ratio">달성용적률</div>
                <div className="th created-at" onClick={() => this.props.setSortOptions("created_at", this.props.sort.created_at === "asc" ? "desc" : "asc")}>
                  <div className="name">생성일</div>
                  <div className="arrow">
                    {this.props.sort.created_at === "desc" && <SortDecIcon className="icon" />}
                    {this.props.sort.created_at === "asc" && <SortAscIcon className="icon" />}
                  </div>
                </div>
              </div>
            </div>
            <div className="table-body">
              {(this.props.projects &&
                this.props.projects.length > 0 &&
                this.props.projects.map((p) => {
                  return (
                    <ProjectTableRow
                      project={p}
                      key={`project-table-row-${p.project_id}`}
                      setProjectFavorite={this.props.setProjectFavorite}
                      tableType={this.props.tableType}
                      setSelectedProject={this.props.setSelectedProject}
                      selectedProject={this.props.selectedProject}
                    />
                  );
                })) || <div className="noItemText">프로젝트가 없습니다.</div>}
            </div>
          </div>
        );
        break;
      case "TABLE_DETAIL":
        table = (
          <div className="ProjectListTable">
            <div className="table-header">
              <div className="tr">
                <div className="th checkbox">
                  <Checkbox
                    className={`checkbox ${(this.props.checkAll && "checked") || ""}`}
                    checked={this.props.checkAll}
                    onClick={(e) => {
                      this.props.projects &&
                        this.props.setSelectedProject(
                          this.props.projects.map((p) => p.user_project_id!),
                          !this.props.checkAll
                        );
                    }}
                  />
                </div>
                <div className="th user-project-id" onClick={() => this.props.setSortOptions("user_project_id", this.props.sort.user_project_id === "asc" ? "desc" : "asc")}>
                  <div className="name">번호</div>
                  <div className="arrow">
                    {this.props.sort.user_project_id === "desc" && <SortDecIcon className="icon" />}
                    {this.props.sort.user_project_id === "asc" && <SortAscIcon className="icon" />}
                  </div>
                </div>
                <div className="th project-type">
                  <BuilditSelect
                    type="Table"
                    adornment="Bottom"
                    list={[
                      { label: <ProjectTypeTag background="DARK" type="NOCONFIG" isForTable={true} />, value: "NOCONFIG" },
                      // { label: <ProjectTypeTag background="DARK" type="UNDEFINED" isForTable={true} />, value: "UNDEFINED" },
                      { label: <ProjectTypeTag background="DARK" type="AI" isForTable={true} />, value: "AI" },
                      { label: <ProjectTypeTag background="DARK" type="CAL" isForTable={true} />, value: "CAL" },
                      { label: <ProjectTypeTag background="DARK" type="DESIGNER" isForTable={true} />, value: "DESIGNER" },
                    ]}
                    value={this.props.tagType}
                    onChange={(r: React.ReactText) => this.props.setProjectTypeSelect(r as TagType)}
                    useJSXPlaceholder={true}
                    placeholder="타입"
                  />
                </div>
                <div className="th status">
                  <BuilditSelect
                    type="Table"
                    adornment="Bottom"
                    list={[
                      { label: "전체", value: "ALL" },
                      { label: <ProjectIcon status="CONFIG" />, value: "CONFIG" },
                      { label: <ProjectIcon status="WAITING" />, value: "WAITING" },
                      { label: <ProjectIcon status="FINISHED" />, value: "FINISHED" },
                      { label: <ProjectIcon status="FAILED" />, value: "FAILED" },
                    ]}
                    value={this.props.projectListType}
                    onChange={(r: React.ReactText) => this.props.setProjectListType(r as ProjectListType)}
                    useJSXPlaceholder={true}
                    placeholder="상태"
                  />
                </div>
                <div className="th building-type">
                  <BuilditSelect
                    type="Table"
                    adornment="Bottom"
                    list={buildingTypeList}
                    value={this.props.building_type}
                    onChange={(r: React.ReactText) => {
                      this.props.setBuildingType(r as TableBuildingType);
                    }}
                    placeholder="건물 종류"
                  />
                </div>
                <div className="th project-name">프로젝트명</div>
                <div className="th favorite" />
                <div className="th area" onClick={() => this.props.setSortOptions("project_site_area", this.props.sort.project_site_area === "asc" ? "desc" : "asc")}>
                  <div className="name">면적</div>
                  <div className="arrow">
                    {this.props.sort.project_site_area === "desc" && <SortDecIcon className="icon" />}
                    {this.props.sort.project_site_area === "asc" && <SortAscIcon className="icon" />}
                  </div>
                </div>
                <div className="th floor-area-ratio">달성용적률</div>
                <div className="th created-at" onClick={() => this.props.setSortOptions("created_at", this.props.sort.created_at === "asc" ? "desc" : "asc")}>
                  <div className="name">생성일</div>
                  <div className="arrow">
                    {this.props.sort.created_at === "desc" && <SortDecIcon className="icon" />}
                    {this.props.sort.created_at === "asc" && <SortAscIcon className="icon" />}
                  </div>
                </div>
              </div>
            </div>
            <div className="table-body">
              {(this.props.projects &&
                this.props.projects.length > 0 &&
                this.props.projects.map((p) => (
                  <ProjectTableRow
                    project={p}
                    key={`project-table-detail-row-${p.project_id}`}
                    setProjectFavorite={this.props.setProjectFavorite}
                    tableType={this.props.tableType}
                    setSelectedProject={this.props.setSelectedProject}
                    selectedProject={this.props.selectedProject}
                  />
                ))) || <div className="noItemText">프로젝트가 없습니다.</div>}
            </div>
          </div>
        );
        break;
      case "CARD":
        table = (
          <div className="ProjectListTable">
            <div className="ProjectListCardHeader">
              <div className="leftSide">
                <div className="checkbox">
                  <Checkbox
                    className={`checkbox ${(this.props.checkAll && "checked") || ""}`}
                    checked={this.props.checkAll}
                    onClick={(e) => {
                      this.props.projects &&
                        this.props.setSelectedProject(
                          this.props.projects.map((p) => p.user_project_id!),
                          !this.props.checkAll
                        );
                    }}
                  />
                </div>
                <div className="th user-project-id" onClick={() => this.props.setSortOptions("user_project_id", this.props.sort.user_project_id === "asc" ? "desc" : "asc")}>
                  <div className="name">번호</div>
                  <div className="arrow">
                    {this.props.sort.user_project_id === "desc" && <SortDecIcon className="icon" />}
                    {this.props.sort.user_project_id === "asc" && <SortAscIcon className="icon" />}
                  </div>
                </div>
                <div className="th project-type">
                  <BuilditSelect
                    type="CARD_TOP"
                    adornment="Bottom"
                    list={[
                      { label: <ProjectTypeTag background="DARK" type="NOCONFIG" isForTable={true} />, value: "NOCONFIG" },
                      // { label: <ProjectTypeTag background="DARK" type="UNDEFINED" isForTable={true} />, value: "UNDEFINED" },
                      { label: <ProjectTypeTag background="DARK" type="AI" isForTable={true} />, value: "AI" },
                      { label: <ProjectTypeTag background="DARK" type="CAL" isForTable={true} />, value: "CAL" },
                      { label: <ProjectTypeTag background="DARK" type="DESIGNER" isForTable={true} />, value: "DESIGNER" },
                    ]}
                    value={this.props.tagType}
                    onChange={(r: React.ReactText) => this.props.setProjectTypeSelect(r as TagType)}
                    useJSXPlaceholder={true}
                    placeholder="타입"
                  />
                </div>
                <div className="th status">
                  <BuilditSelect
                    type="CARD_TOP"
                    adornment="Bottom"
                    list={[
                      { label: "전체", value: "ALL" },
                      { label: <ProjectIcon status="CONFIG" />, value: "CONFIG" },
                      { label: <ProjectIcon status="WAITING" />, value: "WAITING" },
                      { label: <ProjectIcon status="FINISHED" />, value: "FINISHED" },
                      { label: <ProjectIcon status="FAILED" />, value: "FAILED" },
                    ]}
                    value={this.props.projectListType}
                    onChange={(r: React.ReactText) => this.props.setProjectListType(r as ProjectListType)}
                    useJSXPlaceholder={true}
                    placeholder="상태"
                  />
                </div>
                <div className="th building-type">
                  <BuilditSelect
                    type="CARD_TOP"
                    adornment="Bottom"
                    list={buildingTypeList}
                    value={this.props.building_type}
                    onChange={(r: React.ReactText) => {
                      this.props.setBuildingType(r as TableBuildingType);
                    }}
                    placeholder="건물 종류"
                  />
                </div>
              </div>
              <div className="rightSide">
                {/* <div className="th floor-area-ratio">
                        달성용적률
                      </div> */}
              </div>
            </div>

            <div className="cardTable">
              {(this.props.projects &&
                this.props.projects.length > 0 &&
                this.props.projects.map((p, i) => {
                  return (
                    <TableCard
                      key={`table-card-${p.project_id}`}
                      favorite={p.favorite}
                      project={p}
                      projectId={p.project_id!}
                      setProjectFavorite={this.props.setProjectFavorite}
                      margin={i % 4 == 3 ? 0 : 20}
                      setSelectedProject={this.props.setSelectedProject}
                      selectedProject={this.props.selectedProject}
                    />
                  );
                })) || <div className="noItemText card">프로젝트가 없습니다.</div>}
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    return table;
  }
}

interface ProjectTableRowProps {
  setProjectFavorite: (project: Project, favorite: boolean) => void;
  project: Project;
  tableType: TableType;
  setSelectedProject: (user_project_id: number[], allCheck?: boolean) => void;
  selectedProject: Array<{ user_project_id: number; checked: boolean }>;
}
interface ProjectTableCellState {
  loc_floor_area_ratio: number | undefined;
  isMouseOver: boolean;
  daylight_hours: number | undefined;
  household: number | undefined;
  favoriteloading: boolean;
  designerVersionAtCal: string | undefined;
}
class ProjectTableRow extends Component<ProjectTableRowProps, ProjectTableCellState> {
  state: ProjectTableCellState = {
    loc_floor_area_ratio: undefined,
    isMouseOver: false,
    daylight_hours: undefined,
    household: undefined,
    favoriteloading: false,
    designerVersionAtCal: "",
  };
  componentWillMount = async () => {
    this.getResult();
  };
  componentDidMount = () => {
    if (this.props.project.run_meta_info) {
      this.setState({
        designerVersionAtCal: this.props.project.run_meta_info!.designer_version_at_cal,
      });
    }
  }

  componentDidUpdate = (pp: Readonly<ProjectTableRowProps>) => {
    if (_.isEqual(pp.project.project_id, this.props.project.project_id) === false) {
      this.getResult();
    }
    if (_.isEqual(pp.project.favorite, this.props.project.favorite) === false) {
      this.setState({ favoriteloading: false });
    }
  };

  getResult = async () => {
    const r = await App.search({
      table: "engine-buildit-result",
      query: {
        query_string: {
          query: `base_project_id: ${this.props.project.project_id}`,
        },
      },
      sort: [{ loc_floor_area_ratio: "desc" }],
    });

    const rr = r.data;
    if (rr.hits.hits.length > 0) {
      console.log(rr.hits.hits[0]);
      this.setState({
        loc_floor_area_ratio: rr.hits.hits[0]._source.loc_floor_area_ratio,
        daylight_hours: rr.hits.hits[0]._source.daylight_hours_avg,
        household: rr.hits.hits[0]._source.loc_total_household,
      });
    } else {
      this.setState({ loc_floor_area_ratio: undefined, daylight_hours: undefined, household: undefined });
    }
  };

  get projectDetail(): string {
    let ret: string = "";

    if (this.props.project.project_use_district) {
      ret += this.props.project.project_use_district;
    }

    if (this.props.project.building_type) {
      ret += ` |  ${buildingTypeLabel(this.props.project.building_type)}`;
    }

    if (this.props.project.floor_area_ratio) {
      ret += ` | 용적률  ${this.props.project.floor_area_ratio}%`;
    }

    if (this.props.project.building_land_ratio) {
      ret += ` | 건폐율 ${this.props.project.building_land_ratio}%`;
    }

    if (this.props.project.floor_height) {
      ret += ` | 층고 ${this.props.project.floor_height}m`;
    }

    if (this.props.project.building_stories_max && this.props.project.building_stories_min) {
      ret += ` | 층수(고/저) ${this.props.project.building_stories_max}/${this.props.project.building_stories_min}층`;
    }

    // if (this.props.project.building_stories_avg) {
    //   ret += ` | 평균층수 ${this.props.project.building_stories_avg}층`;
    // }

    if (ret.startsWith(" | ")) {
      ret = ret.substr(3);
    }

    return ret;
  }

  get resultDetail(): string {
    let ret: string = "";

    if (this.state.loc_floor_area_ratio && this.props.project.project_type !== "DESIGNER" && this.props.project.project_type !== "SITE_PLAN") {
      ret += `최고 달성 용적률 ${new Intl.NumberFormat(undefined, { maximumFractionDigits: 2 }).format(this.state.loc_floor_area_ratio)}%`;
    }

    if (this.state.daylight_hours) {
      ret += ` | 일조량 ${timeScale(this.state.daylight_hours)}`;
    }

    if (this.state.household) {
      ret += ` | 세대수 ${this.state.household}세대`;
    }

    if (ret.startsWith(" | ")) {
      ret = ret.substr(3);
    }

    return ret;
  }

  render() {
    const p = this.props.project;
    const project_link = p.status === "CONFIG" ? `/project/edit?pid=${p.user_project_id}` : `/project/result?pid=${p.user_project_id}`;
    const tooltipMsg = `프로젝트 설정페이지로 이동합니다.`;
    let loc_floor_area_ratio: number | undefined = undefined;
    if (p.status === "FINISHED" && this.state.loc_floor_area_ratio !== undefined) loc_floor_area_ratio = this.state.loc_floor_area_ratio;
    return (
      <Fragment>
        <div
          onMouseEnter={(e) => {
            this.setState({ isMouseOver: true });
          }}
          onMouseLeave={(e) => {
            this.setState({ isMouseOver: false });
          }}
        >
          <Tooltip msg={tooltipMsg} on={p.status === "CONFIG" ? this.state.isMouseOver : false}>
            <div className={`ProjectTableRow ${this.state.isMouseOver ? "mouseOver" : ""}`}>
              <div className="td checkbox">
                <Checkbox
                  className={`checkbox ${(this.props.selectedProject[this.props.selectedProject.findIndex((e) => e.user_project_id === p.user_project_id)].checked && "checked") || ""}`}
                  checked={this.props.selectedProject[this.props.selectedProject.findIndex((e) => e.user_project_id === p.user_project_id)].checked}
                  onClick={(e) => {
                    this.props.setSelectedProject([p.user_project_id!]);
                  }}
                />
              </div>
              <Link className="link" to={project_link} style={{ textDecoration: "none" }}>
                <div title={App.stage === "dev" ? this.props.project.project_id!.toString() : ""} className="td project-field user-project-id">
                  {p.user_project_id}
                </div>
                <div title={App.stage === "dev" ? this.state.designerVersionAtCal : ""} className="td project-field project-type">
                  <ProjectTypeTag background="DARK" type={p.project_type} isForTable={true} />
                </div>
                <div className="td project-field status">
                  <TooltipProjectIcon status={p.status} tooltip={ProjectStatusLabel[p.status!]} />
                </div>
                <div className="td project-field building-type">{buildingTypeLabel(this.props.project.building_type)}</div>
                <div className="td project-field project-name" title={this.props.project.project_name}>
                  <div style={{ marginRight: "2px" }}>{p.discountable && <DiscountTag />}</div>
                  <div className="value" style={{ width: this.props.project.discountable ? "500px" : "520px" }}>
                    {p.project_name}
                  </div>
                  {this.props.project.project_explanation && (
                    <div className="td project-field memo">
                      <TooltipMemoIcon tooltip={this.props.project.project_explanation} />
                    </div>
                  )}
                </div>
              </Link>
              <div className="td project-field favorite">
                {((this.props.project.status === "RUNNING" || this.props.project.status === "WAITING") && (
                  <Tooltip msg="분석중인 프로젝트는 즐겨찾기 기능을 사용하실 수 없습니다.">
                    <IconButton
                      disabled={true}
                      className="favorite-btn"
                      disableRipple={true}
                      onClick={() => {
                        this.props.setProjectFavorite(p, !p.favorite!);
                        this.setState({ favoriteloading: true });
                      }}
                    >
                      {(p.favorite === true && <FavoriteProjectIconActive className="icon active" />) || <FavoriteProjectIcon className="icon" />}
                    </IconButton>
                  </Tooltip>
                )) || (
                  <IconButton
                    className="favorite-btn"
                    disableRipple={true}
                    onClick={() => {
                      this.props.setProjectFavorite(p, !p.favorite!);
                      this.setState({ favoriteloading: true });
                    }}
                  >
                    {this.state.favoriteloading && (
                      <div style={{ width: "100%", height: "100%" }}>
                        <CircularProgress className="icon progress-icon" />{" "}
                      </div>
                    )}
                    {!this.state.favoriteloading && ((p.favorite === true && <FavoriteProjectIconActive className="icon active" />) || <FavoriteProjectIcon className="icon" />)}
                  </IconButton>
                )}
              </div>
              <Link className="link" to={project_link} style={{ textDecoration: "none" }}>
                <div className="td project-field area">{p.project_site_area !== undefined ? Number(p.project_site_area.toFixed(2)).toLocaleString() : 0}㎡</div>
                <div className="td project-field floor-area-ratio">
                  {loc_floor_area_ratio && p.project_type !== "DESIGNER" && p.project_type !== "SITE_PLAN" ? `${Number(loc_floor_area_ratio.toFixed(2)).toLocaleString()}%` : "-"}
                </div>
                <div className="td project-field created-at">
                  {moment().diff(moment(p.created_at).format("YYYY-MM-DD"), "day") === 0 ? moment(p.created_at).format("HH:mm:ss") : moment(p.created_at).format("YYYY-MM-DD")}
                </div>
              </Link>
            </div>
          </Tooltip>

          {this.props.tableType === "TABLE_DETAIL" && (
            <Link className="link" to={project_link} style={{ textDecoration: "none" }}>
              <div className={`ProjectTableDetailRow ${this.state.isMouseOver ? "mouseOver" : ""}`}>
                <div className="detailInfo">
                  <div className="detailRow" style={{ marginTop: "10px", marginBottom: this.resultDetail && p.status === "FINISHED" ? "0px" : "24px" }}>
                    <div className="rowTitle">프로젝트 요약</div>
                    <div className="rowValue">{this.projectDetail}</div>
                  </div>
                  {this.resultDetail && p.status === "FINISHED" && (
                    <div className="detailRow" style={{ marginBottom: "24px" }}>
                      <div className="rowTitle">결과 요약</div>
                      <div className="rowValue">{this.resultDetail}</div>
                    </div>
                  )}
                </div>
              </div>
            </Link>
          )}
        </div>
      </Fragment>
    );
  }
}

interface TableCardProps {
  setProjectFavorite: (project: Project, favorite: boolean) => void;
  project: Project;
  projectId: number;
  margin: number;
  favorite: boolean | undefined;
  setSelectedProject: (user_project_id: number[], allCheck?: boolean) => void;
  selectedProject: Array<{ user_project_id: number; checked: boolean }>;
}

interface TableCardState {
  result: any;
  // isMouseOver: boolean;
  isThumbnailError: boolean;
  project: Project;
  favoriteloading: boolean;
  designerVersionAtCal: string | undefined;
}

class TableCard extends Component<TableCardProps, TableCardState> {
  state: TableCardState = {
    result: undefined,
    // isMouseOver: false,
    isThumbnailError: false,
    project: new Project(),
    favoriteloading: false,
    designerVersionAtCal: "",
  };

  getProject = async () => {
    const ddb = await new AWSModule("DDB").connect();
    const r = await ddb
      .Ddb!.get({
        ConsistentRead: true,
        TableName: "platform-buildit-project",
        Key: {
          project_id: this.props.projectId,
          stage: App.tempStage,
        },
      })
      .promise();

    if (r.Item) {
      this.setState({ project: r.Item }, () => {
        this.getResult();
      });
    }
  };

  componentWillMount = async () => {
    this.getProject();
  };

  componentDidMount = () => {
    if (this.props.project.run_meta_info) {
      this.setState({
        designerVersionAtCal: this.props.project.run_meta_info!.designer_version_at_cal,
      });
    }
  };

  thmbnailErrorHandler = () => {
    this.setState({ isThumbnailError: true });
  };

  getResult = async () => {
    if (this.state.project) {
      const rr = await App.search({
        table: "engine-buildit-result",
        query: `base_project_id:${this.state.project.project_id}`,
        sort: [{ loc_floor_area_ratio: "desc" }],
      });

      const rrr = rr.data;
      if (rrr.hits && rrr.hits.hits && rrr.hits.hits.length > 0) {
        const projectId = rrr.hits.hits[0]._source.base_project_id;
        const reportId = rrr.hits.hits[0]._source.base_report_id;
        const aws = await new AWSModule("DDB").connect();
        const r = await aws
          .Ddb!.get({
            TableName: "engine-buildit-result",
            ConsistentRead: true,
            Key: {
              base_uuid: `${App.tempStage}_${projectId}_${reportId}`,
            },
          })
          .promise();

        if (r.Item) {
          this.setState({ result: r.Item });
        }
      }
    }
  };

  componentDidUpdate = (pp: Readonly<TableCardProps>) => {
    if (_.isEqual(pp.projectId, this.props.projectId) === false) {
      this.getResult();
    }
    if (_.isEqual(pp.favorite, this.props.favorite) === false) {
      this.setState({ favoriteloading: false });
    }
  };

  render() {
    const project_link = this.state.project.status === "CONFIG" ? `/project/edit?pid=${this.state.project.user_project_id}` : `/project/result?pid=${this.state.project.user_project_id}`;
    const tooltipMsg = this.state.project.status === "CONFIG" ? `프로젝트 설정페이지로 이동합니다.` : `프로젝트 결과페이지로 이동합니다.`;
    let loc_floor_area_ratio: string | undefined = undefined;
    const hasImage: boolean =
      this.state.project && this.state.project.status === "FINISHED"
      && this.state.result && this.state.result.base_report_file_path && this.state.result.base_report_file_path.resImage
    const hasFloorRatio =
      (this.state.project.project_type !== "SITE_PLAN" && this.state.project.project_type !== "DESIGNER" && this.state.project.status === "FINISHED") ||
      (this.state.project.project_type === "SITE_PLAN" && this.state.project.selected_my_building_plan_id !== undefined);
    const hasSubBuilding = this.state.project.project_type === "AI" && this.state.project.building_type === "MULTIPLE" && this.state.result && this.state.result.sub_building_type;
    
    if (this.state.result !== undefined && this.state.result.loc_floor_area_ratio && this.state.project.status === "FINISHED") {
      loc_floor_area_ratio = this.state.result.loc_floor_area_ratio;
    }

    if (this.state.project.project_id !== undefined) {
      return (
        <Fragment>
          <Link className="link" to={project_link} style={{ textDecoration: "none" }}>
            <ProjectCardComponent
              project={this.props.project}
              result={this.state.result}
              // isMouseOver={this.state.isMouseOver}
              isThumbnailError={this.state.isThumbnailError}
              favorite={this.props.favorite}
              favoriteloading={this.state.favoriteloading}
              hasImage={hasImage}
              hasFloorRatio={hasFloorRatio}
              hasSubBuilding={hasSubBuilding}
              designerVersionAtCal={this.state.designerVersionAtCal}
              tooltipMsg={tooltipMsg}
              margin={this.props.margin}
              selectedProject={this.props.selectedProject}
              setSelectedProject={this.props.setSelectedProject}
              setProjectFavorite={this.props.setProjectFavorite}
              thmbnailErrorHandler={this.thmbnailErrorHandler}
            />
          </Link>
        </Fragment>
      );
    } else {
      return <div></div>;
    }
  }
}
