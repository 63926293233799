import React, { Component, Fragment } from "react";
import "./css/ProjectCardComponent.scss";
import App from "./App";
import MapView from "./MapView";
import ProjectTypeTag from "./ProjectTypeTag";
import { Project } from "./model/Project";
import moment from "moment";
import S3Image from "./S3Image";
import Tooltip from './Tooltip';
import { Checkbox, IconButton, CircularProgress } from "@material-ui/core";
import ProjectIcon, { TooltipProjectIcon } from "./ProjectIcon";
import { ProjectStatusLabel } from "./ProjectList";
import FavoriteProjectIconActive from "@material-ui/icons/Star";
import FavoriteProjectIcon from "@material-ui/icons/StarBorder";
import TooltipMemoIcon from "./TooltipMemoIcon";
import DiscountTag from "./DiscountTag";


export interface ProjectCardComponentProps {
  project: Project;
  result: any;
  favorite: boolean | undefined;
  favoriteloading: boolean;
  hasImage: boolean;
  hasFloorRatio: boolean;
  hasSubBuilding: boolean;
  margin: number;
  isThumbnailError?: boolean;
  designerVersionAtCal?: string;
  tooltipMsg?: string;
  selectedProject?: Array<{ user_project_id: number; checked: boolean }>;
  setSelectedProject?: (user_project_id: number[], allCheck?: boolean) => void;
  setProjectFavorite: (project: Project, favorite: boolean) => void;
  thmbnailErrorHandler?: () => void;
}

export interface ProjectCardComponentState {
  favorite: boolean | undefined;
  isMouseOver: boolean;
  favoriteloading: boolean;
}

export default class ProjectCardComponent extends Component<ProjectCardComponentProps, ProjectCardComponentState> {
  state = {
    favorite: false,
    isMouseOver: false,
    favoriteloading: false,
  };

  componentDidMount = () => {
    const favorite = this.props.favorite;
    const favoriteloading = this.props.favoriteloading;

    this.setState({
      favorite,
      favoriteloading,
    });
  };

  render = () => {
    let no_img_string: string = "";

    if (this.props.project.status === "CONFIG") {
      no_img_string = "프로젝트 설정을 완료해주세요.";
    } else if (this.props.project.status === "RUNNING" || this.props.project.status === "WAITING") {
      no_img_string = "프로젝트를 분석 중 입니다.";
    } else if (this.props.project.status === "FAILED") {
      no_img_string = "프로젝트 확인이 필요합니다.";
    }
    return (
      <div
        className={`projectCard ${this.state.isMouseOver ? "mouseOver" : ""}`}
        style={{ marginRight: this.props.margin }}
        onMouseEnter={() => this.setState({ isMouseOver: true })}
        onMouseLeave={() => this.setState({ isMouseOver: false })}
        id={this.props.project.project_id ? this.props.project.project_id.toString() : ""}
      >
        {this.props.tooltipMsg && <Tooltip className="cardToolTip" msg={this.props.tooltipMsg} on={this.props.project.status === "CONFIG" ? this.state.isMouseOver : false} />}
        <div className="header">
          <div className={this.props.selectedProject ? `cardCheck` : `cardCheck-hidden`}>
            {this.props.selectedProject && (
              <Checkbox
                className={`checkbox ${(this.props.selectedProject[this.props.selectedProject.findIndex((p) => p.user_project_id === this.props.project.user_project_id)].checked && "checked") || ""}`}
                checked={this.props.selectedProject[this.props.selectedProject.findIndex((p) => p.user_project_id === this.props.project.user_project_id)].checked}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.props.setSelectedProject && this.props.setSelectedProject([this.props.project.user_project_id!]);
                }}
              />
            )}
          </div>

          <div className="idNumber font bg-navy font-primary font-roboto font-pretendard font-14px">{this.props.project.user_project_id ? this.props.project.user_project_id : ""}</div>
          <div className="type" title={App.stage === "dev" ? this.props.designerVersionAtCal : ""}>
            <ProjectTypeTag background="DARK" type={this.props.project.project_type} />
          </div>
          <div className="statusField">
            <TooltipProjectIcon status={this.props.project.status} tooltip={ProjectStatusLabel[this.props.project.status!]} />
          </div>
          {((this.props.project.status === "RUNNING" || this.props.project.status === "WAITING") && (
            <Tooltip className="favorite" msg="분석중인 프로젝트는 즐겨찾기 기능을 사용하실 수 없습니다.">
              <div className="favorite">{(this.state.favorite === true && <FavoriteProjectIconActive className="icon active" />) || <FavoriteProjectIcon className="icon" />}</div>
            </Tooltip>
          )) || (
            <div
              className="favorite"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.props.setProjectFavorite(this.props.project, !this.props.favorite!);
                this.setState({ favoriteloading: true, favorite: !this.state.favorite });
              }}
            >
              {this.props.favoriteloading && (
                <div style={{ width: "100%", height: "100%" }}>
                  <CircularProgress className="icon progress-icon" />{" "}
                </div>
              )}
              {!this.props.favoriteloading && ((this.state.favorite === true && <FavoriteProjectIconActive className="icon active" />) || <FavoriteProjectIcon className="icon" />)}
            </div>
          )}
        </div>
        {/* end Header */}
        {/* 이미지 */}
        {(this.props.hasImage && (
          <div className="img">
            {this.props.project && this.props.project.status === "FINISHED" && (
              // 완료된 AI,CAL, DESIGNER, SITE_PLAN 프로젝트 (통합)
              <div>
                {this.props.result && this.props.result.base_report_file_path && this.props.result.base_report_file_path.resImage && (
                  <>
                    <S3Image
                      props={{ className: "img" }}
                      s3={{
                        Bucket: "teneleven-engine-result",
                        Key: this.props.result.base_report_file_path.resImage.split("teneleven-engine-result/")[1],
                      }}
                    />
                    <div className="content">
                      <div className="key-value">
                        {this.props.hasSubBuilding && (
                          <>
                            <div className="key">종류</div>
                            <div className="value">{this.props.result.sub_building_type}</div>
                          </>
                        )}
                      </div>
                      {this.props.hasFloorRatio && (
                        <div className="key-value">
                          <div className="key">용적률</div>
                          <div className="value">{Number(this.props.result.loc_floor_area_ratio).toFixed(2)}%</div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )) || (
          <div className="no-img">
            <div className="inner-background">
              {<ProjectIcon className="icon-field" iconClassName="status-icon" status={this.props.project.status} />}
              <div className="no-img-text">{no_img_string}</div>
            </div>
          </div>
        )}
        {/* end 이미지 */}
        <article className="tailContent">
          <div title={this.props.project.project_name} className="name-wrapper title">
            {this.props.project.discountable && <div style={{ marginRight: "5px", display: "flex" }}>{this.props.project.discountable && <DiscountTag />}</div>}
            <div className="name font bg-navy font-primary font-noto font-pretendard font-14px" style={{ width: this.props.project.discountable ? "225px" : "245px" }}>
              {this.props.project.project_name}
            </div>
            {this.props.project.project_explanation && (
              <div className="memo">
                <TooltipMemoIcon tooltip={this.props.project.project_explanation} />
              </div>
            )}
          </div>

          <div className="area font bg-navy font-primary font-roboto font-pretendard font-12px">
            {this.props.project.building_type ? (
              <>
                <div className="key">건물 종류</div>
                <div className="value">{this.props.project.building_type === "MULTIPLE" ? "BUILDIT 추천" : this.props.project.building_type}</div>
              </>
            ) : (
              <>
                <div className="key">건물 종류</div>
                <div className="value"> - </div>
              </>
            )}
          </div>

          <div className="area font bg-navy font-primary font-roboto font-pretendard font-12px">
            <>
              <div className="key">면적</div>
              <div className="value">{this.props.project.project_site_area!.toFixed(2)}㎡</div>
            </>
          </div>
          <div className="area font bg-navy font-primary font-roboto font-pretendard font-12px">
            <div className="key">생성일</div>
            <div className="value"> {this.props.project.created_at && moment(this.props.project.created_at).format("YYYY-MM-DD")} </div>
          </div>
        </article>
      </div>
    );
  };
}
