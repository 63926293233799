

import React, { Component } from 'react';
import Tree from 'rc-tree';
import '../../css/CADConverter/Rc-tree.scss';
import '../../css/CADConverter/BlockTreeList.scss';
import { BuildingComponent, BuildingPart, ConverterBuilding } from '../BuildingPart';
import { DataNode, EventDataNode, Key, IconType } from '../../../node_modules/rc-tree/lib/interface';
// import { setBlockOpacity } from './CoreAndHouseController';
import { BuildingHouseUnit } from '../BuildingHouseUnit';
import { BuildingCoreUnit } from '../BuildingCoreUnit';
import { ExpandMore, ChevronRight, KeyboardArrowRight } from '@material-ui/icons';
// import { Polygon } from './DataTypes';
import { ReactComponent as BlockIcon } from '../../img/icon/blockIcon.svg';
import '../../css/CADConverter/BlockInspectorTree.scss';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
// import { Button } from '@material-ui/core';
// import Tooltip from '../Tooltip';
// import { ErrorList } from './Error';


const STYLE = `
.rc-tree-child-tree {
  display: block;
}

.node-motion {
  box-sizing: border-box;
  transition: all .3s;
  overflow-y: hidden;
  overflow-x: hidden;
}
`;

const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: () => ({ height: 0 }),
  onAppearActive: (node: any) => ({ height: node.scrollHeight }),
  onLeaveStart: (node: any) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 }),
};

interface BlockInspectorTreeProps {
  buildings: ConverterBuilding | BuildingComponent,
}

interface BlockInspectorTreeState {
  switcherOnOff: boolean;
}

class BlockInspectorTree extends Component<BlockInspectorTreeProps> {
  treeRef: React.RefObject<any> = React.createRef(); //LegacyRef<Tree> | undefined = React.createRef();

  state: BlockInspectorTreeState = {
    switcherOnOff: true, // icon fold unfold
  };

  getTreeData = () => {

    let buildings = this.props.buildings;
    let result: DataNode[] = [];
  
    buildings.parts.forEach((part: BuildingPart) => {
      let blockB: DataNode = {
        key: part.uuid,
        title: <div className="title">
          <div className="font font-emphasis left-side font-14px">
            <BlockIcon className="icon block-icon" />
            <div className="name">{`${part.name} - ${part.sameNameNumber}`}</div>
          </div>
          <div className="font font-secondary right-side font-12px">코어-세대 조합</div>
        </div>,
        children: [],
        switcherIcon: <KeyboardArrowRight className="icon switcher-icon"/>, 
        className: "blockB",
      }
    

      part.parts.forEach((component) => {

        let componentNode: DataNode = {
          key: component.uuid,
          title: <div className="title">
            <div className="font font-emphasis left-side font-14px">
              <BlockIcon className="icon block-icon" />
              <div className="name">{`${component.name.split('_')[0]} - ${component.sameNameNumber}`}</div>
            </div>
            {component.buildingType === "component" &&
              //@ts-ignore
              <div className="font font-secondary right-side font-12px">{component.componentType === "core" ? "코어" : "단위세대"}</div>
            }
          </div>,
          children: [],
          switcherIcon:  <KeyboardArrowRight className="icon switcher-icon"/>, 
          className: "component"
        }


        if (component.buildingType === "component") {
          //@ts-ignore
          if (component.componentType === "core") {
            let infoNode: DataNode = {
              key: `${component.uuid}1`,
              title: <div className="font font-secondary font-12px area-info">코어면적: {(component as BuildingCoreUnit).GetArea()}</div>,
            }
            componentNode.children!.push(infoNode);
          }
          //@ts-ignore
          else if (component.componentType === "house") {
            let exclusiveAreaNode: DataNode = {
              key: `${component.uuid}1`,
              title: <div className="font font-secondary font-12px area-info">전용면적: {(component as BuildingHouseUnit).exclusiveArea.toFixed(2)}</div>
            }
            componentNode.children!.push(exclusiveAreaNode);

            let serviceAreaNode: DataNode = {
              key: `${component.uuid}2`,
              title: <div className="font font-secondary font-12px area-info">서비스면적: {(component as BuildingHouseUnit).serviceArea.toFixed(2)}</div>
              
            }
            componentNode.children!.push(serviceAreaNode);

            let commonWallAreaNode: DataNode = {
              key: `${component.uuid}3`,
              title: <div className="font font-secondary font-12px area-info">벽체공용면적: {(component as BuildingHouseUnit).commonWallArea.toFixed(2)}</div>

            }
            componentNode.children!.push(commonWallAreaNode);
          }
        }

        //@ts-ignore
        blockB.children.push(componentNode);
      })
      result.push(blockB);
      //@ts-ignore
      //blockD.children.push(blockB);
    })

    return result;
  }


  render() {
    return (
      <div className="animation BlockInspectorTree m-t-sm">
        <style dangerouslySetInnerHTML={{ __html: STYLE }} />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 50%' }}>
            <Tree
              ref={this.treeRef}
              defaultExpandAll={true}
              autoExpandParent={true}
              defaultExpandParent={true}
              // defaultExpandedKeys={defaultExpandedKeys}
              motion={motion}
              treeData={this.getTreeData()}
              onSelect={(key, info) => {
                // this.onSelectNode(info.node);
              }}
              // onClick={(key, info) => this.onExpand(info.key, info)}
            
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BlockInspectorTree;