import React, { Component, ReactDOM } from 'react';
import * as THREE from '@teneleven/three';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { OrbitControls } from '@teneleven/three/examples/jsm/controls/OrbitControls'
import '../css/CADConverter/SaveViewerModal.scss'

export interface SaveViewerModalProps {
  open: boolean,
  isLatestVer: boolean,
  buildingObject: () => THREE.Group,
  positive: () => void,
}

interface SaveViewerModalState {
  open: boolean,
  update: number,
}

export class SaveViewerModal extends Component<SaveViewerModalProps, SaveViewerModalState> {
  SCENE_WIDTH = 698;
  SCENE_HEIGHT = 330;

  state: SaveViewerModalState = {
    open: false,
    update: 0,
  }

  renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true });
  scene: THREE.Scene = new THREE.Scene();
  perspectiveCamera: THREE.PerspectiveCamera = new THREE.PerspectiveCamera(60, this.SCENE_WIDTH / this.SCENE_HEIGHT, 0.1, 10000);
  perspectiveControl: OrbitControls = new OrbitControls(this.perspectiveCamera, this.renderer.domElement);;
  polygon3DGroup = new THREE.Group();

  animate = () => {
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.perspectiveCamera);
  }

  componentDidMount = () => {
    this.perspectiveControl.mouseButtons.LEFT = THREE.MOUSE.RIGHT;
    this.perspectiveControl.mouseButtons.RIGHT = THREE.MOUSE.LEFT;


    this.renderer.setSize(this.SCENE_WIDTH, this.SCENE_HEIGHT);
    this.renderer.setClearColor(new THREE.Color(0x111a22), 1);

    this.perspectiveCamera.aspect = this.SCENE_WIDTH / this.SCENE_HEIGHT;
    this.perspectiveCamera.updateProjectionMatrix();
    this.perspectiveCamera.position.set(0, -30, 0);

    this.scene.add(new THREE.AmbientLight(0xffffff, 0.5));
    let light = new THREE.DirectionalLight();
    light.position.set(10, 10, 10);
    this.scene.add(light);
    this.scene.add(light.target);

    this.scene.add(this.polygon3DGroup);

    this.animate();
  }

  componentDidUpdate = (previousProps: Readonly<SaveViewerModalProps>, previousState: Readonly<SaveViewerModalState>,) => {
    if (previousProps.open !== this.props.open) {
      this.polygon3DGroup.children = [];
      this.polygon3DGroup.add(this.props.buildingObject());
      this.resetCameraPosition();

      this.setState({
        open: true,
      })
    }
  }

  resetCameraPosition = () => {
    let bbox = new THREE.Box3();
    bbox.expandByObject(this.polygon3DGroup);
    let bboxCenter = new THREE.Vector3();
    bbox.getCenter(bboxCenter);

    this.perspectiveControl.target = bboxCenter;
    this.perspectiveControl.object.position.set(bboxCenter.x, bboxCenter.y + 8, bboxCenter.z + 10);
    this.perspectiveControl.update();
  }

  closeModal = () => {
    this.setState({ open: false })
  }

  positive = () => {
    this.props.positive();
    this.closeModal();
  }

  render = () => {
    if (this.props.isLatestVer) {
      return (<div className="modal-content">
        <div className='SaveCanvas' ref={ref => ref && ref.appendChild(this.renderer.domElement)} />
      </div>
      );
    }
    else {
      return (

        <React.Fragment>
          <div>
            <Dialog className='SaveModalBody preVersion' aria-labelledby="simple-dialog-title" open={this.state.open}>
              <div className='modal-title' id="alert-dialog-title">
                <span className='title-content'>나의 동평면 저장</span>
                <CloseIcon className="icon" onClick={this.closeModal} />
              </div>
              <div className="modal-content">
                <div className='SaveCanvas ' ref={ref => ref && ref.appendChild(this.renderer.domElement)} />
              </div>
              <div className="modal-action">
                <span className='action-content'>나의 동평면을 저장하시겠습니까?</span>
                <span className="modal-btn negative" onClick={this.closeModal}><span className='btn-text'>취소</span></span>
                <span className="modal-btn positive" onClick={this.positive}><span className='btn-text'>확인</span></span>
              </div>
            </Dialog>
          </div>
        </React.Fragment>
      )

    }
  }
}