import React, { Component } from 'react';
import Tabs from './Tabs';
import { Button } from '@material-ui/core';
import './css/BuildingLaws.scss';

export type Laws = "A" | "B" | "C" | "D" | "E" | "F";

interface BuildingLawsState {
  type: Laws;
}
interface BuildingLawsProps {
  initial_type?: Laws;
}

export default class BuildingLaws extends Component<BuildingLawsProps, BuildingLawsState> {
  componentWillMount = () => {
    this.setState({type: this.props.initial_type ? this.props.initial_type : "A"});
  }
  componentDidMount = () => {
    
  }
  state: BuildingLawsState = {
    type: "A",
  }
  render() {
    return (
      <div className="BuildingLaws buildit-template">
        <div className="body">
          <Tabs className="bg-navy tabs" align="vertical">
            <Button onClick={() => this.setState({ type: "A" })} disableRipple={true} className={`${this.state.type === "A" && "active"} bg-navy tab tab-primary`}>
              정북일조
            </Button>
            <Button onClick={() => this.setState({ type: "B" })} disableRipple={true} className={`${this.state.type === "B" && "active"} bg-navy tab tab-primary`}>
              채광사선
            </Button>
            <Button onClick={() => this.setState({ type: "C" })} disableRipple={true} className={`${this.state.type === "C" && "active"} bg-navy tab tab-primary`}>
              채광창↔다른건물 인동거리
            </Button>
            <Button onClick={() => this.setState({ type: "D" })} disableRipple={true} className={`${this.state.type === "D" && "active"} bg-navy tab tab-primary`}>
              벽면↔측벽 인동거리
            </Button>
            <Button onClick={() => this.setState({ type: "E" })} disableRipple={true} className={`${this.state.type === "E" && "active"} bg-navy tab tab-primary`}>
              측벽↔측벽 인동거리
            </Button>
            <Button onClick={() => this.setState({ type: "F" })} disableRipple={true} className={`${this.state.type === "F" && "active"} bg-navy tab tab-primary`}>
              대지안의공지
            </Button>
          </Tabs>
          <div className="content">
            {this.state.type === "A" && (
              <div className="info">
                <img className="img" src="/img/law_picture/A.png" />
                <span className="font font-14px">정북일조</span>
                <br />
                <span>건축법시행령 제86조 관련 전용주거지역/일반주거지역에서 정북방향 대지 인접대지경계선으로부터 이격하는 거리를 나타냅니다.</span>
                <br />
                <span className="font font-special">건축법 시행령 제 86조</span>
                <br />
                <span>[일조 등의 확보를 위한 건축물의 높이제한] 1항</span>
                <span>
                  ① 전용주거지역이나 일반주거지역에서 건축물을 건축하는 경우에는 법 제61조제1항에 따라 건축물의 각 부분을 정북(正北) 방향으로의 인접 대지경계선으로부터 다음 각 호의 범위에서 건축조례로
                  정하는 거리 이상을 띄어 건축하여야 한다. [개정 2015. 7. 6.]
                </span>
                <br />
                <span>1. 높이 9미터 이하인 부분: 인접 대지경계선으로부터 1.5미터 이상 </span>
                <span>2. 높이 9미터를 초과하는 부분: 인접 대지경계선으로부터 해당 건축물 각 부분 높이의 2분의 1 이상</span>
              </div>
            )}
            {this.state.type === "B" && (
              <div className="info">
                <img className="img" src="/img/law_picture/B.png" />
                <span className="font font-14px">채광사선</span>
                <br />
                <span>건축법시행령 제86조 관련 공동주택의 채광창에서부터 인접대지경계선 사이의 이격거리를 나타냅니다.</span>
                <br />
                <span className="font font-special">건축법 시행령 제 86조</span>
                <br />
                <span>[일조 등의 확보를 위한 건축물의 높이제한] 3항 1호</span>
                <span>
                  ③ 법 제61조제2항에 따라 공동주택은 다음 각 호의 기준에 적합하여야 한다. 다만, 채광을 위한 창문 등이 있는 벽면에서 직각 방향으로 인접 대지경계선까지의 수평거리가 1미터 이상으로서
                  건축조례로 정하는 거리 이상인 다세대주택은 제1호를 적용하지 아니한다.[개정 2009. 7. 16., 2013. 5. 31., 2015. 7. 6.]
                </span>
                <br />
                <span>
                  1. 건축물(기숙사는 제외한다)의 각 부분의 높이는 그 부분으로부터 채광을 위한 창문 등이 있는 벽면에서 직각 방향으로 인접 대지경계선까지의 수평거리의 2배(근린상업지역 또는 준주거지역의
                  건축물은 4배) 이하로 할 것
                </span>
              </div>
            )}
            {this.state.type === "C" && (
              <div className="info">
                <img className="img" src="/img/law_picture/C.png" />
                <span className="font font-14px">채광창↔다른건물 인동거리</span>
                <br />
                <span>건축법시행령 제86조 관련 공동주택의 서로 다른 동의 채광창이 있는 벽면끼리의 이격거리를 나타냅니다.</span>
                <br />
                <span className="font font-special">건축법 시행령 제 86조</span>
                <br />
                <span>[일조 등의 확보를 위한 건축물의 높이제한] 3항 2호 가목</span>
                <span>
                  ③ 법 제61조제2항에 따라 공동주택은 다음 각 호의 기준에 적합하여야 한다. 다만, 채광을 위한 창문 등이 있는 벽면에서 직각 방향으로 인접 대지경계선까지의 수평거리가 1미터 이상으로서
                  건축조례로 정하는 거리 이상인 다세대주택은 제1호를 적용하지 아니한다.[개정 2009. 7. 16., 2013. 5. 31., 2015. 7. 6.]
                </span>
                <br />
                <span>
                  2. 같은 대지에서 두 동(棟) 이상의 건축물이 서로 마주보고 있는 경우(한 동의 건축물 각 부분이 서로 마주보고 있는 경우를 포함한다)에 건축물 각 부분 사이의 거리는 다음 각 목의 거리
                  이상을 띄어 건축할 것. 다만, 그 대지의 모든 세대가 동지(冬至)를 기준으로 9시에서 15시 사이에 2시간 이상을 계속하여 일조(日照)를 확보할 수 있는 거리 이상으로 할 수 있다.
                </span>
                <br />
                <span>가. 채광을 위한 창문 등이 있는 벽면으로부터 직각방향으로 건축물 각 부분 높이의 0.5배(도시형 생활주택의 경우에는 0.25배) 이상의 범위에서 건축조례로 정하는 거리 이상</span>
              </div>
            )}
            {this.state.type === "D" && (
              <div className="info">
                <img className="img" src="/img/law_picture/D.png" />
                <span className="font font-14px">벽면↔측벽 인동거리</span>
                <br />
                <span>건축법시행령 제86조 관련 공동주택의 서로 다른 동의 채광창이 없는 벽면과 측벽 사이의 이격거리를 나타냅니다.</span>
                <br />
                <span className="font font-special">건축법 시행령 제 86조</span>
                <br />
                <span>[일조 등의 확보를 위한 건축물의 높이제한] 3항 2호 라목</span>
                <span>
                  ③ 법 제61조제2항에 따라 공동주택은 다음 각 호의 기준에 적합하여야 한다. 다만, 채광을 위한 창문 등이 있는 벽면에서 직각 방향으로 인접 대지경계선까지의 수평거리가 1미터 이상으로서
                  건축조례로 정하는 거리 이상인 다세대주택은 제1호를 적용하지 아니한다.[개정 2009. 7. 16., 2013. 5. 31., 2015. 7. 6.]
                </span>
                <br />
                <span>
                  2. 같은 대지에서 두 동(棟) 이상의 건축물이 서로 마주보고 있는 경우(한 동의 건축물 각 부분이 서로 마주보고 있는 경우를 포함한다)에 건축물 각 부분 사이의 거리는 다음 각 목의 거리
                  이상을 띄어 건축할 것. 다만, 그 대지의 모든 세대가 동지(冬至)를 기준으로 9시에서 15시 사이에 2시간 이상을 계속하여 일조(日照)를 확보할 수 있는 거리 이상으로 할 수 있다.
                </span>
                <br />
                <span>라. 채광창(창 넓이가 0.5제곱미터 이상인 창을 말한다)이 없는 벽면과 측벽이 마주보는 경우에는 8미터 이상</span>
              </div>
            )}
            {this.state.type === "E" && (
              <div className="info">
                <img className="img" src="/img/law_picture/E.png" />
                <span className="font font-14px">측벽↔측벽 인동거리</span>
                <br />
                <span>건축법시행령 제86조 관련 공동주택의 서로 다른 동의 측벽끼리의 이격거리를 나타냅니다.</span>
                <br />
                <span className="font font-special">건축법 시행령 제 86조</span>
                <br />
                <span>[일조 등의 확보를 위한 건축물의 높이제한] 3항 2호 마목</span>
                <span>
                  ③ 법 제61조제2항에 따라 공동주택은 다음 각 호의 기준에 적합하여야 한다. 다만, 채광을 위한 창문 등이 있는 벽면에서 직각 방향으로 인접 대지경계선까지의 수평거리가 1미터 이상으로서
                  건축조례로 정하는 거리 이상인 다세대주택은 제1호를 적용하지 아니한다.[개정 2009. 7. 16., 2013. 5. 31., 2015. 7. 6.]
                </span>
                <br />
                <span>
                  2. 같은 대지에서 두 동(棟) 이상의 건축물이 서로 마주보고 있는 경우(한 동의 건축물 각 부분이 서로 마주보고 있는 경우를 포함한다)에 건축물 각 부분 사이의 거리는 다음 각 목의 거리
                  이상을 띄어 건축할 것. 다만, 그 대지의 모든 세대가 동지(冬至)를 기준으로 9시에서 15시 사이에 2시간 이상을 계속하여 일조(日照)를 확보할 수 있는 거리 이상으로 할 수 있다.
                </span>
                <br />
                <span>
                  마. 측벽과 측벽이 마주보는 경우[마주보는 측벽 중 하나의 측벽에 채광을 위한 창문 등이 설치되어 있지 아니한 바닥면적 3제곱미터 이하의 발코니(출입을 위한 개구부를 포함한다)를 설치하는
                  경우를 포함한다]에는 4미터 이상
                </span>
              </div>
            )}
            {this.state.type === "F" && (
              <div className="info">
                <img className="img" src="/img/law_picture/F.png" />
                <span className="font font-14px">대지안의공지</span>
                <br />
                <span>건축법시행령 제80조의 2 관련 건축선 및 인접대지경계선으로부터 건축물까지의 이격거리를 나타냅니다.</span>
                <br />
                <span className="font font-special">건축법 시행령 제 80조의 2</span>
                <br />
                <span>[대지 안의 공지]</span>
                <span>
                  법 제58조에 따라 건축선(법 제46조제1항에 따른 건축선을 말한다. 이하 같다) 및 인접대지경계선(대지와 대지 사이에 공원, 철도, 하천, 광장, 공공공지, 녹지, 그 밖에 건축이 허용되지
                  아니하는 공지가 있는 경우에는 그 반대편의 경계선을 말한다)으로부터 건축물의 각 부분까지 띄어야 하는 거리의 기준은 별표 2와 같다. [개정 2014. 10. 14.] [전문개정 2008. 10. 29.]
                </span>
                <br />
                <span>*별표2에 표기된 공동주택의 건축선 및 인접대지경계선 후퇴기준은 다음과 같다.</span>
                <br />
                <span>(건축선 기준) 아파트: 2m 이상 6m 이하 / 연립주택: 2m 이상 5m 이하 / 다세대주택: 1m 이상 4m 이하</span>
                <span>(인접대지선선 기준) 아파트: 2m 이상 6m 이하 / 연립주택: 1.5m 이상 5m 이하 / 다세대주택: 0.5m 이상 4m 이하</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}