import * as THREE from '@teneleven/three';
import Axios from "axios";

export enum MatType {
    WALL,
    FLOOR,
    CEILING,
    WINDOW,
    BOTTOMLINE,
    PARKINGLINE,
    LOGO,
    BUILDNO
}

export class MaterialManager {
    private static _instance: MaterialManager;

    private _flipY = false;

    readonly resloc_materials = new Map<MatType, any>();

    // readonly valuemapMaterials = new Map<MatType, any>();

    public static getInstance(font: THREE.Font | undefined = undefined) {
        return this._instance || (this._instance = new this(font));
    }

    private constructor(public readonly font: THREE.Font | undefined) {
        this.resloc_materials.set(MatType.WALL, {
            FC_CORE: new THREE.MeshPhongMaterial({color: '#54595b'}),
            FC_HOUSE: new THREE.MeshPhongMaterial({color: '#eeeeee', transparent: false, opacity: 0.5})
        });

        this.resloc_materials.set(MatType.FLOOR, {
            FC_CORE: new THREE.MeshStandardMaterial({map: loadTexture('Floor_cement.jpg')}),
            FC_HOUSE: new THREE.MeshStandardMaterial({map: loadTexture('Floor_wood.jpg')})
        });

        this.resloc_materials.set(MatType.CEILING, new THREE.MeshStandardMaterial({map: loadTexture('ceiling-knockdown.jpg')}));

        this.resloc_materials.set(MatType.WINDOW, [
            new THREE.MeshBasicMaterial({
                color: '#ddedf8',
                transparent: true,
                reflectivity: 1,
                opacity: 0.3,
                side: THREE.DoubleSide
            }),
            new THREE.MeshBasicMaterial({color: '#000000', side: THREE.DoubleSide}),
            new THREE.MeshBasicMaterial({map: loadTexture('Window.jpg')}),
            new THREE.MeshBasicMaterial({map: loadTexture('Window.jpg', false, Math.PI)})
        ]);

        this.resloc_materials.set(MatType.BOTTOMLINE, new THREE.LineBasicMaterial({color: '#999999'}));

        this.resloc_materials.set(MatType.PARKINGLINE, new THREE.LineBasicMaterial({color: 0xff0000}));

        const logoImage = '2019_BUILDIT_logo.png';
        this.resloc_materials.set(MatType.LOGO, [
            new THREE.MeshBasicMaterial({color: '#54595b'}),
            new THREE.MeshBasicMaterial({
                map: loadTexture(logoImage),
                alphaTest: 0.2
            }),
            new THREE.MeshBasicMaterial({
                map: loadTexture(logoImage, false, Math.PI),
                alphaTest: 0.2,
            })
        ]);

        this.resloc_materials.set(MatType.BUILDNO, [
            new THREE.MeshPhongMaterial({color: '#81878c', flatShading: true, side: THREE.DoubleSide}), // front
            new THREE.MeshPhongMaterial({color: '#54595b', side: THREE.DoubleSide}) // side
        ]);
    }

    public flipY(){
        return this._flipY;
    }

    public SetFlipY(flipY: boolean) {
        // this.resloc_materials.get(MatType.LOGO)[1].map!.flipY = this.resloc_materials.get(MatType.WINDOW)[2].map!.flipY = flipY;
        // this.resloc_materials.get(MatType.LOGO)[1].map!.rotation = this.resloc_materials.get(MatType.WINDOW)[2].map!.rotation = rotation;
        this._flipY = flipY;
        let windowMats = this.resloc_materials.get(MatType.WINDOW);
        let logoMats = this.resloc_materials.get(MatType.LOGO);

        if (flipY) {
            if (!windowMats[2].map!.flipY) {
                windowMats = [windowMats[0], windowMats[1], windowMats[2], windowMats[3]];
                logoMats = [logoMats[0], logoMats[1], logoMats[2]];
            }
        }
        else {
            if (windowMats[2].map!.flipY) {
                windowMats = [windowMats[0], windowMats[1], windowMats[3], windowMats[2]];
                logoMats = [logoMats[0], logoMats[2], logoMats[1]];
            }
        }

        this.resloc_materials.set(MatType.WINDOW, windowMats);
        this.resloc_materials.set(MatType.LOGO, logoMats);
    }

    public static mkMat = (color: any) => new THREE.MeshStandardMaterial({color: color, roughness: 1, metalness: 0.8});

    public static async LoadFont(url: string) : Promise<THREE.Font | undefined> {
        if (this._instance) {
            return undefined;
        }
        let jsonData = await Axios.get(url);
        return new THREE.FontLoader().parse(jsonData.data);
    }
}

export function loadTexture(textureName: string, flipY: boolean = true, rotation: number = 0) : THREE.Texture {
    let texture = new THREE.TextureLoader().load('./img/Textures/' + textureName);
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    // texture.offset.set(0, 0);
    // texture.repeat.set(2, 2);
    texture.rotation = rotation;
    texture.flipY = flipY;
    return texture;
}