import React, { Fragment, Component } from "react";
import { Button } from "@material-ui/core";

import "./css/ProjectFormDetail.scss";
import ProjectFormBasic from "./ProjectFormBasic";
import ProjectFormValue from "./ProjectFormValue";
import ProjectFormBuilding from "./ProjectFormBuilding";
import ProjectFormHousing from "./ProjectFormHousing";
import { ProjectFormPropsBase } from "./ProjectInput";
import ProjectFormBuildingDrawer from "./ProjectFormBuildingDrawer";
import Tabs from "./Tabs";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { DarkSwitch } from "./CustomSwitch";
import ProjectFormHousingChart from "./ProjectFormHousingChart";
import Switch from "./Switch";
import ProjectFormJiguInfo from "./ProjectFormJiguInfo";
import { JiguInfo, 프로젝트_기본값, reset_type } from "./ProjectForm";
import { Project, BuildingType, ProjectUseDistrict } from "./model/Project";
import Tooltip from "./Tooltip";
import { ModalOptions } from "./Modal";

export type ConfigType = "BASIC" | "VALUE" | "BUILDING" | "HOUSING";

export interface ProjectFormDetailProps extends ProjectFormPropsBase {
  setOpenBuildingDrawer: (open: boolean) => void;
  setOpenDetailConfig: (open: boolean) => void;
  setOpenHousingChart: (open: boolean) => void;
  setOpenJiguInfo: (open: boolean) => void;
  onUpdateAddress: (callback?: Function) => void;
  onUpdateDefaults: (set_buildingType?: BuildingType, set_use_distirct?: ProjectUseDistrict, reset?: reset_type) => void;
  updateJiguInfo: (jiguInfo: Array<Array<JiguInfo>>) => void;
  // setAutoSetting: (on: boolean) => void;
  openDetailConfig: boolean;
  openBuildingDrawer: boolean;
  openHousingChart: boolean;
  openJiguInfo: boolean;
  jiguInfo: Array<Array<JiguInfo>>;
  buildingDefault: Map<string, 프로젝트_기본값>;
  initDefault?: 프로젝트_기본값;
  setModal: (open: boolean, options?: ModalOptions) => void;
  // autoSetting: boolean;
}
export interface ProjectFormDetailState {
  configType: ConfigType;
  moreBasic: boolean;
  moreValue: boolean;
}

export default class ProjectFormDetail extends Component<ProjectFormDetailProps, ProjectFormDetailState> {
  state: ProjectFormDetailState = {
    configType: "BASIC",
    moreBasic: false,
    moreValue: false,
  };
  componentDidUpdate = () => {};

  render() {
    return (
      <div className="ProjectFormDetail">
        <Tooltip
          msg="설정이 필요한 부분이 있습니다"
          place="left"
          on={!this.props.openDetailConfig && Object.values(this.props.projectErros!.errs).filter((e) => e!.configType === "DETAIL").length > 0}
          className="detail-config-btn-tooltip"
        >
          <Button
            disableRipple={true}
            className={`font font-pretendard detail-config-btn ${Object.values(this.props.projectErros!.errs).filter((r) => r !== undefined && r.configType === "DETAIL").length > 0 && "error-field"}`}
            onClick={(e) => this.props.setOpenDetailConfig(!this.props.openDetailConfig)}
          >
            {(this.props.openDetailConfig && <KeyboardArrowRightIcon className="icon" />) || <KeyboardArrowLeftIcon className="icon" />}
            상세 설정
          </Button>
        </Tooltip>
        {this.props.openBuildingDrawer && <ProjectFormBuildingDrawer {...this.props} open={this.props.openBuildingDrawer} setOpen={this.props.setOpenBuildingDrawer} />}
        {this.props.openHousingChart && this.props.currentProject.project_type! === "AI" && (
          <ProjectFormHousingChart {...this.props} open={this.props.openHousingChart} setOpen={this.props.setOpenHousingChart} project={this.props.currentProject} />
        )}
        {this.state.configType === "BASIC" && this.props.openDetailConfig && this.props.openJiguInfo && (
          <ProjectFormJiguInfo {...this.props} open={this.props.openJiguInfo} setOpen={this.props.setOpenJiguInfo} project={this.props.currentProject} updateJiguInfo={this.props.updateJiguInfo} />
        )}
        {this.props.openDetailConfig && (
          <div className="detail-config-wrap">
            <div className="header">
              {/* <div className={`project-type-title ${this.props.currentProject.project_type === "AI" && "AI" || "DESIGNER"}`}>
                {this.props.currentProject.project_type === "AI" && "AI" || "DESIGNER"} 프로젝트
              </div> */}
              <div className="title font font-18px bg-navy font-emphasis">
                상세 설정
                <Tooltip
                  id="ProjectFormMap-SiteMerged"
                  className="tooltip"
                  msg={
                    <div>
                      대지영역에 맞는 설정값을
                      <br />
                      자동으로 설정합니다
                    </div>
                  }
                >
                  <Switch
                    projectType={this.props.currentProject.project_type === "DESIGNER" || this.props.currentProject.project_type === "SITE_PLAN" ? "DESIGNER" : "AI"}
                    className="auto-setting"
                    checked={this.props.currentProject.auto_setting!}
                    onClick={(checked: boolean) => {
                      this.props.onUpdateProject({ auto_setting: checked }, () => {
                        checked &&
                          this.props.onUpdateAddress(() => {
                            this.props.onUpdateDefaults(undefined, undefined, "All");
                          });
                      });
                    }}
                    name="자동 설정"
                  />
                </Tooltip>
              </div>
              <div className="detail-config-type">
                <Tabs className="tabs bg-navy">
                  <Button
                    onClick={(e) => this.setConfigType("BASIC")}
                    disableRipple={true}
                    className={`
                      tab bg-navy tab-primary first-child 
                      ${this.state.configType === "BASIC" && "active"} 
                      ${Object.values(this.props.projectErros!.errs).filter((r) => r!.subDetailType !== undefined && r!.subDetailType === "BASIC").length > 0 && "error-field"}
                      `}
                  >
                    기본설정
                  </Button>
                  <Button
                    onClick={(e) => this.setConfigType("VALUE")}
                    disableRipple={true}
                    className={`tab bg-navy tab-primary ${this.state.configType === "VALUE" && "active"} 
                      ${Object.values(this.props.projectErros!.errs).filter((r) => r!.subDetailType !== undefined && r!.subDetailType === "VALUE").length > 0 && "error-field"}
                    `}
                  >
                    건축개요
                  </Button>
                  {(["DESIGNER", "SITE_PLAN"].includes(this.props.currentProject.project_type!) && (
                    <Button
                      onClick={(e) => this.setConfigType("BUILDING")}
                      disableRipple={true}
                      className={`tab bg-navy tab-primary ${this.state.configType === "BUILDING" && "active"} 
                      ${Object.values(this.props.projectErros!.errs).filter((r) => r!.subDetailType !== undefined && r!.subDetailType === "BUILDING").length > 0 && "error-field"}`}
                    >
                      동타입
                    </Button>
                  )) || (
                    <Tooltip msg="아파트만 동타입 설정이 가능합니다" className="tab" on={this.props.currentProject.building_type === "아파트" ? false : undefined}>
                      <Button
                        onClick={(e) => this.setConfigType("BUILDING")}
                        disableRipple={true}
                        disabled={this.props.currentProject.building_type !== "아파트"}
                        className={`tab bg-navy tab-primary ${this.state.configType === "BUILDING" && "active"} 
                          ${Object.values(this.props.projectErros!.errs).filter((r) => r!.subDetailType !== undefined && r!.subDetailType === "BUILDING").length > 0 && "error-field"}`}
                      >
                        동타입
                      </Button>
                    </Tooltip>
                  )}
                  <Button
                    onClick={(e) => this.setConfigType("HOUSING")}
                    disableRipple={true}
                    className={`tab bg-navy tab-primary last-child ${this.state.configType === "HOUSING" && "active"} 
                      ${Object.values(this.props.projectErros!.errs).filter((r) => r!.subDetailType !== undefined && r!.subDetailType === "HOUSING").length > 0 && "error-field"}
                    `}
                  >
                    세대타입
                  </Button>
                </Tabs>
              </div>
            </div>
            <div className="content">
              {this.state.configType === "BASIC" && (
                <ProjectFormBasic
                  {...this.props}
                  more={this.state.moreBasic}
                  setMore={this.setMore}
                  setOpen={this.props.setOpenJiguInfo}
                  openJiguInfo={this.props.openJiguInfo}
                  setOpenJiguInfo={this.props.setOpenJiguInfo}
                  onUpdateDefaults={this.props.onUpdateDefaults}
                  buildingDefault={this.props.buildingDefault}
                  initDefault={this.props.initDefault}
                />
              )}
              {this.state.configType === "VALUE" && <ProjectFormValue {...this.props} more={this.state.moreValue} setMore={this.setMore} initDefault={this.props.initDefault} />}
              {this.state.configType === "BUILDING" && (
                <ProjectFormBuilding {...this.props} open={this.props.openBuildingDrawer} setOpen={this.props.setOpenBuildingDrawer} setModal={this.props.setModal} />
              )}
              {this.state.configType === "HOUSING" && <ProjectFormHousing {...this.props} open={this.props.openHousingChart} setOpen={this.props.setOpenHousingChart} setModal={this.props.setModal} />}
            </div>
          </div>
        )}
      </div>
    );
  }

  setMore = (type: ConfigType) => {
    switch (type) {
      case "BASIC":
        this.setState({ moreBasic: !this.state.moreBasic });
        break;
      case "VALUE":
        this.setState({ moreValue: !this.state.moreValue });
        break;
    }
  };

  setConfigType = (type: "BASIC" | "VALUE" | "BUILDING" | "HOUSING") => {
    this.setState({ configType: type }, () => {
      this.props.setOpenJiguInfo(false);
    });
  };
}
