import React, { Component, Fragment } from "react";
import "./css/ProjectSummaryChangeable.scss";
import { Project } from "./model/Project";
import ProjectValue from "./ProjectValue";
import BuilditInput from "./BuilditInput";
import Tooltip from "./Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import { Button, ClickAwayListener, InputAdornment, CircularProgress } from "@material-ui/core";
import { BuildingItems, MyBuildingItems } from "./ProjectFormBuilding";
import { SelectedBuildingType, SelectedMyBuildingType } from "./ProjectFormBuildingDrawer";
import UpArrowIcon from "@material-ui/icons/KeyboardArrowUp";
import DownArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import MapView, { MapViewOptions } from "./MapView";
import SiteOutlineIcon from "@material-ui/icons/Brightness1Outlined";
import SiteIcon from "@material-ui/icons/Brightness1";
// import Chart from './Chart';
import C3Chart from "./C3Chart";
import { MyBuildingShapeType } from "./ProjectFormHousing";
import AWSModule from "./AWSModule";
import _ from "lodash";
import HousingChart from "./HousingChart";
import App from "./App";
import DiscountTag from "./DiscountTag";
import { pad, buildingTypeLabel } from "./Utils";

export interface ProjectSummaryChangeableProps {
  project: Project;
  className?: string;
  showProjectName?: boolean;
  showProjectExplanation?: boolean;
  mapViewErrorHandler?: () => void;
  updateProject: (update: Project, callback?: Function) => void;
  saveProject: (project: Project) => void;
}
export interface ProjectSummaryChangeableState {
  moreBasic: boolean;
  moreValue: boolean;
  moreMyType: boolean;
  showSite: MapViewOptions;
  myBuildingShapeType: Array<MyBuildingShapeType>;
  showSiteInfo: boolean;
  projectNameFocus: boolean;
  editProjectDescription: boolean;
  onSave: boolean;
  saveTooltipMsg: string;
}

export default class ProjectSummaryChangeable extends Component<ProjectSummaryChangeableProps, ProjectSummaryChangeableState> {
  state: ProjectSummaryChangeableState = {
    moreBasic: false,
    moreValue: false,
    moreMyType: false,
    showSite: {
      projectSite: true,
      road: true,
      vacancyOutside: true,
      vacancyInside: true,
      topography: true,
      skyline: true,
      boundarySite: true,
      buildingData: true
    },
    myBuildingShapeType: [],
    showSiteInfo: true,
    projectNameFocus: false,
    editProjectDescription: false,
    onSave: false,
    saveTooltipMsg: "",
  };

  getMyBuildingShapeType = async () => {
    const myBuildingShapeType: Array<any> = [];
    if (this.props.project.my_building_shape_type && this.props.project.my_building_shape_type.length > 0) {
      for (const t of this.props.project.my_building_shape_type) {
        const r = await App.search({
          table: App.DDBTable.MyBuildingType,
          query: {
            query_string: {
              query: `stage.keyword:${App.tempStage} AND global_id:${t.id}`,
            },
          },
        });

        const rr = r.data;
        if (rr.hits.hits && rr.hits.hits.length > 0) {
          myBuildingShapeType.push({
            proportion: t.proportion,
            ...rr.hits.hits[0]._source,
          });
        }
      }
    }

    this.setState({
      myBuildingShapeType: myBuildingShapeType,
    });
  };

  componentWillMount = async () => {
    this.getMyBuildingShapeType();
  };

  componentDidUpdate = (pp: ProjectSummaryChangeableProps) => {
    if (_.isEqual(pp, this.props) === false) {
      this.getMyBuildingShapeType();
    }
  };

  render() {
    const chartData: any = [];
    if (this.props.project) {
      if (this.state.myBuildingShapeType && this.state.myBuildingShapeType.length > 0) {
        this.state.myBuildingShapeType.map((r) => chartData.push([`동평면 (${r.name})`, r.proportion]));
      }
      if (this.props.project.housing_plan_type && this.props.project.housing_plan_type.length > 0) {
        this.props.project.housing_plan_type.map((r) => chartData.push([`${r.area}㎡`, r.proportion]));
      }
    }
    return (
      <div className={`ProjectSummary ${this.props.className}`}>
        <div className="title font font-special">입력개요</div>
        <div className="content">
          {this.props.showProjectName && (
            <div className="project-title-wrap">
              <div className="project-title-info">프로젝트 명</div>
              <div className="project-title">
                {
                  <Fragment>
                    {(this.state.projectNameFocus && (
                      <ClickAwayListener
                        onClickAway={() => {
                          if (this.state.projectNameFocus) {
                            if (this.props.project.project_name!.length === 0) {
                              this.props.updateProject({ project_name: "제목없는 프로젝트" }, async () => await this.props.saveProject(this.props.project));
                            } else {
                              this.props.updateProject({ project_name: this.props.project.project_name }, async () => await this.props.saveProject(this.props.project));
                            }
                          }
                          this.setState({ projectNameFocus: false });
                        }}
                      >
                        <BuilditInput
                          className={`project-title ${(this.state.projectNameFocus && "focus") || ""}`}
                          placeholder="프로젝트 제목"
                          value={this.props.project.project_name}
                          onChange={(e) => {
                            this.props.updateProject({ project_name: e as string });
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              if (this.props.project.project_name!.length === 0) {
                                this.props.updateProject({ project_name: "제목없는 프로젝트" }, async () => await this.props.saveProject(this.props.project));
                              } else {
                                this.props.updateProject({ project_name: this.props.project.project_name }, async () => await this.props.saveProject(this.props.project));
                              }
                              this.setState({ projectNameFocus: false });
                            }
                          }}
                          length={100}
                          type="text"
                          endAdornment={
                            <InputAdornment position="end">
                              <Button disableRipple={true} className="edit-btn" title={this.state.saveTooltipMsg}>
                                {!this.state.projectNameFocus && <EditIcon className="icon" />}
                                {this.state.projectNameFocus && this.state.onSave && <CircularProgress className="icon" />}
                              </Button>
                            </InputAdornment>
                          }
                        />
                      </ClickAwayListener>
                    )) || (
                      <Tooltip id="ProjectResult-ProjectName" msg="프로젝트명 수정">
                        <div className="project-title-edit" onClick={() => this.setState({ projectNameFocus: true })}>
                          <div className="project-title">
                            {this.props.project.project_name}
                            {/* <EditIcon className="icon m-l-lg" /> */}
                          </div>
                        </div>
                      </Tooltip>
                    )}
                  </Fragment>
                }
              </div>
            </div>
          )}
          {this.props.showProjectExplanation && (
            <div className="project-description-wrap">
              <div className="project-description-info">프로젝트 메모</div>
              {/* <textarea value={this.props.project.project_explanation} className="project-explanation" readOnly /> */}
              <Fragment>
                {
                  <Tooltip msg={"프로젝트 메모"} on={!this.state.editProjectDescription ? undefined : false}>
                    <ClickAwayListener
                      onClickAway={() => {
                        if (this.state.editProjectDescription) {
                          this.props.updateProject({ project_explanation: this.props.project.project_explanation }, async () => await this.props.saveProject(this.props.project));
                          this.setState({ editProjectDescription: false });
                        }
                      }}
                    >
                      <BuilditInput
                        onClick={() => {
                          this.setState({ editProjectDescription: true });
                        }}
                        key="project-description"
                        className={`project-description ${this.state.editProjectDescription === false && "no-edit"}`}
                        multiline={true}
                        placeholder="프로젝트에 대한 내용을 정리하세요. (500자 이내)"
                        value={this.props.project.project_explanation}
                        onChange={(e) => {
                          const strArr = (e as string).split("\n");
                            const row = strArr.length;
                            if (row > 30) {
                              return;
                            } else {
                              this.props.updateProject({ project_explanation: e as string });
                            }
                        }}
                        rows={this.props.project.project_explanation !== undefined ? this.props.project.project_explanation.split(/\r|r\n|\n/).length : 1}
                        type="text"
                        length={500}
                      />
                    </ClickAwayListener>
                  </Tooltip>
                }
              </Fragment>
            </div>
          )}
          <div className="map-wrap">
            <div className="map-content">
              <MapView project={this.props.project} height={470} viewOptions={this.state.showSite} useWheel={false} controlCadastral={true} errorHandler={this.props.mapViewErrorHandler} />
              <div className={`site-info ${this.state.showSiteInfo === false && "hidden"}`}>
                <div className="key-value">
                  <div className="key">대지영역 면적</div>
                  <div className="value">{(this.props.project.project_site_area !== undefined && Number(this.props.project.project_site_area.toFixed(2)).toLocaleString()) || 0}㎡</div>
                </div>
                <div className="key-value">
                  <div className="key">공지영역 면적</div>
                  <div className="value">{(this.props.project.vacancy_outside_area !== undefined && Number(this.props.project.vacancy_outside_area.toFixed(2)).toLocaleString()) || 0}㎡</div>
                </div>
                <div className="key-value">
                  <div className="key">배치제한영역 면적</div>
                  <div className="value">{(this.props.project.vacancy_inside_area !== undefined && Number(this.props.project.vacancy_inside_area.toFixed(2)).toLocaleString()) || 0}㎡</div>
                </div>
                <div className="closed">
                  <img src="/img/map_closed_icon.svg" onClick={() => this.setState({ showSiteInfo: !this.state.showSiteInfo })} />
                </div>
              </div>
            </div>
            <div className="btns-content">
              {this.props.project.project_site && this.props.project.project_site.length > 0 && (
                <Button
                  disableRipple={true}
                  className={`bg-navy tab tab-secondary tab-small ${(this.state.showSite.projectSite && "active") || ""}`}
                  onClick={(e) =>
                    this.setState({
                      showSite: {
                        ...this.state.showSite,
                        projectSite: !this.state.showSite.projectSite,
                      },
                    })
                  }
                >
                  {(this.state.showSite.projectSite && <SiteIcon className="inner-icon project-site" />) || <SiteOutlineIcon className="inner-icon project-site reverse" />}
                  대지영역
                </Button>
              )}
              {this.props.project.boundary_site && this.props.project.boundary_site.length > 0 && (
                <Button
                  disableRipple={true}
                  className={`bg-navy tab tab-secondary tab-small ${(this.state.showSite.boundarySite && "active") || ""}`}
                  onClick={(e) =>
                    this.setState({
                      showSite: {
                        ...this.state.showSite,
                        boundarySite: !this.state.showSite.boundarySite,
                      },
                    })
                  }
                >
                  {(this.state.showSite.boundarySite && <SiteIcon className="inner-icon boundary-site" />) || <SiteOutlineIcon className="inner-icon boundary-site" />}
                  인접대지경계선
                </Button>
              )}
              {this.props.project.road_site && this.props.project.road_site.length > 0 && (
                <Button
                  disableRipple={true}
                  className={`bg-navy tab tab-secondary tab-small ${(this.state.showSite.road && "active") || ""}`}
                  onClick={(e) =>
                    this.setState({
                      showSite: {
                        ...this.state.showSite,
                        road: !this.state.showSite.road,
                      },
                    })
                  }
                >
                  {(this.state.showSite.road && <SiteIcon className="inner-icon road" />) || <SiteOutlineIcon className="inner-icon road" />}
                  인접도로
                </Button>
              )}
              {this.props.project.vacancy_outside && this.props.project.vacancy_outside.length > 0 && (
                <Button
                  disableRipple={true}
                  className={`bg-navy tab tab-secondary tab-small ${(this.state.showSite.vacancyOutside && "active") || ""}`}
                  onClick={(e) =>
                    this.setState({
                      showSite: {
                        ...this.state.showSite,
                        vacancyOutside: !this.state.showSite.vacancyOutside,
                      },
                    })
                  }
                >
                  {(this.state.showSite.vacancyOutside && <SiteIcon className="inner-icon vacancy-outside" />) || <SiteOutlineIcon className="inner-icon vacancy-outside" />}
                  공지영역
                </Button>
              )}
              {this.props.project.vacancy_inside && this.props.project.vacancy_inside.length > 0 && (
                <Button
                  disableRipple={true}
                  className={`bg-navy tab tab-secondary tab-small ${(this.state.showSite.vacancyInside && "active") || ""}`}
                  onClick={(e) =>
                    this.setState({
                      showSite: {
                        ...this.state.showSite,
                        vacancyInside: !this.state.showSite.vacancyInside,
                      },
                    })
                  }
                >
                  {(this.state.showSite.vacancyInside && <SiteIcon className="inner-icon vacancy-inside" />) || <SiteOutlineIcon className="inner-icon vacancy-inside" />}
                  배치제한영역
                </Button>
              )}
              {this.props.project.topography_line && this.props.project.topography_line.length > 0 && (
                <Button
                  disableRipple={true}
                  className={`bg-navy tab tab-secondary tab-small ${(this.state.showSite.topography && "active") || ""}`}
                  onClick={(e) =>
                    this.setState({
                      showSite: {
                        ...this.state.showSite,
                        topography: !this.state.showSite.topography,
                      },
                    })
                  }
                >
                  {(this.state.showSite.topography && <SiteIcon className="inner-icon topography" />) || <SiteOutlineIcon className="inner-icon topography" />}
                  대지레벨
                </Button>
              )}
              {((this.props.project.skyline_circle && this.props.project.skyline_circle.length > 0) || (this.props.project.skyline_line && this.props.project.skyline_line.length > 0)) && (
                <Button
                  disableRipple={true}
                  className={`bg-navy tab tab-secondary tab-small ${(this.state.showSite.skyline && "active") || ""}`}
                  onClick={(e) =>
                    this.setState({
                      showSite: {
                        ...this.state.showSite,
                        skyline: !this.state.showSite.skyline,
                      },
                    })
                  }
                >
                  {(this.state.showSite.skyline && <SiteIcon className="inner-icon skyline" />) || <SiteOutlineIcon className="inner-icon skyline" />}
                  스카이라인
                </Button>
              )}
            </div>
          </div>
          <div className="basic config-wrap">
            <div className="title">기본 설정</div>
            <div className="content-wrap">
              <div className="content">
                <ProjectValue className="basic-box address" title="프로젝트 주소" value={this.props.project.project_address} />
                <ProjectValue className="basic-box" title="용도지역" value={this.props.project.project_use_district} />
                <ProjectValue className="basic-box" title="건물 종류" value={buildingTypeLabel(this.props.project.building_type)} />
                {/* <ProjectValue className="basic-box" title="대지영역 면적" 
                  value={(this.props.project.project_site_area && Number(this.props.project.project_site_area.toFixed(2)).toLocaleString() || 0) + "㎡"} 
                /> */}
              </div>
              <div className="content">
                {/* <ProjectValue className="basic-box textarea" title="설명" value={this.props.project.project_explanation} /> */}
                <Button disableRipple={true} className="bg-navy btn btn-primary btn-small more-btn" onClick={(e) => this.setState({ moreBasic: !this.state.moreBasic })}>
                  더보기
                  {(this.state.moreBasic && <UpArrowIcon className="icon" />) || <DownArrowIcon className="icon" />}
                </Button>
              </div>
              {this.state.moreBasic && (
                <Fragment>
                  <div className="content more">
                    <ProjectValue className="basic-box" title="발주처" value={this.props.project.ordering_companay} />
                    <ProjectValue className="basic-box" title="발주처 담당자" value={this.props.project.ordering_manager} />
                    <ProjectValue className="basic-box" title="작성자" value={this.props.project.drafter} />
                    <ProjectValue className="basic-box" title="작성자 연락처" value={this.props.project.drafter_contact_number} />
                  </div>
                  <div className="content more">
                    <ProjectValue className="basic-box" title="건축설계 단계" value={this.props.project.design_stage} />
                    <ProjectValue className="basic-box" title="관련 건축 설계사무소" value={this.props.project.design_office} />
                    <ProjectValue className="basic-box" title="시공사" value={this.props.project.builder} />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <div className="value config-wrap">
            <div className="title">건축 개요</div>
            <div className="content-wrap">
              <div className="content">
                {this.props.project.project_type === "AI" && (
                  <Fragment>
                    <ProjectValue className="value-box" title="건폐율" value={this.props.project.building_land_ratio} unit="%" />
                    <ProjectValue className="value-box" title="용적률" value={this.props.project.floor_area_ratio} unit="%" />
                    {["아파트", "연립주택"].includes(this.props.project.building_type!) && (
                      <ProjectValue className="value-box" title="최저 층수" value={this.props.project.building_stories_min} unit="층" />
                    )}
                    <ProjectValue className="value-box" title="최고 층수" value={this.props.project.building_stories_max} unit="층" />
                    {/* {["아파트", "연립주택"].includes(this.props.project.building_type!) && (
                      <ProjectValue
                        className="value-box"
                        title="평균 층수 타입"
                        value={(this.props.project.building_stories_avg_type === "AREA" && "기준 면적") || (this.props.project.building_stories_avg_type === "HOUSE" && "총 세대") || "산술 평균"}
                      />
                    )}
                    {["아파트", "연립주택"].includes(this.props.project.building_type!) && (
                      <ProjectValue className="value-box" title="평균 층수" value={this.props.project.building_stories_avg} unit="층" />
                    )} */}
                  </Fragment>
                )}
                <ProjectValue className="value-box" title="층고" value={this.props.project.floor_height} unit="m" />
              </div>
              <div className="content more">
                <Button disableRipple={true} className="bg-navy btn btn-primary btn-small more-btn" onClick={(e) => this.setState({ moreValue: !this.state.moreValue })}>
                  더보기
                  {(this.state.moreValue && <UpArrowIcon className="icon" />) || <DownArrowIcon className="icon" />}
                </Button>
              </div>
              {this.state.moreValue && (
                <Fragment>
                  <div className="content">
                    {["아파트", "다세대주택", "연립주택"].includes(this.props.project.building_type!) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">정북일조</div>
                        <div className="content">
                          <ProjectValue
                            className="value-box"
                            title="9m 이하"
                            value={this.props.project.setback_regulation_from_north_less_9m}
                            unit={`${(this.props.project.setback_regulation_from_north_less_9m_type === "METER" && "m") || "h"}`}
                          />
                          <ProjectValue
                            className="value-box"
                            title="9m 초과"
                            value={this.props.project.setback_regulation_from_north_more_9m}
                            unit={`${(this.props.project.setback_regulation_from_north_more_9m_type === "METER" && "m") || "h"}`}
                          />
                        </div>
                      </div>
                    )}
                    {["아파트", "연립주택"].includes(this.props.project.building_type!) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">채광사선</div>
                        <div className="content">
                          <ProjectValue className="value-box large" title="채광창↔인접대지경계선" value={this.props.project.setback_regulation_from_site_boundary} unit="h" />
                        </div>
                      </div>
                    )}
                    {["아파트", "오피스텔", "연립주택"].includes(this.props.project.building_type!) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">인동거리</div>
                        <div className="content">
                          <ProjectValue className="value-box" title="채광창↔다른건물" value={this.props.project.distance_between_window_opaque_walls} unit="h" />
                          {["아파트", "연립주택"].includes(this.props.project.building_type!) && (
                            <Fragment>
                              <ProjectValue className="value-box" title="벽면↔측벽" value={this.props.project.distance_between_side_opaque_walls} unit="m" />
                              <ProjectValue className="value-box" title="측벽↔측벽" value={this.props.project.distance_between_side_walls} unit="m" />
                            </Fragment>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="content">
                    {(this.props.project.building_type === "아파트" || this.props.project.building_type === "미정" || this.props.project.building_type === undefined) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">대지안의공지 (아파트)</div>
                        <div className="content">
                          <ProjectValue className="value-box" title="건축선후퇴" value={this.props.project.setback_building_line_apartment} unit="m" />
                          <ProjectValue className="value-box" title="인접대지선후퇴" value={this.props.project.setback_site_boundary_apartment} unit="m" />
                        </div>
                      </div>
                    )}
                    {(this.props.project.building_type === "연립주택" || this.props.project.building_type === "미정" || this.props.project.building_type === undefined) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">대지안의공지 (연립주택)</div>
                        <div className="content">
                          <ProjectValue className="value-box" title="건축선후퇴" value={this.props.project.setback_building_line_row_house} unit="m" />
                          <ProjectValue className="value-box" title="인접대지선후퇴" value={this.props.project.setback_site_boundary_row_house} unit="m" />
                        </div>
                      </div>
                    )}
                    {(this.props.project.building_type === "다세대주택" || this.props.project.building_type === "미정" || this.props.project.building_type === undefined) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">대지안의공지 (다세대주택)</div>
                        <div className="content">
                          <ProjectValue className="value-box" title="건축선후퇴" value={this.props.project.setback_building_line_multi_house} unit="m" />
                          <ProjectValue className="value-box" title="인접대지선후퇴" value={this.props.project.setback_site_boundary_multi_house} unit="m" />
                        </div>
                      </div>
                    )}
                    {(this.props.project.building_type === "오피스텔" || this.props.project.building_type === "미정" || this.props.project.building_type === undefined) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">대지안의공지 (오피스텔)</div>
                        <div className="content">
                          <ProjectValue className="value-box" title="건축선후퇴" value={this.props.project.setback_building_line_officetel} unit="m" />
                          <ProjectValue className="value-box" title="인접대지선후퇴" value={this.props.project.setback_site_boundary_officetel} unit="m" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="content">
                    {(this.props.project.building_type === "도시형생활주택 - 원룸" || this.props.project.building_type === "미정" || this.props.project.building_type === undefined) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">대지안의공지 (도시형생활주택 - 원룸)</div>
                        <div className="content">
                          <ProjectValue className="value-box" title="건축선후퇴" value={this.props.project.setback_building_line_city_apartment} unit="m" />
                          <ProjectValue className="value-box" title="인접대지선후퇴" value={this.props.project.setback_site_boundary_city_apartment} unit="m" />
                        </div>
                      </div>
                    )}
                    {(this.props.project.building_type === "도시형생활주택 - 연립주택" || this.props.project.building_type === "미정" || this.props.project.building_type === undefined) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">대지안의공지 (도시형생활주택 - 연립주택)</div>
                        <div className="content">
                          <ProjectValue className="value-box" title="건축선후퇴" value={this.props.project.setback_building_line_city_row_house} unit="m" />
                          <ProjectValue className="value-box" title="인접대지선후퇴" value={this.props.project.setback_site_boundary_city_row_house} unit="m" />
                        </div>
                      </div>
                    )}
                    {(this.props.project.building_type === "도시형생활주택 - 다세대주택" || this.props.project.building_type === "미정" || this.props.project.building_type === undefined) && (
                      <div className="project-value-wrap">
                        <div className="title bg-navy font font-emphasis">대지안의공지 (도시형생활주택 - 다세대주택)</div>
                        <div className="content">
                          <ProjectValue className="value-box" title="건축선후퇴" value={this.props.project.setback_building_line_city_multi_house} unit="m" />
                          <ProjectValue className="value-box" title="인접대지선후퇴" value={this.props.project.setback_site_boundary_city_multi_house} unit="m" />
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          {this.props.project.building_type === "아파트" && (
            <div className="building-type config-wrap">
              <div className="title">동 타입</div>
              <div className="content-wrap">
                <div className="content type-content">
                  <div className="type buildit-type">
                    <div className="title">
                      BUILDIT 동타입
                      <span className="font font-special m-l-sm">{this.props.project.building_shape_type ? this.props.project.building_shape_type.length : 0}개</span>
                    </div>
                    <div className="list">
                      {this.props.project.building_shape_type &&
                        this.props.project.building_shape_type.map((b, i) => (
                          <SelectedBuildingType item={b} className={`building-type-box ${((i + 1) % 4 === 0 && "no-margin-right") || ""} ${i > 3 && "top-margin"}`} direction="vertical" />
                        ))}
                    </div>
                  </div>
                  <div className="type my-type">
                    <div className="title">
                      나의 동평면
                      <span className="font font-special m-l-sm">{this.props.project.my_building_shape_type ? this.props.project.my_building_shape_type.length : 0}개</span>
                      {this.props.project.my_building_shape_type && this.props.project.my_building_shape_type.length > 4 && (
                        <Button
                          className="more-my-type-btn"
                          disableRipple={true}
                          onClick={() => {
                            this.setState({ moreMyType: !this.state.moreMyType });
                          }}
                        >
                          전체보기
                          {(this.state.moreMyType && <DownArrowIcon className="icon m-l-sm" />) || <UpArrowIcon className="icon m-l-sm" />}
                        </Button>
                      )}
                    </div>
                    <div className="list">
                      {this.props.project.my_building_shape_type &&
                        this.props.project.my_building_shape_type!.map((b, i) => {
                          return (
                            <SelectedMyBuildingType
                              item={b}
                              className={`building-type-box ${((i + 1) % 4 === 0 && "no-margin-right") || ""} ${(i > 3 && "top-margin") || ""} ${
                                (!this.state.moreMyType && i > 3 && "display-none") || ""
                              }`}
                              direction="vertical"
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="housing-type config-wrap">
            <div className="title">세대 타입</div>
            <div className="content-wrap">
              <div className="content">
                {(this.props.project.project_type! === "AI" && this.props.project.building_type! === "아파트" && (
                  <div className="housing-chart-wrap">
                    <HousingChart width={250} height={250} legend="right" project={this.props.project} id="project-summary-c3-chart" />
                  </div>
                )) ||
                  (this.props.project.housing_plan_type &&
                    this.props.project.housing_plan_type.map((r, i) => {
                      return <ProjectValue className="value-box housing" title={`세대타입 ${pad((i + 1).toString(), 2)}`} value={r.area!} unit="㎡" />;
                    }))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
