import React, { Component, Fragment } from "react";
import SessionComponent from "./SessionComponent";
import "./css/MyInformationPage.scss";
import { Button, InputAdornment, Checkbox } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import BuilditInput, { BuilditInputProps, InputAttr } from "./BuilditInput";
import App from "./App";
// import { FaceNormalsHelper } from '@teneleven/three';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { RegisterInput } from "./RegistrationUser";
import { default as _ } from "lodash";
import AWS, { CognitoIdentityServiceProvider } from "aws-sdk";
import { User } from "./model/User";
import AWSModule from "./AWSModule";
import Modal, { ModalOptions } from "./Modal";
import Tooltip from "./Tooltip";

interface infoState {
  checked: boolean;
  password: string;
  confirmPassword: string;
  name: string;
  company: string;
  contact: string;
  passwordInput: InputAttr;
  confirmInput: InputAttr;
  passwordRequiredList: Array<{ key: string; checked: boolean; regex: RegExp }>;
  prevPassword: string;
  accessToken: string;
  modalOptions?: ModalOptions;
  openModal: boolean;
  openPassword: boolean;
  checkedPrivacy: boolean;
}

interface infoProps {
  userEmail: string;
}

type TOSModalType = "Privacy";

export default class MyInformationPage extends SessionComponent<infoProps, infoState> {
  state: infoState = {
    checked: false,
    password: "",
    confirmPassword: "",
    name: App.session.name,
    company: App.session.company,
    contact: App.session.contact_number,
    passwordInput: {
      requiredCheck: false,
      error: false,
    },
    confirmInput: {
      requiredCheck: false,
      error: false,
    },
    passwordRequiredList: [
      { key: "대문자", checked: false, regex: /[A-Z]/ },
      { key: "소문자", checked: false, regex: /[a-z]/ },
      { key: "숫자", checked: false, regex: /[0-9]/ },
      { key: "특수문자", checked: false, regex: /[!@#$%^\&\*\(\)\-\+]/ },
      { key: "8글자이상", checked: false, regex: /.{8}/ },
    ],
    prevPassword: "",
    accessToken: "",
    openModal: false,
    openPassword: true,
    checkedPrivacy: false,
  };

  componentWillMount = () => {};

  componentDidUpdate = (pp: Readonly<infoProps>, ps: Readonly<infoState>) => {
    if (this.state.checked === false) {
      return;
    }

    if (!_.isEqual(this.state.password, ps.password)) {
      this.setState({
        passwordRequiredList: this.state.passwordRequiredList.map((v) => {
          v.checked = Boolean(this.state.password.match(v.regex));
          return v;
        }),
      });
    }
  };

  checkPasswordValidation = () => {
    if (this.state.password.length === 0) {
      return false;
    }

    if (this.state.passwordRequiredList.findIndex((e) => e.checked === false) > -1) {
      this.setState({
        passwordInput: {
          error: true,
          requiredCheck: false,
        },
      });
      return false;
    } else {
      if (this.state.confirmPassword.length > 0) {
        this.checkConfirmValidation();
      }
      return true;
    }
  };

  checkConfirmValidation = () => {
    if (this.state.confirmPassword.length === 0) {
      return false;
    }

    if (this.state.passwordRequiredList.findIndex((e) => e.checked === false) > -1) {
      return false;
    }

    let error = true;
    if (this.state.passwordRequiredList.findIndex((e) => e.checked === false) < 0 && this.state.password === this.state.confirmPassword) {
      error = false;
    }
    this.setState({
      confirmInput: {
        error: error,
        requiredCheck: !error,
        msg: (error && "입력한 비밀번호가 일치하지 않습니다") || undefined,
      },
    });
    return !error;
  };

  checkPassword = () => {
    if (this.state.password.length === 0) {
      this.setModal(true, "비밀번호 확인", "비밀번호를 입력해 주십시오.");
    } else {
      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: App.USER_POOL_ID,
        ClientId: App.COGNITO_CLIENT_ID,
      });

      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: this.props.userEmail,
        Password: this.state.password,
      });

      const userData = {
        Username: this.props.userEmail,
        Pool: userPool,
      };

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: this.successConfirm,
        onFailure: this.failConfirm,
      });
    }
  };

  failConfirm = async (err: any) => {
    switch (err.code) {
      case "NotAuthorizedException":
        this.setModal(true, "비밀번호 확인", "등록하신 비밀번호와 일치하지 않습니다.");
        break;
      default:
        this.setModal(true, "비밀번호 확인", "알 수 없는 오류로 실패했습니다.");
        break;
    }
  };

  successConfirm = async (result: AmazonCognitoIdentity.CognitoUserSession) => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: App.IDENTITY_POOL_ID,
      Logins: {
        [`cognito-idp.ap-northeast-2.amazonaws.com/${App.USER_POOL_ID}`]: result.getIdToken().getJwtToken()!,
      },
    });
    this.setState({ accessToken: result.getAccessToken().getJwtToken() });
    this.resetState(true);
  };

  reviseInformation = async () => {
    if (this.checkPasswordValidation() && this.checkConfirmValidation()) {
      const params = {
        AccessToken: this.state.accessToken,
        PreviousPassword: this.state.prevPassword,
        ProposedPassword: this.state.password,
      };
      const cognitoService = new CognitoIdentityServiceProvider();
      cognitoService.changePassword(params, async (err, data) => {
        if (err) {
          this.setState({
            password: "",
            confirmPassword: "",
            passwordInput: { requiredCheck: false, error: false },
            confirmInput: { requiredCheck: false, error: false },
          });
          if (err.code === "LimitExceededException") {
            this.setModal(true, "회원 정보 수정", "잠시 후 다시 시도해주시기 바랍니다..");
          } else {
            this.setModal(true, "회원 정보 수정", "비밀번호 변경에 실패했습니다.");
          }
        } else {
          // let preCompany: string = "";
          // let preContact: string = "";
          // let preName: string = "";

          // if (!(this.state.company.length > 0 && this.state.name.length > 0 && this.state.contact.length > 0)) {

          //   const aws = await new AWSModule("DDB").connect();
          //   const r = await aws.Ddb!.get({
          //     TableName: 'platform-buildit-user',
          //     Key: {
          //       "id": aws.id!,
          //     }
          //   }).promise();

          //   if (r.Item) {
          //     preCompany = r.Item.company;
          //     preContact = r.Item.contact_number;
          //     preName = r.Item.name;
          //   }
          // }

          // const user: User = {
          //   id: App.session.id,
          //   email: this.props.userEmail,
          //   company: this.state.company.length > 0 ? this.state.company : preCompany,
          //   contact_number: this.state.contact.length > 0 ? this.state.contact : preContact,
          //   name: this.state.name.length > 0 ? this.state.name : preName,
          //   point: App.session.point,
          //   service_point: App.session.service_point,
          // };
          // const ddb = new AWS.DynamoDB.DocumentClient();
          // const dr = await ddb.put({
          //   TableName: "platform-buildit-user",
          //   Item: {...user, stage: App.stage},
          // }).promise();
          this.resetState(true);
          await App.updateSession();
          this.setModal(true, "회원 정보 수정", "비밀번호가 변경 되었습니다.");
        }
      });
    } else {
      this.setModal(true, "회원 정보 수정", "대문자/소문자/숫자/특수문자/글자수를 확인해주세요");
    }
  };

  revisePrivacyInfo = async () => {
    if (this.state.checkedPrivacy || App.session.optional_consent) {
      let preCompany: string = "";
      let preContact: string = "";
      let preName: string = "";
      const aws = await new AWSModule("DDB").connect();

      //if (!(this.state.company.length > 0 && this.state.name.length > 0 && this.state.contact.length > 0)) {
      const r = await aws
        .Ddb!.get({
          TableName: "platform-buildit-user",
          Key: {
            id: aws.id!,
          },
        })
        .promise();

      if (r.Item) {
        preCompany = r.Item.company;
        preContact = r.Item.contact_number;
        preName = r.Item.name;
      }
      //}

      const user: User = {
        id: App.session.id,
        email: this.props.userEmail,
        company: this.state.company.length > 0 ? this.state.company : this.state.company,
        contact_number: this.state.contact.length > 0 ? this.state.contact : this.state.contact,
        name: this.state.name.length > 0 ? this.state.name : this.state.name,
        point: App.session.point,
        service_point: App.session.service_point,
        optional_consent: true,
      };

      const lambda = await new AWSModule("LAMBDA").connect();
      const dr = await lambda
        .Lambda!.invoke({
          FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
          Payload: JSON.stringify([
            {
              TableName: "platform-buildit-user",
              Item: {
                stage: App.tempStage,
                ...r.Item,
                ...user,
              },
            },
          ]),
        })
        .promise();

      this.setModal(true, "회원 정보 수정", "회원 정보가 변경 되었습니다.");
      await App.updateSession();
      this.resetState(true);
    } else {
      this.setModal(true, "개인정보 수집 및 이용(선택)", "약관 동의가 필요합니다.");
    }
  };

  resetState = (isChecked: boolean, prevPassword?: string) => {
    this.setState({
      checked: isChecked,
      password: "",
      confirmPassword: "",
      name: App.session.name,
      company: App.session.company,
      contact: App.session.contact_number,
      passwordInput: { requiredCheck: false, error: false },
      confirmInput: { requiredCheck: false, error: false },
      prevPassword: isChecked ? this.state.password : "",
      openPassword: true,
    });
  };

  setModal = (open: boolean, title?: string, content?: string) => {
    this.setState({
      openModal: open,
      modalOptions: {
        color: "DARK",
        type: "SIMPLE",
        positive: () => {
          this.setModal(false);
        },
        negative: "hidden",
        content: <div style={{ display: "flex", alignItems: "center" }}>{content}</div>,
        title: title,
      },
    });
  };

  setTOSModal = (open: boolean, type: TOSModalType) => {
    let title: string = "";

    switch (type) {
      case "Privacy":
        title = "개인정보 수집 및 이용 (선택)";
        break;
      default:
        title = "약관 동의";
    }

    this.setState({
      openModal: open,
      modalOptions: {
        title: title,
        color: "DARK",
        negative: () => {
          this.setState({ openModal: false });
        },
        positive: () => {
          this.setState({
            openModal: false,
            checkedPrivacy: true,
          });
        },
        content: this.getTOSContent(type),
      },
    });
  };

  getTOSContent = (type: TOSModalType) => {
    let content = <div></div>;
    switch (type) {
      case "Privacy":
        content = (
          <div>
            개인정보처리방침 1. 총칙 주식회사 텐일레븐(이하 “회사”라 한다)은 이용자의 개인정보보호를 소중하게 생각하고, 이용자의 개인정보를 보호하기 위하여 정보통신망 이용촉진 및 정보보호 등에 관한
            법률을 비롯한 모든 개인정보보호 관련 법률규정을 준수하고 있으며, 「개인정보처리방침」을 제정하여 이를 준수하고 있습니다. 이를 인터넷사이트에 공개하여 이용자가 언제나 용이하게 열람할 수
            있도록 하여 소비자 권익보호를 위해 최선을 다하고 있습니다. 2. 개인정보의 수집방법 및 항목 "회사"는 회원가입과 상담, 각종 서비스의 제공 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
            1. 회원가입 시점에서 수집하는 항목 o 필수 : E-mail 주소, 비밀번호 o 선택 : 성명, 소속회사, 연락처 2. 서비스 이용과정에서 수집될 수 있는 개인정보의 범위 o 서비스 이용과정에서 이용자가
            입력한 정보 o 모든 기능을 이용하는 과정에서 휴대폰 번호 또는 전화번호 3. 개인정보의 수집방법 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있으며,
            아래와 같은 방법을 통해 개인정보를 수집합니다. o 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 웹페이지에서 정보를 입력하는 경우 o 고객센터를 통한 전화와
            온라인 상담, o 이벤트 응모 등 참여 3. 개인정보의 수집 및 이용목적 1. 서비스의 원활한 제공 및 운영 2. 회원관리 회원제 서비스 이용, 개인식별, 불량회원의 부정 이용방지와 비인가 사용방지,
            가입의사 확인, 가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달 3. 재화 또는 서비스 제공 서비스 제공, 맞춤 서비스 제공, 요금결제, 정산 등 4. 마케팅 및
            광고에의 활용 고객별 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 정기 간행물 발송, 서비스의 유효성 확인, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 접속빈도 파악,
            회원의 서비스 이용에 대한 통계 4. 개인정보 수집, 이용, 제공에 대한 동의 "회사"는 "이용자"가 최초 회원가입 시 또는 서비스 이용과 관련해 개인정보를 입력할 시 개인정보처리방침 및 이용약관
            등의 내용에 대해 동의 여부를 체크할 수 있는 절차를 마련하여, 이를 체크하면 개인정보수집에 대해 동의한 것으로 취급합니다. 5. 개인정보의 목적 외 이용, 제3자 제공 1. "회사"는 "이용자"의
            개인정보를 "3. 개인정보의 수집 및 이용목적"에서 고지한 범위 내에서 사용하며 동의 범위를 초과하여 이용하거나 원칙적으로 제 3자에게 제공하지 않습니다. 2. 다만 제휴관계나 위탁처리를 위한 경우
            합당한 절차를 통한 회원의 동의를 얻어 개인정보를 제공 또는 이용할 수 있습니다. 6. 개인정보의 보유기간 및 파기 1. "회사"가 "회원"의 개인정보를 수집하는 경우 그 보유기간은 원칙적으로
            회원가입 후 이용계약의 종료 시까지며, 이용계약 종료의 경우 회사는 회원의 개인정보를 즉시 파기합니다. (단, 재가입유예기간 동안의 재가입 방지 등을 위해 이용계약이 종료한 날로부터 2개월 경과
            후에 파기하도록 합니다.) 또한, 다음의 경우 각각 명시한 기간 동안 개인정보를 보유합니다. (1) 상법 등 법령의 규정에 의하여 보존할 필요성이 있는 경우 법령에서 규정한 보존기간 동안 거래내역과
            최소한의 기본정보를 보유 (2) 보유기간을 미리 공지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 회원의 동의를 받을 경우에는 약정한 기간 동안 보유 (3) 다음의 경우 명시한 기간 동안
            개인정보를 보유 - 계약 또는 청약철회 등에 관한 기록: 5년 - 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 - 부정이용 등에 관한 기록: 5년 2. "회사"는 고객의 개인정보를 보호하여 개인정보
            유출로 인한 피해가 발생하지 않도록 하기 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다. o 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. o 전자적 파일
            형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 3. "회원"의 동의를 받아 보유하고 있는 개인정보에 대한 당해 회원의 열람요구가 있을 경우 회사는 지체 없이
            이를 열람, 확인할 수 있도록 조치합니다. 4. "회원"이 1년간 서비스 이용기록이 없는 경우 회원에게 사전 통지하고 별도로 분리하여 저장합니다. 5. 개인정보의 국외 보관에 대한 내용 "회사"는
            이용자로부터 취득 또는 생성한 개인정보를 Amazon Web Service 가 보유하고 있는 데이터베이스(물리적보관소: 한국, 일본)에 저장합니다. Amazon Web Service는 해당 서버의 물리적인 관리만을 행하고,
            원칙적으로 회원님의 개인정보에 접근하지 않습니다. 7. 회원 및 법정대리인의 권리와 그 행사방법 1. "회원" 및 “법정대리인”은 언제든지 등록되어 있는 자신 또는 대리자의 개인정보를 조회하거나
            수정할 수 있으며 가입 해지를 요청할 수도 있습니다. 2. "회원" 혹은 “법정대리인”의 개인정보 조회, 수정, 삭제를 위해서는 고객센터를 통해 본인확인 절차를 거친 후 직접 열람, 정정 또는 탈퇴
            요청이 가능합니다. 3. “회원” 혹은 “법정대리인”이 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하여 개인정보의 조회, 수정 및 가입 해지 요청이 가능합니다. 4. “회원” 혹은
            “법정대리인”이 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제 3자에게 이미 제공한 경우에는
            정정 처리결과를 제3자 에게 통지합니다. 5. "회사"는 “회원” 혹은 “법정대리인”의 요청에 의해 해지 또는 삭제된 개인정보를 "6. 개인정보의 보유기간 및 파기"에 명시된 바에 따라 처리하고 그 외의
            용도로 열람 또는 이용할 수 없도록 처리하고 있습니다. 8. 개인정보 자동 수집 장치의 설치와 운영 및 거부에 관한 사항 1. 쿠키란? o “회사”는 개인화되고 맞춤화된 서비스를 제공하기 위해서
            이용자의 정보를 저장하고 수시로 불러오는 '쿠키 (cookie)'를 사용합니다. o 쿠키는 웹 서비스 제공자의 서버가 “회원”의 브라우저에게 보내는 소량의 정보이며, “회원” 컴퓨터의 하드디스크에
            저장됩니다. 여기에는 방문한 웹사이트의 정보 등이 담겨있습니다. 이후 “회원”가 웹사이트에 방문할 경우 웹사이트 서버는 “회원”의 하드 디스크에 저장되어 있는 쿠키의 내용을 읽어 “회원”의
            환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용됩니다. o 쿠키는 “회원” 컴퓨터의 웹브라우저는 식별하지만 “회원” 개인을 식별하는 정보를 자동적/능동적으로 수집하지 않으며, “회원”는
            웹브라우저의 옵션 메뉴를 통해 쿠키의 수용 여부 등을 설정할 수 있습니다. 2. "회사"의 쿠키 사용 목적 o “회원”들이 방문한 “회사”의 웹사이트의 각 서비스의 접속여부, 필지 확인 여부, 문의하기
            등에 사용합니다. o "회원"은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 “회원"은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 동의 절차를 거치거나,
            아니면 모든 쿠키의 저장을 거부할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 “회사”의 일부 서비스는 이용에 어려움이 있을 수 있습니다. 9. 개인정보의 안정성 확보 조치
            "회사"는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. 1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등 2. 기술적 조치 : 개인정보처리시스템 등의 접근권한
            관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제 10. 개인정보보호책임자 및 담당자 1. 개인정보보호책임자는 고객의
            개인정보를 보호하고 유출을 방지하는 책임자로서 이용자가 안심하고 회사가 제공하는 서비스를 이용할 수 있도록 도와드리며, 개인정보를 보호하는데 있어 이용자에게 고지한 사항들에 반하여 사고가
            발생할 시에는 이에 관한 책임을 집니다. 2. 기술적인 보완조치를 취하였음에도 불구하고 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 멸실에
            관해서는 책임이 없습니다. 3. "회사"는 개인정보보호책임자를 다음과 같이 지정합니다. [ 개인정보 보호책임자 ] 이름 : 이호영 소속 : 사업부 직위 : CEO E-mail : 1011@1011.co.kr 전화번호 :
            02-375-1011 [ 개인정보 관리담당자 ] 이름 : 김동철 소속 : 연구소 직위 : CTO E-mail : 1011@1011.co.kr 전화번호 : 02-375-1011 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
            기관에 문의하시기 바랍니다. • 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118) • 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301) • 경찰청 사이버안전국 (www.ctrc.go.kr /
            국번없이 182) 11. 고지의 의무 현 개인정보처리방침은 2020년 5월 18일에 제정되었으며, 정부 및 회사의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 경우에는 개정 최소 7일
            전부터 서비스의 '공지사항'란을 통해 고지하며, 본 정책은 시행일자에 시행됩니다. • 시행일자 : 2020년 6월 11일
          </div>
        );
        break;
      default:
        content = <div></div>;
        break;
    }
    return content;
  };

  render = () => {
    let contentDiv = (
      <Fragment>
        <div className="title font bg-navy font-primary font-18px font-noto font-pretendard">비밀번호 확인</div>
        <div>
          <div className="tips font bg-navy font-primary font-18px font-noto font-pretendard">개인정보를 안전하게 보호하기 위하여 비밀번호를 한번 더 입력해주세요.</div>

          <div className="pwDiv">
            <RegisterInput
              type="password"
              className="inputValue"
              placeholder="비밀번호"
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e as string });
              }}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  this.checkPassword();
                }
                return;
              }}
              capsLockTooltip={true}
            />
          </div>
          <div className="buttonGroup">
            <Button className="btn bg-navy btn-primary enter" onClick={this.checkPassword} disableRipple={true}>
              확인
            </Button>
          </div>
        </div>
      </Fragment>
    );

    if (this.state.checked) {
      contentDiv = (
        <Fragment>
          <div className="title font bg-navy font-primary font-18px font-noto font-pretendard">회원 정보 수정</div>
          <div>
            <div>
              <div className="itemName font bg-navy font-secondary font-12px font-roboto font-pretendard" style={{ marginTop: "28px" }}>
                이메일 주소
              </div>
              <div className="itemValue font bg-navy font-primary font-14px font-roboto font-pretendard">{this.props.userEmail}</div>
            </div>
            <div className="wrap-password">
              {(this.state.openPassword && (
                <Fragment>
                  <div className="itemName font bg-navy font-secondary font-12px font-roboto font-pretendard" style={{ marginTop: "25px" }}>
                    비밀번호 변경
                  </div>
                  <div className="pwDiv" style={{ marginTop: "8px" }}>
                    <RegisterInput
                      id="password-input"
                      type="password"
                      className="register-input inputValue"
                      placeholder="새 비밀번호"
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({
                          password: e as string,
                          passwordInput: {
                            error: false,
                          },
                        })
                      }
                      onBlur={this.checkPasswordValidation}
                      error={this.state.passwordInput.error}
                      requiredCheck={this.state.passwordRequiredList && this.state.passwordRequiredList.length > 0 && this.state.passwordRequiredList.findIndex((e) => e.checked === false) < 0}
                      requiredList={this.state.passwordRequiredList}
                      required={true}
                      capsLockTooltip={true}
                    />
                  </div>
                  <div className="pwDiv" style={{ marginTop: "10px" }}>
                    <RegisterInput
                      id="confirm-input"
                      type="password"
                      className="register-input inputValue"
                      placeholder="새 비밀번호 확인"
                      value={this.state.confirmPassword}
                      onChange={(e) =>
                        this.setState({
                          confirmPassword: e as string,
                          confirmInput: {
                            error: false,
                            msg: undefined,
                            requiredCheck: false,
                          },
                        })
                      }
                      onBlur={this.checkConfirmValidation}
                      error={this.state.confirmInput.error}
                      errMsg={this.state.confirmInput.msg}
                      requiredCheck={
                        this.state.passwordRequiredList &&
                        this.state.passwordRequiredList.length > 0 &&
                        this.state.passwordRequiredList.findIndex((e) => e.checked === false) < 0 &&
                        this.state.password === this.state.confirmPassword
                      }
                      required={true}
                      capsLockTooltip={true}
                    ></RegisterInput>
                  </div>
                  <div className="buttonGroup" style={{ marginTop: "10px" }}>
                    {/* <Button className='btn bg-navy btn-cancel cancel' onClick={() => {
                    this.setState({openPassword: false, 
                      password: "", 
                      confirmPassword: "",
                      passwordInput: {
                        error: false,
                      }});
                    } } disableRipple={true}>변경 취소</Button> */}
                    <Button
                      className="btn bg-navy btn-cancel cancel"
                      onClick={() => {
                        history.back();
                      }}
                      disableRipple={true}
                    >
                      취소
                    </Button>
                    <Button className="btn bg-navy btn-primary revise" onClick={this.reviseInformation} disableRipple={true}>
                      변경
                    </Button>
                  </div>
                  <div className="withdraw-tooltip">
                    <Tooltip msg={"회원 탈퇴를 원하실 경우, 고객센터 02-375-1011로 연락 주시기 바랍니다."} className="tooltip" arrowOn={false}>
                      <div>회원탈퇴</div>
                      <div className="icon">
                        <Help className="help-icon" />
                      </div>
                    </Tooltip>
                  </div>
                </Fragment>
              )) || (
                <Fragment>
                  <div className="itemName font bg-navy font-secondary font-12px font-roboto font-pretendard" style={{ marginTop: "25px" }}>
                    비밀번호
                  </div>
                  <Button
                    className="large-button btn bg-navy btn-primary"
                    style={{ marginTop: "8px" }}
                    disableRipple={true}
                    onClick={(e) => {
                      this.setState({ openPassword: true });
                    }}
                  >
                    비밀번호 변경
                  </Button>
                </Fragment>
              )}
            </div>
            {/* <div className="wrap-privacy">
            <div>
              <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "18px"}}>이름 또는 기업명</div>
              <div className='pwDiv' style={{marginTop: "8px"}}><BuilditInput type='text' className='inputValue' placeholder='이름 또는 기업명' value={this.state.name} onChange={e => {this.setState({name: e as string})}}></BuilditInput></div>
            </div>
            <div>
              <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "18px"}}>소속 회사</div>
              <div className='pwDiv' style={{marginTop: "8px"}}><BuilditInput type='text' className='inputValue' placeholder='소속 회사' value={this.state.company} onChange={e => {this.setState({company: e as string})}}></BuilditInput></div>
            </div>
            <div>
              <div className='itemName font bg-navy font-secondary font-12px font-roboto' style={{marginTop: "18px"}}>연락처</div>
              <div className='pwDiv' style={{marginTop: "8px"}}><BuilditInput type='text' className='inputValue' placeholder='연락처' value={this.state.contact} onChange={e => {this.setState({contact: e as string})}}></BuilditInput></div>
            </div>
            {
              !App.session.optional_consent &&
              <div className="wrap-tos">
                <div className="header">
                  <div className="content">
                    약관 동의
                  </div>
                </div>
                <div className="row">
                  <div className="title">개인정보 수집 및 이용 (선택)</div>
                  <div className="content">
                    <div className="detail-link" onClick={e => {
                      this.setTOSModal(true, "Privacy");
                    }}>
                      내용보기
                    </div>
                    <Checkbox
                      className={`m-l-md checkbox ${this.state.checkedPrivacy && "checked" || ""}`} checked={this.state.checkedPrivacy} 
                      onChange={e => {
                        this.setState({
                          checkedPrivacy: !this.state.checkedPrivacy,
                        })
                      }} 
                    />
                  </div>
                </div>
              </div>
            }
            <div className='buttonGroup' style={{marginTop: "30px", marginBottom: "202px"}}>
              <Button className='large-button btn bg-navy btn-primary' 
              onClick={this.revisePrivacyInfo} 
              disableRipple={true}>
                수정
              </Button>
            </div>
          </div> */}
          </div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className="userInformation">{contentDiv}</div>
        {this.state.openModal && (
          <Modal
            open={this.state.openModal}
            type={(this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type) || "SIMPLE"}
            positive={this.state.modalOptions && this.state.modalOptions.positive}
            negative={this.state.modalOptions && this.state.modalOptions.negative}
            title={this.state.modalOptions && this.state.modalOptions.title}
            negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
            positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions && this.state.modalOptions.content}
            color={(this.state.modalOptions && this.state.modalOptions.color) || "DARK"}
          />
        )}
      </Fragment>
    );
  };
}
