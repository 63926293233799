import React, { Component } from 'react';
import { ConverterField } from './BuildingPart';
import { ConverterInputBox } from './ConverterInputBox';
import { Field, FieldType } from './Field';

interface FieldProps {
  field: ConverterField,
  updateArea: () => void,
  showSnackBar?: (msg: "AREA" | "HEIGHT") => void,
}

interface FieldState {
  // id: string,
  // name: string,
  value: number,
}

class FieldPartUI extends Component<FieldProps, FieldState> {
  state: FieldState = {
    value: this.props.field.typeName === FieldType.topography ? this.props.field.getHeight() : this.props.field.getArea(),
  }

  componentDidUpdate = (previousProps: Readonly<FieldProps>, previousState: Readonly<FieldState>) => {
    if (previousProps.field.name !== this.props.field.name) {
      if (this.props.field.typeName === FieldType.topography) {
        this.setState({ value: this.props.field.getHeight() })
      }
      else {
        this.setState({ value: this.props.field.getArea() })
      }

    }
  }
  getInputBox = () => {
    
    switch (this.props.field.typeName) {
      case FieldType.site:
        return <ConverterInputBox
          name='면적(㎡)'
          value={this.state.value}
          valueOnChange={this.valueChanged}
          defaultValue={this.props.field.defaultInput}
          valueUpdate={this.props.updateArea}
          step={0.0001}
          hasRefresh={true}
          />
      case FieldType.topography:
        return <ConverterInputBox
          name='대지레벨(m)'
          canHaveZero={true}
          value={this.state.value}
          valueOnChange={this.valueChanged}
          defaultValue={this.props.field.defaultInput}
          valueUpdate={this.props.updateArea}
          step={0.01} 
          hasRefresh={true}
          />
      default:
        return <div></div>
    }
  }


  valueChanged = (value: number) => {
    if (this.props.field.typeName === FieldType.site) {
      
      this.props.field.setArea(value);
      this.props.showSnackBar && this.props.showSnackBar("AREA");

    }
    else if (this.props.field.typeName === FieldType.topography) {
      this.props.field.setHeight(value);
      this.props.showSnackBar && this.props.showSnackBar("HEIGHT");

    }


    this.props.updateArea();
    this.setState({ value: value })
  }


  render() {
    return (

      <div className="FieldPartUI">
        {
          this.getInputBox()
        }
      </div>

      // <div className='field' key={this.state.id}>
      //   <div className='nameDiv'>{this.state.name}<CloseIcon className='closeIcon' onClick={e => this.props.removeField(this.props.field)} /></div>
      //   <div className='buttonDiv'>
      //     <div className={`selectButton ${this.props.settingID === this.props.field.id && 'selected' || 'unselected'}`} onClick={e => this.props.showLayerList(this.props.field.id)} >
      //       <span className='text'>{this.props.field.typeName} 선택</span>
      //       {/* <CheckIcon className={`CheckIcon ${this.props.field.getFieldLayerName() && 'checked'}`} /> */}
      //     </div>
      //     <div className='nameText'>
      //       {this.props.field.getFieldLayerName()}
      //       {/* <CloseIcon className={`closeIcon ${(this.checkSelectState() && this.props.field.getLayer()) && 'selected' || 'unselected'} ${this.props.field.getLayer() && 'show' || 'hide'}`} onClick={this.deleteWall} /> */}
      //     </div>
      //   </div>
      //   <div className={`inputValues ${this.getInputType() === InputType.none && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
      //     {
      //       this.getInputType() === InputType.area && <ConverterInputBox name='면적' value={this.state.value} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />
      //     }
      //     {
      //       this.getInputType() === InputType.height && <ConverterInputBox name='해발 고도' value={this.state.value} valueOnChange={this.valueChanged} unit='m' step={0.01} />
      //     }
      //   </div>
      //   <div className='Line' />
      // </div>
    );
  }
}

export default FieldPartUI;