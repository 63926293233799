import React, { Component, Fragment } from "react";
import { ProjectFormPropsBase } from "./ProjectInput";
import "./css/ProjectFieldInput.scss";
import BuilditInput from "./BuilditInput";
import { IconButton } from "@material-ui/core";
import { Project } from "./model/Project";
import { default as _ } from "lodash";

import IncIcon from "@material-ui/icons/Add";
import DecIcon from "@material-ui/icons/Remove";
import { ProjectConstraints, projectInputValidCheckSome } from "./Constraints";
import BuilditSelect, { List_Element } from "./BuilditSelect";
import Tooltip from "./Tooltip";
import { Help } from "@material-ui/icons";
import { buildingTypeLabel } from './Utils'
import { BuildingType } from './model/Project'

export interface ProjectFieldInputProps extends ProjectFormPropsBase {
  className?: string;
  fieldName?: keyof Project;
  fieldValue: React.ReactText | undefined;
  unit?: string;
  unitFieldName?: keyof Project;
  unitFieldValue?: React.ReactText;
  title?: string;
  placeholder?: string;
  type?: "text" | "number";
  multiline?: boolean;
  rows?: number;
  select?: boolean;
  list?: Array<List_Element>;
  unitList?: Array<{ value: React.ReactText; label: React.ReactText }>;
  infoAdornment?: React.ReactNode;
  min?: number;
  max?: number;
  length?: number;
  error?: boolean;
  errorMsg?: string;
  step?: number;
  refName?: string;
  align?: "left" | "right" | "center";
  tabIndex?: number;
  forceBlur?: boolean;
  autoFocus?: boolean;
  disabledAutoSetting?: boolean;
  defaultValue?: string | number;
  showDefault?: boolean;
  tooltip?: JSX.Element;

  onUpdateDefaults?: () => void;
  onKeyUp?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onChange?: (value: React.ReactText | undefined) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  setMultiSolver?: (field: string, value: any) => void;
}
export interface ProjectFieldInputState {
  focus: boolean;
  hover: boolean;
  error: boolean;
  errorMsg: string;
}

export default class ProjectFieldInput extends Component<ProjectFieldInputProps, ProjectFieldInputState> {
  state: ProjectFieldInputState = {
    focus: false,
    hover: false,
    error: false,
    errorMsg: "",
  };

  checkErr = () => {
    if (this.props.fieldName !== undefined && this.props.projectErros !== undefined) {
      // console.log('checkErrs', this.props.projectErros.errs, this.props.fieldName);
      if (this.props.projectErros.errs[this.props.fieldName] !== undefined) {
        this.setState({
          error: true,
          errorMsg: this.props.projectErros.errs[this.props.fieldName]!.msg,
        });
      } else {
        this.setState({
          error: false,
          errorMsg: "",
        });
      }
    }
  };

  componentDidMount = () => {
    this.checkErr();
  };

  componentDidUpdate = (pp: Readonly<ProjectFieldInputProps>, ps: Readonly<ProjectFieldInputState>) => {
    if (this.props.forceBlur) {
      this.setState({
        focus: false,
        hover: false,
      });
    }
    if (!_.isEqual(this.props.fieldValue, pp.fieldValue) || !_.isEqual(this.props.projectErros, pp.projectErros)) {
      this.checkErr();
    }
  };

  render() {
    return (
      <div className={`ProjectFieldInput ${this.props.className || ""}`}>
        {(this.props.title || this.props.infoAdornment || this.props.unitFieldName) && (
          <div className="header">
            <div className={`title ${this.props.error && "error"}`}>{this.props.title}</div>
            {this.props.tooltip ? (
              <Tooltip msg={this.props.tooltip}>
                <Help className="help-icon" />
              </Tooltip>
            ) : (
              <></>
            )}
            {this.props.infoAdornment && <div className="info-adornment">{this.props.infoAdornment}</div>}
            {this.props.unitFieldName && (
              <div className="unit-select">
                <BuilditInput select={true} className="unit" value={this.props.unitFieldValue} onChange={this.onUnitChange} list={this.props.unitList} />
              </div>
            )}
          </div>
        )}
        <div
          className="content"
          onMouseEnter={(e) => this.setState({ hover: true })}
          onMouseLeave={(e) => this.setState({ hover: false })}
          onMouseDown={(e) => {
            if (this.props.onMouseDown) {
              this.props.onMouseDown(e);
            }
          }}
        >
          {(this.props.showDefault === true && (
            <div className={`default-value ${(this.props.select && "select") || "input"}`}>
              {(this.props.select && this.props.defaultValue) || (
                <Fragment>
                  <div className={`value ${this.props.unit === undefined && "no-unit"}`}>{this.props.defaultValue}</div>
                  {this.props.unit && <div className="unit">{this.props.unit}</div>}
                </Fragment>
              )}
            </div>
          )) || (
            <Fragment>
              {(this.props.select && (
                <BuilditSelect
                  type="Normal"
                  onChange={this.onChange}
                  className={`${
                    this.props.defaultValue !== undefined && this.props.showDefault !== undefined && this.props.defaultValue !== buildingTypeLabel(this.props.fieldValue! as BuildingType) ? "diffrent-default" : ""
                  }`}
                  list={this.props.list}
                  value={this.props.fieldValue}
                  placeholder={this.props.placeholder}
                  forceBlur={this.props.forceBlur}
                />
              )) || (
                <BuilditInput
                  className={`${this.props.defaultValue !== undefined && this.props.showDefault !== undefined && this.props.defaultValue !== this.props.fieldValue ? "diffrent-default" : ""}`}
                  ref={`buildit-${this.props.refName}`}
                  min={
                    (this.props.min === undefined &&
                      this.props.fieldName !== undefined &&
                      ProjectConstraints[this.props.fieldName] !== undefined &&
                      ProjectConstraints[this.props.fieldName!]!.min !== undefined &&
                      ((typeof ProjectConstraints[this.props.fieldName]!.min === "number"
                        ? ProjectConstraints[this.props.fieldName]!.min
                        : (ProjectConstraints[this.props.fieldName]!.min as Function)(this.props.currentProject)) ||
                        undefined)) ||
                    this.props.min
                  }
                  max={
                    (this.props.max === undefined &&
                      this.props.fieldName !== undefined &&
                      ProjectConstraints[this.props.fieldName] !== undefined &&
                      ProjectConstraints[this.props.fieldName!]!.max !== undefined &&
                      ((typeof ProjectConstraints[this.props.fieldName]!.max === "number"
                        ? ProjectConstraints[this.props.fieldName]!.max
                        : (ProjectConstraints[this.props.fieldName]!.max as Function)(this.props.currentProject)) ||
                        undefined)) ||
                    this.props.max
                  }
                  length={this.props.length}
                  step={this.props.step}
                  error={this.props.error !== undefined ? this.props.error : this.state.error}
                  errorMsg={this.props.errorMsg !== undefined ? this.props.errorMsg : this.state.errorMsg}
                  autoFocus={this.props.autoFocus}
                  forceBlur={this.props.forceBlur}
                  tabIndex={this.props.tabIndex}
                  refName={this.props.refName}
                  onChange={this.onChange}
                  placeholder={this.props.placeholder}
                  value={this.props.fieldValue}
                  defaultValue={this.props.defaultValue}
                  type={this.props.type}
                  multiline={this.props.multiline}
                  rows={this.props.rows}
                  select={this.props.select}
                  list={this.props.list}
                  onFocus={() => this.setState({ focus: true }, () => this.props.onFocus && this.props.onFocus())}
                  onBlur={() => this.setState({ focus: false }, () => this.props.onBlur && this.props.onBlur())}
                  align={this.props.align}
                  onKeyDown={this.props.onKeyDown}
                  onKeyUp={this.props.onKeyUp}
                  startAdornment={
                    (this.props.type === "number" && (
                      <Fragment>
                        <IconButton
                          className={`icon-btn dec-btn ${(!this.state.focus && !this.state.hover && "visibility-hidden") || ""}`}
                          onClick={(e) => {
                            this.handleValue("DEC");
                          }}
                        >
                          <DecIcon className="icon" />
                        </IconButton>
                        <IconButton className={`icon-btn inc-btn ${(!this.state.focus && !this.state.hover && "visibility-hidden") || ""}`} onClick={(e) => this.handleValue("INC")}>
                          <IncIcon className="icon" />
                        </IconButton>
                      </Fragment>
                    )) ||
                    undefined
                  }
                  endAdornment={
                    (this.props.type === "number" && this.props.unit && (
                      <Fragment>
                        <div className="unit" style={{ padding: "0px 10px 0px 0px" }}>
                          {this.props.unit}
                        </div>
                      </Fragment>
                    )) ||
                    undefined
                  }
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  onChange = (value: React.ReactText) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    } else {
      if (this.props.fieldName !== undefined) {
        this.props.onUpdateProject(
          {
            [this.props.fieldName]: value,
            auto_setting: this.props.disabledAutoSetting === true ? this.props.currentProject.auto_setting : false,
          },
          () => {
            this.props.onUpdateDefaults && this.props.onUpdateDefaults();
          }
        );
      }
    }
  };
  onUnitChange = (value: React.ReactText) => {
    if (this.props.unitFieldName) {
      this.props.onUpdateProject({ [this.props.unitFieldName]: value });
    }
  };
  handleValue = (type: "INC" | "DEC") => {
    const step = this.props.step === undefined ? 1 : this.props.step;
    //@ts-ignore
    const curVal = this.props.fieldValue === undefined ? Number(this.refs[`buildit-${this.props.refName!}`].refs[this.props.refName!].value) : (this.props.fieldValue as number);
    const nextVal = Math.round((curVal + (type === "INC" ? step : -step)) * 100) / 100;
    const min = this.props.min === undefined ? 0 : this.props.min;
    const max = this.props.max === undefined ? Number.MAX_SAFE_INTEGER : this.props.max;

    let value;

    if (min <= nextVal && nextVal <= max) {
      value = nextVal;
    } else if (min > nextVal) {
      value = min;
    } else if (max < nextVal) {
      value = max;
    }
    this.onChange(value as number);
    if (this.props.fieldValue === undefined) {
      //@ts-ignore
      this.refs[`buildit-${this.props.refName!}`].refs[this.props.refName!].value = value;
    }
  };
}
