import React, { Component, Fragment } from 'react';
import './css/ProjectFormBuilding.scss';
import { Button, InputAdornment, IconButton } from '@material-ui/core';
import { ProjectFormPropsBase } from './ProjectInput';
import AddIcon from '@material-ui/icons/AddCircle';
import SubIcon from '@material-ui/icons/RemoveCircle';
import CheckIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/HelpOutline';
import ArrowForward from '@material-ui/icons/ArrowForward';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ProjectBuildingShapeType, ProjectMyBuildingShapeType } from './model/Project';
import App from './App';
import * as URI from "uri-js";
import moment from 'moment';

import { default as _ } from 'lodash';
import AWSModule from './AWSModule';
import jquery from 'jquery';
import S3Image from './S3Image';
import { Link } from 'react-router-dom';
import BuilditInput from './BuilditInput';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from './Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { ModalOptions } from './Modal';
import { buildingShapeTypeToString } from './Utils';
const $ = jquery;

export interface ProjectFormBuildingProps extends ProjectFormPropsBase {
  setOpen: (open: boolean) => void;
  setModal: (open: boolean, options?: ModalOptions) => void;
  open: boolean;
}
export interface ProjectFormBuildingState {
  position: number;
  myBuildingTypes: any[];
  activeTab: "LINEAR_CORRIDOR_TYPE" | 'FOLDED_CORRIDOR_TYPE' | 'LINEAR_NON_CORRIDOR_TYPE' | 'FOLDED_NON_CORRIDOR_TYPE' | 'T_TOWER_TYPE' | 'Y_TOWER_TYPE' | 'MY_TYPE';
  buildingItemsScroll: number,
  searchText: string,
}

export default class ProjectFormBuilding extends Component<ProjectFormBuildingProps, ProjectFormBuildingState> {
  state: ProjectFormBuildingState = {
    position: 100,
    myBuildingTypes: [],
    activeTab: "LINEAR_NON_CORRIDOR_TYPE",
    buildingItemsScroll: 0,
    searchText: "",
  }

  builditBuildingTypes = [
    {
      name: '판상 I형',
      type: 'LINEAR_NON_CORRIDOR_TYPE',
      houses: [2, 4, 6]
    },
    {
      name: '판상 L형',
      type: 'FOLDED_NON_CORRIDOR_TYPE',
      houses: [2, 3, 4, 5, 6, 7]
    },    
    {
      name: '타워 T형',
      type: 'T_TOWER_TYPE',
      houses: [3, 4]
    },
    {
      name: '타워 Y형',
      type: 'Y_TOWER_TYPE',
      houses: [3, 4, 5, 6, 7]
    },
    {
      name: '복도식 I형',
      type: 'LINEAR_CORRIDOR_TYPE',
      houses: [4, 5, 6, 7, 8, 9]
    },
    {
      name: '복도식 L형',
      type: 'FOLDED_CORRIDOR_TYPE',
      houses: [4, 5, 6, 7, 8, 9]
    }
  ]


  searchMyBuildingTypes = async () => {
    const searchText = this.state.searchText.trim();
    const r1 = await App.search({
      table: App.DDBTable.MyBuildingType,
      query: {
        query_string: {
          query: `deleted: false AND (name:*${searchText}* ${searchText.length > 0 && `OR houses_number:${searchText}` || "" }) `,
        },
      },
      sort: [ { created_at: 'desc' }],
      from: 0, size: 10000,
    });

    const rr1 = r1.data;
    const myBuildingTypes: any = [];

    
    
    if (rr1.hits && rr1.hits.hits.length > 0) {
      
      
      rr1.hits.hits.map((t: any) => myBuildingTypes.push(t._source));
      

      
    }

    this.setState({
      myBuildingTypes: myBuildingTypes
    })
  }

  componentWillMount = () => {
    this.searchMyBuildingTypes = _.debounce(this.searchMyBuildingTypes, 200);
  }

  componentDidMount = async () => {

    this.props.setOpen(true); 
    this.searchMyBuildingTypes();

  }
  componentWillUnmount = () => {
    this.moveToType = _.debounce(this.moveToType, 200);
    this.onChangeBuildingItemsScroll = _.debounce(this.onChangeBuildingItemsScroll, 200);
    this.props.setOpen(false);
  }

  componentDidUpdate = (pp: Readonly<ProjectFormBuildingProps>, ps: Readonly<ProjectFormBuildingState>) => { 
    if (ps.searchText !== this.state.searchText) {
      this.searchMyBuildingTypes();
    }
    
  }

  moveToType = (type: string) => {
    const ePosition = $($(`.${type}`)[0]).offset();
    const wTop = $($('.building-items')[0]).scrollTop();
    const eHeight = $($(`.${type}`)[0]).height();
    const moveTo = ePosition!.top + wTop! - eHeight!;
    $($('.building-items')[0]).animate({ scrollTop: ePosition!.top - $($('.building-items')).offset()!.top + wTop! - 20}, 500);
  }

  onChangeBuildingItemsScroll = () => {
    if (this.state.activeTab !== "MY_TYPE") {
      const y = this.state.buildingItemsScroll;
      this.builditBuildingTypes.map((t: any) => {
        const ePosition = $($(`.${t.type}`)[0]).offset();
        const wTop = $($('.building-items')[0]).scrollTop();
        const eHeight = $($(`.${t.type}`)[0]).height();
        // const mHeight = $($(`.MY_TYPE`)[0]).height();
        const et = ePosition!.top + wTop! - eHeight!;

        // const myTypeTop = $($('.MY_TYPE')[0]).offset()!.top - mHeight!;
        if (et - 50 < y &&  y < et + 302) {
          this.setState({ activeTab: t.type })
        }
        // } else if (myTypeTop < 250) {
        //   // this.setState({ activeTab: 'MY_TYPE' })
        // }
      })
    }
  }


  render () {

    
    return (
      <div className="ProjectFormBuilding">
        <div className="building-types">
          <div className={`type buildit-type ${this.state.activeTab !== "MY_TYPE" && "active" || ""}`}>
          <span className="title">BUILDIT 동타입</span>
          {
            this.builditBuildingTypes.map((t, i) => {
              return (
                <BuildingTypes type={t.type} activeType={this.state.activeTab} 
                onClick={() => this.setState({ 
                  activeTab: "LINEAR_NON_CORRIDOR_TYPE" 
                }, () => {
                  this.moveToType(t.type)
                })} />
              )
            })
          }
          </div>
          <div className={`type my-type ${this.state.activeTab === "MY_TYPE" && "active" || ""}`}>
            <span className="title">나의 동평면</span>
            <BuildingTypes type="MY_TYPE" activeType={this.state.activeTab} title={<div>나의<br />동평면</div>} 
              onClick={() => this.setState({ activeTab: "MY_TYPE" }, () => {
                $($('.building-items')[0]).animate({ scrollTop: 0 }, 500);
              })} />
            {/* <Button
              className="building-drawer-open-btn"
              disableRipple={true}
              onClick={ e => this.props.setOpen(!this.props.open) }
            >
              선택된<br />타입
            </Button> */}
          </div>
        </div>
        <div className="building-items-wrapper">
          <div className={`items-header ${this.state.activeTab !== "MY_TYPE" && "BUILDIT_TYPE" || "MY_TYPE"}`}>
          {
            this.state.activeTab !== "MY_TYPE" &&
            <Fragment>
              <div className="font bg-navy font-18px font-primary m-r-sm title">BUILDIT 동타입</div>
              <div className="info">세대 선택 후 이미지를 클릭해주세요</div>
            </Fragment> ||
            <div className="MY_TYPE my-building-type-wrap">
              <div className="font bg-navy font-18px font-primary m-r-sm title">
                나의 동평면
                <Tooltip 
                  className="m-l-sm"
                  msg={
                    <Fragment>
                      '마이페이지 > 나의 동평면'에서 <br />
                      업로드 후 사용 가능합니다
                    </Fragment>
                  }
                  id="ProjectFormBuilding-MyBuildingPlan"
                >
                  <HelpIcon className="icon" />
                </Tooltip>
              </div>
              <div className="button-wrapper">
                <Button className="upload-btn btn bg-navy btn-primary" disableRipple={true} onClick={e => window.open("/cad/MyBuildingType", "_blank")}>
                  업로드 바로가기
                  <ArrowForward className="icon"/>
                </Button>
                <Button className="refresh-btn btn bg-navy btn-primary" disableRipple={true} onClick={e => this.searchMyBuildingTypes()}>
                  <RefreshIcon className="icon" />
                </Button>
              </div>
              <div className="search-field">
                <BuilditInput 
                  align="left"
                  placeholder="검색"
                  value={this.state.searchText}
                  onChange={(v: React.ReactText) => this.setState({ searchText: v as string })}
                  endAdornment={<InputAdornment position="end">
                    {
                      this.state.searchText.length > 0 &&
                      <IconButton className="icon-btn" disableRipple={true} onClick={e => { this.setState({ searchText: "" }) }}>
                        <CloseIcon className="icon remove-icon" />
                      </IconButton>
                    }
                  <IconButton className="icon-btn" disableRipple={true} onClick={e => {
                        
                    }}>
                      <SearchIcon className="icon" />
                    </IconButton>
                  </InputAdornment>}
                />
              </div>
            </div>
          }
          </div>
          <div className={`building-items ${this.state.activeTab !== "MY_TYPE" && "BUILDIT_TYPE" || "MY_TYPE"}`}
            onScroll={(e: any) => { this.setState({ buildingItemsScroll: e.target.scrollTop }, () =>
            this.onChangeBuildingItemsScroll()) }}
          >
            {              
               this.state.activeTab !== "MY_TYPE" &&
               <Fragment>
                  <Fragment>
                  {
                    this.builditBuildingTypes.map((t, i) => { 
                      return (
                        <BuildingItems key={`buildingitems_${i}`} className={t.type} {...this.props} item={t} setOpen={this.props.setOpen} open={this.props.open} />
                      )
                    })
                  }
                  </Fragment>
              </Fragment> || 
              <Fragment>
                { 
                  this.state.myBuildingTypes.length > 0 &&
                  this.state.myBuildingTypes.map((t, i) => {
                    return (
                      <MyBuildingItems key={`buildingitems_MY_TYPE_${i}`} {...this.props} item={t} setOpen={this.props.setOpen} open={this.props.open} />
                    )
                  }) || 
                  <MyBuildingItems {...this.props} item={null} isEmpty={true} />
                }
              </Fragment>
            }
            <div className="buffer" />
          </div>
        </div>
      </div>
    );
  }
}

interface BuildingTypesProps {
  type?: string;
  activeType?: string;
  title?: JSX.Element;
  onClick?: () => void;
}
interface BuildingTypesState {
  active: boolean;
}
class BuildingTypes extends Component<BuildingTypesProps, BuildingTypesState> {
  state: BuildingTypesState = {
    active: false,
  }
  render () {
    return (
      <Button 
        disableRipple={true} 
        className={this.props.type === this.props.activeType && "building-types-btn active" || "building-types-btn"}
        onClick={e => this.setState({active: !this.state.active}, () => {
          this.props.onClick && this.props.onClick();
        })}
      >
        {
          this.props.type && this.props.type !== "MY_TYPE" &&
          <Fragment>
            <img className="active-img" src={'/img/building_types/types/active/' + this.props.type + '.png'} />
            <img className="default-img" src={'/img/building_types/types/default/' + this.props.type + '.png'} />
          </Fragment>
        }
        {
          this.props.title && this.props.title
        }
      </Button>
    )
  }
}

export interface BuildingItemsProps extends ProjectFormPropsBase {
  item: {name: string, type: string, houses: number[]};  
  setOpen?: (open: boolean) => void;
  setModal: (open: boolean, options?: ModalOptions) => void;
  open?: boolean;
  className?: string;
}
export interface BuildingItemsState {
  selectHouses: number;
}

export class BuildingItems extends Component<BuildingItemsProps, BuildingItemsState> {
  state: BuildingItemsState = {
    selectHouses: this.props.item.houses[0],
  }
  render () {
    return (
      <div className={`BuildingItems ${this.props.className && this.props.className || ""}`}>
        <div className="title font bg-navy font-primary font-18px">{this.props.item.name}</div>
        <div className="houses-btn-wrap font bg-navy font-primary font-14px">
        {
          this.props.item.houses.map((h, i) => {
            return <Button
              onClick={e => this.onSelectHouses(i)}
              disableRipple={true}
              // className={"houses-btn" + (this.state.selectHouses === h && " active" || "")}
              className={`tab bg-navy tab-secondary tab-small houses-btn ${this.state.selectHouses === h && 'active'}`}
            >
              <div className="houses-value">{h}</div>
            </Button>
          })
        } 세대
        </div>
        {
          this.props.currentProject.building_shape_type &&
          this.props.currentProject.building_shape_type.findIndex(e => e.figures === this.props.item.type && e.houses_number === this.state.selectHouses) > -1 &&
          <Button disableRipple={true} className="img-btn"
            onClick={e => this.onDeleteItem(this.props.item.type, this.state.selectHouses)}
          >
            <img src={"/img/building_types/items/" + this.props.item.type + "_" + this.state.selectHouses + ".png"} />
            <SubIcon className="building-types-sub building-types-icon" />
            <CheckIcon className="building-types-check building-types-icon" />
          </Button>
          ||
          <Button disableRipple={true} className="img-btn"
            onClick={e => this.onSelectItem(this.props.item.type, this.state.selectHouses)}
          >
            <img src={"/img/building_types/items/" + this.props.item.type + "_" + this.state.selectHouses + ".png"} />
            <AddIcon className="building-types-add building-types-icon" />
          </Button>
        }
      </div>
    );
  }

  onSelectHouses = (index: number) => {
    this.setState({
      selectHouses: this.props.item.houses[index]
    })
  }
  onSelectItem = (type: string, houses: number) => {
    if (this.props.currentProject.building_shape_type!.length > 3) {
      this.props.setModal(true, {
        negative: () => { this.props.setModal(false) },
        positive: () => { this.props.setModal(false) },
        content: <div>최대 4개 까지 선택 하실 수 있습니다.</div>
      })
      return;
    }

    const list = _.cloneDeep(this.props.currentProject.building_shape_type);
    list!.push(new ProjectBuildingShapeType({
      figures: type,
      houses_number: houses
    }));

    this.props.onUpdateProject({
      building_shape_type: list,
      auto_setting: false,
    })
  }
  onDeleteItem = (type: string, houses: number) => {
    
    const list = _.cloneDeep(this.props.currentProject.building_shape_type!);
    const index = list!.findIndex(e => e.figures === type && e.houses_number === houses);
    if (index > -1) {
      list!.splice(index, 1);
    }
    this.props.onUpdateProject({
      building_shape_type: list,
      auto_setting: false,
      housing_plan_type: list.length === 0 ? [] : this.props.currentProject.housing_plan_type,
      housing_rate: list.length === 0 ? 0 :
        this.props.currentProject.housing_plan_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0) +
        this.props.currentProject.my_building_shape_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0)
    })
  }
}

export interface MyBuildingItemsProps extends ProjectFormPropsBase {
  item: any;
  setOpen?: (open: boolean) => void;
  open?: boolean;
  className?: string;
  isEmpty?: boolean;
}
export class MyBuildingItems extends Component<MyBuildingItemsProps> {


    
  render = () => {
    // const s3 = await new AWSModule("S3").connect();
    
    return (
      <div className={`MyBuildingItems ${this.props.className && this.props.className || ""} ${this.props.isEmpty && "empty" || ""}`}>
        {
          (this.props.isEmpty === false || this.props.isEmpty === undefined) &&
          <Fragment>
            <div className="header">
              <div className="id">{this.props.item.user_id}</div>
              <img src="/img/my_icon.png" />
            </div>
            <div className="img">
            {
              this.props.currentProject.my_building_shape_type &&
              this.props.currentProject.my_building_shape_type.findIndex(e => e.id === this.props.item.global_id) > -1 &&
              <Button disableRipple={true} className="img-btn" onClick={e => this.onDeleteItem(this.props.item)} >
                <S3Image s3={{
                  Bucket: App.S3Bucket.MyBuildingType,
                  Key: (URI.parse(this.props.item.img_path, { absolutePath: true }).path as string).substring(1)
                }}/>
                <SubIcon className="building-types-sub building-types-icon" />
                <CheckIcon className="building-types-check building-types-icon" />
              </Button>
              ||
              <Button disableRipple={true} className="img-btn" onClick={e => this.onSelectItem(this.props.item)} >
                <S3Image s3={{
                  Bucket: App.S3Bucket.MyBuildingType,
                  Key: (URI.parse(this.props.item.img_path, { absolutePath: true }).path as string).substring(1)
                }}/>
                <AddIcon className="building-types-add building-types-icon" />
              </Button>
            }
            </div>
            <div className="info">
              <div className="name" title={this.props.item.name}>{this.props.item.name}</div>
              <div className="key-value">
                <div className="key">세대타입</div>
{/*                 
                {
                  <div className='value padding-right-none'>{this.state.item.exclusive_area_list && `${Array.from(new Set(this.state.item.exclusive_area_list.map((areaInfo: { name: string, area: number }) => Math.floor(areaInfo.area)))).join('/')}`}</div>
                   {
                  this.state.item.exclusive_area_list ?
                    <div className="unit">타입</div>
                    : <div className="unit">-</div>
                }
              </div>
                } */}
                {
                  this.props.item.exclusive_area_list && 
                  <div className="value">
                    <div className="house-type">{this.props.item.exclusive_area_list.map((data: { name: string, area: number }) => Math.floor(data.area)).join('/')}</div>
                    <div className="unit">타입</div>
                  </div>
                  || <div className="value">-</div>
                }
              </div>
              <div className="key-value">
                <div className="key">베이</div>
                {
                  this.props.item.main_bay && <div className="value">{this.props.item.main_bay}베이</div>
                  || <div className="value">-</div>
                }
              </div>
              <div className="key-value">
                <div className="key">총세대수</div>
                <div className="value">{this.props.item.houses_number}세대</div>
              </div>
              <div className="key-value">
                <div className="key">동타입</div>
                {
                  this.props.item.building_shape_type && <div className="value">{buildingShapeTypeToString(this.props.item.building_shape_type)}</div>
                  || <div className="value">-</div>
                }
              </div>
              <div className="key-value">
                <div className="key">생성일</div>
                <div className='value'>{moment(this.props.item.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
              </div>
            </div>
          </Fragment> ||
          <div>나의 동평면이 없습니다</div>
        }
      </div>
    )
  }
  onSelectItem = (item: any) => {
    const list = _.cloneDeep(this.props.currentProject.my_building_shape_type);
    list!.push(new ProjectMyBuildingShapeType({id: item.global_id}));
    
    this.props.onUpdateProject({
      my_building_shape_type: list,
      auto_setting: false,
    })
  }
  onDeleteItem = (item: any) => {
    const list = _.cloneDeep(this.props.currentProject.my_building_shape_type);
    const index = list!.findIndex(e => e.id === item.global_id);
    if (index > -1) {
      list!.splice(index, 1);
    }
    this.props.onUpdateProject({
      my_building_shape_type: list,
      auto_setting: false,
      housing_rate: list!.length === 0 ? 0 :
        this.props.currentProject.housing_plan_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0) +
        this.props.currentProject.my_building_shape_type!.map(r => r.proportion!).reduce((a, b) => a + b, 0)
    })
  }
}