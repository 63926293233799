import React, { Component } from 'react';
import Tooltip from "../Tooltip";
import Switch from "../Switch";
import { Info } from '@material-ui/icons';
import { myTypeSettingData } from './detialSetting';
import { SaveState } from './SettingModal';
import { ConverterType } from './DataTypes';

interface MySiteSettingUIProps {
  myTypeSettingData: myTypeSettingData,
  autoEditAll: boolean,
  converterType: ConverterType,
  handleSaveState: (saveState: SaveState) => void,
  handleAutoEditAll: () => void,
}

interface MySiteSettingUIState {
  unClosedPolygonEnable: boolean,
  blockOverlapEnable: boolean,
  polygonOffsetEnable: boolean,
  lineInSameBlockEnable: boolean, // 같은 블록 안에 N개의 라인 선택
}


class MySiteSettingUI extends Component<MySiteSettingUIProps, MySiteSettingUIState> {
  state: MySiteSettingUIState = {
    unClosedPolygonEnable: this.props.myTypeSettingData.unClosedPolygon.enable,
    blockOverlapEnable: this.props.myTypeSettingData.blockOverlap.enable,
    polygonOffsetEnable: this.props.myTypeSettingData.polygonOffset.enable,
    lineInSameBlockEnable: this.props.myTypeSettingData.lineInSameBlock.enable,
  
  }

  componentDidUpdate = async (prevProps: MySiteSettingUIProps, prevState: MySiteSettingUIState) => {
    if (prevProps.autoEditAll !== this.props.autoEditAll) {
      await this.props.myTypeSettingData.updateDB();
      this.setState({
        unClosedPolygonEnable: this.props.myTypeSettingData.unClosedPolygon.enable, // 닫혀있지않은 폴리곤 오차보정
        blockOverlapEnable: this.props.myTypeSettingData.blockOverlap.enable, // 블록간 교차 면적 보정
        polygonOffsetEnable: this.props.myTypeSettingData.polygonOffset.enable, // 폴리곤간 이격 보정
        lineInSameBlockEnable: this.props.myTypeSettingData.lineInSameBlock.enable,
      });
      this.props.handleSaveState(SaveState.saveDone);
    }
  }

  render() {
    if (this.props.converterType === ConverterType.mySite) {
      return (
        <>
        
          <div className="wrap-key-value">
            <div className="wrap-left-text">

              <div className="key font font-primary font-14px">닫혀 있지 않은 폴리곤 오차 보정
                <Tooltip msg="동평면/사업영역 - 설정하신 폴리곤이 닫혀 있지 않을 시, 해당 범위 내에서 보정합니다." arrowOn={false}>
                  <div className="wrap-icon">
                    <Info className="icon info-icon font font-emphasis" />
                  </div>
                </Tooltip>
              </div>
              <div className="font font-secondary middle-text">1mm 이하 오차 범위 내</div>
            </div>
            <div className="value">
              <Switch
                className={`font ${this.state.unClosedPolygonEnable ? "font-special" : "font-secondary"}`}
                checked={this.state.unClosedPolygonEnable}
                onClick={async (checked) => {
                  this.setState({ unClosedPolygonEnable: !this.state.unClosedPolygonEnable }, async () => {
                    this.props.handleSaveState(SaveState.saving);
                    this.props.myTypeSettingData.unClosedPolygon.enable = checked;
                    await this.props.handleAutoEditAll();
                    await this.props.myTypeSettingData.updateEnable(checked, 'unClosedPolygon');
                    this.props.handleSaveState(SaveState.saveDone);
                  });
                }}
                name="자동 보정"
              />

            </div>
          </div>
          <div className="wrap-key-value">
            <div className="wrap-left-text">
              <div className="key font font-primary font-14px">블록 간 교차 면적 보정
                <Tooltip msg="동평면/사업영역 - 설정하신 블록 간의 교차된 면적 오차를 해당 범위 내에서 보정합니다. " arrowOn={false}>
                  <div className="wrap-icon">
                    <Info className="icon info-icon font font-emphasis" />
                  </div>
                </Tooltip>
              </div>
              <div className="font font-secondary middle-text">0.1% 미만 오차 범위 내</div>
            </div>

            <div className="value">
              <Switch
                className={`font ${this.state.blockOverlapEnable ? "font-special" : "font-secondary"}`}
                checked={this.state.blockOverlapEnable}
                onClick={async (checked) => {
                  this.setState({ blockOverlapEnable: !this.state.blockOverlapEnable }, async () => {
                    this.props.myTypeSettingData.blockOverlap.enable = checked;
                    this.props.handleSaveState(SaveState.saving);
                    await this.props.myTypeSettingData.updateEnable(checked, 'blockOverlap');
                    await this.props.handleAutoEditAll();
                    this.props.handleSaveState(SaveState.saveDone);
                  });
                }}
                name="자동 보정"
              />

            </div>
          </div>
          <div className="wrap-key-value">
            <div className="wrap-left-text">
              <div className="key font font-primary font-14px">블록 간 이격 보정
                <Tooltip msg="동평면/사업영역 - 설정 하신 폴리곤 사이의 이격 거리 오차를 해당 범위 내에서 보정합니다." arrowOn={false}>
                  <div className="wrap-icon">
                    <Info className="icon info-icon font font-emphasis" />
                  </div>
                </Tooltip>
              </div>
              <div className="font font-secondary middle-text font-12px">1mm이하 / OFFSET 10mm미만 오차 범위 내</div>
            </div>
            <div className="value">
              <Switch
                className={`font ${this.state.polygonOffsetEnable ? "font-special" : "font-secondary"}`}
                checked={this.state.polygonOffsetEnable}
                onClick={async (checked) => {
                  this.setState({ polygonOffsetEnable: !this.state.polygonOffsetEnable }, async () => {
                    this.props.myTypeSettingData.polygonOffset.enable = checked;
                    this.props.handleSaveState(SaveState.saving);
                    await this.props.myTypeSettingData.updateEnable(checked, 'polygonOffset');
                    await this.props.handleAutoEditAll();
                    this.props.handleSaveState(SaveState.saveDone);
                  });
                }}
                name="자동 보정"
              />

            </div>
          </div>
          <div className="wrap-key-value">
            <div className="wrap-left-text">

              <div className="key font font-primary font-14px">같은 블록 안에 N개의 라인 선택
                <Tooltip msg="사업영역 블록 지정 - 같은 이름의 블록 안에 N개의 라인이 들어 올 시, 가장 큰 폴리라인을 제외한 라인은 삭제 처리 하여 저장합니다. " arrowOn={false}>
                  <div className="wrap-icon">
                    <Info className="icon info-icon font font-emphasis" />
                  </div>
                </Tooltip>
              </div>
              <div className="font font-secondary middle-text">가장 큰 폴리라인 선택</div>
            </div>
            <div className="value">
              <Switch
                className={`font ${this.state.lineInSameBlockEnable ? "font-special" : "font-secondary"}`}
                checked={this.state.lineInSameBlockEnable}
                onClick={async (checked) => {
                  this.setState({ lineInSameBlockEnable: !this.state.lineInSameBlockEnable }, async () => {
                    this.props.myTypeSettingData.lineInSameBlock.enable = checked;
                    this.props.handleSaveState(SaveState.saving);
                    await this.props.myTypeSettingData.updateEnable(checked, 'lineInSameBlock');
                    await this.props.handleAutoEditAll();
                    this.props.handleSaveState(SaveState.saveDone);
                  });
                }}
                name="자동 보정"
              />

            </div>
          </div>

        </>
      );
    }
    else return (
      <>
        <div className="wrap-key-value">
          <div className="wrap-left-text">

            <div className="key font font-primary font-14px">같은 블록 안에 N개의 라인 선택
              <Tooltip msg="사업영역 블록 지정 - 같은 이름의 블록 안에 N개의 라인이 들어 올 시, 가장 큰 폴리라인을 제외한 라인은 삭제 처리 하여 저장합니다. " arrowOn={false}>
                <div className="wrap-icon">
                  <Info className="icon info-icon font font-emphasis" />
                </div>
              </Tooltip>
            </div>
            <div className="font font-secondary middle-text">가장 큰 폴리라인 선택</div>
          </div>
          <div className="value">
            <Switch
              className={`font ${this.state.lineInSameBlockEnable ? "font-special" : "font-secondary"}`}
              checked={this.state.lineInSameBlockEnable}
              onClick={async (checked) => {
                this.setState({ lineInSameBlockEnable: !this.state.lineInSameBlockEnable }, async () => {
                  this.props.myTypeSettingData.lineInSameBlock.enable = checked;
                  this.props.handleSaveState(SaveState.saving);
                  await this.props.myTypeSettingData.updateEnable(checked, 'lineInSameBlock');
                  await this.props.handleAutoEditAll();
                  this.props.handleSaveState(SaveState.saveDone);
                });
              }}
              name="자동 보정"
            />

          </div>
        </div>
      </>
    );

  }
}

export default MySiteSettingUI;