import * as jsts from 'jsts';
import * as THREE from '@teneleven/three';

const DXF_writer = require('dxf-writer');

export enum DXFColor {
  layer = DXF_writer.ACI.LAYER,
  red = DXF_writer.ACI.RED,
  yellow = DXF_writer.ACI.YELLOW,
  green = DXF_writer.ACI.GREEN,
  cyan = DXF_writer.ACI.CYAN,
  blue = DXF_writer.ACI.BLUE,
  magenta = DXF_writer.ACI.MAGENTA,
  white = DXF_writer.ACI.WHITE,
}

export enum DXFVersion {
  AutoCAD2018 = 'AutoCAD 2018',
  AutoCAD2013 = 'AutoCAD 2013',
  AutoCAD2010 = 'AutoCAD 2010',
  AutoCAD2007 = 'AutoCAD 2007',
  AutoCAD2004 = 'AutoCAD 2004',
  AutoCAD2000 = 'AutoCAD 2000',
}

export class DXFMaker {
  writer: any;
  layerList: string[] = [];
  boundary: any;
  scale: number;
  constructor(boundaryWKT: string, version: string) {
    this.writer = new DXF_writer();

    this.setDXFVersion(version);
    this.writer.setUnits('Millimeters');
    let reader = new jsts.io.WKTReader();
    this.boundary = reader.read(boundaryWKT);
    this.scale = 1000;
    
    this.writer.addLayer('TEXT', 60, 'CONTINUOUS');
    this.writer.addLayer('FIELD', 252, 'CONTINUOUS');
    this.writer.addLayer('BUILDING', 252, 'CONTINUOUS');
    this.writer.addLayer('ROAD', 253, 'CONTINUOUS');


    // this.writer.addLayer('text', DXFColor.green, 'CONTINUOUS');
    // this.writer.addLayer('field', DXFColor.yellow, 'CONTINUOUS');
    // this.writer.addLayer('building', DXFColor.white, 'CONTINUOUS');
    // this.writer.addLayer('road', DXFColor.yellow, 'CONTINUOUS');

    //    this.writer.layers.text.setTrueColor('31,0,127');
  }

  setDXFVersion = (label: string) => {
    let version = "AC1021";
  
    switch (label) {
      case DXFVersion.AutoCAD2018:      
        version = "AC1032";
        break;
      case DXFVersion.AutoCAD2013:
        version = "AC1027";
        break;
      case DXFVersion.AutoCAD2010:
        version = "AC1024";
        break;
      case DXFVersion.AutoCAD2007:
        version = "AC1021";
        break;
      case DXFVersion.AutoCAD2004:
        version = "AC1018";
        break;
      case DXFVersion.AutoCAD2000:
        version = "AC1015";
        break;
    }   
    this.writer.header("ACADVER", [[1, version]]);
  }

  ActiveLayer = (name: string, color: any = DXF_writer.ACI.WHITE) => {
    if (this.layerList.findIndex(layer => layer === name) > 0)
      this.writer.setActiveLayer(name);
    else {
      this.writer.addLayer(name, color, 'CONTINUOUS');
      this.writer.setActiveLayer(name);
      this.layerList.push(name);
    }
  }

  AddPolyline = (geom: jsts.geom.Geometry, number: string = '') => {
    try {
    
      this.writer.setActiveLayer('TEXT');
      let center = geom.getCentroid();
      this.writer.drawText(center.getX() * this.scale, center.getY() * this.scale, this.scale, 0, number, 'center', 'middle');
      this.writer.setActiveLayer('FIELD');
      this.writeGeomertry(geom); 
      
    } catch (e) {

    }
  }

  writeGeomertry = (geo: jsts.geom.Geometry) => {
    for (let i = 0; i < geo.getNumGeometries(); i++) {
      let writerPoints: number[][] = [];
      //@ts-ignore
      let subGeo = geo.getGeometryN(i)//.buffer(0.001);
      //@ts-ignore
      subGeo._shell.getCoordinates().forEach(c => {
        writerPoints.push([c.x * this.scale, c.y * this.scale, 0]);
      })
      this.writer.drawPolyline(writerPoints, true);

      //@ts-ignore
      let holes = subGeo._holes;
      holes.forEach((h: any) => {
        writerPoints = [];
        h.getCoordinates().forEach((c: any) => {
          writerPoints.push([c.x * this.scale, c.y * this.scale, 0]);
        })
        this.writer.drawPolyline(writerPoints, true);
      });
    }
  }

  AddRoadPolyline = (geom: jsts.geom.Geometry, number: string = '') => {
    try {     
      this.writer.setActiveLayer('TEXT');
      let center = geom.getCentroid();
      this.writer.drawText(center.getX() * this.scale, center.getY() * this.scale, this.scale, 0, number, 'center', 'middle');
      this.writer.setActiveLayer('ROAD');
      this.writeGeomertry(geom); 
    } catch (e) {

    }
  }


  AddBuildingPolyline = (geom: jsts.geom.Geometry) => {
    this.writer.setActiveLayer('BUILDING');
    // this.writer.setTrueColor(0xDCDCDC);
    try {
      this.writeGeomertry(geom);
    } catch (e) {
    }
  }

  //pos: center pos
  AddText = (pos: THREE.Vector2, height: number, text: string) => {
    this.writer.drawText(pos.x, pos.y, height, 0, text, 'CENTER', 'middle');
  }

  MakeDXF = () => {
    // this.writer.addLayer('Boundry', DXFColor.blue, 'CONTINUOUS');
    this.writer.addLayer('BOUNDARY', 255, 'CONTINUOUS');
    
    this.writer.setActiveLayer('BOUNDARY');

    let writerPoints: number[][] = [];
    this.boundary.getCoordinates().forEach((c: any) => {
      writerPoints.push([c.x * this.scale, c.y * this.scale, 0]);
    })
    this.writer.drawPolyline(writerPoints, true);

//    let dxfString = this.writer.toDxfString();

    
    //* download
    // let blob = new Blob([dxfString as Buffer]);
    // let url = URL.createObjectURL(blob);
    // var link = document.createElement('a');
    // link.setAttribute('download', 'test.dxf');
    // link.href = url;
    // link.click();
    return;
  }

  DownloadDXF = (fileName: string = "test") => {      

    this.writer.addLayer('BOUNDARY', 255, 'CONTINUOUS');
    this.writer.setActiveLayer('BOUNDARY');

    let writerPoints: number[][] = [];
    this.boundary.getCoordinates().forEach((c: any) => {
      writerPoints.push([c.x * this.scale, c.y * this.scale, 0]);
    })
    this.writer.drawPolyline(writerPoints, true);

    let dxfString = this.writer.toDxfString();
    
    let blob = new Blob([dxfString as Buffer]);
    let url = URL.createObjectURL(blob);
    var link = document.createElement('a');
    
    link.setAttribute('download', `${fileName}.dxf`);
    link.href = url;
    return link;
//    link.click();
  }
}