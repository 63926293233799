import { Project } from "./model/Project";

const ProjectSelectOptions: Partial<
  {
    [k in keyof Project]: {
      label: string;
      value: string;
    }[];
  }
> = {
  project_use_district: [
    {
      value: "제1종전용주거지역",
      label: "제1종전용주거지역",
    },
    {
      value: "제2종전용주거지역",
      label: "제2종전용주거지역",
    },
    {
      value: "제1종일반주거지역",
      label: "제1종일반주거지역",
    },
    {
      value: "제2종일반주거지역",
      label: "제2종일반주거지역",
    },
    {
      value: "제3종일반주거지역",
      label: "제3종일반주거지역",
    },
    {
      value: "준주거지역",
      label: "준주거지역",
    },
    {
      value: "중심상업지역",
      label: "중심상업지역",
    },
    {
      value: "일반상업지역",
      label: "일반상업지역",
    },
    {
      value: "근린상업지역",
      label: "근린상업지역",
    },
    {
      value: "유통상업지역",
      label: "유통상업지역",
    },
    {
      value: "전용공업지역",
      label: "전용공업지역",
    },
    {
      value: "일반공업지역",
      label: "일반공업지역",
    },
    {
      value: "준공업지역",
      label: "준공업지역",
    },
    {
      value: "보전녹지지역",
      label: "보전녹지지역",
    },
    {
      value: "생산녹지지역",
      label: "생산녹지지역",
    },
    {
      value: "자연녹지지역",
      label: "자연녹지지역",
    },
    {
      value: "보전관리지역",
      label: "보전관리지역",
    },
    {
      value: "생산관리지역",
      label: "생산관리지역",
    },
    {
      value: "계획관리지역",
      label: "계획관리지역",
    },
    {
      value: "농림지역",
      label: "농림지역",
    },
    {
      value: "자연환경보전지역",
      label: "자연환경보전지역",
    },
  ],
  building_type: [
    // {
    //   value: '미정',
    //   label: '미정'
    // }, {
    {
      value: "MULTIPLE",
      label: "BUILDIT 추천",
    },
    {
      value: "아파트",
      label: "아파트",
    },
    // {
    //   value: '연립주택',
    //   label: '연립주택',
    // },
    {
      value: "다세대주택",
      label: "다세대주택",
    },
    // {
    //   value: '도시형생활주택 - 원룸',
    //   label: '도시형생활주택 - 원룸'
    // },
    // {
    //   value: '도시형생활주택 - 다세대주택',
    //   label: '도시형생활주택 - 다세대주택'
    // },
    // {
    //   value: '도시형생활주택 - 연립주택',
    //   label: '도시형생활주택 - 연립주택'
    // },
    {
      value: "오피스텔",
      label: "오피스텔",
    }
  ],
  design_stage: [
    {
      value: "CONCEPT_DESIGN",
      label: "기획설계",
    },
    {
      value: "SCHEMATIC_DESIGN",
      label: "계획설계",
    },
    {
      value: "DESIGN_DEVELOPMENT",
      label: "기본설계",
    },
    {
      value: "CONSTRUCTION_DESIGN",
      label: "실시설계",
    },
  ],
  building_stories_avg_type: [
    {
      value: "AREA",
      label: "기준 면적",
    },
    {
      value: "NUMERICAL",
      label: "산술 평균",
    },
    {
      value: "HOUSE",
      label: "총 세대",
    },
  ],
  setback_regulation_from_north_more_9m_type: [
    {
      value: "HEIGHT",
      label: "HEIGHT",
    },
    {
      value: "METER",
      label: "METER",
    },
  ],
  setback_regulation_from_north_less_9m_type: [
    {
      value: "HEIGHT",
      label: "HEIGHT",
    },
    {
      value: "METER",
      label: "METER",
    },
  ],
  reports_number: [
    {
      value: "1",
      label: "1개",
    },
    {
      value: "2",
      label: "2개",
    },
    {
      value: "3",
      label: "3개",
    },
    {
      value: "4",
      label: "4개",
    },
    {
      value: "5",
      label: "5개",
    },
    {
      value: "6",
      label: "6개",
    },
    {
      value: "7",
      label: "7개",
    },
    {
      value: "8",
      label: "8개",
    },
    {
      value: "9",
      label: "9개",
    },
    {
      value: "10",
      label: "10개",
    },
  ],
  project_type: [
    {
      value: "AI",
      label: "AI",
    },
    {
      value: "DESIGNER",
      label: "DESIGNER",
    },
  ],
};

export default ProjectSelectOptions;
