import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplateBlock.scss';
import '@teneleven/protocols-ts-web';
import '../css/BuilditUI.scss';
import { ConverterLayer, siteStruct, Unit, ConverterType } from './DataTypes';
import { asyncFileRead, blockParsing, switchLineDashedState } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite, getRoadLine } from './DBManager';
import { NaverMapManager, NaverPoint, NaverLatLng } from './NaverMapManager';
import { jstsPolygontoWKT, latlng2tm, SceneManager, tm2latlng, } from './SceneManager';
import { Field, FieldType } from './Field';
import { brightenAllField, fieldToTHREEGeo, setBlockOpacity, wkt2LatLngs } from './CoreAndHouseController';
import { shapeInfoData } from './ProjectDataStruct';
import { ModalProps, CadConverterModal, buttonNum } from './Modal';
import { incrementCounter, timeScale } from '../Utils';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { Setting, userSettingData } from './SettingModal';
import { ErrorLogCell2, ErrorType } from './ErrorLog';
import { DXFMaker } from './DXFMaker';
import { BlockParsingData, BuildingPart, ConverterBuilding, ConverterField } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { ConverterInputBox } from './ConverterInputBox';
import {ReactComponent as BlockIcon} from '../img/icon/blockIcon.svg';
import {ReactComponent as HierarchyIcon} from '../img/icon/hierarchyIcon.svg';
import * as jsts from 'jsts';
import { Button, Snackbar } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import wkx from 'wkx'
import Axios from 'axios';
import App from '../App';
import MySiteBlockSaveModal from './MySiteBlockSaveModal';
import { LoadingPage } from './LoadingPage';
import { checkSiteError, checkSiteStructure } from './CheckSiteBlockError';
import { ErrorLogBlock } from './ErrorLogBlock';
import { ChevronLeft, Info, NavigateNext, SpeakerNotes, Warning } from '@material-ui/icons';
import { getCurveErrorCircleField, makePolygonApartLine } from './MeshMaker';
import FieldPartUI from './FieldPartUI';
import Tooltip from '../Tooltip';
import { ErrorList, ErrorItem, SiteError } from './Error';
import {ReactComponent as LevelIcon} from '../img/icon/levelIcon.svg';
import { makePlane } from '../Viewer/MeshManager';
import Modal, { ModalOptions } from '../Modal';
import ConstructionLineInfo from '../ConstructionLineInfo';
import { LineInfoType } from '../model/Project';
import DrawingManager2 from '../DrawingManager2';
import { Polygon } from "../Shape";
import { default as _ } from 'lodash';


export interface MySiteProps {

}

export interface MySiteState {
  //isCad: boolean;
  isCadastral: boolean;
  screenWidth: number;
  screenHeight: number;
  address: string;
  canvasAlpha: number;
  fileName: string;
  saveName: string;
  showBlock: boolean;
  siteArea: number,
  roadArea: number,
  vacancyOusideArea: number,
  vacancyInsideArea: number,
  elevationMax: number,
  elevationMin: number,
  settingID: string,
  userID: string,
  showModal: boolean,
  loadFile: boolean,
  fileUnit: Unit,
  showSettingModal: boolean,
  showAutoSetting: boolean,
  showSample: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
  infoSize: number,
  showSaveModal: boolean,
  isSaved: boolean,
  loadingPage: boolean,
  clickedField: ConverterField | null, 
  canvasMode: CanvasMode,
  showBlockInfo: boolean,
  field: {
    site: ConverterField[],
    road: ConverterField[],
    roadCenterLine: ConverterField[],
    vacancyInside: ConverterField[],
    vacancyOutside: ConverterField[],
    topography: ConverterField[],
  },
  errorLogs: any[];
  currentLog: string;
  is2D: boolean;
  openModal: boolean;
  modalOptions: ModalOptions,
  settingModalType: ConverterType,
  drawPolygonOnSaveModal: boolean,
  sceneTopPosition: THREE.Vector3,
  openSnack: boolean,
  snackMsg: React.ReactNode,
}

enum CanvasMode {
  CAD = 'CAD',
  MAP = 'MAP',
  VIEW_3D = 'VIEW_3D',
}


const earcut = require('earcut');
export class Scene extends Component<MySiteProps, MySiteState> {
  state: MySiteState = {
    //isCad: true,
    isCadastral: true,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    address: '',
    canvasAlpha: 50,
    fileName: '선택된 파일 없음',
    saveName: '',
    showBlock: false,
    siteArea: 0,
    roadArea: 0,
    vacancyOusideArea: 0,
    vacancyInsideArea: 0,
    elevationMax: 0,
    elevationMin: 0,
    settingID: '',
    userID: 'test@1011.co.kr',
    showModal: false,
    showAutoSetting: false,
    loadFile: false,
    fileUnit: Unit.Millimeters,
    showSettingModal: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    infoSize: 0,
    showSample: true,
    showSaveModal: false, // 사업영역 저장 모달
    isSaved: false, // 저장완료
    loadingPage: false,
    clickedField: null,
    canvasMode: CanvasMode.CAD,
    showBlockInfo: false,
    field: {
      site: [],
      road: [],
      roadCenterLine: [],
      vacancyInside: [],
      vacancyOutside: [],
      topography: [],
    },
    errorLogs: [],
    currentLog: 'ALL',
    is2D: true,
    openModal: false,
    modalOptions: {},
    settingModalType: ConverterType.mySite,
    sceneTopPosition: new THREE.Vector3(0),
    drawPolygonOnSaveModal: false,
    openSnack: false,
    snackMsg: "",
  };
  needInitialCamera = false; // camera 초기설정값 필요 여부

  mapManager = new DrawingManager2();
  sceneManager = new SceneManager();

  mount: HTMLDivElement | null = null;
  polygon2DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  DBTableName = 'platform-buildit-my-site-v2';
  S3BucketName = 'teneleven-platform-my-site-v2';
  fileData: string = '';
  areaBias = 1;
  makedAreaBias = false; 
  sumAllArea = 0; // 모든 면적 합
  parsingOutput: BlockParsingData = { buildings: [], fields: [], wrongBlocks: [], cadastralMap: [], wrongPolygons: [] };
  block: ConverterBlock[] = [];
  centerOfRoadWKT = '';
  settingData = new userSettingData(App.tempStage, App.session.email);

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log('test'),
    title: '제목'
  }

  errorList = new ErrorList();
  sitePolygon: Polygon[] = [];
  boundarySite: Polygon[] = [];
  topographyLine: Polygon[] = [];
  vacancyInside: Polygon[] = [];
  vacancyOutside: Polygon[] = [];
  roadSite: Polygon[] = [];
  corePolygon: Polygon[] = [];
  housePolygon: Polygon[] = [];
  


  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
    this.state.field.topography.forEach(topo => {
      this.updateInfoPosition(topo);
    })
  }

  make3DField = () => {
    // const geometry = new THREE.Geometry();
    // geometry.vertices.push(
    //   new THREE.Vector3(-1, -1,  1),  // 0
    //   new THREE.Vector3( 1, -1,  1),  // 1
    //   new THREE.Vector3(-1,  1,  1),  // 2
    //   new THREE.Vector3( 1,  1,  1),  // 3
    //   new THREE.Vector3(-1, -1, -1),  // 4
    //   new THREE.Vector3( 1, -1, -1),  // 5
    //   new THREE.Vector3(-1,  1, -1),  // 6
    //   new THREE.Vector3( 1,  1, -1),  // 7
    // );

    // geometry.faces.push(
    //   // 앞쪽
    //   new THREE.Face3(0, 3, 2),
    //   new THREE.Face3(0, 1, 3),
    //   // 오른쪽
    //   new THREE.Face3(1, 7, 3),
    //   new THREE.Face3(1, 5, 7),
    //   // 뒷쪽
    //   new THREE.Face3(5, 6, 7),
    //   new THREE.Face3(5, 4, 6),
    //   // 왼쪽
    //   new THREE.Face3(4, 2, 6),
    //   new THREE.Face3(4, 0, 2),
    //   // 상단
    //   new THREE.Face3(2, 7, 6),
    //   new THREE.Face3(2, 3, 7),
    //   // 하단
    //   new THREE.Face3(4, 1, 0),
    //   new THREE.Face3(4, 5, 1),
    // );
    // this.polygon3DGroup.add(new THREE.Mesh(geometry,  new THREE.MeshBasicMaterial({ color: new THREE.Color(1, 0, 0) })))

    // this.state.field.topography.forEach(topo => {
    //   const geometry = new THREE.Geometry();
    //       this.polygon3DGroup.add(fieldToTHREEGeo(topo));

 
    //   });




    // this.parsingOutput.fields.forEach(field => {
    //   let verts: THREE.Vector3[] = [];
    //   let fieldGeo = new THREE.Geometry();
    //   field.parts.forEach(part => {
    //     const verts = part.polygon.vertices;
    //     for (let i = 0; i < part.polygon.vertices.length - 1; i++) {
    //       fieldGeo.vertices.push(new THREE.Vector3(verts[i].x, 0, verts[i].y).applyMatrix4(field.renderGroup.matrixWorld));
    //     }
    //   })
    //   console.log(fieldGeo, 'field geop');
      
    //   let material = new THREE.MeshPhongMaterial({ color: '#ffffff' });
    //   group.add(new THREE.Mesh(fieldGeo, material));

    //   // l1 = { line: new THREE.Line3(lines[i].line.start, p2), thickness: 0.6, type: LineType.LT_OUTERWALL };

    //   // let verts = [];
    //   // verts.push(new THREE.Vector3(start.x, roomHeight, start.y));
    //   // verts.push(new THREE.Vector3(start.x, roomHeight + wallHeight, start.y));
    // })
   // this.polygon3DGroup.add(group)

  }


  updateInfoPosition = (field: ConverterField) => {
    let s = document.querySelector(`.TT${field.uuid}`) as HTMLInputElement;

    if (s) {
      s.style.visibility = 'hidden';
      if (this.state.showBlockInfo) {
        field.renderGroup.updateWorldMatrix(true, true);
        let matrixWorld = field.renderGroup.matrixWorld;

        field.parts.forEach(part => {

          let coords: jsts.geom.Coordinate[] = [];
          part.polygon.vertices.forEach(v => {
            let newV = v.clone().applyMatrix4(matrixWorld);
            coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
          })
          let geoFac = new jsts.geom.GeometryFactory();
          let linearRing = geoFac.createLinearRing(coords);

          //@ts-ignore
          let newPolygon = geoFac.createPolygon(linearRing, []).buffer(0);
          const interiorPoint = newPolygon.getInteriorPoint().getCoordinates();
          const center = new THREE.Vector3(interiorPoint[0].x, interiorPoint[0].y, 0);
          this.sceneManager.resizeCanvasBBox();
          let c = this.sceneManager.getScreenPosition(center);


          s.style.top = `${c.y}px`;
          s.style.left = `${c.x}px`;
          s.style.visibility = 'visible';
        })
      }
    }
  }

  componentWillMount = () => {
    document.addEventListener("keydown", (e) => {
      if (this.state.loadFile && e.key === "Escape") {
        this.setState({ clickedField: null });
        setBlockOpacity(this.parsingOutput.fields, 1);
      }
    });
  }

  componentDidMount = async () => { 
    this.mount!.appendChild(this.sceneManager.canvasElement);
    this.sceneManager.addObjectToScene(this.polygon2DGroup);    
    this.sceneManager.SceneInit();
    this.sceneManager.renderer.sortObjects = false;

    this.mapManager.createMap({
      ref: this.refs.map,
      cadastral: true,
      zoom: 17,
//        center: [centerInLL.x, centerInLL.y],
      maxZoom: 21,
      transitionOptions: {
        duration: 0,
        easing: "linear",
      },
      tileSpare: 1,
    });

    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';

    this.animate();
    await this.settingData.getDataFromDB();

    const r = await App.search({
      "table": this.DBTableName,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${App.session.email} AND deleted:false`
    });

    this.setState({
      userID: App.session.email,
      showSample: r.data.hits.total > 0 ? true : false,
    })
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize, false);
//    window.addEventListener("keyup", this.onKeyUp, false);
    //@ts-ignore
    if (this.props.location.state && this.props.location.state.file && this.props.location.state.file[0]) {
      //TODO this.load
      //@ts-ignore
      await this.loadDXFFileOutside(this.props.location.state.file[0]);
      window.addEventListener('keydown', (e) => {
        if (e.key === "F5") { // 새로고침시 파일 새로 열게
          //@ts-ignore
          this.props.location.state = undefined;
          //@ts-ignore
          this.props.history.push('/cad/mySiteblock');
        }
      })
    }

    this.recalculateArea = _.debounce(this.recalculateArea, 500);
  }

  componentDidUpdate = (previousProps: Readonly<MySiteProps>, previousState: Readonly<MySiteState>) => {
    
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      
      let aspect = width / height;

      let frustumSize = this.sceneManager.orthoCamera.right;
      // if (this.state.canvasAlpha !== 100 && !this.state.isCad) {
      //   frustumSize = this.getFrustumSizeWithMapSize();
      // }
      this.sceneManager.CameraFrustumResize(frustumSize, aspect);

      this.sceneManager.renderer.setSize(width, height);
      this.mapManager.map!.nMap.autoResize();
    }
    if (this.state.canvasMode === CanvasMode.MAP && this.state.canvasMode !== previousState.canvasMode) {
      this.mapManager.map!.addListener('zoom_changed', () => { this.naverMapChanged(true);});
      this.mapManager.map!.addListener('dragend', () => { this.naverMapChanged(true);});
      this.mapManager.map!.addListener('size_changed', () => {
        if (this.state.canvasMode !== CanvasMode.CAD && this.needInitialCamera) {
          this.naverMapChanged(true);
        } else {
          const center = new THREE.Vector3(0);
          const size = new THREE.Vector3(0);
          this.bbox.getCenter(center);
          this.bbox.getSize(size);
          let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
          if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
            const height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
            frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
          }
          const aspect = this.state.screenWidth / this.state.screenHeight;
          this.sceneManager.CameraFrustumResize(frustumSize, aspect);
          this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
          this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
          this.sceneManager.orthoCamera.zoom = 1;
        }
    });
    }

    if (previousState.canvasMode !== this.state.canvasMode || previousState.canvasAlpha !== this.state.canvasAlpha) {
      if (this.state.canvasMode === CanvasMode.CAD || this.state.canvasMode === CanvasMode.VIEW_3D) {
        this.sceneManager.setRendererAlpha(1);
      }
      else {
        this.sceneManager.setRendererAlpha(0); //this.state.canvasAlpha / 100);
      }
    }

    if (this.state.showBlock !== previousState.showBlock) {
      this.onWindowResize();  
      this.naverMapChanged();
    }

    if (this.state.canvasMode === CanvasMode.MAP && this.state.canvasMode !== previousState.canvasMode) {
      this.naverMapChanged();
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }
    if (this.state.loadFile && previousState.loadFile !== this.state.loadFile) {
  
      this.bbox.makeEmpty();
      this.state.field.site.length ? this.bbox.setFromObject(this.state.field.site[0].renderGroup) : this.bbox.setFromObject(this.polygon2DGroup);
      let center = new THREE.Vector3(0);
      let size = new THREE.Vector3(0);

      this.bbox.getCenter(center);
      this.bbox.getSize(size);

      let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
      if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
        let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
        frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
      }


      let aspect = this.state.screenWidth / this.state.screenHeight;
      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
    }
  }

  componentWillUnmount = () => {

    if (this.mount)
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }
  
  loadDXFFileOutside = async (file: any) => {
    if (file) {
      this.loadFilebyFile([file]);
    }
  }

  fitPolygonToMap = () => {
    const { x, y } = this.mapManager.map!.nMap.getCenter();
    const center = latlng2tm(new THREE.Vector2(x, y));        
    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
  }
  
  setCenterOfRoad = (value: string) => {
    this.centerOfRoadWKT = value;
  }

  onWindowResize = () => {
    if (window.innerWidth >= 1200) {
      this.setState({
        screenWidth: window.innerWidth - 640 * (this.state.showBlock ? 1 : 0), //- (this.state.loadFile ? 420 : 0),
        screenHeight: window.innerHeight + (this.state.loadFile ? - 122 : 0) - (this.state.errorLog ? 286 : 30) + (!this.state.loadFile ? 30 : 0),
      });
    }

    this.mapManager.map!.nMap.autoResize();
    this.setMapOnRenderGroup();
  }

  dataInitialize = () => {     
       
    Object.values(this.parsingOutput).forEach(value => {
      while (value.length > 0) {
        value.splice(0, 1);
      }
    })
    this.bbox = new THREE.Box3();    
    this.mapManager.clearDrawingManager();

    // clear all polygon
    
    this.polygon2DGroup.children = [];

    [...this.sitePolygon, ...this.roadSite, ...this.topographyLine, ...this.vacancyInside, ...this.vacancyOutside, ...this.boundarySite].forEach(polygon =>
      polygon !== null && polygon.remove());

    this.sitePolygon = [];
    this.roadSite = [];
    this.topographyLine = [];
    this.vacancyOutside = [];
    this.vacancyInside = [];
    this.boundarySite = [];

    this.errorList.resetError();
    
    this.setState({
      showBlock: false,
      clickedField: null,
      loadFile: false,
      isSaved: false,
      currentLog: "ALL",
      errorLog: false,
      errorSize: 0,
      warningSize: 0,
      infoSize: 0,
      canvasMode: CanvasMode.CAD,
      field: {
        site: [],
        road: [],
        roadCenterLine: [],
        vacancyInside: [],
        vacancyOutside: [],
        topography: [],
      },
      showBlockInfo: false,
      drawPolygonOnSaveModal: false,
    });
    this.mapManager.map!.nMap.setZoom(17);
    this.canvasModeChanged(CanvasMode.CAD);
}

  setMapOnRenderGroup = () => {
    let centerX = (this.bbox.max.x + this.bbox.min.x) / 2;
    let centerY = (this.bbox.max.y + this.bbox.min.y) / 2;
    
    const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
    this.mapManager.map!.setCenter([centerCoord.x, centerCoord.y]);
  }

  loadFilebyFile = async (file: any) => {    
    
    if (!file[0].name.endsWith('.dxf')) {
      this.setState({ loadingPage: false });
      this.setState({
        openModal: true,
        modalOptions: {
          title: "파일 업로드 실패",
          content: <div>dxf 파일을 올려주세요.</div>,
          negative: 'hidden',
          positive: () => { this.setState({ openModal: false}); },
        },
      })
      return;
    }
    this.setState({ loadingPage: true });
    
    this.dataInitialize();
    
    let data = await asyncFileRead(file);
    if (!data)
      return;
    this.fileData = data;
    
    let parsingError: {errorNameLayers: Array<any>, pointError: Array<any>} = {
      errorNameLayers: [],
      pointError: []
    };
    const layerOfPoint = new Set();
    this.block = blockParsing(data, this.parsingOutput, ConverterType.mySite, layerOfPoint);
    App.stage !== "prod" && console.log(this.parsingOutput);

    if (layerOfPoint.size) {
      this.errorList.addError(new SiteError(
        {
          title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
          msg: `${Array.from(layerOfPoint).join('/')}의 불필요한 Point 가 자동 삭제 되었습니다.`,
          id: [],
          type: ErrorType.Info,
        }));
    }


    const projectSiteWKT: any[] = [];
    this.parsingOutput.fields.forEach(f => {

      switch (f.typeName) {
        case FieldType.site:
          this.state.field.site.push(f);
          f.renderGroup.updateWorldMatrix(true, true);
          f.parts.forEach(p => {
            if (!p.unused) {
              const polygon = p.getJSTSPolygon(f.renderGroup.matrixWorld);
              const coords = polygon.getCoordinates();
              //@ts-ignore
              if (coords[0] !== coords[coords.length - 1] && polygon._geometries && polygon._geometries.length > 1) {
                this.errorList.addError(new SiteError({
                  type: ErrorType.Error,
                  title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
                  msg: `${f.name}의 폭이 충분하지 않습니다. 캐드에서 재설정 후 다시 진행하세요.`,
                  id: [f.uuid],
                  targetFields: [f],
                }))
              }
              else if (polygon && polygon.getCoordinates().length > 0) {
                projectSiteWKT.push(jstsPolygontoWKT(polygon));
              }
              //              projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(f.renderGroup.matrixWorld)));
            }
          })
          break;
        case FieldType.road:
          this.state.field.road.push(f);
          break;
        case FieldType.centerLineOfRoad:
          this.state.field.roadCenterLine.push(f);
          break;
        case FieldType.vacancyInside:
          this.state.field.vacancyInside.push(f);
          break;
        case FieldType.vacancyOutside:
          this.state.field.vacancyOutside.push(f);
          break;
        case FieldType.topography:
          this.state.field.topography.push(f);
          break;
      }
    })
    this.recalculateArea();
    
// //    let address = await getAddressByProjectSite(projectSiteWKT);
    if (projectSiteWKT.length > 0)
      try {
        this.setState({ address: await getAddressByProjectSite(projectSiteWKT) });        
      }
      catch {
        App.stage !== "prod" && console.log('get address error')
      }

    this.parsingOutput.fields.forEach((field) => {
      this.sumAllArea += field.calcArea;
    })

    //* 에러체크
    
    const {site, road, roadCenterLine} = this.state.field;
    if (checkSiteStructure({sites: site, roads: road, roadCenterLines: roadCenterLine }, this.parsingOutput.fields, this.errorList)) {
      checkSiteError(this.parsingOutput, ConverterType.mySite, this.errorList);
    }

    this.setState({
      errorLogs: this.errorList.getError(),
    }) 


    { 
      this.parsingOutput.fields.forEach((field: ConverterField) => {
  

        if (this.errorList.getErrorById(field.uuid).length > 0) {
          field.parts.forEach(p => {
            if (p.polygon) {
              switchLineDashedState(p.polygon.lineMesh.material, true);
            }
          })
        }
        this.polygon2DGroup.add(field.ErrorPolygonGroup);
        // field.parts.forEach(part=>{
        //   this.polygon2DGroup.add(part.errorPolygonGroup)
        // })
      })
  
    }
    this.parsingOutput.wrongPolygons.forEach(polygon => {
      this.parsingOutput.fields.length && this.parsingOutput.fields[0].renderGroup.add(polygon.lineMesh);
    });

    (this.parsingOutput.wrongBlocks as ConverterField[]).forEach(block => {
      this.polygon2DGroup.add(block.renderGroup);
    })
    this.setState({
      warningSize: this.errorList.getWarningSize(),
      errorSize: this.errorList.getErrorSize(),
      infoSize: this.errorList.getInfoSize(),
    })

    if (this.state.errorSize > 0 || this.state.warningSize > 0 || this.state.infoSize > 0) {
      this.setState({ errorLog : true});
    }
    
  
    {
      const renderOrder = [...this.parsingOutput.fields];
      renderOrder.sort((a, b) => {
        if (a.typeName === FieldType.topography) return -1;
        else if (b.typeName === FieldType.topography) return 1;
        else if (a.typeName === FieldType.road || a.typeName === FieldType.vacancyOutside) return -1;
        else if (b.typeName === FieldType.road || b.typeName === FieldType.vacancyOutside) return 1;
        else if (a.typeName === FieldType.centerLineOfRoad) return -1;
        else if (b.typeName === FieldType.centerLineOfRoad) return 1;
        else if (a.typeName === FieldType.site) return -1;
        else if (b.typeName === FieldType.site) return 1;
        else if (a.typeName === FieldType.vacancyInside) return -1;
        else if (b.typeName === FieldType.vacancyInside) return 1;    
        else return 1;
      })
      
      renderOrder.forEach(f => {
        this.polygon2DGroup.add(f.renderGroup);
      });
    }
    this.bbox.makeEmpty();
    this.state.field.site.length ? this.bbox.setFromObject(this.state.field.site[0].renderGroup) :  this.bbox.setFromObject(this.polygon2DGroup);
    let center = new THREE.Vector3(0);
    let size = new THREE.Vector3(0);
    
    this.bbox.getCenter(center);
    this.bbox.getSize(size);
    
    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
    let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
      frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
    }

    
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);    
    
    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;
      
    this.setState({loadingPage: false});
    this.setState({
      fileName: file[0].name.split('.dxf')[0],
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadFile: true,
      showBlock: true,
      clickedField: this.state.field.site.length > 0 ? this.state.field.site[0] : null,
      showAutoSetting: true,
      // isCad: true,
    }, () => {
      this.onWindowResize();
      if (this.state.field.site.length > 0)
      this.AutoAdd2Map();
    })
  }


  
  AutoAdd2Map = async () => {

    if (this.parsingOutput.fields.length > 0) {
      let sitePolygon = this.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      let unionPolygon = this.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      // let center = new THREE.Vector2(0);
      let RoadLatlngs: any[] = [];
      let SiteLatlngs: any[] = [];
      let centerRoadlatlngs: any[] = [];
      let outsideLatLngs: any[] = [];
      let insideLatLngs: any[] = [];
      let topographyLatLngs: any[] = [];

      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site && !f.unused) {
          sitePolygon = f.getUnionJstsPolygon();
          
          if (sitePolygon) {
            //@ts-ignore
            sitePolygon = sitePolygon.buffer(0);
            //@ts-ignore
            unionPolygon = f.getUnionJstsPolygon()!.buffer(0.1);
          }
          // let cPoint = sitePolygon.getCentroid();
          // center.set(cPoint.getX(), cPoint.getY());

          //@ts-ignore
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          SiteLatlngs = SiteLatlngs.concat(list);
        }
        else if (f.typeName === FieldType.road && !f.unused) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          RoadLatlngs = RoadLatlngs.concat(list);
        }
        else if (f.typeName === FieldType.vacancyInside && !f.unused) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          insideLatLngs = insideLatLngs.concat(list);
        }
        else if (f.typeName === FieldType.vacancyOutside && !f.unused) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          outsideLatLngs = outsideLatLngs.concat(list);
        }
        else if (f.typeName === FieldType.centerLineOfRoad && !f.unused) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          centerRoadlatlngs = centerRoadlatlngs.concat(list);
          f.parts.forEach(p => {
            this.centerOfRoadWKT = jstsPolygontoWKT(p.getJSTSPolygon(matrix));
          })
        }
        else if (f.typeName === FieldType.topography) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          topographyLatLngs = topographyLatLngs.concat(list);
        }
      })

      let siteError = false;
      this.state.field.site.forEach(site => {
        //@ts-ignore
        if (this.errorList.getErrorById(site.uuid).filter(log => log.type === "Error").length > 0)
          siteError = true;
      })
      
      let roadError = false;
      this.state.field.road.forEach(road => {
        //@ts-ignore
        if (this.errorList.getErrorById(road.uuid).filter(log => log.type === "Error").length > 0)
          roadError = true;
      })

      let vacancyInsideError = false;
      this.state.field.vacancyInside.forEach(vacancyInside => {
        //@ts-ignore
        if (this.errorList.getErrorById(vacancyInside.uuid).filter(log => log.type === "Error").length > 0)
          vacancyInsideError = true;
      })

      let vacancyOutsideError = false;
      this.state.field.vacancyOutside.forEach(vacancyOutside => {
        //@ts-ignore
        if (this.errorList.getErrorById(vacancyOutside.uuid).filter(log => log.type === "Error").length > 0)
          vacancyOutsideError = true;
      })


      //! error 일때 점선
      if (SiteLatlngs.length) {
        const naverSitePolygon = this.mapManager.addPolygon(SiteLatlngs, DrawingManager2.DrawingOption.PROJECT_SITE, false);
        this.sitePolygon.push(naverSitePolygon);
      }
      if (RoadLatlngs.length) {
        const naverRoadPolygon = this.mapManager.addPolygon(RoadLatlngs, DrawingManager2.DrawingOption.ROAD_SITE, false);
        this.roadSite.push(naverRoadPolygon);
      }
      if (insideLatLngs.length) {
        const naverVacancyInside = this.mapManager.addPolygon(insideLatLngs, DrawingManager2.DrawingOption.VACANCY_INSIDE, false);
        this.vacancyInside.push(naverVacancyInside);
      }
      if (outsideLatLngs.length) {
        const naverVacancyOutside = this.mapManager.addPolygon(outsideLatLngs, DrawingManager2.DrawingOption.VACANCY_OUTSIDE, false);
        this.vacancyOutside.push(naverVacancyOutside);
      }
      if (topographyLatLngs.length) {

        const naverTopology = this.mapManager.addPolygon(topographyLatLngs, DrawingManager2.DrawingOption.TOPOGRAPHY_LINE, false);      
        this.topographyLine.push(naverTopology);
      }
      // let centerInLL = tm2latlng(center);
      // this.mapManager.setMapCenter(centerInLL.x, centerInLL.y);

      this.parsingOutput.fields.forEach(f => {
        if (f.typeName !== FieldType.vacancyInside && f.typeName !== FieldType.topography && f.getUnionJstsPolygon() && unionPolygon) {
          //@ts-ignore
          unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
        }
      })
      
      if (centerRoadlatlngs.length === 0) {
        try {
          this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
          centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
          //* test code
          //@ts-ignore
          let coordinates = wkx.Geometry.parse(this.centerOfRoadWKT).toGeoJSON().coordinates; // 두개
          let newC = coordinates.map((coords: any) => {
            return coords.map((coord: any) => {
              return tm2latlng(new THREE.Vector2(coord[0], coord[1]));
            })
          })
          const boundaryLine = this.mapManager.addPolygon(newC, DrawingManager2.DrawingOption.BOUNDARY_SITE, false);
          this.boundarySite.push(boundaryLine);
        }
        catch (e) {
          App.stage !== "prod" && console.log('auto add error', e)
        }

        // this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
        // centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
      }
      else {
        const boundaryLine = this.mapManager.addPolygon(centerRoadlatlngs, DrawingManager2.DrawingOption.BOUNDARY_SITE, false);
        this.boundarySite.push(boundaryLine);
      }
      let centerX = (this.bbox.max.x + this.bbox.min.x) / 2;
      let centerY = (this.bbox.max.y + this.bbox.min.y) / 2;
      
      const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
      this.mapManager.map!.setCenter([centerCoord.x, centerCoord.y]);

      if (sitePolygon) {
        const splited = this.state.field.site[0].name.split('_');
        let area = 0;
        if (splited.length === 2 && !isNaN(Number(splited[1]))) area = Number(splited[1]);
        else if (splited.length === 1) area = Number(sitePolygon.getArea().toFixed(4));
        this.setState({
          siteArea: area,//Number(sitePolygon.getArea().toFixed(2)),
        })
      }
    }
  }
  

  saveInputBlocks = async () => {
  
  try{
    let fn = this.state.saveName === '' ? 'my_site' : this.state.saveName;
    
    let { globalId: globalID, userId: privateID } = await incrementCounter("my_site_id", this.state.userID);

    let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;
    let imageName = `img_large.png`;
    let shapeInfoName = 'shapeInfo.json';
    let date = new Date().toISOString();
    
    saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

    let projectSiteWKT: string[] = [];
    let roadSiteWKT: string[] = [];
    let vacancyInsideWKT: string[] = [];
    let vacancyOutsideWKT: string[] = [];
    //let centerOfRoadWKT: string[] = []; // ?
    let topographyLines: { polygon: string[], height: number }[] = [];
    let topographyLinesPlatform: { polygon: string[], height: number }[] = [];

    this.parsingOutput.fields.forEach(field => {
      field.parts.forEach(part => {
        if (part.polygon) 
          part.polygon.lineMesh.material.opacity = 1;
      })  
    })
    this.parsingOutput.wrongPolygons.forEach(polygon => {
      polygon.lineMesh.visible = false;      
    })
    // wrongPolygon을 renderGroup에서 제외
    this.parsingOutput.fields.length > 0 && this.parsingOutput.fields[0].renderGroup.children.forEach(c => {
      if (c.type !== 'Group') this.parsingOutput.fields[0].renderGroup.remove(c);
    });

    this.parsingOutput.fields.forEach(f => {
      let matrix = f.renderGroup.matrixWorld;
  
      if (f.typeName === FieldType.site && !f.unused) {
        f.parts.forEach(p => {
          if (!p.unused)
          projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.road && !f.unused) {
        f.parts.forEach(p => {
          if (!p.unused)
          roadSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.vacancyInside && !f.unused) {
        f.parts.forEach(p => {
          if (!p.unused)
          vacancyInsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.vacancyOutside && !f.unused) {
        f.parts.forEach(p => {

          if (!p.unused)
          vacancyOutsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.topography && !f.unused) {
        let topographyWKT: string[] = [];

        f.parts.forEach(p =>{

          if (!p.unused)
            topographyWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
        topographyLinesPlatform.push({ polygon: [topographyWKT[0]], height: f.getHeight() });
        topographyLines.push({ polygon: [...topographyWKT], height: f.getHeight() })
      }
    })

    // if (projectSiteWKT.length === 0) {
    //   this.showModal('알림', ['사업영역이 없습니다.'], buttonNum.oneButton, () => console.log('saving'));
    //   return;
    // }

    // if (roadSiteWKT.length === 0 || this.centerOfRoadWKT.length === 0) {
    //   this.showModal('알림', ['도로영역이사 인접대지 경계선이 있어야 합니다.'], buttonNum.oneButton, () => console.log('saving'));
    //   return;
    // }

    // this.showModal('알림', ['나의 사업영역을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));

    let captureBbox = new THREE.Box3();
    captureBbox.expandByObject(this.state.field.site.length ? this.state.field.site[0].renderGroup : this.polygon2DGroup);
   
    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    
    //image 
    saveDataToS3(lImgBuf, S3SavePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, S3SavePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, S3SavePath, 'img_small.png', 'image/png');

    let shapeInfo: shapeInfoData = {
      boundaryLine: [],
      projectSite: projectSiteWKT,
      projectSiteArea: this.state.siteArea,
      projectSiteRoad: [],
      road: roadSiteWKT,
      centerOfRoad: [this.centerOfRoadWKT],
      setbackLineApartment: [],
      setbackLineMultiHouse: [],
      setbackLineOfficetel: [],
      setbackLineRowHouse: [],
      skylineCircle: [],
      skylineLine: [],
      vacancyInsie: vacancyInsideWKT,
      vacancyOutsie: vacancyOutsideWKT,
      topographyLines: topographyLines,//topographyWKT,//[],
    }
    App.stage !== "prod" && console.log(shapeInfo);
    saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');

    let dbItem: siteStruct = {
      stage: App.tempStage,
      global_id: globalID,
      user_id: privateID,
      name: fn,
      email: this.state.userID,
      address: await getAddressByProjectSite(projectSiteWKT),
      project_site_area: this.state.siteArea,
      project_site: projectSiteWKT,
      road_site_area: this.state.roadArea,
      road_site: roadSiteWKT,
      vacancy_outside_area: this.state.vacancyOusideArea,
      vacancy_outside: vacancyOutsideWKT,
      vacancy_inside_area: this.state.vacancyInsideArea,
      vacancy_inside: vacancyInsideWKT,
      centerOfRoad: [this.centerOfRoadWKT],
      img_path: `s3://${S3SavePath}/${imageName}`,
      shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
      topography_lines: topographyLinesPlatform,//topographyWKT,
      created_at: date,
      modified_at: date,
      deleted: false,
    };
    App.stage !== "prod" && console.log(dbItem, 'dbItem');

    App.stage !== "prod" && console.log(dbItem);
    saveDataToDynamoDB(dbItem, this.DBTableName);
    this.setState({ isSaved: true });
    this.setState({
      openModal: true,
      modalOptions: {
        title: "나의 사업영역 저장 완료",
        content: <div>'{fn}'의 저장이 완료되었습니다.</div>,
        positive: () => {
          window.location.replace("/cad/mySiteBlock");
        },
        negative: () => {
          window.location.href = "/myPage/file/mySite";
        },
        negativeTitle: "나의 사업영역 바로가기",
      }
    })
      //  this.showModal('알림', ['나의 사업영역을 저장했습니다.'], buttonNum.oneButton, () => console.log('save done'));
      return true;
    }
  catch (e) {
    // this.showModal('알림', ['나의 사업영역을 저장에 실패 했습니다.'], buttonNum.oneButton, () => {
    //   App.stage !== "prod" && console.log('save fail', e)
    // }
    // );
    return false;

  }
  }
  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // 버튼클릭해서 열기 창으로 열기 
    this.needInitialCamera = false;
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }


  recalculateArea = () => {

    let siteArea = 0;
    let roadArea = 0;
    let vacancyInsideArea = 0;
    let vacancyOusideArea = 0;
    let elevationMax = 0;
    let elevationMin = 100000000000;

    
    this.parsingOutput.fields.forEach(f => {
      if (f.typeName === FieldType.site) {
        this.errorList.delAreaError(f.uuid);
        if (Number(f.calcArea.toFixed(4)) !== Number(f.getArea().toFixed(4))) { // .toFixed(2)
          if (f.parts.length > 0 && f.parts[0].shape && f.parts[0].polygon && !f.parts[0].polygon.hasCurve) {
            const error = new SiteError(
              {
                title: `[면적 경고] 저장 시 입력 면적으로 반영됩니다.`,
                msg: `
              ${f.name}의 사용자가 입력한 면적과 실제 폴리곤 면적이 서로 상이합니다.
                폴리곤 전체 입력 면적: ${f.getArea().toFixed(4)}㎡, 계산 면적: ${f.calcArea.toFixed(4)}㎡, 차이: ${Math.abs(Number(f.getArea().toFixed(4)) - Number(f.calcArea.toFixed(4))).toFixed(4)}㎡`,
                id: [f.uuid],
                type: ErrorType.Warning,
                targetFields: [f],
              });
            this.errorList.addError(error);
          }
        }
      }
      

      switch (f.typeName) {
        case FieldType.site:
          siteArea += f.getArea();
          break;        
        case FieldType.road:
          roadArea += f.getArea();
          break;
        case FieldType.vacancyInside:
          vacancyInsideArea += f.getArea();
          break;
        case FieldType.vacancyOutside:
          vacancyOusideArea += f.getArea();
          break;
        case FieldType.topography:
          if (!f.unused) {
            if (elevationMax < f.getHeight()) {
              elevationMax = f.getHeight();
            }
            if (elevationMin > f.getHeight()) {
              elevationMin = f.getHeight();
            }  
          }
          break;
        default:
          break;
      }
    })
    if (elevationMin === 100000000000) elevationMin = 0;
    this.setState({
      elevationMax,
      elevationMin,
    });

    const error = this.errorList.getErrorSize();
    const warning = this.errorList.getWarningSize();
    const info = this.errorList.getInfoSize();

    if (this.state.clickedField === null) this.setState({ errorLogs: this.errorList.getError() });
    else if (this.state.clickedField.uuid) this.setState({ errorLogs: this.errorList.getErrorById(this.state.clickedField.uuid) });
    this.setState({
      siteArea: siteArea,
      roadArea: roadArea,
      vacancyInsideArea: vacancyInsideArea,
      vacancyOusideArea: vacancyOusideArea,
      errorSize: error,
      warningSize: warning,
      errorLog: error + warning + info > 0 ? true : false,
      errorLogs: this.errorList.getError(),
     
    })
  
//    this.errorList.getError();
  }

  showLayerList = (id: string) => {
    this.setState({
      showBlock: true,
      settingID: id,
    })
  }

  objectRotate(degree: number) {
    this.polygon2DGroup.rotateZ(THREE.MathUtils.degToRad(degree));
  }

  getFrustumSizeWithMapSize = () => {
//    let mapProj = this.mapManager.getMapProjectionData();
    const projection = this.mapManager.map!.nMap.getProjection();
    let rect = this.mount!.getBoundingClientRect();
    let p1 = projection.fromPageXYToCoord(NaverPoint(0, rect.top));
    let p2 = projection.fromPageXYToCoord(NaverPoint(this.state.screenWidth, rect.top));
    return projection.getDistance(p1, p2) / 2;
  }

  resizeCanvasResolution = () => {
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    let width = this.getFrustumSizeWithMapSize();
    width = this.areaBias !== 1 ? width / this.areaBias : width;
    this.sceneManager.CameraFrustumResize(width / this.areaBias, aspect);
  }

  naverMapChanged = (fitMap: boolean = false) => {     
    this.areaBias = 1;
    this.makedAreaBias = false;
    this.mapManager.map!.nMap.autoResize();   
  
    if (fitMap) {
      this.resizeCanvasResolution(); // 지도 zoom 비율맞추기    
      this.fitPolygonToMap(); //지도 폴리곤 센터 맞추기  
    }
  }

  setErrorByType = (type: ErrorType) => {
    this.setState({
      errorLogs: this.errorList.getErrorByType(type)
    });
  }

  canvasModeChanged = (canvasMode: CanvasMode) => { //(_isCAD: boolean) => {
    
    switch (canvasMode) {
      case CanvasMode.CAD:
        const center = new THREE.Vector3(0)
        this.bbox.getCenter(center);
        let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
        if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
          let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
          frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
        }
        
        let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
        this.sceneManager.CameraFrustumResize(frustumSize, aspect);
        this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
        this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
        this.sceneManager.orthoCamera.zoom = 1;

        this.sceneManager.getControl().enableKeys = true;
        this.sceneManager.canvasElement.style.pointerEvents = '';
        (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
        break;
      case CanvasMode.MAP:
        this.needInitialCamera = true;
        this.mapManager.map!.nMap.autoResize();
        this.sceneManager.getControl().enableKeys = false;
        this.sceneManager.canvasElement.style.pointerEvents = 'none';
        (this.refs.map as HTMLDivElement).style.visibility = 'visible';
        //!this.mapManager.createDrawingManager();
        this.resizeCanvasResolution();
        
        break;
      case CanvasMode.VIEW_3D:
        // this.polygon3DGroup.children.forEach(child => {
        //   child.visible = true;
        // });
        this.sceneManager.getControl().enableKeys = true;
        this.sceneManager.canvasElement.style.pointerEvents = '';
        (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
  
        break;
    }
    this.setState({canvasMode:canvasMode})

  }

  setIsFocus = (type: LineInfoType | null) => {
   
      switch (type) {
        case "project_site":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.site, 1);
          break;
        case "road":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.road, 1);
          break;
        case "center_of_road":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.roadCenterLine, 1);
          break;
        case "vacancy_outside":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.vacancyOutside, 1);
          break;
        case "vacancy_inside":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.vacancyInside, 1);
          break;
        case "topography":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.topography, 1);
          break;
        case null:
          setBlockOpacity(this.parsingOutput.fields, 1);
          break;
      }
    
    if (this.state.canvasMode === "MAP") {
      this.setPolygonOpacity(type);
    }
  };

  setPolygonOpacity = (type: LineInfoType | null) => {
    const projectSite = {
      data: this.sitePolygon,
      normalOption: DrawingManager2.DrawingOption.PROJECT_SITE,
      fadeOption: {
        strokeColor: "rgb(255, 51, 51, 0.4)",
        strokeStyle: DrawingManager2.DrawingOption.PROJECT_SITE.strokeStyle,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.PROJECT_SITE,
      type: "project_site",
    };
    const boundarySite = {
      data: this.boundarySite,
      normalOption: DrawingManager2.DrawingOption.BOUNDARY_SITE,
      fadeOption: {
        strokeColor: "rgba(255, 255, 255, .4)",
      },
      focusOption: DrawingManager2.DrawingOption.BOUNDARY_SITE,
      type: "center_of_road",
    };

    const topographyLine = {
      data: this.topographyLine,
      normalOption: DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
        strokeColor: "rgba(255, 255, 255, .4)",
      },
      focusOption: {
        ...DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
        strokeColor: "#0085FF",
        strokeWeight: 2.5,
      },
      type: "topography",
    };
    const vacancyInside = {
      data: this.vacancyInside,
      normalOption: DrawingManager2.DrawingOption.VACANCY_INSIDE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.VACANCY_INSIDE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.VACANCY_INSIDE,
      type: "vacancy_inside",
    };
    const vacancyOutside = {
      data: this.vacancyOutside,
      normalOption: DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
      type: "vacancy_outside",
    };
    const roadSite = {
      data: this.roadSite,
      normalOption: DrawingManager2.DrawingOption.ROAD_SITE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.ROAD_SITE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0
      },
      focusOption: DrawingManager2.DrawingOption.ROAD_SITE,
      type: "road",
    };
    const coreData = {
      data: this.corePolygon,
      normalOption: DrawingManager2.DrawingOption.CAD_CORE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.CAD_CORE,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.CAD_CORE,
      type: "core",
    };
    const houseData = {
      data: this.housePolygon,
      normalOption: DrawingManager2.DrawingOption.CAD_HOUSE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.CAD_HOUSE,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.CAD_HOUSE,
      type: "house",
    };


    let fadeArray: Array<any> = [projectSite, boundarySite, topographyLine, vacancyInside, vacancyOutside, roadSite, coreData, houseData];
    let focusArray: Array<any> = [];
    let index: number | undefined;

    if (type !== null) {
      index = fadeArray.findIndex(item => item.type === type);
      if (index > -1) {
        focusArray = fadeArray.splice(index, 1);
        fadeArray.map((fades) => {
          fades["data"]!.length !== 0 &&
            fades["data"].map((fade: any) => {
              fade !== undefined && fade.setOptions(fades.fadeOption);
            });
        });
        focusArray.length !== 0 &&
          focusArray[0]["data"].map((focusData: any) => {
            focusData !== undefined && focusData.setOptions(focusArray[0].focusOption);
          });  
      }
    }  
    else {
      // type === null: 범례에서 focus되지 않고 있는 경우
      index = undefined;
      focusArray = [];

      fadeArray.map((fades) => {
        fades["data"]![0] !== null &&
          fades["data"].map((allData: any) => {
            allData !== undefined && allData.setOptions(fades.normalOption)
          });
      });
    }
  };

  mapTypeChanged = (isCadastral: boolean) => {
//    this.mapManager.changeMapType(isCadastral);
    this.mapManager.setCadastralLayer(isCadastral);
    this.setState({ isCadastral: isCadastral });
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  getCurrentSelected = () => {
    return null;
  }

  showSaveModal() {
    this.setState({ 
      showSaveModal: false,
      isSaved: false,
    }); 
  }
  
  changeSaveFileName = (name: string) => {
    this.setState({ saveName: name });
  }

  reset = () => {
    this.setState({
      loadFile: false,
    });
    this.setState({
      screenWidth: window.innerWidth, //- (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 60,
    });
    this.dataInitialize();
  }

  // addNewField = (fields: Field[], type: FieldType) => {
  //   let newField = new Field(fields.length.toString(), type);
  //   let uuid = newField.id;

  //   if (type === FieldType.site) {
  //     if (fields.length > 0) {
  //       this.showModal('알림', ['사업영역은 하나만 허용합니다!!!!'], buttonNum.oneButton, () => console.log('enable only one site layer'));
  //       return;
  //     }
  //   }

  //   fields.push(newField);
  //   this.errorLogs.push(newField.ErrorLogBlock);
  //   this.polygon2DGroup.add(newField.ErrorPolygonGroup);

  //   this.setState({
  //     settingID: uuid,
  //     showBlock: false,
  //   })

  //   this.recalculateArea();
  // }
  openModal = (modalOptions: ModalOptions) => {
    this.setState({ 
      openModal: true,
      modalOptions,
    })
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  handleSettingModalType = (type: ConverterType) => {
    this.setState({ settingModalType: type })
  }


  render = () => {    
    return (
      <div className="buildit-template">
        {
          this.state.openModal &&
          <Modal
            open={this.state.openModal}
            color="DARK"
            type="MOBILE"
            negative={this.state.modalOptions.negative}
            positive={this.state.modalOptions.positive}
            title={this.state.modalOptions.title}
            positiveTitle={this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions.content}
            negativeTitle={this.state.modalOptions.negativeTitle}
          />

        }
        <CadConverterModal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></CadConverterModal>
        <LoadingPage show={this.state.loadingPage} loadingMsg="사업영역 파일을 불러오는 중입니다." />
        <MySiteBlockSaveModal
          DBTableName={this.DBTableName}
          converterType={ConverterType.mySite}
          showModal={this.state.showSaveModal}
          userId={this.state.userID}
          errorSize={this.state.errorSize}
          onShowSaveModal={() => this.showSaveModal()}
          recalculateArea={() => this.recalculateArea()}
          siteArea={{
            siteArea: `${this.state.siteArea.toFixed(2)}㎡`, // .toFixed(2)
            // roadArea: `${this.state.roadArea.toFixed(4)}㎡`, // .toFixed(2)
            // vacancyOutsideArea: `${this.state.vacancyOusideArea.toFixed(4)}㎡`, // .toFixed(2)
            // vacancyInsideArea: `${this.state.vacancyInsideArea.toFixed(4)}㎡`, // .toFixed(2)
            // elevationCnt: this.parsingOutput.fields.filter(f => f.typeName === FieldType.topography).length,
            address: this.state.address,
            elevationMax: `${this.state.elevationMax.toFixed(2)}m`,
            elevationMin: `${this.state.elevationMin.toFixed(2)}m`,
          }}
          fileName={this.state.saveName}
          onSave={async () => {
            const result = await this.saveInputBlocks();
            return result;
          }}
          isSaved={this.state.isSaved}
          parsingOutput={this.parsingOutput}
          onChangeFileName={this.changeSaveFileName}
          showModalAlert={this.showModal}
          centerOfRoad={this.setCenterOfRoad}
          hasDrawing={this.state.drawPolygonOnSaveModal}
          handleDrawing={(value: boolean) => this.setState({drawPolygonOnSaveModal: value})}
        />
        <Setting
          handleConverterType={this.handleSettingModalType}
          converterType={this.state.settingModalType} closeModal={() => this.setState({ showSettingModal: false })} open={this.state.showSettingModal} settingData={this.settingData} />
        <ConverterHeader
          type={ConverterType.mySite}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={() => {
            this.reset();
          }}
          closeModal={this.closeModal}

          isFileOpen={this.state.loadFile}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          infoSize={this.state.infoSize}
          isLatest={true}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          saveFile={() => {
       
              this.setState({ showSaveModal: true });      
            // let showModal = false;
            // for (let i = 0; i < this.state.field.topography.length; i++) {
            //   if (this.state.field.topography[i].getHeight() >= 500) {
            //     showModal = true;
            //     break;
            //   }
            // }

            // if (showModal) {
            //   this.openModal(
            //     {
            //       title: "사업영역 저장 알림",
            //       positiveTitle: "확인",
            //       negativeTitle: "취소",
            //       content: <div>대지 레벨 입력 값이 권장 범위를 벗어났습니다.<br />실제 프로젝트 생성 시 사업영역 대지레벨 기준으로 범위가 제한될 수 있습니다.<br />
            //         그래도 입력하시겠습니까?
            //       </div>,
            //       positive: () => { this.setState({ openModal: false, showSaveModal: true }); },
            //       negative: () => { this.setState({ openModal: false }) }
            //     });
            // }
            // else this.setState({ showSaveModal: true });
          }}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.openModal}
        />
        <div className={`MainBody `}>
          <div className="siteInfo">
            {this.state.field.topography.map((topo) => (
              <div className={`fieldText TT${topo.uuid}`}>
                <LevelIcon className="icon levelIcon"></LevelIcon>
                <div className={`font font-14px`}>{topo.getHeight()}</div>
              </div>
            ))}
          </div>
          {
            this.state.loadFile &&
            <div className="information">
              {/* .toFixed(2) */}
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">대지영역면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(this.state.siteArea.toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">인접도로면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(this.state.roadArea.toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">공지영역면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(this.state.vacancyOusideArea.toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">배치제한영역면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(this.state.vacancyInsideArea.toFixed(2)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">대지레벨최저높이</div>
                <div className="inforValue font font-emphasis font-14px">{Number(this.state.elevationMin.toFixed(2)).toLocaleString()}m</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">대지레벨최고높이</div>
                <div className="inforValue font font-emphasis font-14px">{Number(this.state.elevationMax.toFixed(2)).toLocaleString()}m</div>
              </div>
              <div></div>
              <div className="fileInfo">
                <span className={`dxfIcon ${this.state.loadFile && "loaded"}`}>
                  <span className={`text ${this.state.loadFile && "loaded"}`}>dxf</span>
                </span>
                <span className={`fileName ${this.state.loadFile && "loaded"}`}>{(this.state.loadFile && `${this.state.fileName}.dxf`) || "선택된 파일이 없습니다."}</span>
                {/* <span className={`layerToggle ${this.state.loadFile && 'loaded'}`} onClick={() => this.state.loadFile && this.setState({ showBlock: !this.state.showBlock })}>블록 보기</span> */}
              </div>
            </div>
          }


          <div className="RenderView">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.openSnack}
              onClose={() => { this.setState({ openSnack: false }) }}//{this.handleSnackClose}
              className="snack-bar"
              autoHideDuration={5000}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={this.state.snackMsg}
            />
            <div className="Scene">
              <div
                className="Canvas"
                ref={(mount) => {
                  this.mount = mount;
                }}
                onClick={() => {
                  this.setState({ clickedField: null });
                  setBlockOpacity(this.parsingOutput.fields, 1);
                }}

              >
                <DropFileBox functionForButton={this.loadDXFFile} functionForDrop={this.loadFilebyFile} loadFile={this.state.loadFile} type={ConverterType.mySite} showSample={this.state.showSample}
                  isBlockVer={true} />
                <div ref="map" style={{ width: `${this.state.screenWidth}px`, height: `${this.state.screenHeight}px`, position: "absolute" }} />
                {
                  //this.state.canvasMode === "MAP" && 
                  this.state.loadFile &&
                  <ConstructionLineInfo
                    title={["나의 사업영역"]}
                    darkMode={this.state.canvasMode === "MAP" ? false : true}
                    //                  isCadConverter={this.state.canvasMode === "MAP" ? false : true}

                    className="construction-line-info"
                    typeList={[["vacancy_inside", "project_site", "center_of_road", "road", "vacancy_outside", "topography"]]}
                    isCadConverter={true}
                    hasVisibleIcon={false}
                    setIsFocus={this.setIsFocus}
                    fieldBlocks={this.state.field}
              
                    // header={<div></div>}
                    //  handleVisible={(isVisible: ConverterType.mySite | ConverterType.myType) => this.handleVisible(isVisible)}
                    isCollapsible={true}

                  />
                }
                {/* width: "1500px", height: "593px", position: "absolute"}}/>
                 */}
                {/* `100%`, height: `100%`, position: "absolute" }} /> */}

                {this.state.loadFile && (
                  <Button
                    className="btn bg-navy btn-primary fold-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ showBlock: !this.state.showBlock });
                    }}
                  >
                    {this.state.showBlock ? <NavigateNext className="icon icon-next" /> : <ChevronLeft className="icon icon-next" />}
                  </Button>
                )}

                <div className={`toolBar ${!this.state.loadFile && "hidden"} ${this.state.showBlock ? "" : "showBlock"}`}>
               
                  <div className="rightButtons">
                    <div className="switchTabs">
                      <Tooltip place="left" arrowOn={false} msg="캐드 도면으로 확인">
                        <Button
                          className={`switchButton ${this.state.canvasMode === CanvasMode.CAD && "enable"}`}
                          onClick={(e) => {
                            this.canvasModeChanged(CanvasMode.CAD);
                          }}
                        >
                          CAD
                        </Button>
                      </Tooltip>
                      <Tooltip place="left" arrowOn={false} msg="지도에서 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.MAP && "enable"}`} onClick={(e) => this.canvasModeChanged(CanvasMode.MAP)}>
                          MAP
                        </Button>
                      </Tooltip>
                      {/* <Tooltip place="left" arrowOn={false} msg="3D 모델로 확인">
                        <Button
                          className={`switchButton ${this.state.canvasMode === CanvasMode.VIEW_3D && "enable"}`}
                          onClick={(e) => {
                            this.Switch2D3D(true);
                            this.canvasModeChanged(CanvasMode.VIEW_3D);
                          }}
                        >
                          3D VIEW
                        </Button>
                      </Tooltip> */}
                    </div>

                    <div className={`switchTabs ${this.state.canvasMode !== CanvasMode.MAP && "hidden"}`}>
                      <Button className={`switchButton ${this.state.isCadastral && "enable"}`} onClick={(e) => this.mapTypeChanged(true)}>
                        지적도
                      </Button>
                      <Button className={`switchButton ${!this.state.isCadastral && "enable"}`} onClick={(e) => this.mapTypeChanged(false)}>
                        지도
                      </Button>
                    </div>
                    <Tooltip place="left" msg="블록 정보 보기" arrowOn={false}>
                      <Button
                        className={`btn bg-navy btn-secondary infoButton ${this.state.showBlockInfo ? "active" : ""}`}
                        onClick={() => {
                          this.setState({ showBlockInfo: !this.state.showBlockInfo });
                        }}
                      >
                        <Info className={`icon info-icon font font-emphasis ${this.state.showBlockInfo ? "font-special" : ""}`} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <ErrorLogBlock
                show={this.state.errorLog}
                closeLog={() => {
                  this.setState({
                    errorLog: !this.state.errorLog,
                  });
                }}
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                confirmSize={this.state.infoSize}
                errorList={this.state.errorLogs}
                showErrorByType={this.setErrorByType}
                currentLog={
                  this.parsingOutput.fields && this.parsingOutput.fields.find((f) => f.uuid === this.state.currentLog)
                    ? this.parsingOutput.fields.find((f) => f.uuid === this.state.currentLog)!.name
                    : "ALL"
                }
                closeCurrentLog={() => {
                  this.setState({ errorLogs: this.errorList.getError() });
                  this.setState({ currentLog: "ALL" });
                }}
                // currentLog={this.state.currentLog === "ALL" ? "ALL"

                // : this.parsingOutput.fields.find(f => f.uuid === this.state.currentLog).length ? .name}
                allFields={this.parsingOutput.fields.concat(this.parsingOutput.wrongBlocks as ConverterField[])}
                converterType={ConverterType.mySite}
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && "hidden"}`}>
              <div className={`wrapBlockList ${this.state.showBlock ? "" : "display-none"}`}>
                <div className="header">
                  <div className="left-side">
                    <HierarchyIcon className="icon hierarchy-icon" />
                    <span className="text">목록</span>
                  </div> 
                </div>
                <div className="description">
                  {this.parsingOutput.fields.concat(this.parsingOutput.wrongBlocks as ConverterField[]).map((f) => (
                    <div
                      id={f.name}
                      className={`font field-item ${this.state.clickedField && f.name === this.state.clickedField.name ? "selected font-special" : ""}`}
                      onMouseOver={() => {
                        setBlockOpacity(this.parsingOutput.fields, 0.2);
                        setBlockOpacity(this.parsingOutput.wrongBlocks, 0.2);

                        setBlockOpacity([f], 1);
                      }}
                      onMouseLeave={() => {
                        if (this.state.clickedField !== null) { 
                          setBlockOpacity(this.parsingOutput.fields, 0.2);
                          setBlockOpacity(this.parsingOutput.wrongBlocks, 0.2);
                          setBlockOpacity([this.state.clickedField], 1);
                        } 
                        else brightenAllField(this.parsingOutput.fields);
                      }}
                      onClick={(e) => {
                        const find = this.parsingOutput.fields.find((f) => f.name === e.currentTarget.id);
                        if (find) {
                          this.setState({
                            clickedField: this.parsingOutput.fields.filter((f) => f.name === e.currentTarget.id)[0],
                          });
                        }
                      }}
                    >
                      <div className="text">
                        <BlockIcon className="block-icon" />
                        {f.name}
                      </div>
                      <Tooltip msg="오류 정보 확인" arrowOn={false} place={"right"}>
                        <Button
                          id={f.uuid}
                          className={`btn bg-navy btn-primary icon-wrap ${this.errorList.getErrorById(f.uuid).length === 0 ? "disabled" : ""}
                          ${this.state.currentLog === f.uuid ? "active" : ""}
                          `}
                          onClick={(e) => {
                            //  e.stopPropagation();
                            if (this.state.currentLog === f.uuid) {
                              this.setState({
                                errorLogs: this.errorList.getError(),
                                currentLog: "ALL",
                              });
                            } else {
                              this.setState({
                                errorLogs: this.errorList.getErrorById(f.uuid),
                                currentLog: f.uuid,
                              });
                            }
                          }}
                        >
                          <SpeakerNotes className={`icon speaker-icon ${this.errorList.getErrorById(f.uuid).length === 0 ? "disabled" : ""}`} />
                        </Button>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>

              <div className={`functionalLayout ${this.state.showBlock ? "" : "display-none"}`}>
                <div className="header">
                  <div className="wrapper">
                    <div className="wrap-icon m-l-md">
                      <Info className="icon info-icon font font-emphasis" />
                    </div>
                    <span className="font font-primary">속성</span>
                  </div>
                </div>
                <div className={`description ${this.state.showBlock ? "" : "display-none"}`}>
                  <div className="font font-emphasis font-14px selectedBlockName">
                    {this.state.clickedField && (
                      <div className="title">
                        <span>
                          <BlockIcon className="block-icon font font-emphasis block-icon" />
                          {this.state.clickedField.name}
                      </span>
                        <span className="font font-secondary font-12px field-type">
                          {" "}
                          {(this.state.clickedField.typeName === FieldType.topography && "대지레벨") ||
                            (this.state.clickedField.typeName === FieldType.centerLineOfRoad && "인접대지경계선") ||
                            (this.state.clickedField.typeName === FieldType.site && "대지영역") ||
                            (this.state.clickedField.typeName === FieldType.road && "도로영역") ||
                            (this.state.clickedField.typeName === FieldType.vacancyInside && "배치제한영역") ||
                            (this.state.clickedField.typeName === FieldType.vacancyOutside && "공지영역") ||
                            this.state.clickedField.typeName}
                        </span>
                      </div>
                    )}
                    {
                      this.state.clickedField === null &&
                      <div className="title">
                        <span>
                          <BlockIcon className="block-icon font font-emphasis" />
                          -
                        </span>
                      </div>
                    }
                  </div>
                  {this.state.clickedField !== null && <FieldPartUI field={this.state.clickedField} updateArea={() => {
                    this.recalculateArea();
                    const word = this.state.clickedField && this.state.clickedField.typeName === FieldType.topography ? "대지레벨" : "면적";
                    this.setState({
                      snackMsg: <div className="font font-emphasis msg font-pretendard" style={{ textAlign: "center" }}>
                        <span className="font font-special">{`${word}이 수정되었습니다.`}</span><br />
                        {`수정된 ${word}은 ‘파일 관리’에서 제공되는 원본 캐드파일에는 반영되지 않습니다.`}</div>,
                      openSnack: true,
                    });
                  }}></FieldPartUI>}
                </div>
                {/* <div className='saveLayout lbrBorder TwoButtonHeight'>
                  <Button className='navyButton' onClick={() => this.makeDXFFile()}>DXF파일 다운로드</Button>
                  <Button className='sideMargin' onClick={() => this.showModal('나의 사업영역 저장', ['나의 사업영역을 저장하시겠습니까?'], buttonNum.twoButton, this.saveInputBlocks)}>나의 사업영역 저장</Button>
                  <div className='extraText'><span>서비스 이용약관</span><span className='end'>Copyright © 2019 BUILDIT</span></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
