import Events from "events";
import App from "./App";
import naver from "@teneleven/navermaps-v3";
import { WKT } from "@teneleven/protocols-ts-web/lib/db_gateway_pb";
import wkx, { Geometry } from "wkx";
import * as turf from "@turf/turf";

// @ts-ignore
import { reproject } from "reproject";
import { PolygonOptions, Polygon, Shape, Polyline, PolylineOptions, InfoWindow, Circle, CircleOptions, MapOptions, Map, ProjectSitePolygon, Marker, MarkerOptions, InfoWindowOptions } from "./Shape";
import { default as _ } from "lodash";
import { TextureFilter } from "three";
import { AllGeoJSON, GeometryTypes } from "@turf/turf";
const jsts = require("jsts");

export default class DrawingManager2 extends Events {
  static nProj: string = "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs";
  static bProj: string = "+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs";
  map?: Map;
  dm?: any;
  cadastralLayer: any;
  backgrounDetailLayer: any;
  poiKoreanLayer: any;
  newPoint = (x: number, y: number) => {
    return new App.naver.maps.Point(x, y);
  };

  setCadastralLayer = (on: boolean) => {
    if (this.map) {
      this.cadastralLayer.setMap(on ? this.map.nMap : null);
    }
  };

  createMap = (options: MapOptions) => {
    const map = new Map();

    // let initLayer = [
    //   App.naver.maps.StyleMapLayerId.BACKGROUND_DETAIL,
    //   App.naver.maps.StyleMapLayerId.POI_KOREAN,
    // ];

    this.cadastralLayer = new App.naver.maps.CadastralLayer({ useStyleMap: true });
    map.nMap = new App.naver.maps.Map(options.ref, {
      useStyleMap: true,
      center: (options.center && options.center) || undefined,
      zoom: (options.zoom && options.zoom) || 19,
      scrollWheel: options.wheel !== undefined ? options.wheel : true,
      zoomControl: options.zoomControl,
      zoomControlOptions: {
        position: 3,
      },
      logoControl: options.logoControl,
      mapDataControl: options.mapDataControl,
      scaleControl: options.scaleControl,
      maxZoom: options.maxZoom,
      ...options,
    });

    App.naver.maps.Event.once(map.nMap, "init_stylemap", () => {
      if (options.cadastral) {
        this.cadastralLayer.setMap(map.nMap);
      }
      map.nMap.addListener("mousemove", (e: any) => {
        map.emit("mousemove", e);
      });

      map.nMap.addListener("mouseout", () => {
        map.emit("mouseout");
      });

      map.nMap.addListener("click", (e: any) => {
        map.emit("click", e);
      });

      map.nMap.addListener("idle", (e: any) => {
        map.emit("idle", e);
      });
      
      map.nMap.addListener("zoom_changed", (e: any) => {
        map.emit("zoom_changed", e);
      });
      
      map.nMap.addListener("size_changed", (e: any) => {
        map.emit("size_changed", e);
      });

      map.nMap.addListener("dragend", (e: any) => {
        map.emit("dragend", e);
      });

    });

    this.map = map;
    return map;
  };

  // 그리기 취소
  clearDrawingManager = () => {
    if (this.dm) {
      this.dm.setMap(null);
    }
  };

  drawPolygon = (options?: PolygonOptions) => {
    const polygon = options && options.isProjectSite ? new ProjectSitePolygon() : new Polygon();
    const drawingOptions = {
      drawingControl: null,
      drawingMode: 5,
      map: this.map!.nMap,
      polygonOptions: options,
    };
    this.dm = new App.naver.maps.drawing.DrawingManager(drawingOptions);
    this.dm.addListener("drawing_removed", () => {
      polygon.emit("removed");
    });
    this.dm.addListener("drawing_added", (overlay: any) => {
      polygon.setOverlay(overlay);

      const rightClickEvent = polygon.getRightClick();
      polygon.setRightClick(false);
      setTimeout(() => {
        polygon.setRightClick(true, rightClickEvent);
      }, 100);
      if (overlay.getPath().length < 3) {
        polygon.emit("cancelled");
        return;
      }
      polygon.setId(overlay.id);
      polygon.setType("Polygon");
      // this.clearVertex(polygon);
      polygon.emit("added");

      overlay.addListener("click", (e: any) => polygon.emit("click"));
      overlay.addListener("mouseup", (e: any) => {
        this.overlayMoveEvent(e, polygon);
      });
      overlay.getPath().addListener("insert_at", (index: number) => polygon.emit("vertex_added", index));
      overlay.getPath().addListener(
        "set_at",
        _.debounce(() => {
          polygon.emit("changed");
        }, 50)
      );

      if (options && options.isProjectSite) {
        const roadLen = overlay.getPath().length - 1; // 도로 개수
        const p: ProjectSitePolygon = polygon as ProjectSitePolygon;
        for (let i = 0; i < roadLen; i++) {
          p.road.spacePolygon.push(undefined);
          p.road.verticalLine.push(undefined);
          p.road.horizontalLine.push(undefined);
        }
      }
    });
    return polygon;
  };

  addMultiPolygon = (paths?: number[][][][], options?: PolygonOptions, controllable: boolean = false) => {
    const drawingOptions = {
      drawingControl: null,
      map: this.map!.nMap,
    };
    const nPolygon = new App.naver.maps.Polygon({
      ...options,
      paths: paths,
    });
    const polygon = new Polygon();
    nPolygon.setMap(this.map!.nMap);
    polygon.setOverlay(nPolygon);
    polygon.setId(nPolygon.id);
    polygon.setType("MultiPolygon");

    return polygon;
  };

  addPolygon = (paths?: number[][][], options?: PolygonOptions, controllable: boolean = false) => {
    const drawingOptions = {
      drawingControl: null,
      map: this.map!.nMap,
    };
    const nPolygon = new App.naver.maps.Polygon({
      ...options,
      paths: paths,
    });

    const polygon = options && options.isProjectSite ? new ProjectSitePolygon() : new Polygon();

    if (controllable) {
      this.dm = new App.naver.maps.drawing.DrawingManager(drawingOptions);
      this.dm.addListener("drawing_removed", () => polygon.emit("removed"));
      this.dm.addDrawing(nPolygon);

      nPolygon.addListener("mouseup", (e: any) => this.overlayMoveEvent(e, polygon));
      nPolygon.addListener("click", (e: any) => polygon.emit("click"));
      // nPolygon.getPath().addListener('set_at', _.debounce(() => polygon.emit('changed')), 10);
      // nPolygon.getPath().addListener('insert_at', (index: number) => polygon.emit('vertex_added', index));
    } else {
      nPolygon.setMap(this.map!.nMap);
    }
    polygon.setOverlay(nPolygon);
    polygon.setId(nPolygon.id);
    polygon.setType("Polygon");
    options && options.simplify && this.clearVertex(polygon);
    this.ccwVertex(polygon);

    if (controllable) {
      nPolygon.getPath().addListener(
        "set_at",
        _.debounce(() => polygon.emit("changed")),
        50
      );
      nPolygon.getPath().addListener("insert_at", (index: number) => polygon.emit("vertex_added", index));
    }

    if (options && options.isProjectSite) {
      const roadLen = nPolygon.getPath().length - 1; // 도로 개수
      const p: ProjectSitePolygon = polygon as ProjectSitePolygon;
      for (let i = 0; i < roadLen; i++) {
        p.road.spacePolygon.push(undefined);
        p.road.verticalLine.push(undefined);
        p.road.horizontalLine.push(undefined);
      }
    }

    return polygon;
  };

  drawPolyline = (options?: PolylineOptions) => {
    const polyline = new Polyline();
    const drawingOptions = {
      drawingControl: null,
      drawingMode: 4,
      arrowlineOptions: options,
      map: this.map!.nMap,
    };

    this.dm = new App.naver.maps.drawing.DrawingManager(drawingOptions);
    this.dm.addListener("drawing_removed", () => {
      polyline.emit("removed");
    });
    this.dm.addListener("drawing_added", (overlay: any) => {
      polyline.setOverlay(overlay);
      polyline.setId(overlay.id);
      polyline.setType("LineString");
      if (overlay.getPath().length < 2) {
        polyline.emit("cancelled");
        return;
      }
      polyline.emit("added");

      overlay.addListener("click", (e: any) => polyline.emit("click"));
      overlay.addListener("mouseup", (e: any) => this.overlayMoveEvent(e, polyline));
      overlay.getPath().addListener("insert_at", (index: number) => {
        polyline.emit("vertex_added", index);
      });
      overlay.getPath().addListener(
        "set_at",
        _.debounce(() => polyline.emit("changed"), 50)
      );
    });
    return polyline;
  };

  addPolyline = (path: number[][], options?: PolylineOptions, controllable: boolean = false) => {
    const drawingOptions = {
      drawingControl: null,
      map: this.map!.nMap,
    };

    const nPolyline = new App.naver.maps.Polyline({
      ...options,
      path: path,
    });

    const polyline = new Polyline();
    polyline.setOverlay(nPolyline);
    polyline.setId(nPolyline.id);
    polyline.setType("LineString");

    if (controllable) {
      this.dm = new App.naver.maps.drawing.DrawingManager(drawingOptions);
      this.dm.addListener("drawing_removed", () => polyline.emit("removed"));
      this.dm.addDrawing(nPolyline);

      nPolyline.addListener("mouseup", (e: any) => this.overlayMoveEvent(e, polyline));
      nPolyline.addListener("click", (e: any) => polyline.emit("click"));
      nPolyline.getPath().addListener(
        "set_at",
        _.debounce(() => polyline.emit("changed"))
      );
      nPolyline.getPath().addListener("insert_at", (index: number) => polyline.emit("vertex_added", index));
    } else {
      nPolyline.setMap(this.map!.nMap);
    }
    return polyline;
  };

  drawCircle = (options: CircleOptions) => {
    const circle = new Circle();
    const drawingOptions = {
      drawingControl: null,
      drawingMode: 2,
      ellipseOptions: options,
      map: this.map!.nMap,
    };

    this.dm = new App.naver.maps.drawing.DrawingManager(drawingOptions);
    this.dm.addListener("click", () => circle.emit("click"));
    this.dm.addListener("drawing_removed", () => circle.emit("removed"));
    this.dm.addListener("drawing_added", (overlay: any) => {
      const n = overlay.getBounds();
      const min = App.naver.maps.TransCoord.fromLatLngToUTMK(n.getMin());
      const max = App.naver.maps.TransCoord.fromLatLngToUTMK(n.getMax());
      const r = (max.x - min.x + (max.y - min.y)) / 4;
      let c = new App.naver.maps.Circle({
        center: [(n._min.x + n._max.x) / 2, (n._max.y + n._min.y) / 2],
        radius: r,
        visible: false,
        map: this.map!.nMap,
      });

      overlay.setBounds(c.getBounds());
      c.setMap(null);
      c = null;

      circle.setOverlay(overlay);
      circle.setId(overlay.id);
      circle.setType("Polygon");
      circle.emit("added");

      overlay.addListener(
        "bounds_changed",
        _.debounce(() => {
          const n = overlay.getBounds();
          const min = App.naver.maps.TransCoord.fromLatLngToUTMK(n.getMin());
          const max = App.naver.maps.TransCoord.fromLatLngToUTMK(n.getMax());
          if ((max.x - min.x) / (max.y - min.y) > 1.05 || (max.x - min.x) / (max.y - min.y) < 0.95) {
            const r = (max.x - min.x + (max.y - min.y)) / 4;
            let c = new App.naver.maps.Circle({
              center: [(n._min.x + n._max.x) / 2, (n._max.y + n._min.y) / 2],
              radius: r,
              visible: false,
              map: this.map!.nMap,
            });

            overlay.setBounds(c.getBounds());
            c.setMap(null);
            c = null;
          }
          circle.emit("changed");
        }),
        10
      );
    });

    return circle;
  };

  addCircle = (path: number[][][], options?: CircleOptions, controllable: boolean = false) => {
    const drawingOptions = {
      drawingControl: null,
      map: this.map!.nMap,
    };

    const nCircle = new App.naver.maps.Ellipse({
      ...options,
      bounds: new App.naver.maps.LatLngBounds({ lat: path[0][0][1], lng: path[0][0][0] }, { lat: path[0][2][1], lng: path[0][2][0] }),
    });

    const circle = new Circle();
    circle.setOverlay(nCircle);
    circle.setId(nCircle.id);
    circle.setType("Polygon");

    if (controllable) {
      this.dm = new App.naver.maps.drawing.DrawingManager(drawingOptions);
      this.dm.addListener("drawing_removed", () => circle.emit("removed"));
      this.dm.addDrawing(nCircle);

      nCircle.addListener(
        "bounds_changed",
        _.debounce(() => {
          const n = nCircle.getBounds();
          const min = App.naver.maps.TransCoord.fromLatLngToUTMK(n.getMin());
          const max = App.naver.maps.TransCoord.fromLatLngToUTMK(n.getMax());
          if ((max.x - min.x) / (max.y - min.y) > 1.05 || (max.x - min.x) / (max.y - min.y) < 0.95) {
            const r = (max.x - min.x + (max.y - min.y)) / 4;
            let c = new App.naver.maps.Circle({
              center: [(n._min.x + n._max.x) / 2, (n._max.y + n._min.y) / 2],
              radius: r,
              visible: false,
              map: this.map!.nMap,
            });

            nCircle.setBounds(c.getBounds());
            c.setMap(null);
            c = null;
          }
          circle.emit("changed");
        }),
        10
      );
    } else {
      nCircle.setMap(this.map!.nMap);
    }
    return circle;
  };

  drawMarker = (option?: MarkerOptions, controllable: boolean = false) => {
    const marker = new Marker();

    this.dm = new App.naver.maps.drawing.DrawingManager({
      drawingControl: null,
      map: this.map!.nMap,
      drawingMode: 6,
      markerOptions: option,
    });

    this.dm.addListener("drawing_added", (overlay: any) => {
      marker.setOverlay(overlay);
      marker.setId(overlay._nmarker_id);
      marker.emit("added");

      overlay.addListener("mouseup", (e: any) => {
        setTimeout(() => marker.emit("changed", e), 10);
      });
    });

    this.dm.addListener("drawing_removed", (overlay: any) => {
      marker.emit("removed");
    });
    return marker;
  };
  addMarker = (position: number[], options?: MarkerOptions, controllable: boolean = false) => {
    const drawingOptions = {
      drawingControl: null,
      map: this.map!.nMap,
    };
    const nMarker = new App.naver.maps.Marker({
      ...options,
      position: position,
    });

    const marker = new Marker();

    if (controllable) {
      this.dm = new App.naver.maps.drawing.DrawingManager(drawingOptions);
      this.dm.addDrawing(nMarker);

      this.dm.addListener("drawing_removed", () => marker.emit("removed"));
      nMarker.addListener("mouseup", (e: any) => {
        setTimeout(() => marker.emit("changed", e), 10);
      });
      nMarker.addListener("mouseover", () => {
        marker.emit("hover");
      });
    } else {
      nMarker.setMap(this.map!.nMap);
      nMarker.setOptions({ draggable: false });
      nMarker.addListener("click", (e: any) => {
        marker.emit("click", e);
      });
      nMarker.addListener("mouseover", () => {
        marker.emit("hover");
      });
    }
    marker.setId(nMarker._nmarker_id);
    marker.setOverlay(nMarker);
    marker.setType("Point");

    return marker;
  };

  addInfoWindow = (options: InfoWindowOptions) => {
    const nInfoWindow = new App.naver.maps.InfoWindow({
      ...options,
    });

    const infoWindow = new InfoWindow(this.map!);
    infoWindow.infoWindow = nInfoWindow;

    return infoWindow;
  };

  overlayMoveEvent = (e: any, shape: Shape) => {
    if (e.overlay.getEditable()) {
      setTimeout(() => {
        shape.setOverlay(shape.overlay);
        shape.emit("changed");
        e.overlay.getPath().addListener("insert_at", (index: number) => shape.emit("vertex_added", index));
        e.overlay.getPath().addListener(
          "set_at",
          _.debounce(() => {
            shape.emit("changed");
          }, 50)
        );
      }, 10);
    }
  };
  getAABB = (projectSiteWKT: string[]): { min: [number, number]; max: [number, number] } | undefined => {
    if (projectSiteWKT.length === 0) {
      return;
    }

    let maxLat = 0;
    let maxLng = 0;
    let minLat = 0;
    let minLng = 0;
    projectSiteWKT.map((p) => {
      DrawingManager2.toGeom(p, "Polygon").coordinates.map((pp: Array<[number, number]>) => {
        pp.map((ppp) => {
          if (maxLng < ppp[0] || maxLng === 0) {
            maxLng = ppp[0];
          }
          if (maxLat < ppp[1] || maxLat === 0) {
            maxLat = ppp[1];
          }
          if (minLng > ppp[0] || minLng === 0) {
            minLng = ppp[0];
          }
          if (minLat > ppp[1] || minLat === 0) {
            minLat = ppp[1];
          }
        });
      });
    });
    return {
      min: [minLng, minLat],
      max: [maxLng, maxLat],
    };
  };

  static getZoomLevel = (area: number, size?: "SMALL" | "NORMAL") => {
    if (size && size === "SMALL") {
      if (area > 50000) {
        return 14;
      } else if (area > 25000) {
        return 15;
      } else {
        return 16;
      }
    } else {
      if (area > 50000) {
        return 17;
      } else if (area > 25000) {
        return 18;
      } else if (area > 5000) {
        return 19;
      } else if (area > 1000) {
        return 20;
      } else {
        return 21;
      }
    }
  };

  static getPolygonCenter = (polygon: string) => {
    const reader = new jsts.io.WKTReader();
    const poly = reader.read(polygon);
    const center: { x: number; y: number; z: number } = reader.read(polygon).getCentroid()._coordinates._coordinates[0];
    return { x: center.x, y: center.y };
  };

  static getProjectSiteCenter = (polygons: ProjectSitePolygon[]) => {
    if (polygons.length > 0) {
      let center: number[] = polygons.map((s) => turf.center({ type: "Polygon", coordinates: s.getPath() }).geometry!.coordinates).reduce((a: number[], b: number[]) => [a[0] + b[0], a[1] + b[1]]);
      const len = polygons.length;
      center = [center[0] / len, center[1] / len];
      return {
        centerCoordinates: center,
        centerWKT: wkx.Geometry.parseGeoJSON(
          reproject(
            {
              type: "Point",
              coordinates: center,
            },
            DrawingManager2.nProj,
            DrawingManager2.bProj
          )
        ).toWkt(),
      };
    } else {
      return {
        centerCoordinates: [0, 0],
        centerWKT: "",
      };
    }
  };

  static toWKT = (shape: Shape) => {
    let path = shape.getPath();
    let type = shape.getType();
    if (type === "Polygon") {
      if ((shape as Polygon).multiPolygonPath !== undefined) {
        // @ts-ignore
        path = (shape as Polygon).multiPolygonPath!;
        // @ts-ignore
        for (let i = 0; i < path.length; i++) {
          // @ts-ignore
          for (let j = 0; j < path[i].length; j++) {
            // @ts-ignore
            path[i][j].push(path[i][j][0]);
          }
        }
        type = "MultiPolygon";
      } else {
        // @ts-ignore
        for (let i = 0; i < path.length; i++) {
          // @ts-ignore
          path[i].push(path[i][0]);
        }
      }
    }
    return wkx.Geometry.parseGeoJSON(
      reproject(
        {
          type: type,
          coordinates: path,
        },
        DrawingManager2.nProj,
        DrawingManager2.bProj
      )
    ).toWkt();
  };

  static toGeom = (wkt: string, type: GeometryTypes | "Field") => {
    let coordinates = undefined;
    switch (type) {
      case "Field":
        // @ts-ignore
        coordinates = wkx.Geometry.parse(wkt).toGeoJSON().coordinates[0];
        break;
      default:
        // @ts-ignore
        coordinates = wkx.Geometry.parse(wkt).toGeoJSON().coordinates;
        break;
    }

    const gj = {
      type: type,
      coordinates: coordinates,
    };
    const geom = reproject(gj, DrawingManager2.bProj, DrawingManager2.nProj);
    if (type === "Polygon") {
      for (let i = 0; i < geom.coordinates.length; i++) {
        geom.coordinates[i].splice(geom.coordinates[i].length - 1, 1);
      }
    }
    return geom;
  };

  deleteLastVertex = (shape: Shape) => {
    const type = shape.getType();
    if (type === "Polygon") {
      let path = (shape as Polygon).getPath();
      path[0].splice(path[0].length - 1, 1);
      (shape as Polygon).setPath(path);
    } else if (type === "LineString") {
      let path = (shape as Polyline).getPath();
      path.splice(path.length - 1, 1);
      (shape as Polyline).setPath(path);
    }
  };

  ccwVertex = (shape: Polygon | Circle) => {
    let path = shape.getPath();
    for (let i = 0; i < path.length; i++) {
      path[i].push(path[i][0]);
    }

    const ccwPolygon = turf.rewind(turf.polygon(path));
    path = ccwPolygon.geometry!.coordinates;
    path.map((p: any) => p.splice(p.length - 1, 1));
    shape.setPath(path);
  };

  clearVertex = (shape: Polygon | Polyline | Circle) => {
    let path = shape.getPath();
    // input first vertex at last
    for (let i = 0; i < path.length; i++) {
      path[i].push(path[i][0]);
    }
    // clear equal and very near vertex
    const simplifiedPolygon = turf.simplify(turf.polygon(path), { tolerance: 0.000001 });
    path = simplifiedPolygon.geometry!.coordinates;
    path.map((p: any) => p.splice(p.length - 1, 1));
    if (shape.getType() === "Polygon") {
      (shape as Polygon).setPath(path);
    } else {
    }
  };

  sumOfSite = (wkt: string[]) => {
    if (wkt.length == 0) {
      return 0;
    } else {
      const reader = new jsts.io.WKTReader();
      return wkt.map((r) => reader.read(r).getArea()).reduce((a, b) => a + b, 0);
    }
  };

  distanceBetweenPoint = (pointA: number[], pointB: number[]) => {
    return turf.distance(pointA, pointB, { units: "meters" });
  };

  clockwise = (polygon: ProjectSitePolygon) => {
    let sum = 0;
    polygon.getPath().map((path: number[][]) => {
      for (let i = 0; i < path.length; i++) {
        const utm1 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path[i][1], path[i][0]));
        const utm2 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path[(i + 1) % path.length][1], path[(i + 1) % path.length][0]));
        sum += utm1.x * utm2.y - utm2.x * utm1.y;
      }
    });
    return sum > 0 ? true : false;
  };
  getRoadSetOfPath = (polygon: ProjectSitePolygon, roadIndex: number, distance: number) => {
    const length = polygon.getPath()[0].length;
    let x1, x2, y1, y2;
    const clockwise = this.clockwise(polygon);
    const path1 = polygon.getPath()[0][roadIndex];
    const path2 = polygon.getPath()[0][(roadIndex + 1) % length];
    if (clockwise) {
      x1 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path1[1], path1[0])).x;
      y1 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path1[1], path1[0])).y;
      x2 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path2[1], path2[0])).x;
      y2 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path2[1], path2[0])).y;
    } else {
      x2 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path1[1], path1[0])).x;
      y2 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path1[1], path1[0])).y;
      x1 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path2[1], path2[0])).x;
      y1 = App.naver.maps.TransCoord.fromLatLngToUTMK(new App.naver.maps.LatLng(path2[1], path2[0])).y;
    }
    const dVec = { x: x2 - x1, y: y2 - y1 };
    const nVec = { x: dVec.y, y: -dVec.x };
    const normal = Math.sqrt(nVec.x * nVec.x + nVec.y * nVec.y);
    nVec.x /= normal;
    nVec.y /= normal;

    const pC = { x: (x1 + x2) / 2, y: (y1 + y2) / 2 };
    const pCn = { x: pC.x + distance * nVec.x, y: pC.y + distance * nVec.y };
    const p1n = { x: x1 + distance * nVec.x, y: y1 + distance * nVec.y };
    const p2n = { x: x2 + distance * nVec.x, y: y2 + distance * nVec.y };

    return {
      roadSpacePolygonPath: [
        App.naver.maps.TransCoord.fromUTMKToLatLng(p1n),
        App.naver.maps.TransCoord.fromUTMKToLatLng(p2n),
        App.naver.maps.TransCoord.fromUTMKToLatLng({ x: x2, y: y2 }),
        App.naver.maps.TransCoord.fromUTMKToLatLng({ x: x1, y: y1 }),
      ],
      roadVeritcalPolylinePath: [App.naver.maps.TransCoord.fromUTMKToLatLng(new App.naver.maps.Point(pC)), App.naver.maps.TransCoord.fromUTMKToLatLng(new App.naver.maps.Point(pCn))],
      roadHorizontalPolylinePath: [App.naver.maps.TransCoord.fromUTMKToLatLng(new App.naver.maps.Point(p1n)), App.naver.maps.TransCoord.fromUTMKToLatLng(new App.naver.maps.Point(p2n))],
    };
  };

  searchAddressToCoordinate = (address: string) => {
    App.naver.map.Service.geocode({
      query: address
    }, (status: any, response: any) => {
      if (status === App.naver.map.Service.Status.ERROR) {
        return alert('Something Wrong!');
      }

      if (response.v2.meta.totalCount === 0) {
        return alert('totalCount' + response.v2.meta.totalCount);
      }

      var htmlAddresses = [],
        item = response.v2.addresses[0],
        point = new App.naver.map.Point(item.x, item.y);

      if (item.roadAddress) {
        htmlAddresses.push('[도로명 주소] ' + item.roadAddress);
      }

      if (item.jibunAddress) {
        htmlAddresses.push('[지번 주소] ' + item.jibunAddress);
      }

      if (item.englishAddress) {
        htmlAddresses.push('[영문명 주소] ' + item.englishAddress);
      }

      this.map!.nMap.setCenter(point);
    });

  }

  static DrawingOption = {
    JIGU_HOVER: {
      fillOpacity: 0,
      strokeColor: "#FA00FF",
      strokeWeight: 2,
      strokeStyle: "solid",
      clickable: false,
    },
    JIGU_ACTIVE: {
      fillColor: "#FA00FF",
      fillOpacity: 0.2,
      strokeColor: "#FA00FF",
      strokeStyle: "solid",
      clickable: false,
    },
    CAL_DISTANCE: {
      strokeColor: "#DC0091",
      strokeWeight: 3,
      endIcon: 3,
      endIconSize: 7,
      startIcon: 3,
      startIconSize: 7,
    },
    CAL_AREA: {
      fillColor: "#B311FF",
      fillOpacity: 0.2,
      strokeColor: "#B311FF",
      strokeWeight: 1,
      strokeStyle: "solid",
      clickable: false,
    },
    CAL_MARKER: {
      draggable: false,
      icon: {
        content: '<div class="">지우기</div>',
      },
    },
    CAD_MAP_MARKER: {
      draggable: false,
      icon: {
        content: "",
      },
    },
    PROJECT_SITE_UNSELECTED: {
      fillColor: "black",
      fillOpacity: 0.2,
      strokeColor: "black",
      strokeWeight: 1,
      strokeStyle: "solid",
      strokeOpacity: 0.5,
      zIndex: -10,
      clickable: true,
    },
    PROJECT_SITE: {
      fillColor: "rgb(255, 51, 51)",
      fillOpacity: 0.4,
      strokeColor: "#FF3333",
      strokeWeight: 3,
      strokeStyle: "shortdashdotdot",
      zIndex: -10,
      clickable: true,
      isProjectSite: true,
    },
    VACANCY_OUTSIDE: {
      fillColor: "#00A15E",
      fillOpacity: 0.3,
      strokeColor: "#00A15E",
      strokeWeight: 1,
      zIndex: -8,
      clickable: true,
    },
    VACANCY_INSIDE: {
      fillColor: "rgb(136, 0, 200)",
      fillOpacity: 0.2,
      strokeColor: "#8800C8",
      strokeWeight: 1,
      zIndex: -6,
      clickable: true,
    },
    ROAD_SPACE: {
      // fillColor: '#999999',
      // fillOpacity: 0.5,
      strokeWeight: 0,
      clickable: false,
      zIndex: -9,
      simple: true,
    },
    ROAD_SITE: {
      fillColor: "rgb(90, 121, 165);",
      fillOpacity: 0.4,
      strokeColor: "#5A79A5;",
      strokeWeight: 1,
      strokeOpacity: 1,
      clickable: false,
      zIndex: -9,
      simple: true,
    },
    ROAD_VERTICAL: {
      strokeColor: "rgb(90, 121, 165)",
      strokeWeight: 1,
      startIcon: 2,
      endIcon: 2,
      clickable: false,
      zIndex: -8,
      simple: true,
    },
    ROAD_VERTICAL_ACTIVE: {
      strokeColor: "#FA00FF",
      strokeWeight: 2,
      startIcon: 2,
      endIcon: 2,
      clickable: false,
      zIndex: -8,
      simple: true,
    },
    ROAD_HORIZONTAL: {
      strokeColor: "rgb(90, 121, 165)",
      strokeWeight: 1,
      startIcon: 3,
      endIcon: 3,
      clickable: false,
      zIndex: -8,
      simple: true,
    },
    ROAD_HORIZONTAL_ACTIVE: {
      strokeColor: "#FA00FF",
      strokeWeight: 2,
      startIcon: 3,
      endIcon: 3,
      clickable: false,
      zIndex: -8,
      simple: true,
    },
    SKYLINE_LINE: {
      strokeColor: "#0076E3",
      strokeWeight: 3,
      endIcon: 3,
      endIconSize: 7,
      startIcon: 3,
      startIconSize: 7,
      clickable: true,
      zIndex: -4,
    },
    SKYLINE_CIRCLE: {
      strokeColor: "#0076E3",
      strokeWeight: 2,
      fillOpacity: 0.3,
      fillColor: "#0076E3",
      clickable: true,
      zIndex: -4,
    },
    MAX_FLOOR_MARKER: {
      clickable: true,
      draggable: true,
      icon: {
        url: "/img/max_floor.svg",
      },
      visible: true,
    },
    MIN_FLOOR_MARKER: {
      clickable: true,
      draggable: true,
      icon: {
        url: "/img/min_floor.svg",
      },
      visible: true,
    },
    HOME_MARKER: {
      draggable: false,
      icon: {
        content: '<div class="project-marker-wrap"></div>',
      },
    },
    BOUNDARY_SITE: {
      strokeColor: "#1045FF",
      strokeWeight: 2,
      fillOpacity: 0,
      zIndex: -18,
      clickable: false,
      simplify: false,
    },
    BOUNDARY_SITE_EXTENDED: {
      fillColor: "rgb(0, 161, 94)",
      fillOpacity: 0.3,
      zIndex: -20,
      strokeColor: "#00A15E",
      strokeWeight: 1,
      clickable: false,
      simplify: false,
    },
    TOPOGRAPHY_LINE: {
      // 성절토 (a.k.a 대지레빌)
      // fillColor: "#898989",
      fillOpacity: 0.9,
      strokeColor: "#DDDDDD",
      strokeWeight: 1,
      clickable: false,
      zIndex: -9,
      simplify: false,
    },
    CAD_PROJECT_SITE: {
      // CAD Converter 지적도 사업영역 주소검색시 나오는 폴리곤

      fillColor: "rgba(255, 109, 65)", //'#ff0000',
      fillOpacity: 0.3,
      strokeWeight: 2,
      strokeColor: "rgba(243, 72, 41)", //'#ff0000'
      // fillOpacity: 0.3,
      // strokeColor: "#232732",
      // strokeWeight: 2,
      // strokeStyle: 'shortdashdotdot',
      // zIndex: -10,
      // clickable: true,
      // isProjectSite: true,
    },
    CAD_PROJECT_SITE_SQUARE: {
      // CAD Converter 지적도 사각형
      strokeWeight: 2,
      strokeColor: "#0038FF",
      fillColor: '#0038FF',
      fillOpacity: 0.1,
    },
    CAD_CORE: {
      fillColor: "#246D77",
      fillOpacity: 0.8,
      strokeColor: "#246D77",
      zIndex: -5,
    },
    CAD_HOUSE: {
      fillColor: "#95E4B3",
      fillOpacity: 0.6,
      strokeWeight: 1,
      strokeColor: "#246D77",
      zIndex: -5,
    },
  };
}
