import * as THREE from '@teneleven/three';
import {DataForGenerator, eachNode, FeatureGenerator} from "./GeneratorInterface";
import {addPathToShapePath, OffsetLineSegments, SimplifyLineSegments} from "../GeomUtilities";

export class RoofLineGenerator extends FeatureGenerator {
    constructor(allBuilding: THREE.Group) {
        super(allBuilding);
    }

    public Generate(data: DataForGenerator) {
        // let node_to_generate = [];

        data.nodes.forEach((node: eachNode) => {
            if (node.roofLineHeight) {
                // node_to_generate.push('');

                let roofLineGeom = RoofLineGenerator.generateTopRoofLineGeometry(node.node, node.roofLineHeight);
                let roofLineMesh = new THREE.Mesh(roofLineGeom, this._wallcoreMaterial['FC_CORE']);

                roofLineMesh.name = node.buildingGroup.children[node.buildingGroup.children.length - 1].name;
                roofLineMesh.layers.set(2);
                node.buildingGroup.add(roofLineMesh);
            }
        });
    }

    public Dispose() {
    }

    private static generateTopRoofLineGeometry(node2: THREE.Vector2[], YPos: number) : THREE.BufferGeometry {
        let simplifiedNode2 = SimplifyLineSegments(node2);
        let offsetNode2 = OffsetLineSegments(FeatureGenerator._wallThickness / 2, simplifiedNode2).reverse();
        let offsetNode = OffsetLineSegments(-FeatureGenerator._wallThickness / 2, simplifiedNode2);

        let sPath = new THREE.ShapePath();

        addPathToShapePath(sPath, offsetNode);

        addPathToShapePath(sPath, offsetNode2);

        let shape = sPath.toShapes(false);

        let geom = new THREE.ExtrudeBufferGeometry(shape, {
            steps: 1,
            depth: FeatureGenerator._wallThickness,
            bevelEnabled: false
        });

        let matrix = new THREE.Matrix4();
        matrix.set(-1, 0, 0, 0,
            0, 0, 1, YPos,
            0, 1, 0, 0,
            0, 0, 0, 1);
        geom.applyMatrix4(matrix);

        geom.computeVertexNormals();

        return geom as THREE.BufferGeometry;
    }
}