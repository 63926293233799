import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import './css/MyPageHead.scss';
import { MyPageProps, pageContent, } from './MyPage';
import EmailIcon from '@material-ui/icons/Email';
import ReceiptIcon from '@material-ui/icons/Receipt';
import NextIcon from '@material-ui/icons/NavigateNext'
import InfoIcon from '@material-ui/icons/InfoRounded'
import AWS, { IoTThingsGraph } from 'aws-sdk';
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom';
import App from './App';
import { bool } from 'aws-sdk/clients/signer';
import AWSModule from './AWSModule';
import Tooltip from './Tooltip';
import { Help } from '@material-ui/icons';
import moment from 'moment';
import ServicePlanTag from './ServicePlanTag';

interface MyPageHeadProps extends MyPageProps {
  pageContent: pageContent,
  userEmail: string,
}

interface MyPageHeadState {
  userName: string,
  userEmail: string,
  buildITPoint: number,
  servicePoint: number,
  userLevel: string,
  mySiteCount: number,
  myTypeCount: number,
  myPlanCount: number,
  myPlanTerm: string,
}

export default class MyPageHead extends SessionComponent<MyPageHeadProps, MyPageHeadState> {
  state: MyPageHeadState = {
    userName: '고객',
    userEmail: App.session.email,
    buildITPoint: App.session.point,
    servicePoint: App.session.service_point,
    userLevel: 'PLATINUM',
    myPlanCount: 0,
    mySiteCount: 0,
    myTypeCount: 0,
    myPlanTerm: '',
  }

  componentWillMount = async () => {
    this.setSiteCount();
    this.setBuildingPlanCount();
    this.setBuildingTypeCount();
    await App.updateSession();
  }

  componentDidMount = async () => {
    if (App.session.paying_type === "INFINITE") this.setPlanTerm();
  }

  // timer = setInterval(async () => {
  //   await App.updateSession();  
  //   this.setState({ buildITPoint: App.session.point });
  // }, 30000)


  // componentWillUnmount() {
  //   clearInterval(this.timer);
  // }

  setSiteCount = async () => {
    const r = await App.search({
      table: App.DDBTable.MyBuildingSite,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${this.state.userEmail} AND deleted:false`
    });
    this.setState({
      mySiteCount: r.data.hits.total,
    })
  }

  setBuildingPlanCount = async () => {
    const r = await App.search({
      table: App.DDBTable.MyBuildingPlan,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${this.state.userEmail} AND deleted:false`,
    });
    this.setState({
      myPlanCount: r.data.hits.total,
    })
  }

  setBuildingTypeCount = async () => {
    const r = await App.search({
      table: App.DDBTable.MyBuildingType,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${this.state.userEmail} AND deleted:false`,
    });
    this.setState({
      myTypeCount: r.data.hits.total,
    })
  }

  setPlanTerm = async () => {
    const AWS = await new AWSModule("DDB").connect();
    let startDate = '0000.00.00';
    let endDate = '0000.00.00';
    try {
      const r = await AWS.Ddb!.get({
        TableName: 'platform-buildit-user',
        Key: {
          "id": AWS.id!,
        }
      }).promise();

      if (r.Item && (r.Item.point_expiration_date_start || r.Item.point_expiration_date_end)) {
        startDate = r.Item.point_expiration_date_start ? moment(r.Item.point_expiration_date_start).format("YYYY.MM.DD") : startDate;
        endDate = r.Item.point_expiration_date_end ? moment(r.Item.point_expiration_date_end).format("YYYY.MM.DD") : endDate;
      }
    }
    catch (e) {
      //console.log(e);
    }
    this.setState({
      myPlanTerm: `${startDate} ~ ${endDate}`,
    });    
  }

  render = () => {
    let titleString = ""
    let infoString = ""
    let buttons = <div/>
    let pointInfo = <div/>

    switch (this.props.pageContent) {
      case pageContent.myBuildingType:
      case pageContent.myPlan:
      case pageContent.mySite:
        titleString = "나의 파일"
        infoString = "나의 파일"

        buttons = <div className='buttons'>
        {/* <Button className='emailButton' disableRipple={true}>{this.props.userEmail}</Button> */}
        <div className='switchTabs' style={{width:"262px"}}>
          <Button className={`switchButton ${this.props.pageContent === pageContent.myBuildingType && 'enable'}`}
          onClick={e => this.props.changePageContent!(pageContent.myBuildingType)}
          style={{width:"86px", marginLeft:"2px", marginRight:"2px"}} disableRipple={true}>
            나의 동평면
          </Button>
          <Button className={`switchButton ${this.props.pageContent === pageContent.mySite && 'enable'}`}
          onClick={e => this.props.changePageContent!(pageContent.mySite)}
          style={{width:"86px"}} disableRipple={true}>
            나의 사업영역
          </Button>
          <Button className={`switchButton ${this.props.pageContent === pageContent.myPlan && 'enable'}`}
          onClick={e => this.props.changePageContent!(pageContent.myPlan)}
          style={{width:"86px"}} disableRipple={true}>
            나의 배치안
          </Button>
        </div>
          <Link to={"/cad/cadConverterHome" } target="_blank">
            <Button className="btn bg-navy btn-run newCadButton">CAD Converter</Button>
          </Link>
        <Tooltip msg={<>CAD CONVERTER란, <br />AUTOCAD에서 작성된 도면을 BUILDIT 포맷으로 변환해주는 서비스입니다.</>} arrowOn={false}>
          <div className="icon-wrap"><Help className="icon help-icon"></Help></div>
        </Tooltip>
      </div>
        break;
      case pageContent.pointUsageList:
      case pageContent.purchaseHistory:
        titleString = "구매/포인트 내역"
        infoString = "구매/포인트"

        if (App.session.paying_type) {
          buttons = <div className='buttons'>
          {     
          <div className='pointButton'>
            <div className='svg'>
              <object type="image/svg+xml" data="/img/icon_Bpoint.svg" />
            </div>
            <div className='point'>{
              App.session.paying_type ? App.session.point.toLocaleString() :
                new Intl.NumberFormat().format(this.state.buildITPoint + this.state.servicePoint)
            }P</div>
          </div>
          }
          </div>
        }

        pointInfo = 
          <section className="servicePlan">
            <article>
              <div className="service-plan-title">{`${App.session.email} 님의`}<br/>서비스 플랜 현황</div>
              {
                App.session.paying_type &&
                <div className="key-value">
                  <div className="key">서비스 플랜</div>
                  <ServicePlanTag type={App.session.paying_type} />
                </div>
                ||
                <div className="key-value no-plan" style={{marginBottom: "20px"}}>
                  <div className="plan-info">사용 중인<u className="link" onClick={() => {
                    window.open('/landing?type=SERVICE')
                  }}><Tooltip arrowOn={false} msg={"새 창으로 열립니다"}>서비스 플랜</Tooltip></u>이 없습니다.</div>
                </div>
              }
            </article>
            { App.session.paying_type &&<div className="divider" /> }
            {
              App.session.paying_type &&
              <article>
                <div className="key-value">
                  <div className="key"></div>
                <div className="value"></div>
                </div>
                <div className="key-value">
                  {
                    App.session.paying_type === "INFINITE" &&
                    <div className="key">오늘 사용 가능 포인트
                      <Tooltip msg={'일일 최대 100만 포인트 사용 가능'} arrowOn={false}>
                        <div className="icon">
                          <Help className="help-icon" />
                        </div>
                      </Tooltip>
                    </div>
                    ||
                    <div className="key">사용 가능 포인트</div>
                  }
                  <div className="value">{App.session.point.toLocaleString()}P</div>
                </div>
                <div className="key-value">
                  <div className="key">사용 기간</div>
                  <div className="value">{this.state.myPlanTerm}</div>
                </div>

              </article>
            }
            { App.session.paying_type &&<div className="divider" /> }
          </section>       
        break;
      case pageContent.myInformation:
        titleString = "계정 관리"
        infoString = "계정 관리"

        buttons = <div className='buttons'>
        <Button className='emailButton' disableRipple={true}>{this.props.userEmail}</Button>
      </div>

        break;
    }
    return (
      <div className={`MyPageHead`}>
        <div className='content'>
          <div className='pageInfoLayer font bg-navy font-secondary font-12px font-noto font-pretendard'>
            <Link to="/" style={{textDecoration:"none", color:"#999"}}>
            HOME
            </Link>
            <NextIcon className='nextIcon' />
            <div>{infoString}</div>
          </div>
          <div className='buttonLayer'>
            <div className='title font bg-navy font-emphasis font-30px'>{titleString}</div>
            {buttons}
          
          </div>
          {this.props.pageContent === "포인트" && pointInfo}
        </div>
      </div>
    )
  }
}