import * as THREE from '@teneleven/three';
import {BufferGeometryUtils} from '@teneleven/three/examples/jsm/utils/BufferGeometryUtils';
// import { CSS2DRenderer, CSS2DObject } from '@teneleven/three/examples/jsm/renderers/CSS2DRenderer.js';
import CSG from "./csg/three-csg.js";
import {FeatureGenerator} from "./Generator/GeneratorInterface";
// import {BufferGeometryUtils} from "three";

//벽 두께
const wallThickness = FeatureGenerator.wallThickness();

function vec3Togeometry(contour: THREE.Vector3[]) {
    let lineGeom = new THREE.Geometry();
    for (let i = 0; i < contour.length; i++) {
        lineGeom.vertices.push(contour[i]);
        lineGeom.vertices.push(contour[i + 1 === contour.length ? 0 : i + 1]);
    }

    return lineGeom;
}

//debug
export function generateOffsetSegments(node2: THREE.Vector2[], height: number) {
    let simplifiedNode2 = SimplifyLineSegments(node2);

    let originalNode3: THREE.Vector3[] = [];
    simplifiedNode2.forEach(v => originalNode3.push(new THREE.Vector3(v.x, height, v.y)));

    //
    let result = OffsetLineSegments(0.3, simplifiedNode2);

    //
    let offsetNode3: THREE.Vector3[] = [];
    result.forEach(v => offsetNode3.push(new THREE.Vector3(v.x, height, v.y)));

    // // contour.forEach(v => lineGeom.vertices.push(v));
    let lineGeom = new THREE.Geometry();
    lineGeom.merge(vec3Togeometry(originalNode3));
    lineGeom.merge(vec3Togeometry(offsetNode3));

    //
    return lineGeom;
}

export function addPathToShapePath(shapePath: THREE.ShapePath, path: any) {
    const iReverse = -1;
    shapePath.moveTo(path[0].x * iReverse, path[0].y);
    for (let i = 1; i < path.length; i++) {
        shapePath.lineTo(path[i].x * iReverse, path[i].y);
    }
    shapePath.lineTo(path[0].x * iReverse, path[0].y);
}

function getLineSegmentGeometry(segments: THREE.Vector2[], YPos: number) {
    let offsetCVS = new THREE.Geometry();

    for (let i = 0; i < segments.length; i++) {
        let stP = segments[i - 1 < 0 ? segments.length - 1 : i - 1];
        let endP = segments[i];

        offsetCVS.vertices.push(new THREE.Vector3(stP.x, YPos, stP.y));
        offsetCVS.vertices.push(new THREE.Vector3(endP.x, YPos, endP.y));
    }

    return offsetCVS;
}

export function generateWallGeometry(node2: THREE.Vector2[], YPos: number, height: number, bGroup: THREE.Group | undefined = undefined) : THREE.BufferGeometry {
    let simplifiedNode2 = SimplifyLineSegments(node2);
    let offsetNode2 = OffsetLineSegments(wallThickness, simplifiedNode2).reverse();

    //debug
    if (bGroup) {
        let offsetCVS = new THREE.Geometry();
        for (let i = 0; i < offsetNode2.length; i++) {
            let stP = simplifiedNode2[i - 1 < 0 ? simplifiedNode2.length - 1 : i - 1];
            let endP = simplifiedNode2[i];

            let a = new THREE.Vector3(stP.x, 100, stP.y);
            let b = new THREE.Vector3(endP.x, 100, endP.y);
            let dir = new THREE.Vector3().subVectors(b, a).normalize();

            bGroup.add(new THREE.ArrowHelper(dir, a, a.distanceTo(b), 0xff0000));

            // offsetCVS.vertices.push(a);
            // offsetCVS.vertices.push(b);
        }
    }
    //////

    let sPath = new THREE.ShapePath();

    addPathToShapePath(sPath, simplifiedNode2);

    addPathToShapePath(sPath, offsetNode2);

    let shape = sPath.toShapes(false);

    let geom = new THREE.ExtrudeBufferGeometry(shape, {
        steps: 1,
        depth: height,
        bevelEnabled: false
    });

    //
    let matrix = new THREE.Matrix4();
    matrix.set(-1, 0, 0, 0,
        0, 0, 1, YPos,
        0, 1, 0, 0,
        0, 0, 0, 1);
    geom.applyMatrix4(matrix);

    // let counts = [0, 0, 0];
    // let normals = geom.getAttribute('normal'),
    //     positions = geom.getAttribute('position');
    // for (let i = 0; i < normals.count; i++) {
    //     // let eachNormal = new THREE.Vector3(normals.array[i * 3], normals.array[i * 3 + 1], normals.array[i * 3 + 2]);
    //     if (-1 == normals.array[i * 3 + 1]) {
    //         counts[0] += 1;
    //     } else if (1 == normals.array[i * 3 + 1]) {
    //         counts[1] += 1;
    //     } else {
    //         counts[2] += 1;
    //     }
    // }
    //
    // // geom.normalizeNormals();
    // geom.clearGroups();
    // let lastIndex = 0;
    // for (let i = 0; i < counts.length; i++) {
    //     geom.addGroup(lastIndex, counts[i], i);
    //     lastIndex += counts[i];
    // }
    geom.computeVertexNormals();

    return geom;
}

export function regroupWallGeometry(geom: THREE.BufferGeometry) : THREE.BufferGeometry {
    geom.computeBoundingBox();

    let lastIndex = -1;
    let normals = geom.getAttribute('normal');

    //0: -Y, 1: +Y, 2: rest
    let matGroups: { [key: number]: number[] } = {0: [], 1: [], 2: []};
    let grpStIndex = 0;
    let eachCount = -1;
    for (let i = 0; i < normals.count; i++) {
        eachCount++;
        let setValue = false;
        let currentArray;

        // let eachNormal = new THREE.Vector3(normals.array[i * 3], normals.array[i * 3 + 1], normals.array[i * 3 + 2]);
        if (-1 == normals.array[i * 3 + 1]) {
            // counts[0] += 1;
            if (-1 != lastIndex && 0 != lastIndex) {
                setValue = true;
                currentArray = matGroups[lastIndex];

                // matGroups[lastIndex].push(grpStIndex, eachCount);
                // grpStIndex += eachCount;
                // eachCount = 0;
            }

            lastIndex = 0;
        } else if (1 == normals.array[i * 3 + 1]) {
            // counts[1] += 1;
            if (-1 != lastIndex && 1 != lastIndex) {
                setValue = true;
                currentArray = matGroups[lastIndex];

                // matGroups[lastIndex].push(grpStIndex, eachCount);
                // grpStIndex += eachCount;
                // eachCount = 0;
            }

            lastIndex = 1;
        } else {
            if (-1 != lastIndex && 2 != lastIndex) {
                setValue = true;
                currentArray = matGroups[lastIndex];

                // matGroups[lastIndex].push(grpStIndex, eachCount);
                // grpStIndex += eachCount;
                // eachCount = 0;
            }

            lastIndex = 2;
        }

        if (setValue && currentArray) {
            currentArray.push(grpStIndex, eachCount);
            grpStIndex += eachCount;
            eachCount = 0;
        }
    }
    matGroups[lastIndex].push(grpStIndex, ++eachCount);

    geom.clearGroups();

    for (let matKey in matGroups) {
        let matIndex = 0;
        if ('1' == matKey) {
            matIndex = 1;
        }

        for (let i = 0; i < matGroups[matKey].length / 2; i++) {
            // geom.addGroup(matGroups[matKey][(i * 2)], matGroups[matKey][(i * 2) + 1], Number(matKey));
            geom.addGroup(matGroups[matKey][(i * 2)], matGroups[matKey][(i * 2) + 1], matIndex);
        }
    }

    geom.computeVertexNormals();

    return geom;
}

function isBetween(a: any, b: any, c: any) {
//     crossproduct = (c.y - a.y) * (b.x - a.x) - (c.x - a.x) * (b.y - a.y)
//
// # compare versus epsilon for floating point values, or != 0 if using integers
//     if abs(crossproduct) > epsilon:
//     return False
//
//     dotproduct = (c.x - a.x) * (b.x - a.x) + (c.y - a.y)*(b.y - a.y)
//     if dotproduct < 0:
//     return False
//
//     squaredlengthba = (b.x - a.x)*(b.x - a.x) + (b.y - a.y)*(b.y - a.y)
//     if dotproduct > squaredlengthba:
//     return False
//
//     return True
}

export function generateLevelLine(node2: THREE.Vector2[], YPos: number) : THREE.Geometry {
    let simplifiedNode2 = SimplifyLineSegments(node2);
    let levelLine = new THREE.Geometry();

    for (let i = 0; i < simplifiedNode2.length; i++) {
        let stP = simplifiedNode2[i - 1 < 0 ? simplifiedNode2.length - 1 : i - 1];
        let endP = simplifiedNode2[i];

        levelLine.vertices.push(new THREE.Vector3(stP.x, YPos, stP.y));
        levelLine.vertices.push(new THREE.Vector3(endP.x, YPos, endP.y));
    }

    return levelLine;
}

function generateShapeWindow(stP: THREE.Vector2, endP: THREE.Vector2, windowH: number) : THREE.BufferGeometry {
    // let windowGeom = new THREE.BufferGeometry();
    const windowL = Math.round(stP.distanceTo(endP) * 100) / 100;
    const frameW = Math.round(windowL * 10) / 200;
    const swindowW = (Math.round((windowL - 3 * frameW) * 100) / 100) / 2;
    const swindowH = Math.round((windowH - 2 * frameW) * 100) / 100;

    function addNodeToPath(node: any, sPath: THREE.ShapePath) {
        const iReverse = -1;
        sPath.moveTo(node[0] * iReverse, node[1]);
        for (let i = 0; i < node.length / 3; i++) {
            sPath.lineTo(node[i * 3] * iReverse, node[i * 3 + 1]);
        }
        sPath.lineTo(node[0] * iReverse, node[1]);
    }

    let sPath = new THREE.ShapePath();
    let outterNodes = [
        0, 0, 0,
        windowL, 0, 0,
        windowL, windowH, 0,
        0, windowH, 0
    ];
    addNodeToPath(outterNodes, sPath);

    let onlyMesh = true;
    let windowGeom: THREE.BufferGeometry;

    if (onlyMesh) {
        let innerNodes1 = [
            frameW, frameW, 0,
            frameW, frameW + swindowH, 0,
            frameW + swindowW, frameW + swindowH, 0,
            frameW + swindowW, frameW, 0
        ];
        addNodeToPath(innerNodes1, sPath);

        let innerNodes2 = [
            frameW + frameW + swindowW, frameW, 0,
            frameW + frameW + swindowW, frameW + swindowH, 0,
            frameW + frameW + swindowW + swindowW, frameW + swindowH, 0,
            frameW + frameW + swindowW + swindowW, frameW, 0
        ];
        addNodeToPath(innerNodes2, sPath);

        let geoms: THREE.ShapeBufferGeometry[] = [];
        for (let i = 0; i < 2; i++) {
            geoms.push(new THREE.ShapeBufferGeometry(sPath.toShapes(i == 0)));
        }

        // let windowGeom = new THREE.ShapeBufferGeometry(sPath.toShapes(false));
        windowGeom = BufferGeometryUtils.mergeBufferGeometries(geoms);

        // windowGeom = geoms[0];
        //
        windowGeom.clearGroups();
        // let typ = typeof geoms[0].index;
        // let abcd = geoms[0].index instanceof THREE.BufferAttribute;
        let geom1Index = geoms[0].index!.count;
        let geom2Index = geoms[1].index!.count;
        //
        windowGeom.addGroup(0, geom1Index, 0);
        windowGeom.addGroup(geom1Index, geom2Index, 1);
    } else {
        windowGeom = new THREE.ShapeBufferGeometry(sPath.toShapes(true));
    }

    //
    let translationMatrix = new THREE.Matrix4().set(
        -1, 0, 0, -windowL / 2,
        0, 1, 0, -windowH / 2,
        0, 0, 1, 0,
        0, 0, 0, 1,
    );
    windowGeom.applyMatrix4(translationMatrix);

    return windowGeom;
}

export function generateWindowGeometry(lineInfo: any, node2: any, YPos: number, floorHeight: number, depth: number = wallThickness * 3, forShape: boolean = false, shiftBack: number = wallThickness / 2) : THREE.BufferGeometry | undefined {
    let //lastIndex = -1,
        windowLength = 0;
    let windowGeom: THREE.BufferGeometry[] = [];
    const vec2X = new THREE.Vector2(1, 0);

    lineInfo.forEach((l: any) => {
        switch (l.lineType) {
            case 'LT_OUTERWINDOW':
            case 'LT_LIGHTWINDOW':
            case 'LT_INNERWINDOW':
            case 'LT_WINDOW':
            case 'LT_VIEWWINDOW':
            case 'LT_LVWINDOW':
                let stP = new THREE.Vector2(node2[l.start].x, node2[l.start].y);
                let endP = new THREE.Vector2(node2[l.end].x, node2[l.end].y);
                // if(lastIndex == l.start)
                // {
                //   windowLength += stP.distanceTo(endP);
                // }
                // else
                // {
                windowLength = stP.distanceTo(endP);
                // }

                //box 생성
                let windowOffset = (windowLength > 2.6 ? 0.2 : 0.6);
                let windowHeight = floorHeight - windowOffset * 2;
                let boxGeom;
                if (forShape) {
                    boxGeom = generateShapeWindow(stP, endP, windowHeight);
                } else {
                    boxGeom = new THREE.BoxBufferGeometry(windowLength, windowHeight, depth);
                }

                //창문 방향
                let windowDir = new THREE.Vector2().subVectors(endP, stP).normalize();

                let angle = vec2X.angle() - windowDir.angle();

                let shiftMatrix = new THREE.Matrix4().set(
                    1, 0, 0, 0,
                    0, 1, 0, 0,
                    0, 0, 1, shiftBack,
                    0, 0, 0, 1,
                );
                // boxGeom.applyMatrix4(shiftMatrix);

                let rotationMatrix = new THREE.Matrix4().set(
                    Math.cos(angle), 0, Math.sin(angle), 0,
                    0, 1, 0, 0,
                    -Math.sin(angle), 0, Math.cos(angle), 0,
                    0, 0, 0, 1
                );
                // boxGeom.applyMatrix4(rotationMatrix);

                //중심점
                let midPnt = new THREE.Vector2();
                midPnt.lerpVectors(stP, endP, 0.5);

                // lastIndex = l.end;
                let translationMatrix = new THREE.Matrix4().set(
                    1, 0, 0, midPnt.x,
                    0, 1, 0, YPos + windowOffset + windowHeight / 2,
                    0, 0, 1, midPnt.y,
                    0, 0, 0, 1,
                );
                // boxGeom.applyMatrix4(translationMatrix);

                boxGeom.applyMatrix4(translationMatrix.multiply(rotationMatrix).multiply(shiftMatrix));

                windowGeom.push(boxGeom);
                break;
            default:
                break;
        }
    });

    let result: THREE.BufferGeometry | undefined;
    if (windowGeom.length != 0) {
        result = BufferGeometryUtils.mergeBufferGeometries(windowGeom);

        //색상 적용을 위해 그룹 분리를 해줘야 함
        if (forShape) {
            result.clearGroups();

            let lastCount = 0;
            windowGeom.forEach((w: THREE.BufferGeometry) => {
                // let geom1Index = w.groups[0].
                // let geom2Index = (w.index as THREE.BufferAttribute).count;
                let matIndex = 0;
                w.groups.forEach((g: any) => {
                    result!.addGroup(g.start + lastCount, g.count, matIndex++);
                });
                // lastCount += w.getAttribute('position').count;
                lastCount += w.index!.count;
            });
        } else {
            result.clearGroups();
        }
    }
    return result;
}

//Operation: 'subtract', 'intersect', 'union'
export function doCSG(MeshTarget: THREE.Mesh, MeshTool: THREE.Mesh, Operation: string, Material: THREE.Material) : THREE.Mesh {
    let bspA = CSG.fromMesh(MeshTarget);
    let bspB = CSG.fromMesh(MeshTool);
    let bspC = bspA[Operation](bspB);
    let result = CSG.toMesh(bspC, MeshTarget.matrix);
    result.material = Material;
    result.castShadow = result.receiveShadow = true;
    return result;
}

function fromGeom(geometry: THREE.BufferGeometry | THREE.Geometry) {
    let csg = CSG.fromGeometry(geometry);
    let tmpm3 = new THREE.Matrix3();
    let ttvv0 = new THREE.Vector3();
    let matrix = new THREE.Matrix4().identity();
    tmpm3.getNormalMatrix(matrix);
    for (let i = 0; i < csg.polygons.length; i++) {
        let p = csg.polygons[i]
        for (let j = 0; j < p.vertices.length; j++) {
            let v = p.vertices[j]
            v.pos.copy(ttvv0.copy(v.pos).applyMatrix4(matrix));
            v.normal.copy(ttvv0.copy(v.normal).applyMatrix3(tmpm3))
        }
    }

    return csg;
}

export function doCSGbyGeometry(GeomTarget: THREE.BufferGeometry | THREE.Geometry, GeomTool: THREE.BufferGeometry | THREE.Geometry, Operation: string, buffered = true) : THREE.BufferGeometry {
    let bspA = fromGeom(GeomTarget);
    let bspB = fromGeom(GeomTool);
    let bspC = bspA[Operation](bspB);
    return CSG.toGeometry(bspC, buffered);
}

//전후 라인의 방향이 같을 경우 하나로 합침
export function SimplifyLineSegments(segments: THREE.Vector2[]) : THREE.Vector2[] {
    //console.log("before SimplifyLineSegments", segments);
    let result = [];

    for (let i = 0; i < segments.length; i++) {
        let v1 = new THREE.Vector2().subVectors(segments[i - 1 < 0 ? segments.length - 1 : i - 1], segments[i]);
        let v2 = new THREE.Vector2().subVectors(segments[i + 1 === segments.length ? 0 : i + 1], segments[i]);

        // let aaa = v2.angle() - v1.angle();
        let angle = Math.round((v2.angle() - v1.angle()) * 10000);
        // let angle1 = Math.ceil((v2.angle() - v1.angle()) * 10000);

        //방향이 같은 경우
        if (angle * angle !== 31416 * 31416) //31416 : 파이 x 10000하고 소수점 날린 값임
        {
            result.push(segments[i]);
        }
    }
    //console.log("after SimplifyLineSegments", result);
    return result;
}

//주어진 값으로 Offset, CCW기준 양수가 안쪽임
export function OffsetLineSegments(offsetValue: number, segments: THREE.Vector2[]) : THREE.Vector2[] {
    let result = [];

    let offset = new THREE.BufferAttribute(new Float32Array([offsetValue, 0, 0]), 3);
    console.debug("offset", offset);

    for (let i = 0; i < segments.length; i++) {
        let v1 = new THREE.Vector2().subVectors(segments[i - 1 < 0 ? segments.length - 1 : i - 1], segments[i]);    //이전점과 현재점 방향
        let v2 = new THREE.Vector2().subVectors(segments[i + 1 === segments.length ? 0 : i + 1], segments[i]);      //다음점과 현재점 방향

        let angle = v2.angle() - v1.angle();    //위 두 벡터의 각도
        let halfAngle = angle * 0.5;
        let tempAngle = v2.angle() + Math.PI * 0.5; //회전 할 각도

        let shift = Math.tan(halfAngle - Math.PI * 0.5);  //-Y 방향으로 shift할 값 계산
        let shiftMatrix = new THREE.Matrix4().set(
            1, 0, 0, 0,
            -shift, 1, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        );

        let rotationMatrix = new THREE.Matrix4().set(
            Math.cos(tempAngle), -Math.sin(tempAngle), 0, 0,
            Math.sin(tempAngle), Math.cos(tempAngle), 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        );

        let translationMatrix = new THREE.Matrix4().set(
            1, 0, 0, segments[i].x,
            0, 1, 0, segments[i].y,
            0, 0, 1, 0,
            0, 0, 0, 1,
        );

        let cloneOffset = offset.clone();
        // console.debug("cloneOffset", cloneOffset);

        cloneOffset.applyMatrix4(shiftMatrix);
        cloneOffset.applyMatrix4(rotationMatrix);
        cloneOffset.applyMatrix4(translationMatrix);

        result.push(new THREE.Vector2(Math.round(cloneOffset.getX(0) * 1000) / 1000, Math.round(cloneOffset.getY(0) * 1000) / 1000));
    }

    // let testAngle = 0.785;
    // let rotationMatrix = new THREE.Matrix4().set(
    //     Math.cos(testAngle), -Math.sin(testAngle), 0, 0,
    //     Math.sin(testAngle), Math.cos(testAngle), 0, 0,
    //     0, 0, 1, 0,
    //     0, 0, 0, 1
    // );
    // let abcdVec = new THREE.Vector3(1 ,-2.414, 0);
    // abcdVec.applyMatrix4(rotationMatrix);

    return result;
}

export function multiOffsetTestFunc(segments: THREE.Vector2[], height: number) {
    let offsetCVS = new THREE.Geometry();
    // let res = OffsetLineSegmentsByMultiValue(0.5, segments);

    let simplifiedNode2 = SimplifyLineSegments(segments);
    let res = OffsetLineSegmentsByMultiValue(simplifiedNode2, wallThickness / 2);
    // let res2 = OffsetLineSegments(wallThickness / 2, simplifiedNode2);

    // res.forEach((v: any) => {
    //     offsetCVS.vertices.push(new THREE.Vector3(v.x, height, v.y));
    // });
    for (let i = 0; i < res.length; i++) {
        let stP = res[i - 1 < 0 ? res.length - 1 : i - 1];
        let endP = res[i];

        offsetCVS.vertices.push(new THREE.Vector3(stP.x, height, stP.y));
        offsetCVS.vertices.push(new THREE.Vector3(endP.x, height, endP.y));

        let stOP = simplifiedNode2[i - 1 < 0 ? res.length - 1 : i - 1];
        let endOP = simplifiedNode2[i];
        offsetCVS.vertices.push(new THREE.Vector3(stOP.x, height, stOP.y));
        offsetCVS.vertices.push(new THREE.Vector3(endOP.x, height, endOP.y));

        // let stOP1 = res2[i - 1 < 0 ? res.length - 1 : i - 1];
        // let endOP1 = res2[i];
        // offsetCVS.vertices.push(new THREE.Vector3(stOP1.x, height, stOP1.y));
        // offsetCVS.vertices.push(new THREE.Vector3(endOP1.x, height, endOP1.y));
    }

    return offsetCVS;
}

//라인별 각기 다른 값의 오프셋 커브 생성, offsetValue의 갯수는 segments의 갯수와 같아야함
//segment 0, 1 : offset 0
//segment 1, 2 : offset 1
//...
//segment n, 0 : offset n
function OffsetLineSegmentsByMultiValue(segments: THREE.Vector2[], offsetValue: number) : THREE.Vector2[] {  //offsetValue 나중에 배열로 수정해야 됨
    //나중에 두 변수의 갯수 검사 구문 추가하기

    let offsetValues = new Array(segments.length);
    offsetValues.fill(offsetValue);

    //test
    offsetValues[0] *= 2;
    // offsetValues[1] *= 2;
    // offsetValues[2] *= 2;
    // offsetValues[offsetValues.length - 2] *= 2;

    let result = [];
    for (let i = 0; i < segments.length; i++) {
        let fstOffset = offsetValues[i - 1 < 0 ? segments.length - 1 : i - 1],
            sndOffset = offsetValues[i];
        let restOffsets = [fstOffset > sndOffset ? fstOffset - sndOffset : 0, fstOffset < sndOffset ? sndOffset - fstOffset : 0];

        //이전점과 현재점 방향
        let v1 = new THREE.Vector2().subVectors(segments[i - 1 < 0 ? segments.length - 1 : i - 1], segments[i]);

        //다음점과 현재점 방향
        let v2 = new THREE.Vector2().subVectors(segments[i + 1 === segments.length ? 0 : i + 1], segments[i]);

        //위 두 벡터의 각도
        let angle = v2.angle() - v1.angle();
        let halfAngle = angle * 0.5;

        //회전 할 각도
        let tempAngle = v2.angle() + Math.PI * 0.5;

        let shift = Math.tan(halfAngle - Math.PI * 0.5);  //-Y 방향으로 shift할 값 계산
        if (0 > shift) {
            //오프셋이 밖으로 빠지는 경우는 x, y offset 값이 바뀌어야 함
            [restOffsets[0], restOffsets[1]] = [restOffsets[1], restOffsets[0]];
        }

        //offset 둘 중 작은 값으로 설정
        let minOffset = Math.min(fstOffset, sndOffset);
        if (0 != restOffsets[0] || 0 != restOffsets[1]) {
            let newV = new THREE.Vector2(minOffset, minOffset);

            newV.y += 0 != restOffsets[0] ? restOffsets[0] : 0;
            newV.x += 0 != restOffsets[1] ? restOffsets[1] : 0;

            let xyshiftAngle = ((2 * Math.PI) - angle) / 2 % (Math.PI / 2);
            xyshiftAngle -= newV.angle();

            newV.applyMatrix3(new THREE.Matrix3().set(
                Math.cos(xyshiftAngle), -Math.sin(xyshiftAngle), 0,
                Math.sin(xyshiftAngle), Math.cos(xyshiftAngle), 0,
                0, 0, 1
            ));
            minOffset = 0 != restOffsets[0] ? newV.y : newV.x;

            tempAngle += xyshiftAngle;
        }

        let offset = new THREE.BufferAttribute(new Float32Array([minOffset, 0, 0]), 3);

        let shiftMatrix = new THREE.Matrix4().set(
            1, 0, 0, 0,
            -shift, 1, 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        );

        let rotationMatrix = new THREE.Matrix4().set(
            Math.cos(tempAngle), -Math.sin(tempAngle), 0, 0,
            Math.sin(tempAngle), Math.cos(tempAngle), 0, 0,
            0, 0, 1, 0,
            0, 0, 0, 1
        );

        let translationMatrix = new THREE.Matrix4().set(
            1, 0, 0, segments[i].x,
            0, 1, 0, segments[i].y,
            0, 0, 1, 0,
            0, 0, 0, 1,
        );

        let cloneOffset = offset.clone();
        console.debug("cloneOffset", cloneOffset);

        cloneOffset.applyMatrix4(shiftMatrix);
        cloneOffset.applyMatrix4(rotationMatrix);
        cloneOffset.applyMatrix4(translationMatrix);

        result.push(new THREE.Vector2(Math.round(cloneOffset.getX(0) * 1000) / 1000, Math.round(cloneOffset.getY(0) * 1000) / 1000));
        // result.push(new THREE.Vector2(cloneOffset.getX(0), cloneOffset.getY(0)));
    }

    return result;
}

function SignedVolumeOfTriangle(p1: THREE.Vector3, p2: THREE.Vector3, p3: THREE.Vector3) {
    let v321 = p3.x * p2.y * p1.z;
    let v231 = p2.x * p3.y * p1.z;
    let v312 = p3.x * p1.y * p2.z;
    let v132 = p1.x * p3.y * p2.z;
    let v213 = p2.x * p1.y * p3.z;
    let v123 = p1.x * p2.y * p3.z;
    return (1 / 6) * (-v321 + v231 + v312 - v132 - v213 + v123);
}

// function VolumeOfMesh(mesh: THREE.Mesh) {
//     // let vols = from t in mesh.Triangles
//
//     // select SignedVolumeOfTriangle(t.P1, t.P2, t.P3);
//
//     // return Math.Abs(vols.Sum());
//
// }