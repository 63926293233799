import * as THREE from '@teneleven/three';
import {FeatureGenerator} from "./GeneratorInterface";
// import {MaterialManager, MatType} from "../MaterialManager";
import {DataForLevel, LevelGenerator} from './LevelGenerator';


export class RoofTopGenerator extends FeatureGenerator {

    constructor(allBuilding: THREE.Group) {
        super(allBuilding);
    }

    public Generate(data: DataForLevel) : void {
        let nodeData = data.nodes[0];
        const eachLevel = data.level_mesh;

        if (undefined != eachLevel && data.with_roof) {
            let roofTopGeom = LevelGenerator.generateFloorCeilingGeometry(nodeData.node, nodeData.building_height + data.level_height);
            let roofTopMesh = new THREE.Mesh(roofTopGeom, [this._ceilingMaterial, this._wallcoreMaterial[data.level_type], this._wallcoreMaterial[data.level_type]]);
            roofTopMesh.name = eachLevel.name;
            roofTopMesh.layers.set(2);
            nodeData.buildingGroup.add(roofTopMesh);
            // eachLevel.add(roofTopMesh);

            //
            if (data.with_parapet) {

            } else {
                if (data.with_roof_line && 'FC_HOUSE' == data.level_type) {
                    nodeData.roofLineHeight = nodeData.building_height + data.level_height;

                    // let roofLineGeom = generateTopRoofLineGeometry(nodeData.node, nodeData.building_height + data.level_height);
                    // let roofLineMesh = new THREE.Mesh(roofLineGeom, this._wallcoreMaterial['FC_CORE']);
                    // roofLineMesh.name = eachLevel.name;
                    // roofLineMesh.layers.set(2);
                    // nodeData.buildingGroup.add(roofLineMesh);
                }
            }
        }
    }

    public Dispose() {
    }
}