import React, { Component, Fragment } from "react";
import App from "./App";
import BuilditInput, { BuilditInputProps } from "./BuilditInput";
import "./css/SearchPlaceInput.scss";
// @ts-ignore
import Highlighter from "react-highlight-words";
import DrawingManager2 from "./DrawingManager2";
import { CircularProgress, ClickAwayListener, IconButton } from "@material-ui/core";
import { default as _ } from "lodash";
import { latlng2tm } from './CADConverter/SceneManager'
import * as THREE from "@teneleven/three";

import CloseIcon from "@material-ui/icons/Close";

interface SearchLogData {
  address: string;
  searchDate: string;
  center: {
    lat: number;
    lng: number;
  };
  isLand: boolean;
}

export interface GeocodeResult {
  addressElements: Array<any>;
  jibunAddress: string;
  roadAddress: string;
  x: string;
  y: string;
}

export interface AddressResult {
  geom: string;
  pnu: string;
  address: string;
}

export interface PlaceResult {
  name: string;
  road_address: string;
  jibun_address: string;
  phone_number: string;
  x: string;
  y: string;
  address_type?: string;
  category_name?: string;
  category_group_name?: string;
}

interface SearchLog {
  text: string;
  date: string;
}

export interface SearchPlaceInputProps extends BuilditInputProps {
  className?: string;
  geocodeResult?: Array<GeocodeResult>;
  addressResult?: Array<AddressResult>;
  placeResult?: Array<PlaceResult>;
  loading?: boolean;
  onSelectItem?: (center: { lat: number; lng: number }, isLand: boolean) => void;
  moveToAddress: (lat: number, lng: number) => void;
  initiateResult: () => void;
}

export interface SearchPlaceInputState {
  onSearchLog: boolean;
  searchLogList: Array<SearchLogData>;
  selectIndex?: number;
  focus: boolean;
}

export default class SearchPlaceInput extends Component<SearchPlaceInputProps, SearchPlaceInputState> {
  state: SearchPlaceInputState = {
    onSearchLog: true,
    searchLogList: [],
    focus: false,
  };

  static SEARCH_LOG_NAME = "address-search-log";

  componentWillMount = () => {
    document.addEventListener("keydown", this.keyPressControlToList);
    this.getSearchLog();
  };
  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.keyPressControlToList);
  };

  keyPressControlToList = (e: any) => {
    // console.log('keyPressControlToList', e)
    // up: 38 down: 40
    if (this.state.selectIndex !== undefined && this.state.selectIndex >= 0 && this.state.focus) {
      const searchWrapper = this.refs["search-wrapper"] as HTMLDivElement;
      const curItem = this.refs[`search-index-${this.state.selectIndex}`] as HTMLDivElement;
      const listLength = (this.props.value as string).length === 0 ? this.getSearchLogLength() : this.getSearchLength();

      switch (e.key) {
        case "ArrowUp":
          if (curItem.offsetTop! < searchWrapper.scrollTop + searchWrapper.offsetTop!) {
            searchWrapper.scroll({ top: searchWrapper.scrollTop - curItem.scrollHeight });
          }
          this.setState({ selectIndex: this.state.selectIndex - 1 >= 0 ? this.state.selectIndex - 1 : this.state.selectIndex });
          break;
        case "ArrowDown":
          if (searchWrapper.offsetHeight - searchWrapper.offsetTop! / 2 < curItem.offsetTop! + curItem.scrollHeight) {
            // 아래로 넘칠 때
            searchWrapper.scroll({ top: searchWrapper.scrollTop + curItem.scrollHeight });
          }
          this.setState({ selectIndex: this.state.selectIndex + 1 < listLength ? this.state.selectIndex + 1 : this.state.selectIndex });
          break;
      }
    }
  };

  componentDidUpdate = (pp: Readonly<SearchPlaceInputProps>, ps: Readonly<SearchPlaceInputState>) => {
    const curResult = {
      geocodeResult: this.props.geocodeResult,
      addressResult: this.props.addressResult,
      placeResult: this.props.placeResult,
    };
    const preResult = {
      geocodeResult: pp.geocodeResult,
      addressResult: pp.addressResult,
      placeResult: pp.placeResult,
    };
    // 검색결과가 달라지는 케이스
    if (!_.isEqual(curResult, preResult)) {
      this.setState({ selectIndex: undefined, focus: true }, () => {
        if (this.getSearchLength() > 0) {
          if (this.state.selectIndex === undefined) {
            this.setState({ selectIndex: 0 });
          } else if (!_.isEqual(this.state.selectIndex, ps.selectIndex)) {
            this.setState({ selectIndex: this.state.selectIndex });
          }
        }
      });
    }

    // 검색 기록 on/off case
    // 1) focus 유지하면서 검색어가 있다 없는 경우
    // 2) focus 가 있다 없는 경우
    // 3) 검색 -> 선택이후 selectIndex가 undefined 되는경우
    if (this.state.focus && !_.isEqual(this.props.value, pp.value) && (this.props.value as string).length === 0) {
      this.setState({ selectIndex: 0 });
    }
    if (!_.isEqual(this.state.focus, ps.focus) && this.state.focus) {
      this.setState({ selectIndex: 0 });
    }
    if (this.state.focus && !_.isEqual(this.state.selectIndex, ps.selectIndex) && this.state.selectIndex === undefined) {
      this.setState({ selectIndex: 0 });
    }
  };

  getSearchLength = () => {
    return (
      (this.props.geocodeResult !== undefined ? this.props.geocodeResult.length : 0) +
      (this.props.addressResult !== undefined ? this.props.addressResult.length : 0) +
      (this.props.placeResult !== undefined ? this.props.placeResult.length : 0)
    );
  };
  getSearchLogLength = () => {
    const lsData = window.localStorage.getItem(SearchPlaceInput.SEARCH_LOG_NAME);
    return lsData === null ? 0 : JSON.parse(lsData).length;
  };

  render() {
    let index = 0;

    return (
      <ClickAwayListener
        onClickAway={() => {
          this.setState({ focus: false });
        }}
      >
        <div className={`SearchPlaceInput ${this.props.className || ""}`}>
          <BuilditInput
            {...this.props}
            className={`${(this.getSearchLength() > 0 && (this.props.value as string).length > 0 && "open") || ""}
              ${(this.state.focus && " open") || ""}`}
            onKeyDown={(e: any) => {
              switch (e.key) {
                case "ArrowUp": // up
                case "Process": // input's last charcter
                  e.preventDefault();
                  break;
                case "ArrowDown": // down
                  if (this.state.focus === false) {
                    this.setState({ focus: true });
                  }
                  e.preventDefault();
                  break;
                case "Enter": // enter
                  if (this.state.focus === false) {
                    this.setState({ focus: true });
                  } else if (this.state.selectIndex !== undefined && this.refs[`search-index-${this.state.selectIndex}`] !== undefined) {
                    setTimeout(() => (this.refs[`search-index-${this.state.selectIndex}`] as HTMLDivElement).click(), 500);
                  }
                  break;
              }
            }}
            onFocus={() => {
              this.setState({ focus: true });
            }}
            onClick={() => {
              this.setState({ focus: true });
            }}
          />
          {
            // (this.state.focus && (
            //   (this.props.geocodeResult && this.props.geocodeResult.length > 0) ||
            //   (this.props.placeResult && this.props.placeResult.length > 0) ||
            //   (this.props.addressResult && this.props.addressResult.length > 0) ||
            //   (this.state.searchLogList && this.state.searchLogList.length > 0)
            // )) &&
            <div className={`search-wrapper ${((this.props.value as string).length === 0 && "empty") || ""}`} ref="search-wrapper">
              {(this.props.value && (this.props.value as string).length > 0 && (
                <Fragment>
                  {this.props.geocodeResult &&
                    this.props.geocodeResult.map((p) => {
                      return (
                        <div
                          className={`item geocde ${(this.state.selectIndex === index && "active") || ""}`}
                          ref={`search-index-${index++}`}
                          onClick={() => {
                            this.props.onSelectItem && this.onSelectItemGeocode(p);
                          }}
                        >
                          <div className="body">
                            <div className="name">
                              <Highlighter
                                highlightClassName="highlight"
                                searchWords={this.props.value && (this.props.value as string).split(" ")}
                                textToHighlight={p.jibunAddress}
                                autoEscape={true}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {this.props.placeResult &&
                    this.props.placeResult.map((p) => {
                      return (
                        <div
                          className={`item place ${(this.state.selectIndex === index && "active") || ""}`}
                          ref={`search-index-${index++}`}
                          onClick={() => {
                            this.props.onSelectItem && this.onSelectItemPlace(p);
                          }}
                        >
                          <div className="body">
                            <div className="name">
                              <Highlighter highlightClassName="highlight" searchWords={this.props.value && (this.props.value as string).split(" ")} textToHighlight={p.name} autoEscape={true} />
                            </div>
                            <div className="address">{p.jibun_address}</div>
                          </div>
                          {p.address_type === "KEYWORD" && <div className="keyword">{p.category_name!.split(">").pop()!.toString().split(",").pop()}</div>}
                        </div>
                      );
                    })}
                  {this.props.addressResult &&
                    this.props.addressResult.map((p) => {
                      return (
                        <div
                          className={`item address ${(this.state.selectIndex === index && "active") || ""}`}
                          ref={`search-index-${index++}`}
                          onClick={() => {
                            // const isMultiPolygon = Boolean(p.geom.match("MULTIPOLYGON"));
                            // const path = DrawingManager2.toGeom(p.geom, isMultiPolygon && "Field" || "Polygon").coordinates[0];
                            // let sumLat = 0;
                            // let sumLng = 0;
                            // for (let i = 0; i < path.length; i++) {
                            //   sumLat += path[i][1];
                            //   sumLng += path[i][0];
                            // }
                            this.props.onSelectItem && this.onSelectItemAddress(p);
                          }}
                        >
                          <div className="body">
                            <div className="name">
                              <Highlighter highlightClassName="highlight" searchWords={this.props.value && (this.props.value as string).split(" ")} textToHighlight={p.address} autoEscape={true} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {this.props.loading && (
                    <div className="loading-wrapper">
                      <CircularProgress className="loading" />
                    </div>
                  )}
                </Fragment>
              )) || (
                <Fragment>
                  {this.state.focus && (
                    <Fragment>
                      {this.state.onSearchLog && <div className="header">{(this.state.searchLogList.length > 0 && "최근 검색 기록") || "최근 검색 기록이 없습니다"}</div>}
                      {this.state.searchLogList.map((r, i: number) => {
                        return (
                          <div
                            className={`item log ${(this.state.selectIndex === index && "active") || ""}`}
                            ref={`search-index-${index++}`}
                            onClick={() => this.onSelectItem(r.center, r.address, r.isLand)}
                            key={`searchLogList ${i}`}
                          >
                            <div className="address">{r.address}</div>
                            <IconButton
                              disableRipple={true}
                              className="icon-btn m-l-a"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                this.deleteSearchLog(r.address);
                              }}
                            >
                              <CloseIcon className="icon" />
                            </IconButton>
                          </div>
                        );
                      })}
                      <div className="footer">
                        {(this.state.onSearchLog && (
                          <Fragment>
                            <div className="title" onClick={() => this.setOnSearchLog(false)}>
                              최근 검색 끄기
                            </div>
                            <div className="delete" onClick={() => this.deleteSearchLog()}>
                              전체 삭제
                            </div>
                          </Fragment>
                        )) || (
                          <Fragment>
                            <div className="title" onClick={() => this.setOnSearchLog(true)}>
                              최근 검색 켜기
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          }
        </div>
      </ClickAwayListener>
    );
  }
  moveMapAddressButNotSelect = (item: PlaceResult) => {
    // 지도 이동, select X
    this.props.moveToAddress(Number(item.y), Number(item.x));
    this.props.initiateResult();
    this.setState({ selectIndex: undefined, focus: false }, () => {
      if (this.state.onSearchLog) {
        this.setSearchLog(item.name, { lat: Number(item.y), lng: Number(item.x) }, false);
      }
    });
  };

  onSelectItemPlace = async (item: PlaceResult) => {
    if (item.address_type === "REGION" || item.address_type === "ROAD") {
      this.moveMapAddressButNotSelect(item)
    } else if (item.address_type === "KEYWORD" && item.road_address.length === 0) {
      const centerTM = latlng2tm(new THREE.Vector2(Number(item.x), Number(item.y)));
      let isLand: boolean = false;
      try {
        const r = await App.API_Axios.post(`${App.API_URL}/GetFieldInfoAtPoint`, {
          ...centerTM,
        });
        isLand = true
        this.onSelectItem({ lat: Number(item.y), lng: Number(item.x) }, item.jibun_address, true);
      } catch (e) {
        this.moveMapAddressButNotSelect(item);
      }
    } else {this.onSelectItem({ lat: Number(item.y), lng: Number(item.x) }, item.jibun_address, true)};
  };
  onSelectItemGeocode = (item: GeocodeResult) => {
    const landIndex = item.addressElements.findIndex((e) => e.types.includes("LAND_NUMBER"));
    let isLand = false;
    if (landIndex > -1) {
      const ae = item.addressElements[landIndex];
      isLand = (ae.longName && ae.longName.length > 0) || (ae.shortName && ae.shortName.length > 0);
    }
    this.onSelectItem({ lat: Number(item.y), lng: Number(item.x) }, item.jibunAddress, isLand);
  };

  onSelectItemAddress = (item: AddressResult) => {
    const isMultiPolygon = Boolean(item.geom.match("MULTIPOLYGON"));
    const path = DrawingManager2.toGeom(item.geom, (isMultiPolygon && "Field") || "Polygon").coordinates[0];
    let sumLat = 0;
    let sumLng = 0;
    for (let i = 0; i < path.length; i++) {
      sumLat += path[i][1];
      sumLng += path[i][0];
    }

    this.onSelectItem({ lat: sumLat / path.length, lng: sumLng / path.length }, item.address, true);
  };

  onSelectItem = async (center: { lat: number; lng: number }, address: string, isLand: boolean) => {
    const searchAddress = address.replace(/\s{2,}/gi, " ").trim(); // 특정 케이스에 공백 2칸이상 데이터가있음
    this.props.onSelectItem && this.props.onSelectItem(center, isLand);
    this.setState({ selectIndex: undefined, focus: false }, () => {
      if (this.state.onSearchLog) {
        this.setSearchLog(searchAddress, center, isLand);
      }
    });
  };

  setSearchLog = (address: string, center: { lat: number; lng: number }, isLand: boolean) => {
    const lsData = window.localStorage.getItem(SearchPlaceInput.SEARCH_LOG_NAME);

    if (lsData !== null) {
      const searchLog: Array<SearchLogData> = JSON.parse(lsData);

      const sIndex = searchLog.findIndex((s) => s.address === address);
      if (sIndex > -1) {
        searchLog.splice(sIndex, 1);
      } else if (searchLog.length > 9) {
        searchLog.splice(9, 1);
      }

      searchLog.push({
        address: address,
        searchDate: new Date().toISOString(),
        center: center,
        isLand: isLand,
      });

      searchLog.sort((a, b) => (a.searchDate > b.searchDate ? -1 : a.searchDate < b.searchDate ? 1 : 0));

      window.localStorage.setItem(SearchPlaceInput.SEARCH_LOG_NAME, JSON.stringify(searchLog));
    } else {
      // local storage empty
      window.localStorage.setItem(
        SearchPlaceInput.SEARCH_LOG_NAME,
        JSON.stringify([
          {
            address: address,
            searchDate: new Date().toISOString(),
            center: center,
          },
        ])
      );
    }
    this.getSearchLog();
  };

  getSearchLog = () => {
    const lsData = window.localStorage.getItem(SearchPlaceInput.SEARCH_LOG_NAME);
    this.setState({
      searchLogList: lsData === null ? [] : JSON.parse(lsData),
      focus: false,
    });
  };

  setOnSearchLog = (on: boolean) => {
    this.setState({ onSearchLog: on }, () => {
      this.deleteSearchLog();
    });
  };

  deleteSearchLog = (address?: string) => {
    const lsData = window.localStorage.getItem(SearchPlaceInput.SEARCH_LOG_NAME);

    if (lsData === null) {
      return;
    }

    let searchLog: Array<SearchLogData> = JSON.parse(lsData);

    if (address) {
      const delIndex = searchLog.findIndex((s) => s.address === address);

      if (delIndex > -1) {
        searchLog.splice(delIndex, 1);
      }
    } else {
      // all delete
      searchLog = [];
    }

    window.localStorage.setItem(SearchPlaceInput.SEARCH_LOG_NAME, JSON.stringify(searchLog));
    this.getSearchLog();
  };
}
