import * as THREE from '@teneleven/three';
import {DataForGenerator, FeatureGenerator} from "./GeneratorInterface";
import {SimplifyLineSegments, OffsetLineSegments, addPathToShapePath} from "../GeomUtilities";
import {MaterialManager, MatType} from "../MaterialManager";

export interface DataForPiloti extends DataForGenerator {
    level_height: number,
    level_index: number,
}

export class PilotiGenerator extends FeatureGenerator {

    constructor(allBuilding: THREE.Group) {
        super(allBuilding);
    }

    public Generate(data: DataForPiloti) : void {
        let nodeData = data.nodes[0];

        let pilotiGeom = PilotiGenerator.generatePilotiGeometry(nodeData.node, nodeData.building_height, data.level_height);
        if (pilotiGeom) {
            let pilotiMesh = new THREE.Mesh(pilotiGeom, this._wallcoreMaterial['FC_CORE']);
            pilotiMesh.name = 'Level ' + `${data.level_index + 1}`;
            nodeData.buildingGroup.add(pilotiMesh);
        }
    }

    public Dispose() {
    }

    private static generatePilotiGeometry(node2: THREE.Vector2[], YPos: number, height: number) : THREE.BufferGeometry {
        const beamWidth = FeatureGenerator._wallThickness;

        // let offsetCVS = new THREE.Geometry();
        let simplifiedNode2 = SimplifyLineSegments(node2);
        let segments = OffsetLineSegments(beamWidth, simplifiedNode2);

        let sPath = new THREE.ShapePath();
        //
        for (let i = 0; i < segments.length; i++) {
            let base1 = new THREE.Vector2().subVectors(segments[i - 1 < 0 ? segments.length - 1 : i - 1], segments[i]).normalize().multiplyScalar(beamWidth);    //이전점과 현재점 방향
            let base2 = new THREE.Vector2().subVectors(segments[i + 1 === segments.length ? 0 : i + 1], segments[i]).normalize().multiplyScalar(beamWidth);      //다음점과 현재점 방향

            let angle = base2.angle() - base1.angle();    //위 두 벡터의 각도
            let shift = Math.tan(angle * 0.5 - Math.PI * 0.5);  //-Y 방향으로 shift할 값 계산

            let base1m = new THREE.Vector2(-base1.x, -base1.y);

            let vertices = [];
            vertices.push(new THREE.Vector2().addVectors(segments[i], new THREE.Vector2().subVectors(base1m, base2)));
            vertices.push(new THREE.Vector2().addVectors(segments[i], new THREE.Vector2().addVectors(base1m, base2)));
            vertices.push(new THREE.Vector2().addVectors(segments[i], new THREE.Vector2().addVectors(base1, base2)));
            vertices.push(new THREE.Vector2().addVectors(segments[i], new THREE.Vector2().subVectors(base1, base2)));

            if (0 > shift) {
                vertices = vertices.reverse();
            }

            addPathToShapePath(sPath, vertices);
        }

        let geom = new THREE.ExtrudeBufferGeometry(sPath.toShapes(true), {
            steps: 1,
            depth: height,
            bevelEnabled: false
        });

        let matrix = new THREE.Matrix4();
        matrix.set(-1, 0, 0, 0,
            0, 0, 1, YPos,
            0, 1, 0, 0,
            0, 0, 0, 1);
        geom.applyMatrix4(matrix);
        geom.computeVertexNormals();
        return geom;
    }
}