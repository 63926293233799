import * as THREE from '@teneleven/three';
import { degrees2radians, distance, radiansToDegrees } from '@turf/turf';
import { BuildingBlockType, BuildingComponent } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { CheckVerticesArrayisCCW, getPolygonType} from './CoreAndHouseController';
import { CompletenessType, ConverterUnit, LineType, makeHouseState, Polygon, PolylineInfo } from './DataTypes';
import { ErrorLogCell2, ErrorType, makeInfoInformation } from './ErrorLog';
import { switchLineDashedState } from './FileParser';
import { checkPointOnLine, checkWindowLength, PolygonSimplification } from './PolygonManager';
import userSettingData from './SettingModal';
import { default as _ } from 'lodash';
import { getCircle, getCurveErrorCircleBlock, getLineErrorCircleBlock } from './MeshMaker';
import * as jsts from 'jsts';
import { changeLineColorRed, WindowInfoType } from './CheckTypeBlockError';
import { ErrorList, TypeError } from './Error';

export interface WindowType {
  polygon: Polygon,
  lineType: LineType,
  maked: boolean
}

export class BuildingHouseUnit extends BuildingComponent {
  readonly componentType: 'core' | 'house';
  exclusiveArea: number; // 전용 면적
  serviceArea: number; // 발코니 면적
  balconyOver150cm: number;
  balconyLess150cm: number;
  commonWallArea: number;
  levelHeights: number[];
  piloti: number;
  complete: CompletenessType;
  ErrorLog: ErrorLogCell2[];
  ErrorPolygonGroup: THREE.Group;
  name: string;
  isFirstMadenHouse: boolean;
  lineState: CompletenessType;
  errorList: ErrorList;
  blockType: BuildingBlockType;
  initialArea: {
    exclusiveArea: number,
    serviceArea: number,
    commonWallArea: number,
  }
  private makeState: makeHouseState;

  constructor(block: ConverterBlock) {
    super(block);
    this.componentType = 'house';
    this.houseNumber = 1;
    this.complete = CompletenessType.complete;//.warning;
    this.blockType = BuildingBlockType.unit;
    this.exclusiveArea = 0;
    this.serviceArea = 0;
    this.balconyLess150cm = 0;
    this.balconyOver150cm = 0;
    this.commonWallArea = 0;
    this.levelHeights = [2.8];
    this.levelCount = this.level.length;
    this.piloti = 0;
    this.makeState = makeHouseState.Finish;
    this.ErrorLog = [];
    this.ErrorPolygonGroup = new THREE.Group();
    this.name = block.name;
    this.isFirstMadenHouse = false;
    this.lineState = CompletenessType.complete;
    this.errorList = new ErrorList();
    this.initialArea = {
      exclusiveArea: 0,
      serviceArea: 0,
      commonWallArea: 0,
    }
    this.setInitialVal();
    block!.entities.sort(function (a, b ) { // renderOrder : alphabet순 정렬 (CON위에 WIN이 오도록 한다)
      return a.layer.toUpperCase() > b.layer.toUpperCase() ? 1 : -1;
    })

    block!.entities.forEach(en => { 
      let p = (en as ConverterUnit).polygon;
      let newLineMesh = p.lineMesh.clone(); // material 참조방지
      newLineMesh.material = p.lineMesh.material.clone();

      let newInnerMesh = p.innerMesh.clone(); // material 참조방지
      //@ts-ignore
      newInnerMesh.material = p.innerMesh.material.clone();
      //@ts-ignore
      newInnerMesh.material.side = THREE.DoubleSide;

      let newPolygon: Polygon = {
        area: p.area,
        hasCurve: p.hasCurve,
        innerMesh: newInnerMesh,//p.innerMesh.clone(),
        lineMesh: newLineMesh,
        selected: p.selected,
        shape: p.shape,
        type: p.type,
        vertices: p.vertices,
        layer: en.layer,
      }

      this.AddNewPolygon(newPolygon);
    })
    this.delOverlapZeroLayer();
    
  }

  setInitialVal = () => {
    // 면적 초깃값
    let splited = this.name.split('_');
    if (splited.length >= 2) {
      if (isNaN(Number(splited[1]))) this.initialArea.exclusiveArea = 0;
      else this.initialArea.exclusiveArea = Number(splited[1]);
    }
    if (splited.length >= 3) {
      if (isNaN(Number(splited[2]))) this.initialArea.serviceArea = 0;
      else this.initialArea.serviceArea = Number(splited[2]);
    }
    if (splited.length >= 4) {
      if (isNaN(Number(splited[3]))) this.initialArea.commonWallArea = 0;
      else this.initialArea.commonWallArea = Number(splited[3]);
    }
    
  }
  
  setErrorList = (errorList: ErrorList) => {
    this.errorList = errorList;
  }

  delOverlapZeroLayer = () =>  { // 0레이어가 블록 내 다른 라인과 겹치면 삭제
    let zeroLayer: Polygon[] = [];
    let otherLayer: Polygon[] = [];

    this.polygon.forEach(poly => {
      if (poly.layer === "0") zeroLayer.push(poly);
      else otherLayer.push(poly);
    })

    let geoFac = new jsts.geom.GeometryFactory;
    let newPolygon = otherLayer;

    for (let i = 0; i < zeroLayer.length; i++) {
      let coords: jsts.geom.Coordinate[] = [];
      zeroLayer[i].vertices.forEach(v => { coords.push(new jsts.geom.Coordinate(v.x, v.y)) });
      let line1 = geoFac.createLineString(coords);
      let line2;
      for (let j = 0; j < otherLayer.length; j++) {
        let coords: jsts.geom.Coordinate[] = [];
        otherLayer[i].vertices.forEach(v => { coords.push(new jsts.geom.Coordinate(v.x, v.y)) });
        line2 = geoFac.createLineString(coords)
      }
      if (line2 && !line1.intersects(line2)) {
        this.renderGroup.add(zeroLayer[i].lineMesh);
        newPolygon.push(zeroLayer[i]);
      }
    }
    this.polygon = newPolygon;
  }

  getJSTSGeometryPolygon = () => {
    // this.renderGroup.updateWorldMatrix(true, true);

    // let matrixWorld = this.renderGroup.matrixWorld;
    // let coords: jsts.geom.Coordinate[] = [];

    // this.polygon.forEach(polygon => {
    //   if (polygon.shape) {
    //     polygon.vertices.forEach(v => {
    //       let newV = v.clone().applyMatrix4(matrixWorld);
    //       coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
    //     });

    //     let geoFac = new jsts.geom.GeometryFactory();
    //     let linearRing = geoFac.createLinearRing(coords);

    //     //@ts-ignore
    //     return geoFac.createPolygon(linearRing, []).buffer(0);
    //   }
    // })
    // return new jsts.geom.Geometry;
  }

  setCenterOfAllLine = () => {
    
    
   //  this.centerOfAllLine = GetPolygonCentroid2(block);
  }

  checkNoExistArea = (errorList: ErrorList) => {
    // 면적이 들어오지 않은 경우
    let splitName = this.name.split('_');
    if (splitName.length === 1 || (splitName.length === 2 && (isNaN(Number(splitName[1])) || Number(splitName[1]) === 0))) {
      // 1) 블록 이름에 면적 기입이 안된 경우, (예: H1, H2, C1 뒤에 면적이 안 적혔을 때
      errorList.addError(new TypeError({
        title: `[면적 오류] 저장이 불가합니다.`,
        msg: `${this.name} 에 면적이 존재하지 않습니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: [this.uuid],
        components: [this],
      }));
      this.complete = CompletenessType.error;
    }

    else {
      let sumArea = 0;
      this.polygon.forEach(poly => {
        // 2) 코어, 유닛 블록에 폴리곤은 있지만 계산된 면적이 0일 때
        sumArea += poly.area;
      })

      if (sumArea === 0) {
        errorList.addError(new TypeError({
          title: `[면적 오류] 저장이 불가합니다.`,
          msg: `${this.name} 에 면적이 존재하지 않습니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [this.uuid],
          components: [this],
        }));
        this.complete = CompletenessType.error;
      }
    }
  }

  checkZCoord = (errorList: ErrorList) => {
    let layerName = new Set();
    let polygonType = new Set();

    for (let i = 0; i < this.block.entities.length; i++) {
      let entity = this.block.entities[i];
      if ((entity as ConverterUnit).hasZCoord) {
        layerName.add(entity.layer);
        let findPolygon = this.polygon.filter(poly => entity.layer === poly.layer);
        findPolygon.forEach(poly => {
          polygonType.add(getPolygonType(poly.type, poly.shape));
        })
      }
    }
    if (layerName.size) {
      //@ts-ignore
      errorList.addError(new TypeError({
        type: ErrorType.Info,
        title: "[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.",
        msg: `${Array.from(layerName).join('/')} 의 Z값에 불필요한 ${Array.from(polygonType).join('/')}은 미반영 처리되었습니다.`,
        id: [this.uuid],
        components: [this],
      })
      );
      // makeWarningInformation2(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
      // `${Array.from(layerName).join('/')} 의 Z값에 불필요한 ${Array.from(polygonType).join('/')}은 미반영 처리되었습니다.`,
      // new THREE.Group(), [], { components: [this] }

     // if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
    }
  }
  
  CheckCompleteness = (errorList: ErrorList) => {

    // 면적
    this.checkAreaError(errorList);
    
    // z값
    this.checkZCoord(errorList);
    
    /* (U/C블록) 안에 N개의 (라인/폴리라인) 이 있을 경우 */
    // shape이 있을때
    if (this.polygon.filter(poly => poly.shape && poly.layer.toUpperCase() === "CON").length === 1 && 
    this.polygon.filter(poly => !poly.shape && poly.layer.toUpperCase() !== "WIN1" && poly.layer.toUpperCase() !== "WIN2").length > 0 ) {
      this.polygon.forEach(poly => {
        let layer = poly.layer.toUpperCase();
        if (!poly.shape && ["CON"].indexOf(layer) > -1) {
          errorList.addError(new TypeError({
            title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
            msg:  `${this.name}은 하나의 Polygon 만을 허용합니다. `,
            type: ErrorType.Warning,
            id: [this.uuid],
            //@ts-ignore
            polygons: [poly],
          }));
  
        }
      });
  
    }


    /* 면적이 들어오지 않은 경우 */
    this.checkNoExistArea(errorList);
    
    /* 창문이 폴리곤으로 들어온 경우 */
    this.polygon.forEach(poly => {
      let layer = poly.layer.toUpperCase();
      
      if ((layer === "WIN1" || layer === "WIN2") && poly.shape) {
        let group = new THREE.Group();
        this.renderGroup.updateWorldMatrix(true, true);
        let worldVerts: THREE.Vector3[] = [];
        poly.vertices.forEach(v => worldVerts.push(v.clone().applyMatrix4(this.renderGroup.matrixWorld)));
        
        let tmpDist = worldVerts[0].distanceTo(worldVerts[worldVerts.length-1]);
        let lastVert = worldVerts[worldVerts.length-1];

        for (let i = 1; i < worldVerts.length; i++) {
          if (worldVerts[0].distanceTo(worldVerts[i]) > tmpDist) {
            lastVert = worldVerts[i];
            tmpDist =worldVerts[0].distanceTo(worldVerts[i]);            
          }
        }

        let circleMesh = getCircle(worldVerts[0], lastVert, new THREE.Color(1, 0, 0), 5);
        
        group.add(circleMesh);
        this.ErrorPolygonGroup.add(group);
        errorList.addError(new TypeError({
          title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
          msg: `${poly.layer}에 Polygon 이 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [this.uuid],
          //@ts-ignore
          polygons: [poly],
        }));

        switchLineDashedState(poly.lineMesh.material, true); //x
        poly.lineMesh.material.color = new THREE.Color(1, 0, 0);
      }
    })


    //* 창문 최소길이
    this.checkWindows(errorList);
    


    //라운드
    this.checkRounded(errorList);

    //@ts-ignore
    if (this.complete === CompletenessType.error) {
      this.complete = CompletenessType.error;
      this.polygon.forEach(poly => {
        if (/^CON$/i.test(poly.layer)) {
          poly.lineMesh.material.color = new THREE.Color(1, 0, 0);
        }
        switchLineDashedState(poly.lineMesh.material, true);
      })
    }
    //@ts-ignore
    else if (this.complete === CompletenessType.warning) {
      this.polygon.forEach(poly => {
        switchLineDashedState(poly.lineMesh.material, true);  // O
      })
    }   
  }

  checkAreaError = (errorList: ErrorList) => {
    // 사용자가 입력한 전용 면적과
    // 계산면적 (= 최외각선 계산 면적값 - 사용자 서비스 면적 입력값 - 사용자 벽체 공용 면적 입력값) 비교
    // inputExclusiveArea === calcArea - 서비스면적 - 벽체공용
    const polygonArea = Number(this.polygon.map(p => p.area).reduce((a, b) => a + b, 0).toFixed(2));
    const inputArea = {
      exclusiveArea: 0,
      serviceArea: 0,
      commonWallArea: 0,
    };

    inputArea.exclusiveArea = this.exclusiveArea;
    inputArea.serviceArea = this.serviceArea;
    inputArea.commonWallArea = this.commonWallArea;
    errorList.delAreaError(this.uuid);

    let calcArea = Number((polygonArea - inputArea.serviceArea - inputArea.commonWallArea).toFixed(4));
    if (inputArea.exclusiveArea !== calcArea) {
      errorList.addError(new TypeError({
        title: `[면적 경고] 저장 시 입력 면적으로 반영됩니다.`,
        msg: `${this.name}의 사용자가 입력한 면적과 실제 폴리곤 면적이 서로 상이합니다.
        폴리곤 전체 입력 면적: ${inputArea.exclusiveArea.toFixed(4)} ㎡, 계산 면적: ${calcArea.toFixed(4)} ㎡
        차이: ${Math.abs(inputArea.exclusiveArea - calcArea).toFixed(4)} ㎡`,
        type: ErrorType.Warning,
        id: [this.uuid],
        components: [this],
      }));      

    
    }
  }

  getInputArea = () => {
    return this.exclusiveArea + this.serviceArea;
  }
  
  AddNewPolygon = (polygon: Polygon) => {
    this.polygon.push(polygon);
    let line = polygon.lineMesh.clone();
 //   line.material.opacity = 0.5;
    if ((!polygon.layer!.toUpperCase().startsWith("WIN") && !polygon.shape)) {      
      polygon.lineMesh.renderOrder = 1;
      switchLineDashedState(line.material, true); //x
      
    }
    else {
      switchLineDashedState(line.material, false); //x
    }

    let mesh = polygon.innerMesh//.clone();
    mesh.applyMatrix4(this.renderGroup.matrixWorld);
    
    if (polygon.layer !== "0") {
      this.renderGroup.add(mesh);
      this.renderGroup.add(line);
    }

    this.renderGroup.updateWorldMatrix(true, true);

   // mesh.visible = true;

  }

  SetName = (name: string) => { this.name = name; }
  GetName = () => { return this.name; }

  
  SetLevel = (level: number) => {
    let height = this.levelHeights[0];
    this.level = [];
    this.levelHeights = [];
    for (let i = 0; i < level; i++) {
      this.level.push(true);
      this.levelHeights.push(height);
    }
    this.levelCount = this.level.length;
  }


  SetLevelHeight = (value: number) => {
    for (let i = 0; i < this.levelHeights.length; i++) {
      this.levelHeights[i] = value;
    }
  }

  SetPiloti = (level: number) => {
    level = level > this.level.length ? this.level.length : level;

    for (let i = 0; i < this.level.length; i++) {
      this.level[i] = i < level ? false : true;
    }

    this.piloti = level;
  }

  SetInitialArea = (area: {exclusiveArea: number, serviceArea: number, commonWallArea: number}) => {
    const {exclusiveArea, serviceArea, commonWallArea} = area;
    this.initialArea.exclusiveArea = Math.floor(exclusiveArea * 10000) / 10000;
    this.initialArea.serviceArea = Math.floor(serviceArea * 10000) / 10000;
    this.initialArea.commonWallArea = Math.floor(commonWallArea * 10000) / 10000;
  }

  SetExclusiveArea = (value: number) => {
    this.exclusiveArea = value;
//    this.CheckCompleteness();
  }

  SetServiceArea = (value: number) => {
    this.serviceArea = value;
    this.balconyLess150cm = value;
    this.balconyOver150cm = 0;
  //  this.CheckCompleteness();
  }

  SetBalconyOver150cm = (value: number) => {
    if (value > this.serviceArea) {
      value = this.serviceArea;
    }

    this.balconyOver150cm = value;
    this.balconyLess150cm = Number((this.serviceArea - this.balconyOver150cm).toFixed(4));
   // this.CheckCompleteness();
  }

  SetCommonWallArea = (value: number) => {
    this.commonWallArea = value;
  //  this.CheckCompleteness();
  }

  SetPosition = (position: THREE.Vector3) => {
    this.position = position;
    this.renderGroup.position.set(position.x, position.y, position.z);
  }

  SetScale = (scale: THREE.Vector3) => {
    this.scale = scale;
    
    this.renderGroup.scale.set(scale.x, scale.y, 0);
  }

  RotateWithRadians = (radians: number) => {
    this.rotate = radiansToDegrees(radians);
    this.renderGroup.rotateZ(radians);
  }

  RotateWithDegrees = (degress: number) => {
    this.rotate = degress;
    this.renderGroup.rotateZ(degrees2radians(degress));
  }

  UpdateArea = () => {
    this.totalServiceAreas = this.balconyLess150cm;
    this.totalExclusiveAreas = this.exclusiveArea + this.balconyOver150cm;
    this.totalCommonWallAreas = this.commonWallArea;
  }

  checkRounded = (errorList: ErrorList) => {
      let hasCurve = false;
      this.polygon.forEach(p => {
        if (p.hasCurve) {
          hasCurve = true;
          this.errorBlock = true;
        }
      })
      
      if (this.componentType === "house" || this.componentType === "core") {
        if (hasCurve) {
          errorList.addError(new TypeError({
            title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
            msg: `${this.name} 에 ARC가 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
            type: ErrorType.Error,
            id: [this.uuid],
            components: [this],
            //@ts-ignore
            hilightPolygon: getCurveErrorCircleBlock(this),
          }));

          this.complete = CompletenessType.error;
        }
      }
  }


  checkWindows = (errorList: ErrorList) => {
    let warning = false;
    let error = false;

    let windowPolygons: Array<Polygon> = [];
    this.polygon.forEach(p => {
      if (p.layer.toUpperCase() === 'WIN1') {
        windowPolygons.push(p);
      }
      else if (p.layer.toUpperCase() === 'WIN2') {
        windowPolygons.push(p);
      }
    })
    
    /* n개 "폴리곤" 존재 (지정된 레이어) */
    let polygons = this.polygon.filter(poly => poly.shape && ["CON"].indexOf(poly.layer.toUpperCase()) > -1);
    if (polygons.length > 1) {
      errorList.addError(new TypeError({
        title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
        msg:   `${this.name}은 하나의 Polygon만을 허용합니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: [this.uuid],
       //@ts-ignore
        polygons
      }));

      changeLineColorRed(this.polygon, this.name);
      this.complete = CompletenessType.error;
    }


    /* 창문 레이어에 같은 좌표로 창문 (라인/폴리라인/폴리곤) 이 존재할 경우 */
    let lines: jsts.geom.LineString[] = [];
    let geoFactory = new jsts.geom.GeometryFactory();
    windowPolygons.forEach(wp => {
      let coords: jsts.geom.Coordinate[] = [];
      wp.vertices.forEach(vert => {
        coords.push(new jsts.geom.Coordinate(vert.x, vert.y));
      })
      lines.push(geoFactory.createLineString(coords));
    })

    for (let i = 0; i < lines.length; i++) {
      for (let j = i + 1; j < lines.length; j++) {
        if (lines[i].equals(lines[j])) {
          //TODO
          let group = getLineErrorCircleBlock(this.renderGroup, windowPolygons[i]);

          errorList.addError(new TypeError({
            title:`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
            msg: `${windowPolygons[i].layer} 겹쳐진 ${getPolygonType(windowPolygons[i].type, windowPolygons[i].shape)} 이 있습니다. `, 
            type: ErrorType.Warning,
            id: [this.uuid],
            //@ts-ignore
            polygons: [windowPolygons[i]],
          }));

          // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
          //   `${windowPolygons[i].layer} 겹쳐진 ${getPolygonType(windowPolygons[i].type, windowPolygons[i].shape)} 이 있습니다. `, 
          //   group, undefined, {
          //   windows: [windowPolygons[i]],
          // }));
          if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
//          error = true;
        }
      }
    }

    
    if (error) return "error";
    else if (warning) return "warning";
    else return "";
  
  }
  

 

  RebuildOutputPolygon = () => {
  }



  getHouseError = (errorList: ErrorList) => {
    let lines: PolylineInfo[] = [];
    let windowPolygons: WindowType[] = [];
    let matrix = new THREE.Matrix4().identity();

    this.polygon.forEach(p => {
      let verts = PolygonSimplification(p.vertices);

      if (p.layer === 'CON' && p.shape === true && p.vertices.length >= 4 && verts.length >= 4) {
        let worldVerts: THREE.Vector3[] = [];

        for (let i = 0; i < verts.length; i++)
          worldVerts.push(verts[i].clone().applyMatrix4(matrix));
        if (!CheckVerticesArrayisCCW(worldVerts)) worldVerts = worldVerts.reverse();
        if (this.renderGroup.matrixWorld.elements[0] * this.renderGroup.matrixWorld.elements[5] < 0) worldVerts = worldVerts.reverse();

        for (let i = 0; i < worldVerts.length - 1; i++) {
          worldVerts[i].z = 0;
          worldVerts[i + 1].z = 0;
          lines.push({
            line: new THREE.Line3(worldVerts[i], worldVerts[i + 1]),
            thickness: 0.6,
            type: LineType.LT_OUTERWALL,
          })
        }
      }
      else if (/^win[1-2]$/i.test(p.layer)) { // win1, win2일 때
        if (!p.shape) {
          if (checkWindowLength(p)) { // 창문 길이 체크
            windowPolygons.push({
              polygon: p,
              lineType: /^win1$/i.test(p.layer) ? LineType.LT_LIGHTWINDOW : LineType.LT_OUTERWINDOW,
              maked: false,
            })
          }
          else {
            let group = getLineErrorCircleBlock(this.renderGroup, p, 1000);
            errorList.addError(new TypeError({
              title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
              msg: `설정 하신 창문의 폭이 최소 길이 300mm에 충족하지 않아 생성되지 않습니다.`,
              type: ErrorType.Warning,
              id: [this.uuid],
              //@ts-ignore
              polygons: [p],
              //@ts-ignore
              hilightPolygon: group,
            }));
            this.renderGroup.add(group);
            if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning;
          }
        }
      }
    })

    let makeState: makeHouseState = makeHouseState.Finish;
    let windowOffset = userSettingData.myTypeSettingData.windowOffset;
    let offset = windowOffset.value;//: 0; // 0.01;

    let winConfirmGroup = new THREE.Group();
    let windowInfoPoly: Polygon[] = [];

    windowPolygons.forEach(wp => { // 창문 3D폴리곤 만들기
      if (wp.polygon.vertices.length) {
        let windowStartPoint = wp.polygon.vertices[0].clone().applyMatrix4(matrix);
        let windowEndPoint = wp.polygon.vertices[wp.polygon.vertices.length - 1].clone().applyMatrix4(matrix);
        let maked = false;
        let isWindowInWall = true;
        let distance = windowStartPoint.clone().distanceTo(windowEndPoint) / 2; // 창문길이 절반
        let windowCenter = windowStartPoint.clone().add(windowEndPoint.clone()).divideScalar(2); // 창문의 센터 좌표
        let errorDistance = Number.MAX_SAFE_INTEGER;

        for (let i = 0; i < lines.length; i++) {
          if (lines[i].type === LineType.LT_OUTERWALL && windowStartPoint.distanceTo(windowEndPoint) > 0.01) { // 외벽일때
            let newStartPoint = new THREE.Vector3(); // 벽 위 창문 시작점
            let newEndPoint = new THREE.Vector3(); // 벽 위 창문 끝점
            let newCenterPoint = new THREE.Vector3(); // 창문 중점을 벽위에 수직투영

            lines[i].line.closestPointToPoint(windowStartPoint, true, newStartPoint); // 외벽라인에서 가장 가까운 점 리턴
            lines[i].line.closestPointToPoint(windowEndPoint, true, newEndPoint);
            lines[i].line.closestPointToPoint(windowCenter, true, newCenterPoint); // 창문중점과 벽 가장 가까운 지점을 p3에 넣음

            let preP1 = newStartPoint.clone();
            let preP2 = newEndPoint.clone();

            const normalize = new THREE.Vector3(lines[i].line.end.x - lines[i].line.start.x, lines[i].line.end.y - lines[i].line.start.y).normalize();

            newStartPoint.x = newCenterPoint.x - normalize.x * distance;
            newStartPoint.y = newCenterPoint.y - normalize.y * distance;

            newEndPoint.x = newCenterPoint.x + normalize.x * distance;
            newEndPoint.y = newCenterPoint.y + normalize.y * distance;

            this.renderGroup.updateWorldMatrix(true, true);


            if ((windowStartPoint.distanceTo(newStartPoint) <= 100 && windowEndPoint.distanceTo(newEndPoint) <= 100) ||
              (windowStartPoint.distanceTo(preP1) <= 100 && windowEndPoint.distanceTo(preP2) <= 100)
              || windowCenter.distanceTo(newCenterPoint) <= 100) {
              let l1: PolylineInfo, l2: PolylineInfo, l3: PolylineInfo;
              // 창문이 벽 라인 넘어감              
              isWindowInWall = checkPointOnLine(newStartPoint, new THREE.Line3(lines[i].line.start, lines[i].line.end)) && checkPointOnLine(newEndPoint, new THREE.Line3(lines[i].line.start, lines[i].line.end));

              if (!isWindowInWall) {
                this.renderGroup.updateWorldMatrix(true, true);
                let matrixWorld = this.renderGroup.matrixWorld;
                let worldVerts: THREE.Vector3[] = [];
                wp.polygon.vertices.forEach(v => {
                  worldVerts.push(v.clone());//.clone().applyMatrix4(matrixWorld));
                });
                let group = new THREE.Group();
                let circleMesh = getCircle(worldVerts[0], worldVerts[worldVerts.length - 1], new THREE.Color(1, 0, 0), 5);
                group.add(circleMesh);

                errorList.addError(new TypeError({
                  title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
                  msg: `설정하신 ${wp.lineType === LineType.LT_LIGHTWINDOW ? "WIN1" : "WIN2"} 이 ${this.name} 영역을 벗어나 생성되지 않습니다.`,
                  type: ErrorType.Warning,
                  id: [this.uuid],
                  //@ts-ignore
                  polygons: [wp.polygon],
                  //@ts-ignore
                  hilightPolygon: group,
                }));
                this.renderGroup.add(group);
                if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning;  //x
                errorDistance = Math.min(Math.max(windowStartPoint.distanceTo(preP1), windowEndPoint.distanceTo(preP2)), errorDistance);
                return;
              }
              // 창문점이 벽 라인위 && 창문-벽 이격 거리가 0.1이하면 생성
              else if (windowStartPoint.distanceTo(preP1) <= 0.1 && windowEndPoint.distanceTo(preP2) <= 0.1) {
                l1 = { line: new THREE.Line3(lines[i].line.start, newStartPoint), thickness: 0.6, type: LineType.LT_OUTERWALL };
                l2 = { line: new THREE.Line3(newStartPoint, newEndPoint), thickness: 0.6, type: wp.lineType };
                l3 = { line: new THREE.Line3(newEndPoint, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
                lines.splice(i, 1, l1, l2, l3);
                i += 2;
                maked = true;
              }
              else { // 이격                
                // 자동보정 ON, 사용자 설정 범위 이내 => INFO (0.1초과~사용자 설정)
                if (windowOffset.enable && windowStartPoint.distanceTo(preP1) <= offset! && windowEndPoint.distanceTo(preP2) <= offset!) {
                  let windowName = "";
                  if (wp.lineType === "LT_OUTERWINDOW") windowName = "일반창";
                  else if (wp.lineType === "LT_LIGHTWINDOW") windowName = "채광창";
                  this.renderGroup.updateWorldMatrix(true, true);
                  let matrixWorld = this.renderGroup.matrixWorld;
                  let worldVerts: THREE.Vector3[] = [];
                  wp.polygon.vertices.forEach(v => {
                    worldVerts.push(v.clone().applyMatrix4(matrixWorld));
                  });
                  let group = new THREE.Group();
                  let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
                  group.add(circleMesh);
                  winConfirmGroup.add(group);
                  windowInfoPoly.push(wp.polygon);

                  l1 = { line: new THREE.Line3(lines[i].line.start, newStartPoint), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  l2 = { line: new THREE.Line3(newStartPoint, newEndPoint), thickness: 0.6, type: wp.lineType };
                  l3 = { line: new THREE.Line3(newEndPoint, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };

                  lines.splice(i, 1, l1, l2, l3);
                  i += 2;
                  maked = true;
                  //자동보정알람
                  errorList.addError(new TypeError({
                    title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
                    msg: `${this.name} 의 이격된 창문 라인이 자동 보정 되었습니다.`,
                    type: ErrorType.Info,
                    id: [this.uuid],
                    //@ts-ignore
                    polygons: windowInfoPoly,
                    //@ts-ignore
                    hilightPolygon: winConfirmGroup,
                  }));
                }
                else {
                  if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning;
                }
              }
            }
            //창문생성이 안됐을때 거리넣기
            if (!maked) {
              errorDistance = Math.min(Math.max(windowStartPoint.distanceTo(preP1), windowEndPoint.distanceTo(preP2)), errorDistance);
            }
          }
        }


        if (!maked && lines.length && isWindowInWall) {
          let windowName = "";
          if (wp.lineType === "LT_OUTERWINDOW") windowName = "일반창";
          else if (wp.lineType === "LT_LIGHTWINDOW") windowName = "채광창";
          this.renderGroup.updateWorldMatrix(true, true);
          // warning 추가
          this.renderGroup.updateWorldMatrix(true, true);
          let matrixWorld = this.renderGroup.matrixWorld;
          let worldVerts: THREE.Vector3[] = [];
          wp.polygon.vertices.forEach(v => {
            worldVerts.push(v.clone());//.clone().applyMatrix4(matrixWorld));
          });
          let group = new THREE.Group();
          let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
          group.add(circleMesh);

          //*NEW
          this.renderGroup.add(group);
          errorList.addError(new TypeError({
            title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
            msg: `${this.name}에 ${wp.lineType !== LineType.LT_OUTERWINDOW ? "WIN1" : "WIN2"} 라인이 
            ${errorDistance.toFixed(2)}mm 이격 되어 있어 창문이 생성되지 않습니다. `,
            type: ErrorType.Warning,
            id: [this.uuid],
            //@ts-ignore
            hilightPolygon: group,
            //@ts-ignore
            polygons: [wp.polygon],
          }));
        }
        wp.maked = maked;
      }
    })

    windowPolygons.forEach(wp => {
      if (!wp.maked) {
        if (wp.lineType === LineType.LT_LIGHTWINDOW) {
          makeState = makeState === makeHouseState.outerWindowError ? makeHouseState.allWindowError : makeHouseState.lightWindowError;
        }
        else if (wp.lineType === LineType.LT_OUTERWINDOW) {
          makeState = makeState === makeHouseState.lightWindowError ? makeHouseState.allWindowError : makeHouseState.outerWindowError;
        }
      }
    })
    
    this.outputPolygon = lines;
    this.makeState = makeState;
  }
  


  toJson = () => {
    let text = {
      name: this.name,
      buildingType: this.buildingType,
      block: this.block.name,
      position: this.position,
      scale: this.scale,
      rotate: this.rotate,
      parts: this.parts.map(p => p.toJson()),
      componentType: this.componentType,
      level: this.level,
      exclusiveArea: this.exclusiveArea, // 전용 면적
      serviceArea: this.serviceArea, // 발코니 면적
      balconyOver150cm: this.balconyOver150cm,
      balconyLess150cm: this.balconyLess150cm,
      commonWallArea: this.commonWallArea,
      levelHeights: this.levelHeights,
      piloti: this.piloti,
    }

    return text;
  }
}