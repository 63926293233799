

import React, { Component } from 'react';
import Tree from 'rc-tree';
import '../css/CADConverter/Rc-tree.scss';
import '../css/CADConverter/BlockTreeList.scss';
import { BuildingPart, ConverterBuilding, ConverterField } from './BuildingPart';
import { DataNode, EventDataNode, Key, IconType } from '../../node_modules/rc-tree/lib/interface';
import { brightenAllField, setBlockOpacity } from './CoreAndHouseController';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { ExpandMore, ChevronRight, SpeakerNotes, Visibility, VisibilityOff } from '@material-ui/icons';
import { ConverterType, Polygon } from './DataTypes';
import { ReactComponent as BlockIcon } from '../img/icon/blockIcon.svg';
import { Button } from '@material-ui/core';
import Tooltip from '../Tooltip';
import { ErrorList } from './Error';

interface BlockTreeListProps {
  clickedField: ConverterField | null,
  parsingOutput: any,
  setClickedBlock: (block: any, type: ConverterType.myType | ConverterType.mySite) => void,
  clickComponent: (field: ConverterField) => void,
  currentLog: string,
  errorList: ErrorList,
  handleCurLogById: (id: string) => void,
  handleCurLog: Function,
  hasHeader?: boolean,
  filter: ConverterType,
  isVisible: {
    site: boolean, // 사업영역
    type: boolean, // 동평면
  },
  clickedBuilding: ConverterBuilding | BuildingCoreUnit | BuildingHouseUnit | BuildingPart,
  handleVisible: (type: ConverterType.mySite | ConverterType.myType) => void,
  findBlockUUID: (id: string) => any,
}

const STYLE = `
.rc-tree-child-tree {
  display: block;
}

.node-motion {
  box-sizing: border-box;
  transition: all .3s;
  overflow-y: hidden;
  overflow-x: hidden;
}
`;

const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: () => ({ height: 0 }),
  onAppearActive: (node: any) => ({ height: node.scrollHeight }),
  onLeaveStart: (node: any) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 }),
};

interface BlockTreeListState {
  isVisible: {
    site: boolean,
    type: boolean,
  }
}

class BlockTreeList extends Component<BlockTreeListProps> {
  treeRef: React.RefObject<any> = React.createRef(); //LegacyRef<Tree> | undefined = React.createRef();
 // blockNameCnt = new Map();

  state: BlockTreeListState = {
    isVisible: {
      site: true,
      type: true,
    }
  };

  getTypeTreeData = () => {
    // 동평면
//    this.blockNameCnt = new Map();

    //@ts-ignore
    const { buildings } = this.props.parsingOutput;
    const treeDataNode: DataNode[] = [];
    const children: DataNode[] = [];
    if (!this.props.hasHeader) {
      buildings.forEach((building: ConverterBuilding) => this.getBuildingTree(building, 0, treeDataNode));
    }
    else {
      treeDataNode.push({
        key: 'typeHeader',
        title: <>
          <div className="text">나의 동평면</div>

          <Tooltip msg={this.props.isVisible.type ? "도면 이미지 숨기기" : "도면 이미지 보기"} arrowOn={false} place="right">
            <Button
              className="btn bg-navy btn-primary visibility-btn"
              onClick={() => {
                this.props.handleVisible(ConverterType.myType);
              }}>
              {this.props.isVisible.type ? <Visibility className="visibility-icon" /> : <VisibilityOff className="visibility-icon disabled" />}
            </Button>
          </Tooltip>
        </>,
        children: children,
        switcherIcon: <div className="switcher-icon"><ExpandMore className="expand-icon" /></div>, //<>{!this.state.icons.get(field.uuid) ? <ExpandMore className="icon expand-icon"/> : <ChevronRight className="icon expand-icon"/>}</>,
        className: 'start-node',
      });
      buildings.forEach((building: ConverterBuilding) => this.getBuildingTree(building, 0, children));
    }

    return treeDataNode;
  }

  getBuildingTree(block: any, depth: number, children: any[]) {
    const logCnt = this.props.errorList.getErrorById(block.uuid).length;


    const newChildren: any[] = [];
    let blockNode: DataNode = {
      key: block.uuid,
      title: <>
        <div className="name">{block.name}</div>
        <Tooltip msg="오류 정보 확인" arrowOn={false} place="right">
          {<span className="m-r-sm font font-12px">{block.sameNameNumber}</span>}
          <Button
            className={`btn bg-navy btn-primary log-btn ${logCnt === 0 ? 'disabled' : ""} ${this.props.currentLog === block.uuid ? "active" : ""}`}
            onClick={() => {
              this.props.handleCurLogById(block.uuid)
            }}>
            <SpeakerNotes className={`log-icon ${this.props.errorList.getErrorById(block.uuid).length === 0 ? "disabled" : ""}`} />
          </Button>
        </Tooltip></>,
      children: newChildren,
      isLeaf: false,
      switcherIcon: <div className="switcher-icon"><ExpandMore className="expand-icon" /></div>,
      icon: <div className="tree-icon"><BlockIcon className="block-icon" /></div>,
      className: `depth-${depth} type ${this.props.clickedBuilding && this.props.clickedBuilding.uuid === block.uuid ? "selected" : ""}`,
    }

    children.push(blockNode);
    block.parts && block.parts.forEach((part: BuildingPart | BuildingCoreUnit | BuildingHouseUnit) => this.getBuildingTree(part, depth + 1, newChildren));

    if (block.componentType === "house") {
      ["WIN1", "WIN2"].forEach((windowName: string) => {
        const windowLayers = block.polygon.filter((polygon: Polygon) => polygon.layer.toUpperCase() === windowName);
        if (windowLayers.length) {
          const windowNode: DataNode = {
            key: `${block.uuid}+${windowLayers[0].lineMesh.uuid}`,
            title: windowName,
            children: [],
            icon: <div className="wrap-icon"><div className="window-icon" /></div>,
            isLeaf: true,
            className: "layer type",
            selectable: false,
          }
          newChildren.push(windowNode);
        }

      });
    }
    else if (block.componentType === "core") {
      let concreteLayers = block.polygon.filter((polygon: Polygon) => polygon.layer.toUpperCase() === "CON");
      if (concreteLayers.length > 0) {
        const concreteNode: DataNode = {
          key: `${block.uuid}+${concreteLayers[0].lineMesh.uuid}`,
          title: "CON",
          children: [],
          icon: <div className="wrap-icon"><div className="window-icon" /></div>,
          isLeaf: true,
          className: "layer type",
          selectable: false,
        }
        //@ts-ignore
        newChildren.push(concreteNode);

      }
    }

  }

  getSiteTreeData = () => {
    //@ts-ignore
    const { fields } = this.props.parsingOutput;
    const treeDataNode: DataNode[] = [];
    const children: DataNode[] = [];

    treeDataNode.push({
      key: 'siteHeader',
      title: <>
        <div className="text">나의 사업영역</div>
        <Tooltip msg={this.props.isVisible.site ? "도면 이미지 숨기기" : "도면 이미지 보기"} arrowOn={false} place="right">
          <Button
            className="btn bg-navy btn-primary visibility-btn"
            onClick={() => {
              if (this.props.isVisible.site) {
                setBlockOpacity(this.props.parsingOutput.fields, 0.2);
              }
              else {
                setBlockOpacity(this.props.parsingOutput.fields, 1);
              }
              this.props.handleVisible(ConverterType.mySite);
            }}>
            {this.props.isVisible.site ? <Visibility className="visibility-icon" /> : <VisibilityOff className="visibility-icon disabled" />}
          </Button>
        </Tooltip>
      </>,
      children: children,
      selectable: true,

      // selectable: false,
      switcherIcon: <div className="switcher-icon"><ExpandMore className="expand-icon" /></div>, //<>{!this.state.icons.get(field.uuid) ? <ExpandMore className="icon expand-icon"/> : <ChevronRight className="icon expand-icon"/>}</>,
      className: 'start-node',
    });

    fields.forEach((field: ConverterField) => {
      const logCnt = this.props.errorList.getErrorById(field.uuid).length;
      let fieldNode: DataNode = {
        key: field.uuid,
        title: <>{field.name}
          <Tooltip msg="오류 정보 확인" arrowOn={false} place="right">
            <Button
              className={`btn bg-navy btn-primary log-btn ${logCnt === 0 ? 'disabled' : ""}`}
              onClick={() => {
                this.props.handleCurLogById(field.uuid)
              }}>
              <SpeakerNotes className={`log-icon ${logCnt === 0 && "disabled"}`} />
            </Button>
          </Tooltip></>,
        children: [],
        selectable: true,
        isLeaf: true,
        switcherIcon: <></>,
        icon: <div className="tree-icon"><BlockIcon className="block-icon" /></div>,
        className: `field ${this.props.clickedField === field ? "selected" : ""}`,
      }
      children.push(fieldNode);
    })

    return treeDataNode;
  }

  findBuildingByUUID(uuid: string) {
    let result: any = undefined;
    for (let i = 0; i < this.props.parsingOutput.buildings.length; i++) {
      checkUUID(this.props.parsingOutput.buildings[i]);
      if (result) return result;
    }

    function checkUUID(block: any) {
      if (block.uuid === uuid) {
        result = block;
        return;
      }
      else {
        if (block.parts) {
          for (let i = 0; i < block.parts.length; i++) {
            checkUUID(block.parts[i]);
            if (result) break;
          }
        }
      }
    }
  }

  setNodeKeys(nodeKeys: Key[]) {
    this.setState({ nodeKeys });
  }

  getTreeData() {
    if (this.props.filter === ConverterType.mySite) return this.getSiteTreeData();
    else if (this.props.filter === ConverterType.myType) return this.getTypeTreeData();
    else if (this.props.filter === ConverterType.myPlane) return this.getTypeTreeData().concat(this.getSiteTreeData());
  }

  render() {
    return (
      <div className={`animation BlockTreeList ${this.props.hasHeader ? "" : "non-header"}`}>
        <style dangerouslySetInnerHTML={{ __html: STYLE }} />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 50%' }}>

            <Tree
              ref={this.treeRef}
              defaultExpandAll={true}
              autoExpandParent={true}
              defaultExpandParent={true}
              motion={motion}

              onClick={() => { }}
              treeData={this.getTreeData()}//!사업영역: {this.getTreeData()}
              onMouseEnter={({ event, node }) => {
                const key = node.key as string;


                if (!key.endsWith("Header") && node.className) {
                  if (node.className.includes('field')) {
                    //사업영역
                    setBlockOpacity(this.props.parsingOutput.fields, 0.2);
                    setBlockOpacity(this.props.parsingOutput.wrongBlocks, 0.2);
                    if (this.props.isVisible.site) {
                      for (let i = 0; i < this.props.parsingOutput.fields.length; i++) {
                        if (key === this.props.parsingOutput.fields[i].uuid) {
                          setBlockOpacity([this.props.parsingOutput.fields[i]], 1);
                          // break;
                          return;
                        }
                      }
                    }
                  }
                  else if (node.className.includes('type')) {

                    setBlockOpacity(this.props.parsingOutput.buildings, 0.2);
                    setBlockOpacity(this.props.parsingOutput.wrongBlocks, 0.2);

                    if (this.props.isVisible.type) {
                      if (node.className.includes("layer")) {
                        const uuid = key.split('+')[0];
                        const block = this.findBuildingByUUID(uuid);
                        setBlockOpacity([], 1, block.polygon.filter((p: any) => p.layer === node.title));
                      }
                      else {
                        const block = this.findBuildingByUUID(key);
                        setBlockOpacity([block], 1);

                      }
                    }
                  }
                }
              }}
              onMouseLeave={({ event, node }) => {
                if (!this.props.isVisible.site) {
                  setBlockOpacity(this.props.parsingOutput.fields, 0.2);
                }
                else {
                  if (this.props.clickedField !== null) { // 선택된게 있을때
                    setBlockOpacity(this.props.parsingOutput.fields, 0.2);
                    setBlockOpacity([this.props.clickedField], 1);
                  }
                  else {
                    brightenAllField(this.props.parsingOutput.fields);
                  }
                }

                if (!this.props.isVisible.type) {
                  setBlockOpacity(this.props.parsingOutput.buildings, 0.2);
                }
                else {
                  if (this.props.clickedBuilding !== null) { // 선택된게 있을때
                    setBlockOpacity(this.props.parsingOutput.buildings, 0.2);
                    setBlockOpacity([this.props.clickedBuilding], 1);
                  }
                  else {
                    setBlockOpacity(this.props.parsingOutput.buildings, 1);
                  }
                }
              }}
              onSelect={(key: Key[], info) => {
                if (key[0] !== "header") {
                  const find = this.props.parsingOutput.fields.find((f: ConverterField) => f.uuid === key[0]);
                  if (find) {
                    this.props.setClickedBlock(find, ConverterType.mySite);
                    return;
                  }
                  const result = this.props.findBlockUUID(key[0] as string);
                  if (result) {
                    this.props.setClickedBlock(result, ConverterType.myType);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BlockTreeList;