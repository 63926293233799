import * as THREE from '@teneleven/three';
// import Axios from 'axios';
import {MaterialManager, MatType} from "../MaterialManager";
import {BufferGeometryUtils} from "@teneleven/three/examples/jsm/utils/BufferGeometryUtils";
import {DataForGenerator, eachNode, FeatureGenerator} from "./GeneratorInterface"

interface DataForLogo {
    width: number,
    height: number,
    stPnt: THREE.Vector3,
    endPnt: THREE.Vector3,
    rectangle: boolean,
    topLevelHeight: number,
    buildingGroup: THREE.Group,
    buildingIndex?: number,
}

export class LogoGenerator extends FeatureGenerator {
    private static _geomTemplate: {[key: string]: THREE.BufferGeometry} = {};

    private readonly _logoMaterial = MaterialManager.getInstance().resloc_materials.get(MatType.LOGO);

    private readonly _fontMaterial = MaterialManager.getInstance().resloc_materials.get(MatType.BUILDNO);

    private readonly m_noHeight = 1;

    constructor(
        allBuilding: THREE.Group) {
        super(allBuilding);
    }

    // public static async fontLoad(url: string) : Promise<THREE.Font> {
    //     let jsonData = await Axios.get('./font/font.json');
    //     return new THREE.FontLoader().parse(jsonData.data);
    // }

    private static addDataToSideWall(stPos: THREE.Vector2, endPos: THREE.Vector2, buildingHeight: number, sideWalls: any) : void {
        let stPnt = new THREE.Vector3(stPos.x, buildingHeight, stPos.y);
        let endPnt = new THREE.Vector3(endPos.x, buildingHeight, endPos.y);
        let distance = stPnt.distanceTo(endPnt);

        if (2 <= distance) {
            sideWalls.push(new Object({distance: distance, stPnt: stPnt, endPnt: endPnt}));
        }
    }

    public Generate(data: DataForGenerator) : void {
        this.allBuilding.updateMatrixWorld();

        // let offsetCVS = new THREE.Geometry();
        let rayCast = new THREE.Raycaster();

        let toCheck: THREE.Object3D[] = [];
        this.allBuilding.traverse((obj: THREE.Object3D) => {
                if ('Mesh' == obj.type && -1 != obj.name.indexOf('Level')) {
                    toCheck.push(obj);
                }
            }
        );

        const checkValue = 0.1;
        const YMoveVec = new THREE.Vector3(0, -checkValue, 0);
        const YMDir = new THREE.Vector3(0, -1, 0);

        let canAddLogo: DataForLogo[] = [];

        //
        data.nodes.forEach((node: eachNode) => {
            // let linePos = [];
            let levels = node.heights.length;
            let segments = node.node;

            //
            // let result = [];
            // let sideWall = [];
            let stPosIndex = undefined, endPosIndex = -1;
            let sideWalls: Object[] = [];

            //측벽만 따옴, https://www.notion.so/1011x/Solver-e331e4ecbe044fefae7cef8f263b7a54
            //인덱스 0이 직선의 중심일 경우 문제가 될 수 있음, 0이 꺽여진 좌표가 될 수 있도록 확인하는 루틴 필요
            // let startIndex = 0;
            // for (let i = 0; i < segments.length; i++) {
            //     let v1 = new THREE.Vector2().subVectors(segments[i - 1 < 0 ? segments.length - 1 : i - 1], segments[i]);
            //     let v2 = new THREE.Vector2().subVectors(segments[i + 1 === segments.length ? 0 : i + 1], segments[i]);
            //
            //     let angle = Math.round((v2.angle() - v1.angle()) * 10000);
            //     if (angle * angle !== 31416 * 31416) //31416 : 파이 x 10000하고 소수점 날린 값임
            //     {
            //         startIndex = i;
            //         break;
            //     }
            // }
            //
            // if (0 != startIndex) {
            //     let abcd = 0;
            // }
            let skipLine = false;
            let lastInsertIndex = -2;
            // let abcd = SimplifyLineSegments(segments);
            for (let i = 0; i < segments.length; i++) {
                let v1 = new THREE.Vector2().subVectors(segments[i - 1 < 0 ? segments.length - 1 : i - 1], segments[i]);
                let v2 = new THREE.Vector2().subVectors(segments[i + 1 === segments.length ? 0 : i + 1], segments[i]);

                let angle = Math.round((v2.angle() - v1.angle()) * 10000);

                // skipLine = false;
                //방향이 같은 경우
                if (angle * angle !== 31416 * 31416) //31416 : 파이 x 10000하고 소수점 날린 값임
                {
                    if (undefined != stPosIndex && !skipLine) {

                        //바로 옆에 붙으면 생성 안함
                        if (i -1 != lastInsertIndex) {
                            LogoGenerator.addDataToSideWall(segments[stPosIndex], segments[endPosIndex], node.building_height, sideWalls);
                            lastInsertIndex = i;

                        }
                    }

                    stPosIndex = i;
                    skipLine = false;
                }

                if (-1 != node.lineInfo[i].lineType.toUpperCase().indexOf('WINDOW')) {
                    skipLine = true;
                }

                if (!skipLine) {
                    endPosIndex = i + 1 === segments.length ? 0 : i + 1;
                }
            }

            //마지막 라인
            if (undefined != stPosIndex && !skipLine && segments.length - 1 != lastInsertIndex) {
                LogoGenerator.addDataToSideWall(segments[stPosIndex], segments[endPosIndex], node.building_height, sideWalls);
            }

            //긴 길이 순으로 정렬
            sideWalls.sort((a: any, b: any) => {
                return b.distance - a.distance;
            });

            //삽입 가능한 측벽 추려냄
            sideWalls.forEach((line: any) => {
                let lineDistance = Math.round(line.distance * 100) / 100;

                //건물 외벽 수직 방향
                let normalDir = new THREE.Vector3(0, 1, 0).cross(new THREE.Vector3().subVectors(line.endPnt, line.stPnt)).normalize();

                //건물 외벽 수직 방향으로 벽 두께 만큼 이동
                let stMovedPnt = line.stPnt.clone().addScaledVector(normalDir, FeatureGenerator._wallThickness).add(YMoveVec);
                let endMovedPnt = line.endPnt.clone().addScaledVector(normalDir, FeatureGenerator._wallThickness).add(YMoveVec);

                //건물 외벽 라인 방향
                let rayDirs = [new THREE.Vector3().subVectors(stMovedPnt, endMovedPnt).normalize()];    //0: endMovedPnt가 시작점
                rayDirs.push(rayDirs[0].clone().multiplyScalar(-1));    //1: stMovedPnt가 시작점

                //debug
                // offsetCVS.vertices.push(stMovedPnt);
                // offsetCVS.vertices.push(stMovedPnt.clone().addScaledVector(rayDirs[1], line.distance));

                //중심점, 필요 없을거 같음
                let midPnt = new THREE.Vector3();
                midPnt.lerpVectors(stMovedPnt, endMovedPnt, 0.5).addScaledVector(normalDir, FeatureGenerator._wallThickness).add(YMoveVec);

                let skip = false;

                rayCast.set(stMovedPnt, rayDirs[1]);
                let arrIntersected_objs = [];
                arrIntersected_objs.push(rayCast.intersectObjects(toCheck, false));  //측벽에 평행한 방향으로 시작점에서 끝점 방향으로 검사

                rayCast.set(endMovedPnt, rayDirs[0]);
                arrIntersected_objs.push(rayCast.intersectObjects(toCheck, false));  //측벽에 평행한 방향으로 끝점에서 시작점 방향으로 검사

                rayCast.set(midPnt, normalDir);
                arrIntersected_objs.push(rayCast.intersectObjects(toCheck, false));  //측벽에 수직한 방향으로 측벽 앞에 객체가 있는지 검사

                //앞에 뭔가 있으면 스킵
                if (0 != arrIntersected_objs[2].length) {
                    skip = true;
                }

                if (!skip) {
                    for (let i = 0; i < 2; i++) {
                        if (0 != arrIntersected_objs[i].length) {
                            let intersection_obj = arrIntersected_objs[i][0];

                            if (/*'FC_CORE' == intersection_obj.object.userData.floorCase &&*/ Math.round(intersection_obj.distance * 100) / 100 == lineDistance) {
                                //부딪치는게 코어이고 측벽 길이와 같다면 로고 삽입
                                skip = false;

                                //자기 자신인 경우 스킵
                                node.buildingGroup.traverse((o: THREE.Object3D) => {
                                    if (intersection_obj.object == o) {
                                        skip = true;
                                    }
                                });
                            } else {
                                //그 이외 항목이 하나라도 있으면 스킵
                                skip = true;
                                break;
                            }
                        }
                    }
                }

                //오피스텔의 경우 적층 방식이라 하부 노드가 들어올 가능성이 있음
                if (line.stPnt.y < lineDistance) {
                    skip = true;
                }

                if (!skip) {
                    arrIntersected_objs = [];

                    let rectangle = true;

                    //건물 바닥 방향(-Y)으로 한번 더 쏴서 최대 거리 계산
                    let innerStMovedPnt = stMovedPnt.clone().addScaledVector(rayDirs[1], checkValue);
                    let innerEndMovedPnt = endMovedPnt.clone().addScaledVector(rayDirs[0], checkValue);

                    rayCast.set(innerStMovedPnt, YMDir);
                    arrIntersected_objs.push(rayCast.intersectObjects(toCheck, false));

                    rayCast.set(innerEndMovedPnt, YMDir);
                    arrIntersected_objs.push(rayCast.intersectObjects(toCheck, false));

                    let height = Infinity;
                    for (let i = 0; i < 2; i++) {
                        if (0 != arrIntersected_objs[i].length) {
                            height = Math.round(arrIntersected_objs[i][0].distance * 100) / 100;
                            if (height < lineDistance) {
                                rectangle = false;
                                break;
                            }
                        }
                    }

                    canAddLogo.push({
                        width: lineDistance as number,
                        height: height as number,
                        stPnt: line.stPnt.clone(),
                        endPnt: line.endPnt.clone(),
                        rectangle: rectangle as boolean,
                        topLevelHeight: node.heights[levels - 1],
                        buildingGroup: node.buildingGroup,
                        buildingIndex: node.buildingIndex
                    });

                    // if (!skip){
                    //     offsetCVS.vertices.push(stMovedPnt);
                    //     offsetCVS.vertices.push(stMovedPnt.clone().addScaledVector(rayDirs[1], line.distance));
                    // }

                    // offsetCVS.vertices.push(innerStMovedPnt);
                    // offsetCVS.vertices.push(innerStMovedPnt.clone().addScaledVector(YMDir, line.distance));
                    //
                    // offsetCVS.vertices.push(innerEndMovedPnt);
                    // offsetCVS.vertices.push(innerEndMovedPnt.clone().addScaledVector(YMDir, line.distance));

                    // offsetCVS.vertices.push(line.stPnt);
                    // offsetCVS.vertices.push(stMovedPnt);
                    // offsetCVS.vertices.push(stMovedPnt.clone().addScaledVector(rayDirs[1], line.distance));

                    // offsetCVS.vertices.push(line.endPnt);
                    // offsetCVS.vertices.push(endMovedPnt);
                    // offsetCVS.vertices.push(endMovedPnt.clone().addScaledVector(rayDirs[0], line.distance));

                    // abcd.add(new THREE.ArrowHelper(rayDirs[0], endMovedPnt, line.distance, 0xff0000));
                }
            });
        });

        //긴 길이 순으로 정렬
        canAddLogo.sort((a: any, b: any) => {
            return b.distance - a.distance;
        });

        //전체 생성 가능한 갯수
        // let allAvailable = canAddLogo.filter((l: any) => (l.height == Infinity || l.height > l.width + this.m_noHeight * 3 + l.topLevelHeight) || (l.height > l.width + this.m_noHeight * 3)).length;

        // let result;
        canAddLogo.forEach((l: any) => {
            // offsetCVS.vertices.push(l.stPnt);
            // offsetCVS.vertices.push(l.endPnt);

            let stPnt = l.stPnt.clone();    //사이드 월의 양 끝점
            let endPnt = l.endPnt.clone();  //사이드 월의 양 끝점
            // let stLowerPnt, endLowerPnt;

            //check method
            let flag = -1;
            if (l.height == Infinity || l.height > l.width + this.m_noHeight * 3 + l.topLevelHeight) {
                flag = 0;   //한층 아래로 내리고 전부 생성
            } else if (l.height > l.width + this.m_noHeight * 3) {
                flag = 1;   //전부 생성
            } else if (l.height > l.width + checkValue) {
                flag = 2;   //로고만 생성
            }
            // else if (l.height > this.m_noHeight * 2 && 0 == allAvailable) {
            else if (l.height > this.m_noHeight * 2) {
                flag = 3;   //동 번호만 생성
            }

            if (0 == flag) {
                stPnt.addScaledVector(YMDir, l.topLevelHeight);
                endPnt.addScaledVector(YMDir, l.topLevelHeight);
            }
            // else if (3 == flag) {
            //     stPnt.addScaledVector(YMDir, this.m_noHeight);
            //     endPnt.addScaledVector(YMDir, this.m_noHeight);
            // }

            //동번호 Y 높이
            let YPosForNumber: number = stPnt.y - this.m_noHeight * 1.5;
            // let YPosForNumber: number = stPnt.y;
            if (3 == flag) {
                YPosForNumber = stPnt.y - l.height / 2;
            }

            //건물 외벽 수직 방향
            // let normalDir = new THREE.Vector3(0, 1, 0).cross(new THREE.Vector3().subVectors(endPnt, stPnt)).normalize();

            //로고 생성
            if (2 >= flag) {
                let geom = this.geneteLogoGeometry(stPnt, endPnt, l.width);
                if (geom) {
                    YPosForNumber -= (l.width / 2);

                    let logo = new THREE.Mesh(geom, this._logoMaterial);
                    logo.name = 'logo';
                    logo.layers.set(2);
                    l.buildingGroup.add(logo);
                }
            }

            //동 번호 생성
            if (l.buildingIndex && MaterialManager.getInstance().font) {
                if (1 >= flag || 3 == flag) {
                    // offsetCVS.vertices.push(new THREE.Vector3(stPnt.x, YPosForNumber, stPnt.z));
                    // offsetCVS.vertices.push(new THREE.Vector3(endPnt.x, YPosForNumber, endPnt.z));
                    let noGeom = this.generateBuildingNumberTextGeometry(`${100 + l.buildingIndex}`, stPnt, endPnt, YPosForNumber, );
                    if (noGeom) {
                        let noMesh = new THREE.Mesh(noGeom, this._fontMaterial);
                        noMesh.name = 'buildingno';
                        noMesh.layers.set(2);
                        l.buildingGroup.add(noMesh);
                    }
                }
            }

            //debug
            // let make = false;
            // if (0 == flag || 1 == flag) {
            //     stLowerPnt = stPnt.clone().addScaledVector(YMDir, l.width + noHeight * 2);
            //     endLowerPnt = endPnt.clone().addScaledVector(YMDir, l.width + noHeight * 2);
            //     make = true;
            // } else if (2 == flag) {
            //     stLowerPnt = stPnt.clone().addScaledVector(YMDir, l.width);
            //     endLowerPnt = endPnt.clone().addScaledVector(YMDir, l.width);
            //     make = true;
            // } else if (3 == flag) {
            //     stLowerPnt = stPnt.clone().addScaledVector(YMDir, noHeight);
            //     endLowerPnt = endPnt.clone().addScaledVector(YMDir, noHeight);
            //     make = true;
            // }
            //
            // if (make) {
            //     offsetCVS.vertices.push(stPnt);
            //     offsetCVS.vertices.push(endPnt);
            //
            //     offsetCVS.vertices.push(stPnt);
            //     offsetCVS.vertices.push(stLowerPnt);
            //
            //     offsetCVS.vertices.push(endPnt);
            //     offsetCVS.vertices.push(endLowerPnt);
            //
            //     offsetCVS.vertices.push(stLowerPnt);
            //     offsetCVS.vertices.push(endLowerPnt);
            // }
            //
        });

        // return offsetCVS;
    }

    public Dispose() {
        LogoGenerator._geomTemplate = {};
    }

    private generateBuildingNumberTextGeometry(no: string, stPnt: THREE.Vector3, endPnt: THREE.Vector3, YPos: number) : THREE.BufferGeometry {
        let noGeom = new THREE.TextBufferGeometry(no, {
            font: MaterialManager.getInstance().font as THREE.Font,
            size: this.m_noHeight,
            height: FeatureGenerator._wallThickness / 2,
            // bevelEnabled: false
            // bevelThickness: FeatureGenerator._wallThickness
        });

        if (noGeom) {
            noGeom.computeBoundingBox();
            let bounding = noGeom.boundingBox as THREE.Box3;

            let stPnt2 = new THREE.Vector2(stPnt.x, stPnt.z);
            let endPnt2 = new THREE.Vector2(endPnt.x, endPnt.z);

            let wallDir = new THREE.Vector2().subVectors(endPnt2, stPnt2).normalize();
            let angle = new THREE.Vector2(1, 0).angle() - wallDir.angle();

            noGeom.applyMatrix4(new THREE.Matrix4().set(
                1, 0, 0, -(bounding.max.x - bounding.min.x) / 2,
                0, 1, 0, -(bounding.max.y - bounding.min.y) / 2,
                0, 0, 1, -FeatureGenerator._wallThickness / 2,
                0, 0, 0, 1));

            if (MaterialManager.getInstance().flipY()) {
                noGeom.applyMatrix4(new THREE.Matrix4().set(
                    -1, 0, 0, 0,
                    0, 1, 0, 0,
                    0, 0, 1, 0,
                    0, 0, 0, 1));
            }

            let rotationMatrix = new THREE.Matrix4().set(
                Math.cos(angle), 0, Math.sin(angle), 0,
                0, 1, 0, 0,
                -Math.sin(angle), 0, Math.cos(angle), 0,
                0, 0, 0, 1
            );
            noGeom.applyMatrix4(rotationMatrix);

            //중심점
            let midPnt = new THREE.Vector2();
            midPnt.lerpVectors(stPnt2, endPnt2, 0.5);

            let matrix = new THREE.Matrix4();
            matrix.set(1, 0, 0, midPnt.x,
                0, 1, 0, YPos,
                0, 0, 1, midPnt.y,
                0, 0, 0, 1);
            noGeom.applyMatrix4(matrix);

            noGeom.computeVertexNormals();
        }
        return noGeom;
    }

    private geneteLogoGeometry(stPnt: THREE.Vector3, endPnt: THREE.Vector3, width: number) : THREE.BufferGeometry {
        let result;
        const logo_height = width / 2;

        if (width in LogoGenerator._geomTemplate) {
            result = LogoGenerator._geomTemplate[width].clone();
        }
        else
        {
            let outerRec = new THREE.BoxBufferGeometry(width * 0.9, logo_height, FeatureGenerator._wallThickness / 2);

            let innerRec = new THREE.BoxBufferGeometry(logo_height, logo_height, FeatureGenerator._wallThickness);

            result = BufferGeometryUtils.mergeBufferGeometries([outerRec, innerRec]) as THREE.BoxBufferGeometry;

            //재질 적용을 위한 재 그룹
            let indices = result.getIndex() as THREE.BufferAttribute;
            let normals = result.getAttribute('normal') as THREE.BufferAttribute;
            let positions = result.getAttribute('position') as THREE.BufferAttribute;

            let nnn = [];
            for (let i = 0; i < indices.count; i++) {
                let indexNo = indices.array[i];
                if (-1 == normals.array[indexNo * 3 + 2] && -FeatureGenerator._wallThickness / 2 == positions.array[indexNo * 3 + 2]) {
                    nnn.push(i);
                }
            }

            if (0 != nnn.length) {
                result.clearGroups();
                result.addGroup(0, nnn[0], 0);
                result.addGroup(nnn[0], nnn.length, 1);
                if (nnn[0] + nnn.length != indices.count) {
                    result.addGroup(nnn[nnn.length - 1] + 1, indices.count - nnn[nnn.length - 1] - 1, 0);
                }
            }

            //똑같은 모양은 나중에 사용할 수 있도록 복사해 둠
            LogoGenerator._geomTemplate[width] = result.clone();
        }

        if (result) {
            //
            result.applyMatrix4(new THREE.Matrix4().set(
                1, 0, 0, 0,
                0, 1, 0, 0,
                0, 0, 1, -FeatureGenerator._wallThickness / 4,
                0, 0, 0, 1,
            ));

            let stPnt2 = new THREE.Vector2(stPnt.x, stPnt.z);
            let endPnt2 = new THREE.Vector2(endPnt.x, endPnt.z);

            let wallDir = new THREE.Vector2().subVectors(endPnt2, stPnt2).normalize();
            let angle = new THREE.Vector2(1, 0).angle() - wallDir.angle();

            let rotationMatrix = new THREE.Matrix4().set(
                Math.cos(angle), 0, Math.sin(angle), 0,
                0, 1, 0, 0,
                -Math.sin(angle), 0, Math.cos(angle), 0,
                0, 0, 0, 1
            );
            result.applyMatrix4(rotationMatrix);

            //중심점
            let midPnt = new THREE.Vector2();
            midPnt.lerpVectors(stPnt2, endPnt2, 0.5);

            let matrix = new THREE.Matrix4();
            matrix.set(1, 0, 0, midPnt.x,
                0, 1, 0, stPnt.y - logo_height * 0.5,
                0, 0, 1, midPnt.y,
                0, 0, 0, 1);
            result.applyMatrix4(matrix);

            result.computeVertexNormals();

            // let logo = new THREE.Mesh(result, this.m_LogoMaterial);
            // logo.name = 'logo';
            // logo.layers.set(2);
            // l.buildingGroup.add(logo);
        }

        return result;
    }
}