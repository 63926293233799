import React, { Component } from 'react';
import { IProjectJS, ProjectJS, BuildingShapeFiguresJS } from '@teneleven/protocols-ts-web/lib/project';
import TextField, { OutlinedTextFieldProps, StandardTextFieldProps } from '@material-ui/core/TextField';
import { ProjectConstraints, ConstraintError, ConstraintErrors } from './Constraints';
import SelectOptions from './SelectOptions';
import { isNumber } from 'util';
import { PathJS } from '@teneleven/protocols-ts-web/lib/map';
import { Polygon } from '@teneleven/protocols-ts-web/lib/field_info_pb';
import { BuildingShapeFigures } from '@teneleven/protocols-ts-web/lib/project_pb';
import proj4 from 'proj4';
import { UserJS } from '@teneleven/protocols-ts-web/lib/user';
import AWS from 'aws-sdk';
import './css/Utils.scss';
import App from './App';
import { ProjectBuildingShapeType, BuildingType } from './model/Project';
import AWSModule from './AWSModule';
import { default as moment } from 'moment';

export async function incrementCounter(type: string, email: string) {
  const ddb = await new AWSModule("DDB").connect();

  const global_id = await ddb.Ddb!.update({
    TableName: 'platform-buildit-counter',
    Key: { 'name': `${App.tempStage}/${type}` },
    ExpressionAttributeNames: { '#counter' : 'counter' },
    ExpressionAttributeValues: { ':counter' : 1 },
    UpdateExpression: 'SET #counter = #counter + :counter',
    ReturnValues: 'UPDATED_OLD',
  }).promise();
  
  const user_id_exists = await ddb.Ddb!.get({
    TableName: 'platform-buildit-counter',
    Key: {
      name: `${App.tempStage}/${type}/${email}`
    }
  }).promise();

  // counter db update at creating first project
  if (user_id_exists.Item === undefined) {
    await ddb.Ddb!.put({
      TableName: "platform-buildit-counter",
      Item: { 
        name: `${App.tempStage}/${type}/${email}`,
        counter: 1
       }
    }).promise();
  }

  const user_id = await ddb.Ddb!.update({
    TableName: 'platform-buildit-counter',
    Key: { 'name': `${App.tempStage}/${type}/${email}` },
    ExpressionAttributeNames: { '#counter' : 'counter' },
    ExpressionAttributeValues: { ':counter' : 1 },
    UpdateExpression: 'SET #counter = #counter + :counter',
    ReturnValues: 'UPDATED_OLD',
  }).promise();

  return {
    globalId: global_id.Attributes!.counter,
    userId: user_id.Attributes!.counter,
  }
}

export function printOption(option: {
  label: string,
  value: string,
}) {
  return (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  )
}

export function pad(n: string, width: number) {
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}
export function timeScale(x: number) {    
  const t: number = Math.round(x * 60);
  return (Math.floor(t / 60)) + '시간 ' + pad(Number(t % 60).toString(), 2) + '분';
  // return moment(0).utc().add(moment.duration(x, 'hours')).format('H시간 mm분')
}


export interface BuilditPropsBase {
  logout?: () => void;
}

export interface ProjectFormPropsBase {
  showModal: (title?:string, content?:JSX.Element, positiveBtn?: Function, negativeBtn?: Function) => void;
  onUpdateProject: (update: IProjectJS) => void;
  currentProject: ProjectJS;
  invalidKeyList: ConstraintErrors;
  projectModified: Set<keyof ProjectJS>;
}

// export interface ProjectFormInputFieldProps extends ProjectFormPropsBase, StandardTextFieldProps {
//   label: string;
//   fieldName: keyof ProjectJS;
//   className?: string;
// }

// export interface ProjectFormInputFieldState {
//   value: string;
//   valueModified: boolean;
// }

// export class ProjectFormInputField extends Component<ProjectFormInputFieldProps, ProjectFormInputFieldState> {
//   state: ProjectFormInputFieldState = {
//     value: "",
//     valueModified: false,
//   }
 
//   valueType: "string" | "number" = "string";

//   componentWillMount = () => {
//     this.update();
//   }

//   componentDidUpdate = (pp: Readonly<ProjectFormInputFieldProps>, ps: Readonly<ProjectFormInputFieldState>) => {
//     if (this.state.valueModified) {
//       this.props.onUpdateProject({
//         [this.props.fieldName]: this.toProj(this.state.value),
//       });

//       this.setState({
//         valueModified: false,
//       })
//     }
    
//     // TODO: state machine 정리
//     if (this.fromProj(this.props.currentProject) != this.fromProj(pp.currentProject)) {
//       if (this.state.valueModified) {
//       } else {
//         this.update();
//       }
//     }
//   }
  
//   render = () => {
//     const constraint = ProjectConstraints[this.props.fieldName];

//     let valueType: "string" | "number" = "string";

//     let converter = (v: string) => v as any;

//     switch (typeof this.props.currentProject[this.props.fieldName]) {
//       case "number":
//       case "bigint":
//         converter = Number;
//         valueType = 'number';
//     }

//     const props = {} as Partial<StandardTextFieldProps>;

//     props.required = constraint && constraint.required;

//     if (this.props.fieldName in SelectOptions) {
//       props.select = true;
//       props.SelectProps = {
//         native: true,
//       };
//     }
//     /* Object.keys(this.state.invalidKeyList.list).map((e) => { 
//       return this.state.invalidKeyList.list[e as keyof ProjectJS]!.msg
//     }) */
//     return (
//       <div className="ProjectFormInputField">
//         {this.props.label}<br />
//         <TextField
//           value={this.state.value}
//           onChange={this.onChange}
//           error={(this.props.projectModified.has(this.props.fieldName) && this.props.fieldName in this.props.invalidKeyList.list)}
//           helperText={(this.props.projectModified.has(this.props.fieldName) && this.props.invalidKeyList.list[this.props.fieldName] && this.props.invalidKeyList.list[this.props.fieldName]!.msg)}
//           {...props}
//           {...this.props}
//           label={false}
//           className={this.props.className}
//         >
//         {
//           // props.select && SelectOptions[this.props.fieldName]!.map(printOption)
//         }
//         </TextField>
//       </div>
//     )
//   }
  
//   fromProj = (proj = this.props.currentProject) => {
//     return proj[this.props.fieldName].toString();
//   }

//   toProj = (value: string) => {
//     if (this.valueType == "string") {
//       return value;
//     } else {
//       return Number(value);
//     }
//   }

//   update = () => {
//     this.setState({
//       value: this.fromProj(),
//     });

//     switch (typeof this.props.currentProject[this.props.fieldName]) {
//       case "number":
//       case "bigint":
//         this.valueType = "number";
//         break
//       default:
//         this.valueType = "string";
//     }
//   }

//   get = (value?: number | ((proj: ProjectJS) => number)) => {
//     if (value === undefined) {
//       return undefined;
//     }

//     if (typeof value == "number") {
//       return value;
//     }

//     return value(this.props.currentProject);
//   }

//   onChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
//     if (this.valueType == "string" || true) { // TODO: 숫자 처리
//       this.setState({
//         value: e.target.value,
//         valueModified: true,
//       });
//     }
//   }

// }
// {
//   name: '판상 ㅣ형',
//   type: 'LINEAR_NON_CORRIDOR_TYPE',
//   houses: [2, 4, 6]
// },
// {
//   name: '판상 L형',
//   type: 'FOLDED_NON_CORRIDOR_TYPE',
//   houses: [2, 3, 4, 5, 6, 7]
// },    
// {
//   name: '타워 T형',
//   type: 'T_TOWER_TYPE',
//   houses: [3, 4]
// },
// {
//   name: '타워 Y형',
//   type: 'Y_TOWER_TYPE',
//   houses: [3, 4, 5, 6, 7]
// },
// {
//   name: '복도식 I형',
//   type: 'LINEAR_CORRIDOR_TYPE',
//   houses: [4, 5, 6, 7, 8, 9]
// },
// {
//   name: '복도식 L형',
//   type: 'FOLDED_CORRIDOR_TYPE',
//   houses: [4, 5, 6, 7, 8, 9]
// }
export function buildingShapeTypeToString(id: string) {
  switch (id) {
    case 'FOLDED_CORRIDOR_TYPE': return "복도식 L형";     // ㄱ자 복도식
    case 'FOLDED_NON_CORRIDOR_TYPE': return "판상 L형";   // ㄱ자 비복도식
    case 'LINEAR_CORRIDOR_TYPE': return "복도식 I형";     // 일자 복도식
    case 'LINEAR_NON_CORRIDOR_TYPE': return "판상 I형";   // 일자 비복도식
    case 'T_TOWER_TYPE': return "타워 T형";               // T자 탑상형
    case 'Y_TOWER_TYPE': return "타워 Y형";               // Y자 탑상형
  }
}

// export async function scan(input: AWS.DynamoDB.DocumentClient.ScanInput, maxCount: number) {
//   const ret = [] as any[];
//   const db = (await new AWSModule("DDB").connect()).Ddb!;
//   await new Promise((resolve, reject) => {
//     db.scan(input).eachPage((err, data) => {
//       if (err) {
//         reject(err);
//       } else if (!data) {
//         resolve();
//       } else if (data.Items) {
//         ret.push(...data.Items!.slice(0, maxCount - ret.length));
//       } else {
//         resolve();
//       }

//       if (ret.length >= maxCount) {
//         resolve();
//         return false;
//       }

//       return true;
//     });
//   });

//   return ret;
// }

// export async function dynamoDBBatchGet(table: string, stage: string, key: string, valueList: string[], slice: number = 10, fieldList?: string[]) {
//   const dynamo = (await new AWSModule("DDB").connect()).Ddb!;
//   const len = valueList.length;
//   let result = [] as any[];

//   for (let i = 0; i < len; i += slice) {
//     const d_res = await dynamo.batchGet({
//       RequestItems: {
//         table: {
//           ConsistentRead: true,
//           Keys: valueList.map((val: string) => ({
//             key: val
//           })),
//           AttributesToGet: fieldList && fieldList.map(f => f)
//         }
//       }
//     }).promise();
    
//   }

// }

export function buildingTypeLabel(buildingType: BuildingType | undefined) {
  switch (buildingType) {
    case "MULTIPLE":
      return "BUILDIT 추천"
      break
    case undefined:
      return "미정"
      break
    default:
      return buildingType
      break
  }
}