import * as THREE from '@teneleven/three';
import {DataForGenerator, FeatureGenerator} from "./GeneratorInterface";
import {
    addPathToShapePath,
    doCSGbyGeometry,
    generateWallGeometry,
    generateWindowGeometry, OffsetLineSegments, SimplifyLineSegments,
} from "../GeomUtilities";
import {MaterialManager, MatType} from "../MaterialManager";
import {DataForPiloti} from "./PilotiGenerator";

export interface DataForLevel extends DataForPiloti {
    level_type: string,
    top_floor_index: number,
    with_roof_line: boolean,
    with_parapet: boolean,
    with_roof: boolean
    level_mesh?: THREE.Mesh
}

export class LevelGenerator extends FeatureGenerator {
    private static _levelGroup: { [key: string]: THREE.Mesh } = {};

    constructor(allBuilding: THREE.Group) {
        super(allBuilding);
    }

    public static levelGroup() {
        return this._levelGroup;
    }

    public Generate(data: DataForLevel) : void {
        let nodeData = data.nodes[0];
        let eachLevel: THREE.Mesh | undefined = undefined;

        if (!(data.level_height in LevelGenerator._levelGroup)) {
            let wallGeom = generateWallGeometry(nodeData.node, nodeData.building_height, data.level_height);

            let windowToSubGeom = generateWindowGeometry(nodeData.lineInfo, nodeData.node, nodeData.building_height, data.level_height);

            let floorGeom = LevelGenerator.generateFloorCeilingGeometry(nodeData.node, nodeData.building_height, 0);

            let windowGeom = generateWindowGeometry(nodeData.lineInfo, nodeData.node, nodeData.building_height, data.level_height, 0.25 / 2, false);

            // if (!withRoof)
            //     levelLine = generateLevelLine(nodes, buildingHeight + levelHeight);

            // eachLevel = LevelGenerator._levelGroup[data.level_height] = new THREE.Group();
            // eachLevel.name = 'Level ' + `${i + 1}`;
            // buildingGroup.add(eachLevel);

            if (wallGeom) {
                if (windowToSubGeom) {
                    wallGeom = doCSGbyGeometry(wallGeom, windowToSubGeom, 'subtract');
                    wallGeom.computeVertexNormals();
                }

                let wallMesh;
                //외벽, 내벽의 텍스쳐를 다르게 하기 위함, FPS 저하로 나중에 방법 찾아야함
                // if ('FC_HOUSE' == floor[i] && !withRoof){
                //     wallGeom = regroupWallGeometry(wallGeom);
                //
                //     eachLevel.add(new THREE.Mesh(wallGeom, [wallcoreMaterial[floor[i]], blackMaterial] ));
                //     // eachLevel.add(new THREE.Mesh(wallGeom, wallcoreMaterial[floor[i]]));
                // }
                // else
                {
                    wallMesh = new THREE.Mesh(wallGeom, this._wallcoreMaterial[data.level_type]);

                    wallMesh.userData.floorCase = data.level_type;

                    // const edges = new THREE.EdgesGeometry( wallGeom, 0 );
                    // const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: '#ff0000' } ) );
                    // eachLevel.add(line);
                }
                // wallMesh.name = 'wall';

                eachLevel = LevelGenerator._levelGroup[data.level_height] = wallMesh;
                eachLevel.name = 'Level ' + `${data.level_index + 1}`;
                nodeData.buildingGroup.add(eachLevel);

                // wallGeom.computeVertexNormals();
                // eachLevel.add(new THREE.Mesh(wallGeom, [wallcoreMaterial[data.level_type], blackMaterial, wallcoreMaterial[data.level_type]] ));

                if (windowGeom) {
                    windowGeom.computeVertexNormals();
                    let windowMesh = new THREE.Mesh(windowGeom, 0 == windowGeom.groups.length ? this._windowMaterial[2] : this._windowMaterial);
                    windowMesh.name = 'window';
                    wallMesh.add(windowMesh);
                }

                if (floorGeom) {
                    floorGeom.computeVertexNormals();
                    let floorMesh = new THREE.Mesh(floorGeom, [this._ceilingMaterial, this._floorMaterial[data.level_type], this._wallcoreMaterial[data.level_type]]);
                    // if ('FC_CORE' == floor[i])
                    {
                        floorMesh.visible = false;
                    }
                    floorMesh.name = 'floor';
                    wallMesh.add(floorMesh);
                }

                // if (levelLine) {
                //     let lineMesh = new THREE.LineSegments(levelLine, bottomLineMaterial);
                //     wallMesh.add(lineMesh);
                // }

                eachLevel.traverse((obj: THREE.Object3D) => {
                        if ('Mesh' == obj.type) {
                            obj.castShadow = true;
                            obj.receiveShadow = true;
                        }
                    }
                );
            }
        }
        else {
            eachLevel = LevelGenerator._levelGroup[data.level_height].clone();

            eachLevel.name = 'Level ' + `${data.level_index + 1}`;

            let translationMatrix = new THREE.Matrix4().set(
                1, 0, 0, 0,
                0, 1, 0, data.level_height,
                0, 0, 1, 0,
                0, 0, 0, 1,
            );
            eachLevel.applyMatrix4(translationMatrix);

            //탑층인 경우 레벨 라인 숨김
            // if (data.level_index == data.top_floor_index) {
            //     eachLevel.traverse((obj: THREE.Object3D) => {
            //         if ('LineSegments' == obj.type) {
            //             obj.visible = false;
            //         }
            //     });
            // }

            nodeData.buildingGroup.add(eachLevel);
            LevelGenerator._levelGroup[data.level_height] = eachLevel;
        }

        data.level_mesh = eachLevel;

        // if (undefined != eachLevel && data.with_roof) {
        //     let roofTopGeom = generateFloorCeilingGeometry(nodeData.node, nodeData.building_height + data.level_height);
        //     let roofTopMesh = new THREE.Mesh(roofTopGeom, [this._ceilingMaterial, this._wallcoreMaterial[data.level_type], this._wallcoreMaterial[data.level_type]]);
        //     roofTopMesh.name = eachLevel.name;
        //     roofTopMesh.layers.set(2);
        //     nodeData.buildingGroup.add(roofTopMesh);
        //     // eachLevel.add(roofTopMesh);
        //
        //     //
        //     if (data.with_parapet) {
        //
        //     } else {
        //         if (data.with_roof_line && 'FC_HOUSE' == data.level_type) {
        //             nodeData.roofLineHeight = nodeData.building_height + data.level_height;
        //
        //             let roofLineGeom = generateTopRoofLineGeometry(nodeData.node, nodeData.building_height + data.level_height);
        //             let roofLineMesh = new THREE.Mesh(roofLineGeom, this._wallcoreMaterial['FC_CORE']);
        //             roofLineMesh.name = eachLevel.name;
        //             roofLineMesh.layers.set(2);
        //             nodeData.buildingGroup.add(roofLineMesh);
        //         }
        //     }
        // }
    }

    public Dispose() : void {
        LevelGenerator._levelGroup = {};
    }

    public static generateFloorCeilingGeometry(node2: THREE.Vector2[], YPos: number, depth: number = FeatureGenerator._wallThickness / 2) : THREE.BufferGeometry {
        let geom: THREE.BufferGeometry,
            matrix = new THREE.Matrix4();
        // if (0 < arrCeilings.length) {
        //     geom = arrCeilings[0].clone();
        //
        //     matrix.set(
        //         1, 0, 0, 0,
        //         0, 1, 0, YPos,
        //         0, 0, 1, 0,
        //         0, 0, 0, 1);
        // } else
        {
            let simplifiedNode2 = SimplifyLineSegments(node2);
            let offsetNode2 = OffsetLineSegments(FeatureGenerator._wallThickness, simplifiedNode2);

            let sPath = new THREE.ShapePath();

            addPathToShapePath(sPath, offsetNode2);

            let shape = sPath.toShapes(false);

            geom = new THREE.ExtrudeBufferGeometry(shape, {
                steps: 1,
                depth: FeatureGenerator._wallThickness / 2,
                bevelEnabled: false
            });

            matrix.set(
                -1, 0, 0, 0,
                0, 0, 1, YPos - depth,
                0, 1, 0, 0,
                0, 0, 0, 1);
        }
        geom.applyMatrix4(matrix);
        // geom.computeVertexNormals();

        //texture mapping을 위한 group 분리
        // for(let i = geom.groups[0].start; i< geom.groups[0].count;i++){
        //
        // }
        let counts = [0, 0, 0];
        let normals = geom.getAttribute('normal');
        for (let i = 0; i < normals.count; i++) {
            // let eachNormal = new THREE.Vector3(normals.array[i * 3], normals.array[i * 3 + 1], normals.array[i * 3 + 2]);
            if (-1 == normals.array[i * 3 + 1]) {
                counts[0] += 1;
            } else if (1 == normals.array[i * 3 + 1]) {
                counts[1] += 1;
            } else {
                counts[2] += 1;
            }
        }

        geom.clearGroups();
        let lastIndex = 0;
        for (let i = 0; i < counts.length; i++) {
            geom.addGroup(lastIndex, counts[i], i);
            lastIndex += counts[i];
        }
        geom.computeVertexNormals();

        return geom as THREE.BufferGeometry;
    }
}