import { LineSegmentsGeometry } from '@teneleven/three/examples/jsm/lines/LineSegmentsGeometry';
import { Coordinate } from '@teneleven/protocols-ts-web/lib/map_pb';
import { BlockParsingData, ConverterField, FieldPart } from "./BuildingPart";
import { ErrorLogCell2, ErrorType } from "./ErrorLog";
import { getCircle, getCircleGeom, getCurveErrorCircleField, getLineErrorCircleBlock, makePolygonApartLine } from "./MeshMaker";
import * as THREE from '@teneleven/three';
import { FieldType } from "./Field";
import { CheckPolygonsApart, getPolygonType, JSTSGeoToTHREEGeo } from './CoreAndHouseController';
import { checkFieldBlockName, makePolygon, switchLineDashedState } from './FileParser';
import { ConverterType, Polygon } from './DataTypes';
import { ErrorList, SiteError } from './Error';
import * as turf from '@turf/turf';


const jsts = require('jsts');
interface FieldsType {
  sites?: ConverterField[],
  roads?: ConverterField[],
  roadCenterLines?: ConverterField[],
  grounds?: ConverterField[],
  special?: ConverterField[],
  topographies?: ConverterField[],
}

export function checkSiteError(parsingOutput: BlockParsingData, type: ConverterType, errorList: ErrorList) {
  let sites = parsingOutput.fields.filter(field => field.typeName === FieldType.site);
  let roads = parsingOutput.fields.filter(f => f.typeName === FieldType.road);
  let roadCenterLines = parsingOutput.fields.filter(f => f.typeName === FieldType.centerLineOfRoad);
  let grounds = parsingOutput.fields.filter(field => field.typeName === FieldType.vacancyOutside);
  let special = parsingOutput.fields.filter(field => field.typeName === FieldType.vacancyInside);
  let topographies = parsingOutput.fields.filter(field => field.typeName === FieldType.topography);
  let cadastralMapError = checkInsideCadastralMap(parsingOutput, errorList);
  // if (cadastralMapError.length > 0) {
  //   return;
  // }

  const hasKink = checkKink(parsingOutput, errorList);
  if (hasKink) return;

  checkSiteName(parsingOutput, type, errorList);
  checkSiteRound(parsingOutput.fields, errorList);
  checkClosedPolygon(parsingOutput.fields, errorList);
  checkFieldsApart({ sites, roads, roadCenterLines, grounds, special, topographies }, parsingOutput.fields, errorList);
  checkSamePolygon(parsingOutput.fields, errorList);
  checkFieldsInSite(sites, special, topographies, errorList);
  if (topographies.length > 0 && sites.length > 0) checkCorrectTopography(sites, topographies, errorList);
  
  //*인접대지경계선은 대지영역을 포함하며 대지영역보다 커야됨 (같거나 작으면안됨)    
  checkFieldOverlap({ sites, roads, roadCenterLines, grounds }, parsingOutput.fields, errorList);
  polygonNotInBlock(parsingOutput.wrongPolygons, errorList);
  applyTopographyInSite(sites, topographies, errorList);
}

function applyTopographyInSite(sites: ConverterField[], topographies: ConverterField[], errorList: ErrorList) {
  if (sites.length > 0 && topographies.length) {
    let sitePoly: jsts.geom.Geometry;
    sites.forEach((site, i) => {
      if (i === 0) sitePoly = site.getUnionJstsPolygon();
      else sitePoly = sitePoly.union(site.getUnionJstsPolygon());
    });

    topographies.forEach(topo => {
      let topoPolygon = topo.getUnionJstsPolygon();
      if (sitePoly && topoPolygon) {
        if (sitePoly.intersection(topoPolygon).getArea() > 0 && topoPolygon.difference(sitePoly).getArea() > 5) {
          // 성절토가 대지에 걸친 경우 - intersection만 남김
          const interPoly = sitePoly.intersection(topoPolygon);
          const geomVerts = interPoly.getCoordinates();

          let vertices: THREE.Vector3[] = [];
          vertices = geomVerts.map((vert: jsts.geom.Coordinate) => new THREE.Vector3(vert.x, vert.y, 0));
          
          while (topo.parts.length > 1) {
            topo.parts.pop();
          }

          topo.parts[0].area = Number(topo.parts[0].polygon.area.toFixed(2));
          topo.parts[0].verts = vertices.map(v => new THREE.Vector3(v.x, v.y, 0));
          topo.setArea(topo.parts[0].area);
          topo.calcArea = topo.getArea();

          {
            // scene은 폴리곤 형태 유지
            const geomVerts = topoPolygon.getCoordinates();
            let vertices: THREE.Vector3[] = [];
            vertices = geomVerts.map((vert: jsts.geom.Coordinate) => new THREE.Vector3(vert.x, vert.y, 0));

            const color = topo.parts[0].polygon.lineMesh.material.color;
            const type = topo.parts[0].polygon.type;
            const shape = topo.parts[0].polygon.shape;
            while (topo.parts.length > 1) {
              topo.parts.pop();
            }

            const matrixWorld = topo.renderGroup.matrixWorld.clone();

            topo.parts[0].polygon = makePolygon(vertices, color, type, shape);
            topo.parts[0].polygon.lineMesh.material.opacity = 1;
            topo.renderGroup.remove();
            topo.renderGroup = new THREE.Group();
            topo.renderGroup.add(topo.parts[0].polygon.lineMesh);
            topo.renderGroup.matrixWorld = matrixWorld;
          }

          errorList.addError(new SiteError({
            title: '[자동 선택] 원치 않을 시 캐드에서 재설정 후 다시 진행해 주세요.',
            msg: `${sites[0].name} 내부에 존재 하는 ${topo.name} 만이 자동으로 선택됩니다.`,
            type: ErrorType.Info,
            id: [topo.uuid],
            targetFields: [topo, sites[0]],
          }));
        }
      }
    })
  }
}

function polygonNotInBlock(wrongPolygons: Polygon[], errorList: ErrorList) {
  const polygonType: Set<any> = new Set();  

  wrongPolygons.forEach(polygon => {
    polygonType.add(getPolygonType(polygon.type, polygon.shape));
    switchLineDashedState(polygon.lineMesh.material,true);
  })

  if (wrongPolygons.length) {
    errorList.addError(new SiteError({
      title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.', 
      msg: `블록으로 지정되지 않은 ${Array.from(polygonType).join('/')} 이 존재하여 자동 삭제 되었습니다.`,
      type: ErrorType.Info,
      id: [],
      //@ts-ignore
      polygons: wrongPolygons,
    }));
  }

}

function siteAreaZero(sites: ConverterField[], errorList: ErrorList) {
  sites.forEach(site => {
    let splitedName = site.name.split('_');
    // SA or SA_0
    if (splitedName.length >= 2 && splitedName[1] !== '' && Number(splitedName[1]) === 0) { //Number(splitedName[1]) === 0) {
      errorList.addError(new SiteError({
        title: '[면적 경고] 저장 시 입력 면적으로 반영됩니다.', 
        msg: `${site.name}의 면적 값이 '0' 입니다.`,
        type: ErrorType.Warning,
        id: [site.uuid],
        targetFields: [site],
      }));
    }
  });
}


function checkCorrectTopography(sites: ConverterField[], topographies: ConverterField[], errorList: ErrorList) {
  // site 블록 1개
  // topographies블록들 union
  // 성절토와 사업여역 intersection폴리곤
  // site와 면적 비슷한지 체크
  
  sites.forEach(site => {
    const sitePoly = site.getUnionJstsPolygon();
    let topoPoly = undefined;
    if (topographies[0].getUnionJstsPolygon()) {
      //@ts-ignore
      topoPoly = topographies[0].getUnionJstsPolygon().buffer(0.01);    
    }
    
      for (let i = 1; i < topographies.length; i++) { 
        if (topographies[i].getUnionJstsPolygon()) {
        //@ts-ignore
        topoPoly = topoPoly.union(topographies[i].getUnionJstsPolygon().buffer(0.01));
        }
      }  
    
    
    if (sitePoly && topoPoly) {
      const interPoly = sitePoly.intersection(topoPoly);
      if (Number(interPoly.getArea().toFixed(4)) !== Number(sitePoly.getArea().toFixed(4))) {
        let group = new THREE.Group();

        // let mesh = JSTSGeoToTHREEGeo(sitePoly.difference(topoPoly));
        // mesh.visible = false;
        // group.add(mesh);
        
        //@ts-ignore
        const differPoly = sitePoly.difference(topoPoly)//.buffer(0.5);
        if (differPoly.getGeometryType() === "Polygon") {
          let mesh = JSTSGeoToTHREEGeo(differPoly, new THREE.Color(1, 1, 0));          
          mesh.visible = false;
          group.add(mesh);
        }
        else {
          //@ts-ignore
          differPoly._geometries.forEach(geom => {
            let mesh = JSTSGeoToTHREEGeo(geom, new THREE.Color(1, 1, 0));          
            mesh.visible = false;
            group.add(mesh);
          });
        }
      
        group.add(getCircleGeom(differPoly));        
        errorList.addError(new SiteError({
          title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
          msg: `EL숫자_높이 블록으로 설정되지 않아 누락될 수 있는 SA 블록 면적이 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [sites[0].uuid],
          targetFields: sites,//.concat(topographies),
          //@ts-ignore
          hilightPolygon: group,
        }));
        site.ErrorPolygonGroup.add(group);
      }  
    }
  })
}

function checkSamePolygon(fields: ConverterField[], errorList: ErrorList) {
  // 같은 블록 안에 면적이 같은 N개의 폴리곤 존재
  fields.forEach((field: ConverterField) => {
    let count = 0;

    for (let i = 0; i < field.parts.length; i++) {
      for (let j = i + 1; j < field.parts.length; j++) {
        if (Math.abs(field.parts[i].area - field.parts[j].area) < 0.01) {
          count++;
        }
      }
    }
    if (count > 0) {
      errorList.addError(new SiteError({
        title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
        msg: `${field.name} 영역에 저장 할수 없는 ${count}개의 폴리곤이 존재합니다. 
          각 블록 별로 1개의 폴리곤만 설정 가능합니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: [field.uuid],
        targetFields: [field],
      }));
    }
  })
}

function checkFieldOverlap(fields: FieldsType, parsedFields: ConverterField[], errorList: ErrorList) {

  if (fields.sites!.length > 0) {

    fields.sites!.forEach(site => {
      let sitePoly = site.getUnionJstsPolygon();
      // 1. 대지 - 인접대지경계선이 겹쳐져 있을 경우
      if (sitePoly) {
        if (fields.roadCenterLines && fields.roadCenterLines.length > 0) {
          fields.roadCenterLines.forEach(adjLine => {
            let adjLinePoly = adjLine.getUnionJstsPolygon();
            if (adjLinePoly) {
              if (sitePoly.covers(adjLinePoly) || Math.abs(sitePoly.union(adjLinePoly).getArea() - adjLinePoly.getArea()) >= 0.4) {
                errorList.addError(new SiteError({
                  title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
                  msg: `대지 영역 내에 인접 대지 경계선 을 설정 할 수 없습니다. 캐드에서 재설정 후 다시 진행하세요.`,
                  type: ErrorType.Error,
                  id: [adjLine.uuid],
                  targetFields: [adjLine],
                }));
              }
            }
          })
        }

        // 대지 - 도로의 겹침
        if (fields.roads && fields.roads.length > 0) {
          fields.roads.forEach(road => {
            let roadPoly = road.getUnionJstsPolygon();
            if (roadPoly) {
              let intersection = sitePoly.intersection(roadPoly);
              if (intersection.getArea() > roadPoly.getArea() * 0.01) {
                let group = new THREE.Group();
                let mesh = JSTSGeoToTHREEGeo(intersection);
                group.add(mesh);
                mesh.visible = false;

                errorList.addError(new SiteError({
                  title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
                  msg: `${site.name}과 ${road.name}의 면적이 서로 교차되어 있습니다.`,
                  type: ErrorType.Warning,
                  id: [road.uuid, site.uuid],
                  targetFields: [road, site],
                  //@ts-ignore
                  hilightPolygon: group,
                }));
                site.ErrorPolygonGroup.add(group);
              }
            }
          })
        }
      }
    })
  }

  // 도로 - 공지 겹침
  if (fields.roads!.length > 0 && fields.grounds!.length > 0) {
    fields.roads!.forEach(road => {
      const roadPoly = road.getUnionJstsPolygon();
      if (roadPoly) {
        fields.grounds!.forEach(ground => {
          const groundPoly = ground.getUnionJstsPolygon();
          if (groundPoly) {
            const intersection = roadPoly.intersection(groundPoly);
            if (intersection.getArea() > roadPoly.getArea() * 0.01) {
              let group = new THREE.Group();
              let mesh = JSTSGeoToTHREEGeo(intersection);
              group.add(mesh);
              mesh.visible = false;

              errorList.addError(new SiteError({
                title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
                msg: `${road.name}과 ${ground.name}의 면적이 서로 교차되어 있습니다.`,
                type: ErrorType.Warning,
                id: [road.uuid, ground.uuid],
                targetFields: [road, ground],
                //@ts-ignore
                hilightPolygon: group,
              }));
              road.ErrorPolygonGroup.add(group);
            }
          }
        })
      }
    })
  }
}



function checkFieldsApart(fields: FieldsType, parsedFields: ConverterField[], errorList: ErrorList) {

  const MILLIMETER = 1000;

  // if (fields.roads!.length === 0)
  //   fields.roads = fields.roadCenterLines;

  // 폴리곤 이격 체크
  if (fields.sites && fields.sites.length > 0) {
    let unionPolygon = fields.sites[0].getUnionJstsPolygon();

    for (let i = 1; i < fields.sites.length; i++) {
      unionPolygon.union(fields.sites[i].getUnionJstsPolygon());
    }

    const sitePolygon = unionPolygon;
    const notUnion: ConverterField[] = [];
    const blockList: ConverterField[] = [];

    [fields.special, fields.grounds, fields.roads].forEach((blocks) => {
      blocks && blockList.push(...blocks);
    })

    // 다른 블록 폴리곤 거리가 대지와 가까운 순으로 정렬
    blockList.sort((block1, block2) => unionPolygon.distance(block1.getUnionJstsPolygon()) - unionPolygon.distance(block2.getUnionJstsPolygon()));

    blockList.forEach(block => {
      let polygon = block.getUnionJstsPolygon();
      //@ts-ignore
      if (polygon) polygon = polygon.buffer(0.001);
      if (unionPolygon.distance(polygon) >= 0.1) notUnion.push(block);
      else unionPolygon = unionPolygon.union(polygon);
    });

    notUnion.forEach(block => {
      let polygon = block.getUnionJstsPolygon();
      //@ts-ignore
      if (polygon) polygon = polygon.buffer(0.001);
      const distance = sitePolygon.distance(polygon) * MILLIMETER;
      
      if (distance >= 10) {
        const group = makePolygonApartLine(polygon, sitePolygon);
        errorList.addError(new SiteError({
          title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
          msg: `${fields.sites![0].name}과 ${block.name} 사이에 10mm 이상의 이격 오차가 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [fields.sites![0].uuid, block.uuid],
          targetFields: [fields.sites![0], block],
          //@ts-ignore
          hilightPolygon: group,
        }));
        block.ErrorPolygonGroup.add(group);
        block.unused = true;
        block.parts.forEach(part => {
          part.unused = true;
        });
        block.parts.forEach(part => {
          switchLineDashedState(part.polygon.lineMesh.material, true);
        })
      }
      else if (distance >= 1 && distance < 10) {
        // 워닝
        const polygon = block.getUnionJstsPolygon();
        let group = makePolygonApartLine(polygon, sitePolygon);
        errorList.addError(new SiteError({
          title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
          msg: `${fields.sites![0].name} 과 ${block.name} 간의 ${distance.toFixed(2)}mm 의 이격 오차가 있습니다.`,
          type: ErrorType.Warning,
          id: [block.uuid, fields.sites![0].uuid],
          targetFields: [block, fields.sites![0]],
          //@ts-ignore
          hilightPolygon: group,
        }));
        block.ErrorPolygonGroup.add(group);
        block.unused = true;
        block.parts.forEach(part => {
          part.unused = true;
        });
        block.parts.forEach(part => {
          switchLineDashedState(part.polygon.lineMesh.material, true);
        })
      }
      else if (distance > 0 && distance < 1) {
        errorList.addError(new SiteError({
          title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
          msg: `${fields.sites![0].name} 과 ${block.name}의 이격된 라인이 자동 보정 되었습니다. `,
          type: ErrorType.Info,
          id: [block.uuid, fields.sites![0].uuid],
          targetFields: [block, fields.sites![0]],
        }));
      }
    })

    if (sitePolygon) {
      // 도로영역 or 인접대지 경계선이 없을경우 error
      let isRoadAvaliable = false;

      if ((fields.roads && fields.roads.filter(road => !road.unused).length > 0) || (fields.roadCenterLines && fields.roadCenterLines.filter(line => !line.unused).length > 0)) {
        isRoadAvaliable = true;
      }

      if (!isRoadAvaliable) {
        errorList.addError(new SiteError({
          title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
          msg: `최소 하나의 도로 영역이(RA) 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [],
        }));
      }
    }
  }
}

function checkKink(parsingOutput: BlockParsingData, errorList: ErrorList) {
  let hasKink = false;
  parsingOutput.fields.forEach(field => {
    for (let i = 0; i < field.parts.length; i++) {
      field.renderGroup.updateMatrixWorld();
      field.parts[i].getJSTSPolygon(field.renderGroup.matrixWorld);
      if (field.parts[i].isKink) {
        field.isKink = true;
        field.unused = true;
        
        const turfCoords = field.parts[i].verts.map((v: { x: number, y: number }) => {
          const newV = new THREE.Vector3(v.x, v.y).applyMatrix4(field.renderGroup.matrixWorld);
          return [newV.x, newV.y];
        })
        
        const poly = turf.polygon([turfCoords]);
        const kinks = turf.kinks(poly);
        const group = new THREE.Group();

        if (kinks.features.length && kinks.features[0].geometry) {
          const [x, y] = kinks.features[0].geometry.coordinates;
          const v = new THREE.Vector3(x, y);
          const mesh = getCircle(v, v, new THREE.Color(1, 0, 0), 5, 10);
          // mesh.visible = true;
          group.add(mesh);
        }
        field.ErrorPolygonGroup.add(group);
        
        errorList.addError(new SiteError({
          title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
          msg: `${field.name} 내부에 꼬인 점이 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [field.uuid],
          targetFields: [field],
          //@ts-ignore
          hilightPolygon: group,
        }));
        hasKink = true;
      }
    }
  })

  return hasKink;
}


function checkInsideCadastralMap(parsingOutput: BlockParsingData, errorList: ErrorList) {
  // 지적도 밖의 폴리라인 체크
  if (parsingOutput.cadastralMap!.length > 0) {
    let boundaryVerts = parsingOutput.cadastralMap![0].boundary.vertices;
    
    let coords: jsts.geom.Coordinate[] = [];

    const startVert = boundaryVerts[0];
    const endVert = boundaryVerts[boundaryVerts.length - 1];

    if (boundaryVerts.length >= 4 && startVert.x === endVert.x && startVert.y === endVert.y) {
      boundaryVerts.forEach((v: { x: number, y: number }) => {
        coords.push(new jsts.geom.Coordinate(v.x, v.y));
      })


      let geoFac = new jsts.geom.GeometryFactory();

      let linearRing = geoFac.createLinearRing(coords);

      //@ts-ignore
      // const boundary = geoFac.createPolygon(linearRing, []).buffer(0);

      parsingOutput.fields.forEach((field: ConverterField) => {
        field.getUnionJstsPolygon()
        field.renderGroup.updateWorldMatrix(true, true);
        let matrix = field.renderGroup.matrixWorld;

        let polygon: jsts.geom.Geometry;
        if (field.parts[0]) {
          polygon = field.parts[0].getJSTSPolygon(matrix);
          field.parts.forEach((p, i) => {
            if (i !== 0) polygon = polygon.union(p.getJSTSPolygon(matrix));
          })
        }
      })


      // 지적도 밖에 있는 블록이 아닌 폴리곤 삭제

      {

        let isPolygonDel = false;
        let matrix = parsingOutput.fields.length > 0 ? parsingOutput.fields[0].renderGroup.matrixWorld : new THREE.Matrix4().identity();
        const type = new Set();

        for (let i = 0; i < parsingOutput.wrongPolygons.length; i++) {
          const polygon = parsingOutput.wrongPolygons[i];

          polygon.vertices.forEach((v: { x: number, y: number }) => {
            const coord = new THREE.Vector3(v.x, v.y).applyMatrix4(matrix);
            coords.push(new jsts.geom.Coordinate(coord.x, coord.y));
          })
        }

        if (isPolygonDel) {
          errorList.addError(new SiteError({
            title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
            msg: `블록으로 지정되지 않은 ${Array.from(type).join('/')}의 경우 다운 받는 지적도 영역을 벗어나 자동 삭제 되었습니다.`,
            type: ErrorType.Info,
            id: [],
          }));
        }



      }
    }

  }
}


export function checkSiteName(parsingOutput: BlockParsingData, type: ConverterType, errorList: ErrorList) {
  // 전체 특수문자 보정 체크
  for (let i = 0; i < parsingOutput.wrongBlocks.length; i++) {
    const block = parsingOutput.wrongBlocks[i];
    const correctedName = block.name.replace(/\s/gi, '').replace(/\'/gi, '');

    if (block.name !== correctedName) {
      let isCorrect = false;
      if (/^[L][A](\d+)/i.test(correctedName)) {
        block.typeName = FieldType.vacancyInside;
        isCorrect = checkFieldBlockName(correctedName, FieldType.vacancyInside);
      }
      else if (/^[R][A](\d+)/i.test(correctedName)) {
        block.typeName = FieldType.road;
        isCorrect = checkFieldBlockName(correctedName, FieldType.road);
      }
      else if (/^[G][A](\d+)/i.test(correctedName)) {
        block.typeName = FieldType.vacancyOutside;
        isCorrect = checkFieldBlockName(correctedName, FieldType.vacancyOutside);
      }
      else if (/^[A][A](\d+)/i.test(correctedName)) {
        block.typeName = FieldType.centerLineOfRoad;
        isCorrect = checkFieldBlockName(correctedName, FieldType.centerLineOfRoad);
      }
      else if (/^[S][A](\d+)/i.test(correctedName)) {
        block.typeName = FieldType.site;
        isCorrect = checkFieldBlockName(correctedName, FieldType.site);
      }
      else if (/^[E][L](\d+)/i.test(correctedName)) {
        block.typeName = FieldType.topography;
        isCorrect = checkFieldBlockName(correctedName, FieldType.topography);
      }

      if (isCorrect) {
        parsingOutput.fields.push(block);
        parsingOutput.wrongBlocks.splice(i, 1);
        i--;
        errorList.addError(new SiteError({
          title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
          msg: `${block.name}에 유효하지 않은 기호가 포함되어 자동보정 되었습니다.`,
          type: ErrorType.Info,
          id: [block.uuid],
          targetFields: [block],
        }));
        ConverterField.setColor(block.typeName, block.parts);
        block.name = correctedName;
      }
    }
  }

  if (parsingOutput.wrongBlocks.length > 0) {
    //let filtered = parsingOutput.buildings.filter(building => building.name !== 'A$Cd09eb8b0'&& building.name !== 'DIMDOT');
    parsingOutput.wrongBlocks.forEach((block: ConverterField) => {
      const splitedName = block.name.split('_');
  
      if (!/^[S][A](\d+)$/i.test(splitedName[0]) && !/^[L][A](\d+)$/i.test(splitedName[0]) && !/^[E][L](\d+)$/i.test(splitedName[0]) && !/^[G][A](\d+)$/i.test(splitedName[0]) && 
      !/^[A][A](\d+)$/i.test(splitedName[0]) && !/^[R][A](\d+)$/i.test(splitedName[0])) { // _가 없을때
        errorList.addError(new SiteError({
          title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
          msg: `${block.name} 은(는) 유효하지 않은 블록입니다.
          수정이 필요한 블록: ${block.name}`,
          type: ErrorType.Error,
          id: [block.uuid],
          targetFields: [block],
        }));    
      }
      else {
        errorList.addError(new SiteError({
          title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
          msg: `유효하지 않은 문자가 포함되어 있습니다. 
          수정이 필요한 블록: ${block.name}`,
          type: ErrorType.Error,
          id: [block.uuid],
          targetFields: [block],
        }));    
      }
    })
  }

  const sites = parsingOutput.fields.filter(field => field.typeName === FieldType.site);
  sites.forEach(site => {
    const splitedName = site.name.split('_');
    if (splitedName.length === 1) {
      errorList.addError(new SiteError({
        title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
        msg: `${site.name}의 면적이 누락 되어 계산 면적으로 입력되었습니다.`,
        type: ErrorType.Info,
        id: [site.uuid],
        targetFields: [site],
      }));
    }

  });

  if (sites.length > 0) siteAreaZero(sites, errorList);
}

function checkClosedPolygon(fields: ConverterField[], errorList: ErrorList) {
  let errorLogs: ErrorLogCell2[] = [];

  fields.forEach((field: ConverterField) => {
    let group = new THREE.Group();
    let diameter = 5;
    // -- mm단위만 사용해서 삭제 --
    // switch (field.unitScale) {
    //   case 0.001: // mm
    //     diameter = 5;
    //     break;
    //   case 1: // m
    //     diameter = 5000;
    //     break;
    //   case 39.3701: // inch
    //     diameter = 100000;
    //     break;
    //   default:
    //     break;
    // }
    const editedPolygon = [];

    field.parts.forEach(part => {
      
      if (!part.shape) {
        const PART_LENGTH = part.verts.length;
        part.renderGroup.updateWorldMatrix(true, true);
        let matrixWorld = part.renderGroup.matrixWorld;
        let v1 = new THREE.Vector3(part.verts[0].x, part.verts[0].y, 0).applyMatrix4(matrixWorld);
        let v2 = new THREE.Vector3(part.verts[PART_LENGTH - 1].x, part.verts[PART_LENGTH - 1].y, 0).applyMatrix4(matrixWorld);        
        let circleMesh1 = getCircle(v1, v1, new THREE.Color(1, 0, 0), 5, 5000);
        let circleMesh2 = getCircle(v2, v2, new THREE.Color(1, 0, 0), 5, 5000);
        group.renderOrder = 1;
        group.add(circleMesh1);
        group.add(circleMesh2);
      }
      
      field.renderGroup.add(group);
    });
    const editedPoly = field.parts.filter(part => part.editPolygonClose).length;
    if (editedPoly > 0) {
      errorList.addError(new SiteError({
        title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.', 
        msg: `${field.name} 의 polyline이 닫힌 polyline으로 자동 보정 되었습니다. `,
        type: ErrorType.Info,
        id: [field.uuid],
        targetFields: [field],
      }));
    }

    
    if (group.children.length > 0) {
      errorList.addError(new SiteError({
        title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
        msg: `${field.name}에 닫혀 있지 않은 Poly line 이 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: [field.uuid],
        targetFields: [field],
        //@ts-ignore
        hilightPolygon: group,
      }));

    //  errorLogs.push(makeErrorInformation2('[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', `${field.name}에 닫혀 있지 않은 Poly line 이 있습니다. `, group));
    }
  })
  return errorLogs;
}

function checkSiteRound(fields: ConverterField[], errorList: ErrorList) {

  fields.forEach(field => {
    let group = new THREE.Group();
    field.renderGroup.updateWorldMatrix(true, true);
    let matrix = field.renderGroup.matrixWorld;
    group.applyMatrix4(matrix);
    field.parts.forEach(part => {
      if (part.hasCurve) {
        group.add(getCurveErrorCircleField(part));
        errorList.addError(new SiteError({
          title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
          msg: `${field.name}에 ARC 가 있습니다. 캐드에서 재설정 후 다시 진행하세요.`,
          type: ErrorType.Error,
          id: [field.uuid],
          //@ts-ignore
          hilightPolygon: group,
        }));
        // errorLogs.push(makeErrorInformation2('[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
        // `${field.name}에 ARC 가 있습니다.`, group));
      }   
    })
    field.ErrorPolygonGroup.add(group);
  })

}

export function checkSiteStructure(fields: { sites: ConverterField[], roads: ConverterField[], roadCenterLines: ConverterField[] }, allFields: ConverterField[], errorList: ErrorList) {
  let isStructureOK = true;
  if (fields.sites.length === 0) {
    errorList.addError(new SiteError({
      title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.', 
      msg: `최소 하나의 대지 영역(SA)이 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다. 캐드에서 재설정 후 다시 진행하세요.`,
      type: ErrorType.Error,
      id: fields.sites.map(site => site.uuid),
      targetFields: fields.sites,
    }));
    isStructureOK = false;
    return isStructureOK;
  }
  else {
    // SA영역 블록이 N 개 존재하는 경우
    if (fields.sites.length > 1) {
      errorList.addError(new SiteError({
        title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
        msg: `대지 영역의 블록이 ${fields.sites.length}개 있습니다. 나의 사업영역은 하나의 대지 영역(SA) 블록만을 허용합니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: fields.sites.map(site => site.uuid),
        targetFields: fields.sites,
      }));
    }


    let isSiteAreaZero = true;
    fields.sites.forEach(site => {
      const sitePoly = site.getUnionJstsPolygon();
      if (sitePoly && sitePoly.getArea() !== 0) isSiteAreaZero = false;
    })
    if (isSiteAreaZero) {
      errorList.addError(new SiteError({
        title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.', 
        msg: `최소 하나의 대지 영역(SA)이 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다. 캐드에서 재설정 후 다시 진행하세요.`,
        type: ErrorType.Error,
        id: fields.sites.map(site => site.uuid),
        targetFields: fields.sites,
      }));
      isStructureOK = false;
      return isStructureOK;
    }
  }

  const isRoadLinePolyExist = fields.roadCenterLines.filter(line => line.getArea() !== 0).length === 0 ? false : true;
  const isRoadExist = fields.roads.length === 0 ? false : true;
  const isRoadPolyExist = fields.roads.filter(road => road.parts.length).length === 0 ? false : true;
  const isRoadAreaExist = fields.roads.filter(road => road.getArea() !== 0).length === 0 ? false : true;
  // 1) RA블록 없을 때 + 폴리곤 미존재,
  // 2) RA 폴리곤면적이 0일때

  if ((fields.roadCenterLines.length === 0 || !isRoadLinePolyExist) && (!isRoadExist || !isRoadPolyExist || !isRoadAreaExist)) {
    errorList.addError(new SiteError({
      title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
      msg: `최소 하나의 도로 영역(RA)이나 인접대지경계선(AA)이 선택 되어야 하며, 이때 도로 면적은 0㎡ 보다 커야합니다. 캐드에서 재설정 후 다시 진행하세요.`,
      type: ErrorType.Error,
      id: fields.roads.map(road => road.uuid),
      targetFields: fields.roads,
    }));
    isStructureOK = false;
  }
  else if ((fields.roadCenterLines.length > 0 || isRoadLinePolyExist) && (!isRoadExist || !isRoadPolyExist || !isRoadAreaExist)) {
    //Warning
    errorList.addError(new SiteError({
      title: '[블록 구조 오류] 도로 영역의 누락으로 일부 솔버가 실패할 수 있습니다.',
      msg: `최소 하나의 도로 영역이(RA) 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다.`,
      type: ErrorType.Warning,
      id: fields.roads.map(road => road.uuid),
      targetFields: fields.roads,
    }));
    isStructureOK = false;
  }



  return isStructureOK;
  // allFields.forEach(field => {
  //   if (field.isMultiple) {
  //     let mainMsg = "[자동 선택] 원치 않을 시 캐드에서 재설정 후 다시 진행해 주세요.";
  //     let subMsg = "";

  //     switch (field.typeName) {
  //       case FieldType.site:
  //         subMsg = "대지 영역 블록은 하나의 대지 영역 Polygon 만을 허용합니다. 가장 큰 면적의 대지 영역이 자동으로 선택됩니다.";
  //         break;
  //       case FieldType.road:
  //         subMsg = "도로 영역 블록은 하나의 도로 Polygon 만을 허용합니다. 가장 큰 면적의 도로 영역이 자동으로 선택됩니다. ";
  //         break;
  //       case FieldType.centerLineOfRoad:
  //         subMsg = "나의사업영역은 하나의 인접 대지 경계선만을 허용합니다. 가장 큰 면적의 인적 대지 경계선이 자동으로 선택됩니다.";
  //         break;
  //       case FieldType.vacancyInside:
  //         subMsg = "경관 영역 블록은 하나의 배치 제한 영역 Polygon 만을 허용합니다. 가장 큰 면적의 배치 제한 영역이 자동으로 선택됩니다.";
  //         break;
  //       case FieldType.vacancyOutside:
  //         subMsg = "공지영역 블록은 하나의 공지영역 Polygon 만을 허용합니다. 가장 큰 면적의 공지 영역이 자동으로 선택됩니다.";
  //         break;
  //       case FieldType.topography:
  //         subMsg = "고저차 블록은 하나의 고저차 (대지레벨) 만을 허용합니다. 가장 큰 면적의 고저차 영역이 자동으로 선택됩니다.";
  //         break;
  //       default:
  //         break;
  //     }

  //   }
  // });

}

function checkFieldsInSite(sites: ConverterField[], special: ConverterField[], topography: ConverterField[], errorList: ErrorList) {
  // 대지영역 외부에 영역이 존재
  // 1) 대지 - 배치제한영역
  // 2) 대지 - 대지레벨

  sites.forEach(site => {
    let sitePoly = site.getUnionJstsPolygon();
    if (sitePoly) {
      //@ts-ignore
      sitePoly = sitePoly.buffer(0.01);
      special.concat(topography).forEach(field => {
        const fieldPoly = field.getUnionJstsPolygon();

        if (fieldPoly && sitePoly.intersection(fieldPoly).getArea() === 0) {
          const difference = fieldPoly.difference(sitePoly);          
          const group = new THREE.Group();
          let mesh = JSTSGeoToTHREEGeo(difference, new THREE.Color(1, 1, 0));
          mesh.visible = false;
          group.add(mesh);
          field.ErrorPolygonGroup.add(group);
          field.unused = true;
          field.parts.forEach(part => part.unused = true);
          errorList.addError(new SiteError({
            title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
            msg: `${site.name} 외부에 존재하는 ${field.name}이 있습니다.`,
            type: ErrorType.Warning,
            id: [field.uuid],
            targetFields: [field, site],
            //@ts-ignore
            hilightPolygon: group,
          }));
        }
      })
    }
  })
}
