import { ConverterType } from './DataTypes';
import { ConverterField } from './BuildingPart';
import { Polygon } from './../Overlay';
import { ReferenceObject } from 'popper.js';
import { ErrorLogCell2, ErrorType } from './ErrorLog';

// type ErrorItem = {
//   type: ErrorType,
//   uuid: string,

// }
export class ErrorList {
  errorList: ErrorItem[] = [];
  private _warningSize: number = 0;
  private _errorSize: number = 0;
  private _infoSize: number = 0;

  constructor() {
    //this.type 
  }
  
  isCompleteById(id: string) {
    return this.errorList.filter(log => (log.id.indexOf(id) !== -1 && log.type === ErrorType.Error)).length > 0 ? false : true; 
  }

  getWarningSize() {
    return this._warningSize;
  }
  
  getErrorSize() {
    return this._errorSize;
  }
  
  getInfoSize() {
    return this._infoSize;
  }

  groupingError(list: Array<any>) {
    const group = new Map();
    list.forEach(error => {
      if (group.get(error.title)) {                
        group.set(error.title, group.get(error.title).concat(error));
      }
      else {        
        group.set(error.title, [error])
      }
    })
    return Array.from(group);
  }

  getError() {
    return this.groupingError(this.errorList);
  }

  getErrorById(id: string) {
    const result = this.errorList.filter(error => error.id.indexOf(id) > -1);
    return this.groupingError(result);
  }
  
  getErrorByType(type: ErrorType) {
    if (type === ErrorType.All) return this.getError();
    else {
      const result = this.errorList.filter(error => error.type === type);
      return this.groupingError(result);
    }
  }

  getErrorByConverterType(type: ConverterType) {
    if (type === ConverterType.mySite) {
      const siteError = this.errorList.filter(error => error.constructor.name === "SiteError");
      return this.groupingError(siteError);
    }
    else if (type === ConverterType.myType) {
      const typeError = this.errorList.filter(error => error.constructor.name === "TypeError");
      return this.groupingError(typeError);
    }
    else return this.groupingError([]);
  }

  delErrorByTitle(title: string) {

    for(let i = 0; i < this.errorList.length; i++) {     
      if(this.errorList[i].title === title)  {
        if (this.errorList[i].type === ErrorType.Error) this._errorSize--;
        else if (this.errorList[i].type === ErrorType.Warning) this._warningSize--;
        else if (this.errorList[i].type === ErrorType.Info) this._infoSize--;

        this.errorList.splice(i, 1);
      
        i--;
      }
    
    }    
  }



  delAreaError(id: string) {
    for (let i = 0; i < this.errorList.length; i++) {
      if (this.errorList[i].id.indexOf(id) > -1 && this.errorList[i].title === "[면적 경고] 저장 시 입력 면적으로 반영됩니다." &&
      /사용자가 입력한 면적과 실제 폴리곤 면적이 서로 상이합니다./gi.test(this.errorList[i].msg)
      ) {
        this._warningSize--;
        this.errorList.splice(i, 1);
      }
    }
  }

  // addError
  addError(item: any) {
    if (item.type === ErrorType.Error) this._errorSize++;
    else if (item.type === ErrorType.Warning) this._warningSize++;
    else if (item.type === ErrorType.Info) this._infoSize++;
    this.errorList.push(item);
  }

  resetError() {
    this._errorSize = 0;
    this._warningSize = 0;
    this._infoSize = 0;
    while (this.errorList.length)
      this.errorList.pop();
  }
  // type별로보여주기
  // 같은 msg끼리 묶기
  // 특정id 블록의 오류 보여주기
}

type ErrorItemType = {
  title: string,
  msg: string ;
  type: ErrorType;
  id: Array<string>;
  hilightPolygon?: THREE.Group,

  // 동평면
  components?: any[];
  polygons?: Polygon[];
  // 사업영역
  targetFields?: ConverterField[],
  allFields?: ConverterField[],
}
export class ErrorItem {
  msg: string = "";
  title: string = "";
  type: ErrorType;
  id: Array<string> = [];
  hilightPolygon?: THREE.Group;
  polygons?: Polygon[];

//  ReferenceObject
  constructor(item: ErrorItemType) {
    this.msg = item.msg;
    this.title = item.title;
    this.type = item.type;
    this.id = item.id; // 해당블록 uuid
    if (item.polygons)
      this.polygons = item.polygons;

    if (item.hilightPolygon)
      this.hilightPolygon = item.hilightPolygon;
  }
}

export class TypeError extends ErrorItem {
  components: any[];
  constructor(item: ErrorItemType) {
    super(item);
    this.components = item.components!;
  }
}

export class SiteError extends ErrorItem {
  targetFields: ConverterField[];
  constructor(item: ErrorItemType) {
    super(item);
    this.targetFields = item.targetFields!;
  }
}