import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplateBlock.scss';
import '../css/CADConverter/CADNavigator.scss';
import '@teneleven/protocols-ts-web';
import '../css/BuilditUI.scss';
import { ConverterLayer, buildingPlanStruct, SiteType, BuildingTypeData, LayerType, selectHouse, mapProjectionData, DEMData, ListType, ConverterType, CompletenessType, PartOfSelect, Polygon } from './DataTypes';
import { ReactComponent as MySite } from '../img/CADConverter/mySite.svg';
import { ReactComponent as BuildingTypeIcon } from '../img/CADConverter/buildingTypeIcon.svg';
import { dataParsing, asyncFileRead, blockParsing } from './FileParser';
import { NaverMapManager, NaverPoint, NaverLatLng, NaverPolygon } from './NaverMapManager';
import { jstsPolygontoWKT, latlng2tm, SceneManager, tm2latlng } from './SceneManager';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite, polygonBufferWithDistance, getDEM, getBuildingData, checkFileName, checkSpecialSymbolInName, getRoadLine, makeBlockBuildingData } from './DBManager';
import { MakeANewBuilding, deleteFieldFromFieldList, getFieldsArea, deleteBuildingFromBuildingList, getTotalHousehold, calculateAreaProPortion, buildingStoriesAvg, mouseOutLayerTag, mouseOverLayerTag, mouseOverHouseTag, switchLayerState, findBuilding, wkt2LatLngs, blockBuildingStoriesAvg, getBlockTotalHouseHold, calculateBlockAreaProPortion, brightenAllLayer, darkenAllLayer, brightenAllField, darkenAllField, setBlockOpacity, handleOpacityCertainType, setBlockNameNumber } from './CoreAndHouseController';
import { any } from 'prop-types';
import { Button, Snackbar } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ForwardArrow from '@material-ui/icons/ArrowForward';
import { Field, FieldType } from './Field';
import { BuildingType } from './BuildingType';
import { buildingData, building, resultLocation } from './resultLocationDataStruct';
import { shapeInfoData } from './ProjectDataStruct';
import { resultS3Json, base_report_file_path, base_result_file_path, housing_type_ratio } from './resultDataStruct';
import { ModalProps, CadConverterModal, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import AWSModule from '../AWSModule';
import App from '../App';
import { FieldUI } from './FieldUI';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { Setting, userSettingData } from './SettingModal';
import { House } from './House';
import { Core } from './Core';
import { ConverterBlock } from './ConverterBlock';
import { BlockParsingData, ConverterBuilding, BuildingComponent, BuildingPart, FieldPart, ConverterField } from './BuildingPart';
import MySiteBlockSaveModal from './MySiteBlockSaveModal';
import { checkSiteError, checkSiteStructure } from './CheckSiteBlockError';
import { ErrorLogCell2, ErrorType } from './ErrorLog';
import { ErrorLogBlock } from './ErrorLogBlock';
import _ from 'lodash';
import { checkBlockName, checkBlockStruct, checkConnectionComponent, checkLayerNameError, checkTypeError } from './CheckTypeBlockError';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { LoadingPage } from './LoadingPage';
import BlockTreeList from './BlockTreeList';
import { BuildingPartUI } from './BuildingPartUI';
import { ReactComponent as BlockIcon } from '../img/icon/blockIcon.svg';
import FieldPartUI from './FieldPartUI';
import { ErrorList, ErrorItem, SiteError, TypeError } from './Error';
import { ChevronLeft, Info, NavigateNext, SpeakerNotes } from '@material-ui/icons';
import { ReactComponent as HierarchyIcon } from '../img/icon/hierarchyIcon.svg';
import Tooltip from '../Tooltip';
import {ReactComponent as LevelIcon} from '../img/icon/levelIcon.svg';
import Tabs from '../Tabs';
import Modal, { ModalOptions } from '../Modal';
import ConstructionLineInfo from '../ConstructionLineInfo';
import { BuildingGroup } from './BuildingGroup';
import { LineInfoType } from '../model/Project';
import CADNavigator from './CADNavigator';
import BuilditSelect from '../BuilditSelect';
import polylabel from 'polylabel';
import DrawingManager2 from '../DrawingManager2';

import { Polygon as MapPolygon} from "../Shape";

const uuid4 = require('uuid/v4');
const jsts = require('jsts');

interface MyPlanProps {

}

interface MyPlanState {
  //  handle: number;
  isCad: boolean;
  //  layerListHidden: boolean;
  //  areaListHidden: boolean;
  //  buildingListHidden: boolean;
  // houseListHidden: boolean;
  screenWidth: number;
  screenHeight: number;
  canvasAlpha: number;
  fileName: string;
  saveName: string;
  showBlock: boolean;
  settingID: string;
  siteArea: number;
  roadArea: number,
  vacancyOusideArea: number,
  vacancyInsideArea: number,
  address: string,
  totalGroundArea: number;
  totalBuildingArea: number;
  exclusiveAverageArea: number;
  isSettingSite: boolean;
  listType: ListType;
  houses: House[];
  buildings: BuildingTypeData[];
  userID: string,
  showModal: boolean,
  autoset: boolean,
  isCadastral: boolean,
  loadFile: boolean,
  dragCover: boolean,
  showSettingModal: boolean,
  showSaveModal: boolean,
  isSaved: boolean,
  loadingPage: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
  infoSize: number,
  field: {
    site: ConverterField[],
    road: ConverterField[],
    roadCenterLine: ConverterField[],
    vacancyInside: ConverterField[],
    vacancyOutside: ConverterField[],
    topography: ConverterField[],
  },
  clickedField: any,
  clickedBuildingPart: any,
  totalExclusiveAreas: string,//number,
  totalCoreArea: string,
  totalServiceArea: string,
  houseNumber: number,
  totalCommonWallArea: string,
  buildingArea: string,
  groundArea: string,
  errorLogs: any[];
  currentLog: string;
  showBlockInfo: boolean,
  is2D: boolean;
  hierarchyMode: ConverterType;
  openModal: boolean;
  modalOptions: ModalOptions,
  settingModalType: ConverterType,
  colorInfoType: {
    concrete: Polygon[],
    unit: Polygon[],
    core: Polygon[],
    lightWindow: Polygon[],
    normalWindow: Polygon[],    
  },
  level: {
    minLevel: number,
    maxLevel: number
  },
  polygonVisible: {
    site: boolean,
    type: boolean,
  },
  cameraZoom: number,
  mapCenter: THREE.Vector3,
  canvasOffset: {
    width: number,
    height: number,
  },
  drawPolygonOnSaveModal: boolean,

  sceneTopPosition: THREE.Vector3,
  openSnack: boolean,
  snackMsg: React.ReactNode,

}

export class Scene extends Component<MyPlanProps, MyPlanState> {
  state: MyPlanState = {
    //    handle: 0,
    isCad: true,
    //    areaListHidden: false,
    //    layerListHidden: true,
    //  buildingListHidden: false,
    // houseListHidden: false,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    canvasAlpha: 50,
    address: '',
    fileName: '선택된 파일 없음',
    settingID: '',
    showBlock: false,
    saveName: '',
    exclusiveAverageArea: 0,
    siteArea: 0,
    roadArea: 0,
    vacancyOusideArea: 0,
    vacancyInsideArea: 0,
    totalBuildingArea: 0,
    totalGroundArea: 0,
    isSettingSite: true,
    listType: ListType.layer,
    houses: [],
    buildings: [],
    userID: 'test@1011.co.kr',
    showModal: false,
    autoset: false,
    isCadastral: true,
    loadFile: false,
    dragCover: true,
    showSettingModal: false,
    showSaveModal: false, // 배치안 저장 모달
    isSaved: false, // 저장완료
    loadingPage: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    infoSize: 0,
    clickedField: null,
    clickedBuildingPart: null,
    totalExclusiveAreas: '0',
    totalCoreArea: '0',
    totalServiceArea: '0',
    houseNumber: 0,
    totalCommonWallArea: '0',
    buildingArea: '0',
    groundArea: '0',
    errorLogs: [],
    currentLog: 'ALL',
    is2D: true,
    field: {
      site: [],
      road: [],
      roadCenterLine: [],
      vacancyInside: [],
      vacancyOutside: [],
      topography: [],
    },
    showBlockInfo: false,
    hierarchyMode: ConverterType.myPlane,
    openModal: false,
    modalOptions: {},
    settingModalType: ConverterType.myPlane,
    colorInfoType: {
      concrete: [],
      unit: [],
      core: [],
      lightWindow: [],
      normalWindow: [],    
    },
    level: {
      minLevel: 1,
      maxLevel: 10000
    },
    polygonVisible: {
      type: true,
      site: true,
    },
    cameraZoom: 1,
    mapCenter: new THREE.Vector3(0, 0, 0),
    canvasOffset: {
      width: 314,
      height: 279,
    },
    sceneTopPosition: new THREE.Vector3(0),
    drawPolygonOnSaveModal: false,
    openSnack: false,
    snackMsg: "",
  };

  mount: HTMLDivElement | null = null;

  mapManager = new DrawingManager2();
  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  Buildings = new Array<BuildingTypeData>();

  siteFields: Field[] = [];
  roadFields: Field[] = [];
  vacancyOutsideFields: Field[] = [];
  vacancyInsideFields: Field[] = [];

  parsingOutput: BlockParsingData = { buildings: [], fields: [], wrongBlocks: [], cadastralMap: [], wrongPolygons: [] }
  block: ConverterBlock[] = [];

  naverPolygon: any;
  naverMapProj: any;
  basePosition: any;

  DEM: DEMData[] = [];

  mapProj: mapProjectionData = { mapOffset: new THREE.Vector2(), projection: any };
  addType = LayerType.site;
  S3BucketName = 'teneleven-platform-my-building-plan-v2';
  DBTableName = 'platform-buildit-my-building-plan-v2';

  fileData: string = '';
  areaBias = 1;
  makedAreaBias = false;
  centerOfRoadWKT = '';

  settingData = new userSettingData(App.tempStage, App.session.email);
  errorLogs: ErrorLogCell2[][] = [];
  loadFileErrorLogs: ErrorLogCell2[] = [];
  globalErrorLogs: ErrorLogCell2[] = [];

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log('test'),
    title: '제목'
  }
  blockBs: Array<BuildingPart> = [];
  houses: Array<BuildingHouseUnit> = [];
  cores: Array<BuildingCoreUnit> = [];
  notComponentBlocks: Array<any> = []; // 컴포넌트 레벨에서  빌딩타입이 group인 블록

  errorList = new ErrorList();
  polyLabelList = new Map();//{x: number, y: number, dist: number} = [];

  SitePolygon: MapPolygon[] = [];
  boundarySite: MapPolygon[] = [];
  topographyLine: MapPolygon[] = [];
  vacancyInside: MapPolygon[] = [];
  vacancyOutside: MapPolygon[] = [];
  roadSite: MapPolygon[] = [];
  corePolygon: MapPolygon[] = [];
  housePolygon: MapPolygon[] = [];
  initialBound: any;
  needInitialCamera = false;

  
  animate = () => {

    if (this.sceneManager.orthoCamera.zoom !== this.state.cameraZoom) {
      this.setCameraZoom();
    }
    requestAnimationFrame(this.animate);
    this.sceneManager.render();

   
    this.updateTypeInfoPosition();

    this.state.field.topography.forEach(block => {
      this.updateFieldInfoPosition(block);
    })
  }

  updateFieldInfoPosition = (field: ConverterField) => {
    let s = document.querySelector(`.TT${field.uuid}`) as HTMLInputElement;

    if (s) {
      if (!this.state.showBlockInfo) {
        s.style.visibility = 'hidden';
        return;
      }
      if (this.state.showBlockInfo) {
        field.renderGroup.updateWorldMatrix(true, true);
        const matrixWorld = field.renderGroup.matrixWorld;

        field.parts.forEach(part => {

          const coords: jsts.geom.Coordinate[] = [];
          part.polygon.vertices.forEach(v => {
            const newV = v.clone().applyMatrix4(matrixWorld);
            coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
          })
          const geoFac = new jsts.geom.GeometryFactory();
          const linearRing = geoFac.createLinearRing(coords);

          //@ts-ignore
          const newPolygon = geoFac.createPolygon(linearRing, []).buffer(0);
          const interiorPoint = newPolygon.getInteriorPoint().getCoordinates();
          const center = new THREE.Vector3(interiorPoint[0].x, interiorPoint[0].y, 0);
          this.sceneManager.resizeCanvasBBox();
          const c = this.sceneManager.getScreenPosition(center);

          s.style.top = `${c.y}px`;
          s.style.left = `${c.x}px`;
          s.style.visibility = 'visible';

        })
      }
    }
  }

  
  constructor(props: MyPlanProps) {
    super(props);
    // this.changeSaveFileName = this.changeSaveFileName.bind(this);

  }
  
  setCameraZoom = () => _.debounce(() => {
    this.setState({ cameraZoom: this.sceneManager.orthoCamera.zoom });
  }, 300)();

  setPolyLabel = () => {
    const polyLabelArr = [];
    [...this.cores, ...this.houses].forEach((block: BuildingHouseUnit | BuildingCoreUnit) => {
      block.renderGroup.updateWorldMatrix(true, true);
      let matrixWorld = block.renderGroup.matrixWorld;
      let coords: jsts.geom.Coordinate[] = [];
      if (this.errorList.isCompleteById(block.uuid)) {

        for (let i = 0; i < block.polygon.length; i++) {
          const polygon = block.polygon[i];
          try {
            if (polygon.shape && polygon.area > 0 && polygon.vertices.length >= 4 &&
              polygon.vertices[0].x === polygon.vertices[polygon.vertices.length - 1].x
              && polygon.vertices[0].y === polygon.vertices[polygon.vertices.length - 1].y) {
              polygon.vertices.forEach(v => {
                let newV = v.clone().applyMatrix4(matrixWorld);
                coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
              });

              let geoFac = new jsts.geom.GeometryFactory();
              let linearRing = geoFac.createLinearRing(coords);

              //@ts-ignore
              let newPolygon = geoFac.createPolygon(linearRing, []).buffer(0);
              const polygonCoords = newPolygon.getCoordinates();
              const p = polylabel([polygonCoords.map((c: any) => [c.x, c.y])], 1.0);
              //@ts-ignore
              const dist = p.distance;
              let center = new THREE.Vector3(p[0], p[1]);

              this.polyLabelList.set(block.uuid, {x:p[0], y: p[1], dist});
  //            polyLabelArr.push({x: p[0], y: p[1], dist, id: block.uuid});
            }
          }
          catch { }
        }
      }
    });

    //@ts-ignore
    this.polyLabelList = new Map([...this.polyLabelList.entries()].sort((a, b) => b[1].dist - a[1].dist));
   // this.polyLabelList.sort((a, b) => b.dist - a.dist);
   // console.log(this.polyLabelList, 'list');
    
  }

  updateTypeInfoPosition = () => {
    const components = [...this.houses, ...this.cores];
    // 표현 가능한지 먼저 체크

    if (!this.state.is2D || !this.state.showBlockInfo) {
      components.forEach(block => {
        const blockInfo = document.querySelector(`.TT${block.uuid}`) as HTMLDivElement;
        if (blockInfo) blockInfo.style.visibility = 'hidden';
        const infoIcon = document.querySelector(`.info${block.uuid}`) as HTMLDivElement;
        if (infoIcon) infoIcon.style.visibility = 'hidden';
      });
      return;
    }
    if (this.polyLabelList.size > 0) {
      
      const polyLabelArr = Array.from(this.polyLabelList);
      const { x, y, dist } = polyLabelArr[0][1];
      let screenPosition = this.sceneManager.getScreenPosition(new THREE.Vector3(x + dist, y + dist, 0));
      let c = this.sceneManager.getScreenPosition(new THREE.Vector3(x, y, 0));
      const elementWidth = Math.abs(c.x - screenPosition.x) * 1.5;

      if (elementWidth >= 148) {
        // 전체 표시        
        components.forEach(block => {
          const polyLabel = this.polyLabelList.get(block.uuid);
          if (polyLabel) {
            const { x, y } = polyLabel;
            let c = this.sceneManager.getScreenPosition(new THREE.Vector3(x, y, 0));
            const blockInfo = document.querySelector(`.TT${block.uuid}`) as HTMLDivElement;
            if (blockInfo) {
              blockInfo.style.top = `${c.y}px`;//c.y.toString() + 'px';
              blockInfo.style.left = `${c.x}px`;
              blockInfo.style.visibility = 'visible';
            }
            const infoIcon = document.querySelector(`.info${block.uuid}`) as HTMLDivElement;
            if (infoIcon) {
              infoIcon.style.visibility = 'hidden';
            }
          }
        });
      }
      else if (elementWidth >= 16) {
        // info 표시
        
        components.forEach(block => {
          const polyLabel = this.polyLabelList.get(block.uuid);
          if (polyLabel) {
            const { x, y } = polyLabel;
            let c = this.sceneManager.getScreenPosition(new THREE.Vector3(x, y, 0));
            const blockInfo = document.querySelector(`.TT${block.uuid}`) as HTMLDivElement;
            if (blockInfo) {
              blockInfo.style.visibility = 'visible'; 
              //@ts-ignore
              if (!blockInfo.mouseEnter) blockInfo.style.visibility = 'hidden';
              blockInfo.style.top = `${c.y}px`;
              blockInfo.style.left = `${c.x}px`;
              //   blockInfo.style.visibility = 'visible';  
            }
            const infoIcon = document.querySelector(`.info${block.uuid}`) as HTMLDivElement;
            if (infoIcon) {
              infoIcon.style.top = `${c.y}px`;
              infoIcon.style.left = `${c.x}px`;
              infoIcon.style.visibility = 'visible';
            }
          }
        });
      }
      else {
        // 표시 no
        components.forEach(block => {
          const polyLabel = this.polyLabelList.get(block.uuid);
          if (polyLabel) {
            const { x, y } = polyLabel;
            //            let c = this.sceneManager.getScreenPosition(new THREE.Vector3(x, y, 0));
            const blockInfo = document.querySelector(`.TT${block.uuid}`) as HTMLDivElement;
            if (blockInfo) {
              blockInfo.style.visibility = 'hidden';
            }
            const infoIcon = document.querySelector(`.info${block.uuid}`) as HTMLDivElement;
            if (infoIcon) {
              infoIcon.style.visibility = 'hidden';
            }
          }
        });
      }
    }
  }

  


  componentWillMount = () => {
    document.addEventListener("keydown", (e) => {
      if (this.state.loadFile && e.key === "Escape") {
        this.setState({ 
          clickedField: null,
          clickedBuildingPart: null 
        });
        setBlockOpacity(this.parsingOutput.fields, 1);
        setBlockOpacity(this.parsingOutput.buildings, 1);
      }
    });
  }


  componentDidMount = async () => {
    await this.settingData.getDataFromDB(); // 사용자 설정값 

    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.SceneInit();

    this.sceneManager.renderer.sortObjects = false;
    this.sceneManager.addObjectToScene(this.polygon2DGroup);


    this.mapManager.createMap({
      ref: this.refs.map,
      cadastral: true,
      zoom: 17,
//        center: [centerInLL.x, centerInLL.y],
      maxZoom: 21,
      transitionOptions: {
        duration: 0,
        easing: "linear",
      },
      tileSpare: 1,
    });

    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';


    this.calculateMapProjectionData();


    this.setState({
      userID: App.session.email,
    })
    this.animate();
    //    this.settingData.getDataFromDB();

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
   
    //@ts-ignore
    if (this.props.location.state && this.props.location.state.file && this.props.location.state.file[0]) {
      //TODO this.load
      //@ts-ignore
      await this.loadDXFFileOutside(this.props.location.state.file[0]);
      window.addEventListener('keydown', (e) => {
        if (e.key === "F5") { // 새로고침시 파일 새로 열게
          //@ts-ignore
          this.props.location.state = undefined;
          //@ts-ignore
          this.props.history.push('/cad/myPlaneblock');
        }
      })
    }
    this.setState({cameraZoom: this.sceneManager.orthoCamera.zoom});

    const center = this.mapManager.map!.nMap.getCenter();
    this.setState({
      mapCenter: this.sceneManager.orthoCamera.position,
    });
    
    this.recalculateArea = _.debounce(this.recalculateArea, 500);
  }

  componentDidUpdate = (previousProps: Readonly<MyPlanProps>, previousState: Readonly<MyPlanState>) => {

    
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      let aspect = width / height;

      let frustumSize = this.sceneManager.orthoCamera.right;
      if (this.state.canvasAlpha !== 100 && !this.state.isCad) {
        frustumSize = this.getFrustumSizeWithMapSize();
      }

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(width, height);
      this.mapManager.map!.nMap.autoResize();
    }
    
    if (!this.state.isCad && this.state.isCad !== previousState.isCad) {
      this.mapManager.map!.addListener('zoom_changed', () => { this.naverMapChanged(true) });
      this.mapManager.map!.addListener('dragend', () => { this.naverMapChanged(true); });
      this.mapManager.map!.addListener('idle', () => {
        const center = this.mapManager.map!.nMap.getCenter();
        const tmCenter = latlng2tm(new THREE.Vector2(center.x, center.y));
        this.setState({
          mapCenter: new THREE.Vector3(tmCenter.x, tmCenter.y, 0),
        });
      });
      this.mapManager.map!.addListener('size_changed', () => {
        if (!this.state.isCad && this.needInitialCamera) {
          this.naverMapChanged(true);
        }
        else {
          const center = new THREE.Vector3(0);
          const size = new THREE.Vector3(0);
          this.bbox.getCenter(center);
          this.bbox.getSize(size);
          let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
          if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
            const height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
            frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
          }
          const aspect = this.state.screenWidth / this.state.screenHeight;
          this.sceneManager.CameraFrustumResize(frustumSize, aspect);
          this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
          this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
          this.sceneManager.orthoCamera.zoom = 1;
        }
      });
    }


    if (previousState.isCad !== this.state.isCad || previousState.canvasAlpha !== this.state.canvasAlpha) {
      if (this.state.isCad) {
        this.sceneManager.setRendererAlpha(1);
      }
      else {
        this.sceneManager.setRendererAlpha(0);
      }
    }

    if (this.state.showBlock !== previousState.showBlock) {
      this.onWindowResize();
      this.naverMapChanged();

    }

    if (!this.state.isCad && this.state.isCad !== previousState.isCad) {
      this.naverMapChanged();
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }

    if (this.state.showBlockInfo && previousState.showBlockInfo !== this.state.showBlockInfo) {
      this.setState({ 
        openSnack: true,
        snackMsg: <div className="font font-emphasis msg font-pretendard"><span className="font font-special">마우스 휠로 줌 인</span> 하시면 블록 정보를 확인 가능합니다.</div>,
      });
    }

    
  }

  componentWillUnmount = () => {  
    
    if (this.sceneManager.canvasElement && this.mount) {
      this.mount!.removeChild(this.sceneManager.canvasElement);
    
    } 
  }

  loadDXFFileOutside = async (file: any) => {
    if (file) {
      this.loadFilebyFile([file]);
    }
  }

  onWindowResize = () => {        
    if (window.innerWidth >= 1200) {
      this.setState({
        screenWidth: window.innerWidth - 640 * (this.state.showBlock ? 1 : 0),
        screenHeight: window.innerHeight + (this.state.loadFile ? - 122 : 0) - (this.state.errorLog ? 286 : 30) + (!this.state.loadFile ? 30 : 0),
      });
    }
    this.mapManager.map!.nMap.autoResize();
    this.setMapOnRenderGroup();
    this.setState({
      canvasOffset: {
        width: this.sceneManager.canvasElement.offsetWidth,
        height: this.sceneManager.canvasElement.offsetHeight,
      }
    });
    
  }

  setMapOnRenderGroup = () => {
    let centerX = (this.bbox.max.x + this.bbox.min.x) / 2;
    let centerY = (this.bbox.max.y + this.bbox.min.y) / 2;
    const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
    this.mapManager.map!.setCenter([centerCoord.x, centerCoord.y]);
  }

  dataInitialize = () => {    
    Object.values(this.parsingOutput).forEach(value => {
      while (value.length > 0) {
        value.splice(0, 1);
      }
    })
    this.polygon2DGroup.children = [];
    this.bbox = new THREE.Box3();
    this.mapManager.clearDrawingManager();

    while (this.blockBs.length > 0) {
      this.blockBs.splice(0, 1);
    }
    while (this.cores.length > 0) {
      this.cores.splice(0, 1);
    }
    while (this.houses.length > 0) {
      this.houses.splice(0, 1);
    }    
    this.setState({
      showBlock: false,
      clickedField: null,
      clickedBuildingPart: null,
      loadFile: false,
      isSaved: false,
      currentLog: "ALL",
      errorLog: false,
      errorSize: 0,
      warningSize: 0,
      infoSize: 0,
      isCad: true,
      field: {
        site: [],
        road: [],
        roadCenterLine: [],
        vacancyInside: [],
        vacancyOutside: [],
        topography: [],
      },
      colorInfoType: {
        concrete: [],
        unit: [],
        core: [],
        lightWindow: [],
        normalWindow: [],    
      },
      polygonVisible: {
        site: true,
        type: true,
      },
      showBlockInfo: false,     
      hierarchyMode: ConverterType.myPlane,
      drawPolygonOnSaveModal: false,
    })
  
    this.SitePolygon.map((p) => p !== null && p.remove());
    this.roadSite.map((p) => p !== null && p.remove());
    this.topographyLine.map((p) => p !== null && p.remove());
    this.boundarySite.map((p) => p !== null && p.remove());
    this.vacancyInside.map((p) => p !== null && p.remove());
    this.vacancyOutside.map((p) => p !== null && p.remove());    
    this.corePolygon.map((p) => p !== null && p.remove());
    this.housePolygon.map((p) => p !== null && p.remove());

    this.SitePolygon = [];
    this.roadSite = [];
    this.topographyLine = [];
    this.vacancyOutside = [];
    this.vacancyInside = [];
    this.boundarySite = [];
    this.notComponentBlocks = [];
    
    this.errorList.resetError();
    this.canvasModeChanged(true);
    this.mapManager.map!.nMap.setZoom(17);
  }


  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
//        this.saveBlockData();
        break;
      case 'b':
        this.parsingOutput.buildings.forEach(b => {
          App.stage !== "prod" && console.log(makeBlockBuildingData(b));
        })
        break;
      default:
        break;
    }
  }

  onKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      default:
        break;
    }
  }

  loadFilebyFile = async (file: any) => {
    this.dataInitialize();
    if (!file[0].name.endsWith('.dxf')) {
      this.setState({ loadingPage: false });
      this.setState({
        openModal: true,
        modalOptions: {
          title: "파일 업로드 실패",
          content: <div>dxf 파일을 올려주세요.</div>,
          negative: 'hidden',
          positive: () => { this.setState({ openModal: false}); },
        },
      })
      return;
    }

    this.setState({ loadingPage: true });
    // if (!file[0].name.endsWith('.dxf')) {
    //   return;
    // }

    let data = await asyncFileRead(file);
    if (!data)
      return;
    this.fileData = data;
    //    this.polygon2DGroup.children = [];


    //    let layer = dataParsing(data);
    let parsingError: { errorNameLayers: Array<any>, pointError: Array<any> } = {
      errorNameLayers: [],
      pointError: []
    };

    let errorLayerList = { errorNameLayers: [], pointError: [] };
    const layerOfPoint = new Set();
    this.block = blockParsing(data, this.parsingOutput, ConverterType.myPlane, layerOfPoint);
    if (layerOfPoint.size) {
      this.errorList.addError(new TypeError(
        {
          title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
          msg: `${Array.from(layerOfPoint).join('/')}의 불필요한 Point 가 자동 삭제 되었습니다.`,
          id: [],
          type: ErrorType.Info,
        }));
    }



    this.setGroupByColor();
    setBlockNameNumber(this.parsingOutput.buildings);

   
    this.parsingOutput.fields.forEach(f => {
      if (f.typeName === FieldType.site) {
        f.parts.forEach(p => {
          if (!p.unused) {
            const polygon = p.getJSTSPolygon(f.renderGroup.matrixWorld);
            const coords = polygon.getCoordinates();
            //@ts-ignore
            if (coords[0] !== coords[coords.length - 1] && polygon._geometries && polygon._geometries.length > 1) {
              this.errorList.addError(new SiteError({
                type: ErrorType.Error,
                title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
                msg: `${f.name}의 폭이 충분하지 않습니다. 캐드에서 재설정 후 다시 진행하세요.`,
                id: [f.uuid],
                targetFields: [f],
              }))
            }
          }
        })
      }
    })


    // //! START
    { //* 블럭 구조 체크
      // 블럭 구조 B, D가 없는 경우 & blockBs 값 셋팅
      this.parsingOutput.buildings.forEach(blockD => {

        blockD.parts.forEach(blockB => {
          this.blockBs.push(blockB);

          blockB.parts.forEach((component) => {
            //@ts-ignore
            if (component !== undefined && component.componentType === "core") {
              this.cores.push(component as BuildingCoreUnit);
            }
            //@ts-ignore
            else if (component !== undefined && component.componentType === "house") {
              this.houses.push(component as BuildingHouseUnit);
            }
            else {
              this.notComponentBlocks.push(component);
            }
          })
        })
      })

      this.parsingOutput.fields.forEach(f => {
        switch (f.typeName) {
          case FieldType.site:
            this.state.field.site.push(f);
            break;
          case FieldType.road:
            this.state.field.road.push(f);
            break;
          case FieldType.centerLineOfRoad:
            this.state.field.roadCenterLine.push(f);
            break;
          case FieldType.vacancyInside:
            this.state.field.vacancyInside.push(f);
            break;
          case FieldType.vacancyOutside:
            this.state.field.vacancyOutside.push(f);
            break;
          case FieldType.topography:
            this.state.field.topography.push(f);
            break;
        }
      })

      const { site, road, roadCenterLine } = this.state.field;
      
      
      const isTypeStructOK = checkBlockStruct(this.parsingOutput.buildings, this.errorList, this.parsingOutput.wrongPolygons, ConverterType.myPlane);
      const isSiteStructOK = checkSiteStructure({ sites: site, roads: road, roadCenterLines: roadCenterLine }, this.parsingOutput.fields, this.errorList);
    
      const isConnected = checkConnectionComponent({
        wrongPolygons: this.parsingOutput.wrongPolygons,
        buildings: this.parsingOutput.buildings,
        blockBs: this.blockBs,
        cores: this.cores,
        houses: this.houses,
      }, this.errorList);
      

      //* 사어여역 에러체크

      if (isTypeStructOK && isSiteStructOK && isConnected) {
        checkSiteError(this.parsingOutput, ConverterType.mySite, this.errorList);

        checkBlockName(this.parsingOutput.buildings, this.notComponentBlocks, this.errorList);

        // 전용면적만 들어온경우
        this.block.forEach(block => {
          let splitedName = block.name.toUpperCase().split('_');
          if (block.type === "house" && (/^[U](\d+)[_](\d+)$/i.test(block.name) || /^[U]\d[_](\d*)[.](\d*)$/i.test(block.name))) { // 'u_정수', 'u_소수'의 경우
            this.errorList.addError(new TypeError({
              title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
              msg: `${block.name}의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
              type: ErrorType.Info,
              id: this.houses.filter(h => h.name === block.name).map(h => h.uuid),
              components: this.houses.filter(h => h.name === block.name)
            }));
            
            // this.loadFileErrorLogs.push(makeInfoInformation(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
            //   `${block.name}의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
            //   new THREE.Group(), [], {
            //   components: this.houses.filter(h => h.name === block.name)
            // }
            // ));
          }
        })

        // 레이어 이름 체크
        checkLayerNameError([...this.houses, ...this.cores, ...this.parsingOutput.buildings, ...this.blockBs], this.errorList)

        // 유효 레이어에 존재하는 점: 알람
        errorLayerList.pointError.forEach(layerName => {
          this.errorList.addError(new TypeError({
            title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
            msg:  `${layerName} 의 불필요한 Point 가 자동 삭제 되었습니다. `,
            type: ErrorType.Info,
            id: [],
            components: [],
          }));
          // this.loadFileErrorLogs.push(makeInfoInformation('[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
          //   `${layerName} 의 불필요한 Point 가 자동 삭제 되었습니다. `, new THREE.Group()))
        })
        // this.setState({
        //   blocks: this.block
        // })

       
        checkTypeError(this.loadFileErrorLogs,
          {
            buildings: this.parsingOutput.buildings,
            blockBs: this.blockBs,
            cores: this.cores,
            houses: this.houses,
          }, this.settingData.dataUnit, this.errorList);

        // 완료된 컴포넌트에 면 표현
        {
          this.cores.forEach(core => {
            core.CheckCompleteness(this.errorList);
            if (this.errorList.isCompleteById(core.uuid)) {
              core.polygon.forEach(poly => { if (poly.layer.toUpperCase() === "CON") poly.innerMesh.visible = true; })
            }
          });
          this.houses.forEach(house => {
            house.getHouseError(this.errorList);
            house.CheckCompleteness(this.errorList);
            if (this.errorList.isCompleteById(house.uuid)) {
              house.polygon.forEach(poly => { if (poly.layer.toUpperCase() === "CON") poly.innerMesh.visible = true; })
            }
          });
    }
      }
    }

    [...this.cores, ...this.houses, ...this.blockBs, ...this.parsingOutput.buildings].forEach(block => {
      this.polygon2DGroup.add(block.ErrorPolygonGroup);
    })
    this.parsingOutput.fields.forEach(field => this.polygon2DGroup.add(field.ErrorPolygonGroup));
    this.parsingOutput.wrongPolygons.forEach(poly => {
      if (this.parsingOutput.fields.length) 
        this.parsingOutput.fields[0].renderGroup.add(poly.lineMesh);
      else if (this.parsingOutput.buildings.length) 
        this.parsingOutput.buildings[0].renderGroup.add(poly.lineMesh);
      else
        this.polygon2DGroup.add(poly.lineMesh);
    });

    this.recalculateArea();
    this.setPolyLabel();






    this.setState({
      warningSize: this.errorList.getWarningSize(),
      errorSize: this.errorList.getErrorSize(),
      infoSize: this.errorList.getInfoSize(),
    })

    if (this.state.errorSize > 0 || this.state.warningSize > 0 || this.state.infoSize > 0) {
      this.setState({ errorLog : true});
    }
    // ////////////////////////

    this.polygon2DGroup.children = [];
    this.siteFields = [];
    this.roadFields = [];
    this.vacancyInsideFields = [];
    this.vacancyOutsideFields = [];
    this.Buildings = [];
    this.deleteNaverPolygon();

    App.stage !== "prod" && console.log(this.parsingOutput);
    this.parsingOutput.buildings.forEach(b => {   
      this.polygon2DGroup.add(b.renderGroup);
    })
    this.parsingOutput.fields.forEach(field => {
      this.polygon2DGroup.add(field.ErrorPolygonGroup);
    })



    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        p.RebuildOutputPolygon();
        p.UpdateArea();
      })
    })

    this.parsingOutput.fields.forEach(f => {
      this.polygon2DGroup.add(f.renderGroup);
    })

    this.bbox.makeEmpty();
          
    (this.parsingOutput.wrongBlocks as ConverterField[]).forEach(field => {
      this.polygon2DGroup.add(field.renderGroup);
    }) 
    
    this.state.field.site.length ? this.bbox.setFromObject(this.state.field.site[0].renderGroup) : this.bbox.setFromObject(this.polygon2DGroup);

    let center = new THREE.Vector3();
    this.bbox.getCenter(center);

    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
      let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
      frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
    }

    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);
    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.initialBound = new THREE.Vector3().set(-1, 1, -1).unproject(this.sceneManager.orthoCamera).x,

    
    this.setState({
      // layers: layer,
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadingPage: false,
      loadFile: true,
      showBlock: true,

    }, async () => {
      this.onWindowResize();
      // if (this.settingData.autoSetting) {
      //   this.autoSetPlan();
      // }
      let sitePolygon: any;
      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {

          const splited = this.state.field.site[0].name.split('_');
          let area = 0;
          sitePolygon = f.getUnionJstsPolygon();

          if (splited.length === 2 && !isNaN(Number(splited[1]))) area = Number(Number(splited[1]).toFixed(2));
          else if (splited.length === 1) area = Number(sitePolygon.getArea().toFixed(2));
  
          // if (sitePolygon) {
          //   sitePolygon.buffer(0);
            this.setState({
              siteArea: area,
            })
          // }
        }
      });
      if (this.state.field.site.length > 0)
      await this.AutoAdd2Map();
    })
    this.setState({
//      clickedField: this.state.field.site.length > 0 ? this.state.field.site[0] : null,
      errorLogs: this.errorList.getError(),
    })

    
}

  setClickedBlock = (block: any, type: ConverterType.mySite | ConverterType.myType) => {
    if (type === ConverterType.mySite) {
      this.setState({
        clickedBuildingPart: null,
        clickedField: block
      })  
    }
    else {
      this.setState({
        clickedBuildingPart: block,
        clickedField: null
      })
    }
  }

  AutoAdd2Map = async () => {
    // console.log(getBuildingData());


    if (this.parsingOutput.fields.length > 0) {
      let sitePolygon = this.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      let unionPolygon = this.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      // let center = new THREE.Vector2(0);
      let RoadLatlngs: any[] = [];
      let SiteLatlngs: any[] = [];
      let centerRoadlatlngs: any[] = [];
      let outsideLatLngs: any[] = [];
      let insideLatLngs: any[] = [];
      let topographyLatLngs: any[] = [];

      const projectSiteWKT: any[] = [];
      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {
          this.state.field.site.push(f);
          f.renderGroup.updateWorldMatrix(true, true);
          f.parts.forEach(p => {
            if (!p.unused) {
              const polygon = p.getJSTSPolygon(f.renderGroup.matrixWorld);
              const coords = polygon.getCoordinates();
              //@ts-ignore
              if (coords[0] !== coords[coords.length - 1] && polygon._geometries && polygon._geometries.length > 1) {
                this.errorList.addError(new SiteError({
                  type: ErrorType.Error,
                  title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
                  msg: `${f.name}의 폭이 충분하지 않습니다. 캐드에서 재설정 후 다시 진행하세요.`,
                  id: [f.uuid],
                  targetFields: [f],
                }))
              }
              else if (polygon && polygon.getCoordinates().length > 0) {
                projectSiteWKT.push(jstsPolygontoWKT(polygon));
              }
              //              projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(f.renderGroup.matrixWorld)));
            }
          })

          //@ts-ignore
          sitePolygon = f.getUnionJstsPolygon();
          if (sitePolygon) {
            //@ts-ignore
            sitePolygon = sitePolygon.buffer(0);
            //@ts-ignore
            unionPolygon = f.getUnionJstsPolygon()!.buffer(0.1);
            const list = f.getLatLngList().filter(latlng => latlng.length >= 4);

            SiteLatlngs = SiteLatlngs.concat(list);
          };
        }
        else if (f.typeName === FieldType.road && !f.unused) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          RoadLatlngs = RoadLatlngs.concat(list);
        }
        else if (f.typeName === FieldType.vacancyInside && !f.unused) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          insideLatLngs = insideLatLngs.concat(list);
        }
        else if (f.typeName === FieldType.vacancyOutside && !f.unused) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          outsideLatLngs = outsideLatLngs.concat(list);
        }
        else if (f.typeName === FieldType.centerLineOfRoad && !f.unused) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          centerRoadlatlngs = centerRoadlatlngs.concat(list);
          f.parts.forEach(p => {
            this.centerOfRoadWKT = jstsPolygontoWKT(p.getJSTSPolygon(matrix));
          })
        }
        else if (f.typeName === FieldType.topography) {
          const list = f.getLatLngList().filter(latlng => latlng.length >= 4);
          topographyLatLngs = topographyLatLngs.concat(list);
        }
      })
      // this.parsingOutput.fields.forEach(f => {
      //   //@ts-ignore
      //   if (f.getUnionJstsPolygon()){
      //     //@ts-ignore
      //     unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
      //   }
      // })

      if (projectSiteWKT.length > 0)
      try {
        this.setState({ address: await getAddressByProjectSite(projectSiteWKT) });        
      }
      catch {
        App.stage !== "prod" && console.log('get address error')
      }
      console.log(insideLatLngs, 'ffffffffffffff');
      
      //! error 일때 점선
      if (SiteLatlngs.length) {
        const naverSitePolygon = this.mapManager.addPolygon(SiteLatlngs, DrawingManager2.DrawingOption.PROJECT_SITE, false);
        this.SitePolygon.push(naverSitePolygon);  
      }
      if (RoadLatlngs.length) {
        const naverRoadPolygon = this.mapManager.addPolygon(RoadLatlngs, DrawingManager2.DrawingOption.ROAD_SITE, false);
        this.roadSite.push(naverRoadPolygon);
      }
      if (insideLatLngs.length) {
        const naverVacancyInside = this.mapManager.addPolygon(insideLatLngs, DrawingManager2.DrawingOption.VACANCY_INSIDE, false);
        this.vacancyInside.push(naverVacancyInside);
      }
      if (outsideLatLngs.length) {
        const naverVacancyOutside = this.mapManager.addPolygon(outsideLatLngs, DrawingManager2.DrawingOption.VACANCY_OUTSIDE, false);
        this.vacancyOutside.push(naverVacancyOutside);
      }
      if (topographyLatLngs.length) {
        
        const naverTopology = this.mapManager.addPolygon(topographyLatLngs, DrawingManager2.DrawingOption.TOPOGRAPHY_LINE, false);
        this.topographyLine.push(naverTopology);

      }
      

      const buildingData: buildingData[] = [];
      this.parsingOutput.buildings.forEach(b => {
        buildingData.push(makeBlockBuildingData(b));
      })
      
      buildingData.forEach(data => {
        data.position;
        if (data.position.x !== Infinity && data.position.y !== Infinity && data.position.z !== Infinity) {
          data.building.outline.forEach(outline => {
            const node = outline.node;
            const vertices = node.data.map((v) => [data.position.x + v.x, data.position.y + v.y]);
            const path: number[][][] = [[]];
            vertices.forEach(v => {
              const { x, y } = tm2latlng(new THREE.Vector2(v[0], v[1]));
              path[0].push([x, y]);
            });
            if (path[0].length > 0) {
              if (outline.category === "PCT_CORE") {
                const mapCore = this.mapManager.addPolygon(path, { ...DrawingManager2.DrawingOption.CAD_CORE, simplify: false });
                this.corePolygon.push(mapCore);
              }
              else if (outline.category === "PCT_HOUSE") {
                const mapHouse = this.mapManager.addPolygon(path, { ...DrawingManager2.DrawingOption.CAD_HOUSE, simplify: false });
                this.housePolygon.push(mapHouse);
              }
            }
          })
        }
      })

      this.parsingOutput.fields.forEach(f => {
        if (f.typeName !== FieldType.vacancyInside && f.typeName !== FieldType.topography && f.getUnionJstsPolygon()) {
          const polygon = f.getUnionJstsPolygon();
          if (unionPolygon && polygon) {
            //@ts-ignore
            unionPolygon = unionPolygon.union(polygon.buffer(0.1));
          }
        }
      })

      // let centerInLL = tm2latlng(center);
      // this.mapManager.setMapCenter(centerInLL.x, centerInLL.y);

      // const envelope = unionPolygon.getEnvelopeInternal();
      // let centerX = (envelope.getMinX() + envelope.getMaxX()) / 2;
      // let centerY = (envelope.getMinY() + envelope.getMaxY()) / 2;


      // const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
      // this.mapManager.setMapCenter(centerCoord.x, centerCoord.y);


      if (centerRoadlatlngs.length === 0) {
        try {
          this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
          centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
          //* test code
          //@ts-ignore
          let coordinates = wkx.Geometry.parse(this.centerOfRoadWKT).toGeoJSON().coordinates; // 두개
          let newC = coordinates.map((coords: any) => {
            return coords.map((coord: any) => {
              return tm2latlng(new THREE.Vector2(coord[0], coord[1]));
            })
          })
          const boundaryLine = this.mapManager.addPolygon(newC, DrawingManager2.DrawingOption.BOUNDARY_SITE, false);
          this.boundarySite.push(boundaryLine);
        }
        catch (e) {
          App.stage !== "prod" && console.log('auto add error', e)
        }

        // this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
        // centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
        // this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
        // centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
      }
      else {
        const boundaryLine = this.mapManager.addPolygon(centerRoadlatlngs, DrawingManager2.DrawingOption.BOUNDARY_SITE, false);
        this.boundarySite.push(boundaryLine);
      }
      let centerX = (this.bbox.max.x + this.bbox.min.x) / 2;
      let centerY = (this.bbox.max.y + this.bbox.min.y) / 2;

      const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
      this.mapManager.map!.nMap.setCenter(centerCoord.x, centerCoord.y);
      
      if (sitePolygon) {
        const splited = this.state.field.site[0].name.split('_');
        let area = 0;
        if (splited.length === 2 && !isNaN(Number(splited[1]))) area = Number(splited[1]);
        else if (splited.length === 1) area = Number(sitePolygon.getArea().toFixed(4));
        this.setState({
          siteArea: area,//Number(sitePolygon.getArea().toFixed(2)),
        })
      }
    }
  }
  setCenterOfRoad = (value: string) => {
    this.centerOfRoadWKT = value;
  }

  setLight = async (templates: { [key: string]: building }, buildingData: buildingData[]) => {
    const lambda = await new AWSModule("LAMBDA").connect();

    await Promise.all(
      Object.keys(templates).map(async (key) => {
        const result = await lambda.Lambda!.invoke({
          FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:teneleven-common-gridplan-post-latest`,
          Payload: JSON.stringify({ 'json': JSON.stringify(templates[key]) })
        }).promise();

        const payload = JSON.parse(result.Payload as string);
        if (payload.success) {
          templates[key] = JSON.parse(payload.json);
        }
        else {
          App.stage !== "prod" && console.log(payload.reason);
        }
      })
    );

    await Promise.all(
      buildingData.map(async (data, i) => {
        const result = await lambda.Lambda!.invoke({
          FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:teneleven-common-gridplan-post-latest`,
          Payload: JSON.stringify({ 'json': JSON.stringify(data.building) })
        }).promise();

        const payload = JSON.parse(result.Payload as string);
        if (payload.success) {
          buildingData[i].building = JSON.parse(payload.json);
        }
        else {
          App.stage !== "prod" && console.log(payload.reason);
        }
      })
    );
  }

  saveBlockData = async () => {
    try {
      let fn = this.state.saveName === '' ? 'my_building_plan' : this.state.saveName;
      //   this.showModal('알림', ['나의 배치안을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));
      let { globalId: globalID, userId: privateID } = await incrementCounter("my_building_plan_id", this.state.userID);

      let imageName = `img_large.png`;
      let shapeInfoName = 'shapeInfo.json';
      let resultLocationName = 'resultLocation.json';
      let resultJsonName = 'resultJson.json';
      let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;

      await this.captureImage(imageName, S3SavePath);

      this.calculateMapProjectionData();

      //shape infomation
      let projectSiteWKT: string[] = [];
      let roadSiteWKT: string[] = [];
      let vacancyInsideWKT: string[] = [];
      let vacancyOutsideWKT: string[] = [];
      let topographyLines: { polygon: string[], height: number }[] = [];
      let topographyLinesPlatform: { polygon: string[], height: number }[] = [];

      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site && !f.unused) {
          f.parts.forEach(p => {
            if (!p.unused)
              projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
        }
        else if (f.typeName === FieldType.road && !f.unused) {
          f.parts.forEach(p => {
            if (!p.unused)
              roadSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
        }
        else if (f.typeName === FieldType.vacancyInside && !f.unused) {
          f.parts.forEach(p => {
            if (!p.unused)
              vacancyInsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
        }
        else if (f.typeName === FieldType.vacancyOutside && !f.unused) {
          f.parts.forEach(p => {
            if (!p.unused)
              vacancyOutsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
        }
        else if (f.typeName === FieldType.topography && !f.unused) {
          let topographyWKT: string[] = [];

          f.parts.forEach(p => {
            if (!p.unused)
              topographyWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
          topographyLinesPlatform.push({polygon: [topographyWKT[0]], height: f.getHeight()});
          topographyLines.push({polygon: [...topographyWKT], height: f.getHeight()})
        }
      })
      let shapeInfo: shapeInfoData = {
        boundaryLine: polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment),
        projectSite: projectSiteWKT,
        projectSiteArea: this.state.siteArea,
        projectSiteRoad: [], //[''],
        road: roadSiteWKT,
        centerOfRoad: [this.centerOfRoadWKT],
        setbackLineApartment: [], // [''],
        setbackLineMultiHouse: [], //[''],
        setbackLineOfficetel: [], // [''],
        setbackLineRowHouse: [], //[''],
        skylineCircle: [], //[''],
        skylineLine: [], // [''],
        vacancyInsie: vacancyInsideWKT,
        vacancyOutsie: vacancyOutsideWKT,
        topographyLines: topographyLines,//topographyWKT,//[],
      }
      App.stage !== "prod" && console.log(shapeInfo);
      saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');
      saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

      // result location
      let buildingData: buildingData[] = [];
      let building_Stories_Avg = blockBuildingStoriesAvg(this.parsingOutput.buildings);// buildingStoriesAvg(this.Buildings);
      
      let floorAreaRatio = Number(((this.state.totalGroundArea / this.state.siteArea) * 100).toFixed(12));
      let coverAreaRatio = Number(((this.state.totalBuildingArea / this.state.siteArea) * 100).toFixed(12));
      let totalHousehold = getBlockTotalHouseHold(this.parsingOutput.buildings);
      let cost = Math.floor(this.state.siteArea * floorAreaRatio * 0.0001 * 1450167);
      let areaProportion = calculateBlockAreaProPortion(this.parsingOutput.buildings);

      let lMax = 0;
      let lMin = 10000;
      this.parsingOutput.buildings.forEach(b => {
        b.parts.forEach(p => {
          p.parts.forEach(pp => {
            if (pp.buildingType === 'component') {
              lMax = Math.max((pp as BuildingComponent).level.length, lMax);
              lMin = Math.min((pp as BuildingComponent).level.length, lMin);
            }
          })
        })
      })

      this.parsingOutput.buildings.forEach(b => {
        buildingData.push(makeBlockBuildingData(b));
      })

      let templates: { [key: string]: building } = {};
      let buildingIndex = 0;
      buildingData.forEach(b => {
        // b.position = this.sceneManager.getPointToMap(b.position, this.mapProj);
        b.name = `building${buildingIndex}`;
        b.building.name = `building${buildingIndex}`;
        templates[b.building.name] = b.building;
        buildingIndex++;
      });

      let resultLocation: resultLocation = {
        buildingNumber: this.parsingOutput.buildings.length,
        areaProportion: areaProportion,
        buildingStoriesAvg: [building_Stories_Avg.AREA, building_Stories_Avg.HOUSE, building_Stories_Avg.NUMERICAL],
        buildingStoriesMax: lMax,
        buildingStoriesMin: lMin,
        buildings: buildingData,
        constructionCost: cost,
        coverAreaRatio: coverAreaRatio,
        exclusionRatio: 0,
        floorAreaRatio: floorAreaRatio,
        templateList: templates,
        totalHousehold: totalHousehold,
        topographyLines: topographyLines,
        //TODO 성절토
      }
      App.stage !== "prod" && console.log(resultLocation);

      
      await this.setLight(templates, buildingData); // 채광벽 처리 반영 (isLight field 추가)
      saveDataToS3(JSON.stringify(resultLocation), S3SavePath, resultLocationName, 'application/json');

      //result --> dynamoDB
      let date = new Date().toISOString();
      let housingTypeRatio: housing_type_ratio[] = [];
    areaProportion.forEach(ap => {
        housingTypeRatio.push({
          area: ap.housingPlanTypeArea,
          bay: ap.numberOfBay,
          proportion: Math.floor(ap.housingPlanTypeProportion * 10000) / 100,
        })
      })

      let baseReportFilePath: base_report_file_path = {
        analysisReport: '',
        cad: '',
        constructionReport: '',
        exclusiveReport: '',
        lightReport: '',
        resImage: '',
        viewReport: '',
      }

      let baseResultFilePath: base_result_file_path = {
        light: '',
        location: '',
        view: ''
      }

      let resultJson: resultS3Json = {
        base_created_at: date,
        base_project_id: 0,
        base_report_file_path: baseReportFilePath,
        base_report_id: 0,
        base_result_status: 0,
        base_stage: 'dev',
        base_user_project_id: 0,
        base_uuid: '',
        base_result_file_path: baseResultFilePath,
        daylight_hours_avg: 0,
        daylight_hours_min: 0,
        daylight_hours_mode: 0,
        daylight_hours_proportion_less_n_hours: [100],
        loc_building_land_ratio: 0,
        loc_building_number: 0,
        loc_building_stores_avg: building_Stories_Avg,
        loc_building_stories_max: lMax,
        loc_building_stories_min: lMin,
        loc_construction_cost: cost,
        loc_exclusive_ratio_avg: 0,
        loc_floor_area_ratio: floorAreaRatio,
        loc_housing_type_ratio: housingTypeRatio,
        loc_my_building_type_ratio: [],
        loc_total_household: totalHousehold,
        view_point_avg: 0,
        view_point_skew: 0,
        view_skyarea_avg: 0,
        view_skyarea_min: 0,
        view_skyarea_mode: 0,
        view_skyarea_proportion_less_n_percent: [100],
      }
      App.stage !== "prod" && console.log(resultJson);
      saveDataToS3(JSON.stringify(resultJson), S3SavePath, resultJsonName, 'application/json');
      let uuid = uuid4();
      let buildingTemplateName = `${uuid}.json`;
      // let bufferSitePolygon = polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment);

      const exclusiveAreaList = this.houses.map(h => { return { name: h.name, area: h.exclusiveArea } });

      let dbItem: buildingPlanStruct = {
        stage: App.tempStage,//App.stage,
        global_id: globalID,
        user_id: privateID,
        email: this.state.userID,
        name: fn,
        shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
        resultJson: `s3://${S3SavePath}/${resultJsonName}`,
        resultLocation: `s3://${S3SavePath}/${resultLocationName}`,
        img_path: `s3://${S3SavePath}/${imageName}`,
        address: await getAddressByProjectSite(projectSiteWKT),
        project_site_area: this.state.siteArea,
        project_site: projectSiteWKT,
        road_site_area: this.state.roadArea,//0,//getFieldsArea(this.roadFields),
        road_site: roadSiteWKT,
        vacancy_outside_area: this.state.vacancyOusideArea,//0,//getFieldsArea(this.vacancyOutsideFields),
        vacancy_outside: vacancyOutsideWKT,
        vacancy_inside_area: this.state.vacancyInsideArea,//0,//getFieldsArea(this.vacancyInsideFields),
        vacancy_inside: vacancyInsideWKT,
        building_number: this.parsingOutput.buildings.length,// Buildings.length,
        total_building_area: this.state.totalBuildingArea,
        total_floor_area: this.state.totalGroundArea,
        average_exclusive_area: this.state.exclusiveAverageArea,
        cover_area_ratio: coverAreaRatio,
        floor_area_ratio: floorAreaRatio,
        created_at: date,
        modified_at: date,
        deleted: false,
        centerOfRoad: [this.centerOfRoadWKT],
        topography_lines: topographyLinesPlatform,
        //todo
        loc_total_household: totalHousehold,
        loc_building_stories_max: lMax,
        loc_building_stories_min: lMin, 
        exclusive_area_list: exclusiveAreaList,
        // total_exclusive_area: Number(this.state.totalExclusiveAreas),
        // total_service_area: Number(this.state.totalServiceArea),
        // core_area: Number(this.state.totalCoreArea),
        // building_area: Number(this.state.totalBuildingArea), // buildingarea
        // floor_area: Number(this.state.totalGroundArea), // groundarea
        // common_wall_area: Number(this.state.totalCommonWallArea), // 
        // houses_number: this.state.houseNumber,

        // file_path: `s3://${S3SavePath}/${buildingTemplateName}`,
        // meta_path: `s3://${S3SavePath}`,
      };

      App.stage !== "prod" && console.log(dbItem);
      saveDataToDynamoDB(dbItem, this.DBTableName);
      this.setState({ isSaved: true });
      // this.showModal('알림', ['나의 배치안을 저장했습니다.'], buttonNum.oneButton, () => {App.stage !== "prod" && console.log('save done')});
      this.setState({
        openModal: true,
        modalOptions: {
          title: "나의 배치안 저장 완료",
          content: <div>'{fn}'의 저장이 완료되었습니다.</div>,
          positive: () => {
            window.location.replace("/cad/myPlaneBlock");
          },
          negative: () => {
            window.location.href = "/myPage/file/myPlan";
          },
          negativeTitle: "나의 배치안 바로가기",
        }
      })
  
      return true;
    }
    catch (e) {
      // this.showModal('알림', ['나의 배치안을 저장에 실패 했습니다.'], buttonNum.oneButton, () => {
      //   App.stage !== "prod" && console.log('save fail', e);
      // })
      return false;
    }
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.needInitialCamera = false;
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }

  addNewField = (type: FieldType) => {
    let uuid = uuid4();
    switch (type) {
      case FieldType.site:
        if (this.siteFields.length > 0) {
          this.showModal('알림', ['사업영역은 하나만 허용합니다.'], buttonNum.oneButton, () => { App.stage !== "prod" && console.log('enable only one site layer')});
          break;
        }
        else {
          this.siteFields.push(new Field(`대지영역 ${this.siteFields.length}`, FieldType.site))
        }
        break;
      case FieldType.road:
        this.roadFields.push(new Field(`도로영역 ${this.roadFields.length}`, FieldType.road))
        break;
      case FieldType.vacancyOutside:
        this.vacancyOutsideFields.push(new Field(`공지영역 ${this.vacancyOutsideFields.length}`, FieldType.vacancyOutside))
        break;
      case FieldType.vacancyInside:
        this.vacancyInsideFields.push(new Field(`배치제한영역 ${this.vacancyInsideFields.length}`, FieldType.vacancyOutside));
        break;
      default:
        break;
    }

    this.setState({
      settingID: uuid,
      showBlock: true, //false,
    })

    this.recalculateArea();
  }

  // addBuildings = (name: string = `동평면 ${this.Buildings.length + 1}`, hideList = false) => {
  //   this.Buildings.push(MakeANewBuilding(name, hideList));
  //   this.setState({ buildings: this.Buildings });
  // }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;

      default:
        break;
    }
  }

  getFrustumSizeWithMapSize = () => {
    let rect = this.mount!.getBoundingClientRect();
    let p1 = this.mapProj.projection.fromPageXYToCoord(NaverPoint(0, rect.top));
    let p2 = this.mapProj.projection.fromPageXYToCoord(NaverPoint(this.state.screenWidth, rect.top));
    return this.mapProj.projection.getDistance(p1, p2) / 2;
  }

  resizeCanvasResolution = () => {
    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    let width = this.getFrustumSizeWithMapSize();
    width = this.areaBias !== 1 ? width / this.areaBias : width;
    this.sceneManager.CameraFrustumResize(this.getFrustumSizeWithMapSize(), aspect);
  }

  naverMapChanged = (fitMap: boolean = false) => {
    this.areaBias = 1;
    this.makedAreaBias = false;
    this.mapManager.map!.nMap.autoResize();
    fitMap && this.resizeCanvasResolution();
    this.fitPolygonToMap();

    this.setState({
      sceneTopPosition: new THREE.Vector3().set(-1, 1, -1).unproject(this.sceneManager.orthoCamera),
    })

  }

  fitPolygonToMap = () => {
    const { x, y } = this.mapManager.map!.nMap.getCenter();
    const center = latlng2tm(new THREE.Vector2(x, y));
    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
  }

  canvasModeChanged = (_isCAD: boolean) => {
    if (_isCAD) {
      const center = new THREE.Vector3(0)
      this.bbox.getCenter(center);
      let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
      if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
        let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
        frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
      }
  
      let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
      this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
      this.sceneManager.orthoCamera.zoom = 1;

      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
    }
    else {
      this.needInitialCamera = true;
      // this.setState({
      //   cameraZoom: this.sceneManager.orthoCamera.zoom,
      // })
      this.mapManager.map!.nMap.autoResize();
      this.sceneManager.getControl().enableKeys = false;
      this.sceneManager.canvasElement.style.pointerEvents = 'none';
      (this.refs.map as HTMLDivElement).style.visibility = 'visible';
   //!   this.mapManager.createDrawingManager();
      this.resizeCanvasResolution();
    }

    this.setState({
      isCad: _isCAD,
    });
  }

  mapTypeChanged = (isCadastral: boolean) => {
//    this.mapManager.changeMapType(isCadastral);
    this.mapManager.setCadastralLayer(isCadastral);
    this.setState({
      isCadastral: isCadastral
    })
  }

  recalculateArea = () => {
    
    let totalBuildingArea = 0;
    let totalGroundArea = 0;
    let exclusiveAverageArea = 0;

    let totalExclusiveAreas = 0;

    let totalServiceAreas = 0;
    let totalCommonWallAreas = 0;
    let totalCoreAreas = 0;
    let houseNumber = 0;

    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.pop();
    }

    let siteArea = 0;
    let roadArea = 0;
    let vacancyInsideArea = 0;
    let vacancyOusideArea = 0;


    this.parsingOutput.fields.forEach(f => {

      switch (f.typeName) {

        case FieldType.site:
          this.errorList.delAreaError(f.uuid);

          siteArea += f.getArea();

          let splited = f.name.split('_');
          if (splited.length >= 2 && !isNaN(Number(splited[1]))) {
            let inputArea = Number(Number(splited[1]).toFixed(4));

            if (Number(f.calcArea.toFixed(4)) !== Number(f.getArea().toFixed(4))) { // .toFixed(2)
              if (f.parts.length > 0 && f.parts[0].shape && f.parts[0].polygon && !f.parts[0].polygon.hasCurve) {
                const error = new SiteError(
                  {
                    title: `[면적 경고] 저장 시 입력 면적으로 반영됩니다.`,
                    msg: `${f.name}의 사용자가 입력한 면적과 실제 폴리곤 면적이 서로 상이합니다.
                폴리곤 전체 입력 면적: ${f.getArea().toFixed(4)}㎡, 계산 면적: ${f.calcArea.toFixed(4)}㎡, 차이: ${Math.abs(Number(f.getArea().toFixed(4)) - Number(f.calcArea.toFixed(4))).toFixed(4)}㎡`,
                    id: [f.uuid],
                    type: ErrorType.Warning,
                    targetFields: [f],
                  });
                this.errorList.addError(error);
              }
            }
          }
          break;
        case FieldType.road:
          roadArea += f.getArea();
          break;
        case FieldType.vacancyInside:
          vacancyInsideArea += f.getArea();
          break;
        case FieldType.vacancyOutside:
          vacancyOusideArea += f.getArea();
          break;

        default:
          break;
      }
    })
    
    // let error = this.state.errorSize, warning = this.state.warningSize, info = this.state.infoSize;
    // this.errorLogs.forEach(els => {
    //   els.forEach(el => {
    //     console.log(el)

    //     if (el.Type === ErrorType.Error) error++;
    //     if (el.Type === ErrorType.Warning) warning++;
    //     if (el.Type === ErrorType.Info) info++;
    //   })
    // })
    // console.log(error, warning, info)

    this.setState({
      siteArea: siteArea,
      roadArea: roadArea,
      vacancyInsideArea: vacancyInsideArea,
      vacancyOusideArea: vacancyOusideArea,
      errorLogs: this.errorList.getError(),
      // errorSize: error,
      // warningSize: warning,
      // errorLog: error + warning + info > 0 ? true : false,
    })


    // 건축 면적

    this.houses.forEach(h => {
      totalBuildingArea += h.exclusiveArea + h.serviceArea;
    })
    this.cores.forEach(c => {
      totalBuildingArea += c.GetArea();
    })

    // 바닥 면적
    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        p.UpdateArea();        
        totalExclusiveAreas += p.totalExclusiveAreas;
        totalServiceAreas += p.totalServiceAreas;
        totalCommonWallAreas += p.totalCommonWallAreas;
        totalCoreAreas += p.totalCoreAreas;
        houseNumber += p.houseNumber;
    
      })
    })



    // this.Buildings.forEach(b => {
    //   b.cores.forEach(c => {
    //     let l = 1;
    //     let p = 100;
    //     c.houses.forEach(h => {
    //       totalGroundArea += h.exclusiveArea * (h.level.length - h.piloti);
    //       l = Math.max(l, h.level.length);
    //       p = Math.min(p, h.piloti);
    //     })
    //     totalGroundArea += c.area * (l - p);

    //     if (p > 0)
    //       totalGroundArea += c.area;
    //   })
    // })
    let minLevel = this.state.field.topography.length ? 10000 : 1;
    let maxLevel = 1;
    this.houses.forEach((h: BuildingHouseUnit) => {    
      minLevel = Math.min(minLevel, h.level.length);
      maxLevel = Math.max(maxLevel, h.level.length);
    })

    const error = this.errorList.getErrorSize();
    const warning = this.errorList.getWarningSize();
    const info = this.errorList.getInfoSize();

    [...this.cores, ...this.houses].forEach(block => {
      block.checkAreaError(this.errorList);
    })
    this.setState({
      errorSize: error,
      warningSize: warning,
      infoSize: info,
      //   siteArea: getFieldsArea(this.siteFields),
      totalExclusiveAreas: totalExclusiveAreas.toFixed(4),
      totalCoreArea: totalCoreAreas.toFixed(4),
      totalBuildingArea: totalBuildingArea,
      totalGroundArea: totalExclusiveAreas + totalCoreAreas + totalCommonWallAreas,//totalGroundArea,
      exclusiveAverageArea: Number((totalExclusiveAreas / houseNumber).toFixed(4)),//Number(exclusiveAverageArea.toFixed(2)),
      level: {
        minLevel,
        maxLevel
      },
      errorLogs: this.errorList.getError(),
      houseNumber,
    })

  
  }

 

  captureImage = async (imageName: string, savePath: string) => {
    this.parsingOutput.buildings.forEach(building => {
      building.parts.forEach(blockB => {
        blockB.parts.forEach(part => {
          part.polygon.forEach((poly: Polygon) =>
            {
              poly.lineMesh.material.opacity = 1;
              //@ts-ignore
              poly.innerMesh.material.opacity = 0.5;
            })
        })
      });
    })
    this.parsingOutput.wrongPolygons.forEach(polygon => {
      polygon.lineMesh.visible = false;      
    })
    this.parsingOutput.fields.forEach(field => {
      field.parts.forEach(part => {
        if (part.polygon)
          part.polygon.lineMesh.material.opacity = 1;
      })
    })
 

    let captureBbox = new THREE.Box3();

    
    // wrongPolygon을 renderGroup에서 제외
    this.parsingOutput.fields.length > 0 && this.parsingOutput.fields[0].renderGroup.children.forEach(c => {
      if (c.type !== 'Group') this.parsingOutput.fields[0].renderGroup.remove(c);
    });

    this.parsingOutput.buildings.length > 0 && this.parsingOutput.buildings[0].renderGroup.children.forEach(c => {
      if (c.type !== 'Group') this.parsingOutput.buildings[0].renderGroup.remove(c);
    });
    

    captureBbox.expandByObject(this.state.field.site.length ? this.state.field.site[0].renderGroup : this.polygon2DGroup);


    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    App.stage !== "prod" && console.log('save image');
    saveDataToS3(lImgBuf, savePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, savePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, savePath, 'img_small.png', 'image/png');

  }

  getBuildingHight = async (build: building, pos: THREE.Vector3) => {
    let lambda = await new AWSModule("LAMBDA").connect();

    let points: THREE.Vector3[] = [];
    build.outline.forEach(o => {
      o.node.data.forEach(d => {
        points.push(pos.clone().sub(new THREE.Vector3(d.x, d.y, 0)));
      })
    })

    let tmPoints = this.sceneManager.getPointArrayToMap(points, this.mapProj);

    let heights: number[] = [];
    tmPoints.forEach(async p => {
      await lambda.Lambda!.invoke({
        FunctionName: "buildit-public-platform-GetDemHeight-v20200410",
        Payload: JSON.stringify({
          x: p.x,
          y: p.y,
        })
      }).promise().then(r => {
        heights.push(JSON.parse(r.Payload as string).body.value * 0.1);
      });
    });

    let maxHeight = 0;
    heights.forEach(h => {
      maxHeight = maxHeight < h ? h : maxHeight;
    });
    return maxHeight;
  }

  calculateMapProjectionData() {
    // this.mapProj.sceneOffset = this.mount!.getBoundingClientRect();
//    const projection = this.mapManager.map!.nMap.getProjection();
    
    this.mapProj.projection = this.mapManager.map!.nMap.getProjection();
    if (this.basePosition) {
      let origin = this.sceneManager.getLatLonPosition(this.siteFields[0].getLayer()!.polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.mapProj);
      this.mapProj.mapOffset = new THREE.Vector2(this.basePosition.x, this.basePosition.y).sub(new THREE.Vector2(origin.x, origin.y));
    }
    else {
      this.mapProj.mapOffset = new THREE.Vector2(0, 0);
    }
  }

  checkAllBuilding = (names: string[]) => {
    this.Buildings.forEach(b => {
      let coreHouses = 0;
      b.cores.forEach(c => {
        coreHouses += c.houses.length;
        if (!c.CheckCompleteness()) {
          names.push(b.name + '_' + c.name);
        }
      })

      if (coreHouses > b.houses.length) {
        names.push(b.name);
      }

  
    });

    if (names.length === 0) {
      return true;
    }
    else {
      return false;
    }
  }





  removeBuildingFromeBuildingList = (building: BuildingTypeData) => {
    deleteBuildingFromBuildingList(this.Buildings, building);
    this.recalculateArea();
  }

  reset = () => {
    this.setState({
      loadFile: false,
    });
    this.setState({
      screenWidth: window.innerWidth, //- (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 60,
    });
    this.dataInitialize();    
  }

  setIsFocus = (type: LineInfoType | null) => {
    if (this.state.polygonVisible.site) {

      switch (type) {
        case "project_site":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.site, 1);
          break;
        case "road":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.road, 1);
          break;
        case "center_of_road":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.roadCenterLine, 1);
          break;
        case "vacancy_outside":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.vacancyOutside, 1);
          break;
        case "vacancy_inside":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.vacancyInside, 1);
          break;
        case "topography":
          setBlockOpacity(this.parsingOutput.fields, 0.2);
          setBlockOpacity(this.state.field.topography, 1);
          break;
        case null:

          setBlockOpacity(this.parsingOutput.fields, 1);
          this.state.polygonVisible.type && setBlockOpacity(this.parsingOutput.buildings, 1);
          break;
      }
    }
    if (this.state.polygonVisible.type) {
      switch (type) {
        case "normal_window":
          setBlockOpacity(this.parsingOutput.buildings, 0.2);
          handleOpacityCertainType(this.state.colorInfoType.normalWindow, 1, "WIN2");
          break;
        case "light_window":
          setBlockOpacity(this.parsingOutput.buildings, 0.2);
          handleOpacityCertainType(this.state.colorInfoType.lightWindow, 1, "WIN1");
          break;
        case "concrete":
          setBlockOpacity(this.parsingOutput.buildings, 0.2);
          handleOpacityCertainType(this.state.colorInfoType.concrete, 1, "CON");
          break;
        case "core":
          setBlockOpacity(this.parsingOutput.buildings, 0.2);
          handleOpacityCertainType(this.state.colorInfoType.core, 1, "CORE");
          break;
        case "house":
          setBlockOpacity(this.parsingOutput.buildings, 0.2);
          handleOpacityCertainType(this.state.colorInfoType.unit, 1, "UNIT");
          break;
        case null:
          setBlockOpacity(this.parsingOutput.buildings, 1);
          this.state.polygonVisible.site && setBlockOpacity(this.parsingOutput.fields, 1);
          break;
      }
    }

    if (!this.state.isCad) {
      // type 이 동평면 & 동평면이 visible일때
      if ((type === "project_site" || type === "center_of_road" || type === "road" || type === "vacancy_outside" ||
        type === "vacancy_inside" || type === "topography" || type === null) && this.state.polygonVisible.site) {
        this.setPolygonOpacity(type);
      }
      // type이 사업영역 & 사업영역이 visible일때
      else if ((type === "core" || type === "house" || type === null) && this.state.polygonVisible.type) {
        this.setPolygonOpacity(type);
      }
    }
  };

  setPolygonOpacity = (type: LineInfoType | null) => {    
    const projectSite = {
      data: this.SitePolygon,
      normalOption: DrawingManager2.DrawingOption.PROJECT_SITE,
      fadeOption: {
        strokeColor: "rgb(255, 51, 51, 0.4)",
        strokeStyle: DrawingManager2.DrawingOption.PROJECT_SITE.strokeStyle,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.PROJECT_SITE,
      type: "project_site",
    };
    const boundarySite = {
      data: this.boundarySite,
      normalOption: DrawingManager2.DrawingOption.BOUNDARY_SITE,
      fadeOption: {
        strokeColor: "rgba(255, 255, 255, .4)",
      },
      focusOption: DrawingManager2.DrawingOption.BOUNDARY_SITE,
      type: "center_of_road",
    };

    const topographyLine = {
      data: this.topographyLine,
      normalOption: DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
        strokeColor: "rgba(255, 255, 255, .4)",
      },
      focusOption: {
        ...DrawingManager2.DrawingOption.TOPOGRAPHY_LINE,
        strokeColor: "#0085FF",
        strokeWeight: 2.5,
      },
      type: "topography",
    };
    const vacancyInside = {
      data: this.vacancyInside,
      normalOption: DrawingManager2.DrawingOption.VACANCY_INSIDE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.VACANCY_INSIDE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.VACANCY_INSIDE,
      type: "vacancy_inside",
    };
    const vacancyOutside = {
      data: this.vacancyOutside,
      normalOption: DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.VACANCY_OUTSIDE,
      type: "vacancy_outside",
    };
    const roadSite = {
      data: this.roadSite,
      normalOption: DrawingManager2.DrawingOption.ROAD_SITE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.ROAD_SITE,
        strokeColor: "rgba(255, 255, 255, .4)",
        fillOpacity: 0
      },
      focusOption: DrawingManager2.DrawingOption.ROAD_SITE,
      type: "road",
    };
    const coreData = {
      data: this.corePolygon,
      normalOption: DrawingManager2.DrawingOption.CAD_CORE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.CAD_CORE,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.CAD_CORE,
      type: "core",
    };
    const houseData = {
      data: this.housePolygon,
      normalOption: DrawingManager2.DrawingOption.CAD_HOUSE,
      fadeOption: {
        ...DrawingManager2.DrawingOption.CAD_HOUSE,
        fillOpacity: 0,
      },
      focusOption: DrawingManager2.DrawingOption.CAD_HOUSE,
      type: "house",
    };


    let fadeArray: Array<any> = [projectSite, boundarySite, topographyLine, vacancyInside, vacancyOutside, roadSite, coreData, houseData];
    let focusArray: Array<any> = [];
    let index: number | undefined;

    if (type !== null) {
      index = fadeArray.findIndex(item => item.type === type);
      if (index > -1) {
        focusArray = fadeArray.splice(index, 1);
        fadeArray.map((fades) => {
          fades["data"]!.length !== 0 &&
            fades["data"].map((fade: any) => {
              fade !== undefined && fade.setOptions(fades.fadeOption);
            });
        });
        focusArray.length !== 0 &&
          focusArray[0]["data"].map((focusData: any) => {
            focusData !== undefined && focusData.setOptions(focusArray[0].focusOption);
          });  
      }
    }  
    else {
      // type === null: 범례에서 focus되지 않고 있는 경우
      index = undefined;
      focusArray = [];

      fadeArray.map((fades) => {
        fades["data"]![0] !== null &&
          fades["data"].map((allData: any) => {
            allData !== undefined && allData.setOptions(fades.normalOption)
          });
      });
    }
  };

  setGroupByColor = () => {
    let concrete: Polygon[] = [];
    let unit: Polygon[] = [];
    let core: Polygon[] = [];
    let lightWindow: Polygon[] = [];
    let normalWindow: Polygon[] = [];

    this.parsingOutput.buildings.forEach(blockF => {
      grouping(blockF);
    })

    function grouping(block: ConverterBuilding | BuildingPart | BuildingHouseUnit | BuildingCoreUnit | BuildingGroup) {
      block.polygon.forEach((polygon: Polygon) => checkLayerName(polygon));
      block.parts.forEach((part: any) => grouping(part));
      //@ts-ignore
      if (block.buildingType && block.buildingType === "component") {
        //@ts-ignore
        if (block.componentType === "house") unit = unit.concat(block.polygon);//.filter(poly => poly.innerMesh.visible));
        //@ts-ignore
        else if (block.componentType === "core") core = core.concat(block.polygon);//.filter(poly => poly.innerMesh.visible));
      }
    }

    function checkLayerName(polygon: Polygon) {
      switch(polygon.layer.toUpperCase()) {
        case "CON":
          concrete.push(polygon);
          break;
        case "WIN1":
          lightWindow.push(polygon);
          break;
        case "WIN2":
          normalWindow.push(polygon);
          break;
        default:
          break;
      }
    }
    this.setState({
      colorInfoType: {
        concrete: this.state.colorInfoType.concrete.concat(concrete),
        unit: this.state.colorInfoType.unit.concat(unit),
        core: this.state.colorInfoType.core.concat(core),
        lightWindow: this.state.colorInfoType.lightWindow.concat(lightWindow),
        normalWindow: this.state.colorInfoType.normalWindow.concat(normalWindow),
      }
    });

  }


  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    });
  }

  deleteNaverPolygon = () => {
    if (this.naverPolygon) {
      this.naverPolygon.setMap(null);
      this.naverPolygon = undefined;
    }
  }

  showSaveModal() {
    this.setState({ 
      showSaveModal: false,
      isSaved: false,
    });
  }

  changeSaveFileName = (name: string) => {
    this.setState({ saveName: name });
  }

  clickBlockUI = (buildingPart: BuildingPart | ConverterBuilding) => {
    this.setState({ clickedBuildingPart: buildingPart, clickedField: null })
  }

  clickFieldUI = (field: ConverterField) => {
    this.setState({ clickedField: field });
  }

  setErrorByType = (type: ErrorType) => {
    this.setState({
      errorLogs: this.errorList.getErrorByType(type)
    });
  }

  
  changeCurrentLog = (id: string) => {
    if (this.state.currentLog === id || id === "ALL") {
      this.setState({
        errorLogs: this.errorList.getError(),
        currentLog: "ALL",
      });

    }
    else {
      this.setState({
        errorLogs: this.errorList.getErrorById(id),
        currentLog: id,
      })
  
    }
  }

  handleSettingModalType = (type: ConverterType) => {
    this.setState({ settingModalType: type })
  }

  openModal = (modalOptions: ModalOptions) => {
    this.setState({ 
      openModal: true,
      modalOptions,
    })
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  findUUIDBlock(uuid: string) {
    let result: any = undefined;
    for (let i = 0; i < this.parsingOutput.buildings.length; i++) {
      checkUUID(this.parsingOutput.buildings[i]);
      if (result) {
        
        return result;
      }
    }

    function checkUUID(block: any) {
      if (block.uuid === uuid) {
        result = block;
        return;
      }
      else {
        if (block.parts) {
          for (let i = 0; i < block.parts.length; i++) {
            checkUUID(block.parts[i]);
            if (result) break;
          }
        } 
      }
    }
    return null;
  }


  handleVisible = (type: ConverterType.mySite | ConverterType.myType) => {

    if (this.state.polygonVisible.site && this.state.polygonVisible.type) {
      if (type === ConverterType.mySite) {
        this.setState({ errorLogs: this.errorList.getErrorByConverterType(ConverterType.myType)});
      }
      else if (type === ConverterType.myType) {
        this.setState({ errorLogs: this.errorList.getErrorByConverterType(ConverterType.mySite)});
      }
    }
    if (type === ConverterType.mySite) {
      const opacity = !this.state.polygonVisible.site ? 1 : 0.2;
      setBlockOpacity(this.parsingOutput.fields, opacity);

      if (!this.state.polygonVisible.site === false) {
        if (this.state.polygonVisible.type) this.setState({errorLogs: this.errorList.getErrorByConverterType(ConverterType.myType)});
        else this.setState({errorLogs: []});
      }
      else {
        if (this.state.polygonVisible.type) this.setState({errorLogs: this.errorList.getError()});
        else this.setState({errorLogs: this.errorList.getErrorByConverterType(ConverterType.mySite)});
      }
      this.setState({
        polygonVisible: {
          ...this.state.polygonVisible,
          site: !this.state.polygonVisible.site
        },
      });
    }
    else {
      const opacity = !this.state.polygonVisible.type ? 1 : 0.2;
      setBlockOpacity(this.parsingOutput.buildings, opacity);

      if (!this.state.polygonVisible.type === false) {
        if (this.state.polygonVisible.site) this.setState({errorLogs: this.errorList.getErrorByConverterType(ConverterType.mySite)});
        else this.setState({errorLogs: []});
      }
      else {
        if (this.state.polygonVisible.site) this.setState({errorLogs: this.errorList.getError()});
        else this.setState({errorLogs: this.errorList.getErrorByConverterType(ConverterType.myType)});
      }

      this.setState({
        polygonVisible: {
          ...this.state.polygonVisible,
          type: !this.state.polygonVisible.type
        },
      });
    }
  }


  findCurrentLog = () => {
    if (this.state.currentLog === "ALL") return "ALL";


    const siteBlock = this.parsingOutput.fields.find((f) => f.uuid === this.state.currentLog);
    if (siteBlock) return siteBlock.name;
     
    return this.findUUIDBlock(this.state.currentLog).name;
//    return this.parsingOutput.buildings.find((f) => f.uuid === this.state.currentLog)!.name;
    
  }
  render = () => {
    return (
      <div className="buildit-template myPlanType">
        {
          this.state.openModal &&
          <Modal
            open={this.state.openModal}
            color="DARK"
            type="MOBILE"
            negative={this.state.modalOptions.negative}
            positive={this.state.modalOptions.positive}
            title={this.state.modalOptions.title}
            positiveTitle={this.state.modalOptions.positiveTitle}
            content={this.state.modalOptions.content}
            negativeTitle={this.state.modalOptions.negativeTitle}
          />

        }
        <CadConverterModal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></CadConverterModal>
        <LoadingPage show={this.state.loadingPage} loadingMsg="배치안 파일을 불러오는 중입니다." />
        <Setting 
        
        handleConverterType={this.handleSettingModalType}

        converterType={this.state.settingModalType} closeModal={() => this.setState({ showSettingModal: false })} open={this.state.showSettingModal} settingData={this.settingData} />
        <MySiteBlockSaveModal
          DBTableName={this.DBTableName}
          converterType={ConverterType.myPlane}
          userId={this.state.userID}
          showModal={this.state.showSaveModal}
          onShowSaveModal={() => this.showSaveModal()}
          errorSize={this.state.errorSize}
          planArea={{
            address: this.state.address,
            constructSize: `${this.state.houseNumber}세대, ${this.parsingOutput.buildings.length}동, ${this.state.level.maxLevel}층/${this.state.level.minLevel}층`, //`${}세대, ${this.parsingOutput.buildings.length}동, ${}층/${}층`,
            totalBuildingArea: `${Math.round(this.state.totalBuildingArea)}㎡`,
            totalGroundArea: `${this.state.totalGroundArea.toFixed(4)}㎡`,
            exclusiveAverageArea: `${this.state.exclusiveAverageArea}㎡`,
          }}
          centerOfRoad={this.setCenterOfRoad}
          fileName={this.state.saveName}
          onSave={() => this.saveBlockData()}
          isSaved={this.state.isSaved}
          parsingOutput={this.parsingOutput}
          onChangeFileName={this.changeSaveFileName}
          hasDrawing={this.state.drawPolygonOnSaveModal}
          handleDrawing={(value: boolean) => this.setState({drawPolygonOnSaveModal: value})}
        />

        <ConverterHeader
          type={ConverterType.myPlane}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={() => {
            this.reset();
          }} //this.clearSettingData}
          isFileOpen={this.state.loadFile}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          infoSize={this.state.infoSize}
          isLatest={true}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          
          closeModal={this.closeModal}

          saveFile={() => {
            if (this.state.warningSize > 0) {
              let msg: string[] = [];
              [...this.loadFileErrorLogs, ...this.globalErrorLogs].forEach((log) => {
                if (log.Type === "Warning") msg.push(log.Information);
              });
              this.setState({ showSaveModal: true });
              // this.showModal('알림', [...msg, '계속 진행하시겠습니까?'], buttonNum.twoButton,
              // () => {
              //   this.setState({ showSaveModal: true }) });
            } else {
              this.setState({ showSaveModal: true });
            }
            // let showModal = false;
            // for (let i = 0; i < this.state.field.topography.length; i++) {
            //   if (this.state.field.topography[i].getHeight() >= 500) {
            //     showModal = true;
            //     break;
            //   }
            // }

            // if (showModal) {
            //   this.openModal(
            //     {
            //       title: "배치안 저장 알림",
            //       positiveTitle: "확인",
            //       negativeTitle: "취소",
            //       content: <div>대지 레벨 입력 값이 권장 범위를 벗어났습니다.<br />실제 프로젝트 생성 시 사업영역 대지레벨 기준으로 범위가 제한될 수 있습니다.<br />
            //         그래도 입력하시겠습니까?
            //       </div>,
            //       positive: () => { this.setState({ openModal: false, showSaveModal: true }); },
            //       negative: () => { this.setState({ openModal: false }) }
            //     });
            // }
            // else this.setState({ showSaveModal: true });
          }}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.openModal}
        />
        <div className="MainBody">
        {/* {this.state.loadFile && <div className="navigator-rectangle"/>} */}
        {this.cores.map((c) => (
            <>
              <div className={`infoIcon info${c.uuid}`} key={`info${c.uuid}`}
              hidden={!this.state.is2D}
                onMouseEnter={() => {
                  const element = document.querySelector(`.TT${c.uuid}`);
                  if (element) {
                    (element as HTMLDivElement).style.visibility = "visible";
                    //@ts-ignore
                    element.mouseEnter = true;
                  }
                }}
                onMouseLeave={() => {
                  const element = document.querySelector(`.TT${c.uuid}`);
                  if (element) {
                    (element as HTMLDivElement).style.visibility = "hidden";
                    //@ts-ignore
                    element.mouseEnter = undefined;
                  }
                }}
              >
                <Info />
              </div>
            </>
          ))}

          {this.houses.map((h) => (
            <>
              <div className={`infoIcon info${h.uuid}`}
              hidden={!this.state.is2D}
                key={`info${h.uuid}`}
                onMouseEnter={() => {
                  const element = document.querySelector(`.TT${h.uuid}`);
                  if (element) {
                    (element as HTMLDivElement).style.visibility = "visible";
                    //@ts-ignore
                    element.mouseEnter = true;
                  }
                }}
                onMouseLeave={() => {
                  const element = document.querySelector(`.TT${h.uuid}`);
                  if (element) {
                    (element as HTMLDivElement).style.visibility = "hidden";
                    //@ts-ignore
                    element.mouseEnter = undefined;
                  }
                }}
              >
                <Info/>
              </div>
            </>
          ))}

          <div className="siteInfo">
            {this.state.field.topography.map((topo) => (
              <div className={`fieldText TT${topo.uuid}`}>
                <LevelIcon className="icon levelIcon"></LevelIcon>
                <div className={`font font-14px`}>{topo.getHeight()}</div>
              </div>
            ))}
          </div>
          <div className="areaInfo" hidden={!this.state.is2D}>
          {this.houses.map((h) => (
              <>
                <div className={`textTest TT${h.uuid} houseInfo`} key={h.uuid}
                style={{height: "89px"}}
                >
                  <div className="key-value">
                    <li className="font font-12px key house">{`단위 세대 ${h.name.split('_')[0]}-${h.sameNameNumber}`}</li>
                  </div>
                  <div className="key-value">
                    <li className={`font font-12px key house ${h.initialArea.exclusiveArea !== h.exclusiveArea && "active"}`}>
                    <div className={`circle-icon ${h.initialArea.exclusiveArea !== h.exclusiveArea ? "active" : "non-active"}`} />
                      전용면적
                    </li>
                    <span className={`font font-12px value ${h.initialArea.exclusiveArea !== h.exclusiveArea && "active"}`}>{`${h.exclusiveArea}`}㎡</span>
                  </div>
                  <div className="key-value">
                    <li className={`font font-12px key house ${h.initialArea.serviceArea !== h.serviceArea && "active"}`}>
                      <div className={`circle-icon ${h.initialArea.serviceArea !== h.serviceArea ? "active" : "non-active"}`} />
                      서비스면적
                    </li>
                    <span className={`font font-12px value ${h.initialArea.serviceArea !== h.serviceArea && "active"}`}>{`${h.serviceArea}`}㎡</span>
                  </div>
                  <div className="key-value">
                    <li className={`font font-12px key house ${h.initialArea.commonWallArea !== h.commonWallArea && "active"}`}>
                      <div className={`circle-icon ${h.initialArea.commonWallArea !== h.commonWallArea ? "active" : "non-active"}`} />
                      벽체공용면적
                    </li>
                    <span className={`font font-12px value ${h.initialArea.commonWallArea !== h.commonWallArea && "active"}`}>{`${h.commonWallArea}`}㎡</span>
                  </div>
                </div>
              </>
            ))}
            {this.cores.map((c) => (
              <>
                <div className={`textTest font font-emphasis font-12px TT${c.uuid} coreInfo`} key={c.uuid}
                  style={{height: "54px"}}
                >
                  <div className="key-value">
                    <li className="font font-12px core key">{`코어 ${c.name.split('_')[0]}-${c.sameNameNumber}`}</li>
                  </div>
                  <div className="key-value">
                    <li className={`font font-12px core key ${c.GetArea() !== c.initialArea ? "active": ""}`}>
                      <div className={`circle-icon ${c.GetArea() !== c.initialArea ? "active" : 'non-active'}`} />코어면적
                    </li>
                    <span className={`font font-12px value ${c.GetArea() !== c.initialArea ? "active" : ""}`}>{`${c.GetArea()}㎡`}</span>
                  </div>
                </div>
              </>
            ))}
          </div>
        
          {
            this.state.loadFile &&
            <div className="information">
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">대지영역면적</div>
                <div className="inforValue font font-emphasis font-14px">{Number(this.state.siteArea.toFixed(4)).toLocaleString()}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">총세대수</div>
                <div className="inforValue font font-emphasis font-14px">{this.state.houseNumber}세대</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">총건물수</div>
                <div className="inforValue font font-emphasis font-14px">{this.parsingOutput.buildings.length}동</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">최고층수</div>
                <div className="inforValue font font-emphasis font-14px">{this.state.level.maxLevel}층</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">최저층수</div>
                <div className="inforValue font font-emphasis font-14px">{this.state.level.minLevel}층</div>
              </div>
              {/* {this.Buildings.length} */}
              {/* <div className="info font font-secondary font-12px">
                <div className="infoLabel">건축면적</div>
                <div className="inforValue font font-emphasis font-14px">{this.state.totalBuildingArea.toFixed(4)}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">바닥면적</div>
                <div className="inforValue font font-emphasis font-14px">{this.state.totalGroundArea.toFixed(4)}㎡</div>
              </div>
              <div className="info font font-secondary font-12px">
                <div className="infoLabel">평균 전용면적</div>
                <div className="inforValue font font-emphasis font-14px">{this.state.exclusiveAverageArea.toFixed(4)}㎡</div>
              </div> */}
              <div className="fileInfo">
                <span className={`dxfIcon ${this.state.loadFile && "loaded"}`}>
                  <span className={`text ${this.state.loadFile && "loaded"}`}>dxf</span>
                </span>
                <span className={`fileName ${this.state.loadFile && "loaded"}`}>{(this.state.loadFile && this.state.fileName) || "선택된 파일이 없습니다."}</span>
              </div>
            </div>
          }

          <div className="RenderView">
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.openSnack}
              onClose={() => { this.setState({ openSnack: false }) }}//{this.handleSnackClose}
              className="snack-bar"
              autoHideDuration={5000}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={this.state.snackMsg}

            />

            <div className="Scene">
              <div
                className="Canvas"
                ref={(mount) => {
                  this.mount = mount;
                }}
                onClick={() => {
                  this.setState({ clickedField: null, clickedBuildingPart: null });
                  if (this.state.polygonVisible.site) setBlockOpacity(this.parsingOutput.fields, 1);
                  if (this.state.polygonVisible.type) setBlockOpacity(this.parsingOutput.buildings, 1);
                }}

              >
                <DropFileBox functionForButton={this.loadDXFFile} functionForDrop={this.loadFilebyFile} loadFile={this.state.loadFile} type={ConverterType.myPlane} showSample={true} 
                  isBlockVer={true} />
                <div ref="map" style={{ width: `100%`, height: `100%`, position: "absolute" }} />
                {
                  this.state.loadFile &&
                  < ConstructionLineInfo
                    darkMode={this.state.isCad ? true : false}
                    title={["나의 동평면", "나의 사업영역"]}
                    className=""
                    typeList={
                      this.state.isCad ? [["concrete", "core", "house", "light_window", "normal_window"], ["vacancy_inside", "project_site", "center_of_road", "road", "vacancy_outside", "topography"]] :
                      [["core", "house"], ["vacancy_inside", "project_site", "center_of_road", "road", "vacancy_outside", "topography"]]
                    
                    }
                    hasVisibleIcon={true}
                    isCadConverter={true}
                    setIsFocus={this.setIsFocus}
                    fieldBlocks={this.state.field}
                    // header={<div></div>}
                    isVisible={[this.state.polygonVisible.type, this.state.polygonVisible.site]}
                    handleVisible={(isVisible: ConverterType.mySite | ConverterType.myType) => this.handleVisible(isVisible)}
                    isCollapsible={true}

                  />



                }
                {this.state.loadFile && (
                  <Button
                    className="btn bg-navy btn-primary fold-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ showBlock: !this.state.showBlock });
                    }}
                  >
                    {this.state.showBlock ? <NavigateNext className="icon icon-next" /> : <ChevronLeft className="icon icon-next" />}
                  </Button>
                )}

                <div className={`toolBar ${!this.state.loadFile && "hidden"} ${this.state.showBlock ? "" : "showBlock"}`}>
                  <div className="rightButtons">
                    <div className="switchTabs">
                      {/* <Tooltip place='left' arrowOn={false} msg="캐드 도면으로 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.CAD && 'enable'}`} onClick={e => 
                          {
                            this.Switch2D3D(false);
                            this.canvasModeChanged(CanvasMode.CAD)
                          }}>CAD</Button>
                      </Tooltip>
                      <Tooltip place='left' arrowOn={false} msg="지도에서 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.MAP && 'enable'}`} onClick={e => this.canvasModeChanged(CanvasMode.MAP)}>MAP</Button>
                      </Tooltip>
                      <Tooltip place='left' arrowOn={false} msg="3D 모델로 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.VIEW_3D && 'enable'}`} onClick={e => {
                          this.Switch2D3D(true);
                          this.canvasModeChanged(CanvasMode.VIEW_3D);
                          
                          }}>3D VIEW</Button>
                      </Tooltip> */}
                      <Tooltip place="left" arrowOn={false} msg="캐드 도면으로 확인">
                        <Button className={`switchButton ${this.state.isCad && "enable"}`} onClick={(e) => {
                          
                          // const {x, y, z} = this.state.previousCameraSetting.position;

                          // if (x !== 0 && y !== 0) {
                          //   this.sceneManager.orthoCamera.position.set(x, y, z);
                          //   this.sceneManager.orthoCamera.zoom = this.state.previousCameraSetting.zoom;
                          // }
                          this.canvasModeChanged(true)
                          
                          }}>
                          CAD
                        </Button>
                      </Tooltip>
                      <Tooltip place="left" arrowOn={false} msg="지도에서 확인">
                        <Button className={`switchButton ${!this.state.isCad && "enable"}`} onClick={(e) => {
                          // console.log( this.sceneManager.orthoCamera.zoom, 'zoom');
                          
                          // this.setState({
                          //   previousCameraSetting: {
                          //     position: this.sceneManager.orthoCamera.position,
                          //     zoom: this.sceneManager.orthoCamera.zoom
                          //   }
                          // }, () => {
                            this.canvasModeChanged(false)
//                          })


                        }}>
                          MAP
                        </Button>
                      </Tooltip>
                    </div>
                    <div className={`switchTabs ${this.state.isCad && "hidden"}`}>
                      <Button className={`switchButton ${!this.state.isCadastral && "enable"}`} onClick={(e) => this.mapTypeChanged(false)}>
                        지도
                      </Button>
                      <Button className={`switchButton ${this.state.isCadastral && "enable"}`} onClick={(e) => this.mapTypeChanged(true)}>
                        지적도
                      </Button>
                    </div>
                    <Tooltip place="left" msg="블록 정보 보기" arrowOn={false}>
                      <Button
                        className={`btn bg-navy btn-secondary infoButton ${this.state.showBlockInfo ? "active" : ""}`}
                        onClick={() => {
                          this.setState({ showBlockInfo: !this.state.showBlockInfo });
                        }}
                      >
                        <Info className={`icon info-icon font font-emphasis ${this.state.showBlockInfo ? "font-special" : ""}`} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <ErrorLogBlock
                converterType={ConverterType.myPlane}
                allBuildings={this.parsingOutput.buildings} //{[...this.houses, ...this.cores]}
                allFields={this.parsingOutput.fields.concat(this.parsingOutput.wrongBlocks as ConverterField[])}
                show={this.state.errorLog}
                closeLog={() => this.setState({ errorLog: !this.state.errorLog })}
            
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                confirmSize={this.state.infoSize}
                errorList={this.state.errorLogs}
                showErrorByType={this.setErrorByType}
                visible={this.state.polygonVisible}
                closeCurrentLog={() => {
                  this.setState({ errorLogs: this.errorList.getError() });
                  this.setState({ currentLog: "ALL" });
                }}
                currentLog={this.findCurrentLog()}
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && "hidden"}`}>
              <div className={`wrapBlockList ${this.state.showBlock ? "" : "display-none"}`}>
                <div className="header">
                  <div className="left-side">
                    <HierarchyIcon className="icon hierarchy-icon" />
                    <span className="text">목록</span>  
                  </div>
                  <div className="right-side">
                    <BuilditSelect 
                      type="Normal"
                      value={this.state.hierarchyMode}
                      list={[
                        {label: '전체', value: ConverterType.myPlane},
                        {label: '나의 동평면', value: ConverterType.myType},
                        {label: '나의 사업영역', value: ConverterType.mySite},
                      ]}
                   //   placeholder={'전체'}
                      onChange={(v) => {this.setState({hierarchyMode: v as ConverterType})}}
                      adornment="Right"
                      >

                    </BuilditSelect>
                  </div>
                  
                </div>

                <div className="description">
                  {
                    this.state.showBlock &&
                    <BlockTreeList
                      clickedField={this.state.clickedField}
                      parsingOutput={this.parsingOutput}
                      setClickedBlock={this.setClickedBlock}
                      clickedBuilding={this.state.clickedBuildingPart}
                      clickComponent={this.state.clickedBuildingPart}
                      filter={this.state.hierarchyMode}
                      hasHeader={true}
                      handleVisible={this.handleVisible}
                      isVisible={this.state.polygonVisible}
                      findBlockUUID={(id: string) => this.findUUIDBlock(id)}
                      currentLog={this.state.currentLog}
                      errorList={this.errorList}
                      handleCurLogById={(id: string) => this.changeCurrentLog(id)}
                      handleCurLog={() => { }}
                    />
                  }
                </div>
              </div>

              <div className={`functionalLayout ${this.state.showBlock ? "" : "display-none"}`}>
                <div className="header">
                  <div className="wrapper">
                    <div className="wrap-icon m-l-md">
                      <Info className="icon info-icon font font-emphasis" />
                    </div>
                    <span className="font font-primary">속성</span>
                  </div>
                </div>
                <div className={`description ${this.state.showBlock ? "" : "display-none"}`}>


                  <div className="font font-emphasis font-14px selectedBlockName">
                    {this.state.clickedField && (
                      <div className="title">
                        <span>
                          <BlockIcon className=" font font-emphasis block-icon" />
                          {this.state.clickedField.name}
                        </span>
                        <span className="font font-secondary font-12px field-type">{this.state.clickedField.typeName}</span>
                      </div>
                    )}
                    {this.state.clickedBuildingPart && (
                      <div className="title">
                        <span>
                          <BlockIcon className="block-icon font font-emphasis block-icon" />
                          {this.state.clickedBuildingPart.name}
                        </span>
                        <span className="font font-secondary font-12px field-type">
                          {this.state.clickedBuildingPart.blockType}
                        </span>
                      </div>
                    )}
                    {
                      (this.state.clickedField === null && this.state.clickedBuildingPart === null) &&
                      <div className="title">
                        <span>
                          <BlockIcon className="block-icon font font-emphasis" />
                          -
                        </span>
                      </div>
                    }
                  </div>
                  {this.state.clickedField !== null &&
                    <FieldPartUI
                      field={this.state.clickedField}
                      showSnackBar={(type: "AREA" | "HEIGHT") => {
                        let msg = <></>;
                        switch (type) {
                          case "AREA":
                            msg = <div
                              className="font font-emphasis msg font-pretendard"
                              style={{ textAlign: "center", backgroundColor: "transparent" }}>
                              <span className="font font-special">면적이 수정되었습니다.</span><br />
                              수정된 면적은 ‘파일 관리’에서 제공되는 원본 캐드파일에는 반영되지 않습니다.</div>;
                            break;
                          case "HEIGHT":
                            msg = <div
                              className="font font-emphasis msg font-pretendard"
                              style={{ textAlign: "center", backgroundColor: "transparent" }}>
                              <span className="font font-special">대지레벨이 수정되었습니다.</span><br />
                              수정된 대지레벨은 ‘파일 관리’에서 제공되는 원본 캐드파일에는 반영되지 않습니다.</div>;
                            break;
                          default:
                            break;
                        }
                        this.setState({
                          snackMsg: msg,
                          openSnack: true,
                        });
                      }}
                      updateArea={() => {
                        this.recalculateArea();
                      }}>
                    </FieldPartUI>}
                  {this.state.clickedBuildingPart !== null && (
                    <BuildingPartUI
                      // field={this.state.clickedField}
                      type={ConverterType.myPlane}
                      showSnackBar={() => {
                        this.setState({
                          openSnack: true,
                          snackMsg: <div className="font font-emphasis msg font-pretendard" style={{ textAlign: "center" }}>
                            <span className="font font-special">면적이 수정되었습니다.</span><br />
                            수정된 면적은 ‘파일 관리’에서 제공되는 원본 캐드파일에는 반영되지 않습니다.</div>
                        });
                      }}
                      updateArea={() => {
                        this.recalculateArea();
                        // this.setState({
                        //   openSnack: true,
                        //   snackMsg: <div className="font font-emphasis msg font-pretendard" style={{ textAlign: "center" }}>
                        //     <span className="font font-special">면적이 수정되었습니다.</span><br />
                        //     수정된 면적은 ‘파일 관리’에서 제공되는 원본 캐드파일에는 반영되지 않습니다.</div>
                        // });
                      }} //this.recalculateResult()}
                      compoent={this.state.clickedBuildingPart}
                      buildingPart={this.blockBs}
                    // field={this.state.clickedField}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {
            this.state.loadFile &&
            <CADNavigator
              //@ts-ignore
              polygon2DGroup={this.polygon2DGroup}
              loadFile={this.state.loadFile}
              cameraZoom={this.sceneManager.orthoCamera.zoom}
              mapZoom={this.mapManager.map ? this.mapManager.map!.nMap.getZoom() : 17}
              mainCamPos={this.sceneManager.orthoCamera.position}
              controls={this.sceneManager.orthoControl}
              mapCenter={this.state.mapCenter}
              isCad={this.state.isCad}
              canvasOffset={{width: this.state.screenWidth, height: this.state.screenHeight}}//this.sceneManager.canvasElement.offsetWidth}
              sceneTopPosition={this.state.sceneTopPosition}
            />

          }
        </div>

      </div>
    );
  }
}