import * as THREE from '@teneleven/three';
import {DEMData, getDemHeight} from './DEMManager';
import App from '../App';
// import * as BufferGeometryUtils from '@teneleven/three/examples/jsm/utils/BufferGeometryUtils';
import {eachNode, GeneratorType} from "./Generator/GeneratorInterface"
import {MaterialManager, MatType} from "./MaterialManager";
import {RoofTopGenerator} from "./Generator/RoofTopGenerator";
import {DataForLevel, LevelGenerator} from "./Generator/LevelGenerator";
import {LogoGenerator} from "./Generator/LogoGenerator";
import {DataForPiloti, PilotiGenerator} from "./Generator/PilotiGenerator";
import {RoofLineGenerator} from "./Generator/RoofLineGenerator";
// import {generateTopRoofLineGeometry} from "./GeomUtilities";


const earcut = require('earcut');

interface BuildingMesh {
    wall: THREE.Geometry,
    window: THREE.Geometry,
    core: THREE.Geometry,
    piloti: THREE.Geometry,
    parkingLine: THREE.Vector2[],
    bottomLine: THREE.Geometry,
}

export function makePlane(verts: THREE.Vector3[]) {
    const geometry = new THREE.Geometry();
    geometry.vertices = verts;

    for (let verNum = 0, faceNum = 0; verNum < geometry.vertices.length - 3; faceNum++, verNum += 2) {
        geometry.faces.push(new THREE.Face3(verNum, verNum + 1, verNum + 2));
        geometry.faces.push(new THREE.Face3(verNum + 1, verNum + 3, verNum + 2));

        geometry.faceVertexUvs[0].push([
            new THREE.Vector2(0, 0),
            new THREE.Vector2(0, 1),
            new THREE.Vector2(1, 0),
        ]);
        geometry.faceVertexUvs[0].push([
            new THREE.Vector2(0, 1),
            new THREE.Vector2(1, 1),
            new THREE.Vector2(1, 0),
        ]);
    }

    geometry.computeFaceNormals();
    return geometry;
}

function makeRoofWithVertices(verts: THREE.Vector3[], inverse: boolean = false) {
    const geometry = new THREE.Geometry();

    geometry.vertices = verts;
    const triVerts: any = [];
    verts.forEach(v => {
        triVerts.push(v.x);
        triVerts.push(v.z);
    });
    const triangles = earcut(triVerts);

    for (let i = 0; i < triangles.length; i += 3) {
        if (inverse)
            geometry.faces.push(new THREE.Face3(triangles[i + 1], triangles[i + 2], triangles[i]));
        else
            geometry.faces.push(new THREE.Face3(triangles[i + 2], triangles[i + 1], triangles[i]));

        geometry.faceVertexUvs[0].push([
            new THREE.Vector2(0, 0),
            new THREE.Vector2(0, 1),
            new THREE.Vector2(1, 0),
        ]);
    }

    geometry.computeFaceNormals();
    return geometry;
}


function MakeWall(start: THREE.Vector2, end: THREE.Vector2, roomHeight: number, wallHeight: number) {
    let verts = [];
    verts.push(new THREE.Vector3(start.x, roomHeight, start.y));
    verts.push(new THREE.Vector3(start.x, roomHeight + wallHeight, start.y));

    verts.push(new THREE.Vector3(end.x, roomHeight, end.y));
    verts.push(new THREE.Vector3(end.x, roomHeight + wallHeight, end.y));

    return makePlane(verts);
}

function MakeWindow(windows: any[], nodes: any, winGeo: THREE.Geometry, wallGeo: THREE.Geometry, roomHeight: number, wallHeight: number) {
    if (windows.length > 0) {
        let windowLength = 0;
        windows.forEach(w => {
            windowLength += (new THREE.Vector2(nodes[w.start].x, nodes[w.start].y)).distanceTo(nodes[w.end] as THREE.Vector2);
        });
        let windowOffset = windowLength > 2.6 ? 0.2 : 0.6;
        windows.forEach(w => {
            wallGeo.merge(MakeWall(nodes[w.start], nodes[w.end], roomHeight, windowOffset));
            winGeo.merge(MakeWall(nodes[w.start], nodes[w.end], roomHeight + windowOffset, wallHeight - windowOffset * 2));
            wallGeo.merge(MakeWall(nodes[w.start], nodes[w.end], roomHeight + wallHeight - windowOffset, windowOffset));
        });
    }
}

function MakeLevelLine(start: THREE.Vector2, end: THREE.Vector2, height: number) {
    let lineGeo = new THREE.Geometry();

    lineGeo.vertices.push(new THREE.Vector3(start.x, height, start.y));
    lineGeo.vertices.push(new THREE.Vector3(end.x, height, end.y));

    return lineGeo;
}

function MakeLine(start: THREE.Vector3, end: THREE.Vector3) {
    let lineGeo = new THREE.Geometry();

    lineGeo.vertices.push(start);
    lineGeo.vertices.push(end);

    return lineGeo;
}

function makeHouse(buildingMesh: BuildingMesh, houseType: string, lineInfo: any, nodes: any, roomHeight: number, wallHeight: number, withRoof: boolean) {
    let verts: THREE.Vector3[] = [];
    let groundVerts: THREE.Vector3[] = [];
    let windows: any[] = [];
    lineInfo.forEach((l: any) => {
        switch (l.lineType) {
            case 'LT_COREOUTERWALL':
                buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight));
                buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
                MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.core, roomHeight, wallHeight);
                windows = [];
                break;

            case 'LT_OUTERWALL':
            case 'LT_INNERWALL':
            case 'LT_ENTRANCE':
            case 'LT_SIDEWALL':
            case 'LT_DOOR':
            case 'LT_SLIDE':
            case 'LT_GATE':
            case 'LT_COREINNERWALL':
                if (houseType === 'FC_CORE') {
                    buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight));
                } else {
                    buildingMesh.wall.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight));
                }
                buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
                MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.wall, roomHeight, wallHeight); // 창문생성
                windows = [];
                break;

            case 'LT_OUTERWINDOW':
            case 'LT_LIGHTWINDOW':
            case 'LT_INNERWINDOW':
            case 'LT_WINDOW':
            case 'LT_VIEWWINDOW':
            case 'LT_LVWINDOW':
                windows.push(l);
                buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
                break;

            default:
                App.stage !== "prod" && console.log(l.lineType);
                break;
        }
        verts.push(new THREE.Vector3(nodes[l.start].x, roomHeight + wallHeight, nodes[l.start].y));
        groundVerts.push(new THREE.Vector3(nodes[l.start].x, roomHeight, nodes[l.start].y));
    })

    //지붕면
    let roofPlane: THREE.Geometry | null = null;
    if (withRoof) {
        roofPlane = makeRoofWithVertices(verts);
    }

    if (houseType === 'FC_HOUSE') {
        // if (withRoof)
        //   buildingMesh.wall.merge(roofPlane as THREE.Geometry); //천장면
        buildingMesh.wall.merge(makeRoofWithVertices(groundVerts, true)); //바닥면
        MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.wall, roomHeight, wallHeight);
        windows = [];
    } else if (houseType === 'FC_CORE') {
        // if (withRoof)
        //   buildingMesh.core.merge(roofPlane as THREE.Geometry);
        buildingMesh.core.merge(makeRoofWithVertices(groundVerts, true));
        MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.core, roomHeight, wallHeight);
        windows = [];
    }

    return roofPlane;
}

function makeFoundation(buildingMesh: BuildingMesh, lineInfo: any, nodes: any) { //#211028 기단부 생성
    let foundationHeight = 100;
    let verts: THREE.Vector3[] = [];
    let groundVerts: THREE.Vector3[] = [];
    lineInfo.forEach((l: any) => {
        buildingMesh.wall.merge(MakeWall(nodes[l.start], nodes[l.end], -foundationHeight, foundationHeight));
        //buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], -foundationHeight));

        verts.push(new THREE.Vector3(nodes[l.start].x, 0, nodes[l.start].y));
        groundVerts.push(new THREE.Vector3(nodes[l.start].x, -foundationHeight, nodes[l.start].y));
    })
}

function makeRooftop(buildingMesh: BuildingMesh, houseType: string, lineInfo: any, nodes: any, roomHeight: number, wallHeight: number) {
    const rooftopHeight = 1;
    let coreRoof: THREE.Geometry | null = null;
    if (houseType == 'FC_CORE') {
        let verts: THREE.Vector3[] = [];
        lineInfo.forEach((l: any) => {
            buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight + wallHeight, rooftopHeight));
            buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight + wallHeight));
            buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight + wallHeight + rooftopHeight)); //상부에 라인을 하나 더 그려서 내부 구조 표현

            verts.push(new THREE.Vector3(nodes[l.start].x, roomHeight + wallHeight + rooftopHeight, nodes[l.start].y));
        })

        coreRoof = makeRoofWithVertices(verts);
        // buildingMesh.core.merge(makeRoofWithVertices(verts));
    }
    return coreRoof;
}

function makeParapet(parapetGeom: THREE.Geometry, lineInfo: any, nodes: any, YValue: number, parapetHeight: number) {
    let lineGeom = new THREE.Geometry();

    lineInfo.forEach((l: any) => {
        parapetGeom.merge(MakeWall(nodes[l.start], nodes[l.end], YValue, parapetHeight));
        lineGeom.merge(MakeLevelLine(nodes[l.start], nodes[l.end], YValue));
    });

    return lineGeom;
}

function makePiloti() {

}

function makeParkingLineLevel(buildingMesh: BuildingMesh, category: string, type: string, lineInfo: any, nodes: any, wallHeight: number) {
    lineInfo.forEach((l: any) => {
        switch (l.lineType) {
            case 'LT_COREOUTERWALL':
                buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], 0, wallHeight));
                break;

            case 'LT_OUTERWALL':
            case 'LT_PARKINGLINE':
                if (category === "PCT_PARKING") {
                    buildingMesh.parkingLine.push(new THREE.Vector2(nodes[l.start].x, nodes[l.start].y));
                    buildingMesh.parkingLine.push(new THREE.Vector2(nodes[l.end].x, nodes[l.end].y));
                } else if (category === 'PCT_CORE' && type === 'PT_CORE') {
                    buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], 0, wallHeight));
                }
                break;

            default:
                App.stage !== "prod" && console.log(l.lineType);
                break;
        }
    })
}

export function MakeBuildingMesh(buildingData: any, demData: DEMData[] = [], isCadConverter: boolean = false) {
    App.stage !== "prod" && console.log(buildingData);

    let matManager = MaterialManager.getInstance();

    let buildingMesh: BuildingMesh = {
        core: new THREE.Geometry(),
        piloti: new THREE.Geometry(),
        wall: new THREE.Geometry(),
        window: new THREE.Geometry(),
        parkingLine: [],
        bottomLine: new THREE.Geometry(),
    }

    // let textureAngle = Math.PI;

    let bkCustomData = undefined != buildingData.bk_customdata;

    let building = new THREE.Group();
    building.name = 'buildings';

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //materials
    let flipY = -1 != buildingData.rotStatus.toUpperCase().indexOf('INV_X');
    matManager.SetFlipY(flipY);

    let wallcoreMaterial = matManager.resloc_materials.get(MatType.WALL);
    // let floorMaterial = matManager.resloc_materials.get(MatType.FLOOR);
    let ceilingMaterial = matManager.resloc_materials.get(MatType.CEILING);
    // let windowMaterial = matManager.resloc_materials.get(MatType.WINDOW);
    // let bottomLineMaterial = matManager.resloc_materials.get(MatType.BOTTOMLINE);
    let parkingLineMaterial = matManager.resloc_materials.get(MatType.PARKINGLINE);

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    let allNodeInformation: eachNode[] = [];

    // const featureGenerator: {[feature: string] : FeatureGenerator} = {
    //     LEVEL: new LevelGenerator(building),
    //     ROOFTOP: new RoofTopGenerator(building),
    //     PILOTI: new PilotiGenerator(building),
    //     LOGO: new LogoGenerator(building),
    //     ROOFLINE: new RoofLineGenerator(building),
    // };

    const featureGenerator: GeneratorType = {
        LEVEL: new LevelGenerator(building),
        ROOFTOP: new RoofTopGenerator(building),
        PILOTI: new PilotiGenerator(building),
        LOGO: new LogoGenerator(building),
        ROOFLINE: new RoofLineGenerator(building)
    };

    // const featureGenerator = new Map<GeneratorType, FeatureGenerator>([
    //     [GeneratorType.LEVEL, new LevelGenerator(building)],
    //     [GeneratorType.ROOFTOP, new RoofTopGenerator(building)],
    //     [GeneratorType.PILOTI, new PilotiGenerator(building)],
    //     [GeneratorType.LOGO, new LogoGenerator(building)],
    // ]);


    //각 층고
    let heights = buildingData.floorHeight;

    let withRoofLine = false;
    buildingData.building.outline.forEach((outline: any) => {
        let buildingGroup = new THREE.Group();

        let nodes = outline.node.data;
        let category = outline.category;
        let cIndex = outline.categoryIndex;
        let group = outline.group;
        let type = outline.type;
        let floor;
        const parapetHeight = buildingData.parapetHeight;

        switch (category) {
            case 'PCT_CORE':
                if (cIndex >= buildingData.floorStatus[group].coreFloorInfo.length) {
                    floor = ["FC_VOID"];
                }
                else {
                    floor = buildingData.floorStatus[group].coreFloorInfo[cIndex].floorCategory;
                }
                break;
            case 'PCT_HOUSE':
                if (cIndex >= buildingData.floorStatus[group].houseFloorInfo.length) {
                    floor = ["FC_VOID"];
                }
                else {
                    floor = buildingData.floorStatus[group].houseFloorInfo[cIndex].floorCategory;
                }
                break;
            default:
                floor = ["FC_VOID"]
                break;
        }

        if (category == "PCT_PARKING") {
            makeParkingLineLevel(buildingMesh, outline.category, outline.type, outline.lineInfo, nodes, heights[0]);
        } else {
            let topFloorIdx = 0;
            for (let i = 0; i < floor.length; i++) {
                switch (floor[i]) {
                    case 'FC_CORE':
                    case 'FC_HOUSE':
                        topFloorIdx = i;
                        break;
                    case 'FC_VOID':
                    case 'FC_PILOTI':
                        break;
                }
            }

            //
            let eachNode: eachNode = {
                node: nodes,
                lineInfo: outline.lineInfo,
                building_height: 0,
                heights: heights,
                buildingGroup: buildingGroup
            };

            let isMultiHouse = true;
            let levelGroup = LevelGenerator.levelGroup();

            for (let i = 0; i < floor.length; i++) {
                //소수점 첫째 자리까지만 나오게 계산
                let levelHeight = Math.round(heights[i] * 10) / 10;

                switch (floor[i]) {
                    case 'FC_CORE':
                    case 'FC_HOUSE':
                        let withRoof = false;
                        if (i === floor.length - 1) {
                            withRoof = true;
                        } else if (i !== floor.length - 1 && floor[i + 1] === 'FC_VOID') {
                            withRoof = true;
                        }

                        if (eachNode.building_height == 0 && !isCadConverter) //기단부 생성
                        {
                            // if (buildingData.parents == null || buildingData.parents.length == 0) {
                            //     makeFoundation(buildingMesh, outline.lineInfo, nodes);
                            // }
                        }

                        //나중에 활용하자
                        // if (isMultiHouse){
                        //     isMultiHouse = !(0 != Object.keys(levelGroup).length && 'Level 1' != Object.values(levelGroup)[0].name);
                        // }

                        let withParapet = false;
                        if (i == topFloorIdx && !isCadConverter
                            //아래 부분은 아파트와 다세대 주택의 구분을 위한 것임
                            && 0 != Object.keys(levelGroup).length && 'Level 1' != Object.values(levelGroup)[0].name) {
                            // let rooftopHeight = 0;

                            if (buildingData.children == null || buildingData.children.length == 0) {

                                if (floor[i] == 'FC_CORE') {
                                    levelHeight += 1;

                                    if (!withRoofLine)
                                        withRoofLine = true;
                                }

                                withParapet = undefined != parapetHeight && 0 != parapetHeight;
                            }
                        }

                        //층별 생성
                        let level_data: DataForLevel = {
                            nodes: [eachNode],
                            level_height: levelHeight,
                            level_type: floor[i],
                            level_index: i,
                            with_roof: withRoof,
                            with_parapet: withParapet,
                            with_roof_line: withRoofLine,
                            top_floor_index: topFloorIdx
                        };
                        featureGenerator.LEVEL.Generate(level_data);

                        //지붕 생성
                        featureGenerator.ROOFTOP.Generate(level_data);

                        eachNode.building_height += levelHeight;
                        ////////////////////////////////////////////
                        break;
                    case 'FC_VOID':
                        break;
                    case 'FC_PILOTI':
                        App.stage !== "prod" && console.log('piloti');

                        let piloti_data: DataForPiloti = {
                            nodes: [eachNode],
                            level_height: levelHeight,
                            level_index: i,
                        };
                        featureGenerator.PILOTI.Generate(piloti_data);

                        eachNode.building_height += levelHeight;
                        break;
                    default:
                        break;
                }
            }

            featureGenerator.LEVEL.Dispose();

            //로고는 건물들이 모두 생성된 후 위치 계산을 해야 하기 때문에 정보만 모두 취합해 놓음
            // if (bkCustomData && 'MULTI_HOUSE' != buildingData.bk_customdata.buildingCategory && 'PCT_HOUSE' == category) {
            if ('PCT_HOUSE' == category) {
                // eachNode.buildingHeight = buildingHeight;

                //아파트만 동번호 추가
                // if ('APARTMENT' == buildingData.bk_customdata.buildingCategory) {
                if (bkCustomData && 'APARTMENT' == buildingData.bk_customdata.buildingCategory) {
                    eachNode.buildingIndex = buildingData.bk_customdata.buildingIndex;
                }
                allNodeInformation.push(eachNode);
            }
            ////////////////////////////////////////////
            //new
            // if ('PCT_HOUSE' == category && 21 == nodes.length)
            // offsetTestGeom.merge(multiOffsetTestFunc(nodes, buildingHeight + 20));  //15078, simplify line 생성 시 연결 각도 공차 설정 해결해야 됨
            ////////////////////////////////////////////
            if (0 != buildingGroup.children.length) {
                buildingGroup.name = 'each building';
                building.add(buildingGroup);
            }
        }
    })

    //roofline 생성
    featureGenerator.ROOFLINE.Generate({
        nodes: allNodeInformation
    });

    //buildit logo 생성
    // if (0 != allNodeInformation.length) {
    //     logoGeneration.Generate(allNodeInformation, building);
    // }
    if (bkCustomData && 0 != allNodeInformation.length && 'MULTI_HOUSE' != buildingData.bk_customdata.buildingCategory) {
        featureGenerator.LOGO.Generate({
            nodes: allNodeInformation
        });
    }

    //파킹라인을 dem 높이에 맞게 프로젝션 시킴
    let parkingLine = new THREE.Geometry();
    for (let i = 0; i < buildingMesh.parkingLine.length - 1; i += 2) {
        let lineGeo = new THREE.Geometry();
        let sph = getDemHeight(demData, new THREE.Vector2(buildingData.position.x + buildingMesh.parkingLine[i].x, buildingData.position.y + buildingMesh.parkingLine[i].y));
        let eph = getDemHeight(demData, new THREE.Vector2(buildingData.position.x + buildingMesh.parkingLine[i + 1].x, buildingData.position.y + buildingMesh.parkingLine[i + 1].y));

        lineGeo.vertices.push(new THREE.Vector3(buildingMesh.parkingLine[i].x, sph - buildingData.position.z + 0.1, buildingMesh.parkingLine[i].y));
        lineGeo.vertices.push(new THREE.Vector3(buildingMesh.parkingLine[i + 1].x, eph - buildingData.position.z + 0.1, buildingMesh.parkingLine[i + 1].y));
        parkingLine.merge(lineGeo);
    }
    building.add(new THREE.LineSegments(parkingLine, parkingLineMaterial));

    //scale
    building.scale.set(0.1, 0.1, -0.1);

    // rotate
    building.rotateY(THREE.MathUtils.degToRad(buildingData.angle));

    //inverse
    if (buildingData.rotStatus === 'INV_X') {
        building.scale.setX(-0.1);
    }

    if (buildingData.rotStatus === 'INV_Y') {
        building.scale.setZ(-0.1);
    }

    if (buildingData.rotStatus === 'INV_XY') {
        building.scale.setX(-0.1);
        building.scale.setZ(-0.1);
    }

    App.stage !== "prod" && console.log(building);
    return building;
}


function MakeBorderBox(building: any, startHeight: number, endHeight: number) {
    let lineGeo = new THREE.Geometry();

    building.building.outline.forEach((l: any) => {
        if (l.type === 'PT_BORDER') {
            let node = l.node.data;
            l.lineInfo.forEach((li: any) => {
                lineGeo.merge(MakeLevelLine(node[li.start], node[li.end], startHeight));
                lineGeo.merge(MakeLevelLine(node[li.start], node[li.end], endHeight));
            })
            node.forEach((n: any) => {
                lineGeo.merge(MakeLine(new THREE.Vector3(n.x, startHeight, n.y), new THREE.Vector3(n.x, endHeight, n.y)));
            });
        }
    })

    return lineGeo;
}

function GetNewPointInLine(p1: THREE.Vector3, p2: THREE.Vector3, newY: number) {
    let t = (newY - p1.y) / (p2.y - p1.y);

    let x = p1.x + t * (p2.x - p1.x);
    let y = p1.y + t * (p2.y - p1.y);
    let z = p1.z + t * (p2.z - p1.z);

    console.log(x, y, z, t);
    return new THREE.Vector3(x, y, z);
}

export function MakeBuildingOuterLine(validMass: any[]) {
    let lineGeo = new THREE.Geometry();

    validMass.forEach(vm => {
        let lowerPoly = vm.lowerPoly;
        lowerPoly.push(lowerPoly[0])
        let upperPoly = vm.upperPoly;
        upperPoly.push(upperPoly[0]);
        let edges = vm.edges;

        for (let i = 0; i < lowerPoly.length - 1; i++) {
            let start = new THREE.Vector3(lowerPoly[i].x, lowerPoly[i].z, lowerPoly[i].y);
            let end = new THREE.Vector3(lowerPoly[i + 1].x, lowerPoly[i + 1].z, lowerPoly[i + 1].y);
            lineGeo.merge(MakeLine(start, end));
        }
        for (let i = 0; i < upperPoly.length - 1; i++) {
            let start = new THREE.Vector3(upperPoly[i].x, upperPoly[i].z, upperPoly[i].y);
            let end = new THREE.Vector3(upperPoly[i + 1].x, upperPoly[i + 1].z, upperPoly[i + 1].y);
            lineGeo.merge(MakeLine(start, end));
        }

        for (let i = 0; i < edges.length; i++) {
            let start = new THREE.Vector3(edges[i].from.x, edges[i].from.z, edges[i].from.y);
            let end = new THREE.Vector3(edges[i].to.x, edges[i].to.z, edges[i].to.y);
            lineGeo.merge(MakeLine(start, end));
        }
    })

    let lineMesh = new THREE.LineSegments(lineGeo, new THREE.LineBasicMaterial({color: 0x000000}));
    lineMesh.scale.set(0.1, 0.1, -0.1);
    return lineMesh;
}
