import React, { Component, Fragment } from "react";
import "./css/Login.scss";
import "./css/BuilditUI.scss";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import { LoginUserRequest } from "@teneleven/protocols-ts-web/lib/db_manager_pb";
import { Theme, createStyles, StyledComponentProps, TextField, FilledInput, CircularProgress } from "@material-ui/core";
import App from "./App";
import { GetUserInfoReply } from "./api";
import { UserKeyJS } from "@teneleven/protocols-ts-web/lib/db";
import { UserJS } from "@teneleven/protocols-ts-web/lib/user";
import { Link } from "react-router-dom";
import Tooltip from "./Tooltip";
import { Help } from "@material-ui/icons";

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import AWS from "aws-sdk";
import BuilditInput from "./BuilditInput";
import EmailConfirm from "./UnconfirmedUser";
import { RegisterInput } from "./RegistrationUser";

export interface LoginProps {
  onInitToken: (token: number) => void;
  fromDownLoadPage?: boolean;
}
export interface LoginState {
  email: string;
  password: string;
  check: boolean;
  errMsg: string;
  inputFocus: boolean;
  loading: boolean;
  mobile: boolean;
}

export default class Login extends Component<LoginProps, LoginState> {
  state: LoginState = {
    email: "",
    password: "",
    check: false,
    errMsg: "",
    inputFocus: false,
    loading: false,
    mobile: screen.availWidth < 1200,
  };

  static self: Login;

  keyPress = (e: any) => {
    if (e.key === "Enter") {
      this.login();
    }
  };
  componentDidMount = () => {
    this.keyPress = this.keyPress.bind(this);
  };

  componentWillMount = () => {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: App.USER_POOL_ID,
      ClientId: App.COGNITO_CLIENT_ID,
    });

    const curCognitoUser = userPool.getCurrentUser()!;
    if (curCognitoUser !== null && !this.props.fromDownLoadPage) {
      // @ts-ignore
      this.props.history.replace("/");
      window.location.href = "/";
    }

    Login.self = this;
    document.addEventListener("keydown", this.keyPress);
    window.localStorage.setItem("keep_signed_in", "true");
  };
  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.keyPress);
  };

  render() {
    if (this.state.mobile) {
      return (
        <div className="Login buildite-template">
          <div className="login-wrapper">
            <div className="login-form">
              <img src="/img/SupportedBrowser.png" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="Login buildit-template">
          {this.state.loading && (
            <div className="dim">
              <CircularProgress className="circular-progress" />
            </div>
          )}
          <div className="login-wrapper">
            <Link to="/landing" style={{ textDecoration: "none" }}>
              <img src="/img/buildit_platform_logo.svg" style={{ height: "25px" }} className="logo" />
            </Link>
            {
              <div className="login-form">
                <div className="header">
                  인공지능 건축설계
                  <br />
                  솔루션 빌드잇
                  <div className="subheader">
                    BUILDIT PLATFORM <span style={{ color: "#ccc" }}> LOGIN </span>
                  </div>
                </div>

                {this.props.fromDownLoadPage && (
                  <Fragment>
                    <div className="download-description">
                      로그인 완료 후 BUILDIT DESIGNER를
                      <br />
                      다운로드하실 수 있습니다.
                    </div>
                  </Fragment>
                )}
                <div className="input-wrap" onBlur={(e) => this.setState({ inputFocus: false })}>
                  <BuilditInput className="login-input" placeholder="이메일 입력" onChange={(e) => this.setState({ email: e as string })} value={this.state.email} autoFocus={true} />
                  <RegisterInput
                    className="login-input"
                    placeholder="비밀번호 입력"
                    onChange={(e) => this.setState({ password: e as string })}
                    type="password"
                    value={this.state.password}
                    error={this.state.errMsg.length > 0}
                    errMsg={this.state.errMsg}
                    capsLockTooltip={true}
                  />
                </div>
                <div className="login-btn-wrap">
                  <Button onClick={this.login} className="bg-navy btn btn-run btn-large login-btn">
                    LOGIN
                  </Button>
                </div>
                <div className="options">
                  <div className="find-btn">
                    <Tooltip msg={"아이디를 분실하신 경우, 고객센터 02-375-1011로 연락 주시기 바랍니다."} className="tooltip" arrowOn={false}>
                      <div>아이디찾기</div>
                      <div className="icon">
                        <Help className="help-icon" />
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className="forgot-btn"
                    onClick={(e) => {
                      // @ts-ignore
                      this.props.history.push("/user/resetPassword");
                    }}
                  >
                    <div>비밀번호 재설정</div>
                  </div>
                </div>
                <div className="register-wrap">
                  <div className="register-text">계정이 없으신가요?</div>
                  <Link to="/user/register">
                    <div className="register-btn">회원가입</div>
                  </Link>
                </div>
              </div>
            }
            <div className="footer">
              <div className="">
                <a target="_blank" href="/termofservice?type=개인%20정보%20처리%20방침">
                  개인정보처리방침
                </a>{" "}
                |{" "}
                <a target="_blank" href="/termofservice?type=서비스%20이용%20약관">
                  이용약관
                </a>
              </div>
              <div className="m-l-a">Copyright © 2019 BUILDIT</div>
            </div>
          </div>
        </div>
      );
    }
  }

  forgotPassword = () => {};

  loginSuccess = (result: AmazonCognitoIdentity.CognitoUserSession) => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: App.IDENTITY_POOL_ID,
      Logins: {
        [`cognito-idp.ap-northeast-2.amazonaws.com/${App.USER_POOL_ID}`]: result.getIdToken().getJwtToken()!,
      },
    });
    this.setState({ loading: false });
    if (this.props.fromDownLoadPage) {
      //window.location.href = "/designerdownload";
      // @ts-ignore
      this.props.history.push("/designerdownload");
    } else {
      // @ts-ignore
      this.props.history.push("/");
      // window.location.href = "/";
    }

    App.self.initSession();
  };
  login = () => {
    this.setState(
      {
        errMsg: "",
        loading: true,
      },
      () => {
        const userPool = new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId: App.USER_POOL_ID,
          ClientId: App.COGNITO_CLIENT_ID,
        });

        // const curCognitoUser = userPool.getCurrentUser()!;
        // console.log(curCognitoUser);

        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
          Username: this.state.email,
          Password: this.state.password,
        });

        var userData = {
          Username: this.state.email,
          Pool: userPool,
        };

        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: this.loginSuccess,
          onFailure: function (err) {
            let errMsg = "";
            switch (err.code) {
              case "UserNotFoundException":
                errMsg = "등록되지 않은 이메일 입니다";
                break;
              case "NotAuthorizedException":
                errMsg = "등록하신 비밀번호와 일치하지 않습니다";
                Login.self.setState({ password: "" });
                break;
              case "UserNotConfirmedException":
                errMsg = "이메일 인증 후, 다시 로그인 해주세요";
                alert("이메일 인증이 되지 않았습니다 \n인증 링크가 이메일로 재전송 됩니다");
                // @ts-ignore
                Login.self.props.history.push(`/user/unconfirmed?email=${Login.self.state.email}`);
                break;
            }
            Login.self.setState({ errMsg: errMsg, loading: false });
          },
        });
      }
    );
  };
}
