
import { Help } from '@material-ui/icons';
import ExitToApp from '@material-ui/icons/ExitToApp';
import React, { Component, ReactText } from 'react';
import { NaverMapManager, NaverLatLng } from './NaverMapManager';
import '../css/CADConverter/MapDownloadModal.scss';
import wkx from 'wkx'
import Axios from 'axios';
import App from '../App';
import { latlng2tm } from './SceneManager';
import * as THREE from '@teneleven/three';
import { Button, InputAdornment, Snackbar, IconButton } from '@material-ui/core';
import { Polygon, Marker } from '../Shape';
import SearchPlaceInput, { PlaceResult, AddressResult, GeocodeResult } from '../SearchPlaceInput';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AWSModule from '../AWSModule';
import { default as _ } from 'lodash';
import DrawingManager2 from '../DrawingManager2';
import proj4 from 'proj4';
import Tooltip from '../Tooltip';
import { DXFMaker, DXFVersion } from './DXFMaker';
import MapDownloadPreview from './MapDownloadPreview';
import { Line2 } from '@teneleven/three/examples/jsm/lines/Line2';
import { LineMaterial } from '@teneleven/three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from '@teneleven/three/examples/jsm/lines/LineGeometry';
import { ReactComponent as CalAreaIcon } from '../img/icon/ic-cal-area.svg';
import * as jsts from 'jsts';

interface MapDownloadModalState {
  isCadastral: boolean;
  searchAddress: string;
  searchFieldFocus: boolean;
  placeResult: Array<PlaceResult>;
  geocodeResult: Array<GeocodeResult>;
  searchPlaceLoading: boolean;
  addressResult: Array<AddressResult>;

  projectSite: Polygon[];
  marker: Marker[];
  rectCoords: Array<any>;
  activeDrawingRect: boolean;
  showPreviewModal: boolean;
  parsingData: any;
  openSnack: boolean;
  activeDownloadBtn: boolean;
  completeDownload: boolean;
  searchResultList: Array<any>;
  mapCenter?: [number, number],
  markerArea: number,
  cadVersion: string,
  fileName: string,
}

interface MapDownloadModalProps {
  isShow: boolean;
  turnOffModal: () => void;
}

class MapDownloadModal extends Component<MapDownloadModalProps, MapDownloadModalState> {
  state: MapDownloadModalState = {
    isCadastral: true,
    searchAddress: "",
    searchFieldFocus: false,
    placeResult: [],
    geocodeResult: [],
    searchPlaceLoading: false,
    addressResult: [],
    projectSite: [],
    marker: [],
    rectCoords: [],
    activeDrawingRect: false,
    showPreviewModal: false,
    parsingData: null,
    openSnack: false,
    activeDownloadBtn: false,
    completeDownload: false,
    searchResultList: [],
    markerArea: 0,
    cadVersion: DXFVersion.AutoCAD2007,
    fileName: "",
  }

  mapManager = new DrawingManager2();
  mesh: any[] = [];
  snackbarMsg: React.ReactNode = <></>;
  snackbarType: string = "normal";
  downLink: React.ReactNode = <></>;
  downloadFileName: string[] = [];
  clickCnt = 0;
  mapRectangle: any;
  rectangleManager: any;

  geometry: {
    field: jsts.geom.Geometry[],
    fieldText: string[],
    roadText: string[],
    road: jsts.geom.Geometry[],
    building: jsts.geom.Geometry[],
    boundary: jsts.geom.Geometry[],
    //    : jsts.geom.Geometry | null = null; 
  } = {
      field: [],
      fieldText: [],
      roadText: [],
      road: [],
      building: [],
      boundary: [],
    }

  boundaryWKT: string = '';


  componentWillMount() {
    this.searchPlace = _.debounce(this.searchPlace, 200);
  }

  componentDidMount = async () => {
    this.mapManager.createMap({
      ref: this.refs.map,
      cadastral: true,
      zoom: 17,
      center: [127.105399, 37.3595704],
      maxZoom: 21,
      transitionOptions: {
        duration: 0,
        easing: "linear",
      },
      tileSpare: 1,
    });
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.setState({
          activeDrawingRect: false,
          openSnack: false,
        });
      }

      if (this.mapRectangle) {
        let obj = Object.keys(this.mapRectangle);//this.mapManager.getPolygonInDrawingManager());
        if (e.key === "Delete" && obj.length > 0) {
          this.clickCnt = 0;
          obj.forEach((key: string) => {
            this.mapRectangle.setMap(null);
            this.state.marker.length && this.state.marker[0].remove();
            this.setState({ activeDownloadBtn: false, openSnack: false });
          })
        }

      }
    }, false);
  }

  searchPlace = async (address: string) => {
    this.setState({
      searchPlaceLoading: true,
      placeResult: [],
      addressResult: [],
    }, async () => {
      const trimmedAddress = address.trim().replace(/ +/g, " ");

      if (trimmedAddress.length > 0) {
        const lambda = await new AWSModule("LAMBDA").connect();

        const resLambda = await lambda.Lambda!.invoke({
          FunctionName: `arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-ProvisionedSearchAddress-${App.tempStage}`,
          Payload: JSON.stringify({
            "searchtype": "all",
            "address": trimmedAddress,
          })
        }).promise();
        const rr = JSON.parse(resLambda.Payload as string);

        if (rr.success) {
          this.setState({
            addressResult: rr.data.addresses,
            placeResult: rr.data.places,
            searchPlaceLoading: false
          })

        }
      }
    })
  }

  mapTypeChanged = (isCadastral: boolean) => {
    this.mapManager.setCadastralLayer(isCadastral);
    this.setState({
      isCadastral: isCadastral
    })
  }

  selectPNU = async (coord: { x: number, y: number }, mapCoord: { x: number, y: number }) => {
    const r = await App.API_Axios.post(`/GetFieldInfoAtPoint`, {
      ...coord,
    });

    const payload = {
      success: true,
      body: r.data,
      reason: null,
    };

    if (payload.success) {
      let geom = payload.body.geom;
      const polygon = this.mapManager.addPolygon(DrawingManager2.toGeom(geom, "Field").coordinates, { ...DrawingManager2.DrawingOption.PROJECT_SITE, simplify: false }, false);

      if (this.mapRectangle)
        this.mapRectangle.setMap(null);

      // // 주소 검색 후 필지 표시
      // let polygon = this.mapManager.addPolygon(NaverMapManager.toGeom(geom, "Field").coordinates, { ...DrawingManager2.DrawingOption.CAD_PROJECT_SITE, simplify: false });
      if (this.state.projectSite.length >= 1) { // 지도에 폴리곤이 있으면 삭제
        // this.mapManager.clearAllPolygon();
        this.state.projectSite[0].remove();

        this.mapManager.clearDrawingManager();
        //        this.rectangleManager.setMap(null);
        this.state.marker.length && this.state.marker[0].remove();

        this.setState({
          projectSite: [],
          marker: []
        });
      }

      if (this.state.marker.length) this.state.marker[0].remove();

      this.setState({
        projectSite: [polygon]
      })

      const markerOptions = _.cloneDeep(DrawingManager2.DrawingOption.CAD_MAP_MARKER);

      markerOptions.icon.content =
        `
      <div class="marker-wrapper">
        <div class="marker" >
          <div class="icon-wrapper">
            <img class="place-icon" src="/img/icon/place.png"/>
          </div>
          <span class="text">${payload.body.address}</span>
        </div>
        <div class="tail"></div>
      </div>
      `;

      const newMarker = this.mapManager!.addMarker([mapCoord.x, mapCoord.y], {
        ...markerOptions,

        icon: {
          content: markerOptions.icon.content,
          anchor: { x: 16, y: 43 },
        }
      }, false);

      this.setState({ marker: [newMarker] });
    }
  }

  makeDXFCAD = async () => {

    let polygons = this.rectangleManager.getDrawings();
    let polygonCAD: Array<any> = [];
    for (let polygon of Object.values(polygons)) {
      let bounds = (polygon as any).bounds;
      let leftTopTM = latlng2tm(new THREE.Vector2(bounds._min.x, bounds._min.y));
      let boxWidth = leftTopTM.distanceTo(latlng2tm(new THREE.Vector2(bounds._max.x, bounds._min.y)));
      let boxHeight = leftTopTM.distanceTo(latlng2tm(new THREE.Vector2(bounds._min.x, bounds._max.y)));
      // let latlng2tm
      let coords: any[] = [];
      coords.push([leftTopTM.x, leftTopTM.y]);
      coords.push([leftTopTM.x, leftTopTM.y + boxHeight]);
      coords.push([leftTopTM.x + boxWidth, leftTopTM.y + boxHeight]);
      coords.push([leftTopTM.x + boxWidth, leftTopTM.y]);
      coords.push([leftTopTM.x, leftTopTM.y]);
      let geoJson = {
        type: 'Polygon',
        coordinates: [coords],
      }
      let wkt = wkx.Geometry.parseGeoJSON(geoJson).toWkt();

      const r = await App.API_Axios.post('/GetFieldAndBuildingsAtWKT', {
        'rectWKT': wkt,
      }, {
        headers: {
          ...App.headers,
        },
      });
      this.boundaryWKT = wkt;
      const reader = new jsts.io.WKTReader();
      const boundary = reader.read(wkt);
      let data = await Axios.get(r.data.url);
      if (data.status === 200) {
        this.geometry = {
          field: [],
          road: [],
          boundary: [],
          building: [],
          fieldText: [],
          roadText: []
        }

        data.data.fieldItems.forEach((field: any) => {

          // field.지목
          if (field.토지이용상황 !== '도로등') {
            try {
              // let geom = dxfMaker!.AddPolyline(field.geom, field.지번);
              let geo = reader.read(field.geom).intersection(boundary);
              if (geo) {
                polygonCAD.push([geo, 'field', new THREE.Color().set("#666666"), field.지번]); // index color: 252
                this.geometry.field.push(geo);
                this.geometry.fieldText.push(field.지번);
              }
            }
            catch {
            }
          }
          else {
            //     let geom = dxfMaker!.AddRoadPolyline(field.geom, field.지번);
            try {
              let geo = reader.read(field.geom).intersection(boundary);
              if (geo) {
                polygonCAD.push([geo, 'road', new THREE.Color().set("#999999")]); // index color: 253
                this.geometry.road.push(geo);
                this.geometry.roadText.push(field.지번);
              }
            }
            catch { }
          }
        })


        data.data.buildingItems.forEach((building: any) => {
          //  let geom = dxfMaker.AddBuildingPolyline(building.geom);
          try {
            let geo = reader.read(building.geom).intersection(boundary);
            if (geo) {
              this.geometry.building.push(geo);
              polygonCAD.push([geo, 'building', new THREE.Color().set("#666666")]); // index color: 252
            }
          } catch (e) {
          }
        });
        // boundary
        try {
          let geo = reader.read(wkt).intersection(boundary);
          if (geo) {
            this.geometry.boundary.push(geo);
            polygonCAD.push([geo, 'boundary', new THREE.Color().set("#ffffff")]); // index color: 255
          }
        } catch (e) {
        }

        let splited = this.state.fileName.split('-')[0];
        if (!splited.endsWith("일대")) {
          this.setState({ fileName: `${splited}일대` })
        }
        else {
          this.setState({ fileName: `${splited}` })
        }
      }
    }

    let meshs: any[] = [];
    if (polygonCAD.length >= 1)
      //@ts-ignore
      polygonCAD.forEach(polygon => {
        meshs.push(this.makeLineMesh(polygon));
      })

    return meshs;
  }


  makeDXFFile = () => {
    let dxfMaker = new DXFMaker(this.boundaryWKT, this.state.cadVersion);

    this.geometry.field.forEach((geom, i) => { // field 와 fieldtext 길이 같은지 확인 필요
      dxfMaker.AddPolyline(geom, this.geometry.fieldText[i]);
    });
    // let geom = dxfMaker!.AddPolyline(field.geom, field.지번);

    this.geometry.road.forEach((geom, i) => { // road 와 fieldtext 길이 같은지 확인 필요
      dxfMaker.AddPolyline(geom, this.geometry.roadText[i]);
    });
    //  let geom = dxfMaker!.AddRoadPolyline(field.geom, field.지번);

    this.geometry.building.forEach(geom => dxfMaker.AddBuildingPolyline(geom));
    //  let geom = dxfMaker.AddBuildingPolyline(building.geom);

    //!this.geometry.boundary.forEach(geom => dxfMaker.AddPolyline(geom))
    //    let geom = dxfMaker.AddPolyline();

    let link = dxfMaker.DownloadDXF(this.state.fileName); // param: 파일이름

    link.click();
    //    this.downLink = link;
  }




  makeLineMesh(data: Array<any>) { // 지적도 다운로드 파일 미리보기 생성
    let type = data[1];
    let color: THREE.Color = data[2];
    let landNumber: string = "";//지번

    let coords = data[0].getCoordinates().map((v: any) =>
      new THREE.Vector3(v.x, v.y, 0)
    );
    let vertices: THREE.Vector3[] = coords;
    let turfVerts = new Array<number[]>();

    vertices.forEach(v => {
      turfVerts.push([v.x, v.y]);
    });

    let meshVerts: THREE.Vector3[] = [];
    vertices.forEach(v => {
      meshVerts.push(v.clone());
    });

    let vertsGeo = new Array<number>();
    let colorGeo = new Array<number>();

    meshVerts.forEach(v => {
      vertsGeo.push(v.x, v.y, v.z);
      colorGeo.push(1, 1, 1);
    })

    let geometry = new LineGeometry();
    geometry.setPositions(vertsGeo);
    geometry.setColors(colorGeo);

    let matLine = new LineMaterial({
      linewidth: 4, // in pixels
      vertexColors: true,
      dashed: false,
      dashSize: 1,
      gapSize: 1,
      dashScale: 2,
    });

    matLine.resolution.set(window.innerWidth, window.innerHeight);
    matLine.transparent = true;

    let line = new Line2(geometry, matLine).computeLineDistances();

    // //  switchLineDashedState(matLine, matLine.dashed);
    //@ts-ignore
    line.material.color = color;
    line.renderOrder = -1;

    return ({
      lineMesh: line,
      verts: vertices,
    })
  }

  turnOffPreviewModal() {

    this.setState({ showPreviewModal: false });

    if (this.state.completeDownload) { // 지적도 저장이됐으면      
      this.snackbarType = 'normal';
      this.snackbarMsg = <><b className="font font-special">선택 영역 저장이 완료</b>되었습니다.</>;
      let obj = Object.keys(this.rectangleManager.getDrawings());//.getPolygonInDrawingManager());
      obj.forEach((key: string) => {
        this.mapRectangle.setMap(null);
        this.state.marker.length && this.state.marker[0].remove();
        this.mapManager.clearDrawingManager();//.drawingMananger.removeDrawing(key);
      })
      this.setState({
        openSnack: true,
        activeDownloadBtn: false
      })
    }
  }

  handleCompleteDownload() {
    this.setState({ completeDownload: true }, () => {
      this.turnOffPreviewModal();
      this.setState({ completeDownload: false });
    })
  }

  moveToAddress = (lat: number, lng: number) => {
    
    
    this.setState({ mapCenter: [lng, lat] }, () => {
      this.mapManager!.map!.nMap.setCenter([lng, lat]);
    })
  }

  setMapFileName = (coords: any) => {

    //파일 이름 
    // coords = rect.getBounds();

    let x = (coords._max.x + coords._min.x) / 2;
    let y = (coords._min.y + coords._max.y) / 2;

    App.naver.maps.Service.reverseGeocode({
      location: new App.naver.maps.LatLng(y, x),
    }, (status: any, response: any) => {
      if (status !== App.naver.maps.Service.Status.OK) {
        return "파일 이름";//alert('Something wrong!');
      }
      let result = response.result, // 검색 결과의 컨테이너
        items = result.items; // 검색 결과의 배열

      this.setState({ fileName: items[0].address });
      //        this.downloadFileName.push(items[0].address);
    });
    //
  }


  checkMapDownloadable = async (rect: any) => {
    let bounds = rect.getBounds();
    let center = { x: (bounds._max.x + bounds._min.x) / 2, y: (bounds._max.y + bounds._min.y) / 2 }
    // TODO 마커위
    this.setState({ markerArea: rect.getAreaSize() })

    if (this.state.marker.length >= 1) {
      this.state.marker[0].setPosition([bounds._max.x, bounds._min.y]);
      this.state.marker[0].setIcon({
        content: `<div className="area-wrap font font-12px"
                    style="
                      width: max-content;
                      height: 30px;
                      background-color: #000;
                      display: flex;
                      padding: 8px 10px;
                      box-sizing: border-box;;
                      justify-content: center;
                      align-items: center;
                    ">
                    <span className="key" style="color: #CCC; margin-right: 10px;">면적</span>
                    <span className="value" style="color: #eee;">${Number(this.state.markerArea.toFixed(2)).toLocaleString()}㎡</span>
                  </div>`,
        anchor: { x: -10, y: 30 }
      });
    }

    if (rect.getAreaSize() <= 3000000) {
      this.snackbarMsg = <>다운로드 <b className="font font-special">가능한 영역</b>입니다.</>;
      this.setState({
        openSnack: true,
        activeDownloadBtn: true
      });
      this.setMapFileName(bounds);
    }
    else {
      this.snackbarType = "error";
      this.snackbarMsg = <>다운로드 <b className="font font-error">불가능한 영역</b>입니다. 3,000,000m² 이하의 영역을 지정해주세요.</>;
      this.setState({
        openSnack: true,
        activeDownloadBtn: false,
      });
    }
  }

  handleSnackClose = () => {
    this.setState({ openSnack: false });
  }

  render() {
    return (
      <div className={`MapDownloadModal ${this.props.isShow ? "" : "hidden"}`}>
        <MapDownloadPreview
          dxf={this.state.parsingData}
          showModal={this.state.showPreviewModal}
          handleShow={() => this.turnOffPreviewModal()}
          makeDXFFile={() => { this.makeDXFFile(); }}
          downloadFileName={this.state.fileName}
          changeFileName={(name: string) => this.setState({fileName: name})}
          onCompleteDownload={() => this.handleCompleteDownload()}
          clickCnt={this.clickCnt}
          setCadVersion={(version: string) => {
            this.setState({ cadVersion: version });
          }}
        />
        <div className="modal-content bgc-navy">
          <nav className="nav-bar">
            <div className="left-wrap">
              <div className="title">
                지적도 다운로드
                <Tooltip
                  arrowOn={false}
                  msg={<>사업대상지를 포함한 지정 영역을 dxf로 다운로드 할 수 있습니다.<br />
                    주소를 검색 후, 오른쪽의 '선택 영역 지정' 버튼으로 영역을 지정해주세요.</>}>
                  <Help className="icon help-icon" />
                </Tooltip>
              </div>
            </div>
            <div className="right-wrap">
              <Button className={`btn bg-navy btn-run save-btn ${this.state.activeDownloadBtn ? '' : "disabled"}`} onClick={async () => {
                // 테두리가 변경됐는지여부에 따라 makeDXFFile호출
                this.setState({ showPreviewModal: true });
                this.clickCnt += 1;
                this.setState({ parsingData: await this.makeDXFCAD() });
              }}>선택 영역 저장</Button>
              <Tooltip msg="나가기" arrowOn={false}>
                <Button className="btn bg-navy btn-primary exit-btn" onClick={() => {
                  this.props.turnOffModal()
                }}><ExitToApp className="icon exit-icon" />
                </Button>
              </Tooltip>
            </div>
          </nav>


          <section>
            <Snackbar
              autoHideDuration={5000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.openSnack}
              onClose={(e) => { // 다른 곳을 클릭해도 snackbar가 close되지 않음 
                if (e === null) this.setState({ openSnack: false });
              }} //this.handleSnackClose }}  
              className={`snack-bar ${this.snackbarType}`}
              ContentProps={{ 'aria-describedby': 'message-id' }}
              message={<div className="msg font font-pretendard">{this.snackbarMsg}</div>}
            />

            <div className="search-address">
              <SearchPlaceInput
                placeholder="주소 입력"
                className="input"
                onFocus={() => this.setState({ searchFieldFocus: true }, () => this.searchPlace(this.state.searchAddress))}
                onBlur={() => { this.setState({ searchFieldFocus: false }) }}
                value={this.state.searchAddress}
                placeResult={this.state.placeResult}
                geocodeResult={this.state.geocodeResult}
                addressResult={this.state.addressResult}
                loading={this.state.searchPlaceLoading}
                moveToAddress={this.moveToAddress}
                initiateResult={() => { }}
                onSelectItem={(center: { lng: number, lat: number }, isLand: boolean) => {
                  
                  // if (this.state.activeDrawingRect) {
                  //   //TODO
                  //   this.setState({
                  //     activeDrawingRect: false,
                  //   })
                  //   console.log(this.rectangleManager, 'manager');
                  
                  //   //   this.rectangleManager.destroy();
                  // }

                  if (this.mapManager.map!.nMap.getZoom() < 18) {
                    this.mapManager.map!.nMap.setZoom(18, true);
                  }

                
                  this.mapManager.map!.nMap.setCenter([center.lng, center.lat]);
                  this.setState({
                    addressResult: [],
                    geocodeResult: [],
                    placeResult: [],
                    searchAddress: "",
                  })
                
                  if (isLand) {
                    const coord = proj4(DrawingManager2.nProj, DrawingManager2.bProj, [center.lng, center.lat]);
                    this.selectPNU({ x: coord[0], y: coord[1] }, { x: center.lng, y: center.lat });  
                  }
                }}
                type="text"
                onChange={(e: ReactText) => {
                  this.setState({ searchAddress: e as string }, _.debounce(() => {
                    this.searchPlace(this.state.searchAddress);
                  }, 100))
                }}
                endAdornment={<InputAdornment position="end">
                  {
                    this.state.searchAddress.length > 0 &&
                    <IconButton className="icon-btn close-btn" disableRipple={true} onClick={e => {
                      this.setState({ searchAddress: "" })
                    }}>
                      <CloseIcon className="icon" />
                    </IconButton>
                  }
                  <IconButton className="icon-btn search-btn" disableRipple={true} onClick={e => {
                    this.searchPlace(this.state.searchAddress);
                  }}>
                    <SearchIcon className="icon" />
                  </IconButton>

                </InputAdornment>}
              />
            </div>
            <div className="rightButtons">
              <div className={`switchTabs`}>
                <Button className={`switchButton font font-pretendard ${this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(true)}>지적도</Button>
                <Button className={`switchButton font font-pretendard ${!this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(false)}>지도</Button>
              </div>
            </div>
            <div className="drawing-options">
              <Tooltip className=" draw-square" msg="선택 영역 지정" place="bottom" arrowOn={false}>
                <Button disableRipple={true} className={`btn bg-navy btn-primary ${this.state.activeDrawingRect ? 'active' : ''}`} onClick={
                  () => {
                    this.snackbarType = "normal";
                    this.snackbarMsg = <><b className="font font-special">마우스 좌클릭</b>으로 다운받을 영역을 선택해주세요.</>;
                    this.setState({
                      activeDrawingRect: true,
                      openSnack: true,
                    }, () => {
                      if (this.mapRectangle) {
                        this.mapRectangle.setMap(null);
                      }
                      if (this.rectangleManager) {
                        this.rectangleManager.destroy();
                      }
                      this.rectangleManager = new App.naver.maps.drawing.DrawingManager({
                        drawingMode: 1,
                        drawingControl: [App.naver.maps.drawing.DrawingMode.RECTANGLE],
                        map: this.mapManager.map!.nMap,
                        drawingControlOptions: {
                          position: App.naver.maps.Position.TOP_RIGHT,
                          style: App.naver.maps.drawing.DrawingStyle.HORIZONTAL_2,
                        },
                        rectangleOptions: DrawingManager2.DrawingOption.CAD_PROJECT_SITE_SQUARE,
                      });

                      if (this.state.marker.length >= 1) {
                        this.state.marker[0].remove();
                      }

                      this.rectangleManager.addListener("rectangleAdded", (e: any) => {
                        this.clickCnt = 0;
                        this.mapRectangle = e;

                        if (this.state.projectSite.length >= 1) { // 지도에 폴리곤이 있으면 삭제
                          this.mapManager.clearDrawingManager();//clearAllPolygon();
                          this.state.projectSite[0].remove();
                          this.state.marker.length && this.state.marker[0].remove();
                          this.setState({
                            projectSite: [],
                            marker: [],
                          });
                        }

                        if (this.state.marker.length >= 1) {
                          this.state.marker[0].remove();
                        }

                        e.addListener('bounds_changed', _.debounce(() => { // 도형 수정시
                          this.checkMapDownloadable(e);
                          this.clickCnt = 0;
                        }), 500);

                        let coords;
                        Object.values(this.rectangleManager.getDrawings()).forEach((rect: any) => {
                          // 면적 제한 , 파일 이름
                          coords = rect.getBounds();
                          this.setMapFileName(coords);
                          this.checkMapDownloadable(rect);
                          this.setState({
                            activeDrawingRect: false,
                          });

                          this.setState({ markerArea: rect.getAreaSize() })
                          const markerOptions = _.cloneDeep(DrawingManager2.DrawingOption.CAL_MARKER);
                          markerOptions.icon.content =
                            `<div className="area-wrap font font-12px"
                              style="
                                width: max-content;
                                height: 30px;
                                background-color: #000;
                                display: flex;
                                padding: 8px 10px;
                                box-sizing: border-box;;
                                justify-content: center;
                                align-items: center;
                              ">
                              <span className="key" style="color: #CCC; margin-right: 10px;">면적</span>
                              <span className="value" style="color: #eee;">${Number(this.state.markerArea.toFixed(2)).toLocaleString()}㎡</span>
                            </div>`;

                          //@ts-ignore
                          const newMarker = this.mapManager.addMarker([coords._max.x, coords._min.y], {
                            ...markerOptions,
                            icon: {
                              content: markerOptions.icon.content,
                              anchor: { x: -10, y: 30 }
                            }
                          }, false);
                          this.setState({ marker: [newMarker] });
                        })
                      })

                      this.rectangleManager.addListener("rectangleRemoved", () => {
                        this.state.marker.length && this.state.marker[0].remove();
                        this.setState({
                          marker: [],
                          openSnack: false,
                        })
                        this.setState({ activeDownloadBtn: false });
                        this.clickCnt = 0;
                      });

                    })
                  }}>
                  <CalAreaIcon className={`cal-area-icon  ${this.state.activeDrawingRect ? 'active' : ''}`} />
                </Button>
              </Tooltip>
            </div>
            <div ref="map" style={{ width: `1280px`, height: `756px` }} />
          </section>
        </div>
      </div>
    );
  }
}

export default MapDownloadModal;