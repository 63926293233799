import React, { Component, Fragment } from 'react';
import './css/ResetPassword.scss';
import { FilledInput, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import App from './App';
import BuilditInput, { InputAttr } from './BuilditInput';
import { RegisterInput } from './RegistrationUser';
import { default as _ } from 'lodash';
import juqery from 'jquery';
import Modal, { ModalOptions } from './Modal';
const $ = juqery;

export interface ResetPasswordProps {

}
export interface ResetPasswordState {
  email: string;
  code: string;
  newPassword: string;
  newPasswordRepeat: string;
  isSending: boolean;
  passwordRequiredList: Array<{key: string, checked: boolean, regex: RegExp}>,
  emailInput: InputAttr;
  codeInput: InputAttr;
  passwordInput: InputAttr;
  repeatPasswordInput: InputAttr,
  modal: {
    open: boolean,
    option?: ModalOptions,
  },
}

export default class ResetPassword extends Component<ResetPasswordProps, ResetPasswordState> {
  state: ResetPasswordState = {
    email: "",
    code: "",
    newPassword: "",
    newPasswordRepeat: "",
    isSending: false,
    passwordRequiredList: [
      {key: "대문자", checked: false, regex: /[A-Z]/},
      {key: "소문자", checked: false, regex: /[a-z]/},
      {key: "숫자", checked: false, regex: /[0-9]/},
      {key: "특수문자", checked: false, regex: /[!@#$%^\&\*\(\)\-\+]/},
      {key: "8글자이상", checked: false, regex: /.{8}/},
    ],
    emailInput: {
      requiredCheck: false,
      error: false,
    },
    codeInput: {
      requiredCheck: false,
      error: false,
    },
    passwordInput: {
      requiredCheck: false,
      error: false,
    },
    repeatPasswordInput: {
      requiredCheck: false,
      error: false,
    },
    modal: {
      open: false,
    },
  }

  componentWillMount = () => {
    document.addEventListener('keydown', this.keyPress);
  }
  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keyPress);
  }
  keyPress = (e: any) => {
    if (e.key === "Enter") {
      if (!this.state.isSending) {
        this.resendLink();
      } else {
        this.resetPassword();
      }
    }
  }

  componentDidUpdate = (pp: Readonly<ResetPasswordState>, ps: Readonly<ResetPasswordState>) => {
    if (!_.isEqual(this.state.newPassword, ps.newPassword)) {
      this.setState({
        passwordRequiredList: this.state.passwordRequiredList.map(v => {
          v.checked = Boolean(this.state.newPassword.match(v.regex));
          return v;
        }),
      })
    }
  }

  render () {
    return (
      <div className="buildit-template ResetPassword">
        <div className="reset-password-wrapper">
          <Link to="/login" style={{textDecoration: "none"}}>
            <img src="/img/buildit_platform_logo.svg" style={{height: "25px"}} className="logo" />
          </Link>
          <div className="reset-form">
            <div className="header">
              비밀번호 재설정
            </div>
            <div className="input-wrap">
              {
                this.state.isSending && 
                <Fragment>
                {
                  <div className="info">
                    <span className="email">{this.state.email}</span><br />
                    위 메일로 재설정 링크를 보냈습니다
                  </div>
                }
                  <RegisterInput
                    id="register-code"
                    autoFocus={true}
                    value={this.state.code}
                    onChange={e => this.setState({ 
                      code: e.toString(),
                      codeInput: {
                        error: false,
                        msg: undefined,
                        requiredCheck: this.state.code.length === 6
                      }
                    })}
                    className="input"
                    placeholder="RESET CODE 입력 (6자리 숫자)"
                    length={6}
                    pattern="^[0-9]{0,6}$"
                    error={this.state.codeInput.error}
                    errMsg={this.state.codeInput.msg}
                    onBlur={() => {
                      this.setState({
                        codeInput: {
                          error: this.state.code.length > 0 && this.state.code.length !== 6,
                          requiredCheck: this.state.code.length === 6,
                          msg: this.state.code.length > 0 && this.state.code.length !== 6 && "코드를 입력해주세요 (6자리 숫자)" || undefined
                        }
                      })
                    }}
                  />
                  <RegisterInput
                    id="register-new-password"
                    className="register-input input"
                    onChange={(e: React.ReactText) => this.setState({ 
                      newPassword: e as string,
                      passwordInput: {
                        error: false,
                      }
                    })}
                    onBlur={this.checkPassword}
                    value={this.state.newPassword} placeholder="새 비밀번호"
                    required={true} type="password" requiredList={this.state.passwordRequiredList}
                    error={this.state.passwordInput.error}
                    requiredCheck={
                      this.state.passwordRequiredList && this.state.passwordRequiredList.length > 0 &&
                      this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0
                    }
                    capsLockTooltip={true}
                  />
                  <RegisterInput
                  id="register-new-password-repeat"
                    className="register-input input"
                    onChange={(e: React.ReactText) => this.setState({
                      newPasswordRepeat: e as string,
                      repeatPasswordInput: {
                        error: false,
                        msg: undefined,
                        requiredCheck: false
                      }
                    })}
                    value={this.state.newPasswordRepeat} placeholder="새 비밀번호 확인" 
                    required={true} type="password"
                    requiredCheck={
                      this.state.passwordRequiredList && this.state.passwordRequiredList.length > 0 &&
                      this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0 &&
                      this.state.newPassword === this.state.newPasswordRepeat
                    }
                    error={this.state.repeatPasswordInput.error}
                    errMsg={this.state.repeatPasswordInput.msg}
                    onBlur={this.checkRepeatPassword}
                    capsLockTooltip={true}
                  />
                </Fragment> ||
                <Fragment>
                  <BuilditInput
                    autoFocus={true}
                    value={this.state.email}
                    type="text"
                    onChange={e => this.setState({ 
                      email: e.toString(),
                      emailInput: {
                        error: false,
                        msg: undefined,
                      }
                    })}
                    placeholder="이메일 입력"
                    id="reset-password-email"
                    error={this.state.emailInput.error}
                    errorMsg={this.state.emailInput.msg}
                  />
                </Fragment>
              }
            </div>
            <div className="btn-wrap">
              {
                this.state.isSending &&
                <Button disableRipple={true} className="bg-navy btn btn-run btn-large"
                  onClick={this.resetPassword}
                >
                  변경하기
                </Button> ||
                <Button disableRipple={true} className="bg-navy btn btn-run btn-large"
                  onClick={this.resendLink}
                >
                  재설정 링크 보내기
                </Button>
              }
              
            </div>
          </div>
        </div>
        {
          this.state.modal.open &&
          <Modal
            open={this.state.modal.open}
            color="DARK"
            type="NORMAL"
            positive={this.state.modal.option && this.state.modal.option.positive}
            negative={this.state.modal.option && this.state.modal.option.negative}
            title={this.state.modal.option && this.state.modal.option.title}
            negativeTitle={this.state.modal.option && this.state.modal.option.negativeTitle}
            positiveTitle={this.state.modal.option && this.state.modal.option.positiveTitle}
            content={this.state.modal.option && this.state.modal.option.content}
          />
        }
      </div>
    )
  }

  resetPassword = () => {

    if (this.state.code.length !== 6) {
      this.setState({ 
        codeInput: {
          error: true,
          msg: "코드를 입력해주세요 (6자리 숫자)"
        }
      })
      $('#register-code').focus();
      return;
    }

    if (!this.checkPassword()) {
      this.setState({
        passwordInput: {
          error: true,
        }
      })
      $('#register-new-password').focus();
      return;
    }

    if (!this.checkRepeatPassword()) { 
      this.setState({
        repeatPasswordInput: {
          error: true,
          msg: "입력한 비밀번호가 일치하지 않습니다"
        }
      })
      $('#register-new-password-repeat').focus();
      return;
    }

    const poolData = {
      UserPoolId: App.USER_POOL_ID,
      ClientId: App.COGNITO_CLIENT_ID,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Pool: userPool,
      Username: this.state.email,
    });

    cognitoUser.confirmPassword(this.state.code, this.state.newPassword, {
      onSuccess: () => {

        this.setModal(true, {
          negative: "hidden",
          positive: () => { 
            // @ts-ignore
            this.props.history.push('/login');
          },
          title: "비밀번호 변경 완료",
          content: <div>
            비밀번호가 성공적으로 변경되었습니다. <br/><br />
            확인을 누르시면 로그인 페이지로 이동합니다.
          </div>
        })
      },
      onFailure: (err: any) => {
        let msg = "";
        switch(err.code) {
          case 'LimitExceededException':
            msg = "잠시후 다시 시도해주세요";
            alert(msg);
            break;
          case 'InvalidParameterException':
            msg = "비밀번호를 조건을 확인 해주세요";
            alert(msg);
            break;
          case "CodeMismatchException":
            this.setState({ 
              codeInput: {
                error: true,
                msg: "인증코드가 일치하지 않습니다 (6자리 숫자)"
              }
            })
            break;
          default:
            msg = "예기치 못한 오류"
            console.log(err);
            alert(msg);
            break;
        }
      },
    });
  }

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      modal: {
        open: open,
        option: options
      }
    })
  }

  resendLink = () => {
    const emailReg = new RegExp(/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i);
    if (!Boolean(this.state.email.match(emailReg))) {
      this.setState({
        emailInput: {
          error: true,
          msg: "이메일 형식을 지켜주세요"
        }
      })
      return;
    }
    

    const poolData = {
      UserPoolId: App.USER_POOL_ID,
      ClientId: App.COGNITO_CLIENT_ID,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Pool: userPool,
      Username: this.state.email,
    });

    cognitoUser.forgotPassword({
      onSuccess: () => {
        this.setState({ isSending: true })
      },
      onFailure: (err: any) => {
        let msg = "";
        switch(err.code) {
          case "LimitExceededException":
            msg = "잠시 후 다시 시도 해주세요";
            alert(msg);
            break;
          case "UserNotFoundException":
            this.setState({
              emailInput: {
                error: true,
                msg: "등록되지 않은 이메일입니다"
              }
            })
            break;
          case "InvalidParameterException":
            alert("이메일 인증이 되지 않은 계정은 비밀번호를 변경하실 수 없습니다 \n인증 링크가 이메일로 재전송 됩니다");
            // @ts-ignore
            this.props.history.push(`/user/unconfirmed?email=${this.state.email}`);
            break;
          default: 
            msg = "예기치 못한 오류";
            
            alert(msg);
            break;
        }
      },
    })
  }
  checkPassword = () => {
    if (this.state.newPassword.length === 0) {
      return false;
    }

    if (this.state.passwordRequiredList.findIndex(e => e.checked === false) > -1) {
      this.setState({
        passwordInput: {
          error: true,
          requiredCheck: false,
        }
      })
      return false;
    } else {
      return true;
    }
  }
  checkRepeatPassword = () => {
    
    if (this.state.newPasswordRepeat.length === 0) {
      return false;
    }

    if (this.state.passwordRequiredList.findIndex(e => e.checked === false) > -1) {
      return false;
    }


    let error = true;
    if (this.state.passwordRequiredList.findIndex(e => e.checked === false) < 0 && this.state.newPassword === this.state.newPasswordRepeat) {
      error = false;
    }

    this.setState({
      repeatPasswordInput: {
        error: error,
        requiredCheck: !error,
        msg: error && "입력한 비밀번호가 일치하지 않습니다" || undefined
      }
    })
    return !error;
  }
}