import React, { Component } from 'react';
import { SceneManager } from './SceneManager';
import * as THREE from '@teneleven/three';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { GpsFixed } from '@material-ui/icons';
import BuilditInput from '../BuilditInput';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { OrbitControls } from '@teneleven/three/examples/jsm/controls/OrbitControls'





interface NavigatorProps {
  polygon2DGroup: THREE.Group,
  loadFile: boolean,
  cameraZoom: number,
  mapZoom: number,
  controls: OrbitControls,
  mainCamPos: THREE.Vector3,
  mapCenter: THREE.Vector3,
  isCad: boolean,
  canvasOffset: {
    width: number,
    height: number,
  },
  sceneTopPosition: THREE.Vector3,
}
interface NavigatorState {
  showNavigator: boolean;
}

class CADNavigator extends Component<NavigatorProps, NavigatorState> {
  state: NavigatorState = {
    showNavigator: true,
  }

  navigator: HTMLDivElement | null = null;
  sceneManager = new SceneManager();
  bbox = new THREE.Box3();
  mouse = new THREE.Vector2();
  canvasPosition = { left: 0, top: 0 };

  componentDidMount() {
    this.sceneManager.renderer.setSize(320, 285);
    this.sceneManager.renderer.sortObjects = false;
    this.bbox.setFromObject(this.props.polygon2DGroup);
    let center = new THREE.Vector3();
    this.bbox.getCenter(center);

    let aspect = 320 / 285;
    let bboxSize = new THREE.Vector3(0);
    this.bbox.getSize(bboxSize);
    let frustumSize = bboxSize.x / 2 * 1.1;
    if (aspect > bboxSize.x / bboxSize.y) {
      let height = bboxSize.y / 2 * 1.1;
      frustumSize = height * aspect;
    }

    this.sceneManager.CameraFrustumResize(frustumSize, aspect);
    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;
    this.sceneManager.canvasElement.width = 320;
    this.sceneManager.canvasElement.height = 285;
    this.sceneManager.orthoCamera.updateProjectionMatrix();
    
    this.sceneManager.orthoControl.screenSpacePanning = true;

    this.sceneManager.getControl().enableKeys = false;
    this.sceneManager.canvasElement.style.pointerEvents = 'none';

    this.sceneManager.addObjectToScene(this.props.polygon2DGroup.clone());

    this.animate();
    this.navigator!.appendChild(this.sceneManager.canvasElement);
    
    window.addEventListener('resize', this.setRectPosition, false);
    this.setRectPosition();
  }

  setRectPosition = () => {    
    const position = this.navigator!.getBoundingClientRect();
    
    this.canvasPosition = {
      left: position.left,
      top: position.top,
    }
  }

  componentDidUpdate(preProps: NavigatorProps, preState: NavigatorState) {
    if (this.props.isCad && (this.props.cameraZoom !== preProps.cameraZoom || this.props.isCad !== preProps.isCad || this.state.showNavigator !== preState.showNavigator)) {
      let rectElement = document.querySelector(`.navigator-rectangle`) as HTMLInputElement;

      if (rectElement && this.state.showNavigator) {

        const zoom = this.props.cameraZoom;

        if (this.props.isCad && zoom <= 1) {
          rectElement.style.width = '314px';
          rectElement.style.height = '279px';
        }
        else {
          let newWidth = 314 - (zoom * 49);
          const ASPECT = this.props.canvasOffset.height / this.props.canvasOffset.width;
          let newHeight = ASPECT * newWidth;
          rectElement.style.width = `${newWidth < 14 ? 14 : newWidth}px`;
          rectElement.style.height = `${newHeight < 12 ? 12 : newHeight}px`;
        }
      }
    }
    else if (!this.props.isCad && (this.props.mapZoom !== preProps.mapZoom || this.props.isCad !== preProps.isCad || this.state.showNavigator !== preState.showNavigator)) {
      let rectElement = document.querySelector(`.navigator-rectangle`) as HTMLInputElement;
      let c;

      if (rectElement && this.state.showNavigator) {
        if (this.props.mapZoom <= 17) {
          let center = new THREE.Vector3();
          this.bbox.getCenter(center);
          c = this.sceneManager.getScreenPosition(center);
        }
        else c = this.sceneManager.getScreenPosition(this.props.mapCenter);
        const zoom = (this.props.mapZoom / 17) * 3;

        if (this.props.mapZoom <= 17) {
          rectElement.style.width = '314px';
          rectElement.style.height = '279px';
        }
        else {
          const coord = new THREE.Vector3().set(-1, 1, -1).unproject(this.sceneManager.orthoCamera);
          let newHeight = 320 * (this.props.sceneTopPosition.y - this.props.mainCamPos.y) / (coord.y - this.sceneManager.orthoCamera.position.y);
          let newWidth = 285 * (this.props.sceneTopPosition.x - this.props.mainCamPos.x) / (coord.x - this.sceneManager.orthoCamera.position.x);          
          if (newWidth < 14) newWidth = 14;
          else if (newWidth > 314) newWidth = 314;

          if (newHeight < 12) newHeight = 12;
          else if (newHeight > 279) newHeight = 279;

          rectElement.style.width = `${newWidth}px`;
          rectElement.style.height = `${newHeight}px`;
        }
      }
    }

    if (this.navigator && (this.props.loadFile && (this.props.loadFile !== preProps.loadFile)) || (this.state.showNavigator && this.state.showNavigator !== preState.showNavigator)) {
      this.navigator!.appendChild(this.sceneManager.canvasElement);
    }
  }

  componentWillUnmount() {
    if (this.navigator) this.navigator!.removeChild(this.sceneManager.canvasElement);
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
    let rectElement = document.querySelector(`.navigator-rectangle`) as HTMLInputElement;

    if (rectElement) {
      if (!this.state.showNavigator) { rectElement.style.display = 'none'; }
      else {
        let c;
        if (this.props.isCad) {
          if (this.props.cameraZoom <= 1) {
            let center = new THREE.Vector3();
            this.bbox.getCenter(center);
            c = this.sceneManager.getScreenPosition(center);
          }
          else c = this.sceneManager.getScreenPosition(this.props.mainCamPos);  

        }
        else {
          if (this.props.mapZoom <= 17) {
            let center = new THREE.Vector3();
            this.bbox.getCenter(center);
            c = this.sceneManager.getScreenPosition(center);
          }
          else c = this.sceneManager.getScreenPosition(this.props.mapCenter);
        }
        rectElement.style.display = 'block';

        rectElement.style.top = `${c.y - this.canvasPosition.top}` + 'px';
        rectElement.style.left = `${c.x - this.canvasPosition.left}px`;//c.x.toString() + 'px';


        // 임시주석
        // else {
        //   if (this.props.mapZoom <= 17) {
        //     let center = new THREE.Vector3();
        //     this.bbox.getCenter(center);
        //     c = this.sceneManager.getScreenPosition(center);
        //   }
        //   else c = this.sceneManager.getScreenPosition(this.props.mapCenter);
        // }
        
        // let rect = rectElement.getBoundingClientRect();
        // const zoom = this.props.isCad ? this.props.cameraZoom : (this.props.mapZoom / 17) * 3;

        // if ((this.props.isCad && zoom <= 1) || (!this.props.isCad && this.props.mapZoom <= 17)) {
        //   rectElement.style.width = '314px';
        //   rectElement.style.height = '279px';
        // }
        // else {
        //   let newWidth = 314 - (zoom * 49);
        //   const ASPECT = this.props.canvasOffset.height / this.props.canvasOffset.width;
        //   let newHeight = ASPECT *  newWidth;//(this.props.canvasOffset.height / this.props.canvasOffset.width) * newWidth; // 279 / 314 = 0.8885350318471338
        //   rectElement.style.width = `${newWidth < 14 ? 14 : newWidth}px`;
        //   rectElement.style.height = `${newHeight < 12 ? 12 : newHeight}px`;
        // }
        // rectElement.style.display = 'block'; 
        // rectElement.style.top = `${c.y - this.canvasPosition.top}` + 'px';
        // rectElement.style.left = `${c.x - this.canvasPosition.left}px`;//c.x.toString() + 'px';
      }
    }

  }

  
  render() {

    return (

      <div className="CADNavigator">

        <div className="header font font-primary font-14px"
        onClick={() => { this.setState({ showNavigator: !this.state.showNavigator }) }}
        >
          <span className="left-side">
            <div className="wrap-icon">
              <GpsFixed className="gps-icon font font-emphasis" />
            </div>
            Navigator
          </span>
          {
            this.state.showNavigator ?
            <KeyboardArrowDown className="arrow-icon"  /> :
            <KeyboardArrowUp className="arrow-icon"  />
          }
        </div>
        {
          this.state.showNavigator &&
          <div
            className="Canvas"
            ref={(navigator) => {
              this.navigator = navigator;
            }}
          >
            {<div className="navigator-rectangle" />}
          </div>
        }
      </div>
    );
  }
}

export default CADNavigator;
