import { Button, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { Component, RefObject } from 'react';
import { NaverLatLng, NaverMapManager } from './NaverMapManager';
import { FieldType } from './Field';
import { jstsPolygontoWKT, tm2latlng } from './SceneManager';
import { BlockParsingData, ConverterField } from './BuildingPart';
import * as THREE from '@teneleven/three';
import { checkFileName, checkSpecialSymbolInName, getRoadLine, makeBlockBuildingData} from './DBManager';
import { Link } from 'react-router-dom';
import { LoadingPage } from './LoadingPage';
import '../css/CADConverter/MySiteBlockSaveModal.scss';
import { ConverterBuilding } from './BuildingPart';
import { SaveViewerModal } from './SaveViewerModal';
import { buttonNum } from './Modal';
import _, { debounce } from 'lodash';
import { wkt2LatLngs } from './CoreAndHouseController';
import App from '../App';
import wkx from 'wkx';
import { ExpandLess, ExpandMore, Info } from '@material-ui/icons'; 
import { ConverterType } from './DataTypes';
import { buildingData } from './resultLocationDataStruct';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import Tooltip from '../Tooltip';
import DrawingManager2 from '../DrawingManager2';
import { DrawingOptions } from '../DrawingOptions';
import { Marker } from "../Shape";
import { Polygon } from "../Shape";


const jsts = require("jsts");

type SiteAreaType = { // 사업영역
  siteArea: string,
  // roadArea: string,
  // vacancyOutsideArea: string,
  // vacancyInsideArea: string,
  // elevationCnt: number,
  
  address: string,
  elevationMin: string,
  elevationMax: string,
}

type TypeAreaType = { // 동평면
  totalHouseholds: number,
  totalExclusiveArea: string, // 사용X
  totalCommonWallArea: string, // 사용X
  totalCoreArea: string, // 사용X
  totalBuildingArea: string, // 사용X
  mainBay: number,
  buildingShapeType: string,
}

type PlanAreaType = { // 배치안
  address: string,
  constructSize: string,
  totalBuildingArea: string,
  totalGroundArea: string,
  exclusiveAverageArea: string,
}

interface MySiteBlockSaveModalProps {
  converterType: ConverterType,
  showModal: boolean,
  siteArea?: SiteAreaType,
  typeArea?: TypeAreaType,
  planArea?: PlanAreaType,
  fileName: string,
  isSaved: boolean,
  parsingOutput: BlockParsingData,
  userId: string,
  DBTableName: string,
  errorSize: number,
  loadFile?: boolean,
  hasDrawing?: boolean,
  centerOfRoad?: (value: string) => void,
  onShowSaveModal: () => void,
  onSave: () => Promise<boolean>,
  onChangeFileName: (fileName: string) => void,
  buildingObject?: () => THREE.Group,
  showModalAlert?: (title: string, content: string[], buttonNum: buttonNum, func: () => void) => void;
  recalculateArea?: () => void; 
  handleDrawing?: (value: boolean) => void;
}

interface MySiteBlockSaveModalState {
  msg: string,
  loadingPage: boolean,
  saveNameCheckMsg: string,
  isValueChanged: { // 사업영역 입력값 변경 여부
    site: boolean,
    elevationMax: boolean,
    elevationMin: boolean,
//    topography: boolean,
  },
  isTypeValueChanged: boolean // 동평면 입력값 변경 여부
  showMoreAreaInfo: boolean,
  isSaving: boolean,
}

class MySiteBlockSaveModal extends Component<MySiteBlockSaveModalProps, MySiteBlockSaveModalState> {
  state: MySiteBlockSaveModalState = {
    msg: "사업영역을 저장하시겠습니까?",
    loadingPage: false,
    saveNameCheckMsg: "",
    isValueChanged: {
      site: false,
      elevationMax: false,
      elevationMin: false,
  
 //     topography: false,
    },
    isTypeValueChanged: false,
    showMoreAreaInfo: false,
    isSaving: false,
  }
  mapManager = new DrawingManager2();//NaverMapManager();
  link = "";
  centerOfRoadWKT = '';
  areaChartRef: RefObject<HTMLDivElement>; // 전용면적 표
  isDrawing: boolean = false;
  topologyMarker: Marker[] = [];

  sitePolygon: Polygon[] = [];
  roadSite: Polygon[] = [];
  vacancyInside: Polygon[] = [];
  vacancyOutside: Polygon[] = [];
  topographyLines: Polygon[] = [];
  boundarySite: Polygon[] = [];
  
  corePolygon: Polygon[] = [];
  housePolygon: Polygon[] = [];
  
  //DBTableName = 'platform-buildit-my-site-v2';
  constructor(props: MySiteBlockSaveModalProps) {
    super(props);
    this.areaChartRef = React.createRef();
  }
  componentDidMount() {
    this.saveFile = _.debounce(this.saveFile, 500);

    switch(this.props.converterType) {
      case ConverterType.myType:
        this.link = "/myPage/file/myType";
        break;
      case ConverterType.mySite:
        this.link = "/myPage/file/mySite";
        break;
      default: // 배치안
        this.link = "/myPage/file/myPlan";
        break;
    }



    // this.mount!.appendChild(this.sceneManager.canvasElement);
    if (this.props.converterType !== ConverterType.myType) {
      this.mapManager.createMap({
        ref: this.refs.map,
        cadastral: true,
//        center: [centerInLL.x, centerInLL.y],
        maxZoom: 21,
        transitionOptions: {
          duration: 0,
          easing: "linear",
        },
        tileSpare: 1,
      });
        
      this.mapManager.map!.addListener('zoom_changed', () => {
        this.updateMarker(this.topologyMarker);
      });
    }
    else {
    }
  }

  updateMarker(markers: Marker[]) {
    const topoFields = this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.topography && !f.unused);

    markers.forEach((marker, i) => {

      let zoom = this.mapManager.map!.getZoom();
      let fontSize = 15;
      if (zoom >= 18) fontSize = 20;
      else if (zoom === 17) fontSize = 14;
      else fontSize = 10;

      let inputHeight = topoFields[i].defaultInput;
      let content = inputHeight !== topoFields[i].getHeight() ?
        `<div style="color: #95E4B3; font-size: ${fontSize}px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
        <img class="level-icon" src="/img/icon/ic-level-green.png" style=""/><span>${topoFields[i].getHeight()}</span>
      </div>`
        :
        `<div style="color: white; font-size: ${fontSize}px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
        <img class="level-icon" src="/img/icon/level.png"/><span>${topoFields[i].getHeight()}</span>
      </div>`;
      let icon = {
        content,
      }
      marker.setIcon(icon);
    })    
  }

  async componentDidUpdate(prevProps: MySiteBlockSaveModalProps) {
    //getPolygonInDrawingManager();
    
//    for (let polygon of Object.values(polygons)) {
    
    if (this.props.hasDrawing !== prevProps.hasDrawing && !this.props.hasDrawing) {     
      this.mapManager.clearDrawingManager();
    
      [...this.sitePolygon, ...this.roadSite, ...this.topographyLines, ...this.vacancyInside,
      ...this.vacancyOutside, ...this.boundarySite, ...this.housePolygon, ...this.corePolygon].forEach(polygon => {
        polygon !== null && polygon.remove();
      });

      this.topologyMarker.forEach(marker => {
        marker.remove();
      })
      this.topologyMarker = [];

    }
    
    if (this.props.errorSize !== prevProps.errorSize && this.props.errorSize === 0) {
      let center = new THREE.Vector2(0);
      const sites = this.props.parsingOutput.fields.filter(field => field.typeName === FieldType.site);
      if (sites.length) {
        //@ts-ignore
        const sitePolygon = sites[0].getUnionJstsPolygon()!.buffer(0); // ! 폴리곤 두개로 쪼개짐
        let cPoint = sitePolygon.getCentroid();
        center.set(cPoint.getX(), cPoint.getY());
        let centerInLL = tm2latlng(center);
        this.mapManager.map!.setCenter([centerInLL.x, centerInLL.y]);
      }
    }

    if (this.props.showModal && prevProps.showModal !== this.props.showModal) { // open      
      if (this.areaChartRef.current) {
        this.areaChartRef.current.scrollLeft = 0;
      }  
      this.setState({ showMoreAreaInfo: false });
      const topoField = this.props.parsingOutput.fields.filter(field => field.typeName === FieldType.topography);
      const siteField = this.props.parsingOutput.fields.filter(field => field.typeName === FieldType.site);

      let curMinLevel = Number.MAX_SAFE_INTEGER;
      let curMaxLevel = 0;
      let defaultMaxLevel = 0;
      let defaultMinLevel = Number.MAX_SAFE_INTEGER;

      topoField.forEach(field => {
        curMinLevel = Math.min(curMinLevel, field.getHeight());
        curMaxLevel = Math.max(curMaxLevel, field.getHeight());
        defaultMaxLevel = Math.max(defaultMaxLevel, field.defaultInput);
        defaultMinLevel = Math.max(defaultMinLevel, field.defaultInput);
      });

      if (curMinLevel !== defaultMaxLevel) {
        this.setState({
          isValueChanged: {
            ...this.state.isValueChanged,
            site: siteField.length && siteField[0].getArea() !== siteField[0].defaultInput ? true : false,
            elevationMax: curMaxLevel !== defaultMaxLevel && curMaxLevel !== 0 ? true : false,
            elevationMin: curMinLevel !== defaultMinLevel && curMinLevel !== 0 ? true : false,
          }
        })
      }

      await this.isFileNameAvailable(this.props.fileName);
      if (!this.props.hasDrawing) {
        this.addPolygonOnMap();
        this.props.converterType === ConverterType.myPlane && this.addBuildingOnMap();
       //!this.isDrawing = true;
        this.props.handleDrawing && this.props.handleDrawing(true);
      } 
      else {
        this.updateMarker(this.topologyMarker);
      }

      this.saveFile = _.debounce(this.saveFile, 300);
      this.isFileNameAvailable = _.debounce(this.isFileNameAvailable, 300);
    }
    else if (!this.props.showModal && prevProps.showModal !== this.props.showModal) { // close
      this.saveFile = _.debounce(this.saveFile, 0);
    }
    if (this.props.isSaved && prevProps.isSaved !== this.props.isSaved) {
      this.setState({ loadingPage: false });
    }
  }

  addBuildingOnMap = () => {
    const buildingData: buildingData[] = [];
    this.props.parsingOutput.buildings.forEach((b: ConverterBuilding) => {
      buildingData.push(makeBlockBuildingData(b));
    })
    buildingData.forEach(data => {
      data.position;
      if (data.position.x !== Infinity && data.position.y !== Infinity && data.position.z !== Infinity) {
        data.building.outline.forEach(outline => {
          const node = outline.node;
          const vertices = node.data.map((v) => [data.position.x + v.x, data.position.y + v.y]);
          const path: number[][][] = [[]];
          vertices.forEach(v => {
            const { x, y } = tm2latlng(new THREE.Vector2(v[0], v[1]));
            path[0].push([x, y]);
          });
          if (outline.category === "PCT_CORE") {
            const mapCore = this.mapManager.addPolygon(path, { ...DrawingManager2.DrawingOption.CAD_CORE, simplify: false });
            this.corePolygon.push(mapCore);
          }
          else if (outline.category === "PCT_HOUSE") {
            const mapHouse = this.mapManager.addPolygon(path, { ...DrawingManager2.DrawingOption.CAD_HOUSE, simplify: false });
            this.housePolygon.push(mapHouse);
          }
        })
      }
    })
  }

  addPolygonOnMap = async () => {
    this.setState({
      isValueChanged: {
        site: false,
        elevationMax: false,
        elevationMin: false,
      },
    });
    if (this.props.converterType !== ConverterType.myType && this.props.parsingOutput.fields.length > 0 ) {
      let sitePolygon = this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      //this.props.parsingOutput.fields[0].getUnionJstsPolygon();
      // this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();//
      let unionPolygon = this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();//this.props.parsingOutput.fields[0].getUnionJstsPolygon();
      let center = new THREE.Vector2(0);
      let RoadLatlngs: any[] = [];
      let SiteLatlngs: any[] = [];
      let centerRoadlatlngs: any[] = [];
      let outsideLatLngs: any[] = [];
      let insideLatLngs: any[] = [];
      let topographyLatLngs: any[] = [];
      const topoLatLngName: string[] = [];
      
      // let originalElevationMin = 100000000000;
      // let originalElevationMax = 0;
  
      this.props.parsingOutput.fields.forEach(f => {
        
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {
          //@ts-ignore
          sitePolygon = f.getUnionJstsPolygon()!.buffer(0); // ! 폴리곤 두개로 쪼개짐
          let cPoint = sitePolygon.getCentroid();
          center.set(cPoint.getX(), cPoint.getY());
          // @ts-ignore
          unionPolygon = f.getUnionJstsPolygon()!.buffer(0.1);
          SiteLatlngs = SiteLatlngs.concat(f.getLatLngList());
          
          if (f.defaultInput !== f.getArea()) {
            this.setState({ isValueChanged: { ...this.state.isValueChanged, site: true }});
          }
        }
        else if (f.typeName === FieldType.road && !f.unused) {
          RoadLatlngs = RoadLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyInside) {
          insideLatLngs = insideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyOutside) {
          outsideLatLngs = outsideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.centerLineOfRoad) {
          centerRoadlatlngs = centerRoadlatlngs.concat(f.getLatLngList());
          f.parts.forEach(p => {  
            this.centerOfRoadWKT = jstsPolygontoWKT(p.getJSTSPolygon(matrix));
          })
      //    this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);
        }
        else if (f.typeName === FieldType.topography) {
          const latlngList = f.getLatLngList();
          
          if (latlngList.length > 0) {
            topoLatLngName.push(f.name);
          }
          else {
            f.unused = true;
          }
          topographyLatLngs = topographyLatLngs.concat(latlngList);
        }
    })

      if (SiteLatlngs.length){
        const naverSitePolygon = this.mapManager.addPolygon(SiteLatlngs, DrawingManager2.DrawingOption.PROJECT_SITE, false);
        this.sitePolygon.push(naverSitePolygon);
      }
      if (RoadLatlngs.length){
        const naverRoadPolygon = this.mapManager.addPolygon(RoadLatlngs, DrawingManager2.DrawingOption.ROAD_SITE, false);
        this.roadSite.push(naverRoadPolygon);
      }
      if (insideLatLngs.length){
        const naverVacancyInside = this.mapManager.addPolygon(insideLatLngs, DrawingManager2.DrawingOption.VACANCY_INSIDE, false);
        this.vacancyInside.push(naverVacancyInside);
      }
      if (outsideLatLngs.length){
        const naverVacancyOutside = this.mapManager.addPolygon(outsideLatLngs, DrawingManager2.DrawingOption.VACANCY_OUTSIDE, false);
        this.vacancyInside.push(naverVacancyOutside);
      }
      if (topographyLatLngs.length){
        const naverTopology = this.mapManager.addPolygon(topographyLatLngs, DrawingManager2.DrawingOption.TOPOGRAPHY_LINE, false);
        this.topographyLines.push(naverTopology);
      }

      const topoFields = this.props.parsingOutput.fields.filter(field => field.typeName === FieldType.topography);


      topoFields.forEach(block => {
        for (let j = 0; j < topoLatLngName.length; j++) {
          if (block.name === topoLatLngName[j]) {
            console.log(block.name, topoLatLngName, block.getHeight())
        
            const inputHeight = block.defaultInput;
            const latlng = topographyLatLngs[j];
            //TODO
            const content = inputHeight !== block.getHeight() ?
              `<div style="color: #95E4B3; font-size: 14px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
              <img class="level-icon" src="/img/icon/ic-level-green.png" style="color: #95E4B3;"/>
              <span>${block.getHeight()}</span>
            </div>`
              :
              `<div style="color: white; font-size: 14px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
              <img class="level-icon" src="/img/icon/level.png"/><span>${block.getHeight()}</span>
            </div>`;

            const markerOptions = {
              draggable: false,
              icon: {
                content
              }
            };

            let geoFac = new jsts.geom.GeometryFactory();
            let coords: jsts.geom.Coordinate[] = [];
            latlng.forEach((coord: any) => {
              coords.push(new jsts.geom.Coordinate(coord[0], coord[1]));
            })
            let linearRing = geoFac.createLinearRing(coords);
            let polygon = geoFac.createPolygon(linearRing, [])
            let center = polygon.getCentroid();
            let centerCoord = { x: center.getX(), y: center.getY() };

            const marker = this.mapManager.addMarker([centerCoord.x, centerCoord.y], {
              ...markerOptions,
              icon: {
                content: markerOptions.icon.content,
                anchor: { x: 15, y: 7 }
              }
            }, false);
            this.topologyMarker.push(marker);
            break;
          }
        }
      })



      
      let centerInLL = tm2latlng(center);
      
      this.mapManager.map!.setCenter([centerInLL.x, centerInLL.y]);
      this.props.parsingOutput.fields.forEach(f => {
        if (f.typeName !== FieldType.vacancyInside && f.typeName !== FieldType.topography && f.getUnionJstsPolygon()) {
          //@ts-ignore
          unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
        }
      })
      
      if (centerRoadlatlngs.length > 0) {
        const boundaryLine = this.mapManager.addPolygon(centerRoadlatlngs, DrawingManager2.DrawingOption.BOUNDARY_SITE, false);
        this.boundarySite.push(boundaryLine);
      }
      if (centerRoadlatlngs.length === 0) {
        try {
          this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
          centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
          // //* test code
          //@ts-ignore
          let coordinates = wkx.Geometry.parse(this.centerOfRoadWKT).toGeoJSON().coordinates; // 두개
          let newC = coordinates.map((coords: any) => {
            return coords.map((coord: any) => {
              return tm2latlng(new THREE.Vector2(coord[0], coord[1]));
            })
          })          
          
          const boundaryLine = this.mapManager.addPolygon(newC, DrawingManager2.DrawingOption.BOUNDARY_SITE);
          this.boundarySite.push(boundaryLine);
        }
        catch (e) {
          App.stage !== "prod" && console.log('저장에러', e)
        }
      }
      // 폴리곤이 지도안에 보이게
      let minLatlng = { x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER };
      let maxLatlng = { x: Number.MIN_SAFE_INTEGER, y: Number.MIN_SAFE_INTEGER };

      let fieldsLatLngs = [SiteLatlngs, RoadLatlngs, centerRoadlatlngs, outsideLatLngs, insideLatLngs];
      fieldsLatLngs.forEach(field => {
        field.forEach(latLngs => {
          latLngs.forEach((latLng: number[]) => {
            if (latLng[0] < minLatlng.x) {
              minLatlng.x = latLng[0];
            }
            if (latLng[1] < minLatlng.y) {
              minLatlng.y = latLng[1];
            }
            if (latLng[0] > maxLatlng.x) {
              maxLatlng.x = latLng[0];
            }
            if (latLng[1] > maxLatlng.y) {
              maxLatlng.y = latLng[1];
            }
          })
        })
      })
      this.mapManager.map!.fitBounds([minLatlng.x, minLatlng.y], [maxLatlng.x, maxLatlng.y]);

    }


    if (this.props.recalculateArea)
      this.props.recalculateArea();
    this.props.centerOfRoad && this.props.centerOfRoad(this.centerOfRoadWKT);
  }

  saveFile = async() => {
    this.setState({ loadingPage: true });
    if (!await this.props.onSave()) 
      this.setState({ 
        loadingPage: false,
        isSaving: false,
      });
  
    // if (checkSpecialSymbolInName(fn)) {
    //   this.setState({
    //     saveNameCheckMsg: `파일 이름에는 다음 문자를 사용할 수 없습니다. ₩ / : * ? “ < > |`,
    //   });
    // }
    // else {
    //  let isSaveAvailable = await checkFileName(fn, this.props.userId, this.props.DBTableName);
      // if (isSaveAvailable && isSave) {
      //   this.setState({ loadingPage: true });
      //   if (!await this.props.onSave()) {
      //     this.setState({ loadingPage: false });
      //   }
      // }
      // else if (isSaveAvailable) {
      //   this.setState({
      //     saveNameCheckMsg: "",
      //   });
      // }
      // else {
      //   this.setState({
      //     saveNameCheckMsg: "중복되는 이름의 파일이 있습니다. 다른 이름으로 저장해주세요.",
      //   });
      // }
//    }
  }

  isFileNameAvailable = async(fileName: string) => {
    if (checkSpecialSymbolInName(fileName)) {  // 특수문자 확인
      this.setState({
        saveNameCheckMsg: `파일 이름에는 다음 문자를 사용할 수 없습니다. ₩ / : * ? “ < > |`,
      });
    }
    else { // 중복 체크
      let hasDuplication = !await checkFileName(fileName, this.props.userId, this.props.DBTableName);
      if (hasDuplication) {
        this.setState({
          saveNameCheckMsg: "중복되는 이름의 파일이 있습니다. 다른 이름으로 저장해주세요.",
        });
      }
      else this.setState({ saveNameCheckMsg: "" });
    }
  }

  render() {
    let saveInfo = <div></div>;
    if (this.props.converterType === ConverterType.mySite) {
    
      saveInfo = (
        <>
          <div className="m-t-sm border"></div>
          <div className="m-t-md input-summary">
            <span className="m-r-md font font-secondary font-14px text">입력 개요</span> 
            {
              (this.state.isValueChanged.elevationMax || this.state.isValueChanged.elevationMin || this.state.isValueChanged.site) &&
              <div className="edited">
                <div className="circle-icon" ></div><span>수정된 값</span>
              </div>
            }
          </div>
          <div className="m-t-sm key-value-wrapper">
          <div className="key-value width-250px">
              <div className="key">주소</div>
              <div className='value'>{this.props.siteArea!.address}</div>
            </div>
            <div className="key-value">
              <div className="key"> 대지영역면적</div>
              <div className={`value ${this.state.isValueChanged.site ? "font-green" : ""}`}>{this.props.siteArea!.siteArea!}</div>
            </div>
            <div className="key-value">
              <div className="key"> 대지레벨최저높이</div>
              <div className={`value ${this.state.isValueChanged.elevationMin ? "font-green" : ""}`}>{this.props.siteArea!.elevationMin}</div>
            </div>
            <div className="key-value">
              <div className="key"> 대지레벨최고높이</div>
              <div className={`value ${this.state.isValueChanged.elevationMax ? "font-green" : ""}`}>{this.props.siteArea!.elevationMax!}</div>
            </div>
          </div>
        </>
      )
    }
    else if (this.props.converterType === ConverterType.myType) {

      const houses: BuildingHouseUnit[] = [];
      this.props.parsingOutput.buildings.forEach(building => {
        building.parts.forEach(blockB => {
          blockB.parts.forEach(part => {
            //@ts-ignore
            if (part.buildingType === "component" && part.componentType === "house") {
              houses.push(part as BuildingHouseUnit);
            }
          })
        })
      })

      houses.sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        else return 1;
      })
      let isValueChanged = false;
      const housesRenderList: BuildingHouseUnit[][] = [];

      const sameNameCnt = new Map();

      for (let i = 0; i < houses.length; i += 4) {
        const tmp = [];

        for (let j = i; j < i + 4; j++) {
          if (j >= houses.length) break;
          tmp.push(houses[j]);
          if (houses[j].exclusiveArea !== houses[j].initialArea.exclusiveArea) isValueChanged = true;
        }
        housesRenderList.push(tmp);
      }
      
      saveInfo = (
        <>
        <div className="border"></div>
        <div className="title-wrapper m-t-md m-b-sm">
          <div className="title font font-secondary font-14px">입력 개요</div>
            {
              isValueChanged &&
              <div className="m-l-lg edited">
                <div className="circle-icon m-r-sm" /><div>수정된 값</div>
              </div>
            }
        </div>
        <div className="key-value-wrapper">
            <div className="key-value">
              <div className="key">단위세대 별 전용면적</div>
              <div className={`value d-flex ${this.state.showMoreAreaInfo && "active"}`} onClick={() => {
                this.setState({showMoreAreaInfo: !this.state.showMoreAreaInfo})
              }}>
                <div className={`review-area font font-14px font-emphasis ${isValueChanged && "active"}`}>확인하기</div>
                {
                  !this.state.showMoreAreaInfo ?
                    <ExpandMore className={`icon expand-icon ${isValueChanged && "active"}`} /> :
                    <ExpandLess className={`icon expand-icon ${isValueChanged && "active"}`} />
                }
              </div>
              <div className={`wrap-table m-t-sm  ${this.state.showMoreAreaInfo ? "" : "display-none"}`}
                ref={this.areaChartRef}
                onWheelCapture={(e) => {
                  e.preventDefault();
                  if (this.areaChartRef.current) {
                    this.areaChartRef.current.scrollBy({
                      left: e.deltaY < 0 ? -15 : 15,
                    })
                  }
                }}
              >
                {
                  //TODO
                  housesRenderList.map((houseList) => {

                    return (
                      <div className={`table ${this.state.showMoreAreaInfo ? "display" : "display-none"}`}>
                        <div className="tr">
                          <div className="th td key font font-secondary">U블록</div>
                          <div className="th td value font font-secondary">전용 면적</div>
                        </div>
                        {houseList.map((house) => {
                          let name = house.name.split('_')[0];
                          if (sameNameCnt.get(name)) {
                            sameNameCnt.set(name, sameNameCnt.get(name) + 1);
                          }
                          else sameNameCnt.set(name, 1);

                          return <div className={`tr ${house.initialArea.exclusiveArea !== house.exclusiveArea ? "active" : ""}`}>
                            <div className={`td key font font-secondary ${house.initialArea.exclusiveArea !== house.exclusiveArea && "active"}`}>
                              {house.initialArea.exclusiveArea !== house.exclusiveArea && <div className="circle-icon"></div>}
                              {house.name.split('_')[0]} - {sameNameCnt.get(name)}
                            </div>
                            <div className={`td value font font-primary ${house.initialArea.exclusiveArea !== house.exclusiveArea ? "active" : ""}`}>{house.exclusiveArea.toFixed(2)}㎡</div>
                          </div>
                        })}
                      </div>
                    )
                  })
                }

              </div>
           
            </div>
      

          <div className="key-value">
            <div className="key">총세대수</div>
            <div className="value">{this.props.typeArea!.totalHouseholds}세대</div>
          </div>
          <div className="key-value">
            <div className="key">대표 베이</div>
            <div className="value">{this.props.typeArea!.mainBay}베이</div>
          </div>
          <div className="key-value">
            <div className="key">동타입</div>
            <div className="value">{this.props.typeArea!.buildingShapeType}</div>
          </div>
                    {/* <div className="key-value">
            <div className="key">총 전용 면적<Info className="icon info-icon"/></div>
            <div className="value">{this.props.typeArea!.totalExclusiveArea}㎡</div>
          </div>
          <div className="key-value">
            <div className="key">총 벽체공용 면적<Info className="icon info-icon"/></div>
            <div className="value">{this.props.typeArea!.totalCommonWallArea}㎡</div>
          </div>
          <div className="key-value">
            <div className="key">총 코어 면적<Info className="icon info-icon"/></div>
            <div className="value">{this.props.typeArea!.totalCoreArea}㎡</div>
          </div>
          <div className="key-value">
            <div className="key">건축 면적</div>
            <div className="value">{this.props.typeArea!.totalBuildingArea}㎡</div>
          </div> */}
        </div>
        </>
      );
    }
    else { // 배치안
      const houses: BuildingHouseUnit[] = [];
      this.props.parsingOutput.buildings.forEach(building => {
        building.parts.forEach(blockB => {
          blockB.parts.forEach(part => {
            //@ts-ignore
            if (part.buildingType === "component" && part.componentType === "house") {
              houses.push(part as BuildingHouseUnit);
            }
          })
        })
      })

      houses.sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        else return 1;
      })
      let isValueChanged = false;
      const housesRenderList: BuildingHouseUnit[][] = [];

      const sameNameCnt = new Map();

      for (let i = 0; i < houses.length; i += 4) {
        const tmp = [];


        for (let j = i; j < i + 4; j++) {
          if (j >= houses.length) break;
          tmp.push(houses[j]);
          if (houses[j].exclusiveArea !== houses[j].initialArea.exclusiveArea) isValueChanged = true;
        }
        housesRenderList.push(tmp);
      }
      saveInfo = (
        <>
          <div className="m-t-sm border"></div>
          <div className="m-t-md input-summary">
            <span className="m-r-md font font-secondary font-14px text">입력 개요</span>
            {
              isValueChanged &&
              <div className="edited">
                <div className="circle-icon" ></div><span>수정된 값</span>
              </div>
            }
            {/* {
              isValueChanged &&
              <div className="m-l-lg edited">
                <div className="circle-icon m-r-sm" /><div>수정된 값</div>
              </div>
            } */}
          </div>
          <div className="m-t-sm key-value-wrapper">
            <div className="key-value width-250px">
              <div className="key">주소</div>
              <div className="value">{this.props.planArea!.address}</div>
            </div>
            <div className="key-value width-250px">
              <div className="key">건축규모
                <Tooltip msg="총 세대수, 총 건물수, 최고/최저 층수" arrowOn={false} place={'right'}><Info className="info-icon"/></Tooltip>
              </div>
              <div className="value">{this.props.planArea!.constructSize}</div>
            </div>
            <div className="key-value">
              <div className="key">단위세대 별 전용면적</div>
              <div className={`value d-flex ${this.state.showMoreAreaInfo && "active"}`} onClick={() => {
                this.setState({ showMoreAreaInfo: !this.state.showMoreAreaInfo })
              }}>
                <div className={`review-area font font-14px font-emphasis ${isValueChanged && "active"}`}>확인하기</div>
                {
                  !this.state.showMoreAreaInfo ?
                    <ExpandMore className={`icon expand-icon ${isValueChanged && "active"}`} /> :
                    <ExpandLess className={`icon expand-icon ${isValueChanged && "active"}`} />
                }
              </div>
              <div className={`wrap-table m-t-sm  ${this.state.showMoreAreaInfo ? "" : "display-none"}`}
                ref={this.areaChartRef}
                onWheelCapture={(e) => {
                  e.preventDefault();
                  if (this.areaChartRef.current) {
                    this.areaChartRef.current.scrollBy({
                      left: e.deltaY < 0 ? -15 : 15,
                    })
                  }
                }}
              >
                {
                  //TODO
                  housesRenderList.map((houseList) => {

                    return (
                      <div className={`table ${this.state.showMoreAreaInfo ? "display" : "display-none"}`}>
                        <div className="tr">
                          <div className="th td key font font-secondary">U블록</div>
                          <div className="th td value font font-secondary">전용 면적</div>
                        </div>
                        {houseList.map((house) => {
                          let name = house.name.split('_')[0];
                          if (sameNameCnt.get(name)) {
                            sameNameCnt.set(name, sameNameCnt.get(name) + 1);
                          }
                          else sameNameCnt.set(name, 1);

                          return <div className={`tr ${house.initialArea.exclusiveArea !== house.exclusiveArea ? "active" : ""}`}>
                            <div className={`td key font font-secondary ${house.initialArea.exclusiveArea !== house.exclusiveArea && "active"}`}>
                              {house.initialArea.exclusiveArea !== house.exclusiveArea && <div className="circle-icon"></div>}
                              {house.name.split('_')[0]} - {sameNameCnt.get(name)}
                            </div>
                            <div className={`td value font font-primary ${house.initialArea.exclusiveArea !== house.exclusiveArea ? "active" : ""}`}>{house.exclusiveArea.toFixed(2)}㎡</div>
                          </div>
                        })}
                      </div>
                    )
                  })
                }

              </div>

            </div>
          </div>
        </>
      )
    }


    return (
      <div className={`MySiteBlockSaveModal ${this.props.showModal ? "" : 'hidden'}`}>
        {/* <div className="loading-wrap">
        <LoadingPage 
      //  show={true}
          show={this.state.loadingPage}//props.showLoadingPage}//loadingPage} 
          loadingMsg={`나의 ${this.props.converterType}을 저장 중 입니다.`} 
          isModal={true}
        />
        </div> */}
        {/* {
          this.state.loadingPage ? 
          <div className={`modal-wrapper ${this.props.converterType === ConverterType.myType ? "myType" : ""}`}>
          <div className={`header long ${this.props.converterType === ConverterType.myType ? "myType" : ""}`}>
            <div className="top-bar">
              <div className="title">{`나의 ${this.props.converterType} 저장`}</div>
              <IconButton className="icon-button" onClick={() => {
                this.props.onShowSaveModal();
              }}><Close className={`close-icon ${this.state.loadingPage ? "hidden" : ""}`}></Close></IconButton>
            </div>
           
        </div>
        </div>
          
          :  */}
          <div className={`modal-wrapper ${this.props.converterType === ConverterType.myType ? "myType" : ""}`}>
          {
            this.state.loadingPage && 
                    <LoadingPage 
                        show={this.state.loadingPage}//props.showLoadingPage}//loadingPage} 
                        loadingMsg={`나의 ${this.props.converterType}을 저장 중 입니다.`} 
                        isModal={true}
                      />
          }
          <div className={`header long ${this.props.converterType === ConverterType.myType ? "myType" : ""}`}>
            <div className="top-bar">
              <div className="title">{`나의 ${this.props.converterType} 저장`}</div>
              <IconButton className="icon-button" onClick={() => {
                this.props.onShowSaveModal();
              }}><Close className={`close-icon ${this.state.loadingPage ? "hidden" : ""}`}></Close></IconButton>
            </div>
            <div className={`msg font font-14px ${this.props.isSaved ? "font-special" : ""}`}>{!this.props.isSaved ? `${this.props.converterType}을 저장하시겠습니까?` : 
              `${this.props.converterType}이 저장되었습니다. 저장된 ${this.props.converterType}은 '파일 관리' 메뉴에서 확인 가능합니다.`}
            </div>
            <div className="title">{`${this.props.converterType} 파일 이름`}</div>
            <div className="file-name-wrapper">
              <input
                type="text"
                className="file-name"
                maxLength={255}
                onChange={async (e) => {
                  this.props.onChangeFileName(e.target.value);
                  await this.isFileNameAvailable(e.target.value);

                }}
                value={this.props.fileName}
              />
              <div className="button-wrapper">
                {
                  this.props.isSaved ? 
                  // 저장 후
                  <> 
                    <Link to={this.link} className="router-link">
                      <Button className="btn bg-navy btn-cancel font font-12 cancle-btn" onClick={() => this.props.onShowSaveModal()}>바로 가기</Button>
                    </Link>
                    <Button className={`btn bg-navy btn-primary font font-12 save-btn`} onClick={() => { this.props.onShowSaveModal() }}>확인</Button>
                  </>
                  : // 저장 전
                  <> 
                    <Button className={`btn bg-navy btn-cancel cancle-btn ${this.state.isSaving ? 'disabled' : ""}`} onClick={() => !this.state.isSaving && this.props.onShowSaveModal()}>취소</Button>
                    <Button className={`btn bg-navy btn-primary save-btn ${this.state.saveNameCheckMsg !== "" ? 'disabled' : ""}`} onClick={() => {
                      this.setState({saveNameCheckMsg: " ", isSaving: true});
                      this.saveFile();                      
                      
                      // this.props.onSave();
                    }}>저장</Button>
                  </>
                }
              </div>
            </div>
            <div className="font font-error font-12px saveNameMsg">
              {this.state.saveNameCheckMsg}
            </div>
            {saveInfo}
          </div>
          {
            this.props.converterType !== ConverterType.myType ?
            <section className={`map-wrapper  ${this.state.loadingPage ? "background-none" : ""} ${this.state.loadingPage? "display-none" : ""}`}>
              <div className="scene">
                <div ref="map" style={{ width: `640px`, height: `380px` }} />
              </div>
            </section> 
            : 
              <section className={`map-wrapper ${this.props.converterType === ConverterType.myType ? "myType" : ""} ${this.state.loadingPage ? "background-none" : ""}`}>
                <div className="scene" style={{ width: `698px`, height: `330px`}}>
                  {
                    //@ts-ignore
                    <SaveViewerModal isLatestVer={true} open={this.props.showModal} buildingObject={this.props.buildingObject} positive={this.saveFile} />
                  }
                </div>
              </section>
          }
        </div>
        {/* } */}

      </div>
    );
  }
}

export default MySiteBlockSaveModal;