import * as THREE from '@teneleven/three';
import {MaterialManager, MatType} from "../MaterialManager";

export interface GeneratorType {
    LEVEL: FeatureGenerator,
    ROOFTOP: FeatureGenerator,
    PILOTI: FeatureGenerator,
    LOGO: FeatureGenerator,
    ROOFLINE: FeatureGenerator
}

export interface eachNode {
    node: any,  //동평면 점군
    lineInfo: any,  //라인 정보
    building_height: number, //전체 층고
    heights: any,   //각 층고
    buildingGroup: THREE.Group, //빌딩 그룹(각)
    buildingIndex?: number, //빌딩 번호
    roofLineHeight?: number,    //루프 라인 높이 (생성 필요 시)
}

export interface DataForGenerator {
    nodes: eachNode[],
}

export abstract class FeatureGenerator {

    protected static readonly _wallThickness = 0.25;

    protected readonly _wallcoreMaterial = MaterialManager.getInstance().resloc_materials.get(MatType.WALL);
    protected readonly _floorMaterial = MaterialManager.getInstance().resloc_materials.get(MatType.FLOOR);
    protected readonly _ceilingMaterial = MaterialManager.getInstance().resloc_materials.get(MatType.CEILING);
    protected readonly _windowMaterial = MaterialManager.getInstance().resloc_materials.get(MatType.WINDOW);
    // protected readonly _logoMaterial = MaterialManager.getInstance().resloc_materials.get(MatType.LOGO);
    // protected readonly _fontMaterial = MaterialManager.getInstance().resloc_materials.get(MatType.BUILDNO);

    protected constructor(
        protected readonly allBuilding: THREE.Group) {
    }

    public static wallThickness() {
        return this._wallThickness;
    }

    public abstract Generate(data: DataForGenerator) : void;

    public abstract Dispose() : void;
}