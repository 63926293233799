import React, { Component } from 'react';
import { Info } from '@material-ui/icons';
import { updateDynamoDBData, getSettingData } from './DBManager';
import Tooltip from '../Tooltip';
import { Button } from '@material-ui/core';
import Switch from '../Switch';
import BuilditInput from '../BuilditInput';
import { ConverterType } from './DataTypes';
import { SaveState } from './SettingModal';
import _ from 'lodash';

export interface settingData {
  enable: boolean,
  value: number,
}

export class myTypeSettingData {
  windowOffset: settingData; // 창문 이격거리
  areaOffset: settingData; // 면적
  layerOffset: settingData; // 레이어 이격
  layerOverlap: settingData; // 레이어/폴리곤 교차
  unClosedPolygon: settingData; // 닫혀있지 않은 폴리곤
  polygonOffset: settingData; // 폴리곤 이격
  blockOverlap: settingData; // 블록간 교차면적;
  delWindowLine: settingData;
  delLineNotInBlock: settingData; 


  // 사업영역
  lineInSameBlock: settingData; // 같은 블록 안에 N개의 라인 선택
  
  settingDBTable = 'platform-buildit-cad-converter-user-setting';

  constructor() {
    this.windowOffset = { enable: false, value: 100 } 
    this.areaOffset = { enable: false, value: 10 }
    this.layerOffset = { enable: false, value: 200 }
    this.layerOverlap = { enable: false, value: 10 }
    this.unClosedPolygon = {enable: false, value: 0}
    this.polygonOffset = {enable: false, value: 0};
    this.blockOverlap = { enable: false, value: 0 };
    this.delWindowLine = { enable: false, value: 0 };
    this.delLineNotInBlock = { enable: false, value: 0 };
    this.lineInSameBlock = { enable: false, value: 0 };
  }

  updateDataFromDB = (settingData: myTypeSettingData) => {
    this.windowOffset.enable = settingData.windowOffset ? settingData.windowOffset.enable : this.windowOffset.enable;
    this.windowOffset.value = settingData.windowOffset? settingData.windowOffset.value : this.windowOffset.value;

    this.areaOffset.enable = settingData.areaOffset ? settingData.areaOffset.enable : this.areaOffset.enable;
    this.areaOffset.value = settingData.areaOffset ? settingData.areaOffset.value : this.areaOffset.value;
    
    this.layerOffset.enable = settingData.layerOffset ? settingData.layerOffset.enable : this.layerOffset.enable;
    this.layerOffset.value = settingData.layerOffset ? settingData.layerOffset.value : this.layerOffset.value;
    
    this.layerOverlap.enable = settingData.layerOverlap ? settingData.layerOverlap.enable : this.layerOverlap.enable;
    this.layerOverlap.value = settingData.layerOverlap ? settingData.layerOverlap.value : this.layerOverlap.value;
    
    this.unClosedPolygon.enable = settingData.unClosedPolygon ? settingData.unClosedPolygon.enable : this.unClosedPolygon.enable;
    this.polygonOffset.enable = settingData.polygonOffset ? settingData.polygonOffset.enable : this.polygonOffset.enable;
    this.blockOverlap.enable = settingData.blockOverlap ? settingData.blockOverlap.enable : this.blockOverlap.enable;
    this.delWindowLine.enable = settingData.delWindowLine ? settingData.delWindowLine.enable : this.delWindowLine.enable;
    this.delLineNotInBlock.enable = settingData.delLineNotInBlock ? settingData.delLineNotInBlock.enable : this.delLineNotInBlock.enable;
    this.lineInSameBlock.enable = settingData.lineInSameBlock ? settingData.lineInSameBlock.enable : this.lineInSameBlock.enable;
  }

  // updatePolygonOffsetEnable = async(value: boolean) => {
  //   let data = await getSettingData();
  //   if (!data)
  //     return;
  //   data.myType.polygonOffset.enable = value;
  //   this.polygonOffset.enable = value;
  //   updateDynamoDBData(this.settingDBTable, data);

  // }
  // updateUnClosedPolygonEnable = async (value: boolean) => {
  //   let data = await getSettingData();
  //   if (!data)
  //     return;
  //   data.myType.unClosedPolygon.enable = value;
  //   this.unClosedPolygon.enable = value;
  //   updateDynamoDBData(this.settingDBTable, data);
  // }

  updateEnable = async (value: boolean, key: string) => {
    let data = await getSettingData();
    if (!data) {      
      return;
    }
    if (data.myType[key]) {
      data.myType[key].enable = value;
    }
    await updateDynamoDBData(this.settingDBTable, data);
  }
  updateDB = async () => {
    let data = await getSettingData();
    if (!data) return
    await updateDynamoDBData(this.settingDBTable, data);
  }


  // updateBlockOverlapEnable = async (value: boolean) => {
  //   let data = await getSettingData();
  //   if (!data)
  //     return;
  //   data.myType.blockOverlap.enable = value;
  //   this.blockOverlap.enable = value;
  //   updateDynamoDBData(this.settingDBTable, data);

  // }

  updateWindowOffsetEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.windowOffset.enable = value;
    this.windowOffset.enable = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateWindowOffsetValue = async (value: number) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.windowOffset.value = value;
    this.windowOffset.value = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateAreaOffsetEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.areaOffset.enable = value;
    this.areaOffset.enable = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateAreaOffsetValue = async (value: number) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.areaOffset.value = value;
    this.areaOffset.value = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateLayerOffsetEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.layerOffset.enable = value;
    this.layerOffset.enable = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateLayerOffsetsetValue = async (value: number) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.layerOffset.value = value;
    this.layerOffset.value = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateLayerOverlapEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.layerOverlap.enable = value;
    this.layerOverlap.enable = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateLayerOverlapsetValue = async (value: number) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.layerOverlap.value = value;
    this.layerOverlap.value = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  getData = () => {
    return {
      windowOffset: this.windowOffset,
      areaOffset: this.areaOffset,
      layerOffset: this.layerOffset,
      layerOverlap: this.layerOverlap,
      unClosedPolygon: this.unClosedPolygon, // 닫혀있지 않은 폴리곤
      polygonOffset: this.polygonOffset, // 폴리곤 이격
    }
  }
}

interface myTypeSettingProps {
  converterType: ConverterType,
  autoEditAll: boolean,
  myTypeSettingData: myTypeSettingData,
  handleSaveState: (saveState: SaveState) => void,
  handleAutoEditAll: () => void,
  changeSettingData: (optionName: string, value: any) => void,
}

interface myTypeSettingState {
  windowOffsetEnable: boolean, // 창문 세대간 이격
  windowOffsetValue: number, 
  areaOffsetEnable: boolean,
  areaOffsetValue: number,
  layerOffsetEnable: boolean, 
  layerOffsetValue: number,
  layerOverlapEnable: boolean,
  layerOverlapValue: number,
  // 블록 버전
  unClosedPolygonEnable: boolean, // 닫혀있지않은 폴리곤 오차보정
  blockOverlapEnable: boolean, // 블록간 교차 면적 보정
  polygonOffsetEnable: boolean, // 블록 간 이격 보정
  delWindowLineEnable: boolean, // 세대를 벗어난 창문라인 삭제
  delLineNotInBlock: boolean, // F/B블록으로 지정되지 않은 n개의 라인 삭제
}

export class MyTypeSettingDataUI extends Component<myTypeSettingProps, myTypeSettingState>{

  state: myTypeSettingState = {
    areaOffsetEnable: this.props.myTypeSettingData.areaOffset.enable,
    areaOffsetValue: this.props.myTypeSettingData.areaOffset.value,
    layerOffsetEnable: this.props.myTypeSettingData.layerOffset.enable,
    layerOffsetValue: this.props.myTypeSettingData.layerOffset.value,
    layerOverlapEnable: this.props.myTypeSettingData.layerOverlap.enable,
    layerOverlapValue: this.props.myTypeSettingData.layerOverlap.value,
    windowOffsetEnable: this.props.myTypeSettingData.windowOffset.enable,
    windowOffsetValue: this.props.myTypeSettingData.windowOffset.value,
    unClosedPolygonEnable: this.props.myTypeSettingData.unClosedPolygon.enable,
    polygonOffsetEnable: this.props.myTypeSettingData.polygonOffset.enable, 
    blockOverlapEnable: this.props.myTypeSettingData.blockOverlap.enable,
    delWindowLineEnable: this.props.myTypeSettingData.delWindowLine.enable,
    delLineNotInBlock: this.props.myTypeSettingData.delLineNotInBlock.enable,
  }

  componentDidMount = () => {
    this.changeInput = _.debounce(this.changeInput, 300);
  }

  componentDidUpdate = async (prevProps: myTypeSettingProps, prevState: myTypeSettingState) => {

    if (prevProps.autoEditAll !== this.props.autoEditAll) {
      await this.props.myTypeSettingData.updateDB();
      this.setState({
        windowOffsetEnable: this.props.myTypeSettingData.windowOffset.enable,
        unClosedPolygonEnable: this.props.myTypeSettingData.unClosedPolygon.enable, // 닫혀있지않은 폴리곤 오차보정
        blockOverlapEnable: this.props.myTypeSettingData.blockOverlap.enable, // 블록간 교차 면적 보정
        polygonOffsetEnable: this.props.myTypeSettingData.polygonOffset.enable, // 폴리곤간 이격 보정
        delWindowLineEnable: this.props.myTypeSettingData.delWindowLine.enable, // 세대를 벗어난 창문라인 삭제
        delLineNotInBlock: this.props.myTypeSettingData.delLineNotInBlock.enable, // F/B블록으로 지정되지 않은 n개의 라인 삭제
      })
    }
  }

  changeInput = async (v: number) => {
    if (this.state.windowOffsetValue >= 100) {
      v = 100;
    }
    else if (this.state.windowOffsetValue < 1) {
      v = 1;
    }
    this.props.handleSaveState(SaveState.saving);
    this.setState({windowOffsetValue: v});
    await this.props.myTypeSettingData.updateWindowOffsetValue(v); 
    this.props.handleSaveState(SaveState.saveDone);
    
  };

  render = () => {
    return (  
      <>
      
        <div className="wrap-key-value">
          <div className="wrap-left-text">
            <div className="key font font-primary font-14px">닫혀 있지 않은 폴리곤 오차 보정
              <Tooltip msg="동평면/사업영역 - 설정하신 폴리곤이 닫혀 있지 않을 시, 해당 범위 내에서 보정합니다." arrowOn={false}>
                <div className="wrap-icon">
                  <Info className="icon info-icon font font-emphasis" />
                </div>
              </Tooltip>
            </div>
            <div className="font font-secondary middle-text">1mm 이하 오차 범위 내</div>
          </div>
          <div className="value">
            <Switch
              className={`font ${this.state.unClosedPolygonEnable ? "font-special" : "font-secondary"}`}
              checked={this.state.unClosedPolygonEnable}
              onClick={async (checked) => {
                this.setState({ unClosedPolygonEnable: !this.state.unClosedPolygonEnable }, async () => {
                  this.props.handleSaveState(SaveState.saving);
                  this.props.myTypeSettingData.unClosedPolygon.enable = checked;
                  await this.props.myTypeSettingData.updateEnable(checked, 'unClosedPolygon');
                  await this.props.handleAutoEditAll();
                  this.props.handleSaveState(SaveState.saveDone);
                  
                });
              }}
              name="자동 보정"
            />
          </div>
        </div>
        <div className="wrap-key-value">
          <div className="wrap-left-text">
            <div className="key font font-primary font-14px">블록 간 교차 면적 보정
              <Tooltip msg="동평면/사업영역 - 설정하신 블록 간의 교차된 면적 오차를 해당 범위 내에서 보정합니다. " arrowOn={false}>
                <div className="wrap-icon">
                  <Info className="icon info-icon font font-emphasis" />
                </div>
              </Tooltip>
            </div>
            <div className="font font-secondary middle-text">0.1% 미만 오차 범위 내</div>
          </div>

          <div className="value">
            <Switch
              className={`font ${this.state.blockOverlapEnable ? "font-special" : "font-secondary"}`}
              checked={this.state.blockOverlapEnable}
              onClick={async (checked) => {
                this.setState({ blockOverlapEnable: !this.state.blockOverlapEnable }, async () => {
                  this.props.myTypeSettingData.blockOverlap.enable = checked;
                  this.props.handleSaveState(SaveState.saving);
                  await this.props.myTypeSettingData.updateEnable(checked, 'blockOverlap');
                  await this.props.handleAutoEditAll();
                  this.props.handleSaveState(SaveState.saveDone);
                  
                });
              }}
              // onClick={(checked) => {
              //   this.setState({ siteTooltip: checked }, () => {
              //     window.localStorage.setItem("site_tooltip", checked.toString());
              //     if (this.state.siteTooltip === false) {
              //       if (this.infoWindow) {
              //         this.infoWindow.close();
              //       }
              //     }
              //   });
              // }}
              name="자동 보정"
            />

          </div>
        </div>
        <div className="wrap-key-value">
          <div className="wrap-left-text">
            <div className="key font font-primary font-14px">블록 간 이격 보정
              <Tooltip msg="동평면/사업영역 - 설정 하신 폴리곤 사이의 이격 거리 오차를 해당 범위 내에서 보정합니다." arrowOn={false}>
                <div className="wrap-icon">
                  <Info className="icon info-icon font font-emphasis" />
                </div>
              </Tooltip>
            </div>
            <div className="font font-secondary middle-text font-12px">1mm이하 / OFFSET 10mm미만 오차 범위 내</div>
          </div>
          <div className="value">
            <Switch
              className={`font ${this.state.polygonOffsetEnable ? "font-special" : "font-secondary"}`}
              checked={this.state.polygonOffsetEnable}
              onClick={async (checked) => {
                this.props.myTypeSettingData.polygonOffset.enable = checked;
                this.props.handleSaveState(SaveState.saving);
                await this.props.myTypeSettingData.updateEnable(checked, 'polygonOffset');

                this.setState({ polygonOffsetEnable: !this.state.polygonOffsetEnable }, () => {
                  this.props.handleAutoEditAll();
                  this.props.handleSaveState(SaveState.saveDone);
                });
              }}
              // onClick={(checked) => {
              //   this.setState({ siteTooltip: checked }, () => {
              //     window.localStorage.setItem("site_tooltip", checked.toString());
              //     if (this.state.siteTooltip === false) {
              //       if (this.infoWindow) {
              //         this.infoWindow.close();
              //       }
              //     }
              //   });
              // }}
              name="자동 보정"
            />

          </div>
        </div>
        <div className="wrap-key-value">
          <div className="wrap-left-text">

            <div className="key font font-primary font-14px">창문 - 세대 간 이격 보정
              <Tooltip msg={<>동평면 - 설정하신 창문과 세대 간 이격거리 오차를 입력 범위 내에서 보정합니다.<br/>
              (적용 가능한 보정 범위: 1~100mm)</>} arrowOn={false}>
                <div className="wrap-icon">
                  <Info className="icon info-icon font font-emphasis" />
                </div>
              </Tooltip>
            </div>
            <div className="font font-secondary middle-text">

              <BuilditInput
                className="distance-input"
                align="right"
                type="number"
                min={1}
                max={100}
                length={3}
                onKeyDown={(e: any) => {
                  if (e.key === ".") {
                    e.preventDefault();
                  }
                }}
                onChange={(v) => {
                  let value = Math.floor(v as number).toString();
                  const MAX_LENGTH = 3;
                  if (value.length > MAX_LENGTH) {
                    value = value.slice(0, MAX_LENGTH);
                  }
                  this.setState({ windowOffsetValue: Number(value) });
                  this.changeInput(Number(value));
                }} 
                value={this.state.windowOffsetValue} 
              // autoFocus={true} 
              />
              mm 이내일 경우
            </div>
          </div>
          <div className="value">
            <Switch
              className={`font ${this.state.windowOffsetEnable ? "font-special" : "font-secondary"}`}
              checked={this.state.windowOffsetEnable}
              onClick={async (checked) => {
                this.setState({ 
                  windowOffsetEnable: !this.state.windowOffsetEnable,
                  windowOffsetValue: this.state.windowOffsetValue
                }, async () => {
                  this.props.myTypeSettingData.windowOffset.enable = checked;
                  this.props.handleSaveState(SaveState.saving);
                  await this.props.myTypeSettingData.updateEnable(checked, 'windowOffset');

                  this.props.handleAutoEditAll();
                  this.props.handleSaveState(SaveState.saveDone);
                });
              }}
              // onClick={(checked) => {
              //   this.setState({ siteTooltip: checked }, () => {
              //     window.localStorage.setItem("site_tooltip", checked.toString());
              //     if (this.state.siteTooltip === false) {
              //       if (this.infoWindow) {
              //         this.infoWindow.close();
              //       }
              //     }
              //   });
              // }}
              name="자동 보정"
            />

          </div>
        </div>
        <div className="wrap-key-value">
          <div className="wrap-left-text">
            <div className="key font font-primary font-14px">세대를 벗어난 창문 라인 삭제
              <Tooltip msg="동평면 - 세대 라인에 입력되지 않은 창문 라인에 대해 삭제 처리하여 저장합니다. " arrowOn={false}>
                <div className="wrap-icon">
                  <Info className="icon info-icon font font-emphasis" />
                </div>
              </Tooltip>
            </div>
            <div className="font font-secondary middle-text">0mm이상일 경우</div>
          </div>

          <div className="value">
            <Switch
              className={`font ${this.state.delWindowLineEnable ? "font-special" : "font-secondary"}`}
              checked={this.state.delWindowLineEnable}
              onClick={async (checked) => {
                this.setState({ delWindowLineEnable: !this.state.delWindowLineEnable }, async () => {
                  this.props.myTypeSettingData.delWindowLine.enable = checked;
                  this.props.handleSaveState(SaveState.saving);
                  await this.props.myTypeSettingData.updateEnable(checked, 'delWindowLine');

                  this.props.handleAutoEditAll();
                  this.props.handleSaveState(SaveState.saveDone);
                });
              }}
              // onClick={(checked) => {
              //   this.setState({ siteTooltip: checked }, () => {
              //     window.localStorage.setItem("site_tooltip", checked.toString());
              //     if (this.state.siteTooltip === false) {
              //       if (this.infoWindow) {
              //         this.infoWindow.close();
              //       }
              //     }
              //   });
              // }}
              name="자동 보정"
            />

          </div>
        </div>
        <div className="wrap-key-value">
          <div className="wrap-left-text">
            <div className="key font font-primary font-14px">F/B블록으로 지정되지 않은<br />N개의 라인 삭제
              <Tooltip msg="동평면 블록 지정 - F블록이나 B블록으로 지정되지 않은 라인/폴리라인/폴리곤은 삭제 처리하여 저장합니다. " arrowOn={false}>
                <div className="wrap-icon">
                  <Info className="icon info-icon font font-emphasis" />
                </div>
              </Tooltip>
            </div>
            <div className="font font-secondary middle-text">2개 이상일 경우</div>
          </div>

          <div className="value">
            <Switch
              className={`font ${this.state.delLineNotInBlock ? "font-special" : "font-secondary"}`}
              checked={this.state.delLineNotInBlock}
              onClick={async (checked) => {
                this.setState({ delLineNotInBlock: !this.state.delLineNotInBlock }, async () => {
                  this.props.myTypeSettingData.delLineNotInBlock.enable = checked;
                  this.props.handleSaveState(SaveState.saving);
                  await this.props.myTypeSettingData.updateEnable(checked, 'delLineNotInBlock');

                  this.props.handleAutoEditAll();
                  this.props.handleSaveState(SaveState.saveDone);
                });
              }}
              name="자동 보정"
            />

          </div>
        </div>
      </>
    )
  }
}