import React, { Fragment, Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button } from "@material-ui/core";
import queryString from "query-string";
import { default as _ } from "lodash";
import LaunchOutlinedIcon from "@material-ui/icons/LaunchOutlined";
import "./css/DesignerDownloadPage.scss";
import PageFoot from "./pageFoot";
import SessionComponent from "./SessionComponent";
import DownloadButton from "./DownloadButton";
import App from "./App";
import Login from "./Login";
import BuilditTemplate from "./BuilditTemplate";
import axios from "axios";

export interface DesignerDownloadPageProps {}

export interface DesignerDownloadPageState {
  latestVersion: number;
}

export default class DesignerDownloadPage extends SessionComponent<DesignerDownloadPageProps, DesignerDownloadPageState> {
  state: DesignerDownloadPageState = {
    latestVersion: 0,
  };

  componentWillMount = async () => {};
  componentDidMount = () => {
    this.getVersionDataFromS3();
  };
  componentDidUpdate = (pp: Readonly<DesignerDownloadPageProps>, ps: Readonly<DesignerDownloadPageState>) => {};

  downloadDesigner = (callback?: Function) => {
    let keyName = "";
    switch (App.stage) {
      case "prod":
        keyName = "buildit_designer_installer_release.exe";
        break;
      case "test":
        keyName = "buildit_designer_installer_test.exe";
        break;
      default:
        // dev
        keyName = "buildit_designer_installer_dev.exe";
    }

    App.s3!.getSignedUrl(
      "getObject",
      {
        Bucket: "teneleven-application-registry-apn2",
        Key: `buildit-designer/v1/${keyName}`,
        Expires: 43200,
        ResponseContentDisposition: `attachment; filename="Buildit_Designer_Installer.exe"`,
      },
      async (err, data) => {
        if (err) {
          console.log(err);
        } else {
          window.location.href = data;
        }

        if (callback) {
          callback();
        }
      }
    );
  };

  onInitToken = (token: number) => {
    // this.setState({
    //   token: token,
    // });
    window.location.href = "/";
  };

  getVersionDataFromS3 = async () => {
    let fileURL: any = "";
    switch (App.stage) {
      case "prod":
        fileURL = "https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Designer/v1/LatestVersionInfo_Release.txt";
        break;
      case "test":
        fileURL = "https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Designer/v1/LatestVersionInfo_Test.txt";
        break;
      default:
        fileURL = "https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Designer/v1/LatestVersionInfo_Dev.txt";
    }
    let version: number = 0;
    await axios
      .get(fileURL, {
        withCredentials: false,
      })
      .then((res) => (version = res.data))
      .catch((e) => console.log("ERROR...", e));
    this.setState({
      latestVersion: version,
    });
  };

  render() {
    App.stage !== "prod" && console.log(App.session);
    if (App.session === undefined) {
      return <Login onInitToken={this.onInitToken} fromDownLoadPage={true} {...this.props} />;
    } else {
      return (
        <BuilditTemplate footer={true} {...this.props}>
          <div className="designer-download-page">
            <div className="bg-wrapper">
              <img src="/img/designer_download_bg.png" className="bg-img" />
            </div>
            <div className="content-wrapper">
              <div className="title">
                <span style={{ color: "#48DDF2" }}>BUILDIT DESIGNER</span> DOWNLOAD
              </div>
              <div className="downloder-wrapper">
                <div className="button-wrapper">
                  <div className="title">최신 버전 다운로드</div>
                  <div className="sub-title-wrapper">
                    <div className="sub-title">BUILDIT DESIGNER를 다운로드하여</div>
                    <div className="sub-title">새롭게 추가된 기능과 개선된 성능을 확인해 보세요🙂</div>
                  </div>
                  <div className="download-inner-wrapper mx-auto">
                    <div className="version-wrapper">최신 버전 {this.state.latestVersion} ver</div>
                    <DownloadButton
                      className="bg-navy btn btn-large btn-run download-btn designer-download-btn"
                      onClick={(callback: Function) => {
                        this.downloadDesigner(() => {
                          callback && callback();
                        });
                      }}
                    >
                      <div>DESIGNER</div>
                      <span className="m-l-a width-full" style={{ textTransform: "none" }}>
                        exe
                      </span>
                    </DownloadButton>
                  </div>
                  {/* <div className="download-inner-wrapper mx-auto">
                    <div className="version-wrapper">BUILDIT 사용법 확인</div>
                    <Button
                      disableRipple={true}
                      className="bg-navy btn btn-primary download-btn m-l-a download-btn "
                      onClick={() => {
                        window.open("https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Manual/BUILDIT_MANUAL.pdf");
                      }}
                    >
                      <div className="d-flex">사용자 매뉴얼</div>
                      <LaunchOutlinedIcon className="d-flex launch-icon m-l-a" />
                    </Button>
                  </div> */}
                </div>
                h
              </div>
            </div>
          </div>
        </BuilditTemplate>
      );
    }
  }
}
