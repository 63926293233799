import React, { Component } from "react";
import { Checkbox, Button } from "@material-ui/core";
import "./css/ProjectMultiSettingValue.scss";
import { ProjectNoImageCardData } from "./model/ProjectCard";
import { findBuilding } from "./CADConverter/CoreAndHouseController";

export interface ProjectMultiSettingValueProps {
  project: any;
  building_type: string;
  className?: string;
}

export interface ProjectMultiSettingValueState {
  result?: any;
  data: [];
}

export interface builditBuildingTypes {
  name: string;
  type: string;
  houses: number[];
}

export default class ProjectMultiSettingValue extends Component<ProjectMultiSettingValueProps, ProjectMultiSettingValueState> {
  state: ProjectMultiSettingValueState = {
    result: [],
    data: [],
  };

  componentWillMount = () => {
    if (this.props.building_type === "apartment") {
      let newResult = {
        ...this.props.project,
        setback_building_line: this.props.project.setback_building_line_apartment,
        setback_site_boundary: this.props.project.setback_site_boundary_apartment,
      };
      this.setState({ result: newResult });
    } else if (this.props.building_type === "officetel") {
      let newResult = {
        ...this.props.project,
        setback_building_line: this.props.project.setback_building_line_officetel,
        setback_site_boundary: this.props.project.setback_site_boundary_officetel,
      };
      this.setState({ result: newResult });
    } else {
      let newResult = {
        ...this.props.project,
        setback_building_line: this.props.project.setback_building_line_multi_house,
        setback_site_boundary: this.props.project.setback_site_boundary_multi_house,
      };
      this.setState({ result: newResult });
    }
  };

  componentDidUpdate = () => {
    console.log("state", this.state.result);
  };

  builditBuildingTypes = [
    {
      name: "판상 I형",
      type: "LINEAR_NON_CORRIDOR_TYPE",
      houses: [2, 4, 6],
    },
    {
      name: "판상 L형",
      type: "FOLDED_NON_CORRIDOR_TYPE",
      houses: [2, 3, 4, 5, 6, 7],
    },
    {
      name: "타워 T형",
      type: "T_TOWER_TYPE",
      houses: [3, 4],
    },
    {
      name: "타워 Y형",
      type: "Y_TOWER_TYPE",
      houses: [3, 4, 5, 6, 7],
    },
    {
      name: "복도식 I형",
      type: "LINEAR_CORRIDOR_TYPE",
      houses: [4, 5, 6, 7, 8, 9],
    },
    {
      name: "복도식 L형",
      type: "FOLDED_CORRIDOR_TYPE",
      houses: [4, 5, 6, 7, 8, 9],
    },
  ];

  render() {
    const building_type = this.props.building_type === "apartment" ? "아파트" : this.props.building_type === "officetel" ? "오피스텔" : "다세대주택";
    return (
      <div className={`ProjectMultiSettingValue `}>
        <div className="header bg-navy font font-primary">{building_type}</div>
        <div className="content">
          {ProjectNoImageCardData.map((d) => {
            return (
              <div className={`key-value`}>
                <div className="key">{d.fieldName}</div>
                <div className="value">
                  {d.field !== "building_shape_type" && "housing_plan_type" && this.state.result && d.format(this.state.result[d.field])} {d.unit}
                  {d.field === "building_shape_type" &&
                    this.state.result &&
                    this.state.result[d.field].map((item: any) => {
                      const figure = this.builditBuildingTypes.map((buildingType: builditBuildingTypes) => {
                        if (buildingType.type === item.figures.toString()) {
                          return buildingType.name!;
                        }
                      });
                      let string = item.houses_number.toString() + "세대";
                      return (
                        <div className="value-string">
                          {figure} {string}
                        </div>
                      );
                    })}
                  {d.field === "housing_plan_type" &&
                    this.state.result &&
                    this.state.result[d.field].map((item: any) => {
                      let string = item.area.toString() + "㎡, " + item.proportion.toString() + "%";
                      return <div className="value-string">{string}</div>;
                    })}
                </div>
              </div>
            );
          })}
          {/* <div className={`key-value`}>
            <div className="key">건축선 후퇴 - {this.props.building_type === "apartment" ? "아파트" : this.props.building_type === "officetel" ? "오피스텔" : "다세대주택"}</div>
            <div className="value">{this.state.result[`setback_building_line_${this.props.building_type}`]} m</div>
          </div>
          <div className={`key-value`}>
            <div className="key">인접대지선 후퇴 - {this.props.building_type === "apartment" ? "아파트" : this.props.building_type === "officetel" ? "오피스텔" : "다세대주택"}</div>
            <div className="value">{this.state.result[`setback_site_boundary_${this.props.building_type}`]} m</div>
          </div> */}
        </div>
      </div>
    );
  }
}
