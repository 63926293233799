import React, { Component, Fragment } from 'react';
import './css/ProjectValue.scss';
import Tooltip from './Tooltip';
import BuilditSelect from "./BuilditSelect";
import { any } from 'prop-types';

export interface ProjectValueProps {
  className?: string;
  title: string;
  value?: number | string;
  unit?: string;
  tooltip?: string;
  type?: string | "select";
  setBuildingStoriesAvg?: (input: any) => void;
}
export interface ProjectValueState {
  building_stories_avg_type?: any
}

export default class ProjectValue extends Component<ProjectValueProps, ProjectValueState> {
  state: ProjectValueState = {
    building_stories_avg_type: ""
  };
  
  componentDidMount = () => {
    this.setState({
      building_stories_avg_type: this.props.value
    })
  }

  render() {
    const valueType = typeof this.props.value;
    if (this.props.type === "select") {
      return (
        <div className={`ProjectValue ${this.props.className}`}>
          <div className="wrapper">
            <div className="project-value-title">
              {this.props.title}
              {this.props.tooltip && (
                <Tooltip msg={this.props.tooltip}>
                  <div className="question">
                    <img style={{ marginLeft: "6px", minWidth: "12px", height: "12px", color: "#CCCCCC", verticalAlign: "bottom", paddingBottom: "1px" }} src="/img/question.png"></img>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="project-value-value">
              <BuilditSelect
                className="search-field-select font bg-navy font-primary font-noto font-pretendard font-14px"
                list={[
                  { label: "기준 면적", value: "AREA" },
                  { label: "산술 평균", value: "NUMERICAL" },
                  { label: "총 세대", value: "HOUSE" },
                ]}
                value={this.state.building_stories_avg_type}
                onChange={(e: any) => {
                  this.setState({ building_stories_avg_type: e }, () => {this.props.setBuildingStoriesAvg && this.props.setBuildingStoriesAvg(this.state.building_stories_avg_type);});
                  
                }}
                type="Normal"
              ></BuilditSelect>
              <span className="unit">{this.props.unit}</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`ProjectValue ${this.props.className}`}>
          <div className="wrapper">
            <div className="project-value-title">
              {this.props.title}
              {this.props.tooltip && (
                <Tooltip msg={this.props.tooltip}>
                  <div className="question">
                    <img style={{ marginLeft: "6px", minWidth: "12px", height: "12px", color: "#CCCCCC", verticalAlign: "bottom", paddingBottom: "1px" }} src="/img/question.png"></img>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="project-value-value">
              {(this.props.value !== undefined && valueType === "string" && (((this.props.value as string).length > 0 && this.props.value) || "-")) ||
                (this.props.value !== undefined ? this.props.value : "-")}
              <span className="unit">{this.props.unit}</span>
            </div>
          </div>
        </div>
      );
    }
  }
}