import * as THREE from '@teneleven/three';
import {LoaderInterface} from "./LoaderInterface";
import {MakeBuildingMesh, MakeBuildingOuterLine} from "../MeshManager";

export class ResLocationLoader extends LoaderInterface {
    public constructor(resJson: any, _scene: THREE.Scene) {
        super(resJson, _scene);
    }

    public Load(props: any): void {
        let buildingGroup = new THREE.Group();
        this._scene.add(buildingGroup);

        let index = 0;
        (this._resJson.buildings as []).forEach((b: any) => {
            //custom data
            b.bk_customdata = new Object({
                buildingCategory: props.buildingCategory,
                buildingIndex: ++index
            });
            /////////////////////////////////////////////////////

            let building = MakeBuildingMesh(b, props.demData);
            let pos = props.getPos(b.position.x, b.position.y);
            let height = b.position.z * 0.1;
            if (b.proximalHeight != null) //#211111 다세대주택, 오피스텔 등 적층식 건물의 경우 z값을 가장 아래 건물에 맞추고 proximalHeight로 높이 조절
            {
                height += b.proximalHeight * 0.1;
            }

            building.position.set(pos.x, height, pos.z);

            buildingGroup.add(building);
        })
        buildingGroup.updateMatrixWorld();
        // this.buildingGroup.traverse((o: THREE.Object3D) => {
        //     o.matrixAutoUpdate = false;
        // });

        if (props.buildingCategory === "MULTI_HOUSE" && this._resJson.validMass) {
            let position = this._resJson.buildings[0].position;
            let pos = props.getPos(position.x, position.y);
            let outlineMesh = MakeBuildingOuterLine(this._resJson.validMass);
            outlineMesh.position.set(pos.x, position.z * 0.1, pos.z);
            this._scene.add(outlineMesh);

            // return outlineMesh;
        }
    }
}