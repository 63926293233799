import React, { Component } from 'react';
import { ConverterBuilding, BuildingComponent, BuildingPart } from './BuildingPart';
import { ConverterInputBox } from './ConverterInputBox';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { ConverterType } from './DataTypes';
import { ChevronRight, Done, ExpandLess, ExpandMore } from '@material-ui/icons';
import '../css/CADConverter/BuildingComponentUI.scss';
import BuilditSelect from '../BuilditSelect';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  IconButton,
  ExpansionPanelDetails,
} from "@material-ui/core";
import BlockTreeList from './BlockTreeList';
import BlockInspectorTree from './TypeConverter/BlockInspectorTree';
import Tooltip from '../Tooltip';
import _ from 'lodash';

interface componentState {
  extraMenu: boolean;
  update: number;
  selectedBuildingInfo: {
    bay: number,
    type: string,
  };
  isExpanded: boolean;
  showBalconyArea: boolean;
}

interface componentProp {
  type: ConverterType;
  component: BuildingComponent | ConverterBuilding;
  buildingPart?: BuildingPart;
  selectedBuildingInfo?: {
    bay: number,
    type: string,
  }
  showFontError: boolean | undefined;
  onSelectBuildingInfo?: (bay: number, type: string) => void;
  updateArea: () => void;
  showSnackBar?: () => void;
}


export class ComponentUI extends Component<componentProp, componentState> {
  state: componentState = {
    extraMenu: true,
    update: 0,
    selectedBuildingInfo: {
      bay: 0,
      type: "선택",
    },
    isExpanded: true,
    showBalconyArea: false,
  }

  pageUpdate = (type?: string) => {
    //    this.props.updateArea();
    this.props.updateArea();
    type && this.props.showSnackBar && this.props.showSnackBar();

    this.setState({ update: this.state.update + 1 })
  }

  expandPanel = () => {
    this.setState({ isExpanded: true });
  }

  render = () => {
    if (this.props.type === ConverterType.myPlane && this.props.component.name.toUpperCase().startsWith("F")) {
      return (
        <>
          {/* <ConverterInputBox name='층고(m)' value={(this.props.component as ConverterBuilding).levelHeights[0]}
            valueUpdate={this.pageUpdate} valueOnChange={
              (this.props.component as ConverterBuilding).SetLevelHeight
            } step={0.01} buildingPart={this.props.buildingPart} /> */}
          <div className="font font-12px font-secondary">
          (단위:㎡)
          </div>
          <BlockInspectorTree
            buildings={this.props.component}
          />
        </>
      )
    }
    else if (this.props.type === ConverterType.myType && this.props.component.name.toUpperCase().startsWith("F")) {
      return (
        <div className="BuildingComponentUI">

          <ExpansionPanel
            className="font font-emphasis font-14px expansion"
            classes={{
              root: "exp-root",
              expanded: "expanded",
            }}
            expanded={this.state.isExpanded}
            onChange={() => {
              this.setState({isExpanded: !this.state.isExpanded})
            }}
          >

            <ExpansionPanelSummary
              disableRipple={true}
              // expandIcon={
              //   // <ExpandMore className="icon arrow-right" />
              // }
              classes={{
                root: "summary-root",
                expanded: "expanded",
                content: "content",
                //  expandIcon: "translateY(-50%) rotate(45deg)"
              }}
              className={`expansion-title font font-emphasis ${this.props.showFontError && "font-error"}`}
            >
              
              <IconButton className="arrow-btn">
                {!this.state.isExpanded ? <ChevronRight className="icon arrow-right" /> : <ExpandMore className="icon arrow-right" />}
              </IconButton>{`동평면 설정 (필수)`}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="expansion-content">

              <div className="wrap-key-value">
                <div className="font font-secondary font-12px key">대표 베이</div>
                <div className="value">
                  <Button className={`btn btn-primary bg-navy font font-12px bay-btn ${this.props.selectedBuildingInfo && this.props.selectedBuildingInfo.bay === 2 && "active"}`}
                    onClick={() => {
                      this.props.onSelectBuildingInfo!(2, this.props.selectedBuildingInfo!.type);
                      this.setState({ selectedBuildingInfo: { ...this.state.selectedBuildingInfo, bay: 2 } });
                    }}>
                    <Done className="icon done-icon" />2
                  </Button>
                  <Button className={`btn btn-primary bg-navy font font-12px bay-btn ${this.props.selectedBuildingInfo && this.props.selectedBuildingInfo.bay === 3 && "active"}`}
                    onClick={() => {
                      this.props.onSelectBuildingInfo!(3, this.props.selectedBuildingInfo!.type);
                      this.setState({ selectedBuildingInfo: { ...this.state.selectedBuildingInfo, bay: 3 } });
                    }}>
                    <Done className="icon done-icon" />3
                  </Button>
                  <Button className={`btn btn-primary bg-navy font font-12px bay-btn ${this.props.selectedBuildingInfo && this.props.selectedBuildingInfo.bay === 4 && "active"}`}
                    onClick={() => {
                      this.props.onSelectBuildingInfo!(4, this.props.selectedBuildingInfo!.type);
                      this.setState({ selectedBuildingInfo: { ...this.state.selectedBuildingInfo, bay: 4 } });
                    }}>
                    <Done className="icon done-icon" />4
                  </Button>
                  <Button className={`btn btn-primary bg-navy font font-12px bay-btn width-51px ${this.props.selectedBuildingInfo && this.props.selectedBuildingInfo.bay === 4.5 && "active"}`}
                    onClick={() => {
                      this.props.onSelectBuildingInfo!(4.5, this.props.selectedBuildingInfo!.type);
                      this.setState({ selectedBuildingInfo: { ...this.state.selectedBuildingInfo, bay: 4.5 } });
                    }}>
                    <Done className="icon done-icon" />4.5
                  </Button>
                  <Button className={`btn btn-primary bg-navy font font-12px bay-btn width-51px ${this.props.selectedBuildingInfo && this.props.selectedBuildingInfo.bay === 5 && "active"}`}
                    onClick={() => {
                      this.props.onSelectBuildingInfo!(5, this.props.selectedBuildingInfo!.type);
                      this.setState({ selectedBuildingInfo: { ...this.state.selectedBuildingInfo, bay: 5 } });
                    }}>
                    <Done className="icon done-icon" />5 ~
                  </Button>
                </div>
              </div>



            </ExpansionPanelDetails >

            <ExpansionPanelDetails className="expansion-content">
              <div className="wrap-key-value m-t-md">
                <div className="font font-secondary font-12px key">동타입</div>
                <div className="value">
                  <BuilditSelect className="building-type-select font bg-navy font-primary font-noto font-pretendard font-14px"
                    list={[
                      { label: "판상 I형", value: "판상 I형" },
                      { label: "판상 L형", value: "판상 L형" },
                      { label: "타워 T형", value: "타워 T형" },
                      { label: "타워 Y형", value: "타워 Y형" },
                      { label: "복도식 I형", value: "복도식 I형" },
                      { label: "복도식 L형", value: "복도식 L형" },
                      { label: "기타", value: "기타" },
                    ]}
                    value={this.props.selectedBuildingInfo && this.props.selectedBuildingInfo.type}
                    onChange={(e) => {
                      this.props.onSelectBuildingInfo!(this.props.selectedBuildingInfo!.bay, e as string);
                      this.setState({ selectedBuildingInfo: { ...this.state.selectedBuildingInfo, type: e as string } });
                    }}
                    type="Normal"
                  />
                </div>
              </div>
            </ExpansionPanelDetails >
          </ExpansionPanel>
          {
            <div className="m-t-md">
              <div className="font font-12px font-secondary">
                (단위:㎡)
              </div>
              <BlockInspectorTree
                buildings={this.props.component}
              />
            </div>
          }
        </div>
      )
    }
    //@ts-ignore
    else if (this.props.component.buildingType! === "component")
      return (
        <div key={this.props.component.uuid} className="BuildingComponentUI">

          {
            //@ts-ignore
            this.props.component.componentType! === 'core' &&
            // 코어
            <div className='coreUI'>
              <div>
                  <ConverterInputBox name='코어면적(㎡)' value={(this.props.component as BuildingCoreUnit).GetArea()}
                    valueUpdate={() => this.pageUpdate("AREA")}
                    valueOnChange={(this.props.component as BuildingCoreUnit).SetArea}
                    step={0.0001}
                    defaultValue={(this.props.component as BuildingCoreUnit).initialArea} hasRefresh={true}
                  />
              </div>
              <div className='Line' />
            </div> ||
            // 세대
            <div className='houseUI'>
              {
                this.props.type === ConverterType.myPlane &&
                <>
                  <div className="wrap-input">
                    {
                      this.props.type === ConverterType.myPlane &&
                      <ConverterInputBox name='층수(층)' value={(this.props.component as BuildingHouseUnit).level.length} 
                      valueUpdate={this.pageUpdate} valueOnChange={(this.props.component as BuildingHouseUnit).SetLevel} step={1}
                        defaultValue={1} hasRefresh={false}
                      />
                    }
                    <div className={`inputValues ${!this.state.extraMenu && 'hidden'}`}>
                      <ConverterInputBox name='필로티(층)' value={(this.props.component as BuildingHouseUnit).piloti} 
                      valueUpdate={this.pageUpdate} valueOnChange={(this.props.component as BuildingHouseUnit).SetPiloti} step={1}
                        canHaveZero={true} hasRefresh={false}
                        defaultValue={0}
                      />
                    </div>
                  </div>

                </>
              }
              <div className='inputValues'>
                <ConverterInputBox name='전용면적(㎡)' value={(this.props.component as BuildingHouseUnit).exclusiveArea} 
                valueUpdate={() => this.pageUpdate("AREA")} valueOnChange={(this.props.component as BuildingHouseUnit).SetExclusiveArea} step={0.0001}
                  defaultValue={(this.props.component as BuildingHouseUnit).initialArea.exclusiveArea} hasRefresh={true}
                />
                <div className="inputValues wrap">
                  <ConverterInputBox name='서비스면적(㎡)' value={(this.props.component as BuildingHouseUnit).serviceArea} 
                  valueUpdate={() => this.pageUpdate("AREA")} valueOnChange={(this.props.component as BuildingHouseUnit).SetServiceArea} step={0.0001}
                    defaultValue={(this.props.component as BuildingHouseUnit).initialArea.serviceArea} hasRefresh={true}
                    showMore={true}
                    canHaveZero={true} 
                    hideEdited={true}
                  />
                  {
                    <Tooltip msg="서비스면적 설정" arrowOn={false}>
                      <div className="arrow-bottom"
                        onClick={() => this.setState({ showBalconyArea: !this.state.showBalconyArea })}
                      >
                        {
                          !this.state.showBalconyArea ? 
                          <ExpandMore className="icon" /> : <ExpandLess className="icon" />
                        }
                      </div>
                    </Tooltip>
                  }
                </div>
              </div>

              {/* <div className='extraMenu' onClick={() => this.setState({ extraMenu: !this.state.extraMenu })}>자세히+</div> */}
              {
                this.state.showBalconyArea &&
                <div className="wrap-input m-b-md m-t-md">
                  <ConverterInputBox
                    name='발코니면적(㎡)'
                    value={(this.props.component as BuildingHouseUnit).balconyLess150cm}
                    valueUpdate={this.pageUpdate}
                    valueOnChange={(this.props.component as BuildingHouseUnit).SetBalconyOver150cm}
                    disable={true}
                    canHaveZero={true}
                    step={0.0001}
                    hasRefresh={true}
                  />
                  <ConverterInputBox name='발코니초과면적(㎡)' value={(this.props.component as BuildingHouseUnit).balconyOver150cm} 
                  valueUpdate={() => this.pageUpdate("AREA")} valueOnChange={(this.props.component as BuildingHouseUnit).SetBalconyOver150cm} step={0.0001}
                  canHaveZero={true}
                  defaultValue={0}
                  hasRefresh={true}
                  />
                </div>
              }

              <div className={`inputValues ${!this.state.extraMenu && 'hidden'} m-t-md`}>
                <ConverterInputBox name='벽체공용면적(㎡)' value={(this.props.component as BuildingHouseUnit).commonWallArea} 
                valueUpdate={() => this.pageUpdate("AREA")} valueOnChange={(this.props.component as BuildingHouseUnit).SetCommonWallArea} step={0.0001}
                  defaultValue={(this.props.component as BuildingHouseUnit).initialArea.commonWallArea}
                  canHaveZero={true} hasRefresh={true}
                />

              </div>
   
            </div>
          }
        </div>
      )
    else {
      return <div></div>
    }
  }
}

