import { timeScale } from "../Utils";

// 다세대필드 삭제 차후 다시 살림 ( 20200526 )

const ProjectCardData = [
  {
    field: "building_type",
    fieldName: "건물종류",
    normalField: true,
    showSmall: true,
    format: (data: any) => data,
  },
  {
    field: "loc_building_land_ratio",
    fieldName: "건폐율",
    normalField: false,
    unit: "%",
    showSmall: true,
    format: (data: any) => Number(Number(data).toFixed(2)).toLocaleString(),
  },
  {
    field: "loc_floor_area_ratio",
    fieldName: "달성 용적률",
    normalField: true,
    unit: "%",
    showSmall: true,
    format: (data: any) => Number(Number(data).toFixed(2)).toLocaleString(),
  },
  { field: "loc_building_stories_min", fieldName: "최저 층수", normalField: false, unit: "층", showSmall: false, format: (data: any) => data },
  { field: "loc_building_stories_max", fieldName: "최고 층수", normalField: false, unit: "층", showSmall: true, format: (data: any) => data },
  { field: "loc_building_stories_avg", fieldName: "평균 층수", normalField: false, unit: "층", showSmall: false, format: (data: any) => Number(data).toFixed(2) },
  { field: "loc_building_number", fieldName: "건물 수", normalField: false, unit: "동", showSmall: true, format: (data: any) => data },
  { field: "loc_total_household", fieldName: "총 세대수", normalField: true, unit: "세대", showSmall: true, format: (data: any) => Number(data).toLocaleString() },
  { field: "daylight_hours_avg", fieldName: "평균 일조시간", normalField: true, showSmall: false, format: (data: any) => timeScale(data) },
  { field: "daylight_hours_mode", fieldName: "최빈 일조시간", normalField: false, showSmall: false, format: (data: any) => timeScale(data) },
  {
    field: "daylight_hours_proportion_less_n_hours",
    fieldName: "2시간 미만 일조비율",
    normalField: false,
    unit: "%",
    showSmall: false,
    format: (data: any) => Number(data).toFixed(2).toLocaleString(),
  },
  { field: "view_point_avg", fieldName: "평균 조망점수", normalField: false, unit: "점", showSmall: false, format: (data: any) => Number(Number(data).toFixed(2)).toLocaleString() },
  {
    field: "loc_construction_cost",
    fieldName: "추정 공사비",
    normalField: false,
    unit: "(천)원",
    showSmall: true,
    format: (data: any) => Number(Number(Number(data) / 1000).toFixed(0)).toLocaleString(),
  },
  {
    smallField: true,
    field: "parkingNumber",
    fieldName: "주차 대수",
    normalField: false,
    unit: "대",
    showSmall: true,
    format: (data: any) => Number(data),
  },
];

const ProjectNoImageCardData = [
  {
    field: "building_land_ratio",
    fieldName: "건폐율",
    normalField: false,
    unit: "%",
    showSmall: true,
    format: (data: any) => Number(Number(data).toFixed(2)).toLocaleString(),
  },
  {
    field: "floor_area_ratio",
    fieldName: "용적률",
    normalField: true,
    unit: "%",
    showSmall: true,
    format: (data: any) => Number(Number(data).toFixed(2)).toLocaleString(),
  },
  { field: "building_stories_min", fieldName: "최저 층수", normalField: false, unit: "층", showSmall: false, format: (data: any) => data },
  { field: "building_stories_max", fieldName: "최고 층수", normalField: false, unit: "층", showSmall: false, format: (data: any) => data },
  { field: "building_stories_avg", fieldName: "평균 층수", normalField: false, unit: "층", showSmall: false, format: (data: any) => Number(data).toFixed(1) },
  { field: "distance_between_side_opaque_walls", fieldName: "인동거리_벽면_측벽", normalField: true, unit: "m", showSmall: false, format: (data: any) => Number(data).toFixed(0) },
  { field: "distance_between_side_walls", fieldName: "인동거리_측벽_측벽", normalField: true, unit: "m", showSmall: false, format: (data: any) => Number(data).toFixed(0) },
  { field: "distance_between_window_opaque_walls", fieldName: "인동거리_채광창_다른건물", normalField: true, unit: "h", showSmall: false, format: (data: any) => Number(data).toFixed(1) },
  { field: "floor_height", fieldName: "층고", normalField: false, unit: "m", showSmall: false, format: (data: any) => data },
  { field: "project_use_district", fieldName: "용도지역", normalField: false, unit: "", showSmall: false, format: (data: any) => data },
  {
    field: "setback_regulation_from_north_less_9m",
    fieldName: "9m이하 정북일조",
    normalField: true,
    unit: "m",
    showSmall: false,
    format: (data: any) => data,
  },
  {
    field: "setback_regulation_from_north_more_9m",
    fieldName: "9m이상 정북일조",
    normalField: true,
    unit: "h",
    showSmall: false,
    format: (data: any) => data,
  },
  {
    field: "setback_regulation_from_site_boundary",
    fieldName: "채광사선_채광창_인접대지경계선",
    normalField: true,
    unit: "h",
    showSmall: false,
    format: (data: any) => data,
  },
  {
    field: "setback_building_line",
    fieldName: "건축선후퇴",
    normalField: true,
    unit: "m",
    showSmall: false,
    format: (data: any) => data,
  },
  {
    field: "setback_site_boundary",
    fieldName: "인접대지경계선 후퇴",
    normalField: true,
    unit: "m",
    showSmall: false,
    format: (data: any) => data,
  },
  { field: "building_shape_type", fieldName: "동타입", normalField: false, showSmall: false, format: (data: any) => (data: []) => data.map((d) => d) },
  { field: "housing_plan_type", fieldName: "세대타입", normalField: false, showSmall: false, format: (data: any) => (data: []) => data.map((d) => d) },
];

export default ProjectCardData;
export { ProjectNoImageCardData };
