import React, { Component } from 'react';
import Refresh from '@material-ui/icons/Refresh';
import Settings from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import '../css/CADConverter/ConverterHeader.scss';
import { Button } from '@material-ui/core';
import { ConverterType } from './DataTypes';
import { buttonNum } from './Modal';
import { Home, Info, NavigateNext } from '@material-ui/icons';
import Tooltip from '../Tooltip';
import { ReactComponent as OpenIcon } from '../img/icon/ic-open.svg';
import MapDownloadModal from './MapDownloadModal';
import HeaderDropBox from './HeaderDropBox';
import '../css/CADConverter/DropBox.scss';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as TaskAlt } from '../img/icon/taskAlt.svg';
import CadConverterTypeTag from './CadConverterTypeTag';
import SyncAltRoundedIcon from "@material-ui/icons/SyncAltRounded";
import { ModalOptions } from '../Modal';

interface HeadProps {
  type: ConverterType,
  email: string,
  errorSize: number,
  warningSize: number,
  infoSize?: number,
  isFileOpen: boolean,
  fillInRequiredField?: boolean, // 동평면 필수입력정보
  isLatest: boolean,
  loadFile: (e: React.ChangeEvent<HTMLInputElement>) => void,
  saveFile: () => void,
  reset: () => void,
  showErrorLog?: () => void,
  openSettingModal: () => void,
  showModal?: (modalOptions: ModalOptions) => void,
  closeModal?: () => void,
  showModalLegacy?: (title: string, content: string[], buttonNum: buttonNum, func: () => void) => void,
}

interface HeadState {
  showChangePageBox: boolean,
  showModal: boolean,
}

export class ConverterHeader extends Component<HeadProps, HeadState> {
  state: HeadState = {
    showChangePageBox: false,
    showModal: false,
  }

  switchPage = (isLatest: boolean) => {
    const modalOptions = {
      title: "캐드컨버터 버전 전환",
      positiveTitle: "네",
      content: <div></div>,
      positive: () => {},
      negative: () => { this.props.closeModal && this.props.closeModal(); },
    };

    if (isLatest) {
      modalOptions.content = <div>캐드컨버터를 구버전(레이어 버전)으로 전환하시겠습니까?<br/>전환하실 경우, 설정하신 모든 내용이 초기화됩니다.</div>
    }
    else {
      modalOptions.content = <div>캐드컨버터를 최신 버전(블록 버전)으로 전환하시겠습니까?<br/>전환하실 경우, 설정하신 모든 내용이 초기화됩니다.</div>
    }

    let link = "";

    switch (this.props.type) {
      case ConverterType.myType:
        if (isLatest) link = "/cad/myBuildingType";
        else link = "/cad/myTypeBlock";

        if (this.props.isFileOpen) {          
          modalOptions.positive = () => { 
            window.location.href = link ;
            this.props.closeModal && this.props.closeModal();
          };
          this.props.showModal && this.props.showModal(modalOptions);
          this.props.showModalLegacy && this.props.showModalLegacy("알림", ["나의 동평면 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = link;
          });
        }
        else window.location.href = link;
        break;
      case ConverterType.mySite:
        if (isLatest) link = "/cad/mySite";
        else link = "/cad/mySiteBlock";

        if (this.props.isFileOpen) {          
          modalOptions.positive = () => { window.location.href = link };
          this.props.showModal && this.props.showModal(modalOptions);
          this.props.showModalLegacy && this.props.showModalLegacy("알림", ["나의 사업영역 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = link;
          });
        }
        else window.location.href = link;
        break;

      case ConverterType.myPlane:
        if (isLatest) link = "/cad/myBuildingPlan";
        else link = "/cad/myPlaneBlock";

        if (this.props.isFileOpen) {          
          modalOptions.positive = () => { window.location.href = link };
          this.props.showModal && this.props.showModal(modalOptions);
          this.props.showModalLegacy && this.props.showModalLegacy("알림", ["나의 배치안 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = link;
          });
        }
        else window.location.href = link;
        break;

    }

    // if (isLatest) {
    //   switch (this.props.type) {
    //     case ConverterType.myType:
    //       if (!this.props.isFileOpen) {
    //         this.props.showModal!("알림", ["나의 동평면 구버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
    //           window.location.href = "/cad/MyBuildingType";
    //         });
    //       }
    //       else 
    //         window.location.href = "/cad/MyBuildingType";
    //       break;
    //     case ConverterType.mySite:
    //       this.props.showModal!("알림", ["나의 사업영역 구버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
    //         window.location.href = "/cad/MySite";
    //       });
    //       break;
    //     case ConverterType.myPlane:
    //       this.props.showModal!("알림", ["나의 배치안 구버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
    //         window.location.href = "/cad/MyBuildingPlan";
    //       });
    //       break;
    //   }
    // } else {
    //   switch (this.props.type) {
    //     case ConverterType.myType:
    //       this.props.showModal!("알림", ["나의 동평면 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
    //         window.location.href = "/cad/myTypeBlock";
    //       });
    //       break;
    //     case ConverterType.mySite:
    //       this.props.showModal!("알림", ["나의 사업영역 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
    //         window.location.href = "/cad/mySiteBlock";
    //       });
    //       break;
    //     case ConverterType.myPlane:
    //       this.props.showModal!("알림", ["나의 배치안 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
    //         window.location.href = "/cad/myPlaneBlock";
    //       });
    //       break;
    //   }
    // }
  }

  turnOffModal() {
    this.setState({ showModal: false })
  }
  render = () => {
    let goFileSetting; 
      
    if (this.props.type === "사업영역") {
      goFileSetting = 
          <Link to={`/myPage/file/mySite`} className="text-deco-none">
            <div className="file-setting font font-18px font-primary">파일 관리</div>
          </Link>
    }  
    else if (this.props.type === "동평면") {
      goFileSetting =
          <Link to={`/myPage/file/mybuildingType`} className="text-deco-none">
            <div className="file-setting font font-18px font-primary">파일 관리</div>
          </Link>
    }
    else {
      goFileSetting =
          <Link to={`/myPage/file/myPlan`} className="text-deco-none">
            <div className="file-setting font font-18px font-primary">파일 관리</div>
          </Link>

    }

    return (
      <header className="ConverterHeader font font-roboto font-pretendard">
        <MapDownloadModal isShow={this.state.showModal} turnOffModal={() => this.turnOffModal()} />
        <div className="Head">
          <div className="breadScrum">
            <Tooltip arrowOn={false} msg="BUILDIT PLATFORM으로 이동">
              <div className="logoDiv" onClick={() => window.open("/", "_self")}>
                <img src="/img/buildit_platform_logo.svg" className="logoImg" />
              </div>
            </Tooltip>
            <NavigateNext className="icon next-icon first" />
            <Tooltip arrowOn={false} msg="파일 관리 페이지로 이동">
              {goFileSetting}
            </Tooltip>

            <NavigateNext className="icon next-icon" />
            <Tooltip arrowOn={false} msg="CAD CONVERTER 홈으로 이동">
              <Link to="/cad/cadConverterHome">
                <IconButton className="home-btn">
                  <Home className="m-l-md icon font font-emphasis home-icon" />
                </IconButton>
              </Link>
            </Tooltip>
          </div>

          <div className="middle-wrap">
            <CadConverterTypeTag 
              isLatest={this.props.isLatest} 
              isSwitchable={true} 
              switchPage={this.switchPage}
            />
            <HeaderDropBox type={this.props.type} isLatest={this.props.isLatest }/>
            {(this.props.type === "사업영역" || this.props.type === "배치안") && (
              <Tooltip msg="사업대상지를 포함한 지정영역을 dxf로 다운로드 할 수 있습니다." arrowOn={false}>
                <Button
                  className="btn bg-navy btn-primary download-cadastral-map-btn font font-noto font-pretendard m-l-md"
                  onClick={() => {
                    //
                    this.setState({ showModal: true });
                  }}
                >
                  지적도 다운로드
                </Button>
              </Tooltip>
            )}
          </div>

 
          <div className="Tools">
          <div className={`errorMessage`}>
              <span className="errorInfo">
                <TaskAlt className={`errorIcon icon completeIcon ${this.props.errorSize > 0 || !this.props.isFileOpen ? "disabled" : ""}`} />
              </span>
              <span className={`errorInfo`}>
                <ErrorIcon className={`errorIcon icon ${this.props.errorSize === 0 && "disabled"}`} />
                <span className="value font font-primary">{this.props.isFileOpen ? this.props.errorSize : "-"}</span>
              </span>
              <span className={`errorInfo `}>
                <WarningIcon className={`warningIcon icon ${this.props.warningSize === 0 && "disabled"}`} />
                <span className="value font font-primary">{this.props.isFileOpen ? this.props.warningSize : "-"}</span>
              </span>
              {
                this.props.infoSize !== undefined &&
                <span className={`errorInfo `}>
                  <Info className={`infoIcon icon ${this.props.infoSize === 0 && "disabled"}`} />
                  <span className="value font font-primary">{this.props.isFileOpen ? this.props.infoSize : "-"}</span>
                </span>
              }
            </div>
            <Tooltip msg={this.props.email} arrowOn={false}>
              <div className="btn btn-navy btn-primary account">
                {/* <div className='account tooltip'> */}
                {/* <span className="tooltiptext">{this.props.email}</span> */}
                <AccountCircle className="icon primary" />
              </div>
              {/* </div> */}
            </Tooltip>
            <Tooltip msg="파일 불러오기" arrowOn={false}>
              <label className="btn bg-navy btn-primary imageBox">
                <input type="file" id="ex_file" style={{ display: "none" }} onChange={this.props.loadFile} onClick={(e) => (e.currentTarget.value = "")} accept=".dxf" multiple />
                <OpenIcon className="icon open-icon"></OpenIcon>
              </label>
            </Tooltip>
            <Tooltip msg="리셋" arrowOn={false}>
              <Button className="btn bg-navy btn-primary imageBox"
                onClick={() => {
                  switch (this.props.type) {
                    case ConverterType.mySite:
                      if (this.props.isLatest) window.location.href = "./cad/mySiteBlock";
                      else window.location.href = "./cad/mySite";
                      break;
                    case ConverterType.myType:
                      if (this.props.isLatest) window.location.href = "./cad/myTypeBlock";
                      else window.location.href = "./cad/MyBuildingType";
                      break;
                    case ConverterType.myPlane:
                      if (this.props.isLatest) window.location.href = "./cad/myPlaneBlock";
                      else window.location.href = "./cad/MyBuildingPlan";
                      break;
                    default:
                      break;
                  }
                }
                }
              >
                <Refresh className="icon reset-icon" />
              </Button>
            </Tooltip>
            <Tooltip msg="설정" arrowOn={false}>
              <Button className="btn bg-navy btn-primary imageBox" onClick={this.props.openSettingModal}>
                <Settings className="icon setting-icon" />
              </Button>
            </Tooltip>
            {/* <Button className={`btn bg-navy btn-run font font-noto saveButton
                ${!this.props.isFileOpen ? 'disabled' : ''}`

            } 
              onClick={this.props.saveFile} >
              {this.props.type} 저장
            </Button> */}
            {
              // 0903 수정

              <Button
                className={`btn bg-navy btn-run font font-noto font-pretendard saveButton 
                ${(this.props.isFileOpen && this.props.errorSize > 0) || !this.props.isFileOpen ? "disabled" : ""}`}
                onClick={
                  this.props.saveFile
                }
              >
                {this.props.type} 저장
              </Button>
            }
          </div>
        </div>
      </header>
    );
  }
}