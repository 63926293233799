import { Done, ExpandLess, ExpandMore, Help, Home } from '@material-ui/icons';
import React, { Component } from 'react';
import { ConverterType } from './DataTypes';
import { buttonNum, CadConverterModal, ModalProps } from './Modal';
import '../css/CADConverter/HeaderDropBox.scss';
import { Link } from 'react-router-dom';
import Check from '@material-ui/icons/Check';
import App from '../App';

interface DropBoxProps {
  type: ConverterType | "home",
  isLatest: boolean,
  showModal?: (title: string, content: string[], buttonNum: buttonNum, func: () => void) => void,
}

interface DropBoxState {
  showChangePageBox: boolean;
  showModal: boolean;
}

class HeaderDropBox extends Component<DropBoxProps, DropBoxState> {
  state: DropBoxState = {
    showChangePageBox: false,
    showModal: false,
  };

  ModalProps: ModalProps = {
    content: ["내용"],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log("test"),
    title: "제목",
  };

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    });
  };

  switchPage = (isLatest: boolean, converterType?: ConverterType | "home") => {
    if (isLatest) {
      switch (converterType) {
        case ConverterType.myType:
          this.showModal!("알림", ["나의 동평면 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = "/cad/myTypeBlock";
          });
          break;
        case ConverterType.mySite:
          this.showModal!("알림", ["나의 사업영역 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = "/cad/mySiteBlock";
          });
          break;
        case ConverterType.myPlane:
          this.showModal!("알림", ["나의 배치안 최신버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = "/cad/myPlaneBlock";
          });
          break;
        default:
          this.showModal!("알림", ["캐드컨버터 홈으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.open("/cad/cadConverterHome", "_self");
          });
          break;
      }
    } else {
      switch (converterType) {
        case ConverterType.myType:
          this.showModal!("알림", ["나의 동평면 구버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = "/cad/MyBuildingType";
          });
          break;
        case ConverterType.mySite:
          this.showModal!("알림", ["나의 사업영역 구버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = "/cad/MySite";
          });
          break;
        case ConverterType.myPlane:
          this.showModal!("알림", ["나의 배치안 구버전으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.location.href = "/cad/MyBuildingPlan";
          });
          break;
        default:
          this.showModal!("알림", ["캐드컨버터 홈으로 이동 하겠습니까?"], buttonNum.twoButton, () => {
            window.open("/cad/cadConverterHome", "_self");
          });
          break;
      }
    }
  };

  render() {
    return (
      <div className="HeaderDropBox">
        <CadConverterModal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive} />
        <div
          className="title-wrap dropdown"
          onMouseEnter={() => this.setState({ showChangePageBox: !this.state.showChangePageBox })}
          onMouseLeave={() => this.setState({ showChangePageBox: !this.state.showChangePageBox })}
        >
          <div className="dropbtn">
            <div className="title">{`CAD Converter ${this.props.type !== "home" ? this.props.type : ""}`}</div>
            {this.props.type === "home" && <Home className="icon home-icon font font-primary" />}
            <div className="mainText">{!this.state.showChangePageBox ? <ExpandMore className="icon expand-icon" /> : <ExpandLess className="icon expand-icon" />}</div>
          </div>
          <div className={`dropdown-content ${this.state.showChangePageBox && "show"}`}>
            <div className="select-menu">
              <div className={`dropbox`}>
                <div className="item font font-16px " onClick={(e) => this.switchPage(this.props.isLatest, "home")}>
                  <div className="name-wrap">
                    <Check className={`font icon checkIcon ${this.props.type !== "home" ? "visibility-hidden" : "font-special"} `} />
                    <span className="name">CAD Converter</span>
                    <Home className="icon home-icon" />
                  </div>
                </div>
                <div className="item font font-16px " onClick={(e) => this.switchPage(this.props.isLatest, ConverterType.myType)}>
                  <div className="name-wrap">
                    <Check className={`font icon checkIcon ${this.props.type !== ConverterType.myType ? "visibility-hidden" : "font-special"}`} />
                    <span className="name">{`CAD Converter ${ConverterType.myType}`}</span>
                  </div>
                </div>
                <div className="item font font-16px " onClick={(e) => this.switchPage(this.props.isLatest, ConverterType.mySite)}>
                  <div className="name-wrap">
                    <Check className={`font icon checkIcon ${this.props.type !== ConverterType.mySite ? "visibility-hidden" : "font-special"}`} />
                    <span className="name">{`CAD Converter ${ConverterType.mySite}`}</span>
                  </div>
                </div>
                <div className="item font font-16px " onClick={(e) => this.switchPage(this.props.isLatest, ConverterType.myPlane)}>
                  <div className="name-wrap">
                    <Check className={`font icon checkIcon ${this.props.type !== ConverterType.myPlane ? "visibility-hidden" : "font-special"}`} />
                    <span className="name">{`CAD Converter ${ConverterType.myPlane}`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderDropBox;