import { Visibility, VisibilityOff } from '@material-ui/icons';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React, { ReactText, useEffect, useLayoutEffect, useState } from 'react';
import { ConverterField } from './CADConverter/BuildingPart';
import { ConverterType } from './CADConverter/DataTypes';
import './css/ConstructionLineInfo.scss';
import { ProjectType, LineInfoType } from './model/Project';

type LineObjType = {
  [key: string]: {
    type: string,
    css: {
      border?: string,
      background?: string,
      height?: string,
    },
    cad_css?: {
      background?: string,
      border?: string,
      height?: string,
    }
    isLine?: boolean,
    lowOpacity?: boolean,
  }
}


const lines: LineObjType = {
  project_site : {
    type: "대지영역",
    css: {
      border: "3px dashed #FF3333",
    
      background: "rgba(255, 51, 51, 0.3)",
    },
    cad_css: {
      border: "3px dashed #FF3333",
    
    },
    lowOpacity: false,
  },
  center_of_road : {
    type: "인접대지경계선",
    css: {
      background: "rgba(25, 25, 230, 0.15)",
      border: "2px solid #1919E6",
    
    },
    cad_css: {
      border: "2px solid #1919E6",      
  
    },
    lowOpacity: false,
  },
  road : {
    type: "인접도로",
    css: {
      background: "rgba(90, 121, 165, 0.4)",
      border: "1px solid #5A79A5",
  
    },
    cad_css: {
      border: "2px solid #555",
    
    },
    lowOpacity: false,
  },
  vacancy_outside : {
    type: "공지영역",
    css: {
      background: "rgba(0, 161, 94, 0.3)",
      border: "1px solid #00A15E",
     },
    cad_css: {
      border: "2px solid #0F6B00",
  
    },
    lowOpacity: false,
  },
  vacancy_inside : {
    type: "배치제한영역",
    css: {
      background: "rgba(136, 0, 200, 0.3)",
      border: "1px solid #8800C8",
    },
    cad_css: {
      border: "2px solid #8800C8",  
    },
    lowOpacity: false,
  },
  topography : {
    type: "대지레벨",
    css: {
      background: "#CCC",
    },
    isLine: true,
    lowOpacity: false,
  },
  sky_line : {
    type: "스카이라인",
    css: {
      background: "rgba(0, 118, 227, 0.3)",
      border: "1px solid #0076E3",
    },
  },
  core: {
    type: "코어",
    css: {
      background: "#246D77",
      border: "1px solid #246D77"
    },
  },
  house: {
    type: "단위세대",
    css: {
      background: "rgba(149, 228, 179, 0.6)",
      border: "1px solid #246D77"
    },
  },
  // cad converter
  concrete: {
    type: "벽",
    css: {
      background: "#95E4B3"
    },
    isLine: true,
  },
  light_window:  {
    type: "채광창",
    css: {
      background: "#0038FF",
    },
    isLine: true,
  },
  normal_window: {
    type: "일반창",
    css: {
      background: "#8F00FF",
    },
    isLine: true,
  },


};

interface ConstructionLineInfoProps {
  title?: string[];
  className?: string;
  isVisible?: boolean[];
  hasVisibleIcon?: boolean;
  handleVisible?: (converterType: ConverterType.mySite | ConverterType.myType) => void,
  isCollapsible?: boolean;
  darkMode?: boolean;
  typeList: LineInfoType[][];
  setIsFocus?: (type: LineInfoType | null) => void;
  isCadConverter?: boolean;
  fieldBlocks?: {
    site: ConverterField[],
    road: ConverterField[],
    roadCenterLine: ConverterField[],
    vacancyInside: ConverterField[],
    vacancyOutside: ConverterField[],
    topography: ConverterField[],
  },
}

interface ConstructionLineInfoState {
  isOpen: boolean;
}

const ConstructionLineInfo = (props: ConstructionLineInfoProps, state: ConstructionLineInfoState) => {
  const [isOpen, setIsOpen] = useState(true);
  const {title, handleVisible, isVisible, darkMode, fieldBlocks} = props;

  useEffect(() => {    
    if (fieldBlocks) {
      if (fieldBlocks.site.length === 0) {
        lines.project_site.lowOpacity = true;
      }
      if (fieldBlocks.road.length === 0) {
        lines.road.lowOpacity = true;
      }
      if (fieldBlocks.roadCenterLine.length === 0) {
        lines.center_of_road.lowOpacity = true;
      }
      if (fieldBlocks.vacancyInside.length === 0) {
        lines.vacancy_inside.lowOpacity = true;
      }
      if (fieldBlocks.vacancyOutside.length === 0) {
        lines.vacancy_outside.lowOpacity = true;
      }
      if (fieldBlocks.topography.length === 0) {
        lines.topography.lowOpacity = true;
      }

    }
  }, [fieldBlocks]);
  
  return (
    <div className={`ConstructionLineInfo construction-line-info ${darkMode && "dark-mode"}`}>
      {
        props.isCollapsible &&
        <div className="header"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className="text">범례</div>
          <div className="icon-btn">
            {isOpen ? <KeyboardArrowUp className="arrow-icon" /> : <KeyboardArrowDown className="arrow-icon" />}
          </div>
        </div>
      }
      {
        ((props.isCollapsible && isOpen) || !props.isCollapsible) &&
        props.typeList.map((typeList, idx) =>
          <div className={`m-t-sm m-b-md ${!props.title ? 'm-t-sm' : ""}`}>
            {title && 
            <div className={`m-t-sm title line`}>{title[idx]}
              {
                props.hasVisibleIcon &&
                <div className="wrap-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (title && handleVisible) {
                      const type = title[idx] === "나의 사업영역" ? ConverterType.mySite : ConverterType.myType
                      handleVisible(type);
                    }
                  }}
                >
                  {(isVisible && isVisible[idx] ? <Visibility className="visibility-icon" /> : <VisibilityOff className="visibility-icon disabled" />)}
                </div>
              }
            </div>}
            {
              typeList.map(type => {
                return (
                  <div
                    className={`line-type ${lines[type].lowOpacity ? "disabled" : ""}`}
                    style={{ display: "flex", alignItems: "center", border: "1px dash" }}
                    onMouseOver={() => {
                      props.setIsFocus && props.setIsFocus!(type);
                    }}
                    onMouseLeave={() => {
                      props.setIsFocus && props.setIsFocus!(null);
                    }}
                  >

                    {lines[type].isLine ? (
                      <div className={`m-r-sm img`}>
                        <div className={`line ${isVisible && !isVisible[idx] ? "low-opacity" : ""}`}
                          style={(darkMode && lines[type].cad_css) ? lines[type].cad_css : lines[type].css} />
                      </div>
                    ) : (
                      <div className={`m-r-sm img ${isVisible && !isVisible[idx] ? "low-opacity" : ""}`}
                        style={(darkMode && lines[type].cad_css) ? lines[type].cad_css : lines[type].css} />
                    )}
                    <span className={`name ${isVisible && !isVisible[idx] ? "low-opacity" : ""}`}>
                      {(type === "road" && darkMode) ? "도로영역" : lines[type].type}
                    </span>
                  </div>
                );
              })
            }
          </div>
        )
      }
  </div>
  );
}



export default ConstructionLineInfo;